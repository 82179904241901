import {useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {GET_PRODUCT_BY_ID} from "../graphql/queries/products/getProductById";
import {
  GetProductById,
  GetProductById_getProductById,
  GetProductByIdVariables
} from "../graphql/queries/products/getProductById/__generated__/GetProductById";

interface EditProductParams {
  id: string;
}

export const useFetchProductFromRoute = () => {
  const { id } = useParams<EditProductParams>();
  const [product, setProduct] = useState<GetProductById_getProductById | null>(null);
  const [getProductById, { error,loading, data }] = useLazyQuery<GetProductById, GetProductByIdVariables>(
    GET_PRODUCT_BY_ID,
    {
      onError: (err) => console.error(err),
    }
  );

  useEffect(() => {
      getProductById({ variables: { id } });
  }, []);

  useEffect(() => {
    if (data?.getProductById) {
      setProduct(data.getProductById);
    }
  }, [data]);

  return {
    loading,
    error,
    product,
  };
};