import { useReactiveVar } from "@apollo/client";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { enumsVar } from "../../../apollo";
import {
  MyAutocomplete,
  MyDatePicker,
  MyTextField,
  SelectButton
} from "../../../components";
import { useButtonStyles } from "../../../components/buttons/styles";
import {
  CreateJobInput,
  PublishStatus,
  UpdateJobInput,
} from "../../../graphql/globalTypes";
import {
  GetCompanies_getCompanies_companies
} from "../../../graphql/queries/company/getCompanies/__generated__/GetCompanies";
import { FetchEnums_fetchEnums_employmentTypes as IEnum } from "../../../graphql/queries/jobs/FetchEnums/__generated__/FetchEnums";
import { useGetAllCountries } from "../../../hooks";
import { JobSource, ReadAccess } from "../../../lib";
import { useCommonStyle } from "../../../styles/commonStyles";
import { capitalize, convertEnumToArray } from "../../../utils";
import { UpdateTags } from "./updateJob";

interface EditJobLeftProps {
  applicationLink: string;
  stats?: React.ReactElement;
  categories?: React.ReactElement;
  create?: {
    input: CreateJobInput;
    setInput: React.Dispatch<
      React.SetStateAction<CreateJobInput>
    >;
  };
  update?: {
    input: UpdateJobInput;
    setInput: React.Dispatch<
      React.SetStateAction<UpdateJobInput>
    >;
  }
}

export const EditJobLeft: React.FC<EditJobLeftProps> = ({
  update,
  create,
  applicationLink,
  stats,
  categories,
}) => {
  const commonClasses = useCommonStyle();
  const [companies, setCompanies] = useState<
    GetCompanies_getCompanies_companies[]
  >([]);
  const { t } = useTranslation();
  const enums = useReactiveVar(enumsVar);
  const { countries, loading: countriesLoading } = useGetAllCountries();
  const classes = useButtonStyles();
  /*
  const { loading: companiesLoading } = useQuery<
    GetCompanies,
    GetCompaniesVariables
  >(GET_COMPANIES, {
    variables: {
      filter: {},
    },
    onCompleted: (res) => {
      if (res.getCompanies.companies) {
        setCompanies(res.getCompanies.companies);
      }
    },
  });*/

  const getCountryValue = useMemo(() => {
    const location = update?.input.location;

    if (!countries.length) return "";

    if (location?.countryId) {
      return countries.find((c) => c.id === location!.countryId)!
        .rusTrans!;
    } else {
      return "";
    }
  }, [update, countries]);


  const handleSetCountry = (n: string | null) => {
    const countryId = n
      ? countries.find((c) => c.rusTrans === n)!.id
      : undefined;

      if (update) {
        update.setInput((prev) => ({
          ...prev,
          location: {
            ...prev.location!,
            countryId,
          },
        }))
      }
  };

  const handleSelectChange = (e: any, key: keyof CreateJobInput) => {
    if (update) {
      update.setInput((prev) => ({
        ...prev,
        [key]: e.target.value,
      }))
    } else if (create) {
      create.setInput((prev) => ({
        ...prev,
        [key]: e.target.value,
      }))
    }
  };
  const handleDateChange = (e: MaterialUiPickersDate) => {
    if (update) {
      update.setInput((prev) => ({
        ...prev,
        openUntil: e?.toDate(),
      }))
    } else if (create) {
      create.setInput((prev) => ({
        ...prev,
        openUntil: e?.toDate(),
      }))
    }
  };

  const handleEnumChange = useCallback(
    (e: any, key: keyof UpdateJobInput, arr: IEnum[]) => {

      if (update) {
        update.setInput((prev) => ({
          ...prev,
          [key]:
          e.target.value === t("Not specified")
            ? ""
            : arr.find((v) => capitalize(v.name) === e.target.value)!.id,        }))
      } 
    },
    []
  );

  const getNameByEnumId = useCallback(
    (key: "employmentType" | "seniority", searchArr: IEnum[]) => {
      if (update) {
        return update.input[key] === ""
        ? t("Not specified")
        : capitalize(searchArr.find((v) => v.id === update.input[key])!.name);
      } else if (create) {
        return  t("Not specified")
      }     
    },
    [update, create]
  );

  const handleJobCategoryChange = (e: any) => {
    if (e.target.value) {
      if (update) {
        update.setInput((prev) => ({
          ...prev,
          jobCategories: [e.target.value],
        }));
      } 
    }
  };

  return (
    <Box className={commonClasses.sectionWithSticky__scrollable}>
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name="isUN"
        Label={{
          label: t("UN_JOB"),
        }}
      />

      <Box height="20px" />

      <Field
        component={MyTextField}
        fullWidth
        label={t("Position")}
        name="title"
        style={{ marginBottom: 20 }}
      />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4} style={{ flex: 1 }}>
          <Field
            component={MyTextField}
            fullWidth
            label={t("sourceId")}
            name="sourceId"
          />
        </Grid>
        <Grid item xs={4} style={{ flex: 1 }}>
          <SelectButton
            label={t("sourceType")}
            rootStyle={{ width: "100%" }}
            options={convertEnumToArray(JobSource)}
            value={create?.input.sourceType ?? update?.input.sourceType ?? ""}
            onChange={(e) => handleSelectChange(e, "sourceType")}
            noOptionTranslation
          />
        </Grid>
        <Grid item xs={5} />

        <Grid item xs={11}>
          <Field
            component={MyTextField}
            fullWidth
            label={t("applicationLink")}
            name="applicationLink"
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            href={applicationLink}
            disabled={!!!applicationLink}
            target="_blank"
          >
            <OpenInNew />
          </IconButton>
        </Grid>

        <Grid item xs={4} style={{ flex: 1 }}>
          <SelectButton
            label={t("status")}
            rootStyle={{ width: "100%" }}
            options={convertEnumToArray(PublishStatus)}
            value={create?.input.status ?? update?.input.status ?? ""}
            onChange={(e) => handleSelectChange(e, "status")}
          />
        </Grid>
        <Grid item xs={4} style={{ flex: 1 }}>
          <SelectButton
            label={t("readAccess")}
            rootStyle={{ width: "100%" }}
            options={convertEnumToArray(ReadAccess)}
            value={create?.input.readAccess ?? update?.input.readAccess ?? ""}
            onChange={(e) => handleSelectChange(e, "readAccess")}
          />
        </Grid>
        <Grid item xs={4} style={{ flex: 1 }}>
          <MyDatePicker
            value={create?.input.openUntil ?? update?.input.openUntil ?? ""}
            disablePast
            onChange={handleDateChange}
            label={t("openUntil")}
            rootStyle={{ width: "100%" }}
          />
        </Grid>
      </Grid>

      <Box marginY={2} />
      <Typography variant="overline">Компания</Typography>
      <Box marginY={1} />
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4} style={{ flex: 1 }}>
          <Field
            component={MyTextField}
            fullWidth
            label={"Как видят юзеры"}
            name="companyName"
          />
        </Grid>

        {/*
        <Grid item xs={4} style={{ flex: 1 }}>
          {companiesLoading ? (
            <Typography>Компании загружаются...</Typography>
          ) : (
            <FormikFieldSingleAutocomplete
              name="companyId"
              placeholder="Сущность"
              options={companies.map((c) => c.id)}
              getOptionLabel={(id) =>
                companies.find((c) => c.id === id)?.name ?? ""
              }
            />
          )}
            </Grid>*/}

        <Grid item xs={12} />
      </Grid>

      <Box marginY={2} />
      <Typography variant="overline">{t("Location")}</Typography>
      <Box marginY={1} />
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4} style={{ flex: 1 }}>
          {countriesLoading ? (
            <Typography>Страны загружаются...</Typography>
          ) : (
            <MyAutocomplete
              options={countries.map((c) => c.rusTrans)}
              getOptionLabel={(option) => option}
              label={t("Country")}
              value={getCountryValue}
              placeholder={undefined}
              setValue={handleSetCountry}
            />
          )}
        </Grid>

        <Grid item xs={4} style={{ flex: 1 }}>
          <Field
            component={MyTextField}
            fullWidth
            label={t("city")}
            name="city"
          />
        </Grid>
      </Grid>

      <Box marginY={2} />
      <Typography variant="overline">{t("Details")}</Typography>
      <Box marginY={1} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >

        {update && (
          <>
            {!!enums?.employmentTypes.length && (
          <Grid item xs={6} style={{ flex: 1 }}>
            <SelectButton
              label={t("Employment Type")}
              rootStyle={{ width: "100%" }}
              noOptionTranslation
              options={[
                t("Not specified"),
                ...enums.employmentTypes.map((e) => capitalize(e.name)),
              ]}
              value={getNameByEnumId("employmentType", enums.employmentTypes)}
              onChange={(e) =>
                handleEnumChange(e, "employmentType", enums.employmentTypes)
              }
            />
          </Grid>
        )}

        {!!enums?.seniorities.length && (
          <Grid item xs={6} style={{ flex: 1 }}>
            <SelectButton
              label={t("Seniorities")}
              noOptionTranslation
              rootStyle={{ width: "100%" }}
              options={[
                t("Not specified"),
                ...enums.seniorities.map((e) => capitalize(e.name)),
              ]}
              value={getNameByEnumId("seniority", enums.seniorities)}
              onChange={(e) =>
                handleEnumChange(e, "seniority", enums.seniorities)
              }
            />
          </Grid>
        )}
          
          </>
        )}
      </Grid>

      <Box marginTop={3} />
      <Grid container justifyContent="space-between" spacing={2}>
        {update && (
          <>
            {!!enums?.jobCategories.length && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormHelperText className={classes.sb_label}>
                  Категория вакансии
                </FormHelperText>
                <Select
                  fullWidth
                  displayEmpty
                  value={
                    update.input.jobCategories?.length ? update.input.jobCategories[0] : ""
                  }
                  onChange={handleJobCategoryChange}
                  variant="outlined"
                >
                  <MenuItem disabled value={""}>
                    Выберите категорию
                  </MenuItem>
                  {enums.jobCategories.map((option, index) => (
                    <MenuItem value={option.id} key={index}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} />
          </>
        )}
          
          </>
        )}
    
        <Grid item xs={6}>
          <UpdateTags input={create?.input ?? update!.input!} setInput={create ? create.setInput : update!.setInput! as any} />
        </Grid>
      </Grid>

      {stats}
    </Box>
  );
};
