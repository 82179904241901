import { gql } from "@apollo/client";
import { SeoTagFragment } from "../../../fragments";

export const CREATE_SEO_TAG = gql`
  mutation CreateSeoTag($input: CreateSeoTagInput!) {
    createSeoTag(input: $input) {
      ...SeoTag
    }
  }
  ${SeoTagFragment}
`;
