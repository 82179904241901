import {useQuery} from "@apollo/client";
import {
  GET_CURRENCY_EXCHANGE_RATES
} from "../../graphql/queries/promoters/getCurrencyExchangeRates";
import {
  GetCurrencyExchangeRates
} from "../../graphql/queries/promoters/getCurrencyExchangeRates/__generated__/GetCurrencyExchangeRates";

export const useGetCurrencyExchangeRates = () => {
  const {data, loading, error} =
    useQuery<GetCurrencyExchangeRates>(
      GET_CURRENCY_EXCHANGE_RATES,
      {
        onError: (err) => console.error(err),
      }
    );

  return {data:data?.getCurrencyExchangeRates, loading, error};
};