import {
  Box,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { SimpleObject } from "../../lib";
import { useInputStyles } from "./styles";

interface CheckboxListProps {
  value: SimpleObject[];
  options: SimpleObject[];
  popOption: (o: SimpleObject) => void;
  pushOption: (o: SimpleObject) => void;
  title: string;
  loading?: boolean;
}

export const CheckboxList: React.FC<CheckboxListProps> = ({
  value,
  options,
  popOption,
  pushOption,
  title,
  loading,
}) => {
  const classes = useInputStyles();

  const handleToggle = (option: SimpleObject) => () => {
    if (valueHas(option)) {
      popOption(option);
    } else {
      pushOption(option);
    }
  };

  const valueHas = (option: SimpleObject) => {
    return !!value.find((v) => v.id === option.id);
  };

  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>

      <Paper className={classes.checkList__paper}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70px"
          >
            <CircularProgress size="30px" />
          </Box>
        ) : (
          <List dense component="div" role="list">
            {options.map((option) => {
              return (
                <ListItem
                  key={option.id}
                  role="listitem"
                  button
                  onClick={handleToggle(option)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={valueHas(option)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        )}
      </Paper>
    </Box>
  );
};
