import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, Radio, RadioGroup, Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CreateLearningPathFormValues,
  FileType,
  IPicUpload,
} from "../../../../lib";
import * as Yup from "yup";
import { MyTextField, PictureUpload } from "../../../../components";
import { UpdateDescription } from "./UpdateDescription";
import { useMutation } from "@apollo/client";
import {
  CreateLearningPath,
  CreateLearningPathVariables,
} from "../../../../graphql/mutations/learningPath/createLearningPath/__generated__/CreateLearningPath";
import { CREATE_LEARNING_PATH, GET_LEARNING_PATHS } from "../../../../graphql";
import {convertEnumToArray, getFileNameFromPublicId} from "../../../../utils";
import {
  GetLearningPathsVariables
} from "../../../../graphql/queries/learningPath/getLearningPaths/__generated__/GetLearningPaths";
import {AppLanguage} from "../../../../graphql/globalTypes";
import {appLanguages} from "../../../../lib/constants";

interface AddNewLearningPathProps {
  variables?: GetLearningPathsVariables;
}

export const AddNewLearningPath: React.FC<AddNewLearningPathProps> = ({
  variables,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>("");
  const [create, { loading, error }] = useMutation<
    CreateLearningPath,
    CreateLearningPathVariables
  >(CREATE_LEARNING_PATH, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LEARNING_PATHS,
        variables,
      },
    ],
  });
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);

  useEffect(() => {
    if (!open) {
      setPicUpload(null);
    }
  }, [open]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const initialValues: CreateLearningPathFormValues = {
    title: "",
    slug: "",
    heroVideoUrl: "",
    language:AppLanguage.ru
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    slug: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t("Invalid slug"))
      .required(t("Required")),
  });

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="secondary">
        Добавить новый марафон
      </Button>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Добавить новый марафон</DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            let picture: any | null;
            if (picUpload) {
              picture = {
                publicId: picUpload.public_id,
                url: picUpload.url,
                filename: getFileNameFromPublicId(picUpload.public_id),
                type: FileType.IMAGE,
              };
            }

            await create({
              variables: {
                input: {
                  ...values,
                  description,
                  ...(picture && {
                    heroPic: picture,
                  }),
                },
              },
            });

            setSubmitting(false);
            handleClose();
          }}
        >
          {({ dirty, submitForm, isSubmitting, setFieldValue, values }) => (
            <Form>
              <>
                <DialogContent>
                  <PictureUpload
                    picUpload={picUpload}
                    setPicUpload={setPicUpload}
                    rootStyles={{
                      height: "200px",
                      width: "200px",
                      overflow: "hidden",
                      marginBottom: 20,
                    }}
                  />

                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("Title")}
                    name="title"
                    style={{ marginBottom: 20 }}
                  />
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("slug")}
                    name="slug"
                    style={{ marginBottom: 20 }}
                  />
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={"Video Url"}
                    name="heroVideoUrl"
                    style={{ marginBottom: 10 }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {t("Language")}
                  </Typography>
                  <RadioGroup row value={values.language}
                              onChange={(e)=>setFieldValue("language", e.target.value)} >
                    {convertEnumToArray(AppLanguage).map(lng=>
                      <FormControlLabel value={lng}
                                        control={<Radio />}
                                        label={appLanguages[lng]}
                                        key={lng} />)}
                  </RadioGroup>
                  <UpdateDescription
                    description={description}
                    setDescription={setDescription}
                  />
                </DialogContent>
                <DialogActions>
                  <Button disabled={isSubmitting} onClick={handleClose}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    {t("Save")}
                    {isSubmitting && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={10}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
