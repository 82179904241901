import { gql } from "@apollo/client";
import { TableJobFragment } from "../../../fragments";

export const GET_JOBS = gql`
  query AdminGetJobs(
    $input: AdminGetJobsInput!
    $paginationOptions: PaginationOptionsArgs!
  ) {
    adminGetJobs(input: $input, paginationOptions: $paginationOptions) {
      total
      jobs {
        ...TableJob
      }
    }
  }
  ${TableJobFragment}
`;
