import {gql} from "@apollo/client";
import {ExpertFragment} from "../../../fragments";

export const GET_EXPERT_BY_ID = gql`
    query GetExpertById($id: String!) {
        getExpertById(id: $id) {
            ...Expert
        }
    }
    ${ExpertFragment}
`;
