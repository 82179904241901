import { useQuery } from "@apollo/client";
import { FETCH_USER_PREFERENCES } from "../../graphql";
import { FetchUserPreferencesInput } from "../../graphql/globalTypes";
import {
  FetchUserPreferences,
  FetchUserPreferencesVariables,
} from "../../graphql/queries/users/FetchUserPreferences/__generated__/FetchUserPreferences";

export const useFetchUserPreferences = (input: FetchUserPreferencesInput) => {
  const { data, loading, error } = useQuery<
    FetchUserPreferences,
    FetchUserPreferencesVariables
  >(FETCH_USER_PREFERENCES, {
    onError: (err) => console.error(err),
    variables: {
      input,
    },
  });

  return {
    data,
    loading,
    error,
  };
};
