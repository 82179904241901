import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useLayoutStyle = makeStyles((theme: Theme) =>
  createStyles({
    fs_root: {
      position: "relative",
      width: "100vw",
      height: "100vh",
      background: "#192156",
    },
    fs_header: {
      background: "linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important",
      height: " 50%",
      position: "relative",
    },
    fs_separator: {
      position: "absolute",
      left: 0,
      right: 0,
      width: "100%",
      height: "100px",
      overflow: "hidden",
      pointerEvents: "none",
      bottom: 0,
      "& svg": {
        position: "absolute",
        pointerEvents: "none",
        bottom: 0,
        "& polygon": {
          fill: "#192156",
        },
      },
    },
    fs_main: {
      position: "relative",
      overflow: "scroll",
      maxWidth: "36vw",
      minWidth: "300px",
      maxHeight: "70vh",
      background: theme.palette.background.default,
      borderRadius: "4px",
      boxShadow: "0 0 2rem 0 rgb(136 152 170 / 15%) !important",
      transition: "all 0.5s ease-in",
    },
    fs_container: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    fs_wrapper: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: "hidden",
    },
    appBar: {
      //zIndex: theme.zIndex.drawer + 1,
      zIndex: 1200,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    dl_content: {
      flexGrow: 1,
    },
    dl_contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      minHeight: "55px",
      justifyContent: "flex-end",
    },
    dl_menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    grow: {
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    logoWrapper: {
      display: "flex",
      height: "55px",
      padding: "8px",
      position: "relative",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      "& img": {
        maxWidth: "120px",
        objectFit: "contain",
      },
    },
    listRoot: {
      "& .MuiListItem-button:hover": {
        backgroundColor: "rgb(246 247 254)",
      },
      "& .MuiListItemText-root,": {
        color: theme.palette.text.secondary,
      },
      "& .MuiSvgIcon-root": {
        fill: "#75757591",
      },
      "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover":
        {
          backgroundColor: "rgb(246 247 254)",
          "& .MuiSvgIcon-root": {
            fill: theme.palette.secondary.main,
          },
          "& .MuiListItemText-root": {
            color: theme.palette.text.primary,
          },
        },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
  })
);
