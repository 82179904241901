import { Box, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTableStyle } from "../styles";

interface MyTableToolbarProps {
  numSelected: number;
  rightButton?: JSX.Element;
  left?: JSX.Element;
  bulkActions: JSX.Element;
}

export const MyTableToolbar: React.FC<MyTableToolbarProps> = ({
  numSelected,
  rightButton,
  left,
  bulkActions,
}) => {
  const classes = useTableStyle();
  const { t } = useTranslation();

  const actionsMarkup = (
    <>
      <Typography
        className={classes.toolbar_title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("selected")} {numSelected}
      </Typography>

      {bulkActions}
    </>
  );

  const defaultMarkup = (
    <>
      {left && left}
      <Box flex={1} />
      {rightButton ?? null}
    </>
  );

  return (
    <Toolbar
      className={clsx(classes.toolbar_root, {
        [classes.toolbar_highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? actionsMarkup : defaultMarkup}
    </Toolbar>
  );
};
