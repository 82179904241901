import {gql} from "@apollo/client";

export const GET_PROMOTERS_CURRENT_STATS = gql`
    query GetPromotersCurrentStats {
        getPromotersCurrentStats {
            countActivePromoters
            countAllPromoters
            countPromotersEntitledToPayout
            totalPayoutAmountInUsd
            partialPayoutAmountInUsd
            partialPayoutAmountInRub
            partialPayoutAmountInEur
        }
    }
`;