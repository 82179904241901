import { gql } from "@apollo/client";

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      user {
        id
        role
      }
      error {
        name
        message
      }
    }
  }
`;
