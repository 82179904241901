import { useLazyQuery } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../graphql";
import {
  ForgotPassword,
  ForgotPasswordVariables,
} from "../../graphql/mutations/me/ForgotPassword/__generated__/ForgotPassword";

export const useForgotPassword = () => {
  const [forgotPassword, { error, loading }] = useLazyQuery<
    ForgotPassword,
    ForgotPasswordVariables
  >(FORGOT_PASSWORD, {
    onError: (err) => {
      console.error(err);
    },
  });

  return { forgotPassword, error, loading };
};
