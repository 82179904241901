import {
  ApolloQueryResult,
  OperationVariables,
  useReactiveVar,
} from "@apollo/client";
import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import { AddNewButton, TextSearch } from "../../../components";
import { GetCoupons } from "../../../graphql/queries/subscriptions/GetCoupons/__generated__/GetCoupons";

interface FilterBarProps {
  couponCode: string;
  setCouponCode: (s: string) => void;
  setOpen: (s: boolean) => void;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCoupons>>;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  couponCode,
  setCouponCode,
  refetch,
  setOpen,
}) => {
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setCouponCode(v);
    }
  };
  const handleTextSearchReset = () => {
    setCouponCode("");
  };

  const handleAddNew = () => {
    setOpen(true);
  };

  return (
    <Box
      zIndex={100}
      display="flex"
      width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
      justifyContent="stretch"
      position="fixed"
    >
      <TextSearch
        rootStyle={{ flex: 1 }}
        onClick={handleTextSearchChange}
        onReset={handleTextSearchReset}
        hint="Coupon code"
      />
      <Box style={{ flex: 2 }} />
      <Box
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <AddNewButton title={t("Add Coupon")} onClick={handleAddNew} />
      </Box>
    </Box>
  );
};
