import { gql } from "@apollo/client";
import { ArticleCategoryFragment } from "../../../fragments";

export const UPDATE_ARTICLE_CATEGORY = gql`
  mutation UpdateArticleCategory($input: UpdateArticleCategoryInput!) {
    updateArticleCategory(input: $input) {
      category {
        ...ArticleCategory
      }
      error {
        name
        message
      }
    }
  }
  ${ArticleCategoryFragment}
`;
