import { Box, Chip, Typography } from "@material-ui/core";
import React from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { useTranslation } from "react-i18next";
import { UpdateLearningStepInput } from "../../../../graphql/globalTypes";

interface DndStepsProps {
  orderedSteps: UpdateLearningStepInput[];
  setOrderedSteps: (v: UpdateLearningStepInput[]) => void;
  countAvailableArticles: number;
}

export const DndSteps: React.FC<DndStepsProps> = ({
  orderedSteps,
  countAvailableArticles,
  setOrderedSteps,
}) => {
  const { t } = useTranslation();

  function onChange(
    _sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    _targetId?: string | undefined
  ) {
    const result = swap(orderedSteps, sourceIndex, targetIndex);
    setOrderedSteps(result);
  }

  const CARD_HEIGHT = 300;
  const CARD_PER_ROW = 3;

  const calcDropContainerHeight = () => {
    return Math.ceil(orderedSteps.length / CARD_PER_ROW) * (CARD_HEIGHT + 20);
  };

  const free = (index: number) => {
    return index < countAvailableArticles;
  };

  return (
    <Box position="relative">
      <GridContextProvider onChange={onChange}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            margin: "1rem auto",
          }}
        >
          <GridDropZone
            className="dropzone"
            id="main"
            boxesPerRow={CARD_PER_ROW}
            rowHeight={CARD_HEIGHT + 20}
            style={{
              flex: 1,
              height: calcDropContainerHeight() + "px",
            }}
          >
            {orderedSteps.map((step, index) => (
              <GridItem key={step.order}>
                <Box
                  className="grid-item"
                  sx={{
                    padding: "10px",
                    width: "100%",
                    height: CARD_HEIGHT + "px",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    className="grid-item-content"
                    style={{
                      background: "white",
                      borderRadius: "6px",
                      padding: "10px",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <Typography style={{ marginRight: 10 }}>
                          ШАГ {index + 1}
                        </Typography>{" "}
                        {free(index) && (
                          <Chip label="Бесплатно" color="secondary" />
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        height: "100px",
                        width: "50%",
                        overflow: "hidden",
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      {step.picture && (
                        <img
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                          src={step.picture.url}
                        />
                      )}
                    </Box>

                    <Typography variant="caption">{step.text}</Typography>
                  </Box>
                </Box>
              </GridItem>
            ))}
          </GridDropZone>
        </Box>
      </GridContextProvider>
    </Box>
  );
};
