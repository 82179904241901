import {useMutation} from "@apollo/client";
import {
  ToggleUserPartnership
} from "../../graphql/mutations/promoters/ToggleUserPartnership/__generated__/ToggleUserPartnership";
import {
  TOGGLE_USER_PARTNERSHIP
} from "../../graphql/mutations/promoters/ToggleUserPartnership";
import {GET_PROMOTER_BY_ADMIN} from "../../graphql/queries/promoters/getPromoterByAdmin";


export const useToggleUserPartnership = (id:string) => {
  const [toggleUserPartnership, { loading, error }] = useMutation<
    ToggleUserPartnership
    >(TOGGLE_USER_PARTNERSHIP, {
    onError: (err) => console.error(err),
    refetchQueries: [
      {
        query: GET_PROMOTER_BY_ADMIN,
        variables: {
          id:id
        },
      },
    ],
  });

  return {
    toggleUserPartnership,
    loading,
    error,
  };
};
