import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GET_JOB } from "../graphql";
import { CreateJob_createJob_job as IJob } from "../graphql/mutations/jobs/CreateJob/__generated__/CreateJob";
import {
  GetJob,
  GetJobVariables,
  GetJob_getJob_Job_Gql,
} from "../graphql/queries/jobs/GetJob/__generated__/GetJob";

interface EditJobParams {
  id: string;
}

interface EditJobState {
  Job?: IJob;
  showCreateNotification?: boolean;
}

export const useFetchJobFromRoute = () => {
  const { id } = useParams<EditJobParams>();
  const location = useLocation<EditJobState>();
  const [processing, setProcessing] = useState(true);
  const [job, setJob] = useState<IJob | null>(null);
  const [getJob, { error, data }] = useLazyQuery<GetJob, GetJobVariables>(
    GET_JOB,
    {
      onError: (err) => console.error(err),
    }
  );

  useEffect(() => {
    if (location.state?.Job) {
      setJob(location.state.Job);
      setProcessing(false);
    } else {
      getJob({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getJob) {
      setJob(data.getJob as GetJob_getJob_Job_Gql);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    job,
    showCreateNotification: location.state?.showCreateNotification,
  };
};
