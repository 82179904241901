import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, Radio, RadioGroup,
  Typography,
} from "@material-ui/core";
import {Field, Form, Formik, FormikErrors} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  GetReviews_getReviews
} from "../../../graphql/queries/reviews/GetReviews/__generated__/GetReviews";
import * as Yup from "yup";
import {
  FormikFieldMultipleAutocomplete,
  MyTextField,
  NotificationToast,
  PictureUpload,
} from "../../../components";
import {FileType, IPicUpload} from "../../../lib";
import {useMutation} from "@apollo/client";
import {
  UpdateReview,
  UpdateReviewVariables,
} from "../../../graphql/mutations/reviews/UpdateReview/__generated__/UpdateReview";
import {CREATE_REVIEW, GET_REVIEWS, UPDATE_REVIEW} from "../../../graphql";
import {
  CreateReview,
  CreateReviewVariables,
} from "../../../graphql/mutations/reviews/CreateReview/__generated__/CreateReview";
import {convertEnumToArray, getFileNameFromPublicId} from "../../../utils";
import {AppLanguage, ReviewCategory} from "../../../graphql/globalTypes";
import {appLanguages} from "../../../lib/constants";

interface EditReviewProps {
  open: boolean;
  onClose: () => void;
  review: GetReviews_getReviews | null;
}

export const EditReview: React.FC<EditReviewProps> = ({
  open,
  onClose,
  review,
}) => {
  const { t } = useTranslation();
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const [enableValidation, setEnableValidation] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [update, { loading: uLoading }] = useMutation<
    UpdateReview,
    UpdateReviewVariables
  >(UPDATE_REVIEW, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REVIEWS,
      },
    ],
  });
  const [create, { loading: cLoading }] = useMutation<
    CreateReview,
    CreateReviewVariables
  >(CREATE_REVIEW, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REVIEWS,
      },
    ],
  });

  useEffect(() => {
    if (!open) {
      setPicUpload(null);
      setServerError(null);
    }
  }, [open]);

  const initialValues: FormValues = {
    firstName: review?.firstName ?? "",
    lastName: review?.lastName ?? "",
    position: review?.position ?? "",
    text: review?.text ?? "",
    categories: review?.categories ?? [],
    language: review?.language ?? AppLanguage.ru,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Required")),
    lastName: Yup.string().required(t("Required")),
    position: Yup.string().required(t("Required")),
    text: Yup.string().required(t("Required")),
    categories: Yup.array().min(1, "Select at least one category"),
  });

  async function handleSubmit(
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    let picture: any | null;
    if (picUpload) {
      picture = {
        publicId: picUpload.public_id,
        url: picUpload.url,
        filename: getFileNameFromPublicId(picUpload.public_id),
        type: FileType.IMAGE,
      };
    }

    try {
      if (!review) {
        await create({
          variables: {
            input: { ...values, ...(picture ? { avatar: picture } : {}) },
          },
        });
      } else {
        await update({
          variables: {
            input: {
              id: review.id,
              ...values,
              ...(picture ? { avatar: picture } : {}),
            },
          },
        });
      }
    } catch (err) {
      setServerError(err as any);
    } finally {
      onClose();
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{review ? t("Edit review") : t("Add review")}</DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={(values, { setErrors, setSubmitting }) =>
          handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({ dirty, submitForm, values, setFieldValue }) => (
          <Form>
            <>
              <DialogContent>
                <PictureUpload
                  imageUrl={review?.avatar?.url}
                  picUpload={picUpload}
                  setPicUpload={setPicUpload}
                  rootStyles={{
                    height: "200px",
                    width: "200px",
                    overflow: "hidden",
                  }}
                />
                <Box height="10px"/>
                <Typography variant="body2" color="textSecondary">
                  {t("Language")}
                </Typography>
                <RadioGroup row
                            value={values.language}
                            onChange={(event, value)=>setFieldValue("language", value)} >
                  {convertEnumToArray(AppLanguage).map(lng=>
                    <FormControlLabel
                      value={lng}
                      control={<Radio />}
                      label={appLanguages[lng]}
                      key={lng} />)}
                </RadioGroup>

                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("firstName")}
                  name="firstName"
                  style={{ marginBottom: 20, marginTop: 10 }}
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("lastName")}
                  name="lastName"
                  style={{ marginBottom: 20 }}
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("position")}
                  name="position"
                  style={{ marginBottom: 20 }}
                />

                <Field
                  component={MyTextField}
                  multiline
                  rows={4}
                  fullWidth
                  label={t("text")}
                  name="text"
                  style={{ marginBottom: 20 }}
                />
                <Typography variant="h6">Категории</Typography>

                <FormikFieldMultipleAutocomplete
                  name="categories"
                  values={values.categories}
                  getOptionLabel={(option) => option}
                  options={convertEnumToArray(ReviewCategory)}
                  placeholder={"Выбери хотя бы 1 категорию"}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={cLoading || uLoading} onClick={onClose}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={cLoading || uLoading || !values.categories.length}
                >
                  {t("Save")}
                  {(cLoading || uLoading) && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>

      {serverError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Dialog>
  );
};

interface FormValues {
  firstName: string;
  lastName: string;
  position: string;
  text: string;
  categories: ReviewCategory[];
  language?: AppLanguage | null;
}
