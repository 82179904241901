import { useQuery, useReactiveVar } from "@apollo/client";
import { Box, IconButton, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import {
  AddNewButton,
  MyTable,
  NotificationToast,
  TextSearch,
} from "../../../components";
import { GET_COMPANIES } from "../../../graphql";
import {
  GetCompaniesFilter,
  PaginationOptionsArgs,
} from "../../../graphql/globalTypes";
import {
  GetCompanies,
  GetCompaniesVariables,
  GetCompanies_getCompanies_companies,
} from "../../../graphql/queries/company/getCompanies/__generated__/GetCompanies";
import { useSetHeadbarTitle } from "../../../hooks";
import { HeadCell } from "../../../lib";
import { capitalizeFirstLetters } from "../../../utils";
import { EditCompany } from "./components";

interface CompaniesViewProps {}

export const CompaniesView: React.FC<CompaniesViewProps> = ({}) => {
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  useSetHeadbarTitle(t("Companies"));
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<GetCompaniesFilter>({ search: "" });
  const [currentCompany, setCurrentCompany] =
    useState<GetCompanies_getCompanies_companies | null>(null);
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "name",
        sortBy: "asc",
      },
    });
  const { data, loading, error } = useQuery<
    GetCompanies,
    GetCompaniesVariables
  >(GET_COMPANIES, {
    variables: {
      paginationOptions,
      filter,
    },
    fetchPolicy: "network-only",
  });

  const mapCompaniesToRows = useMemo(() => {
    if (!data?.getCompanies.companies.length) return [];

    const arr = data.getCompanies.companies.map((company) => ({
      id: company.id,
      name: (
        <Typography variant="subtitle2">
          {capitalizeFirstLetters(company.name)}
        </Typography>
      ),
      nameAbbreviations: (
        <Typography variant="subtitle2">
          {company.nameAbbreviations.join(", ")}
        </Typography>
      ),
      referrals: (
        <Box>
          {company.referrals?.map((ref) => (
            <Typography>{ref.firstName + " " + ref.lastName}</Typography>
          ))}
        </Box>
      ),
      salaryFactor: (
        <Typography variant="subtitle2">{company.salaryFactor}</Typography>
      ),
      edit: (
        <IconButton onClick={() => handleOpen(company)}>
          <Edit />
        </IconButton>
      ),
    }));
    return arr;
  }, [data?.getCompanies.companies]);

  const handleOpen = (company: GetCompanies_getCompanies_companies | null) => {
    setOpen(true);
    setCurrentCompany(company);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentCompany(null);
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilter({ search: v });
    }
  };

  const handleTextSearchReset = () => {
    setFilter({ search: "" });
  };

  return (
    <>
      <Box
        zIndex={100}
        display="flex"
        flexWrap="wrap"
        width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
        justifyContent="flex-start"
        position="fixed"
      >
        <TextSearch
          rootStyle={{ flexBasis: "40%" }}
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"Name, name abbreviations..."}
        />
      </Box>
      <Box width="100%" marginY={2}>
        <MyTable
          loading={loading}
          total={data?.getCompanies.meta.totalItems ?? 0}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          headCells={headCells}
          values={mapCompaniesToRows}
          toolbarRightButton={
            <AddNewButton
              onClick={() => handleOpen(null)}
              title={t("Add new company")}
            />
          }
          bulkActions={<></>}
          sortFields={[]}
          hideCheck
        />
      </Box>

      {Boolean(error) && (
        <NotificationToast
          message={t("SOMETHING_WENT_WRONG_MESSAGE")}
          horizontal="center"
          vertical="top"
          severity="error"
        />
      )}

      <EditCompany
        open={open}
        onClose={handleClose}
        company={currentCompany}
        paginationOptions={paginationOptions}
        filter={filter}
      />
    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "name",
    align: "left",
  },
  {
    label: "nameAbbreviations",
    align: "left",
  },
  {
    label: "referrals",
    align: "left",
  },
  {
    label: "salaryFactor",
    align: "right",
  },
  {
    label: "edit",
    align: "right",
  },
];
