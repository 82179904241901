import { gql } from "@apollo/client";
import { ArticleCategoryFragment } from "../../../fragments";

export const CREATE_ARTICLE_CATEGORY = gql`
  mutation CreateArticleCategory($input: CreateArticleCategoryInput!) {
    createArticleCategory(input: $input) {
      category {
        ...ArticleCategory
      }
      error {
        name
        message
      }
    }
  }
  ${ArticleCategoryFragment}
`;
