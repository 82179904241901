import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useCommonStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      onHover: {
        transition: "all 0.2s ease",
        transform: "translateY(0px)",
        "&:hover": {
          boxShadow: "0 7px 19px -1px rgb(172 172 179 / 50%)",
          transform: "translateY(-6px)",
        },
      },
      sectionWithSticky__scrollable: {
        flex: 1,
        padding: 20,
      },
      sectionWithSticky__sticky: {
        flex: 1,
        position: "sticky",
        top: "55px",
        height: "calc(100vh - 55px)",
        padding: 20,
        "& #bottomBtn": {
          position: "absolute",
          bottom: "20px",
          right: "20px",
        },
      },
      sectionWithSticky: {
        display: "flex",
      },

      pdfViewerWrapper: {
        "& container": {
          width: "100% !important",
          height: "auto !important",
        },
      },

      formikSelect: {
        "& .MuiSelect-root": {
          background: "#fff",
          boxShadow: "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
          borderRadius: "4px",
          padding: "10px",
        },
      },
    }),
  { index: 1 }
);
