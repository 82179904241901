import {useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  GetAffiliates_getAffiliates_affiliates
} from "../graphql/queries/affiliate/GetAffiliates/__generated__/GetAffiliates";
import {GET_AFFILIATE_BY_ID} from "../graphql/queries/affiliate/GetAffiliateById";
import {
  GetAffiliateById,
  GetAffiliateByIdVariables
} from "../graphql/queries/affiliate/GetAffiliateById/__generated__/GetAffiliateById";

interface EditAffiliateParams {
  id: string;
}

interface EditAffiliateState {
  affiliate?: GetAffiliates_getAffiliates_affiliates;
  showCreateNotification?: boolean;
}

export const useFetchAffiliateFromRoute = () => {
  const { id } = useParams<EditAffiliateParams>();
  const location = useLocation<EditAffiliateState>();
  const [processing, setProcessing] = useState(true);
  const [affiliate, setAffiliate] = useState<GetAffiliates_getAffiliates_affiliates | null>(null);
  const [getAffiliate, { error, data }] = useLazyQuery<GetAffiliateById, GetAffiliateByIdVariables>(
    GET_AFFILIATE_BY_ID,
    {
      onError: (err) => console.error(err),
    }
  );

  useEffect(() => {
    if (location.state.affiliate) {
      setAffiliate(location.state.affiliate);
      setProcessing(false);
    } else {
      getAffiliate({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getAffiliateById) {
      setAffiliate(data.getAffiliateById);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    affiliate,
    showCreateNotification: location.state.showCreateNotification
  };
};
