import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@material-ui/core";
import { EmailOutlined as EmailIcon } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { MyTextField, NotificationToast } from "../../../components";
import {
  useForgotPassword,
  useIsDesktopView,
  useLoginRoute,
} from "../../../hooks";

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { updateSection } = useLoginRoute();
  const [enableValidation, setEnableValidation] = useState(false);
  const { forgotPassword, loading, error } = useForgotPassword();
  const [submitted, setSubmitted] = useState(false);
  const isDesktopView = useIsDesktopView();

  useEffect(() => {
    return () => setSubmitted(false);
  }, []);

  const initialValues: FormValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t("Invalid email")).required(t("Required")),
  });

  const handleForgotPassword = async (
    { email }: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await forgotPassword({ variables: { email } });
      setSubmitted(true);
    } finally {
      setSubmitting(false);
    }
  };

  const forgotPasswordMarkup = (
    <>
      <Typography color="textSecondary" variant="caption">
        {t("Enter email to reset password")}
      </Typography>

      <Box marginTop={4} marginBottom={6}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async ({ email }, { setSubmitting }) =>
            handleForgotPassword({ email }, setSubmitting)
          }
        >
          {({ values, submitForm }) => (
            <Form>
              <>
                <Field
                  component={MyTextField}
                  type="email"
                  fullWidth
                  placeholder={t("Email")}
                  name="email"
                  style={{ marginBottom: 20 }}
                  icon={<EmailIcon />}
                />

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={!values.email.length || loading}
                >
                  {t("Reset password")}
                  {loading && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );

  const successMarkup = (
    <Box marginBottom={4}>
      <Typography color="textSecondary" variant="caption">
        {t("Forgot Password Link Sent")}
      </Typography>
    </Box>
  );

  return (
    <Box>
      {submitted ? successMarkup : forgotPasswordMarkup}
      <Box position="absolute" bottom={20} right={20}>
        <Link
          color="secondary"
          style={{ cursor: "pointer" }}
          onClick={() => updateSection("login")}
        >
          <Typography variant="caption">{t("Go back to Login")}</Typography>
        </Link>
      </Box>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </Box>
  );
};

interface FormValues {
  email: string;
}
