import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
} from "@material-ui/icons";

interface AlignButtonGroupProps {
  alignment: string;
  handleAlignment: (_e: any, newAlignment: string | null) => void;
}

export const AlignButtonGroup: React.FC<AlignButtonGroupProps> = ({
  alignment,
  handleAlignment,
}) => {
  return (
    <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeft />
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenter />
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRight />
      </ToggleButton>
      <ToggleButton value="justify" aria-label="justified">
        <FormatAlignJustify />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
