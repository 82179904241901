import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const ME = gql`
  query Me {
    me {
      ...User
      id
    }
  }
  ${UserFragment}
`;
