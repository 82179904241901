import React from "react";
import { useTranslation } from "react-i18next";
import { SelectButton } from "../../../../components";
import { convertEnumToArray } from "../../../../utils";
import {AppLanguage, PublishStatus} from "../../../../graphql/globalTypes";
import {Box} from "@mui/system";

interface FilterByStatusProps {
  status: PublishStatus | "ALL";
  setStatus: React.Dispatch<React.SetStateAction<PublishStatus | "ALL">>;
  language: AppLanguage | "ALL";
  setLanguage: React.Dispatch<React.SetStateAction<AppLanguage | "ALL">>;
}

export const Filters: React.FC<FilterByStatusProps> = ({
  status,
  setStatus,
  language,
  setLanguage
}) => {
  const { t } = useTranslation();

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as any);
  };
  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as any);
  };

  return (
    <Box>
      <SelectButton
      squared
      onChange={handleStatusChange}
      value={status}
      options={[...convertEnumToArray(PublishStatus), "ALL"]}
      label={t("Status")}
      rootStyle={{width:"200px"}}
    />
      <SelectButton
        squared
        onChange={handleLanguageChange}
        value={language}
        options={[...convertEnumToArray(AppLanguage), "ALL"]}
        label={t("Language")}
        rootStyle={{width:"200px"}}
      />
    </Box>
  );
};
