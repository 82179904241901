import { gql } from "@apollo/client";
import {FileFragment} from "../../../fragments";

export const GET_EXPERT_COMPANIES = gql`
    query GetExpertCompanies {
        getExpertCompanies {
            id
            name
            logo {
                ...File
            }
        }
    }
    ${FileFragment}
`;
