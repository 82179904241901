import { useQuery, useReactiveVar } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import { Fallback, SelectButton, TextSearch } from "../../../components";
import { GET_ADMIN_SUBSCRIPTION_PLANS } from "../../../graphql";
import { GetSubscriptionsInput } from "../../../graphql/globalTypes";
import {
  GetAdminSubscriptionPlans,
  GetAdminSubscriptionPlans_getAdminSubscriptionPlans,
} from "../../../graphql/queries/subscriptions/GetAdminSubscriptionPlans/__generated__/GetAdminSubscriptionPlans";
import { SubscriptionStatus } from "../../../lib";
import { convertEnumToArray } from "../../../utils";

interface SubscriptionsFilterBarProps {
  filterParams: GetSubscriptionsInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetSubscriptionsInput>>;
}

export const SubscriptionsFilterBar: React.FC<SubscriptionsFilterBarProps> = ({
  filterParams,
  setFilterParams,
}) => {
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  const [plans, setPlans] = useState<
    GetAdminSubscriptionPlans_getAdminSubscriptionPlans[]
  >([]);
  const { data, loading, error } = useQuery<GetAdminSubscriptionPlans>(
    GET_ADMIN_SUBSCRIPTION_PLANS
  );

  useEffect(() => {
    if (data) {
      setPlans(data.getAdminSubscriptionPlans);
    }
  }, [data]);

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            status: undefined,
          }
        : {
            status: event.target.value as string,
          }),
    }));
  };

  const mapPlanIdToName = useMemo(() => {
    if (plans && filterParams.planId) {
      return plans.find(({ plan }) => plan.id === filterParams.planId)!.plan
        .title;
    } else {
      return "all";
    }
  }, [filterParams.planId, plans]);

  const handlePlanChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            planId: undefined,
          }
        : {
            planId: plans!.find(
              ({ plan }) => event.target.value === plan.title
            )!.plan.id,
          }),
    }));
  };
  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      couponCode: undefined,
    }));
  };

  if (error)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  return (
    <Box
      zIndex={100}
      display="flex"
      width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
      justifyContent="stretch"
      position="fixed"
    >
      <SelectButton
        squared
        onChange={handleStatusChange}
        value={filterParams.status ?? "all"}
        options={[...convertEnumToArray(SubscriptionStatus), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Status")}
      />
      <SelectButton
        loading={loading}
        squared
        noOptionTranslation
        onChange={handlePlanChange}
        value={mapPlanIdToName}
        options={[...(plans?.map((c) => c.plan.title) ?? []), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Plan")}
      />
      <TextSearch
        rootStyle={{ flex: 1 }}
        onClick={handleTextSearchChange}
        onReset={handleTextSearchReset}
        hint="user email, first name, last name"
      />
    </Box>
  );
};
