import {Button, Dialog, DialogContent, DialogTitle, IconButton,} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {NotificationToast} from "../../../components";
import {useDeleteExpert} from "../../../hooks/mutations/experts/useDeleteExpert";

interface DeleteExpertDialogProps {
  productId:string
}

export const DeleteExpertDialog: React.FC<DeleteExpertDialogProps> = ({productId}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const {deleteExpert,loading,error}=useDeleteExpert()

  const handleDelete=async ()=>{
    const res =await deleteExpert({
      variables:{
        id:productId
      }
    })
    if(res.data?.deleteExpert){
      handleClose()
    }
  }
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon color="primary"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle style={{width: "400px"}}>
          {t("Delete Expert")}
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
          </Box>
          <Box textAlign="center" fontSize="16px">{t("Do you really want to delete expert?")}</Box>
        </DialogContent>
        <Box display="flex" margin="20px" gap="30px" justifyContent="center">
          <Button disabled={loading} onClick={handleClose} variant="contained" color="primary">
            {t("Cancel")}
          </Button>
          <Button disabled={loading} onClick={handleDelete} variant="contained" autoFocus>
            {t("Delete")}
          </Button>
        </Box>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};