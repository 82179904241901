import { useMutation } from "@apollo/client";
import { BULK_ARTICLES_UPDATE } from "../../graphql";
import {
  BulkArticlesUpdate,
  BulkArticlesUpdateVariables,
} from "../../graphql/mutations/articles/BulkArticlesUpdate/__generated__/BulkArticlesUpdate";

export const useBulkArticleUpdate = () => {
  const [update, { loading, error }] = useMutation<
    BulkArticlesUpdate,
    BulkArticlesUpdateVariables
  >(BULK_ARTICLES_UPDATE, {
    onError: (err) => console.error(err),
  });

  return { loading, error, update };
};
