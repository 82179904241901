import { gql } from "@apollo/client";
import { EnumFragment } from "../../../fragments";

export const UPDATE_ENUM = gql`
  mutation UpdateEnum($input: UpdateEnumInput!) {
    updateEnum(input: $input) {
      error {
        name
        message
      }
      enum {
        ...Enum
      }
    }
  }
  ${EnumFragment}
`;
