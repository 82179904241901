import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useCategoriesStyle } from "../styles";
import noPhoto from "../../../assets/noPhoto.jpg";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import { useCommonStyle } from "../../../styles/commonStyles";
import { useTranslation } from "react-i18next";
import { DeleteButton, NotificationToast } from "../../../components";
import {
  useDeleteJobCategory,
  useIsDesktopView,
} from "../../../hooks";
import { GetJobCategories_getJobCategories } from "../../../graphql/queries/jobs/GetJobCategories/__generated__/GetJobCategories";

interface JobCategoryCardProps {
  category: GetJobCategories_getJobCategories;
  onClick: () => void;
}

export const JobCategoryCard: React.FC<JobCategoryCardProps> = ({
  category,
  onClick,
}) => {
  const classes = useCategoriesStyle();
  const isDesktopView = useIsDesktopView();
  const commonClasses = useCommonStyle();
  const { t } = useTranslation();
  const { loading, error, deleteCategory } = useDeleteJobCategory();

  async function handleDelete(): Promise<void> {
    await deleteCategory({ variables: { id: category.id } });
  }

  return (
    <>
      <Card
        style={{ display: "flex", height: "100%", cursor: "default" }}
        className={commonClasses.onHover}
      >
        <Box display="flex" flexDirection="column" flex={2}>
          <CardContent style={{ flex: "1 0 auto" }}>
            <Typography component="h6" variant="h6">
              {category.name}
            </Typography>
            <Typography component="h6" variant="body1">
              {category.engTrans}
            </Typography>
            {/* <Typography
              variant="subtitle1"
              color="textSecondary"
              noWrap={false}
            >
              {t("Total jobs")}: {category.jobFunctionsIds.length}
            </Typography> */}
          </CardContent>
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={1}
            paddingBottom={1}
          >
            <IconButton onClick={onClick}>
              <EditIcon />
            </IconButton>
            <DeleteButton loading={loading} deleteAction={handleDelete} />
          </Box>
        </Box>

        <CardMedia
          style={{ width: "100%", flex: 1 }}
          image={category.logo ? category.logo.url : noPhoto}
        />
      </Card>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </>
  );
};
