import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../graphql";
import {
  GetUsers,
  GetUsersVariables,
} from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";

export const useGetUsers = (variables: GetUsersVariables) => {
  const { data, loading, error } = useQuery<GetUsers, GetUsersVariables>(
    GET_USERS,
    {
      onError: (err) => console.error(err),
      variables,
    }
  );

  return { data, loading, error };
};
