import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationToast } from "../../../components";
import { useSendLinkByEmail } from "../../../hooks";
import { GetPromoterByAdmin_getPromoterByAdmin_promoter } from "../../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface EmailMessageProps {
  isPartner?: boolean;
  action: string;
  user: Me_me | GetPromoterByAdmin_getPromoterByAdmin_promoter;
  open: boolean;
  onClose: () => void;
  setEmailSent: (s: boolean) => void;
}

export const EmailMessage: React.FC<EmailMessageProps> = ({
  isPartner,
  action,
  user,
  open,
  onClose,
  setEmailSent,
}) => {
  const { t } = useTranslation();
  const { error, loading, send } = useSendLinkByEmail(action);

  const handleClick = async () => {
    setEmailSent(false);
    const res = await send({
      variables: {
        userId: user.id,
        isPartner,
      },
    });
    if (res.data) {
      setEmailSent(true);
    }

    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t(action)}</DialogTitle>
        <DialogContent>{t("confirmAction")}</DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t("Cancel")}</Button>
          <Button
            disabled={loading}
            onClick={handleClick}
            color="primary"
            variant="contained"
          >
            {t("Ok")}
            {loading && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={12}
                color="secondary"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
