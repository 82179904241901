import {Box, IconButton, Typography} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useHistory} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import {
  GetAffiliates_getAffiliates_affiliates
} from "../../graphql/queries/affiliate/GetAffiliates/__generated__/GetAffiliates";
import {PaginationOptionsArgs, PublishStatus} from "../../graphql/globalTypes";
import {MyTable} from "../../components";
import {HeadCell} from "../../lib";
import {DeleteAffiliateDialog} from "./DeleteAffiliateDialog";

interface Props {
  loading: boolean;
  affiliates: GetAffiliates_getAffiliates_affiliates[];
  totalAffiliates: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const AffiliateTable: React.FC<Props>
  = ({
       loading,
       totalAffiliates,
       affiliates,
       paginationOptions,
       setPaginationOptions,
     }) => {
  const {t} = useTranslation();
  const history = useHistory();
  const handleEditClick = (id: string, affiliate: GetAffiliates_getAffiliates_affiliates) => {
    history.push({
      pathname: `/affiliates/edit/${id}`,
      state: affiliate
    })
  }
  const mapAffiliatesToRows = useMemo(() => {
    if (!affiliates?.length) return [];
    const arr = affiliates.map((affiliate, index) => ({
      id: affiliate.id,
      number: index + 1,
      name: (
        <Box>
          <Typography style={{fontWeight: 500, minWidth: "180px"}}>
            {affiliate.name}
          </Typography>
        </Box>),
      action: (
        <Box minWidth="120px">
          <IconButton onClick={() => handleEditClick(affiliate.id, affiliate)}>
            {affiliate.status === PublishStatus.MODERATION
              ? <EditIcon
                color="secondary"
                style={{
                  animation: "pulse 1.5s infinite ease-in-out"
                }}/>
              : <EditIcon color="primary"/>
            }
          </IconButton>
          <DeleteAffiliateDialog id={affiliate.id}/>
        </Box>
      ),
      status: (
        <Typography
          style={{fontSize: "14px", fontWeight: 500, minWidth: "120px"}}
          color={affiliate.status === PublishStatus.MODERATION ? "secondary" : "inherit"}>
          {t(affiliate.status)}
        </Typography>
      ),
      email: (
        <Box>
          {affiliate.email}
        </Box>
      ),
      motivationalText: (
        <Box fontSize="0.9em" fontWeight={500}>
          {affiliate.affiliateApplicationText}
        </Box>
      ),
    }));
    return arr;
  }, [affiliates]);

  return (
    <>
      <Box width="100%">
        <MyTable
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          total={totalAffiliates}
          loading={loading}
          sortFields={[]}
          headCells={headCells}
          values={mapAffiliatesToRows}
          hideCheck
          bulkActions={<></>}/>
      </Box>

    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "number",
    align: "left",
  },
  {
    label: "name",
    align: "left",
  },
  {
    label: "action",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "email",
    align: "left",
  },
  {
    label: "motivationalText",
    align: "left",
  },
];