import { gql } from "@apollo/client";
import {FileFragment} from "../../../fragments";

export const GET_PARTNER_COMPANY_CATEGORIES = gql`
    query GetPartnerCompanyCategories {
        getPartnerCompanyCategories {
            id
            name
            logo {
                ...File
            }
        }
    }
    ${FileFragment}
`;
