import { gql } from "@apollo/client";
import { ReferralFragment } from "../../../fragments";

export const UPDATE_REFERRAL = gql`
  mutation UpdateReferral($input: UpdateReferralInput!) {
    updateReferral(input: $input) {
      ...Referral
    }
  }
  ${ReferralFragment}
`;
