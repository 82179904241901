import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useUsersStyles = makeStyles((theme: Theme) =>
  createStyles({
    summary__paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(10),
      margin: "auto",
      maxWidth: 500,
      position: "relative",
      paddingTop: theme.spacing(10),
      textAlign: "center",
    },
    summary__avatar: {
      color: "#b1b1b1",
      backgroundColor: "#f6f7fe",
      width: theme.spacing(15),
      height: theme.spacing(15),
      transform: "translateY(-50%)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      margin: "auto",
      boxShadow: "0px 1px 5px -3px #000",
    },
    summary__overline: {
      fontSize: "1rem",
      color: theme.palette.text.secondary,
    },
    summary__editBtn: {
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    info__paper: {
      padding: 10,
      "& .MuiChip-root.Mui-disabled": {
        opacity: 1,
        "& .MuiChip-deleteIcon": {
          display: "none",
        },
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.65)",
      },
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "rgba(0, 0, 0, 0)",
        },
    },
  })
);
