import { useReactiveVar } from "@apollo/client";
import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import {SelectButton, TextSearch} from "../../../components";
import {GetUsersInput, UserRole} from "../../../graphql/globalTypes";
import { convertEnumToArray } from "../../../utils";

interface UsersFilterBarProps {
  filterParams: GetUsersInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetUsersInput>>;
}

export const UsersFilterBar: React.FC<UsersFilterBarProps> = ({
  filterParams,
  setFilterParams,
}) => {
  const drawerOpen = useReactiveVar(drawerOpenVar);
  const { t } = useTranslation();

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            roles: undefined,
          }
        : {
            roles: [event.target.value as string],
          }),
    }));
  };

  const handleFellowChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            isFellow: undefined,
          }
        : {
            isFellow: event.target.value === "Have" ? true : false,
          }),
    }));
  };

  const castFellow = (isFellow?: boolean | null) => {
    if (isFellow == undefined) {
      return "all";
    }
    return isFellow ? "Have" : "Not have";
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  return (
    <Box
      zIndex={100}
      display="flex"
      width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
      justifyContent="stretch"
      position="fixed"
    >
      <SelectButton
        squared
        onChange={handleRoleChange}
        value={filterParams.roles?.[0] ?? "all"}
        options={[...convertEnumToArray(UserRole), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Role")}
      />
      <SelectButton
        squared
        onChange={handleFellowChange}
        value={castFellow(filterParams.isFellow)}
        options={["Have", "Not have", "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Fellowship")}
      />
      <TextSearch
        rootStyle={{ flexBasis: "40%" }}
        onClick={(handleTextSearchChange)}
        onReset={handleTextSearchReset}
        hint={"email, name"}
      />
    </Box>
  );
};
