import {useQuery} from "@apollo/client";
import {Box} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AtomProgress, NotificationToast} from "../../components";
import {GET_ADMIN_SUBSCRIPTION_PLANS} from "../../graphql";
import {
  GetAdminSubscriptionPlans,
  GetAdminSubscriptionPlans_getAdminSubscriptionPlans,
  GetAdminSubscriptionPlansVariables,
} from "../../graphql/queries/subscriptions/GetAdminSubscriptionPlans/__generated__/GetAdminSubscriptionPlans";
import {useSetHeadbarTitle} from "../../hooks";
import {PlansTable, SubscriptionPlanDialog} from "./components";
import {PublishStatus} from "../../graphql/globalTypes";

interface SubscriptionPlansProps {}

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("SubscriptionPlans"));
  const [status, setStatus] = useState<PublishStatus | "all">("all");
  const [currentPlan, setCurrentPlan] =
    useState<GetAdminSubscriptionPlans_getAdminSubscriptionPlans | null>(null);

  const [plans, setPlans] = useState<
    GetAdminSubscriptionPlans_getAdminSubscriptionPlans[]
  >([]);
  const { data, loading, error, refetch } = useQuery<
    GetAdminSubscriptionPlans,
    GetAdminSubscriptionPlansVariables
  >(GET_ADMIN_SUBSCRIPTION_PLANS, {
    onError: (err) => console.error(err),
    variables: {},
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.getAdminSubscriptionPlans) {
      setPlans(data.getAdminSubscriptionPlans);
    }
  }, [data]);

  const handleOpen = (
    plan: GetAdminSubscriptionPlans_getAdminSubscriptionPlans
  ) => setCurrentPlan(plan);
  const handleClose = () => setCurrentPlan(null);

  return (
    <>
      <Box padding={3}>
        {loading && (
          <Box
            position="absolute"
            marginTop="5px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <PlansTable
        plans={
          status === "all"
            ? plans
            : plans.filter((plan) => plan.plan.status === status)
        }
        loading={loading}
        refetch={refetch}
        handleOpen={handleOpen}
      />

      <SubscriptionPlanDialog
        plan={currentPlan?.plan ?? null}
        open={!!currentPlan}
        onClose={handleClose}
        refetch={refetch}
      />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
