import { gql } from "@apollo/client";
import { TransactionFragment } from "../../..";

export const FULL_REFUND_SUBSCRIPTION_TRANSACTIONS = gql`
  mutation FullRefundSubscriptionTransactions(
    $input: RefundSubscriptionTransactionsInput!
  ) {
    fullRefundSubscriptionTransactions(input: $input) {
      id
      planId
      userId
      user {
        id
        email
        firstName
        lastName
      }
      status
      lastTimeAmount {
        currency
        value
      }
      startTime
      lastTimeCharge
      nextTimeCharge
      statusUpdateTime
      plan {
        id
        title
        description
        features
        billingCycle
        status
        price {
          value
          currency
        }
        taxData {
          vatCode
          paymentSubject
          paymentMethod
        }
      }
      transactions {
        ...Transaction
      }
      discount {
        percentage
        numberOfCharges
        coupon {
          couponCode
          countUsers
          countTimes
          discountPercentage
        }
      }
    }
  }
  ${TransactionFragment}
`;
