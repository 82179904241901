import {
  TableContainer,
  TableCell,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { PaginationOptionsArgs } from "../../../graphql/globalTypes";
import { HeadCell, SortOrder } from "../../../lib";
import { Empty } from "../../skeletons";
import { useTableStyle } from "../styles";
import { SimpleTableHead } from "./SimpleTableHead";
import {AtomProgress} from "../../loaders";
import {Box} from "@mui/system";

interface SimpleTableProps {
  loading: boolean;
  total: number;
  sortFields: string[];
  headCells: HeadCell[];
  values: any[];
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
  onRowClick: (id: string) => void;
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  headCells,
  sortFields,
  values,
  paginationOptions,
  setPaginationOptions,
  total,
  loading,
  onRowClick,
}) => {
  const classes = useTableStyle();
  const { t } = useTranslation();

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    field: string
  ) => {
    const isAsc =
      paginationOptions.sort!.field === field &&
      paginationOptions.sort!.sortBy === "asc";
    setPaginationOptions((prev) => ({
      ...prev,
      sort: {
        field,
        sortBy: isAsc ? "desc" : "asc",
      },
    }));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPaginationOptions((prev) => ({
      ...prev,
      skip: paginationOptions.limit! * newPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const limit = parseInt(event.target.value, 10);
    setPaginationOptions((prev) => ({ ...prev, skip: 0, limit }));
  };

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer style={{ maxHeight: "65vh" }}>
          <Table className={classes.table} stickyHeader>
            <SimpleTableHead
              order={paginationOptions.sort!.sortBy as SortOrder}
              orderBy={paginationOptions.sort!.field}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              sortFields={sortFields}
            />
            <TableBody>
              {values.map((value) => {
                return (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() => onRowClick(value.id as string)}
                    key={value.id}
                  >
                    {headCells.map((headCell, index) => (
                      <TableCell
                        key={index}
                        align={headCell.align}
                        style={headCell.isBreakWord ? {wordBreak:"break-word", minWidth:"100px"}: undefined}
                      >
                        {value[headCell.label]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && values.length === 0 && (
          <Empty emoji="🙈" title={t("No Articles Found")} />
        )}
        {loading && <Box display="flex" justifyContent="center" marginTop="20px">
          <AtomProgress relative/>
        </Box>}

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={paginationOptions.limit!}
          page={paginationOptions.skip! / paginationOptions.limit!}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      </Paper>
    </>
  );
};
