import { gql } from "@apollo/client";

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
      error {
        name
        message
      }
    }
  }
`;
