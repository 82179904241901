import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { userVar } from "../../../apollo";
import {
  AvatarUpload,
  MyDatePicker,
  MyTextField,
  NotificationToast,
  SelectButton,
} from "../../../components";
import { UpdateMySummaryInput } from "../../../graphql/globalTypes";
import { UpdateMySummaryVariables } from "../../../graphql/mutations/me/UpdateMySummary/__generated__/UpdateMySummary";
import { useUpdateMySummary } from "../../../hooks";
import { Gender } from "../../../lib";
import { convertEnumToArray } from "../../../utils";
import { useUsersStyles } from "../styles";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface EditUserSummaryProps {
  user: Me_me;
}

const defaultBDate = new Date();

export const EditUserSummary: React.FC<EditUserSummaryProps> = ({ user }) => {
  const { t } = useTranslation();
  const classes = useUsersStyles();
  const [input, setInput] = useState<UpdateMySummaryInput>({
    firstName: user.firstName,
    lastName: user.lastName,
    summary: user.summary,
    gender: user.gender ?? "not specified",
    birthDate: user.birthDate ?? defaultBDate,
    picture: {
      source: user.avatar?.url ?? "",
    },
  });
  const [enableValidation, setEnableValidation] = useState(false);
  const { update, loading, error } = useUpdateMySummary();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setInput({
        firstName: user.firstName,
        lastName: user.lastName,
        summary: user.summary,
        gender: user.gender ?? "not specified",
        birthDate: user.birthDate ?? defaultBDate,
        picture: {
          source: user.avatar?.url ?? "",
        },
      });
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues: FormValues = {
    firstName: user.firstName!,
    lastName: user.lastName!,
    summary: user.summary!,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Required")),
    lastName: Yup.string().required(t("Required")),
  });

  const setAvatar = (source: string) => {
    setInput((prev) => ({
      ...prev,
      picture: {
        source,
      },
    }));
  };

  const changeGender = (e: any) => {
    setInput((prev) => ({
      ...prev,
      gender: e.target.value ?? undefined,
    }));
  };

  const changeDate = (e: MaterialUiPickersDate) => {
    setInput((prev) => ({
      ...prev,
      birthDate: e,
    }));
  };

  const handleSubmit = async (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const variables: UpdateMySummaryVariables = {
      input: {
        ...(values.firstName !== user.firstName
          ? { firstName: values.firstName }
          : {}),
        ...(values.lastName !== user.lastName
          ? { lastName: values.lastName }
          : {}),
        ...(values.summary !== user.summary ? { summary: values.summary } : {}),
        ...(input.gender !== user.gender
          ? {
              gender: input.gender,
            }
          : {}),
        ...(input.birthDate !== user.birthDate &&
        input.birthDate !== defaultBDate
          ? { birthDate: input.birthDate }
          : {}),
        ...(input.picture!.source && input.picture!.source !== user.avatar?.url
          ? {
              picture: {
                source: input.picture!.source,
                fileName: `${user.firstName} ${user.lastName}`,
              },
            }
          : {}),
      },
    };

    try {
      const res = await update({ variables });
      userVar(res.data!.updateMySummary);
      handleClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color="primary"
        className={classes.summary__editBtn}
      >
        <EditOutlined />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Edit Profile Summary")}</DialogTitle>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
        >
          {({ submitForm }) => (
            <Form>
              <>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={5}>
                      <AvatarUpload
                        src={input.picture!.source}
                        setAvatar={setAvatar}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("firstName")}
                        name="firstName"
                        style={{ marginBottom: 20 }}
                      />
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("lastName")}
                        name="lastName"
                        style={{ marginBottom: 20 }}
                      />

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <SelectButton
                            options={[
                              ...convertEnumToArray(Gender),
                              "not specified",
                            ]}
                            value={input.gender ?? "not specified"}
                            onChange={changeGender}
                            label={t("Gender")}
                            rootStyle={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MyDatePicker
                            value={input.birthDate}
                            onChange={changeDate}
                            label={t("Birthdate")}
                            rootStyle={{ width: "100%" }}
                            disableFuture
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box marginY={3}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("summary")}
                      name="summary"
                      style={{ marginBottom: 20 }}
                      multiline
                      maxRows={4}
                      rows={2}
                    />
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>{t("Cancel")}</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    fullWidth
                    disabled={loading}
                  >
                    {t("Save")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>

        {error && (
          <NotificationToast
            message={t("ServerNotResponding")}
            horizontal="center"
            severity="error"
            vertical={"bottom"}
          />
        )}
      </Dialog>
    </>
  );
};

interface FormValues {
  firstName: string;
  lastName: string;
  summary: string;
}
