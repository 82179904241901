import {useReactiveVar} from "@apollo/client";
import {Avatar, Box, Paper, Typography,} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {userVar} from "../../../apollo";
import {FullscreenLoader} from "../../../components";
import {
  GetUserById_getUserById as IUser
} from "../../../graphql/queries/users/GetUserById/__generated__/GetUserById";
import {useUsersStyles} from "../../users/styles";
import {PromoterEmail} from "./PromoterEmail";
import {
  GetPromoterByAdmin_getPromoterByAdmin_promoter
} from "../../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";


interface UserSummaryProps {
  promoter?: GetPromoterByAdmin_getPromoterByAdmin_promoter;
  isMe?: boolean;
  setUser?: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const PromoterSummary: React.FC<UserSummaryProps> =
  ({
     promoter,
     isMe,
     setUser,
   }) => {
    const classes = useUsersStyles();
    const {t} = useTranslation();
    const authUser = useReactiveVar(userVar);

    if (!promoter || !authUser) {
      return <FullscreenLoader/>;
    }


    return (
      <Paper
        style={{border: promoter.partnerSettings?.isDeactivated ? "2px solid red" : ""}}
        className={classes.summary__paper}
      >
        <Avatar src={promoter.avatar?.url} className={classes.summary__avatar}/>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="overline" className={classes.summary__overline}>
            {t("promoter")}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          color={promoter.partnerSettings?.isDeactivated ? "error" : "primary"}>
          {promoter.partnerSettings?.isDeactivated ? t("blocked") : t("active")}
        </Typography>
        <Typography variant="h5">
          {`${promoter.firstName} ${promoter.lastName}`}
        </Typography>
        <Typography variant="h6">
          username: {promoter.partnerSettings?.username}
        </Typography>
        <Box height="10px"/>
        <PromoterEmail promoter={promoter}/>
      </Paper>
    );
  };
