import { useMutation } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectButton } from "../../../../components";
import {
  GET_LEARNING_PATH_BY_SLUG,
  UPDATE_LEARNING_PATH,
} from "../../../../graphql";
import {
  UpdateLearningPath,
  UpdateLearningPathVariables,
} from "../../../../graphql/mutations/learningPath/updateLearningPath/__generated__/UpdateLearningPath";
import { GetLearningPathBySlug_getLearningPathBySlug } from "../../../../graphql/queries/learningPath/getLearningBySlug/__generated__/GetLearningPathBySlug";
import { convertEnumToArray } from "../../../../utils";
import {Box} from "@mui/system";
import {FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {AppLanguage, PublishStatus} from "../../../../graphql/globalTypes";
import {appLanguages} from "../../../../lib/constants";

interface UpdateStatusProps {
  learningPath: GetLearningPathBySlug_getLearningPathBySlug;
}

export const UpdateStatus: React.FC<UpdateStatusProps> = ({ learningPath }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<PublishStatus>(
    learningPath.path.status as PublishStatus
  );
  const [language, setLanguage] = useState<AppLanguage>(
    learningPath.path.language as AppLanguage
  );
  const [update, { loading, error }] = useMutation<
    UpdateLearningPath,
    UpdateLearningPathVariables
  >(UPDATE_LEARNING_PATH, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LEARNING_PATH_BY_SLUG,
        variables: {
          slug: learningPath.path.slug,
        },
      },
    ],
  });

  const handleStatusChange = async (e: any) => {
    await update({
      variables: {
        input: {
          id: learningPath.path.id,
          status: e.target.value,
        },
      },
    });

    setStatus(e.target.value as PublishStatus);
  };

  const handleLanguageChange = async (e: any) => {
    await update({
      variables: {
        input: {
          id: learningPath.path.id,
          language: e.target.value,
        },
      },
    });
    setLanguage(e.target.value as AppLanguage);
  };

  return (
    <Box>
      <SelectButton
      label={t("status")}
      rootStyle={{width: "100%", marginBottom:"10px"}}
      options={convertEnumToArray(PublishStatus)}
      value={status}
      onChange={(e) => handleStatusChange(e)}
    />

      <Typography variant="body2" color="textSecondary">
        {t("Language")}
      </Typography>
      <RadioGroup row value={language}
                  onChange={handleLanguageChange} >
        {convertEnumToArray(AppLanguage).map(lng=>
          <FormControlLabel
            value={lng}
            control={<Radio />}
            label={appLanguages[lng]}
            key={lng} />)}
      </RadioGroup>
    </Box>
  );
};
