import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Menu,
  Typography,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationToast } from "../../../components";
import { GetSeoTags_getSeoTags } from "../../../graphql/queries/seotags/getSeoTags/__generated__/GetSeoTags";
import {
  useDeleteSeoTag,
  useSetHeadbarTitle,
  useUpdateSeoTag,
} from "../../../hooks";
import { EditTag, SeoTagFormValues } from "./EditTag";

interface SeoTagCardProps {
  tag: GetSeoTags_getSeoTags;
}

export const SeoTagCard: React.FC<SeoTagCardProps> = ({ tag }) => {
  const { t } = useTranslation();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  useSetHeadbarTitle(t("Seotags"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { error, loading, update } = useUpdateSeoTag();
  const {
    error: deleteError,
    loading: deleteLoading,
    deleteTag,
  } = useDeleteSeoTag();

  const handleOpenMenuFor = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleEditOpen = () => {
    setAnchorEl(null);
    setEditOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditSubmit = async (v: SeoTagFormValues) => {
    await update({ variables: { input: { id: tag.id, ...v } } });
    handleEditClose();
  };

  const handleDelete = async () => {
    await deleteTag({ variables: { id: tag.id } });
    handleDeleteClose();
  };

  return (
    <>
      <Box
        height={"100%"}
        style={{
          background: "white",
          borderRadius: "6px",
          boxShadow: "0px 1px 7px -4px #0000007a",
          padding: "20px",
        }}
      >
        <Box display={"flex"} justifyContent="space-between">
          <a
            href={`https://networkio.io${tag.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography>{`https://networkio.io${tag.url}`}</Typography>
          </a>

          <IconButton onClick={(e) => handleOpenMenuFor(e)}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleEditOpen}>{t("Edit")}</MenuItem>

            <MenuItem onClick={handleDeleteOpen}>{t("Delete")}</MenuItem>
          </Menu>
        </Box>

        <Typography style={{ display: "block", fontWeight: "bold" }}>
          Заголовок
        </Typography>

        <Typography>{tag.title}</Typography>

        <Box height="20px" />

        <Typography style={{ display: "block", fontWeight: "bold" }}>
          Контент
        </Typography>

        <Typography>{tag.description}</Typography>
      </Box>

      <EditTag
        handleClose={handleEditClose}
        handleSubmit={handleEditSubmit}
        open={editOpen}
        tag={tag}
      />

      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Точно удалить?</DialogTitle>
        <DialogActions>
          <Button disabled={deleteLoading} onClick={handleDeleteClose}>
            Отмена
          </Button>
          <Button disabled={deleteLoading} onClick={handleDelete}>
            Да
            {deleteLoading && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={12}
                color="secondary"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {Boolean(error || deleteError) && (
        <NotificationToast
          message={t("SOMETHING_WENT_WRONG_MESSAGE")}
          horizontal="center"
          vertical="top"
          severity="error"
        />
      )}
    </>
  );
};
