export const apiDomain =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5555"
    : `${process.env.REACT_APP_API_ENTRYPOINT}`;

export const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5555/api"
    : `${process.env.REACT_APP_API_ENTRYPOINT}/api`;

export const refreshUrl =
  process.env.NODE_ENV === "development"
    ? `http://localhost:5555/refresh_token`
    : `${process.env.REACT_APP_API_ENTRYPOINT}/refresh_token`;
