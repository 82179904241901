import {useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  GetExperts_getExperts_experts
} from "../../../graphql/queries/experts/getExperts/__generated__/GetExperts";
import {GET_EXPERT_BY_ID} from "../../../graphql/queries/experts/getExpertById";
import {
  GetExpertById,
  GetExpertByIdVariables
} from "../../../graphql/queries/experts/getExpertById/__generated__/GetExpertById";

interface EditExpertParams {
  id: string;
}

interface EditExpertState {
  expert?: GetExperts_getExperts_experts;
  showCreateNotification?: boolean;
}

export const useFetchExpertFromRoute = () => {
  const { id } = useParams<EditExpertParams>();
  const location = useLocation<EditExpertState>();
  const [processing, setProcessing] = useState(true);
  const [expert, setExpert] = useState<GetExperts_getExperts_experts | null>(null);
  const [getExpert, { error, data }] = useLazyQuery<GetExpertById, GetExpertByIdVariables>(
    GET_EXPERT_BY_ID,
    {
      onError: (err) => console.error(err),
    }
  );

  useEffect(() => {
    if (location.state.expert) {
      setExpert(location.state.expert);
      setProcessing(false);
    } else {
      getExpert({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getExpertById) {
      setExpert(data.getExpertById);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    expert,
    showCreateNotification: location.state.showCreateNotification
  };
};
