import { Button, Box, Link } from "@material-ui/core";
import { CellPluginComponentProps, useUiTranslator } from "@react-page/editor";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { defaultMuiButtonState } from "../default/state";
import { MuiButtonState } from "../types/state";

const MuiButtonHtmlRenderer: React.FC<
  CellPluginComponentProps<MuiButtonState>
> = (props) => {
  const { data } = props;
  const { t } = useUiTranslator();

  useEffect(() => {
    props.onChange(defaultMuiButtonState);
  }, []);

  const handleClick = () => {
    if (!data.url) return;

    const link = document.createElement("a");
    if (data.isDownloadable) {
      link.download = data.url;
      link.href = `${data.url}`;
    } else {
      link.href = data.url;
      link.target = "_blank";
    }
    link.click();
    link.remove();
  };

  return (
    <Box display="flex" justifyContent={data.align}>
      <Button
        color={data.color}
        variant={data.variant}
        fullWidth={data.fullWidth}
        onClick={handleClick}
      >
        {data.title}
      </Button>
    </Box>
  );
};

export default MuiButtonHtmlRenderer;
