import {useMutation} from "@apollo/client";
import {UPDATE_EXPERT_ACTIVITY} from "../../../graphql";
import {
  UpdateExpertActivity,
  UpdateExpertActivityVariables
} from "../../../graphql/mutations/experts/updateExpertActivity/__generated__/UpdateExpertActivity";

export const useUpdateExpertActivity = () => {
  const [update, { loading: uLoading }] = useMutation<
    UpdateExpertActivity,
    UpdateExpertActivityVariables
    >(UPDATE_EXPERT_ACTIVITY, {
    onError: (err) => console.error(err),
  });

  return { update, uLoading };
};