import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSetHeadbarTitle} from "../../hooks";
import {useQuery} from "@apollo/client";
import {
  GetBanners,
  GetBannersVariables
} from "../../graphql/queries/banners/getBanners/__generated__/GetBanners";
import {GET_BANNERS} from "../../graphql/queries/banners/getBanners";
import {
  BannerKind,
  GetBannersInput, PublishStatus,
} from "../../graphql/globalTypes";
import {BannerDialog} from "./components/BannerDialog";
import {Box} from "@mui/system";
import {BannersTable} from "./components/BannersTable";
import {SelectButton, TextSearch} from "../../components";
import {convertEnumToArray} from "../../utils";

export const Banners = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Banners"));
  const [input, setInput] = useState<GetBannersInput>({})
  const {data, loading, error} = useQuery<GetBanners, GetBannersVariables
  >(GET_BANNERS, {
    variables: {
      input
    }
  });
  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setInput((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };

  const handleTextSearchReset = () => {
    setInput((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  const handleFilterChange = (
    key: keyof GetBannersInput,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setInput((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  return (
    <Box margin="10px">
      <Box display="flex"
           justifyContent="space-between"
           alignItems="center"
           gap="10px"
      >
        <TextSearch
          isSmall
          rootStyle={{flexBasis: "30%"}}
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"Title, company"}
        />
        <SelectButton
          densed
          noOptionTranslation
          onChange={(e) => handleFilterChange("kind", e)}
          value={input.kind ?? "all"}
          options={[...convertEnumToArray(BannerKind), "all"]}
          rootStyle={{flexBasis: "30%"}}
          label={t("Kind")}
        />

        <SelectButton
          densed
          onChange={(e) => handleFilterChange("status", e)}
          value={input.status ?? "all"}
          options={[...convertEnumToArray(PublishStatus), "all"]}
          rootStyle={{flexBasis: "30%"}}
          label={t("Status")}
        />

        <BannerDialog input={input}/>
      </Box>
      <BannersTable loading={loading}
                    banners={data?.getBanners}
                    input={input}
      />
    </Box>
  );
};

