import { Skeleton } from "@material-ui/lab";
import React from "react";

export const ArticleCategorySkeleton: React.FC = () => {
  return (
    <Skeleton
      variant="rect"
      animation="wave"
      style={{
        borderRadius: 5,
        display: "inline-block",
        width: "100%",
        height: 150,
      }}
    />
  );
};
