import {useQuery} from "@apollo/client";
import {GET_EXPERT_ACTIVITIES} from "../../../graphql";
import {
  GetExpertActivities
} from "../../../graphql/queries/experts/getExpertActivities/__generated__/GetExpertActivities";

export const useGetExpertActivities = () => {
  const { loading, error, data } = useQuery<GetExpertActivities>(
    GET_EXPERT_ACTIVITIES,
    {
      onError: (err) => console.error(err),
    }
  );

  return { loading, error, expertActivities: data?.getExpertActivities };
};