import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import {PromotersInfo} from "./components/PromotersInfo";
import {useSetHeadbarTitle} from "../../hooks";
import {useTranslation} from "react-i18next";
import {AddNewPromoter} from "./components/AddNewPromoter";
import PromotersTable from "./components/PromotersTable";
import {PaginationOptionsArgs, PromotersListFilter,} from "../../graphql/globalTypes";
import {PromotersFilterBar} from "./components/PromotersFilterBar";
import {
  GetPromotersList_getPromotersList_promoters
} from "../../graphql/queries/promoters/getPromotersList/__generated__/GetPromotersList";
import {useGetPromotersList} from "../../hooks/queries/useGetPromotersList";

const Promoters = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Promoters"));
  const [promoters, setPromoters] = useState<GetPromotersList_getPromotersList_promoters[]>([]);
  const [totalPromoters, setTotalPromoters] = useState(0);
  const [filterParams, setFilterParams] = useState<PromotersListFilter>({
    search:undefined
  });
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      },
    });

  const {data,error,loading}=useGetPromotersList({
    paginationOptions,
    query:filterParams
  })

  useEffect(() => {
    if (data) {
      setTotalPromoters(data.getPromotersList.meta.totalItems);
      setPromoters(data.getPromotersList.promoters);
    }
  }, [data]);

  return (
    <Box padding="20px">
      <Box display="flex" justifyContent="center">
        <PromotersFilterBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}/>
      </Box>
      <Box display="flex" justifyContent="end" paddingY={2}>
        <AddNewPromoter/>
      </Box>
      <PromotersInfo/>
      <PromotersTable
        loading={loading}
        promoters={promoters}
        totalPromoters={totalPromoters}
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}/>
    </Box>
  );
};

export default Promoters;