import type { MuiButtonState } from "../types/state";
export const defaultMuiButtonState: MuiButtonState = {
  title: "Click on me",
  color: "primary",
  variant: "contained",
  fullWidth: false,
  align: "center",
  url: "",
  isDownloadable: false,
};
