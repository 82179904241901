import React, {useState} from 'react';
import {Box, Button, CircularProgress} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {NotificationToast} from "../../../components";
import {useTranslation} from "react-i18next";
import {
  useToggleUserPartnership
} from "../../../hooks/mutations/useToggleUserPartnership";
import {
  GetPromoterByAdmin_getPromoterByAdmin_promoter
} from "../../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";

interface LockUnlockSectionProps {
  promoter: GetPromoterByAdmin_getPromoterByAdmin_promoter
}

export const LockUnlockSection = ({promoter}: LockUnlockSectionProps) => {
  const {toggleUserPartnership, loading, error} = useToggleUserPartnership(promoter.id)
  const [isChangedStatus, setIsChangedStatus] = useState(false)
  const {t} = useTranslation();
  const handleUnlockClick = async () => {
    const res= await toggleUserPartnership({
      variables:{
        id:promoter.id
      }
    })
    setIsChangedStatus(false)
    setIsChangedStatus(true)
  }
  return (
    <>
      <Box display="flex" gridColumnGap="20px" marginY="20px">
         <Button
           style={{width:"180px"}}
            onClick={handleUnlockClick}
            disabled={loading}
            variant="contained"
            startIcon={promoter.partnerSettings?.isDeactivated ? <LockOpenIcon/> :<LockIcon/>}
            color="primary">
           {promoter.partnerSettings?.isDeactivated ? "Разблокировать" : "Заблокировать"}
            {loading &&
              <CircularProgress
                thickness={7}
                style={{marginLeft: 10}}
                size={12}
              />
            }
          </Button>
      </Box>
      {isChangedStatus && (
        <NotificationToast
          message={promoter.partnerSettings?.isDeactivated ? t("promoter blocked") : t("promoter unlocked")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
