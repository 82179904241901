import { useReactiveVar } from "@apollo/client";
import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userVar } from "../../apollo";
import { useIsDesktopView } from "../../hooks";
import { logoutUser } from "../../utils";
import { useLayoutStyle } from "../styles";

interface HeaderProfileProps {}

export const HeaderProfile: React.FC<HeaderProfileProps> = ({}) => {
  const user = useReactiveVar(userVar);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const classes = useLayoutStyle();
  const isDesktop = useIsDesktopView();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProfilePage = () => {
    handleClose();
    history.push("/profile");
  };

  const logOut = () => {
    handleClose();
    logoutUser(history);
  };

  if (!user) return null;

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        color="inherit"
        style={{ textTransform: "unset" }}
      >
        <Avatar className={classes.small} style={{ marginRight: 5 }} />
        {isDesktop &&
          _.startCase(_.toLower(user!.firstName + " " + user!.lastName))}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "150px",
          },
        }}
        elevation={10}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={goToProfilePage}>{t("Profile")}</MenuItem>
        <MenuItem onClick={logOut}>{t("Log out")}</MenuItem>
      </Menu>
    </>
  );
};
