import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Field, Form, Formik, FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  MyTextField,
  NotificationToast,
  PictureUpload,
} from "../../../components";
import { GetJobCategories_getJobCategories } from "../../../graphql/queries/jobs/GetJobCategories/__generated__/GetJobCategories";
import {
  useCreateJobCategory,
  useUpdateJobCategory,
} from "../../../hooks";
import { FileType, IPicUpload } from "../../../lib";
import { getFileNameFromPublicId } from "../../../utils";

interface EditJobCategoryProps {
  category: GetJobCategories_getJobCategories | null;
  open: boolean;
  onClose: () => void;
}

export const EditJobCategory: React.FC<EditJobCategoryProps> = ({
  category,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [enableValidation, setEnableValidation] = useState(false);
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const [serverError, setServerError] = useState(null);
  const { update, loading: uLoading, error: uError } = useUpdateJobCategory();
  const { create, loading: cLoading, error: cError } = useCreateJobCategory();

  useEffect(() => {
    if (!open) {
      setPicUpload(null);
      setServerError(null);
    }
  }, [open]);

  const initialValues: FormValues = {
    name: category?.name ?? "",
    engTrans: category?.engTrans ?? "",
    minSalary: category?.minSalary ?? 0,
    maxSalary: category?.maxSalary ?? 0,
    avgSalary: category?.avgSalary ?? 0,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Required")),
    engTrans: Yup.string().required(t("Required")),
    minSalary: Yup.number().required(t("Required")),
    maxSalary: Yup.number().required(t("Required")),
    avgSalary: Yup.number().required(t("Required")),
  });

  async function handleSubmit(
    name: FormValues["name"],
    engTrans:FormValues["engTrans"],
    minSalary: FormValues["minSalary"],
    maxSalary: FormValues["maxSalary"],
    avgSalary: FormValues["avgSalary"],
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    let picture: any | null;
    if (picUpload) {
      picture = {
        publicId: picUpload.public_id,
        url: picUpload.url,
        filename: getFileNameFromPublicId(picUpload.public_id),
        type: FileType.IMAGE,
      };
    }
    try {
      if (!category) {
        const res = await create({
          variables: {
            input: {
              name,
              engTrans,
              minSalary: parseFloat(minSalary as any),
              maxSalary: parseFloat(maxSalary as any),
              avgSalary: parseFloat(avgSalary as any),
              ...(picture ? { logo: picture } : {}),
            },
          },
        });
        if (res.data?.createJobCategory.error) {
          setErrors({ name: t("Title already exists") });
        } else {
          onClose();
        }
      } else {
        const res = await update({
          variables: {
            input: {
              id: category.id,
              minSalary: parseFloat(minSalary as any),
              maxSalary: parseFloat(maxSalary as any),
              avgSalary: parseFloat(avgSalary as any),
              ...(name !== category.name ? { name } : {}),
              ...(engTrans !== category.engTrans ? { engTrans } : {}),
              ...(picture ? { logo: picture } : {}),
            },
          },
        });
        if (res.data?.updateJobCategory.error) {
          setErrors({ name: t("Title already exists") });
        } else {
          onClose();
        }
      }
    } catch (err) {
      setServerError(err as any);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {category ? t("Edit Category") : t("Add Category")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (
          { name, minSalary, maxSalary, avgSalary, engTrans },
          { setErrors, setSubmitting }
        ) =>
          await handleSubmit(
            name,
            engTrans,
            minSalary,
            maxSalary,
            avgSalary,
            setErrors,
            setSubmitting
          )
        }
      >
        {({ values, dirty, submitForm }) => (
          <Form>
            <>
              <DialogContent>
                <PictureUpload
                  imageUrl={category?.logo?.url}
                  picUpload={picUpload}
                  setPicUpload={setPicUpload}
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Title")}
                  name="name"
                  style={{ marginBottom: 30, marginTop: 30 }}
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Eng title")}
                  name="engTrans"
                  style={{ marginBottom: 30 }}
                />

                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Min. Salary, USD")}
                  name="minSalary"
                  type="number"
                  style={{ marginBottom: 30 }}
                />

                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Max. Salary, USD")}
                  name="maxSalary"
                  type="number"
                  style={{ marginBottom: 30 }}
                />

                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Avg. Salary, USD")}
                  name="avgSalary"
                  type="number"
                  style={{ marginBottom: 30 }}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={cLoading || uLoading} onClick={onClose}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={cLoading || uLoading}
                >
                  {t("Save")}
                  {(cLoading || uLoading) && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>

      {serverError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Dialog>
  );
};

interface FormValues {
  name: string;
  engTrans:string;
  minSalary: number;
  maxSalary: number;
  avgSalary: number;
}
