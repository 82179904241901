import React from 'react';
import {GetTrackerItemsInput, TrackerItemStatus} from "../../../graphql/globalTypes";
import MomentUtils from "@date-io/moment";
import {Box} from "@mui/system";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import {SelectButton, TextSearch} from "../../../components";
import {convertEnumToArray} from "../../../utils";
import {useQuery} from "@apollo/client";
import {
  GET_TRACKER_FILTERS
} from "../../../graphql/queries/jobApplicationTracker/getTrackerFilters";
import {
  GetTrackerFilters
} from "../../../graphql/queries/jobApplicationTracker/getTrackerFilters/__generated__/GetTrackerFilters";

interface TrackerFilterBarProps {
  filterParams: GetTrackerItemsInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetTrackerItemsInput>>;
  itemsCount?:number;
}

export const TrackerFilterBar =
  ({filterParams, setFilterParams, itemsCount}:TrackerFilterBarProps) => {
    const {t} = useTranslation();
    const {data, loading: filtersLoading} =
      useQuery<GetTrackerFilters>(GET_TRACKER_FILTERS);
    const handleFromDateChange = (date: MaterialUiPickersDate) => {
      setFilterParams(prev => ({
        ...prev, createdFrom:  moment(date).startOf("day")
      }))
    };

    const handleToDateChange = (date: MaterialUiPickersDate) => {
      setFilterParams(prev => ({
        ...prev, createdTo: moment(date).endOf("day")
      }))
    };

    const handleTextSearchChange = (v: string) => {
      if (v.length > 2) {
        setFilterParams((prev) => ({
          ...prev,
          textSearch: v,
        }));
      }
    };

    const handleTextSearchReset = () => {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: undefined,
      }));
    };

    const handleFilterChange = (
      key: keyof GetTrackerItemsInput,
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setFilterParams((prev) => ({
        ...prev,
        ...(event.target.value === "all"
          ? {
            [key]: undefined,
          }
          : {
            [key]: event.target.value as string,
          }),
      }));
    };

    const handleCountryChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      const countryId =
        event.target.value === "all"
          ? undefined
          : event.target.value === "Not specified"
            ? event.target.value
            : data!.getTrackerFilters.countries.find(
              (c) => c.rusTrans === event.target.value
            )!.id;

      setFilterParams((prev) => ({
        ...prev,
        ...(event.target.value === "all"
          ? {
            countryId: undefined,
          }
          : {
            countryId: countryId,
          }),
      }));
    };

    const handleAuthorChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      const authorId =
        event.target.value === "all"
          ? undefined
          : event.target.value === "Not specified"
            ? event.target.value
            : data!.getTrackerFilters.authors?.find(
              (c) => c.email === event.target.value
            )!.id;

      setFilterParams((prev) => ({
        ...prev,
        ...(event.target.value === "all"
          ? {
            createdById: undefined,
          }
          : {
            createdById: authorId,
          }),
      }));
    };

    const getCountryValue = (id: undefined | string | "Not specified") => {
      if (id === undefined || id === "all") return "all";
      if (id === "Not specified") return "Not specified";
      return data!.getTrackerFilters.countries.find((c) => c.id === id)!.rusTrans!;
    };

    const getAuthorValue = (id: undefined | string | "Not specified") => {
      if (id === undefined || id === "all") return "all";
      if (id === "Not specified") return "Not specified";
      return data!.getTrackerFilters.authors?.find((c) => c.id === id)?.email;
    };

    return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Box display="flex" gap="5px">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            label={<div style={{marginTop:"-10px"}}>С</div>}
            InputProps={{
              style: {
                width: "155px",
                fontSize: 14,
                height: 40
              }
            }}
            value={filterParams.createdFrom}
            onChange={handleFromDateChange}
            inputVariant="outlined"
          />
          <KeyboardDatePicker
            disableToolbar
            InputProps={{
              style: {
                width: "155px",
                fontSize: 14,
                height: 40
              }
            }}
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            label={<div style={{marginTop:"-10px"}}>По</div>}
            value={filterParams.createdTo}
            onChange={handleToDateChange}
            inputVariant="outlined"
          />
          <TextSearch
            isSmall
            rootStyle={{flexBasis: "17%"}}
            onClick={handleTextSearchChange}
            onReset={handleTextSearchReset}
            hint={"Title, company"}
          />
          <SelectButton
            densed
            onChange={(e) => handleFilterChange("status", e)}
            value={filterParams.status ?? "all"}
            options={[...convertEnumToArray(TrackerItemStatus), "all"]}
            rootStyle={{flexBasis: "17%"}}
            label={t("Status")}
          />
          <SelectButton
            densed
            onChange={(e) => handleCountryChange(e)}
            value={getCountryValue(filterParams.countryId ?? "all")}
            options={
              data?.getTrackerFilters.countries
                ? [
                  "Not specified",
                  ...data.getTrackerFilters.countries.map((c) => c.rusTrans ?? ""),
                  "all",
                ]
                : ["Not specified", "all"]
            }
            rootStyle={{flexBasis: "17%"}}
            label={t("Country")}
            noOptionTranslation
            loading={filtersLoading}
          />
          <SelectButton
            densed
            onChange={(e) => handleAuthorChange(e)}
            value={getAuthorValue(filterParams.createdById ?? "all") ?? "all"}
            options={
              data?.getTrackerFilters.authors
                ? [
                  ...data.getTrackerFilters.authors.map((a) => a.email),
                  "all",
                ]
                : ["all"]
            }
            rootStyle={{flexBasis: "17%"}}
            label={t("author")}
            noOptionTranslation
            loading={filtersLoading}
          />
        </Box>
      </MuiPickersUtilsProvider>
      <Box display="flex" gap="40px" marginTop="10px">
        <Box display="flex" gap="10px">
          <Box>Трекером вакансий <br/> воспользовались:</Box>
          <Box fontWeight={500} color="blue">{data?.getTrackerFilters.authors?.length} пользователей</Box>
      </Box>
        {filterParams.createdById &&
          <Box display="flex"  gap="10px">
            <Box>
              <Box
                fontWeight="600">{data?.getTrackerFilters.authors?.find(a => a.id === filterParams.createdById)?.firstName}</Box>
              <Box
                fontSize="0.8em">{data?.getTrackerFilters.authors?.find(a => a.id === filterParams.createdById)?.email}</Box>
            </Box>
            {
              (filterParams.createdFrom || filterParams.createdTo)
              &&
              <Box display="flex" gap="10px">
                <Box>в период</Box>
                {filterParams.createdFrom && <Box
                  fontWeight="600">с {moment(filterParams.createdFrom).format("DD.MM.YYYY")}</Box>}
                {filterParams.createdTo && <Box
                  fontWeight="600">по {moment(filterParams.createdTo).format("DD.MM.YYYY")}</Box>}
              </Box>
            }
            <Box>
              добавил <span
              style={{color: "red"}}>{itemsCount ?? "...Loading..."}</span> вакансий
            </Box>
          </Box>}
      </Box>
    </div>
  );
};

