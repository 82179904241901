import { useQuery, useReactiveVar } from "@apollo/client";
import {
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import {
  AddNewButton,
  AtomProgress,
  MyTable,
  SelectButton,
  TextSearch,
} from "../../../components";
import { GET_COMPANIES_WITH_REFERRALS, GET_REFERRALS } from "../../../graphql";
import {
  PaginationOptionsArgs,
  ReferralsFilter,
} from "../../../graphql/globalTypes";
import { GetCompaniesWithReferrals } from "../../../graphql/queries/company/getCompaniesWithReferrals/__generated__/GetCompaniesWithReferrals";
import {
  GetReferrals,
  GetReferralsVariables,
  GetReferrals_getReferrals_referrals,
} from "../../../graphql/queries/referral/getReferrals/__generated__/GetReferrals";
import { useSetHeadbarTitle } from "../../../hooks";
import { HeadCell } from "../../../lib";
import { capitalizeFirstLetters } from "../../../utils";
import { EditReferral } from "./components";

interface ReferralsViewProps {}

export const ReferralsView: React.FC<ReferralsViewProps> = ({}) => {
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  useSetHeadbarTitle(t("Referrals"));
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<ReferralsFilter>({});
  const [currentReferral, setCurrentReferral] =
    useState<GetReferrals_getReferrals_referrals | null>(null);
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "firstName",
        sortBy: "desc",
      },
    });
  const { data: companiesData, loading: companiesLoading } =
    useQuery<GetCompaniesWithReferrals>(GET_COMPANIES_WITH_REFERRALS);
  const { data, loading } = useQuery<GetReferrals, GetReferralsVariables>(
    GET_REFERRALS,
    {
      variables: {
        paginationOptions,
        query: filter,
      },
      fetchPolicy: "network-only",
    }
  );
  const mapReferralsToRows = useMemo(() => {
    if (!data?.getReferrals.referrals.length) return [];

    const arr = data.getReferrals.referrals.map((referral) => ({
      id: referral.id,
      name: (
        <Box>
          <Typography variant="subtitle2" display="block">
            {referral.firstName + " " + referral.lastName}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: "bold" }}
            display="block"
          >
            {referral.type}
          </Typography>
        </Box>
      ),
      companies: (
        <Box>
          {referral.companies?.map((company) => (
            <Typography>{company.name}</Typography>
          ))}
        </Box>
      ),
      contacts: (
        <Box>
          {referral.email && (
            <a
              style={{ textDecoration: "none" }}
              href={`mailto:${referral.email}`}
            >
              <Typography variant="subtitle2" display="block" color="primary">
                {referral.email}
              </Typography>
            </a>
          )}
          {referral.phone && (
            <a
              style={{ textDecoration: "none" }}
              href={`callto:${referral.phone}`}
            >
              <Typography variant="subtitle2" display="block" color="primary">
                {referral.phone}
              </Typography>
            </a>
          )}
          {referral.facebook && (
            <a
              style={{ textDecoration: "none" }}
              href={`${referral.facebook}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2" display="block" color="primary">
                {referral.facebook}
              </Typography>
            </a>
          )}
          {referral.telegram && (
            <a
              style={{ textDecoration: "none" }}
              href={`https://telegram.me/${referral.telegram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2" display="block" color="primary">
                {referral.telegram}
              </Typography>
            </a>
          )}
          {referral.linkedIn && (
            <a
              style={{ textDecoration: "none" }}
              href={`${referral.linkedIn}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2" display="block" color="primary">
                {referral.linkedIn}
              </Typography>
            </a>
          )}
        </Box>
      ),

      edit: (
        <IconButton onClick={() => handleOpen(referral)}>
          <Edit />
        </IconButton>
      ),
    }));
    return arr;
  }, [data?.getReferrals.referrals]);

  //

  const getCompanyNameById = useMemo(() => {
    if (!filter.companyId) {
      return "all";
    } else {
      return companiesData!.getCompaniesWithReferrals.find(
        (c) => c.id === filter.companyId
      )!.name;
    }
  }, [filter.companyId, companiesData, capitalizeFirstLetters]);

  console.log(getCompanyNameById);

  const handleOpen = (referral: GetReferrals_getReferrals_referrals | null) => {
    setOpen(true);
    setCurrentReferral(referral);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentReferral(null);
  };
  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilter((prev) => ({ ...prev, search: v }));
    }
  };
  const handleTextSearchReset = () => {
    setFilter((prev) => ({ ...prev, search: "" }));
  };
  // console.log(filter.companyId);
  const handleCompanyChange = (name: string) => {
    if (name === "all") {
      setFilter((prev) => ({ ...prev, companyId: undefined }));
    } else {
      const companyId = companiesData!.getCompaniesWithReferrals.find(
        (c) => c.name.toLowerCase() === name
      )!.id;

      setFilter((prev) => ({ ...prev, companyId }));
    }
  };

  return (
    <>
      <Box
        zIndex={100}
        display="flex"
        flexWrap="wrap"
        width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
        justifyContent="flex-start"
        position="fixed"
      >
        <SelectButton
          squared
          onChange={(e) => handleCompanyChange(e.target.value)}
          value={getCompanyNameById}
          options={
            companiesData?.getCompaniesWithReferrals
              ? [
                  ...companiesData.getCompaniesWithReferrals.map((c) => c.name),
                  "all",
                ]
              : ["all"]
          }
          rootStyle={{ flexBasis: "15%" }}
          label={t("Company")}
          noOptionTranslation
          loading={companiesLoading}
        />
        <TextSearch
          rootStyle={{ flexBasis: "40%" }}
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"Name, email, phone..."}
        />
      </Box>

      <Box padding={3} marginTop={4}>
        {loading && (
          <Box
            position="absolute"
            marginTop="15px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <MyTable
        loading={loading}
        total={data?.getReferrals.meta.totalItems ?? 0}
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        headCells={headCells}
        values={mapReferralsToRows}
        toolbarRightButton={
          <AddNewButton
            onClick={() => handleOpen(null)}
            title={t("Add new referral")}
          />
        }
        bulkActions={<></>}
        sortFields={[]}
        hideCheck
      />

      <EditReferral
        open={open}
        onClose={handleClose}
        referral={currentReferral}
        paginationOptions={paginationOptions}
        filter={filter}
      />
    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "name",
    align: "left",
  },
  {
    label: "companies",
    align: "left",
  },
  {
    label: "contacts",
    align: "left",
  },
  {
    label: "edit",
    align: "right",
  },
];
