import { gql } from "@apollo/client";
import { CompanyFragment } from "../../../fragments";

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...Company
      }
      error {
        name
        message
      }
    }
  }
  ${CompanyFragment}
`;
