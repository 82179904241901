import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useGetUsers, useSetHeadbarTitle} from "../../hooks";
import {useFetchProductFromRoute} from "../../hooks/useFetchProductFromRoute";
import {Field, FieldArray, Form, Formik, FormikErrors} from "formik";
import {Box} from "@mui/system";
import {
  Button,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import {
  FullscreenLoader,
  MyAutocomplete,
  MyTextField,
  NotificationToast,
  SelectButton
} from "../../components";
import {convertEnumToArray} from "../../utils";
import {
  AppLanguage,
  Currency,
  FileInput, FileType,
  GetUsersInput,
  ProductCategory, ProductKind,
  ProductVariant, PublishStatus,
  UpdateProductInput,
  UserRole
} from "../../graphql/globalTypes";
import {JobFormValues} from "../../lib";
import {Autocomplete} from "@material-ui/lab";
import {
  GetUsers_getUsers_users as IUser
} from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {FilesUpload} from "../../components/inputs/FilesUpload";
import {CheckboxWithLabel} from "formik-material-ui";
import {PictureInBase64Upload} from "../../components/inputs/PictureInBase64Upload";
import {Add, Remove, SaveOutlined as SaveIcon} from "@material-ui/icons";
import * as Yup from "yup";
import {useQuery} from "@apollo/client";
import {
  GetLearningPaths,
  GetLearningPathsVariables
} from "../../graphql/queries/learningPath/getLearningPaths/__generated__/GetLearningPaths";
import {GET_LEARNING_PATHS} from "../../graphql";
import {useDebounce} from "../../hooks/useDebounce";
import {
  GetProductById_getProductById
} from "../../graphql/queries/products/getProductById/__generated__/GetProductById";
import {useUpdateProduct} from "../../hooks/mutations/useUpdateProduct";
import {
  UpdateProductVariables
} from "../../graphql/mutations/products/updateProduct/__generated__/UpdateProduct";
import {
  GetProducts,
  GetProductsVariables
} from "../../graphql/queries/products/getProducts/__generated__/GetProducts";
import {GET_PRODUCTS} from "../../graphql/queries/products/getProducts";

export const EditProduct = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Edit Product"));
  const {product, loading, error} = useFetchProductFromRoute()
  const [success, setSuccess] = useState(false)
  const {update, loading: updateLoading, error: updateError} = useUpdateProduct()
  const {data: LPData, loading: LPLoading} = useQuery<GetLearningPaths,
    GetLearningPathsVariables>(GET_LEARNING_PATHS, {
    variables: {
      paginationOptions: {}
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: products,
    loading: productsLoading,
    error: getProductsError
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    variables: {
      filter: {},
      paginationOptions: {
        limit: 100
      }
    }
  })

  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    textSearch: undefined,
    roles: [UserRole.COACH]
  });

  const {data, loading: usersLoading} = useGetUsers({
    input: useDebounce(filterParams, 700),
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      }
    }
  });

  const handleTextSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };

  const [input, setInput] = useState<GetProductById_getProductById | null>(null)
  const [downloadableMedia, setDownloadableMedia] = useState<FileInput[] | null | undefined>(null);
  const initialValues: UpdateProductInput = {
    id: input?.id ?? "",
    title: input?.title ?? "",
    description: input?.description ?? "",
    status: input?.status ?? PublishStatus.DRAFT,
    price: {
      value: input?.priceInUsd.toString() ?? "0",
      currency: Currency.USD
    },
    marketingPrice: input?.marketingPrice ?? "",
    category: input?.category ?? ProductCategory.ACCESS_TO_MARATHON,
    isFeatured: input?.isFeatured,
    baseNumber: input?.baseNumber,
    purchasesPerMonth: input?.purchasesPerMonth,
    discountPercentage: input?.discountPercentage,
    isHidden: input?.isHidden,
    canBeSoldSeparately: input?.canBeSoldSeparately,
    features: input?.features,
    productsIds: input?.products?.map(prod => prod.id),
    variant: input?.variant ?? ProductVariant.SEPARATE_ITEM,
    slug: input?.slug ?? "",
    sortOrder: input?.sortOrder ?? 0,
    picture: input?.picture ? {
      fileName: input.picture.fileName,
      source: input.picture.url
    } : null,
    linkToProductInfo: input?.linkToProductInfo ?? "",
    accessToLearningPathPropsInput: input?.accessToLearningPathProps ? {
      learningPathId: input.accessToLearningPathProps.learningPathId
    } : null,
    accessToMarathonPropsInput: input?.accessToMarathonProps ? {
      marathonTitle: input.accessToMarathonProps.marathonTitle,
      linkForBooking: input.accessToMarathonProps.linkForBooking
    } : null,
    consultationWithCoachPropsInput: input?.consultationWithCoachProps ? {
      coachId: input.consultationWithCoachProps.coach?.id ?? "",
      linkForBooking: input.consultationWithCoachProps.linkForBooking
    } : null,
    downloadableMediaPropsInput: input?.downloadableMediaProps ? {
      files: input?.downloadableMediaProps?.files.map(el => ({
        fileName: el.fileName,
        source: el.url,
        type: el.type as FileType
      })),
    } : null,
    externalAccessLinkPropsInput: input?.externalAccessLinkProps ? {
      url: input.externalAccessLinkProps.url
    } : null,
    kind: input?.kind,
    language: input?.language,
    shortDescription: input?.shortDescription
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t("Required")),
    sortOrder: Yup.string().required(t("Required")),
    marketingPrice: Yup.string().required(t("Required")),
    slug: Yup.string().required(t("Required")),
    description: Yup.string().required(t("Required")),
  });

  const handleSubmit = async (
    values: UpdateProductInput,
    setErrors: (errors: FormikErrors<JobFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSuccess(false)
    const variables: UpdateProductVariables = {
      input: {
        ...values,
        downloadableMediaPropsInput: downloadableMedia ? {files: downloadableMedia} : null,
        ...(values.isFeatured ? {discountPercentage: values.discountPercentage} : {}),
        ...(values.isFeatured ? {baseNumber: Number(values.baseNumber)} : {}),
        ...(values.isFeatured ? {purchasesPerMonth: Number(values.purchasesPerMonth)} : {}),
      },
    };

    try {
      const res = await update({variables});
      if (res.data?.updateProduct) {
        setSuccess(true)
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setInput(product)
    setDownloadableMedia(product?.downloadableMediaProps?.files.map(el =>
      ({fileName: el.fileName, source: el.url, type:el.type as FileType})))
  }, [product])

  if (loading) {
    return <FullscreenLoader transparent/>
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          handleSubmit(values, setErrors, setSubmitting)
        }>
        {({values, submitForm, setFieldValue}) => (
          <Form>
            <Box margin="20px">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Title")}
                    placeholder={t("Title")}
                    name="title"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={2}>
                  <SelectButton
                    rootStyle={{width: "100%"}}
                    label={t("status")}
                    options={convertEnumToArray(PublishStatus)}
                    value={values.status ?? PublishStatus.DRAFT}
                    onChange={(e) => setFieldValue("status", e.target.value)}/>
                </Grid>
                <Grid item xs={2}>
                  <SelectButton
                    rootStyle={{width: "100%"}}
                    label={t("Type")}
                    options={convertEnumToArray(ProductKind)}
                    value={values.kind ?? ""}
                    onChange={(e) => setFieldValue("kind", e.target.value)}/>
                </Grid>
                <Grid item xs={2}>
                  <SelectButton
                    rootStyle={{width: "100%"}}
                    label={t("language")}
                    options={convertEnumToArray(AppLanguage)}
                    value={values.language ?? ""}
                    onChange={(e) => setFieldValue("language", e.target.value)}/>
                </Grid>
                <Grid item xs={2}>
                  <SelectButton
                    label={t("Variant")}
                    rootStyle={{width: "100%"}}
                    options={convertEnumToArray(ProductVariant)}
                    value={values.variant ?? ProductVariant.SEPARATE_ITEM}
                    onChange={(e) => {
                      if (e.target.value === ProductVariant.BUNDLE) {
                        setFieldValue("variant", e.target.value)
                        setFieldValue("category", ProductCategory.BUNDLE)
                      } else {
                        setFieldValue("variant", e.target.value)
                        setFieldValue("category", ProductCategory.EXTERNAL_ACCESS_LINK)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SelectButton
                    rootStyle={{width: "100%"}}
                    label={t("Category")}
                    options={convertEnumToArray(ProductCategory)}
                    value={values.category ?? ProductCategory.ACCESS_TO_MARATHON}
                    onChange={(e) => setFieldValue("category", e.target.value)}/>
                </Grid>
              </Grid>

              {values.category === ProductCategory.BUNDLE &&
                <><Box margin="10px" fontWeight="500">{t("BUNDLE")}</Box>
                  <Grid container spacing={3}>
                    <Grid item xs={8}>
                      {productsLoading
                        ? <Typography>{t("Products loading")}</Typography>
                        : <MyAutocomplete
                          multiple
                          options={products?.getProducts.products
                            .filter(prod => prod.variant !== ProductVariant.BUNDLE)
                            .map(el => el.id) ?? []}
                          getOptionLabel={(option) =>
                            products?.getProducts.products.find(el => el.id === option)?.title ?? t("Not specified")}
                          label={t("Products")}
                          value={values.productsIds ?? []}
                          placeholder={t("Expert activities")}
                          setValue={(e: string[]) => {
                            setFieldValue("productsIds", e)
                          }}
                        />}
                    </Grid>
                  </Grid><Box height="10px"/></>}


              {values.category === ProductCategory.CONSULTATION_WITH_A_COACH &&
                <><Box margin={"10px"}
                       fontWeight="500">{t("CONSULTATION_WITH_A_COACH")}</Box>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Field
                        name="userId"
                        noOptionsText={t("User not found")}
                        loading={usersLoading}
                        component={Autocomplete}
                        value={data?.getUsers.users.find(el => el.id === values.consultationWithCoachPropsInput?.coachId)}
                        options={data?.getUsers.users ?? []}
                        getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} ` || ""}
                        onChange={(event: any, newValue: IUser) =>
                          setFieldValue("consultationWithCoachPropsInput",
                            {
                              coachId: newValue?.id,
                              linkForBooking: values.consultationWithCoachPropsInput?.linkForBooking ?? ""
                            })}
                        fullWidth
                        renderInput={(params: any) => (
                          <TextField {...params}
                                     placeholder={t("Coach name or email")}
                                     variant="outlined"
                                     InputProps={{
                                       ...params.InputProps,
                                       endAdornment: (
                                         <React.Fragment>
                                           {usersLoading ?
                                             <CircularProgress color="primary"
                                                               size={20}/> : null}
                                           {params.InputProps.endAdornment}
                                         </React.Fragment>
                                       ),
                                     }}
                                     label={t("Coach name or email")}
                                     onChange={handleTextSearchChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        value={values.consultationWithCoachPropsInput?.linkForBooking}
                        fullWidth
                        component={MyTextField}
                        label={t("Link for booking")}
                        placeholder={t("Link for booking")}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("consultationWithCoachPropsInput",
                          {
                            linkForBooking: e.target.value,
                            coachId: values.consultationWithCoachPropsInput?.coachId
                          })}
                        name="linkForBooking"/>
                    </Grid>
                  </Grid><Box height="10px"/></>}

              {values.category === ProductCategory.ACCESS_TO_MARATHON &&
                <><Box margin="10px" fontWeight="500">{t("ACCESS_TO_MARATHON")}</Box>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Field
                        fullWidth
                        value={values.accessToMarathonPropsInput?.marathonTitle ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("accessToMarathonPropsInput",
                          {
                            marathonTitle: e.target.value,
                            linkForBooking: values.accessToMarathonPropsInput?.linkForBooking
                          })}
                        component={MyTextField}
                        label={t("Marathon title")}
                        placeholder={t("Marathon title")}
                        name="marathonTitle"/>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        fullWidth
                        value={values.accessToMarathonPropsInput?.linkForBooking ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("accessToMarathonPropsInput",
                          {
                            linkForBooking: e.target.value,
                            marathonTitle: values.accessToMarathonPropsInput?.marathonTitle
                          })}
                        component={MyTextField}
                        label={t("Link for marathon booking")}
                        placeholder={t("Link for marathon booking")}
                        name="linkForMarathonBooking"/>
                    </Grid>
                  </Grid><Box height="10px"/></>}

              {values.category === ProductCategory.LEARNING_PATH_ACCESS &&
                <><Box margin="10px" fontWeight="500">{t("LEARNING_PATH_ACCESS")}</Box>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <SelectButton
                        label={t("Educational material")}
                        rootStyle={{width: "100%"}}
                        options={LPData?.getLearningPaths.learningPaths.map(lp => lp.title) ?? []}
                        value={LPData?.getLearningPaths.learningPaths.find(lp => lp.id === values.accessToLearningPathPropsInput?.learningPathId)?.title ?? ""}
                        onChange={(e) =>
                          setFieldValue("accessToLearningPathPropsInput",
                            {learningPathId: LPData?.getLearningPaths.learningPaths.find(lp => lp.title === e.target.value)?.id})
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        fullWidth
                        component={MyTextField}
                        label={t("Material link")}
                        placeholder={t("Material link")}
                        name="linkToProductInfo"/>
                    </Grid>
                  </Grid>
                  <Box height="10px"/></>}

              {values.category === ProductCategory.EXTERNAL_ACCESS_LINK &&
                <><Box margin="10px" fontWeight="500">{t("EXTERNAL_ACCESS_LINK")}</Box>
                  <Field
                    component={MyTextField}
                    label={t("url")}
                    name={"url"}
                    value={values.externalAccessLinkPropsInput?.url}
                    onChange={(e: any) => setFieldValue("externalAccessLinkPropsInput", {
                      url: e.target.value,
                    })}
                    placeholder={t("url")}
                    style={{marginBottom: 20, width: "30%"}}/></>}

              {values.category === ProductCategory.DOWNLOADABLE_MEDIA &&
                <><Box margin="10px" fontWeight="500">{t("DOWNLOADABLE_MEDIA")}</Box>
                  <FilesUpload
                    setFiles={setDownloadableMedia}
                    files={downloadableMedia ?? []}/></>}
              <Divider style={{margin: "20px 0"}}/>
              <Box margin={"10px"}>Стоимость продукта</Box>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <SelectButton
                    label={t("currency")}
                    rootStyle={{width: "100%"}}
                    options={[Currency.USD, Currency.RUB]}
                    value={values.price?.currency ?? Currency.USD}
                    onChange={(e) => setFieldValue("price", {
                      currency: e.target.value,
                      value: values.price?.value ?? ""
                    })}/>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    type="number"
                    component={MyTextField}
                    label={t("price")}
                    name={"value"}
                    value={values.price?.value}
                    onChange={(e: any) => setFieldValue("price", {
                      value: e.target.value,
                      currency: values.price?.currency
                    })}
                    placeholder={t("price")}
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={MyTextField}
                    label={t("Marketing price")}
                    placeholder={t("Marketing price")}
                    name="marketingPrice"
                    style={{marginBottom: 20}}/>
                </Grid>
              </Grid>
              <Divider/>
              <Box display="flex" gap="20px" margin="10px 0">
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="isFeatured"
                  Label={{
                    label: t("Featured service"),
                  }}
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="isHidden"
                  Label={{
                    label: t("Hidden product"),
                  }}
                />
                {/*<Field*/}
                {/*  component={CheckboxWithLabel}*/}
                {/*  type="checkbox"*/}
                {/*  name="canBeSoldSeparately"*/}
                {/*  Label={{*/}
                {/*    label: t("Can be sold separately"),*/}
                {/*  }}*/}
                {/*/>*/}
              </Box>
              <Divider style={{marginBottom: "20px"}}/>

              {values.isFeatured && <>
                <Box margin="10px"
                     fontWeight="500">{t("Marketing info for featured service")}</Box>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      component={MyTextField}
                      label={t("Discount percentage")}
                      placeholder={t("Discount percentage")}
                      name="discountPercentage"/>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      type="number"
                      component={MyTextField}
                      label={t("Base number for sales imitation")}
                      placeholder={t("Base number for sales imitation")}
                      name="baseNumber"/>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      type="number"
                      component={MyTextField}
                      label={t("Purchases per month")}
                      placeholder={t("Purchases per month")}
                      name="purchasesPerMonth"/>
                  </Grid>
                </Grid>
                <Divider style={{margin: "20px 0"}}/>
              </>}

              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    multiline
                    rows={5}
                    label={t("description")}
                    placeholder={t("description")}
                    name="description"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    multiline
                    rows={5}
                    label={t("shortDescription")}
                    placeholder={t("shortDescription")}
                    name="shortDescription"/>
                </Grid>
                <Grid item xs={4}>
                  <Box width="225px" height="150px">
                    <PictureInBase64Upload
                      pictureResolution={"1000*667 WEBP"}
                      src={values.picture?.source}
                      setPicture={(e) => setFieldValue("picture", {source: e})}/>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Slug")}
                    placeholder={t("Slug")}
                    name="slug"/>
                </Grid>
                {values.category !== ProductCategory.LEARNING_PATH_ACCESS &&
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      component={MyTextField}
                      label={t("link to product info")}
                      placeholder={t("link to product info")}
                      name="linkToProductInfo"/>
                  </Grid>}
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("SortOrder")}
                    placeholder={t("SortOrder")}
                    type="number"
                    name="sortOrder"/>
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>

              <Box margin="10px 0" width="50%">
                <FieldArray
                  name="features"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.features && values.features.length > 0 ? (
                        values.features.map((_feature, index) => (
                          <Box
                            key={index}
                            display={"flex"}
                            marginBottom={"20px"}>
                            <Field
                              component={MyTextField}
                              fullWidth
                              name={`features.${index}`}/>
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}>
                              <Remove/>
                            </IconButton>
                            <IconButton
                              onClick={() => arrayHelpers.insert(index, "")}>
                              <Add/>
                            </IconButton>
                          </Box>
                        ))
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                          disabled={loading}
                        >
                          {t("Add a feature")}
                        </Button>
                      )}
                    </Box>
                  )}/>
              </Box>

              <Box textAlign="center">
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  style={{width: "30%"}}
                  id="bottomBtn"
                  onClick={() => submitForm()}
                  disabled={loading || updateLoading}
                >{updateLoading
                  ? <CircularProgress color="secondary" size={30}/>
                  : <><SaveIcon style={{marginRight: 10}}/>
                    {t("Save")}</>
                }

                </Fab>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {loading && <FullscreenLoader transparent/>}
      {(error || updateError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
      {success && (
        <NotificationToast
          message={t("Product updated")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </>
  );
};