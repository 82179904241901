import React, {useEffect, useState} from 'react';
import {useSetHeadbarTitle} from "../../hooks";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/system";
import {AddNewButton, NotificationToast} from "../../components";
import {useHistory, useLocation} from "react-router-dom";
import {
  GetPartnerCompanies_getPartnerCompanies_partnerCompanies
} from "../../graphql/queries/partnerCompanies/getPartnerCompanies/__generated__/GetPartnerCompanies";
import {useGetPartnerCompanies} from "../../hooks/queries/partnerCompanies/useGetPartnerCompanies";
import {GetPartnerCompaniesInput, PaginationOptionsArgs} from "../../graphql/globalTypes";
import {PartnerCompaniesTable} from "./components/PartnerCompaniesTable";
import {PartnerCompaniesFilterBar} from "./components/PartnerCompaniesFilterBar";

export const PartnerCompanies = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation<{showCreateNotification:boolean, message:string}>();
  useSetHeadbarTitle(t("PartnerCompanies"));
  const handleAddNewProductClick = () => {
    history.push("/partner-companies/create");
  }

  const [totalPartnerCompanies, setTotalPartnerCompanies] = useState(0);
  const [partnerCompanies, setPartnerCompanies] = useState<GetPartnerCompanies_getPartnerCompanies_partnerCompanies[]>([]);
  const [filterParams, setFilterParams] = useState<GetPartnerCompaniesInput>({
    partnerCompanyCategories:undefined,
    tags:undefined,
    languages:undefined,
    countriesIds:undefined,
    textSearch:undefined,
  });
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "latest",
        sortBy: "desc",
      },
    });
  const {data,loading,error}=useGetPartnerCompanies({
    input:filterParams,
    paginationOptions
  })

  useEffect(() => {
    if (data) {
      setTotalPartnerCompanies(data.getPartnerCompanies.total);
      setPartnerCompanies(data.getPartnerCompanies.partnerCompanies);
    }
  }, [data]);
  return (
    <Box margin="20px">
      <Box textAlign="right" margin="10px 0 0">
        <PartnerCompaniesFilterBar
          filterParams={filterParams}
          setFilterParams={setFilterParams}/>
        <Box height="10px"/>
        <AddNewButton title={t("Add new partner-company")}
                      onClick={handleAddNewProductClick}/>
        <PartnerCompaniesTable
          loading={loading}
          partnerCompanies={partnerCompanies}
          totalPartnerCompanies={totalPartnerCompanies}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}/>
      </Box>

      {location?.state?.showCreateNotification && (
        <NotificationToast
          message={t(location.state.message)}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};

