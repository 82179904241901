import {gql} from "@apollo/client";
import {FileFragment} from "../../../fragments";

export const UPDATE_EXPERT_COMPANY = gql`
    mutation UpdateExpertCompany($input: UpdateExpertCompanyInput!) {
        updateExpertCompany(input: $input) {
            expertCompany {
                name
                id
                logo{
                    ...File
                }
            }
            error {
                name
                message
            }
        }
    }
    ${FileFragment}
`;
