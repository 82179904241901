import { useQuery } from "@apollo/client";
import { IS_VERIFICATION_TOKEN_VALID } from "../../graphql";
import {
  IsVerificationTokenValid,
  IsVerificationTokenValidVariables,
} from "../../graphql/queries/me/IsVerificationTokenValid/__generated__/IsVerificationTokenValid";

export const useCheckVerificationLink = (token: string) => {
  const {
    loading,
    error: serverError,
    data,
  } = useQuery<IsVerificationTokenValid, IsVerificationTokenValidVariables>(
    IS_VERIFICATION_TOKEN_VALID,
    {
      onError: (err) => {
        console.log(err);
      },
      variables: { token },
    }
  );

  return {
    loading,
    serverError,
    linkError: data?.isVerificationTokenValid.error?.name,
  };
};
