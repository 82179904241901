import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useIsDesktopView } from "../../hooks";

interface FallbackProps {
  title: string;
  message?: string;
  onClick?: () => void;
  btnTitle?: string;
}

export const Fallback: React.FC<FallbackProps> = ({
  title,
  message,
  onClick,
  btnTitle,
}) => {
  const isDesktopView = useIsDesktopView();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClose = () => {
    if (history) {
      history.go(0);
    } else {
      window.location.reload();
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      fullScreen={isDesktopView ? undefined : true}
    >
      <DialogTitle>{title}</DialogTitle>
      {message && <DialogContent>{message}</DialogContent>}
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={onClick ?? handleClose}
        >
          {btnTitle ? btnTitle : t("Reload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
