import { gql } from "@apollo/client";
import { IncomeFragment, PromoterUserFragment } from "../../../fragments";

export const GET_PROMOTER_BY_ADMIN = gql`
  query GetPromoterByAdmin($id: String!) {
    getPromoterByAdmin(id: $id) {
      promoter {
        ...PromoterUser
      }
      accumulatedIncome {
        ...Income
      }
      projectedIncome {
        ...Income
      }
      payoutCurrency
    }
  }
  ${PromoterUserFragment}
  ${IncomeFragment}
`;
