import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  CREATE_SEO_TAG,
  DELETE_SEO_TAG,
  GET_SEO_TAGS,
  UPDATE_SEO_TAG,
} from "../../graphql";
import {
  CreateSeoTag,
  CreateSeoTagVariables,
} from "../../graphql/mutations/seotags/createSeoTag/__generated__/CreateSeoTag";
import {
  DeleteSeoTag,
  DeleteSeoTagVariables,
} from "../../graphql/mutations/seotags/deleteSeoTag/__generated__/DeleteSeoTag";
import {
  UpdateSeoTag,
  UpdateSeoTagVariables,
} from "../../graphql/mutations/seotags/updateSeoTag/__generated__/UpdateSeoTag";
import {
  GetSeoTags,
  GetSeoTags_getSeoTags,
} from "../../graphql/queries/seotags/getSeoTags/__generated__/GetSeoTags";

export const useGetSeoTags = () => {
  const { data, loading, error } = useQuery<GetSeoTags>(GET_SEO_TAGS);
  const [tags, setTags] = useState<GetSeoTags_getSeoTags[]>([]);

  useEffect(() => {
    if (data?.getSeoTags) {
      setTags(data.getSeoTags);
    }
  }, [data]);

  return {
    tags,
    loading,
    error,
  };
};

export const useCreateSeoTag = () => {
  const [create, { loading, error }] = useMutation<
    CreateSeoTag,
    CreateSeoTagVariables
  >(CREATE_SEO_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SEO_TAGS,
      },
    ],
  });

  return {
    create,
    loading,
    error,
  };
};

export const useUpdateSeoTag = () => {
  const [update, { loading, error }] = useMutation<
    UpdateSeoTag,
    UpdateSeoTagVariables
  >(UPDATE_SEO_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SEO_TAGS,
      },
    ],
  });

  return {
    update,
    loading,
    error,
  };
};

export const useDeleteSeoTag = () => {
  const [deleteTag, { loading, error }] = useMutation<
    DeleteSeoTag,
    DeleteSeoTagVariables
  >(DELETE_SEO_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SEO_TAGS,
      },
    ],
  });

  return {
    deleteTag,
    loading,
    error,
  };
};
