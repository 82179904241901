import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Field, Form, Formik, FormikErrors} from "formik";
import {useGetUsers, useSetHeadbarTitle} from "../../hooks";
import * as Yup from "yup";
import {GetUsersInput, PublishStatus} from "../../graphql/globalTypes";
import {Box} from "@mui/system";
import {CircularProgress, Divider, Fab, Grid, TextField} from "@material-ui/core";
import {
  FullscreenLoader,
  MyTextField,
  NotificationToast,
  SelectButton
} from "../../components";
import {convertEnumToArray} from "../../utils";
import {SaveOutlined as SaveIcon} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import {
  GetUsers_getUsers_users as IUser
} from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {useDebounce} from "../../hooks/useDebounce";
import {useMutation} from "@apollo/client";
import {
  CreateAffiliate,
  CreateAffiliateVariables
} from "../../graphql/mutations/affiliates/createAffiliate/__generated__/CreateAffiliate";
import {CREATE_AFFILIATE} from "../../graphql/mutations/affiliates/createAffiliate";
import {GET_AFFILIATES} from "../../graphql/queries/affiliate/GetAffiliates";


export const CreateNewAffiliate = () => {
  const {t} = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Add new affiliate"));
  const [enableValidation, setEnableValidation] = useState(false);
  const [createError, setCreateError] = useState<string | null>(null)
  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    textSearch: undefined,
  });
  const [users, setUsers] = useState<IUser[]>([]);
  const {data, loading: usersLoading} = useGetUsers({
    input: useDebounce(filterParams, 700),
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      }
    }
  });
  const handleTextSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };

  const [create, { loading, error }] = useMutation<
    CreateAffiliate,
    CreateAffiliateVariables
  >(CREATE_AFFILIATE, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_AFFILIATES,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "created_at",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  const initialValues: AffiliateFormValues = {
    name: "",
    affiliateApplicationText: "",
    email: "",
    status: PublishStatus.PUBLISHED,
    userId: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    userId: Yup.string()
      .required(t("Required")),
    email: Yup.string().email(t("Invalid email"))
      .required(t("Required")),
    affiliateApplicationText: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
  });

  const handleSubmit = async (
    values: AffiliateFormValues,
    setErrors: (errors: FormikErrors<AffiliateFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {

    const variables: CreateAffiliateVariables = {
        input: {
          ...values,
        }
      }
    ;

    try {
      const res = await create({variables})
      if (res.data?.createAffiliate.error) {
        setCreateError(res.data.createAffiliate.error.message)
      } else if (res.data?.createAffiliate.affiliate) {
        history.push({
          pathname: `/affiliates/edit/${res.data.createAffiliate.affiliate.id}`,
          state: {showCreateNotification: true},
        });
      }
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    if (data) {
      setUsers(data.getUsers.users);
    }
  }, [data]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          handleSubmit(values, setErrors, setSubmitting)
        }>
        {({values, submitForm, setFieldValue, errors}) => {
          return (<Form>
            <Box margin="20px">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name="userId"
                    noOptionsText={t("User not found")}
                    loading={usersLoading}
                    loadingText={<CircularProgress
                      thickness={7}
                      style={{marginLeft: 10}}
                      size={20}
                      color="primary"
                    />}
                    component={Autocomplete}
                    options={users}
                    getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} ` || ""}
                    onChange={(event: any, newValue: IUser) => {
                      setFieldValue("userId", newValue.id)
                      setFieldValue("name", `${newValue.firstName} ${newValue.lastName}`)
                      setFieldValue("email", newValue.email)
                    }}
                    fullWidth
                    style={{marginBottom: 20}}
                    renderInput={(params: any) => (
                      <TextField {...params}
                                 error={!!errors.userId}
                                 placeholder={t("Enter name or email")}
                                 variant="outlined"
                                 InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                     <React.Fragment>
                                       {usersLoading ?
                                         <CircularProgress color="primary"
                                                           size={20}/> : null}
                                       {params.InputProps.endAdornment}
                                     </React.Fragment>
                                   ),
                                 }}
                                 label={t("Enter name or email")}
                                 onChange={handleTextSearchChange}
                                 helperText={errors.userId}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectButton
                    rootStyle={{width: "100%"}}
                    label={t("status")}
                    options={convertEnumToArray(PublishStatus)}
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("First name, last name")}
                    placeholder={t("Name example")}
                    name="name"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Email")}
                    placeholder={t("Email")}
                    name="email"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Divider style={{margin: "20px 0"}}/>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    multiline
                    rows={6}
                    label={t("Motivational text")}
                    placeholder={t("Motivational text")}
                    name="affiliateApplicationText"/>
                  <Divider style={{marginBottom: "20px"}}/>
                </Grid>
              </Grid>

              <Box textAlign="center">
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  style={{width: "30%"}}
                  id="bottomBtn"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm()
                  }}
                  disabled={loading}
                >
                  {loading
                    ? <CircularProgress style={{color: "white"}} size={30}/>
                    : <><SaveIcon style={{marginRight: 10}}/>
                      {t("Save")}</>
                  }
                </Fab>
              </Box>
            </Box>
          </Form>)
        }}
      </Formik>
      {loading && <FullscreenLoader transparent/>}
      {(error) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
      {createError && (
        <NotificationToast
          message={createError}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
interface AffiliateFormValues {
  name: string;
  userId: string;
  email:string;
  affiliateApplicationText:string;
  status: PublishStatus;
}


