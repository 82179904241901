import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cmsAssetVar } from "../apollo";

export const useCMS = () => {
  const { t } = useTranslation();
  const cmsAsset = useReactiveVar(cmsAssetVar);
  const [currentAsset, setCurrentAsset] = useState<any | null>(null);

  useEffect(() => {
    const btn = document.createElement("div");
    btn.setAttribute("id", "cms-open-btn");
    //@ts-ignore
    if (!window.ml) {
      //@ts-ignore
      window.ml = cloudinary.createMediaLibrary(
        {
          cloud_name: process.env.REACT_APP_CLOUD_NAME,
          api_key: process.env.REACT_APP_CLOUD_API,
          username: process.env.REACT_APP_CLOUD_USERNAME,
          button_class: "cloudBtn",
          button_caption: t("Upload"),
          remove_header: true,
          multiple: false,
          insert_caption: t("Choose"),
        },
        {
          insertHandler: function (data: any) {
            data.assets.forEach((asset: any) => {
              if (asset) setCurrentAsset(asset);
            });
          },
        },
        document.getElementById("cms-open-btn")
      );
    }
    return () => {
      //@ts-ignore
      window.ml = undefined;
    };
  }, []);

  useEffect(() => {
    if (currentAsset && cmsAsset) {
      cmsAssetVar({ ...cmsAsset, asset: currentAsset });
    }
  }, [currentAsset]);
};
