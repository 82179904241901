import React from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import EditIcon from "@material-ui/icons/Edit";
import {MyTextField, NotificationToast, SelectButton} from "../../../components";
import {
  GetPayouts_getPayouts
} from "../../../graphql/queries/promoters/getPayouts/__generated__/GetPayouts";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import {Currency, PromoterPayoutStatus} from "../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../utils";
import {useGetAllCountries} from "../../../hooks";
import {Autocomplete} from "@material-ui/lab";
import {Country} from "../../../graphql/fragments/__generated__/Country";
import {useUpdatePromoterPayout} from "../../../hooks/mutations/useUpdatePromoterPayout";

interface UpdatePartnerPayoutModalProps {
  payout: GetPayouts_getPayouts
}

export const UpdatePromoterPayoutModal: React.FC<UpdatePartnerPayoutModalProps> =
  ({
     payout
   }) => {
    const {t} = useTranslation();
    const {open, handleOpen, handleClose} = useOpenDialog()
    const { countries, loading: countriesLoading } = useGetAllCountries();
    const {update, error, loading, data} = useUpdatePromoterPayout()

    const initialValues: FormValues = {
      payoutId: payout.id,
      productCommission:payout.productCommission*100,
      subscriptionCommission:payout.subscriptionCommission*100,
      currency: payout.currency,
      failReason: payout.failReason,
      totalAmount: payout.totalAmount,
      bankAccountCurrency: payout.bankAccount?.currency ?? Currency.USD,
      accountName: payout.bankAccount?.accountName ?? "",
      iban: payout.bankAccount?.iban ?? "",
      swift_bic: payout.bankAccount?.swift_bic ?? "",
      countryId: payout.bankAccount?.address?.countryObject?.id ?? "",
      zipCode: payout.bankAccount?.address?.zipCode ?? "",
      state: payout.bankAccount?.address?.state ?? "",
      city: payout.bankAccount?.address?.city ?? "",
      reference: payout.bankAccount?.reference ?? "",
      status: payout.status
    };

    const validationSchema = Yup.object({
      bankAccountCurrency: Yup.string().required(t("Required")),
    });

    return (
      <>
        <IconButton color="primary" onClick={handleOpen}>
          <EditIcon/>
        </IconButton>
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
          <DialogTitle>{t("edit partner payout")}</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, {setErrors, setSubmitting}) => {
              const res=await update({
                variables: {
                  input: {
                    payoutId: values.payoutId,
                    failReason: values.failReason,
                    status: values.status,
                    currency: values.currency,
                    productCommission: values.productCommission/100,
                    subscriptionCommission: values.productCommission/100,
                    totalAmount: values.totalAmount,
                    bankAccount: {
                      accountName: values.accountName,
                      iban: values.iban,
                      reference: values.reference,
                      swift_bic: values.swift_bic,
                      currency: values.bankAccountCurrency,
                      address: {
                        zipCode: values.zipCode,
                        city: values.city,
                        countryId: values.countryId,
                        state: values.state
                      }
                    }
                  }
                },
                onCompleted: () => {
                  handleClose()
                },
              })
              res.data?.updatePromoterPayout && handleClose()
              setSubmitting(false);
            }}
          >
            {({dirty, submitForm, values, setFieldValue}) => (
              <Form>
                <>
                  <Box margin="20px" padding="10px" style={{overflow: "hidden"}}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>

                        <SelectButton
                          rootStyle={{width: "100%"}}
                          squared
                          onChange={(e) => setFieldValue("status", e.target.value)}
                          value={values.status!}
                          options={convertEnumToArray(PromoterPayoutStatus)}
                          label={t("Status")}
                        />
                        <Box height="20px"/>

                        <Field
                          type="number"
                          component={MyTextField}
                          fullWidth
                          label={t("Partner commission")}
                          name="productCommission"
                        />
                        <Box height="20px"/>

                        <Field
                          type="number"
                          component={MyTextField}
                          fullWidth
                          label={t("Subscription commission")}
                          name="subscriptionCommission"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("currency")}
                          name="currency"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("fail reason")}
                          name="failReason"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("totalAmount")}
                          name="totalAmount"
                        />
                        <Box height="20px"/>

                        <SelectButton
                          rootStyle={{width: "100%"}}
                          squared
                          onChange={(e) => setFieldValue("bankAccountCurrency", e.target.value)}
                          value={values.bankAccountCurrency!}
                          options={convertEnumToArray(Currency)}
                          label={t("bankAccountCurrency")}
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("Account name")}
                          name="accountName"
                        />
                        <Box height="20px"/>

                      </Grid>

                      <Grid item xs={6}>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("iban")}
                          name="iban"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("swift_bic")}
                          name="swift_bic"
                        />
                        <Box height="20px"/>

                        <Field
                          name="countryId"
                          value={countries.find(country=> country.id===values.countryId) || null}
                          component={Autocomplete}
                          options={countries}
                          getOptionLabel={(option: Country) => option.rusTrans}
                          onChange={(event: any, newValue: Country) => {
                            setFieldValue("countryId", newValue?.id)
                          }}
                          fullWidth
                          renderInput={(params: any) => (
                            <TextField {...params}
                                           value={countries.find(country=> country.id===values.countryId) || null}
                                           placeholder={t("Country")}
                                           variant="outlined"
                            />)}/>
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("Zip code")}
                          name="zipCode"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("State")}
                          name="state"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("City, address")}
                          name="city"
                        />
                        <Box height="20px"/>

                        <Field
                          component={MyTextField}
                          fullWidth
                          label={t("reference")}
                          name="reference"
                        />
                        <Box height="20px"/>
                      </Grid>
                    </Grid>
                  </Box>
                  <DialogActions>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="end"
                      margin="0 20px"
                    >
                      <Button onClick={handleClose}>{t("Close")}</Button>
                      <Box width="20px"/>
                      <Button disabled={loading} onClick={submitForm}>
                        {t("Save")}
                        {loading && (
                          <CircularProgress style={{marginLeft: "10px"}}/>
                        )}
                      </Button>
                    </Box>
                  </DialogActions>
                </>
              </Form>
            )}
          </Formik>
        </Dialog>
        {error &&
          <NotificationToast
            message={error.message}
            horizontal="center"
            severity="error"
            vertical={"bottom"}
          />
        }
      </>
    );
  };

interface FormValues {
  payoutId: string
  productCommission:number
  subscriptionCommission: number
  currency: string
  failReason: string | null
  totalAmount: number
  bankAccountCurrency: Currency
  accountName: string
  iban: string
  swift_bic: string
  countryId: string
  zipCode: string
  state: string
  city: string
  reference: string
  status: PromoterPayoutStatus
}