import { gql } from "@apollo/client";
import { CountryFragment } from "../../../fragments";

export const GET_COUNTRIES = gql`
  query GetCountries($search: String) {
    getCountries(search: $search) {
      ...Country
    }
  }
  ${CountryFragment}
`;
