import {useMutation} from "@apollo/client";
import {REFUND_ORDER} from "../../graphql/mutations/products/refundOrder";
import {
  RefundOrder,
  RefundOrderVariables
} from "../../graphql/mutations/products/refundOrder/__generated__/RefundOrder";

export const useRefundOrder = () => {
  const [refund, { loading, error }] = useMutation<
    RefundOrder,
    RefundOrderVariables
    >(REFUND_ORDER, {
    onError: (err) => console.error(err),
  });

  return {
    refund,
    loading,
    error,
  };
};
