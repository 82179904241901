import { gql } from "@apollo/client";

export const IS_VERIFICATION_TOKEN_VALID = gql`
  query IsVerificationTokenValid($token: String!) {
    isVerificationTokenValid(token: $token) {
      success
      error {
        name
        message
      }
    }
  }
`;
