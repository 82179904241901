import React from "react";
import { useLayoutStyle } from "./styles";

export const FullscreenLayout: React.FC = ({ children }) => {
  const classes = useLayoutStyle();

  return (
    <div className={classes.fs_root}>
      <div className={classes.fs_header}>
        <div className={classes.fs_separator}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>

      <div className={classes.fs_wrapper}>
        <div className={classes.fs_container}>
          <div className={classes.fs_main}>{children}</div>
        </div>
      </div>
    </div>
  );
};
