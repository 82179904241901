import { colors } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useSkeletonsStyle = makeStyles((theme: Theme) =>
  createStyles({
    emoji: {
      fontSize: "45px",
      filter: "grayscale(1)",
      opacity: 0.5,
      cursor: "default",
    },
    empty__title: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: colors.grey["400"],
    },
  })
);
