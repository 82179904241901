import {useLazyQuery} from "@apollo/client";
import {GET_PAYOUTS} from "../../graphql/queries/promoters/getPayouts";
import {
  GetPayouts, GetPayoutsVariables
} from "../../graphql/queries/promoters/getPayouts/__generated__/GetPayouts";

export const useGetPayouts = () => {
  const [getPayouts, {data, loading, error} ]=
    useLazyQuery<GetPayouts, GetPayoutsVariables>(
      GET_PAYOUTS,
      {
        onError: (err) => console.error(err),
      }
    );

  return {getPayouts, payouts:data?.getPayouts, loading, error};
};