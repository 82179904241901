import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { tagsVar, userVar } from "./apollo";
import { Fallback, FullscreenLoader } from "./components";
import { GET_TAGS } from "./graphql";
import { GetAdminTags } from "./graphql/queries/jobs/GetTags/__generated__/getAdminTags";
import { GetTags } from "./graphql/queries/jobs/GetTags/__generated__/GetTags";
import { useAuthentication, useFetchEnums } from "./hooks";
import { Routes } from "./Routes";

function App() {
  const { processing, error } = useAuthentication();
  const me = useReactiveVar(userVar);
  const { t } = useTranslation();
  const { loading, error: enumsError } = useFetchEnums();
  const { loading: tagsLoading, data } = useQuery<GetAdminTags>(GET_TAGS);

  useEffect(() => {
    if (data) {
      tagsVar(data.getAdminTags);
    }
  }, [data]);

  if (error || enumsError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={
          error ? t("Me Query Failed Message") : t("ServerNotResponding")
        }
      />
    );

  return Boolean(processing || loading || tagsLoading) ? (
    <FullscreenLoader />
  ) : (
    <Routes />
  );
}

export default App;
