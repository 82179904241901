import {useMutation} from "@apollo/client";
import {
  DeletePartnerCompany,
  DeletePartnerCompanyVariables
} from "../../graphql/mutations/partnerCompanies/deletePartnerCompany/__generated__/DeletePartnerCompany";
import {
  DELETE_PARTNER_COMPANY
} from "../../graphql/mutations/partnerCompanies/deletePartnerCompany";
import {
  GET_PARTNER_COMPANIES
} from "../../graphql/queries/partnerCompanies/getPartnerCompanies";

export const useDeletePartnerCompany = () => {
  const [deletePartnerCompany, { loading, error }] = useMutation<
    DeletePartnerCompany,
    DeletePartnerCompanyVariables
    >(DELETE_PARTNER_COMPANY, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PARTNER_COMPANIES,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "latest",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  return {
    deletePartnerCompany,
    loading,
    error,
  };
};