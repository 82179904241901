import {useMutation} from "@apollo/client";
import {
  CreatePartnerCompany,
  CreatePartnerCompanyVariables
} from "../../graphql/mutations/partnerCompanies/createPartnerCompany/__generated__/CreatePartnerCompany";
import {
  CREATE_PARTNER_COMPANY
} from "../../graphql/mutations/partnerCompanies/createPartnerCompany";
import {
  GET_PARTNER_COMPANIES
} from "../../graphql/queries/partnerCompanies/getPartnerCompanies";
import {
  UpdatePartnerCompany, UpdatePartnerCompanyVariables
} from "../../graphql/mutations/partnerCompanies/updatePartnerCompany/__generated__/UpdatePartnerCompany";
import {
  UPDATE_PARTNER_COMPANY
} from "../../graphql/mutations/partnerCompanies/updatePartnerCompany";

export const useUpdatePartnerCompany = () => {
  const [update, { loading, error }] = useMutation<
    UpdatePartnerCompany,
    UpdatePartnerCompanyVariables
    >(UPDATE_PARTNER_COMPANY, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PARTNER_COMPANIES,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "postedAt",
              sortBy: "desc",
            }
          },
        },
      },
    ],
  });

  return {
    update,
    loading,
    error,
  };
};
