import {Box, Grid, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AddNewButton, ArticleCategorySkeleton, Empty, Fallback,} from "../../components";
import {
  GetArticleCategories_getArticleCategories as IArticleCategory
} from "../../graphql/queries/article/GetArticleCategories/__generated__/GetArticleCategories";
import {
  GetJobCategories_getJobCategories
} from "../../graphql/queries/jobs/GetJobCategories/__generated__/GetJobCategories";
import {
  useFetchEnums,
  useGetArticleCategories,
  useGetJobCategories,
  useSetHeadbarTitle,
} from "../../hooks";
import {
  ArticleCategoryCard,
  EditArticleCategory,
  EditJobCategory,
  JobCategoryCard,
} from "./components";

export const Categories: React.FC = () => {
  const { t } = useTranslation();
  useFetchEnums();
  useSetHeadbarTitle(t("Categories"));
  const {
    loading: articlesLoading,
    categories: articleCategories,
    error: articlesError,
  } = useGetArticleCategories();
  const {
    loading: jobsLoading,
    categories: jobCategories,
    error: jobsError,
  } = useGetJobCategories();

  const [articleOpen, setArticleOpen] = useState(false);
  const [jobOpen, setJobOpen] = useState(false);

  const [currentArticleCategory, setCurrentArticleCategory] =
    useState<IArticleCategory | null>(null);
  const [currentJobCategory, setCurrentJobCategory] =
    useState<GetJobCategories_getJobCategories | null>(null);

  const handleArticleOpen = (category: IArticleCategory | null) => {
    setCurrentArticleCategory(category);
    setArticleOpen(true);
  };
  const handleJobOpen = (
    category: GetJobCategories_getJobCategories | null
  ) => {
    setCurrentJobCategory(category);
    setJobOpen(true);
  };

  const handleArticleClose = () => setArticleOpen(false);
  const handleJobClose = () => setJobOpen(false);

  if (articlesError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  const skeletonMarkup = new Array(5).fill(undefined).map((_, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
      <ArticleCategorySkeleton />
    </Grid>
  ));

  const articleCategoriesMarkup = articleCategories?.length ? (
    articleCategories!.map((cat, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <ArticleCategoryCard
          category={cat}
          onClick={() => handleArticleOpen(cat)}
        />
      </Grid>
    ))
  ) : (
    <Empty emoji={"🤔"} title={t("No article categories yet")} />
  );


  const jobCategoriesMarkup = jobCategories?.length ? (
    jobCategories!.map((cat, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <JobCategoryCard category={cat} onClick={() => handleJobOpen(cat)} />
      </Grid>
    ))
  ) : (
    <Empty emoji={"🤔"} title={t("No job categories yet")} />
  );

  return (
    <>
      <Box paddingX={4} paddingY={2}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Article Categories")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddNewButton
            title={t("Add article category")}
            onClick={() => handleArticleOpen(null)}
          />
        </Box>
        <Box paddingTop={3}>
          <Grid container spacing={3}>
            {articlesLoading ? skeletonMarkup : articleCategoriesMarkup}
          </Grid>
        </Box>
      </Box>

      <EditArticleCategory
        category={currentArticleCategory}
        open={articleOpen}
        onClose={handleArticleClose}
      />

      <Box paddingX={4} paddingY={2} marginY={6}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Job Categories")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddNewButton
            title={t("Add job category")}
            onClick={() => handleJobOpen(null)}
          />
        </Box>
        <Box paddingTop={3}>
          <Grid container spacing={3}>
            {jobsLoading ? skeletonMarkup : jobCategoriesMarkup}
          </Grid>
        </Box>
      </Box>

      <EditJobCategory
        category={currentJobCategory}
        open={jobOpen}
        onClose={handleJobClose}
      />
    </>
  );
};
