import {Avatar, Box} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IErrorResponse} from "../../lib";
import {NotificationToast} from "../notifications";
import {useInputStyles} from "./styles";

interface PictureInBase64UploadProps {
  src?: string;
  setPicture: (s: string) => void;
  pictureResolution?:string;
}

export const PictureInBase64Upload: React.FC<PictureInBase64UploadProps>
  = ({src, setPicture,pictureResolution}) => {
  const classes = useInputStyles();
  const [preview, setPreview] = useState<string>(src || '')
  const [sizeError, setSizeError] = useState<IErrorResponse | null>(null);
  const {t} = useTranslation();

  const handleImageUpload = (e: any) => {
    setSizeError(null);
    const file = e.currentTarget.files[0];

    if (file) {
      const fileIsValidSize = file.size / 1024 / 1024 < 2;
      if (!fileIsValidSize) {
        setSizeError({
          name: "general",
          message:
            "You're only able to upload valid image files of under 2MB in size!",
        });
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          setPreview(result);
          setPicture(result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(()=>{
    src && setPreview(src)
  },[src])

  return (
    <Box position="relative">
      <label  className={classes.avatarUpload__label}>
      <input
        accept="image/*"
        className={classes.avatarUpload__input}
        type="file"
        onChange={handleImageUpload}
      />

        <Avatar variant="square" component="span" className={classes.pictureUpload__picture} src={preview}>
          <Box display="flex" flexDirection="column">
            <Box textAlign="center">Загрузите изображение</Box>
            <Box textAlign="center">{pictureResolution}</Box>
          </Box>
        </Avatar>
      </label>

      {sizeError && (
        <NotificationToast
          horizontal="center"
          severity="error"
          vertical="bottom"
          message={t(sizeError.message!)}
        />
      )}
    </Box>
  );
};
