import { gql } from "@apollo/client";
import {OrderFragment} from "../../../fragments";

export const GET_ORDER_BY_ID = gql`
    query GetOrderById($id: String!) {
        getOrderById(id: $id) {
            ...Order
        }
    }
    ${OrderFragment}
`;