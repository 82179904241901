import {Button, Dialog, DialogContent, IconButton, Link,} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {NotificationToast} from "../../../components";
import {useMutation} from "@apollo/client";
import {
  GetBanners,
  GetBanners_getBanners
} from "../../../graphql/queries/banners/getBanners/__generated__/GetBanners";
import {DELETE_BANNER} from "../../../graphql/mutations/banners/deleteBanner";
import {
  DeleteBanner,
  DeleteBannerVariables
} from "../../../graphql/mutations/banners/deleteBanner/__generated__/DeleteBanner";
import {GET_BANNERS} from "../../../graphql/queries/banners/getBanners";
import {GetBannersInput} from "../../../graphql/globalTypes";

interface DeleteBannerDialogProps {
  banner: GetBanners_getBanners;
  input: GetBannersInput
}

export const DeleteBannerDialog: React.FC<DeleteBannerDialogProps> = ({banner, input}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const [deleteBanner, {loading, error }] = useMutation<DeleteBanner,
    DeleteBannerVariables>(DELETE_BANNER, {
    onError: (err) => console.error(err),
    update (cache){
      const {getBanners} = cache.readQuery({query:GET_BANNERS, variables:{input}}) as GetBanners
      cache.writeQuery({
        query: GET_BANNERS,
        variables: {input},
        data: {
          getBanners:getBanners.filter((b)=>b.id !== banner.id)
        }
      })
    }
  });

  const handleDelete = async () => {
    await deleteBanner({
      variables: {
        id: banner.id
      }
    })
    handleClose()
  }
  return (
    <>
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleOpen()
        }}
      >
        {t("Delete")}
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Box textAlign="center">
            <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
          </Box>
          <Box textAlign="center"
               fontWeight="600"
               fontSize="16px">
            {t("Do you really want to delete VAR?", {name: banner.title})}
          </Box>
        </DialogContent>
        <Box display="flex" margin="20px" gap="30px" justifyContent="center">
          <Button disabled={loading} onClick={handleClose} variant="contained"
                  color="primary">
            {t("Cancel")}
          </Button>
          <Button disabled={loading} onClick={handleDelete} variant="contained"
                  autoFocus>
            {t("Delete")}
          </Button>
        </Box>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};