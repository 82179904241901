import {
  Box,
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInputStyles } from "./styles";

interface TagsProps {
  pushValue: (t: string) => void;
  popValue: (t: string) => void;
  value: string[];
  title: string;
}

export const Tags: React.FC<TagsProps> = ({
  pushValue,
  popValue,
  value,
  title,
}) => {
  const classes = useInputStyles();
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState("");
  const [exists, setExists] = useState(false);

  const handleAdd = () => {
    const v = currentValue.toLowerCase().trim();
    if (value.includes(v)) {
      setExists(true);
    } else {
      pushValue(v);
      setCurrentValue("");
    }
  };

  const handleChange = (e: any) => {
    if (exists) setExists(false);
    setCurrentValue(e.target.value);
  };

  const handleDelete = (v: string) => {
    popValue(v);
  };

  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>

      <Paper className={classes.tags__paper}>
        <TextField
          variant="standard"
          fullWidth
          value={currentValue}
          label={t("New tag")}
          InputLabelProps={{ shrink: true }}
          style={{ flex: 2 }}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.code === "Enter" && currentValue) {
              e.preventDefault();
              handleAdd();
            }
          }}
        />
        <Button
          onClick={handleAdd}
          fullWidth
          style={{ flex: 1 }}
          disabled={!currentValue}
        >
          {t("Add")}
        </Button>
      </Paper>

      {exists && (
        <Typography color="error" variant="caption" style={{ marginLeft: 10 }}>
          {t("Tag already exists")}
        </Typography>
      )}

      <Box margin={2} />

      <Box>
        {value.map((v) => (
          <Chip
            key={v}
            label={v}
            onDelete={v === "remote" ? undefined : () => handleDelete(v)}
            color="default"
            style={{ margin: 3 }}
            classes={{
              label: classes.chip__label,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
