import { useMutation } from "@apollo/client";
import { UPDATE_ARTICLE } from "../../graphql";
import {
  UpdateArticle,
  UpdateArticleVariables,
} from "../../graphql/mutations/articles/UpdateArticle/__generated__/UpdateArticle";

export const useUpdateArticle = () => {
  const [update, { loading, error }] = useMutation<
    UpdateArticle,
    UpdateArticleVariables
  >(UPDATE_ARTICLE, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};
