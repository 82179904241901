import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GET_ARTICLE } from "../graphql";
import { CreateArticle_createArticle_article as IArticle } from "../graphql/mutations/articles/CreateArticle/__generated__/CreateArticle";
import {
  GetArticle,
  GetArticleVariables,
} from "../graphql/queries/article/GetArticle/__generated__/GetArticle";

interface EditArticleParams {
  id: string;
}

interface EditArticleState {
  article?: IArticle;
  showCreateNotification?: boolean;
}

export const useFetchArticleFromRoute = () => {
  const { id } = useParams<EditArticleParams>();
  const location = useLocation<EditArticleState>();
  const [processing, setProcessing] = useState(true);
  const [article, setArticle] = useState<IArticle | null>(null);
  const [getArticle, { error, data }] = useLazyQuery<
    GetArticle,
    GetArticleVariables
  >(GET_ARTICLE, {
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (location.state?.article) {
      setArticle(location.state.article);
      setProcessing(false);
    } else {
      getArticle({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getArticle) {
      setArticle(data.getArticle);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    article,
    showCreateNotification: location.state?.showCreateNotification,
  };
};
