import React from "react";
import type { MuiButtonSettings } from "../types/settings";
import { lazyLoad } from "@react-page/editor";

const BtnIcon = lazyLoad(() => import("@material-ui/icons/TouchApp"));

export const defaultTranslations = {
  pluginName: "Кнопка",
  pluginDescription: "Выбрать одну из стандартных кнопок",
  isInlineable: true,
  titleLabel: "Текст на кнопке",
  fullWidth: "На всю ширину",
  urlLabel: "Ссылка",
  isDownloadableLabel: "Скачиваемая",
  alignLabel: "Выравнивание",
  colorLabel: "Цвет",
  Primary: "Основной",
  Secondary: "Акцент",
  Default: "По умолчанию",
  variantLabel: "Тип",
  contained: "Объем",
  text: "Текст",
  outlined: "Рамка",
};

export const defaultSettings: MuiButtonSettings = {
  Renderer: () => <>Renderer for this plugin was not provided </>,
  Controls: () => <> Controls for this plugin were not provided</>,
  translations: defaultTranslations,
  icon: <BtnIcon />,
};
