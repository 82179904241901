import { Box } from "@material-ui/core";
import { CellPluginComponentProps } from "@react-page/editor";
import React, { useEffect } from "react";
import { defaultMuiIconState } from "../default/state";
import { MuiIconState } from "../types/state";

const MuiIconHtmlRenderer: React.FC<CellPluginComponentProps<MuiIconState>> = (
  props
) => {
  const { data } = props;
  const Icon = data.icon;

  useEffect(() => {
    props.onChange(defaultMuiIconState);
  }, []);

  if (!Icon) return null;

  return (
    <Box display="flex" justifyContent={data.align}>
      <Icon
        style={{
          color: data.color,
          fontSize: data.size,
        }}
      />
    </Box>
  );
};

export default MuiIconHtmlRenderer;
