import { gql } from "@apollo/client";
import {ProductFragment} from "../../../fragments";

export const CREATE_PRODUCT = gql`
    mutation CreateProduct($input: CreateProductInput!) {
        createProduct(input: $input) {
                ...Product
        }
    }
    ${ProductFragment}
`;