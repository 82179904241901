import {useQuery} from "@apollo/client";
import {
  GetExpertCompanies
} from "../../../graphql/queries/experts/getExpertCompanies/__generated__/GetExpertCompanies";
import {GET_EXPERT_COMPANIES} from "../../../graphql";

export const useGetExpertCompanies = () => {
  const { loading, error, data } = useQuery<GetExpertCompanies>(
    GET_EXPERT_COMPANIES,
    {
      onError: (err) => console.error(err),
    }
  );

  return { loading, error, expertCompanies: data?.getExpertCompanies };
};