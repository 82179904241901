import {useMutation} from "@apollo/client";
import {CREATE_EXPERT_REQUEST, GET_EXPERT_REQUESTS} from "../../../graphql";
import {
  CreateExpertRequest,
  CreateExpertRequestVariables
} from "../../../graphql/mutations/experts/createExpertRequest/__generated__/CreateExpertRequest";

export const useCreateExpertRequest = () => {
  const [create, { loading}] = useMutation<
    CreateExpertRequest,
    CreateExpertRequestVariables
    >(CREATE_EXPERT_REQUEST, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_EXPERT_REQUESTS,
      },
    ],
  });

  return { create, loading };
};
