import {
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import {
  ClearOutlined,
  SearchOutlined as SearchIcon,
} from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface TextSearchProps {
  rootStyle?: React.CSSProperties;
  onClick: (v: string) => void;
  onReset: () => void;
  hint: string;
  isSmall?:boolean;
}

export const TextSearch: React.FC<TextSearchProps> = ({
  rootStyle,
  onClick,
  onReset,
  hint,
  isSmall
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleChange = (e: any) => {
    setValue(e.target.value);
    if (!e.target.value) onReset();
  };
  const handleClear = () => {
    setValue("");
    onReset();
  };

  return (
    <Tooltip title={hint}>
      <TextField
        size={isSmall ? "small" : "medium"}
        fullWidth
        style={rootStyle}
        value={value}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {value.length !== 0 && (
                <ClearOutlined
                  style={{ cursor: "pointer" }}
                  onClick={handleClear}
                  fontSize="small"
                />
              )}
              <Button
                color="default"
                style={{ color: theme.palette.text.secondary }}
                disabled={value.length < 3}
                onClick={() => onClick(value)}
              >
                {t("Search")}
              </Button>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </Tooltip>
  );
};
