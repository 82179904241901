import { gql } from "@apollo/client";
import { MetaFragment, ReferralFragment } from "../../../fragments";

export const GET_REFERRALS = gql`
  query GetReferrals(
    $query: ReferralsFilter!
    $paginationOptions: PaginationOptionsArgs!
  ) {
    getReferrals(query: $query, paginationOptions: $paginationOptions) {
      meta {
        ...Meta
      }
      referrals {
        ...Referral
      }
    }
  }
  ${ReferralFragment}
  ${MetaFragment}
`;
