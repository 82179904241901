import { Box, Chip, colors, Link, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  AddNewButton,
  BulkActions,
  FullscreenLoader,
  MyTable,
  NotificationToast,
} from "../../../components";
import { PaginationOptionsArgs } from "../../../graphql/globalTypes";
import { GetArticlesByAdmin_getArticlesByAdmin_articles as IArticle } from "../../../graphql/queries/article/GetArticles/__generated__/GetArticlesByAdmin";
import { useDuplicateArticle } from "../../../hooks";
import { HeadCell } from "../../../lib";
import { ArticleBulkAction } from "./ArticleBulkAction";
import {useMutation} from "@apollo/client";
import {
  DeleteTrackerItem,
  DeleteTrackerItemVariables
} from "../../../graphql/mutations/jobApplicationTracker/deleteTrackerItem/__generated__/DeleteTrackerItem";
import {
  DELETE_TRACKER_ITEM
} from "../../../graphql/mutations/jobApplicationTracker/deleteTrackerItem";
import {
  GET_TRACKER_ITEMS
} from "../../../graphql/queries/jobApplicationTracker/getTrackerItems";
import {
  DeleteArticle, DeleteArticleVariables
} from "../../../graphql/mutations/articles/DeleteArticle/__generated__/DeleteArticle";
import {DELETE_ARTICLE} from "../../../graphql/mutations/articles/DeleteArticle";
import {GET_ARTICLES} from "../../../graphql";

interface ArticlesTableProps {
  loading: boolean;
  articles: IArticle[];
  totalArticles: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
}

export const ArticlesTable: React.FC<ArticlesTableProps> = ({
  articles,
  paginationOptions,
  setPaginationOptions,
  totalArticles,
  loading,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selected, setSelected] = useState<string[]>([]);
  const [currentAction, setCurrentAction] = useState<
    "Update status" | "Update read access" | ""
  >("");
  const {
    duplicate: makeDuplicate,
    loading: duplicateLoading,
    error,
  } = useDuplicateArticle();

  const [deleteArticle, {loading: delelteLoading, error:deleteError}] = useMutation<DeleteArticle,
    DeleteArticleVariables>(DELETE_ARTICLE, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ARTICLES,
        variables: {
          input: {},
          paginationOptions: {},
        },
      },
    ],
  });

  const duplicate = useCallback(async (id: string) => {
    const res = await makeDuplicate({ variables: { id } });
    if (res.data?.duplicateArticle) {
      history.push({
        pathname: `/articles/edit/${res.data!.duplicateArticle.id}`,
        state: {
          article: res.data!.duplicateArticle,
          showCreateNotification: true,
        },
      });
    }
  }, []);

  const mapArticlesToRows = useMemo(() => {
    const arr = articles.map((article) => ({
      id: article.id,
      title: (
        <Box width={400}>
          <Typography variant="subtitle2">{article.title}</Typography>
          <Typography variant="caption">{article.description}</Typography>

          <Box marginTop={1} display="flex">
            <Link
              component={RouterLink}
              onClick={(e) => e.stopPropagation()}
              to={`/articles/view/${article.id}`}
              target="_blank"
            >
              {t("View")}
            </Link>
            <Box width={20} />
            <Link
              onClick={(e) => e.stopPropagation()}
              component={RouterLink}
              to={`/articles/edit/${article.id}`}
            >
              {t("Edit")}
            </Link>
            <Box width={20} />
            <Link
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                duplicate(article.id);
              }}
            >
              {t("Duplicate")}
            </Link>
            <Box width={20} />
            <Link
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                deleteArticle({variables: {id: article.id}});
              }}
            >
              {t("Delete")}
            </Link>
          </Box>
        </Box>
      ),
      status: t(article.status),
      readAccess: t(article.readAccess),
      viewCount: article.viewCount,
      updated_at: moment(article.updated_at).fromNow(),
      flags: (
        <>
          {article.hideHalf && (
            <Chip
              style={{ background: colors.lime["100"] }}
              label={t("half banner")}
            />
          )}
          {article.isFeatured && (
            <Chip
              style={{ background: colors.deepPurple["100"] }}
              label={t("Featured")}
            />
          )}
          {article.hiddenFromArticles && (
            <Chip
              style={{ background: colors.red["100"] }}
              label={t("hidden article")}
            />
          )}
          {article.isPinned && (
            <Chip
              style={{ background: colors.red["100"] }}
              label={t("is pinned")}
            />
          )}
          {article.withVideo && (
            <Chip
              style={{ background: colors.green["300"] }}
              label={t("Video content")}
            />
          )}

        </>
      ),
    }));
    return arr;
  }, [articles]);

  const handleAddNew = () => {
    history.push("/articles/create");
  };

  const applyAction = (option: string) => {
    setCurrentAction(option as any);
  };

  return (
    <>
      <Box width="100%">
        <MyTable
          selected={selected}
          loading={loading}
          total={totalArticles}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          setSelected={setSelected}
          headCells={headCells}
          values={mapArticlesToRows}
          sortFields={["viewCount", "updated_at"]}
          toolbarRightButton={
            <AddNewButton onClick={handleAddNew} title={t("Add Article")} />
          }
          bulkActions={
            <BulkActions
              onApply={(option) => applyAction(option)}
              options={bulkOptions}
            />
          }
        />
      </Box>

      <ArticleBulkAction
        currentAction={currentAction}
        articleIds={selected}
        onClose={() => setCurrentAction("")}
        setSelected={setSelected}
      />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}

      {(duplicateLoading || delelteLoading) && <FullscreenLoader transparent />}
    </>
  );
};

const bulkOptions = ["Update status", "Update read access"];

const headCells: HeadCell[] = [
  {
    label: "title",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "readAccess",
    align: "left",
  },
  {
    label: "viewCount",
    align: "center",
  },
  {
    label: "updated_at",
    align: "left",
  },
  {
    label: "flags",
    align: "left",
  },
];
