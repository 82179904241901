import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AtomProgress, NotificationToast } from "../../components";
import {
  GetArticlesAdminInput,
  PaginationOptionsArgs,
} from "../../graphql/globalTypes";
import { GET_ARTICLES } from "../../graphql/queries/article/GetArticles";
import {
  GetArticlesByAdmin,
  GetArticlesByAdminVariables,
  GetArticlesByAdmin_getArticlesByAdmin_articles as IArticle,
} from "../../graphql/queries/article/GetArticles/__generated__/GetArticlesByAdmin";
import { useSetHeadbarTitle } from "../../hooks";
import { ArticlesFilterBar, ArticlesTable } from "./components";

export const Articles: React.FC = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Articles"));

  const [articles, setArticles] = useState<IArticle[]>([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [filterParams, setFilterParams] = useState<GetArticlesAdminInput>({
    categoriesIds: undefined,
    readAccess: undefined,
    status: undefined,
    textSearch: undefined,
  });
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "updated_at",
        sortBy: "desc",
      },
    });
  const { data, loading, error } = useQuery<
    GetArticlesByAdmin,
    GetArticlesByAdminVariables
  >(GET_ARTICLES, {
    onError: (err) => console.error(err),
    variables: {
      input: filterParams,
      paginationOptions,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      skip: 0,
    }));
  }, [
    filterParams.categoriesIds,
    filterParams.readAccess,
    filterParams.status,
    filterParams.textSearch,
  ]);

  useEffect(() => {
    if (data?.getArticlesByAdmin) {
      setArticles(data.getArticlesByAdmin.articles);
      setTotalArticles(data.getArticlesByAdmin.total);
    }
  }, [data]);

  return (
    <>
      <ArticlesFilterBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />

      <Box padding={3} marginTop={4}>
        {loading && (
          <Box
            position="absolute"
            marginTop="5px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <ArticlesTable
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        articles={articles}
        totalArticles={totalArticles}
        loading={loading}
      />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
