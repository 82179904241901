import { gql } from "@apollo/client";
import { EnumWithStatFragment } from "../../../fragments";

export const FETCH_ENUMS_WITH_STATS = gql`
  query FetchEnumsWithStats {
    fetchEnumsWithStats {
      jobCategories {
        ...EnumWithStat
      }
      employmentTypes {
        ...EnumWithStat
      }
      seniorities {
        ...EnumWithStat
      }
    }
  }
  ${EnumWithStatFragment}
`;
