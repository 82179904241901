import {gql} from "@apollo/client";
import {PartnerCompanyFragment} from "../../../fragments";

export const GET_PARTNER_COMPANY_BY_ID = gql`
    query GetPartnerCompanyById($id: String!) {
        getPartnerCompanyById(id: $id) {
            ...PartnerCompany
        }
    }
    ${PartnerCompanyFragment}
`;
