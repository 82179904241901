import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useLayoutStyle } from "./styles";

export const EditorLayout: React.FC = ({ children }) => {
  const classes = useLayoutStyle();
  return (
    <Box display="flex">
      <CssBaseline />

      <AppBar position="fixed">
        <Toolbar>
          <Typography>Networkio Article Editor</Typography>
        </Toolbar>
      </AppBar>

      <main className={classes.dl_content}>
        <Box className={classes.drawerHeader} />
        {children}
      </main>
    </Box>
  );
};
