import {useMutation} from "@apollo/client";
import {
  CreateProduct,
  CreateProductVariables
} from "../../graphql/mutations/products/createProduct/__generated__/CreateProduct";
import {CREATE_PRODUCT} from "../../graphql/mutations/products/createProduct";
import {GET_PRODUCTS} from "../../graphql/queries/products/getProducts";

export const useCreateProduct = () => {
  const [create, { loading, error }] = useMutation<
    CreateProduct,
    CreateProductVariables
    >(CREATE_PRODUCT, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          filter: {},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "postedAt",
              sortBy: "desc",
            }
          },
        },
      },
    ],
  });

  return {
    create,
    loading,
    error,
  };
};