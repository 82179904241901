import { useQuery } from "@apollo/client";
import { GET_JOB_CATEGORIES } from "../../graphql";
import { GetJobCategories } from "../../graphql/queries/jobs/GetJobCategories/__generated__/GetJobCategories";

export const useGetJobCategories = () => {
  const { data, loading, error } = useQuery<GetJobCategories>(
    GET_JOB_CATEGORIES,
    {
      onError: (err) => console.log(err),
    }
  );

  return {
    categories: data?.getJobCategories,
    loading,
    error,
  };
};
