import { useQuery } from "@apollo/client";
import { GET_JOBS } from "../../graphql";
import {
  AdminGetJobs,
  AdminGetJobsVariables,
} from "../../graphql/queries/jobs/AdminGetJobs/__generated__/AdminGetJobs";

export const useGetJobs = (variables: AdminGetJobsVariables) => {
  const { data, loading, error } = useQuery<
    AdminGetJobs,
    AdminGetJobsVariables
  >(GET_JOBS, {
    onError: (err) => console.error(err),
    variables,
  });

  return { data, loading, error };
};
