import {
  Box,
  Fab,
  FormControlLabel,
  Grid,
  IconButton, Paper,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import {
  Looks3,
  Looks4,
  Looks5,
  LooksOne,
  LooksTwo,
  OpenInNew,
  SaveOutlined as SaveIcon
} from "@material-ui/icons";
import {IconContainerProps, Rating} from "@material-ui/lab";
import {Field} from "formik";
import {CheckboxWithLabel} from "formik-material-ui";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  MyTextField,
  NotificationToast,
  PictureUpload,
  SelectButton,
  Tags,
} from "../../../components";
import {
  AppLanguage,
  CreateArticleInput,
  PublishStatus,
  UpdateArticleInput,
} from "../../../graphql/globalTypes";
import {useGetUsers} from "../../../hooks";
import {ArticlesFormValues, IPicUpload, ReadAccess, UserRole,} from "../../../lib";
import {useCommonStyle} from "../../../styles/commonStyles";
import {convertEnumToArray} from "../../../utils";
import {EditArticleCategories} from "./EditArticleCategories";
import {appLanguages} from "../../../lib/constants";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";

interface EditArticlesLeftProps {
  input: CreateArticleInput | UpdateArticleInput;
  setInput: React.Dispatch<
    React.SetStateAction<CreateArticleInput | UpdateArticleInput>
  >;
  values: ArticlesFormValues;
  picUpload: IPicUpload | null;
  setPicUpload: (p: IPicUpload | null) => void;
  showEditContent: boolean;
  handleSubmit: () => void;
  disabled: boolean;
  imageUrl?: string;
  id?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const EditArticlesLeft: React.FC<EditArticlesLeftProps> = ({
                                                                    input,
                                                                    setInput,
                                                                    values,
                                                                    disabled,
                                                                    imageUrl,
                                                                    id,
                                                                    picUpload,
                                                                    setPicUpload,
                                                                    showEditContent,
                                                                    handleSubmit,
                                                                    setFieldValue
                                                                  }) => {
  const commonClasses = useCommonStyle();
  const {t} = useTranslation();
  const [contentOrigin, setContentOrigin] = useState(values.articleText ? "admin panel" : "builder")
  const {
    data: authorsData,
    loading: authorsLoading,
    error: authorsError,
  } = useGetUsers({
    paginationOptions: {},
    input: {
      roles: [UserRole.COACH, UserRole.ADMIN, UserRole.MODERATOR],
    },
  });

  const getAuthorsNames = useMemo(() => {
    let authors: string[] = [];
    if (authorsData) {
      authors = authorsData.getUsers.users.map(
        (a) => a.firstName + " " + a.lastName
      );
    }
    return [...authors, "Networkio"];
  }, [authorsData]);

  const getAuthorNameById = useMemo(() => {
    const found = authorsData?.getUsers.users.find(
      (author) => author.id === input.authorId
    );
    if (found) return found.firstName + " " + found.lastName;
    return "Networkio";
  }, [authorsData, input]);

  const handleStatusChange = (e: any) => {
    setInput((prev) => ({
      ...prev,
      status: e.target.value,
    }));
  };

  const popTag = useCallback((t: string) => {
    setInput((prev) => ({
      ...prev,
      tags: prev.tags!.filter((c) => c !== t),
    }));
  }, []);

  const pushTag = useCallback((t: string) => {
    setInput((prev) => ({
      ...prev,
      tags: prev.tags ? [...prev.tags, t] : [t],
    }));
  }, []);

  const handleReadAccessChange = (e: any) => {
    setInput((prev) => ({
      ...prev,
      readAccess: e.target.value,
    }));
  };

  const handleSetFeatureOrder = (newValue: any) => {
    setInput((prev) => ({
      ...prev,
      featureOrder: newValue,
    }));
  };

  const handleSetAuthor = (e: any) => {
    if (e.target.value === "Networkio") {
      setInput((prev) => ({
        ...prev,
        authorId: undefined,
      }));
    } else {
      setInput((prev) => ({
        ...prev,
        authorId: authorsData?.getUsers.users.find(
          (author) =>
            author.firstName + " " + author.lastName === e.target.value
        )?.id,
      }));
    }
  };

  const handleLanguageChange = (newValue: any) => {
    setInput((prev) => ({
      ...prev,
      language: newValue.target.value,
    }));
  };

  return (
    <Box className={commonClasses.sectionWithSticky__scrollable}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Field
            component={MyTextField}
            fullWidth
            label={t("Title")}
            name="title"
            style={{marginBottom: 20}}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={MyTextField}
            fullWidth
            label={t("slug")}
            name="slug"
            style={{marginBottom: 20}}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Field
                component={MyTextField}
                fullWidth
                label={t("description")}
                name="description"
                multiline
                maxRows={4}
                rows={4}
                style={{marginBottom: 20}}
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <SelectButton
                  label={t("status")}
                  rootStyle={{width: "100%"}}
                  options={convertEnumToArray(PublishStatus)}
                  value={input.status!}
                  onChange={handleStatusChange}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectButton
                  label={t("readAccess")}
                  rootStyle={{width: "100%"}}
                  options={convertEnumToArray(ReadAccess)}
                  value={input.readAccess!}
                  onChange={handleReadAccessChange}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectButton
                  label={t("author")}
                  loading={authorsLoading}
                  rootStyle={{width: "100%"}}
                  options={getAuthorsNames}
                  value={getAuthorNameById}
                  onChange={handleSetAuthor}
                  noOptionTranslation
                />
              </Grid>
            </Grid>

            <Grid item>
              <Box height="20px"/>
              <Typography variant="body2" color="textSecondary">
                {t("Language")}
              </Typography>
              <RadioGroup row value={input.language} onChange={handleLanguageChange}>
                {convertEnumToArray(AppLanguage).map(lng =>
                  <FormControlLabel value={lng} control={<Radio/>}
                                    label={appLanguages[lng]}
                                    key={lng}/>)}
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box height="40vh" display="flex">
            <PictureUpload
              imageUrl={imageUrl}
              picUpload={picUpload}
              setPicUpload={setPicUpload}
            />
          </Box>
        </Grid>
      </Grid>

      <Box height="20px"/>

      <Typography variant="body2" color="textSecondary">
        {t("Content origin")}
      </Typography>
      <RadioGroup row value={contentOrigin}
                  onChange={(e) => setContentOrigin(e.target.value)}>
        <FormControlLabel value={"builder"} control={<Radio/>}
                          label={"builderio"}
                          key={"builder"}/>
        <FormControlLabel value={"admin panel"} control={<Radio/>}
                          label={"admin panel"}
                          key={"adminPanel"}/>
      </RadioGroup>

      {contentOrigin === "builder" && (
        <Box
          display="flex"
          width={"50%"}
          alignItems="center"
          justifyContent="space-between"
          marginTop={2}
        >
          <Field
            component={MyTextField}
            fullWidth
            label={t("Content")}
            name="content"
          />
          <IconButton href={`/articles/view/${id}`} target="_blank">
            <OpenInNew/>
          </IconButton>
        </Box>
      )}

      {contentOrigin === "admin panel" &&
        <>

          <Typography variant="overline">{t("Button")}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                component={MyTextField}
                fullWidth
                label={t("Button title")}
                name="buttonTitle"
                style={{marginBottom: 20}}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={MyTextField}
                fullWidth
                label={t("Button link")}
                name="buttonLink"
                style={{marginBottom: 20}}
              />
            </Grid>
          </Grid>

          <Typography variant="overline">{t("Video")}</Typography>
          <Grid item xs={4}>
            <Field
              component={MyTextField}
              fullWidth
              label={t("Video")}
              name="video"
              style={{marginBottom: 20}}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="overline">{t("Editor")}</Typography>
            <Paper style={{padding: 10, minHeight: "600px"}}>
              <Editor
                toolbar={{
                  fontFamily: {
                    options: ['Arial', 'Roboto', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  }
                }}
                editorState={values.articleText}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(editorState: EditorState) =>
                  setFieldValue ? setFieldValue("articleText", editorState) : undefined
                }
              />
            </Paper>
          </Grid>
        </>}

      <Box height="20px"/>
      <Box marginTop={3}/>
      <Typography variant="body2" color="textSecondary">
        {t("flags")}
      </Typography>

      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={3}>
          <Field
            component={CheckboxWithLabel}
            name="withVideo"
            type="checkbox"
            Label={{label: t("Video content")}}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={CheckboxWithLabel}
            name="isPinned"
            type="checkbox"
            Label={{label: t("is pinned")}}
          />
        </Grid>

        {input.readAccess !== ReadAccess.UNANYMOUS &&
          <Grid item xs={3}>
            <Field
              component={CheckboxWithLabel}
              name="hideHalf"
              type="checkbox"
              Label={{label: t("half banner")}}
            />
          </Grid>
        }

        <Grid item xs={3}>
          <Field
            component={CheckboxWithLabel}
            name="hiddenFromArticles"
            type="checkbox"
            Label={{label: t("hidden article")}}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={CheckboxWithLabel}
            name="isFeatured"
            type="checkbox"
            Label={{label: t("Featured")}}
          />
        </Grid>

        {values.isFeatured && (
          <Grid item xs={3}>
            <Box
              display="flex"
              alignItems="center"
            >
              <Typography>{t("Priority")}: </Typography>
              <Box width={5}/>
              <Rating
                name="customized-icons"
                defaultValue={input.featureOrder!}
                precision={1}
                IconContainerComponent={IconContainer}
                onChange={(event, newValue) => {
                  handleSetFeatureOrder(newValue);
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>


      <Box marginTop={3}/>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item style={{flex: 1}}>
          <EditArticleCategories input={input} setInput={setInput}/>
        </Grid>
        <Grid item style={{flex: 1}}>
          <Tags
            value={input.tags ?? []}
            popValue={popTag}
            pushValue={pushTag}
            title={t("Tags")}
          />
        </Grid>
      </Grid>

      <Box marginTop={3}/>

      <Box textAlign={"center"}>
        <Fab
          color="secondary"
          variant="extended"
          size="large"
          style={{width: "20%"}}
          id="bottomBtn"
          onClick={handleSubmit}
          disabled={disabled}
        >
          <SaveIcon style={{marginRight: 10}}/>
          {t("Save")}
        </Fab>
      </Box>

      <Box marginTop={3}/>

      {authorsError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};

function IconContainer(props: IconContainerProps) {
  const {value, ...other} = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const customIcons: {
  [index: string]: { icon: React.ReactElement; label: string };
} = {
  1: {
    icon: <LooksOne/>,
    label: "1",
  },
  2: {
    icon: <LooksTwo/>,
    label: "2",
  },
  3: {
    icon: <Looks3/>,
    label: "3",
  },
  4: {
    icon: <Looks4/>,
    label: "4",
  },
  5: {
    icon: <Looks5/>,
    label: "5",
  },
};


