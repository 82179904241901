import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { DeleteOutlineOutlined as DeleteIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface DeleteButtonProps {
  deleteAction: () => Promise<void>;
  loading: boolean;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  deleteAction,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    try {
      await deleteAction();
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Confirm delete")}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            {t("Cancel")}
          </Button>
          <Button onClick={handleDelete} disabled={loading}>
            {t("Ok")}
            {loading && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={12}
                color="secondary"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
