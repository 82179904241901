import { useMutation } from "@apollo/client";
import { GET_JOB_CATEGORIES, UPDATE_JOB_CATEGORY } from "../../graphql";
import {
  UpdateJobCategory,
  UpdateJobCategoryVariables,
} from "../../graphql/mutations/jobs/UpdateJobCategory/__generated__/UpdateJobCategory";

export const useUpdateJobCategory = () => {
  const [update, { data, loading, error }] = useMutation<
    UpdateJobCategory,
    UpdateJobCategoryVariables
  >(UPDATE_JOB_CATEGORY, {
    onError: (err) => console.log(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_JOB_CATEGORIES,
      },
    ],
  });

  return {
    update,
    data,
    loading,
    error,
  };
};
