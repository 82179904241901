import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NotificationToast } from "../notifications";
import { useTranslation } from "react-i18next";
import {
  DELETE_BULK_ENUM,
  FETCH_ENUMS_WITH_STATS,
  REPLACE_BULK_ENUM,
} from "../../graphql";
import {
  DeleteBulkEnum,
  DeleteBulkEnumVariables,
} from "../../graphql/mutations/jobs/DeleteBulkEnum/__generated__/DeleteBulkEnum";
import { EnumRepoName } from "../../lib";
import {
  ReplaceBulkEnum,
  ReplaceBulkEnumVariables,
} from "../../graphql/mutations/jobs/ReplaceBulkEnum/__generated__/ReplaceBulkEnum";

interface BulkEnumActionProps {
  onClose: () => void;
  open: boolean;
  repoName: EnumRepoName;
  action: "Replace" | "Delete" | "";
  ids: string[];
  setChecked: (s: string[]) => void;
}

export const BulkEnumAction: React.FC<BulkEnumActionProps> = ({
  onClose,
  open,
  repoName,
  action,
  ids,
  setChecked,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [nameExists, setNameExists] = useState("");

  const [replace, { loading: replaceLoading, error: replaceError }] =
    useMutation<ReplaceBulkEnum, ReplaceBulkEnumVariables>(REPLACE_BULK_ENUM, {
      onError: (err) => console.error(err),
      awaitRefetchQueries: true,
      refetchQueries: [{ query: FETCH_ENUMS_WITH_STATS }],
      onCompleted: (res) => {
        if (res.replaceBulkEnum.enum) {
          onClose();
          setChecked([]);
        }
      },
    });

  const [deleteBulk, { loading: deleteLoading, error: deleteError }] =
    useMutation<DeleteBulkEnum, DeleteBulkEnumVariables>(DELETE_BULK_ENUM, {
      onError: (err) => console.error(err),
      update: (cache) => {
        ids.forEach((id) => {
          const normalizedId = cache.identify({
            id,
            __ref: `Enum_Gql:${id}`,
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        });
      },
      onCompleted: () => {
        onClose();
        setChecked([]);
      },
    });

  useEffect(() => {
    if (!open) setName("");
  }, [open]);

  const applyAction = async () => {
    setNameExists("");
    if (action === "Delete") {
      deleteBulk({ variables: { input: { ids, repoName } } });
    } else if (action === "Replace" && name) {
      const res = await replace({
        variables: { input: { ids, name, repoName } },
      });
      if (res.data?.replaceBulkEnum.error) {
        setNameExists(t("Name already exists"));
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {action === "Delete" ? t("Confirm delete") : t("Enter new name")}
        </DialogTitle>

        {action === "Replace" && (
          <DialogContent>
            <TextField
              variant="outlined"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
              helperText={nameExists}
              error={!!nameExists}
              label={t("Name")}
            />
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={onClose}>{t("Cancel")}</Button>
          <Button
            disabled={deleteLoading}
            onClick={applyAction}
            color="primary"
            variant="contained"
          >
            {t("Ok")}
            {(deleteLoading || replaceLoading) && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={12}
                color="secondary"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {(deleteError || replaceError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
