import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PictureUpload } from "../../../../components";
import {
  GET_LEARNING_PATH_BY_SLUG,
  UPDATE_LEARNING_PATH,
} from "../../../../graphql";
import {
  UpdateLearningPath,
  UpdateLearningPathVariables,
} from "../../../../graphql/mutations/learningPath/updateLearningPath/__generated__/UpdateLearningPath";
import { GetLearningPathBySlug_getLearningPathBySlug } from "../../../../graphql/queries/learningPath/getLearningBySlug/__generated__/GetLearningPathBySlug";
import { FileType, IPicUpload } from "../../../../lib";
import { getFileNameFromPublicId } from "../../../../utils";
import ReactPlayer from "react-player";

interface UpdateMediaProps {
  learningPath: GetLearningPathBySlug_getLearningPathBySlug;
}

export const UpdateMedia: React.FC<UpdateMediaProps> = ({ learningPath }) => {
  const { t } = useTranslation();
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const [url, setUrl] = useState<string>(learningPath.path.heroVideoUrl ?? "");
  const [update, { loading, error }] = useMutation<
    UpdateLearningPath,
    UpdateLearningPathVariables
  >(UPDATE_LEARNING_PATH, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LEARNING_PATH_BY_SLUG,
        variables: {
          slug: learningPath.path.slug,
        },
      },
    ],
  });

  const handleSubmit = async () => {
    let picture: any | null;
    if (picUpload) {
      picture = {
        publicId: picUpload.public_id,
        url: picUpload.url,
        filename: getFileNameFromPublicId(picUpload.public_id),
        type: FileType.IMAGE,
      };
    }

    await update({
      variables: {
        input: {
          id: learningPath.path.id,
          heroPic: picture,
          heroVideoUrl: url,
        },
      },
    });
  };

  return (
    <Box>
      <Typography variant="caption">Заглавная картинка</Typography>

      <Box>
        <PictureUpload
          imageUrl={learningPath.path.heroPic?.url ?? undefined}
          picUpload={picUpload}
          setPicUpload={setPicUpload}
          rootStyles={{
            height: "200px",
            width: "200px",
            overflow: "hidden",
          }}
        />
      </Box>

      <Box height="50px" />
      <Typography variant="caption">Заглавное видео</Typography>

      <TextField
        fullWidth
        style={{ marginBottom: 20 }}
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        variant="outlined"
      />

      {url && (
        <Box>
          <ReactPlayer url={url} width="100%" />
        </Box>
      )}

      <Box height="50px" />

      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        {t("Save")}
        {loading && (
          <CircularProgress
            thickness={7}
            style={{ marginLeft: 10 }}
            size={10}
            color="secondary"
          />
        )}
      </Button>
    </Box>
  );
};
