import {Box, IconButton, Typography} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {MyTable} from "../../../components";
import {PaginationOptionsArgs, PublishStatus} from "../../../graphql/globalTypes";
import {HeadCell} from "../../../lib";
import {useHistory} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import {
  GetExperts_getExperts_experts
} from "../../../graphql/queries/experts/getExperts/__generated__/GetExperts";
import {DeleteExpertDialog} from "./DeleteExpertDialog";

interface ExpertsTableProps {
  loading: boolean;
  experts: GetExperts_getExperts_experts[];
  totalExperts: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const ExpertsTable: React.FC<ExpertsTableProps>
  = ({
       loading,
       experts,
       totalExperts,
       paginationOptions,
       setPaginationOptions,
     }) => {
  const {t} = useTranslation();
  const history = useHistory();
  const handleEditClick = (id: string, expert: GetExperts_getExperts_experts) => {
    history.push({
      pathname: `/experts/edit/${id}`,
      state: expert
    })
  }
  const mapProductsToRows = useMemo(() => {
    if (!experts?.length) return [];
    const arr = experts.map((expert, index) => ({
      id: expert.id,
      number: index + 1,
      expertName: (
        <Box>
          <Typography
            style={{fontWeight: 500}}
            color={expert.status === PublishStatus.MODERATION ? "secondary" : "inherit"}>
            {expert.name}
          </Typography>
          <Box fontSize="0.8em" fontWeight={500}>{expert.position}</Box>
          <Box fontSize="0.9em" display="flex">
            <Typography style={{fontSize: "12px"}}>
              {t("status")}
            </Typography>
            <Typography
              style={{fontSize: "12px", fontWeight: 500, marginLeft: "5px"}}
              color={expert.status === PublishStatus.MODERATION ? "secondary" : "inherit"}>
              {t(expert.status)}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={() => handleEditClick(expert.id, expert)}>
              {expert.status === PublishStatus.MODERATION
                ? <EditIcon
                  color="secondary"
                  style={{
                    animation: "pulse 1.5s infinite ease-in-out"
                  }}/>
                : <EditIcon color="primary"/>
              }
            </IconButton>
            <DeleteExpertDialog productId={expert.id}/>
          </Box>
        </Box>),
      directions: (<Box marginLeft="5px" fontWeight="500" fontSize="0.9em">
        {expert.expertActivities?.map(act => act.title + ", ")}
      </Box>),
      requests: (<Box marginLeft="5px" fontSize="0.9em">
        {expert.expertRequests?.map(act =>
          <Box key={act.id}>
            {`${act.category} // ${act.title}, `}
          </Box>)}</Box>),
      services: (<Box>
        {expert.services?.map(serv =>
          <Box key={serv.id}>
            <Box fontSize="0.9em" fontWeight={500}>{serv.title}</Box>
            <Box fontSize="0.8em">{serv.meetingsInfo} </Box>
          </Box>)}
      </Box>),
      companies: (
        <Box marginLeft="5px"
             fontWeight="500">
          {expert.experience?.map(exp => exp.company?.name + ", ")}
        </Box>
      ),
    }));
    return arr;
  }, [experts]);

  return (
    <>
      <Box width="100%">
        <MyTable
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          total={totalExperts}
          loading={loading}
          sortFields={[]}
          headCells={headCells}
          values={mapProductsToRows}
          hideCheck
          bulkActions={<></>}/>
      </Box>

    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "number",
    align: "left",
  },
  {
    label: "expertName",
    align: "left",

  },
  {
    label: "directions",
    align: "left",
  },
  {
    label: "requests",
    align: "left",
  },
  {
    label: "services",
    align: "left",
  },
  {
    label: "companies",
    align: "left",
  },
];