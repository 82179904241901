import {useMutation} from "@apollo/client";
import {GET_EXPERTS} from "../../../graphql/queries/experts/getExperts";
import {
  UpdateExpert,
  UpdateExpertVariables
} from "../../../graphql/mutations/experts/updateExpert/__generated__/UpdateExpert";
import {UPDATE_EXPERT} from "../../../graphql/mutations/experts/updateExpert";

export const useUpdateExpert = () => {
  const [update, { loading, error }] = useMutation<
    UpdateExpert,
    UpdateExpertVariables
    >(UPDATE_EXPERT, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_EXPERTS,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "latest",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  return {
    update,
    loading,
    error,
  };
};