import { gql } from "@apollo/client";
import { JobFragment } from "../../../fragments";

export const CREATE_JOB = gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      error {
        name
        message
      }
      job {
        ...Job
      }
    }
  }
  ${JobFragment}
`;
