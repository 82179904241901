import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddNewButton, NotificationToast } from "../../../components";
import { useCreateSeoTag } from "../../../hooks";
import { EditTag, SeoTagFormValues } from "./EditTag";

interface AddNewProps {}

export const AddNew: React.FC<AddNewProps> = ({}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { create, error, loading } = useCreateSeoTag();

  useEffect(() => {}, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async (v: SeoTagFormValues) => {
    await create({
      variables: {
        input: {
          ...v,
        },
      },
    });
  };

  return (
    <Box>
      <AddNewButton onClick={handleOpen} title={t("Add new seo tag")} />

      <EditTag
        handleClose={handleClose}
        handleSubmit={handleCreate}
        open={open}
      />

      {Boolean(error) && (
        <NotificationToast
          message={t("SOMETHING_WENT_WRONG_MESSAGE")}
          horizontal="center"
          vertical="top"
          severity="error"
        />
      )}
    </Box>
  );
};
