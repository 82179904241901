import { gql } from "@apollo/client";
import { LearningPathFragment } from "../../../fragments";

export const UPDATE_LEARNING_PATH = gql`
  mutation UpdateLearningPath($input: UpdateLearningPathInput!) {
    updateLearningPath(input: $input) {
      ...LearningPath
    }
  }
  ${LearningPathFragment}
`;
