import { Box } from "@material-ui/core";
import { Form, Formik, FormikErrors } from "formik";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { FullscreenLoader, NotificationToast } from "../../components";
import {CreateJobInput, PublishStatus} from "../../graphql/globalTypes";
import { CreateJobVariables } from "../../graphql/mutations/jobs/CreateJob/__generated__/CreateJob";
import { useCreateJob, useSetHeadbarTitle } from "../../hooks";
import { CreateJobFormValues, JobFormValues, JobSource, ReadAccess } from "../../lib";
import { useCommonStyle } from "../../styles/commonStyles";
import { EditJobLeft, EditJobRight } from "./components";
import { UpdateJobDescription } from "./components/updateJob";

const openUntilTimestamp = moment().add(1, "days").toDate();

export const CreateJob: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Create Job"));
  const commonClasses = useCommonStyle();
  const history = useHistory();
  const { create, error, loading } = useCreateJob();
  const [editOpen, setEditOpen] = useState(false);
  const [input, setInput] = useState<CreateJobInput>({
    title: "",
    sourceId: "",
    applicationLink: "",
    companyId: "",
    openUntil: openUntilTimestamp,
    readAccess: ReadAccess.UNANYMOUS,
    status: PublishStatus.DRAFT,
    sourceType: JobSource.OTHER,
    tags: [],
    description: "",
    isUN: false,
  });

  const handleSubmit = async (
    values: CreateJobFormValues,
    setErrors: (errors: FormikErrors<JobFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const variables: CreateJobVariables = {
      input: {
        title: values.title,
        sourceId: values.sourceId,
        sourceType: input.sourceType,
        applicationLink: values.applicationLink,
        isUN: values.isUN,
        ...(input.tags?.length
          ? {
              tags: input.tags,
            }
          : {}),
        description: input.description ?? "",
        ...(values.companyId
          ? {
              companyId: values.companyId,
            }
          : {}),
        openUntil: input.openUntil,
        readAccess: input.readAccess,
        status: input.status,
        ...(values.companyId && {
          companyId: values.companyId,
        }),
      },
    };

    try {
      const res = await create({ variables });
      if (res.data?.createJob.error) {
        setErrors({
          [res.data.createJob.error.name]: t(res.data.createJob.error.message!),
        });
      } else if (res.data?.createJob.job) {
        history.push({
          pathname: `/jobs/edit/${res.data!.createJob.job!.id}`,
          state: {
            job: res.data!.createJob.job,
            showCreateNotification: true,
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues: CreateJobFormValues = {
    title: input.title,
    sourceId: input.sourceId,
    companyId: input.companyId!,
    applicationLink: input.applicationLink,
    isUN: false,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    sourceId: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    applicationLink: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    companyName: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors, setSubmitting }) =>
          handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({ values, submitForm }) => (
          <Form>
            <Box className={commonClasses.sectionWithSticky}>
              {editOpen ? (
                <UpdateJobDescription
                  input={input}
                  setInput={setInput as any}
                />
              ) : (
                <EditJobLeft
                  applicationLink={values.applicationLink}
                  create={{
                    input,
                    setInput
                  }}
                />
              )}

              <EditJobRight
                createInput={input}
                editOpen={editOpen}
                setEditOpen={setEditOpen}
                handleSubmit={() => {
                  submitForm();
                }}
                disabled={loading}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {loading && <FullscreenLoader transparent />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
