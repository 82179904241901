import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($input: UpdateUserInfoInput!) {
    updateUserInfo(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`;
