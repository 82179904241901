import { useReactiveVar } from "@apollo/client";
import { Box, Chip, colors, Link, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { enumsVar } from "../../../apollo";
import { AddNewButton, BulkActions, MyTable } from "../../../components";
import { useCardStyle } from "../../../components/cards/styles";
import { PaginationOptionsArgs } from "../../../graphql/globalTypes";
import { AdminGetJobs_adminGetJobs_jobs as IJob } from "../../../graphql/queries/jobs/AdminGetJobs/__generated__/AdminGetJobs";
import { FetchEnums_fetchEnums_employmentTypes as IEnum } from "../../../graphql/queries/jobs/FetchEnums/__generated__/FetchEnums";
import { HeadCell } from "../../../lib";
import { capitalize } from "../../../utils";
import { JobBulkAction } from "./JobBulkAction";

interface JobsTableProps {
  loading: boolean;
  jobs: IJob[];
  totalJobs: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
}

export const JobsTable: React.FC<JobsTableProps> = ({
  loading,
  jobs,
  totalJobs,
  paginationOptions,
  setPaginationOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const chipStyles = useCardStyle();
  const enums = useReactiveVar(enumsVar);
  const [selected, setSelected] = useState<string[]>([]);
  const [currentAction, setCurrentAction] = useState<
    "Update status" | "Update read access" | "Delete" | ""
  >("");

  const handleAddNew = useCallback(() => {
    history.push("/jobs/create");
  }, []);

  const applyAction = (option: string) => {
    setCurrentAction(option as any);
  };

  const JobEnums = (title: string, arr: string[]) => (
    <>
      <Typography variant="overline" style={{ fontSize: 9 }}>
        {title}
      </Typography>
      {arr.map((e) => (
        <Chip
          className={chipStyles.enumcard__chip}
          style={{ margin: 0 }}
          size="small"
          label={e}
          key={e}
        />
      ))}
    </>
  );

  const handleDelete = (id: string) => {
    setSelected([id]);
    setCurrentAction("Delete");
  };

  const getNamesByIds = useCallback((ids: string[], searchArr: IEnum[]) => {
    return ids.map((id) =>
      capitalize(searchArr.find((el) => el.id === id)!.name)
    );
  }, []);

  const mapJobsToRows = useMemo(() => {
    const arr = jobs.map((job) => ({
      id: job.id,
      title: (
        <Box width={250}>
          <Typography variant="subtitle2">{job.title}</Typography>
          {job.companyObject && (
            <>
              <Typography variant="caption">
                {job.companyObject.name}
              </Typography>
              <br />
            </>
          )}
          {job.location && (
            <Typography variant="caption">
              {job.location.countryObject &&
                `${capitalize(
                  job.location.countryObject.rusTrans ??
                    job.location.countryObject.country
                )}, `}
              {job.location.state && `${capitalize(job.location.state)}, `}
              {job.location.city && capitalize(job.location.city)}
            </Typography>
          )}

          <Box marginTop={1} display="flex">
            <Link
              onClick={(e) => e.stopPropagation()}
              component={RouterLink}
              to={`/jobs/edit/${job.id}`}
            >
              {t("Edit")}
            </Link>
            <Box width={20} />
            <Link
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(job.id);
              }}
            >
              {t("Delete")}
            </Link>
          </Box>
        </Box>
      ),
      jobEnums: (
        <Box
          width={200}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {!!enums && (
            <>
              {job.seniorityId && (
                <>
                  {JobEnums(
                    t("Seniorities"),
                    getNamesByIds([job.seniorityId], enums.seniorities)
                  )}
                </>
              )}
              {job.employmentTypeId && (
                <>
                  {JobEnums(
                    t("Employment Type"),
                    getNamesByIds([job.employmentTypeId], enums.employmentTypes)
                  )}
                </>
              )}
              {!!job.jobCategories?.length &&
                JobEnums(
                  t("Job Categories"),
                  job.jobCategories.map((cat) => cat.name)
                )}
            </>
          )}

          {!!job.salary && (
            <>
              <Typography variant="overline" style={{ fontSize: 9 }}>
                Зарплата
              </Typography>
              <Chip
                className={chipStyles.enumcard__chip}
                style={{ margin: 0 }}
                size="small"
                label={
                  <>
                    <Typography variant="caption">
                      {`${job.salary!.min} - ${job.salary!.max} ${
                        job.salary!.currency
                      }`}
                    </Typography>
                    {job.salary.withoutCompany && (
                      <Typography variant="caption">*</Typography>
                    )}
                  </>
                }
              />
            </>
          )}
        </Box>
      ),
      status: <Typography style={{ fontSize: 12 }}>{t(job.status)}</Typography>,
      readAccess: (
        <Typography style={{ fontSize: 12 }}>{t(job.readAccess)}</Typography>
      ),
      postedAt: (
        <>
          <Typography style={{fontSize: 12}}>
          {moment(job.postedAt).fromNow()}
          </Typography>
          <Typography style={{fontSize: 12, fontWeight:"bold" }}>добавил:</Typography>
          <Typography style={{fontSize: 14, }}>{job.createdBy?.firstName}</Typography>
          <Typography style={{fontSize: 14, }}>{job.createdBy?.email}</Typography>
        </>
      ),
      openUntil: (
        <Typography style={{ fontSize: 12 }}>
          {moment(job.openUntil).fromNow()}
        </Typography>
      ),
      savedBy: <Typography style={{ fontSize: 12 }}>{job.savedBy}</Typography>,
      viewCount: (
        <Typography style={{ fontSize: 12 }}>{job.viewCount}</Typography>
      ),
      lacksJobFunctions: !!!job.jobCategories?.length,
    }));

    return arr;
  }, [jobs]);

  const colorIf = useCallback((job: any) => {
    return job.lacksJobFunctions;
  }, []);

  return (
    <>
      <Box width="100%">
        <MyTable
          selected={selected}
          loading={loading}
          total={totalJobs}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          setSelected={setSelected}
          headCells={headCells}
          values={mapJobsToRows}
          colorCode={{ colorIf, color: colors.red["50"] }}
          sortFields={["postedAt", "openUntil", "savedBy", "viewCount"]}
          toolbarRightButton={
            <AddNewButton onClick={handleAddNew} title={t("Add new job")} />
          }
          bulkActions={
            <BulkActions
              onApply={(option) => applyAction(option)}
              options={bulkOptions}
            />
          }
        />
      </Box>

      <JobBulkAction
        currentAction={currentAction}
        jobsIds={selected}
        onClose={() => setCurrentAction("")}
        setSelected={setSelected}
      />
    </>
  );
};

const bulkOptions = ["Update status", "Update read access", "Delete"];

const headCells: HeadCell[] = [
  {
    label: "title",
    align: "left",
  },
  {
    label: "jobEnums",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "readAccess",
    align: "left",
  },
  {
    label: "postedAt",
    align: "center",
  },
  {
    label: "openUntil",
    align: "center",
  },
  {
    label: "savedBy",
    align: "center",
  },
  {
    label: "viewCount",
    align: "center",
  },
];
