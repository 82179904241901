import { ApolloProvider } from "@apollo/client";
import { builder } from "@builder.io/react";
import { ThemeProvider } from "@material-ui/core";
import "moment/locale/ru";
import React from "react";
import ReactDOM from "react-dom";
import { apolloClient } from "./apollo";
import App from "./App";
import { FullscreenLoader } from "./components";
import ErrorBoundary from "./ErrorBoundary";
import "./i18n";
import "./styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { theme } from "./styles/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";

builder.init(process.env.REACT_APP_BUILDER_PUBLIC_KEY!);

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_AUTH_GOOGLE_ID!}>
    <React.Suspense fallback={<FullscreenLoader />}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ThemeProvider>
      </ApolloProvider>
    </React.Suspense>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
