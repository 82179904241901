import {gql} from "@apollo/client";
import {MetaFragment, ShortProductFragment} from "../../../fragments";

export const GET_PRODUCTS = gql`
    query GetProducts(
        $filter: GetProductsFilter!
        $paginationOptions: PaginationOptionsArgs!
    ) {
        getProducts(filter: $filter, paginationOptions: $paginationOptions) {
            products{
               ...ShortProduct
            }
            meta {
                ...Meta
            }
        }
    }
    ${ShortProductFragment}
    ${MetaFragment}
`;
