import { gql } from "@apollo/client";

export const GET_COUPONS = gql`
  query GetCoupons {
    getCoupons {
      coupon {
        id
        couponCode
        countUsers
        countTimes
        discountPercentage
      }
      countSubsctriptions
    }
  }
`;
