import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSetHeadbarTitle} from "../../../hooks";
import {GetOrdersFilter, PaginationOptionsArgs} from "../../../graphql/globalTypes";
import {
  GetOrders_getOrders_orders
} from "../../../graphql/queries/products/getOrders/__generated__/GetOrders";
import {useGetOrders} from "../../../hooks/queries/useGetOrders";
import {PaymentsTable} from "./PaymentsTable";
import {Box} from "@mui/system";
import {PaymentsFilterBar} from "./PaymentsFilterBar";

export const PaymentsForProducts = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Payments"));
  const [payments, setPayments] = useState<GetOrders_getOrders_orders[]>([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [filterParams, setFilterParams] = useState<GetOrdersFilter>({});
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      },
    });

  const {data, error, loading} = useGetOrders({
    filter: filterParams,
    paginationOptions
  })

  useEffect(() => {
    if (data) {
      setTotalPayments(data.getOrders.meta.totalItems);
      setPayments(data.getOrders.orders);
    }
  }, [data]);
  return (
    <Box padding="20px">
      <PaymentsFilterBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}/>
      <Box height="20px"/>
      <PaymentsTable payments={payments}
                     totalPayments={totalPayments}
                     loading={loading}
                     setPaginationOptions={setPaginationOptions}
                     paginationOptions={paginationOptions}/>
    </Box>
  );
};
