import { gql } from "@apollo/client";
import { TableArticleFragment } from "../../../fragments";

export const BULK_ARTICLES_UPDATE = gql`
  mutation BulkArticlesUpdate($input: BulkArticlesUpdateInput!) {
    bulkArticlesUpdate(input: $input) {
      ...TableArticle
    }
  }
  ${TableArticleFragment}
`;
