import { gql } from "@apollo/client";

export const CREATE_PARTNER_COMPANY_TAG = gql`
    mutation CreatePartnerCompanyTag($input: CreatePartnerCompanyTagInput!) {
        createPartnerCompanyTag(input: $input) {
            tag {
                category
                title
                id
            }
            error {
                name
                message
            }
        }
    }
`;
