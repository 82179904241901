import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {MyAutocomplete, SelectButton, TextSearch} from "../../../components";
import {
  AdminGetJobsInput,
  GetExpertsInput,
  PublishStatus
} from "../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../utils";
import {MyMultipleSelect} from "../../../components/inputs/MyMultipleSelect";
import {Grid} from "@material-ui/core";
import {useGetExpertFilters} from "../../../hooks/queries/experts/useGetExpertFilters";

interface ExpertsFilterBarProps {
  filterParams: GetExpertsInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetExpertsInput>>;
}

export const ExpertsFilterBar: React.FC<ExpertsFilterBarProps>
  = ({
       filterParams,
       setFilterParams,
     }) => {

  const {t} = useTranslation();
  const {filters,loading,error}=useGetExpertFilters()

  const handleFilterChange = (
    key: keyof AdminGetJobsInput,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };


  const getRequestLabel = useCallback(
    (id: string) => {
      const requestName = filters?.expertRequests?.find((t) => t.id === id);
      return requestName?.category + "/" + requestName?.title;
    },
    [filters?.expertRequests]
  );
  const getActivityLabel = useCallback(
    (id: string) => {
      const activity = filters?.expertActivities?.find((t) => t.id === id);
      return activity?.title ?? ""
    },
    [filters?.expertActivities]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <TextSearch
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"name, position, description, usefulness"}
        />
      </Grid>
      <Grid item xs={3}>
        <MyAutocomplete
          multiple
          options={filters?.countries ?? []}
          getOptionLabel={(option) => option.rusTrans ?? ""}
          label={t("Countries")}
          value={filters?.countries?.filter(c => filterParams.countriesIds?.includes(c.id)) ?? []}
          placeholder={t("Countries")}
          setValue={(e: any) => {
            setFilterParams((prev) => ({...prev, countriesIds: e.map((c: any) => c.id)}))
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <MyAutocomplete
          multiple
          options={filters?.expertCompanies ?? []}
          getOptionLabel={(option) => option.name ?? ""}
          label={t("Companies")}
          value={filters?.expertCompanies?.filter(c => filterParams.expertCompanies?.includes(c.id)) ?? []}
          placeholder={t("Companies")}
          setValue={(e: any) => {
            setFilterParams((prev) => ({...prev, expertCompanies: e.map((c: any) => c.id)}))
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectButton
          onChange={(e) => handleFilterChange("status", e)}
          value={filterParams.status ?? "all"}
          options={[...convertEnumToArray(PublishStatus), "all"]}
          rootStyle={{width:"100%"}}
          label={t("Status")}
        />
      </Grid>
      <Grid item xs={4}>
        <MyMultipleSelect
          fullWidth
          options={filters?.expertRequests?.map((s) => s.id) ?? []}
          label={t("Requests")}
          value={filterParams.expertRequests ?? []}
          onChange={(v) => {
            setFilterParams((prev) => ({
              ...prev, expertRequests: v.length ? v : undefined,
            }))
          }}
          translationMap={(v) => getRequestLabel(v)}
        />
      </Grid>
      <Grid item xs={4}>
        <MyMultipleSelect
          fullWidth
          options={filters?.expertActivities?.map((s) => s.id) ?? []}
          label={t("Directions")}
          value={filterParams.expertActivities ?? []}
          onChange={(v) => {
            setFilterParams((prev) => ({
              ...prev, expertActivities: v.length ? v : undefined,
            }))
          }}
          translationMap={(v) => getActivityLabel(v)}
        />
      </Grid>
      <Grid item xs={4}>
        <MyMultipleSelect
          fullWidth
          options={filters?.languages?? []}
          label={t("Languages")}
          value={filterParams.languages ?? []}
          onChange={(v) => {
            setFilterParams((prev) => ({
              ...prev, languages: v.length ? v : undefined,
            }))
          }}
        />
      </Grid>
    </Grid>
  );
};

