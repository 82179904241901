import {Box, InputLabel, MenuItem, Select,} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

import { useInputStyles } from "./styles";

export interface EnumObject {
  id: string;
  name: string;
  translation?: string | null;
}

interface MyMultipleSelectProps {
  options: string[];
  value: string[];
  label: string;
  onChange: (values: string[]) => void;
  disabled?: boolean;
  translationMap?: (v: string) => string;
  i18nTranslation?: string;
  fullWidth?:boolean
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
};

export const MyMultipleSelect: React.FC<MyMultipleSelectProps>
  = ({
       value,
       options,
       label,
       i18nTranslation,
       onChange,
       disabled,
       translationMap,
       fullWidth,
       ...rest
     }) => {
  const classes = useInputStyles();
  const {t} = useTranslation(i18nTranslation);

  const handleChange = (event: any) => {
    const {
      target: {value},
    } = event;

    onChange(value);
  };

  return (
    <><InputLabel>
      {t(label)}
    </InputLabel>
      <Box height="4px"/>
      <Select
        {...rest}
        multiple
        fullWidth={fullWidth}
        value={value}
        variant="outlined"
        onChange={handleChange}
        MenuProps={MenuProps}
        disabled={disabled}
        className={classes.select}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {translationMap ? translationMap(option) :t(option)}
          </MenuItem>
        ))}
      </Select></>
  )
};
