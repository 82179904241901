import { gql } from "@apollo/client";
import {AffiliateFragment, MetaFragment} from "../../../fragments";

export const GET_AFFILIATES = gql`
    query GetAffiliates(
        $input: GetAffiliatesInput!
        $paginationOptions: PaginationOptionsArgs!
    ) {
        getAffiliates(input: $input, paginationOptions: $paginationOptions) {
            affiliates {
                ...Affiliate
            }
            meta{
                ...Meta
            }
            total
        }
    }
    ${MetaFragment}
    ${AffiliateFragment}
`;
