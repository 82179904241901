import {gql} from "@apollo/client";
import {ExpertFragment} from "../../../fragments";

export const CREATE_EXPERT = gql`
    mutation CreateExpert($input: CreateExpertInput!) {
        createExpert(input: $input) {
            error {
                name
                message
            }
            expert {
                ...Expert
            }
        }
    }
    ${ExpertFragment}
`;