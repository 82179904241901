import {gql} from "@apollo/client";

export const GET_EXPERT_REQUESTS = gql`
    query GetExpertRequests {
        getExpertRequests {
            id
            title
            category 
        }
    }
`;