import {useMutation} from "@apollo/client";
import {UPDATE_GLOBAL_VARS} from "../../graphql/mutations/globalVars";
import {
  UpdateGlobalVars,
  UpdateGlobalVarsVariables
} from "../../graphql/mutations/globalVars/__generated__/UpdateGlobalVars";

export const useUpdateGlobalVars = () => {
  const [update, { loading, error }] = useMutation<
    UpdateGlobalVars,
    UpdateGlobalVarsVariables
    >(UPDATE_GLOBAL_VARS, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};
