import {Box, Grid, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AddNewButton, ArticleCategorySkeleton, Empty, Fallback,} from "../../components";
import {useSetHeadbarTitle,} from "../../hooks";
import {useGetExpertActivities} from "../../hooks/queries/experts/useGetExpertActivities";
import {
  GetExpertActivities_getExpertActivities
} from "../../graphql/queries/experts/getExpertActivities/__generated__/GetExpertActivities";
import {ExpertActivityCard} from "./components/ExpertActivityCard";
import {EditExpertActivity} from "./components/EditExpertActivity";


export const ExpertActivities: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Activities"));

  const {
    loading: expertActivitiesLoading,
    expertActivities,
    error: expertActivitiesError,
  } = useGetExpertActivities();

  const [expertActivityOpen, setExpertActivityOpen] = useState(false);

  const [currentExpertActivity, setCurrentExpertActivity] =
    useState<GetExpertActivities_getExpertActivities | null>(null);

  const handleExpertActivityOpen = (
    expertActivity: GetExpertActivities_getExpertActivities | null
  ) => {
    setCurrentExpertActivity(expertActivity);
    setExpertActivityOpen(true);
  };

  const handleExpertActivityClose = () => setExpertActivityOpen(false);

  if (expertActivitiesError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  const skeletonMarkup = new Array(5).fill(undefined).map((_, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
      <ArticleCategorySkeleton />
    </Grid>
  ));

  const expertActivityMarkup = expertActivities?.length ? (
    expertActivities!.map((activity, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <ExpertActivityCard
          expertActivity={activity}
          onClick={() => handleExpertActivityOpen(activity)}
        />
      </Grid>
    ))
  ) : (
    <Empty emoji={"🤔"} title={t("No expert activities yet")} />
  );

  return (
    <>
      <Box paddingX={4} paddingY={2}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Expert activities")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddNewButton
            title={t("Add expert activity")}
            onClick={() => handleExpertActivityOpen(null)}
          />
        </Box>
        <Box paddingTop={3}>
          <Grid container spacing={3}>
            {expertActivitiesLoading ? skeletonMarkup : expertActivityMarkup}
          </Grid>
        </Box>
      </Box>

      <EditExpertActivity
        expertActivity={currentExpertActivity}
        open={expertActivityOpen}
        onClose={handleExpertActivityClose}
      />
    </>
  );
};




