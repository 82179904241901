import {gql} from "@apollo/client";

export const UPDATE_EXPERT_ACTIVITY = gql`
    mutation UpdateExpertActivity($input: UpdateExpertActivityInput!) {
        updateExpertActivity(input: $input) {
            expertActivity {
                title
                id
            }
            error {
                name
                message
            }
        }
    }
`;
