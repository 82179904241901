import {useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  GetPromoterByAdmin,
  GetPromoterByAdmin_getPromoterByAdmin,
  GetPromoterByAdminVariables
} from "../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";
import {GET_PROMOTER_BY_ADMIN} from "../graphql/queries/promoters/getPromoterByAdmin";

interface EditUserParams {
  id: string;
}

interface EditUserState {
  showCreateNotification?: boolean;
}

export const useFetchPromoterFromRoute = () => {
  const { id } = useParams<EditUserParams>();
  const location = useLocation<EditUserState>();
  const [promoter, setPromoter] = useState<GetPromoterByAdmin_getPromoterByAdmin | null>(null);
  const [getPromoter, { error,loading, data }] = useLazyQuery<
    GetPromoterByAdmin,GetPromoterByAdminVariables
    >(GET_PROMOTER_BY_ADMIN, {
    onError: (err) => console.error(err),
  });

  useEffect(() => {
      getPromoter({ variables: { id } });
  }, []);

  useEffect(() => {
    if (data?.getPromoterByAdmin) {
      setPromoter(data.getPromoterByAdmin);
    }
  }, [data]);

  return {
    loading,
    error,
    promoter,
    showCreateNotification: location.state?.showCreateNotification,
  };
};