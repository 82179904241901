import { gql } from "@apollo/client";

export const GET_JOB_CATEGORIES = gql`
  query GetJobCategories {
    getJobCategories {
      id
      name
      engTrans
      minSalary
      maxSalary
      avgSalary
      logo {
        fileName
        url
        public_id
        type
      }
    }
  }
`;
