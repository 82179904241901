import { gql } from "@apollo/client";
import { ReviewFragment } from "../../../fragments";

export const UPDATE_REVIEWS_ORDER = gql`
  mutation UpdateReviewsOrder($input: UpdateReviewsOrderInput!) {
    updateReviewsOrder(input: $input) {
      ...Review
    }
  }
  ${ReviewFragment}
`;
