import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface AddStepProps {
  handleAddNew: () => void;
}

export const AddStep: React.FC<AddStepProps> = ({ handleAddNew }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button onClick={handleAddNew} variant="contained">
        Добавить шаг
      </Button>
    </>
  );
};
