import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import { AddOutlined, ClearOutlined } from "@material-ui/icons";
import React from "react";
import {
  CLFileInput,
  UpdateLearningBulletPointInput,
} from "../../../../graphql/globalTypes";
import { useTranslation } from "react-i18next";
import { FileType, IPicUpload } from "../../../../lib";
import { Field, FieldArray, Formik } from "formik";
import { getFileNameFromPublicId } from "../../../../utils";
import { MyTextField, PictureUpload } from "../../../../components";

interface BulletPointsProps {
  bulletPoints: UpdateLearningBulletPointInput[];
}

export const BulletPoints: React.FC<BulletPointsProps> = ({ bulletPoints }) => {
  return (
    <FieldArray
      name="bulletPoints"
      render={(arrayHelpers) => (
        <Box>
          <Box display={"flex"} alignItems="center">
            <Typography variant="caption">Ключевые моменты</Typography>

            <IconButton
              onClick={() => {
                const newBullet: UpdateLearningBulletPointInput = {
                  text: "",
                  big_text: "",
                  picture: undefined,
                };

                arrayHelpers.push(newBullet);
              }}
            >
              <AddOutlined />
            </IconButton>
          </Box>

          {bulletPoints.map((bullet, index) => (
            <BulletPoint
              key={index}
              bullet={bullet}
              index={index}
              handleRemove={() => {
                let arr = [...bulletPoints];
                arr.splice(index, 1);
                arrayHelpers.remove(index);
              }}
              handlePictureChange={(picture: CLFileInput | null) => {
                const updatedBullet: UpdateLearningBulletPointInput = {
                  ...bullet,
                  picture,
                };
                arrayHelpers.replace(index, updatedBullet);
              }}
              handleBulletTextChange={(text: string) => {
                const updatedBullet: UpdateLearningBulletPointInput = {
                  ...bullet,
                  text,
                };
                console.log("got here");
                arrayHelpers.replace(index, updatedBullet);
              }}
              handleBulletBigTextChange={(big_text: string) => {
                const updatedBullet: UpdateLearningBulletPointInput = {
                  ...bullet,
                  big_text,
                };
                console.log("got here 2");
                arrayHelpers.replace(index, updatedBullet);
              }}
            />
          ))}
        </Box>
      )}
    />
  );
};

interface BulletPointProps {
  bullet: UpdateLearningBulletPointInput;
  index: number;
  handleRemove: (index: number) => void;
  handlePictureChange: (pic: CLFileInput | null) => void;
  handleBulletTextChange: (text: string) => void;
  handleBulletBigTextChange: (big_text: string) => void;
}

const BulletPoint: React.FC<BulletPointProps> = ({
  bullet,
  index,
  handleRemove,
  handlePictureChange,
  handleBulletTextChange,
  handleBulletBigTextChange,
}) => {
  const { t } = useTranslation();

  const convertCFileInputToIPickUpload = (
    picture: CLFileInput | null | undefined
  ): IPicUpload | null => {
    if (!picture) return null;

    return {
      public_id: picture!.publicId,
      url: picture.url,
    };
  };

  const convertPickUploadToCFile = (
    picture: IPicUpload | null
  ): CLFileInput | null => {
    if (!picture) return null;

    return {
      filename: getFileNameFromPublicId(picture.public_id),
      publicId: picture.public_id,
      url: picture.url,
      type: FileType.IMAGE,
    };
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent="space-between" alignItems="center">
        <Typography variant="caption" style={{ fontWeight: "bold" }}>
          Пункт N{index + 1}
        </Typography>
        <IconButton onClick={() => handleRemove(index)}>
          <ClearOutlined />
        </IconButton>
      </Box>

      <Box>
        <PictureUpload
          picUpload={convertCFileInputToIPickUpload(bullet.picture)}
          setPicUpload={(p) => handlePictureChange(convertPickUploadToCFile(p))}
          rootStyles={{
            height: "200px",
            width: "150px",
            overflow: "hidden",
            marginBottom: 20,
          }}
        />

        <TextField
          fullWidth
          label={"Big Bullet Text"}
          style={{ marginBottom: 20 }}
          value={bullet.big_text}
          onChange={(e) => handleBulletBigTextChange(e.target.value)}
          variant="outlined"
        />

        <TextField
          fullWidth
          label={t("Title")}
          style={{ marginBottom: 20 }}
          value={bullet.text}
          onChange={(e) => handleBulletTextChange(e.target.value)}
          variant="outlined"
        />
      </Box>
    </Box>
  );
};
