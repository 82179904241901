import { useMutation } from "@apollo/client";
import { REGISTER_USER } from "../../graphql";
import {
  RegisterUser,
  RegisterUserVariables,
} from "../../graphql/mutations/users/RegisterUser/__generated__/RegisterUser";

export const useRegisterUser = () => {
  const [register, { loading, error }] = useMutation<
    RegisterUser,
    RegisterUserVariables
  >(REGISTER_USER, {
    onError: (err) => console.error(err),
  });

  return {
    register,
    loading,
    error,
  };
};
