import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import {Typography} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Box} from "@mui/system"
import {useGetPayouts} from "../../../hooks/queries/useGetPayouts";
import {
  GetPayoutsVariables
} from "../../../graphql/queries/promoters/getPayouts/__generated__/GetPayouts";
import {HeadCell} from "../../../lib";
import {MyTable} from "../../../components";
import {UpdatePromoterPayoutModal} from "./UpdatePromoterPayoutModal";

interface PromotersPayoutsProps {
  promoterId: string
}

export const PromoterPayoutsTable = ({promoterId}: PromotersPayoutsProps) => {
  const {t} = useTranslation();
  const {getPayouts, payouts, loading, error} = useGetPayouts()
  const handleFromDateChange = (date: MaterialUiPickersDate) => {
    setVariables(prev => ({
      query: {
        ...prev.query, periodFrom: date
      }
    }))
  };
  const handleToDateChange = (date: MaterialUiPickersDate) => {
    setVariables(prev => ({
      query: {
        ...prev.query, periodTo: date
      }
    }))
  };
  const mapPayoutsToRows = useMemo(() => {
    if (!payouts?.length) return [];
    const arr = payouts.map((payout, index) => ({
      id: payout.id,
      number: index + 1,
      status: t(payout.status),
      period: `${moment(payout.periodFrom).format("DD.MM.YYYY")} - ${moment(payout.periodTo).format("DD.MM.YYYY")}`,
      totalAmount: payout.totalAmount,
      currency: payout.currency,
      promoterCommission: (<Box>
        <Box>{`${t("Subscription commission")}: ${payout.subscriptionCommission * 100}%`}</Box>
        <Box>{`${t("Product commission")}: ${payout.productCommission * 100}%`}</Box>
      </Box>),
      payoutDate: payout.paidOutAt
        ? moment(payout.paidOutAt).format("DD.MM.YYYY HH:mm")
        : t("Not paid"),
      edit:<UpdatePromoterPayoutModal payout={payout}/>
    }));
    return arr;
  }, [payouts]);
  const [variables, setVariables] = useState<GetPayoutsVariables>({
      query: {
        promoterId,
        periodFrom: "01.01.2023",
        periodTo: new Date(),
      }
    }
  )

  useEffect(() => {
    getPayouts({
      variables
    })
  }, [variables])

  return (
    <Box padding="0 20px">
      <Typography
        style={{
          marginBottom: "10px",
        }}
        variant="h6"
      >
        {t("Payouts")}
      </Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography>{t("period")}</Typography>
        <Box height="10px"/>
        <Box display="flex" gap="20px" flexWrap="wrap">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            label="С"
            value={variables.query.periodFrom}
            onChange={handleFromDateChange}
            inputVariant="outlined"
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            label="По"
            value={variables.query.periodTo}
            onChange={handleToDateChange}
            inputVariant="outlined"
          />
        </Box>
      </MuiPickersUtilsProvider>
      <MyTable
        loading={loading}
        headCells={headCells}
        values={mapPayoutsToRows}
        bulkActions={<></>}
        sortFields={[]}
        hideCheck
        noFoundMessage={{title: "No partner payouts found", emoji: "💸"}}
      />
    </Box>
  );
};

const headCells: HeadCell[] = [
  {
    label: "number",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "period",
    align: "left",
  },
  {
    label: "totalAmount",
    align: "right",
  },
  {
    label: "currency",
    align: "right",
  },
  {
    label: "promoterCommission",
    align: "right",
  },
  {
    label: "payoutDate",
    align: "right",
  },
  {
    label: "edit",
    align: "center"
  }
];