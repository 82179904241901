import { useMutation } from "@apollo/client";
import { LOGIN } from "../../graphql";
import {
  SignIn,
  SignInVariables,
} from "../../graphql/mutations/me/Login/__generated__/SignIn";

export const useLogin = () => {
  const [login, { loading, error }] = useMutation<SignIn, SignInVariables>(
    LOGIN,
    {
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return { login, loading, error };
};
