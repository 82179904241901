import React from 'react';
import {useTranslation} from "react-i18next";
import {useSetHeadbarTitle} from "../../../hooks";
import {useHistory} from "react-router-dom";
import {
  useFetchOrderFromRoute
} from "../../../hooks/queries/products/useFetchOrderFromRoute";
import {Fallback, FullscreenLoader, SelectButton} from "../../../components";
import {RefundOrderDialog} from "./RefundOrderDialog";
import {useUpdateOrder} from "../../../hooks/mutations/useUpdateOrder";
import {OrderStatus} from "../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../utils";
import {Box} from "@mui/system";
import {NavLink} from "react-router-dom";
import {CircularProgress, Divider, Link, Paper, Typography} from "@material-ui/core";

export const ProductPayment = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Payment"));
  const history = useHistory();
  const {update, error, loading,} = useUpdateOrder()
  const {loading: initLoading, error: initError, order} = useFetchOrderFromRoute();

  const handleOrderStatusChange = (e: any) => {
    update({
      variables: {
        input: {
          status: e.target.value,
          id: order!.id
        }
      }
    })
  }
  if (initError || (!order && !initLoading)) {
    return (
      <Fallback
        title={t("Payment not found")}
        message={t("Seems like the payment you want does not exist")}
        onClick={() => history.push("/products/payments")}
        btnTitle={t("Understand")}
      />
    );
  }
  if (initLoading || !order) {
    return <FullscreenLoader transparent/>;
  }

  return (
    <Paper style={{margin:"10px", padding:"20px"}}>
      <Typography color="textSecondary" variant="h6">
        {t("Order")} {order.id}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <Box display="flex" fontSize="18px" marginBottom="20px">
        <Box marginRight="10px">{t("paymentProvider")}: </Box>
        <Box fontWeight="500">{order?.paymentProvider}</Box>
      </Box>
      <Box display="flex" fontSize="18px" marginBottom="20px">
        <Box marginRight="10px">{t("total")}: </Box>
        <Box fontWeight="500">{order?.totalPrice.value} {order?.totalPrice.currency}</Box>
      </Box>

      {order?.discount && <Box display="flex" fontSize="18px" marginBottom="20px">
        <Box marginRight="10px">{t("total")}: </Box>
        <Box
          fontWeight="500">{order?.discount.coupon.couponCode} {order?.discount.coupon.discountPercentage * 100}%</Box>
      </Box>}

      <Box display="flex" fontSize="18px" marginBottom="20px" alignItems="center">
        <Box marginRight="10px">{t("Order status")}: </Box>
        <Box width="150px" textAlign="center">
          {loading
            ? <CircularProgress style={{height:"50px", width:"50px"}}/>
            :<SelectButton
              
            options={convertEnumToArray(OrderStatus)}
            value={order.status}
            rootStyle={{width: "100%"}}
            onChange={handleOrderStatusChange}/>}
        </Box>
        <Box width="20px"/>
        <RefundOrderDialog amountToRefundInOrderCurrency={Number(order.totalPrice.value)}
                           orderId={order.id}
                           currency={order.totalPrice.currency}/>
      </Box>

      <Box display="flex" fontSize="18px" marginBottom="20px">
        <Box marginRight="10px">{t("productTitle")}: </Box>
       <NavLink to={`/products/edit/${order.product.id}`}>
         <Link>{order?.product.title}</Link>
       </NavLink>

      </Box>

      {order.customer &&
        <Box display="flex" fontSize="18px" marginBottom="20px" alignItems="center">
          <Box marginRight="10px">{t("CUSTOMER")}: </Box>
          <Box fontWeight="300" fontSize="16px">
            <Box>{order?.customer.email}</Box>
            <Box>{order?.customer.firstName} {order?.customer.lastName}</Box>
          </Box>
        </Box>}
    </Paper>
  );
};

