import { CellPlugin, lazyLoad } from "@react-page/editor";
import createPlugin from "./createPlugin";
import MuiIconHtmlRenderer from "./Renderer/MuiIconHtmlRenderer";
import { MuiIconSettings } from "./types/settings";
import { MuiIconState } from "./types/state";

const MuiIconControls = lazyLoad(() => import("./Controls/MuiIconControls"));

const muiIconPlugin: (
  settings?: Partial<MuiIconSettings>
) => CellPlugin<MuiIconState> = (settings) =>
  createPlugin({
    Renderer: MuiIconHtmlRenderer,
    Controls: MuiIconControls,
    ...settings,
  });

export { muiIconPlugin };
