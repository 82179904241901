import {gql} from "@apollo/client";
import {
    IncomeFragment,
    PromoterSubscriptionFragment,
} from "../../../fragments";

export const GET_PROMOTER_SUBSCRIPTION_LIST = gql`
    query GetPromoterSubscriptionList($id: String!) {
        getPromoterSubscriptionList(id: $id) {
            subscriptions{
                ...PromoterSubscription
            }
            archivedSubscriptions{
                ...PromoterSubscription
            }
            currency
            accumulatedIncome{
                ...Income
            }
            projectedIncome{
                ...Income
            }
        }
    }
    ${PromoterSubscriptionFragment}
    ${IncomeFragment}
`;