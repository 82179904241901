import { gql } from "@apollo/client";

export const ADD_TAG = gql`
  mutation AddTag($input: CreateTagInput!) {
    addTag(input: $input) {
      tag {
        title
        id
      }
      error {
        name
        message
      }
    }
  }
`;
