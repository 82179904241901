import { useMutation } from "@apollo/client";
import { UPDATE_ARTICLE_CATEGORY } from "../../graphql";
import {
  UpdateArticleCategory,
  UpdateArticleCategoryVariables,
} from "../../graphql/mutations/categories/UpdateArticleCategory/__generated__/UpdateArticleCategory";

export const useUpdateArticleCategory = () => {
  const [update, { loading: uLoading }] = useMutation<
    UpdateArticleCategory,
    UpdateArticleCategoryVariables
  >(UPDATE_ARTICLE_CATEGORY, {
    onError: (err) => console.error(err),
  });

  return { update, uLoading };
};
