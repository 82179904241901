import {ApolloQueryResult, useMutation} from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  IconButton, MenuItem, Radio, RadioGroup,
  Typography,
} from "@material-ui/core";
import {Add, Remove} from "@material-ui/icons";
import {Field, FieldArray, Form, Formik} from "formik";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {MyTextField, NotificationToast} from "../../../components";
import {UODATE_SUBSCRIPTION_PLAN} from "../../../graphql";
import {
  AppLanguage,
  Currency, PublishStatus,
  UpdateSubscriptionPlanInput
} from "../../../graphql/globalTypes";
import {
  UpdateSubscriptionPlan,
  UpdateSubscriptionPlanVariables,
} from "../../../graphql/mutations/subscriptions/UpdateSubscriptionPlan/__generated__/UpdateSubscriptionPlan";
import {
  GetAdminSubscriptionPlans,
  GetAdminSubscriptionPlans_getAdminSubscriptionPlans_plan,
  GetAdminSubscriptionPlansVariables,
} from "../../../graphql/queries/subscriptions/GetAdminSubscriptionPlans/__generated__/GetAdminSubscriptionPlans";
import {BillingCycle} from "../../../lib";
import {Select} from "formik-mui";
import {convertEnumToArray} from "../../../utils";
import {appLanguages} from "../../../lib/constants";

interface SubscriptionPlanDialogProps {
  open: boolean;
  onClose: () => void;
  plan: GetAdminSubscriptionPlans_getAdminSubscriptionPlans_plan | null;
  refetch: (
    variables?: Partial<GetAdminSubscriptionPlansVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAdminSubscriptionPlans>>;
}

export const SubscriptionPlanDialog: React.FC<SubscriptionPlanDialogProps> = ({
  open,
  onClose,
  plan,
  refetch,
}) => {
  const { t } = useTranslation();

  const [updatePlan, { data, error, loading }] = useMutation<
    UpdateSubscriptionPlan,
    UpdateSubscriptionPlanVariables
  >(UODATE_SUBSCRIPTION_PLAN, {
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    if (data?.updateSubscriptionPlan) {
      refetch({});
      onClose();
    }
  }, [data]);

  if (!plan) return null;

  const initialValues: FormValues = {
    title: plan.title,
    description: plan.description,
    features: plan.features,
    billingCycle: plan.billingCycle as BillingCycle,
    priceValue: plan.price.value as unknown as number,
    status: plan.status as PublishStatus,
    language: plan.language
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t("Required")),
    description: Yup.string().required(t("Required")),
    priceValue: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
  });

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>План подписки #{plan.id}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            const { priceValue, status, ...rest } = values;
            const input: UpdateSubscriptionPlanInput = {
              id: plan.id,
              price: {
                currency: Currency.RUB,
                value: priceValue.toString(),
              },
              status: status as string,
              ...rest,
            };
            updatePlan({ variables: { input } });
          }}
        >
          {({ values, dirty, submitForm, setFieldValue }) => (
            <Form>
              <>
                <DialogContent>
                  <Typography variant="body2" color="textSecondary">
                    {t("Language")}
                  </Typography>
                  <RadioGroup row
                              value={values.language}
                              onChange={(event, value)=>setFieldValue("language", value)} >
                    {convertEnumToArray(AppLanguage).map(lng=>
                      <FormControlLabel value={lng}
                                        control={<Radio />}
                                        label={appLanguages[lng]}
                                        key={lng} />)}
                  </RadioGroup>
                  <Box height="10px"/>
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("Title")}
                    name="title"
                  />
                  <Box height="20px" />
                  <Field
                    component={MyTextField}
                    fullWidth
                    rows={4}
                    multiline
                    label={t("Description")}
                    name="description"
                  />
                  <Box height="20px" />

                  {/*<Field*/}
                  {/*  component={Select}*/}
                  {/*  fullWidth*/}
                  {/*  label={t("billingCycle")}*/}
                  {/*  name="billingCycle"*/}
                  {/*  variant="outlined"*/}
                  {/*>*/}
                  {/*  <MenuItem value={BillingCycle.ANNUALLY}>*/}
                  {/*    {t(BillingCycle.ANNUALLY)}*/}
                  {/*  </MenuItem>*/}
                  {/*  <MenuItem value={BillingCycle.MONTHLY}>*/}
                  {/*    {t(BillingCycle.MONTHLY)}*/}
                  {/*  </MenuItem>*/}
                  {/*</Field>*/}

                  {/*<Box height="20px" />*/}

                  <Field
                    component={Select}
                    fullWidth
                    label={t("status")}
                    name="status"
                    variant="outlined"
                  >
                    <MenuItem value={PublishStatus.DRAFT}>
                      {t(PublishStatus.DRAFT)}
                    </MenuItem>
                    <MenuItem value={PublishStatus.PUBLISHED}>
                      {t(PublishStatus.PUBLISHED)}
                    </MenuItem>
                    <MenuItem value={PublishStatus.ARCHIVED}>
                      {t(PublishStatus.ARCHIVED)}
                    </MenuItem>
                  </Field>

                  <Box height="20px" />

                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("price") + ", RUB"}
                    name="priceValue"
                    type="number"
                  />

                  <Box height="40px" />

                  <Typography variant="h6" style={{ marginBottom: "10px" }}>
                    {t("Features")}
                  </Typography>
                  <FieldArray
                    name="features"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.features && values.features.length > 0 ? (
                          values.features.map((_feature, index) => (
                            <Box
                              key={index}
                              display={"flex"}
                              marginBottom={"20px"}
                            >
                              <Field
                                component={MyTextField}
                                fullWidth
                                name={`features.${index}`}
                              />
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <Remove />
                              </IconButton>
                              <IconButton
                                onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                              >
                                <Add />
                              </IconButton>
                            </Box>
                          ))
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => arrayHelpers.push("")}
                          >
                            {t("Add a feature")}
                          </Button>
                        )}
                      </Box>
                    )}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>{t("Close")}</Button>
                  <Button disabled={loading} onClick={submitForm}>
                    {t("Save")}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  title: string;
  description: string;
  features: string[];
  billingCycle: BillingCycle;
  priceValue: number;
  status: PublishStatus;
  language?: AppLanguage | null
}
