import React from "react";
import type { MuiIconSettings } from "../types/settings";
import { lazyLoad } from "@react-page/editor";

const BtnIcon = lazyLoad(() => import("@material-ui/icons/Apps"));

export const defaultTranslations = {
  pluginName: "Иконка",
  pluginDescription: "Выбрать одну из иконок",
  isInlineable: true,
  selectIcons: "Выбрать иконку",
  sizeLabel: "Размер",
  alignLabel: "Выравнивание",
  colorLabel: "Цвет",
};

export const defaultSettings: MuiIconSettings = {
  Renderer: () => <>Renderer for this plugin was not provided </>,
  Controls: () => <> Controls for this plugin were not provided</>,
  translations: defaultTranslations,
  icon: <BtnIcon />,
};
