import { gql } from "@apollo/client";
import { LearningPathFragment } from "../../../fragments";

export const CREATE_LEARNING_PATH = gql`
  mutation CreateLearningPath($input: CreateLearningPathInput!) {
    createLearningPath(input: $input) {
      ...LearningPath
    }
  }
  ${LearningPathFragment}
`;
