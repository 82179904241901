import { gql } from "@apollo/client";
import { CompanyFragment, MetaFragment } from "../../../fragments";

export const GET_COMPANIES = gql`
  query GetCompanies(
    $filter: GetCompaniesFilter!
    $paginationOptions: PaginationOptionsArgs
  ) {
    getCompanies(filter: $filter, paginationOptions: $paginationOptions) {
      companies {
        ...Company
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${CompanyFragment}
`;
