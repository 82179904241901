import React, {ChangeEvent} from 'react';
import {convertEnumToArray} from "../../../utils";
import {ReadAccess} from "../../../lib";
import {SelectButton, TextSearch} from "../../../components";
import {Box} from "@mui/system";
import {CheckboxWithLabel} from "formik-material-ui";
import {Checkbox, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {SingleCheckbox} from "../../../components/inputs/SingleCheckbox";
import {
  AdminGetJobsInput, AppLanguage, GetProductsFilter,
  ProductCategory, ProductKind,
  ProductVariant,
  PublishStatus
} from "../../../graphql/globalTypes";
import {log} from "util";

interface ProductsFilterBarProps {
  filterParams: GetProductsFilter;
  setFilterParams: React.Dispatch<React.SetStateAction<GetProductsFilter>>;
}

export const ProductsFilterBar = ({filterParams,setFilterParams}:ProductsFilterBarProps) => {
  const { t } = useTranslation();
  const handleCheCheckboxChange=(
    e:ChangeEvent<HTMLInputElement>,
    key: keyof GetProductsFilter,)=>{
    setFilterParams((prev)=>({
      ...prev,...(e.target.checked ? {[key]: e.target.checked} : {[key]: undefined})
    }))
  }
  const handleFilterChange = (
    key: keyof GetProductsFilter,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  return (
    <Box>

      <Box display="flex" gap="20px">
        <SelectButton
          onChange={(e) => {handleFilterChange("status", e)}}
          value={filterParams.status ?? "all"}
          options={[...convertEnumToArray(PublishStatus), "all"]}
          rootStyle={{width: "250px"}}
          label={t("Status")}
        />
        <SelectButton
          rootStyle={{width: "250px"}}
          onChange={(e) => {handleFilterChange("category", e)}}
          value={filterParams.category ?? "all"}
          options={[...convertEnumToArray(ProductCategory), "all"]}
          label={t("Category")}
        />
        <SelectButton
          rootStyle={{width: "250px"}}
          onChange={(e) => {handleFilterChange("variant", e)}}
          value={filterParams.variant ?? "all"}
          options={[...convertEnumToArray(ProductVariant), "all"]}
          label={t("Variant")}
        />
        <SelectButton
          rootStyle={{width: "250px"}}
          onChange={(e) => {handleFilterChange("kind", e)}}
          value={filterParams.kind ?? "all"}
          options={[...convertEnumToArray(ProductKind), "all"]}
          label={t("Type")}
        />
        <SelectButton
          rootStyle={{width: "250px"}}
          onChange={(e) => {handleFilterChange("language", e)}}
          value={filterParams.language ?? "all"}
          options={[...convertEnumToArray(AppLanguage), "all"]}
          label={t("Language")}
        />
      </Box>

      <Box height="10px"/>

      <Box display="flex" gap="20px">

        <Box>
          <TextSearch
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"name, position, description, usefulness"}
        />
        </Box>

        <SingleCheckbox
          onChange={(e)=>handleCheCheckboxChange(e,"isHidden")}
          value={filterParams.isHidden?? false}
          title={t("Hidden products")}/>
        <SingleCheckbox
          onChange={(e)=>handleCheCheckboxChange(e,"canBeSoldSeparately")}
          value={filterParams.canBeSoldSeparately?? false}
          title={t("Can be sold separately")}/>
        <SingleCheckbox
          onChange={(e)=>handleCheCheckboxChange(e,"isFeatured")}
          value={filterParams.isFeatured?? false}
          title={t("isFeatured")}/>
      </Box>
    </Box>
  );
};

export default ProductsFilterBar;



