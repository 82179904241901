import React from "react";
import type { ImageSettings } from "../types/settings";
import { lazyLoad } from "@react-page/editor";
const Panorama = lazyLoad(() => import("@material-ui/icons/Panorama"));

export const defaultTranslations = {
  pluginName: "Картинка",
  pluginDescription: "Загрузить картинку из Cloudinary.",
  imageUrl: "Image URL",
  hrefPlaceholder: "http://example.com",
  hrefLabel: "Ссылка при нажатии на картинку",
  openNewWindow: "Открыть ссылку в новом окне",

  // Strings used in ImageUpload
  buttonContent: "Choose for upload",
  noFileError: "Файл не выбран",
  badExtensionError: "Некорректный формат",
  tooBigError: "Размер картинки > 5MB",
  uploadingError: "Ошибка при закачке",
  unknownError: "Неизвестная ошибка",
};

export const defaultSettings: ImageSettings = {
  Controls: () => <> Controls for this plugin were not provided</>,
  Renderer: () => <>Renderer; for this plugin was not provided </>,
  translations: defaultTranslations,
  icon: <Panorama />,
};
