import { gql } from "@apollo/client";

export const RESET_PASS_BY_TOKEN = gql`
  mutation ResetPasswordByToken($input: ResetPasswordByTokenInput!) {
    resetPasswordByToken(input: $input) {
      error {
        name
        message
      }
      user {
        id
        role
        isFellow
        token
        avatarSrc
        firstName
        lastName
        email
      }
    }
  }
`;
