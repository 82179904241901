import { Box } from "@material-ui/core";
import type { Value } from "@react-page/editor";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Fallback,
  FullscreenLoader,
  NotificationToast,
  Wyziwig,
} from "../../components";
import {
  useFetchArticleContentFromRoute,
  useSetHeadbarTitle,
  useUpdateArticle,
} from "../../hooks";
import { BuilderComponent, builder, Builder } from "@builder.io/react";

builder.init(process.env.REACT_APP_BUILDER_KEY!);

export const EditArticleContent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  useSetHeadbarTitle(t("Article Content Editor"));
  const {
    error: initError,
    loading: initLoading,
    url,
    id,
  } = useFetchArticleContentFromRoute();
  const { update, error, loading } = useUpdateArticle();

  const handleSaveAndQuit = async (content?: Value) => {
    try {
      await handleSave(content!);
      history.push(`/articles/edit/${id}`);
    } catch (error) {}
  };

  const handleSave = async (content: Value) => {
    setSuccess(false);
    await update({
      variables: { input: { id, content: JSON.stringify(content) } },
    });
    setSuccess(true);
  };

  if (initError) {
    return (
      <Fallback
        title={t("Article not found")}
        message={t("Seems like the article you want does not exist")}
        onClick={() => history.push("/articles")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading) {
    return <FullscreenLoader transparent />;
  }

  return (
    <Box marginX={2}>
      <Wyziwig
        content={url}
        loading={loading}
        handleSave={handleSave}
        handleSaveAndQuit={handleSaveAndQuit}
      />

      {error && (
        <NotificationToast
          horizontal="center"
          severity="error"
          vertical="top"
          message={t("Failed to save article")}
        />
      )}

      {success && (
        <NotificationToast
          horizontal="center"
          severity="success"
          vertical="top"
          message={t("Article saved")}
        />
      )}

      {loading && <FullscreenLoader transparent />}
    </Box>
  );
};
