import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ClearOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationToast, PictureUpload } from "../../../../components";
import {
  GET_ARTICLES,
  GET_LEARNING_PATH_BY_SLUG,
  UPDATE_LEARNING_PATH,
} from "../../../../graphql";
import {
  CLFileInput,
  UpdateLearningStepInput,
} from "../../../../graphql/globalTypes";
import {
  UpdateLearningPath,
  UpdateLearningPathVariables,
} from "../../../../graphql/mutations/learningPath/updateLearningPath/__generated__/UpdateLearningPath";
import {
  GetArticlesByAdmin,
  GetArticlesByAdminVariables,
  GetArticlesByAdmin_getArticlesByAdmin_articles,
} from "../../../../graphql/queries/article/GetArticles/__generated__/GetArticlesByAdmin";
import {
  GetLearningPathBySlug_getLearningPathBySlug,
  GetLearningPathBySlug_getLearningPathBySlug_path_steps,
} from "../../../../graphql/queries/learningPath/getLearningBySlug/__generated__/GetLearningPathBySlug";
import { FileType, IPicUpload } from "../../../../lib";
import { getFileNameFromPublicId } from "../../../../utils";
import { AddStep } from "./AddStep";
import { DndSteps } from "./DndSteps";
import { EditNumberOfFreeArticles } from "./EditNumberOfFreeArticles";
import { EditOrder } from "./EditOrder";

interface UpdateStepsProps {
  learningPath: GetLearningPathBySlug_getLearningPathBySlug;
}

export const UpdateSteps: React.FC<UpdateStepsProps> = ({ learningPath }) => {
  const { t } = useTranslation();
  const [section, setSection] = useState<"list" | "order">("list");
  const [steps, setSteps] = useState<UpdateLearningStepInput[]>(
    convertToInput(learningPath.path.steps)
  );
  const [orderedSteps, setOrderedSteps] =
    useState<UpdateLearningStepInput[]>(steps);
  const { data, loading: articlesLoading } = useQuery<
    GetArticlesByAdmin,
    GetArticlesByAdminVariables
  >(GET_ARTICLES, {
    variables: {
      input: {},
      paginationOptions: {},
    },
  });
  const articles: GetArticlesByAdmin_getArticlesByAdmin_articles[] =
    data?.getArticlesByAdmin.articles ?? [];

  const [update, { loading, error }] = useMutation<
    UpdateLearningPath,
    UpdateLearningPathVariables
  >(UPDATE_LEARNING_PATH, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LEARNING_PATH_BY_SLUG,
        variables: {
          slug: learningPath.path.slug,
        },
      },
    ],
  });

  useEffect(() => {
    setSteps(convertToInput(learningPath.path.steps));
  }, [learningPath.path.steps]);

  useEffect(() => {
    setOrderedSteps(steps);
  }, [steps]);

  function convertToInput(
    models: GetLearningPathBySlug_getLearningPathBySlug_path_steps[]
  ): UpdateLearningStepInput[] {
    return [...models]
      .sort((a, b) => a.order - b.order)
      .map((model) => ({
        hasVideo: model.hasVideo,
        order: model.order,
        text: model.text,
        articleId: model.articleId ?? "",
        picture: model.icon
          ? {
              filename: model.icon.fileName,
              publicId: model.icon.public_id,
              type: model.icon.type,
              url: model.icon.url,
            }
          : undefined,
      }));
  }

  const submitForm = () => {
    update({
      variables: {
        input: {
          id: learningPath.path.id,
          steps,
        },
      },
    });
  };

  const handleAddNew = () => {
    setSteps((prev) => [
      ...prev,
      {
        hasVideo: false,
        order: prev.length,
        text: "",
        articleId: "",
        picture: null,
      },
    ]);
  };

  const handleFreeChange = (free: number) => {
    update({
      variables: {
        input: {
          id: learningPath.path.id,
          countAvailableArticles: free,
        },
      },
    });
  };

  const handleDelete = (index: number) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setSteps(newSteps.map((s, index) => ({ ...s, order: index })));
  };

  const handleOrderSubmit = async () => {
    await update({
      variables: {
        input: {
          id: learningPath.path.id,
          steps: orderedSteps.map((step, index) => ({ ...step, order: index })),
        },
      },
    });
    setSection("list");
  };

  return (
    <Box>
      <Typography variant="h5">Шаги</Typography>
      <Box height="20px" />
      <Grid container>
        <Grid item md={4}>
          <EditOrder
            steps={steps}
            section={section}
            setSection={setSection}
            handleOrderSubmit={handleOrderSubmit}
            loading={loading}
          />
        </Grid>
        <Grid item md={4} style={{ display: "flex", justifyContent: "center" }}>
          {section === "list" && (
            <EditNumberOfFreeArticles
              handleFreeChange={handleFreeChange}
              steps={steps}
              free={learningPath.path.countAvailableArticles}
              disabled={loading}
            />
          )}
        </Grid>
        <Grid
          item
          md={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {section === "list" ? (
            <AddStep handleAddNew={handleAddNew} />
          ) : (
            <Button variant="contained" onClick={() => setSection("list")}>
              Отменить
            </Button>
          )}
        </Grid>
      </Grid>

      <Box height="20px" />

      {section === "list" && (
        <>
          <Grid container spacing={2}>
            {steps.map((step, index) => (
              <Grid item md={4} key={index}>
                <Step
                  step={step}
                  articles={articles}
                  indexInArray={index}
                  free={index < learningPath.path.countAvailableArticles}
                  handleDelete={() => handleDelete(index)}
                  handlePictureChange={(picture: CLFileInput | null) => {
                    const updatedStep: UpdateLearningStepInput = {
                      ...step,
                      picture,
                    };

                    setSteps(
                      steps.map((step, stindex) =>
                        index === stindex ? updatedStep : step
                      )
                    );
                  }}
                  handleTextChange={(text: string) => {
                    const updatedStep: UpdateLearningStepInput = {
                      ...step,
                      text,
                    };
                    setSteps(
                      steps.map((step, stindex) =>
                        index === stindex ? updatedStep : step
                      )
                    );
                  }}
                  handleArticleChange={(id: string) => {
                    const updatedStep: UpdateLearningStepInput = {
                      ...step,
                      articleId: id,
                    };
                    setSteps(
                      steps.map((step, stindex) =>
                        index === stindex ? updatedStep : step
                      )
                    );
                  }}
                  handleCheckChange={(checked: boolean) => {
                    const updatedStep: UpdateLearningStepInput = {
                      ...step,
                      hasVideo: checked,
                    };
                    setSteps(
                      steps.map((step, stindex) =>
                        index === stindex ? updatedStep : step
                      )
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <Box height="50px" />

          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={submitForm}
          >
            {t("Save")}
            {loading && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={10}
                color="secondary"
              />
            )}
          </Button>
        </>
      )}

      {section === "order" && (
        <DndSteps
          orderedSteps={orderedSteps}
          countAvailableArticles={learningPath.path.countAvailableArticles}
          setOrderedSteps={setOrderedSteps}
        />
      )}

      {Boolean(error) && (
        <NotificationToast
          message={t("SOMETHING_WENT_WRONG_MESSAGE")}
          horizontal="center"
          vertical="top"
          severity="error"
        />
      )}
    </Box>
  );
};

interface StepProps {
  step: UpdateLearningStepInput;
  indexInArray: number;
  free: boolean;
  handleDelete: () => void;
  handlePictureChange: (pic: CLFileInput | null) => void;
  handleTextChange: (text: string) => void;
  handleArticleChange: (text: string) => void;
  handleCheckChange: (checked: boolean) => void;
  articles: GetArticlesByAdmin_getArticlesByAdmin_articles[];
}

const Step: React.FC<StepProps> = ({
  step,
  indexInArray,
  free,
  handleDelete,
  handlePictureChange,
  handleTextChange,
  handleCheckChange,
  handleArticleChange,
  articles,
}) => {
  const { t } = useTranslation();

  const convertCFileInputToIPickUpload = (
    picture: CLFileInput | null | undefined
  ): IPicUpload | null => {
    if (!picture) return null;

    return {
      public_id: picture!.publicId,
      url: picture.url,
    };
  };

  const convertPickUploadToCFile = (
    picture: IPicUpload | null
  ): CLFileInput | null => {
    if (!picture) return null;

    return {
      filename: getFileNameFromPublicId(picture.public_id),
      publicId: picture.public_id,
      url: picture.url,
      type: FileType.IMAGE,
    };
  };

  return (
    <Box
      style={{
        background: "white",
        borderRadius: "6px",
        padding: "10px",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography style={{ marginRight: 10 }}>
            ШАГ {indexInArray + 1}
          </Typography>{" "}
          {free && <Chip label="Бесплатно" color="secondary" />}
        </Box>
        <IconButton onClick={handleDelete}>
          <ClearOutlined />
        </IconButton>
      </Box>

      <PictureUpload
        picUpload={convertCFileInputToIPickUpload(step.picture)}
        setPicUpload={(p) => handlePictureChange(convertPickUploadToCFile(p))}
        rootStyles={{
          height: "200px",
          width: "150px",
          overflow: "hidden",
          marginBottom: 20,
        }}
      />

      <TextField
        fullWidth
        label={t("Title")}
        style={{ marginBottom: 20 }}
        value={step.text}
        onChange={(e) => handleTextChange(e.target.value)}
        variant="outlined"
      />
      <Select
        value={step.articleId ?? ""}
        variant="outlined"
        onChange={(e) => handleArticleChange(e.target.value as any)}
        fullWidth
        displayEmpty
      >
        <MenuItem value="" disabled>
          Выберите статью
        </MenuItem>
        {articles.map((article) => (
          <MenuItem key={article.id} value={article.id}>
            {article.title}
          </MenuItem>
        ))}
      </Select>

      <Box height="20px" />
      <FormControlLabel
        checked={step.hasVideo}
        control={
          <Checkbox
            onChange={(e) => handleCheckChange(e.target.checked as any)}
          />
        }
        label={"Видео в статье"}
      />
    </Box>
  );
};
