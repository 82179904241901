import { Box, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import {
  CheckCircle,
  EmailOutlined as EmailIcon,
  MoreVert,
  WarningOutlined,
} from "@material-ui/icons";
import theme from "@react-page/editor/lib/ui/ThemeProvider/DarkTheme";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MyMuiTextField, NotificationToast } from "../../../components";
import { EmailMessage } from "./EmailMessage";
import { UpdateEmail } from "./UpdateEmail";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface EmailProps {
  user: Me_me;
  isMe?: boolean;
}

export const Email: React.FC<EmailProps> = ({ user, isMe }) => {
  const { t } = useTranslation();
  const [actions, setActions] = useState<string[]>(getUserActions());
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setActions(getUserActions());
  }, [user, isMe]);

  function getUserActions() {
    const actions: string[] = ["copyEmail"];
    if (!user.emailVerified) {
      actions.push("sendVerificationLink");
    }
    if (isMe) {
      actions.push("updateEmail");
    } else {
      actions.push("sendPasswordReset");
    }
    return actions;
  }

  const handleAction = useCallback(async (a: string) => {
    setCopied(false);
    if (a === "copyEmail") {
      await navigator.clipboard.writeText(user.email);
      setCopied(true);
    } else {
      setCurrentAction(a);
      handleOpen();
    }
    handleMenuClose();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentAction("");
    setOpen(false);
  };

  const dialogMarkup = useMemo(() => {
    if (currentAction === "updateEmail") {
      return <UpdateEmail user={user} open={open} onClose={handleClose} />;
    } else if (!!currentAction) {
      return (
        <EmailMessage
          onClose={handleClose}
          open={open}
          action={currentAction}
          user={user}
          setEmailSent={setEmailSent}
        />
      );
    }
  }, [currentAction]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <MyMuiTextField
          type="email"
          value={user.email}
          fullWidth
          placeholder={t("Email")}
          icon={<EmailIcon />}
          disabled
          rightIcon={
            user.emailVerified ? (
              <Tooltip title={t("Verified")!}>
                <CheckCircle style={{ color: theme.palette.success.main }} />
              </Tooltip>
            ) : (
              <Tooltip title={t("Not Verified")!}>
                <WarningOutlined color="secondary" />
              </Tooltip>
            )
          }
        />

        <IconButton onClick={handleMenuClick}>
          <MoreVert />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {actions.map((a) => (
            <MenuItem key={a} onClick={() => handleAction(a)}>
              {t(a)}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {copied && (
        <NotificationToast
          horizontal="center"
          vertical="bottom"
          message={t("Copied to buffer")}
          severity="success"
        />
      )}

      {dialogMarkup}

      {emailSent && (
        <NotificationToast
          message={t("Email has been sent")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
