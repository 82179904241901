import {useMutation} from "@apollo/client";
import {CREATE_EXPERT_COMPANY, GET_EXPERT_COMPANIES} from "../../../graphql";
import {
  CreateExpertCompany,
  CreateExpertCompanyVariables
} from "../../../graphql/mutations/experts/createExpertCompany/__generated__/CreateExpertCompany";

export const useCreateExpertCompany = () => {
  const [create, { loading}] = useMutation<
    CreateExpertCompany,
    CreateExpertCompanyVariables
    >(CREATE_EXPERT_COMPANY, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_EXPERT_COMPANIES,
      },
    ],
  });

  return { create, loading };
};
