import React from "react";

import slate from "@react-page/plugins-slate";
import "@react-page/plugins-slate/lib/index.css";

import customSlatePlugin from "./customSlatePlugin";

export const defaultSlate = slate((def) => ({
  ...def,
  plugins: {
    ...def.plugins,
    custom: {
      custom1: customSlatePlugin,
    },
  },
}));
