import {
  makeStyles,
  Theme,
  createStyles,
  lighten,
} from "@material-ui/core/styles";

export const useTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    tableHead_visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    toolbar_root: {
      padding: "8px 20px",
      //zIndex: 1000,
      zIndex: 99,
      display: "flex",
      alignItems: "center",
    },
    toolbar_highlight: {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
    toolbar_title: {
      flex: "1 1 100%",
    },
  })
);
