import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FullscreenLoader, NotificationToast } from "../../../components";
import { GetUserById_getUserById as IUser } from "../../../graphql/queries/users/GetUserById/__generated__/GetUserById";
import { useUpdateUserRole } from "../../../hooks";
import { UserRole } from "../../../lib";

interface EditUserRoleProps {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const EditUserRole: React.FC<EditUserRoleProps> = ({
  user,
  setUser,
}) => {
  const { t } = useTranslation();
  const newRole = roleMap[user.role as UserRole];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { error, loading, update } = useUpdateUserRole();
  const [unsafeToAlter, setUnsafeToAlter] = useState(false);

  if (user.role === UserRole.GLOBAL_ADMIN) return null;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRoleChange = async () => {
    setUnsafeToAlter(false);
    const res = await update({
      variables: { input: { newRole, userId: user.id } },
    });
    if (res.data?.updateUserRole.error) {
      setUnsafeToAlter(true);
    } else {
      setUser({
        ...user,
        role: res.data?.updateUserRole.user?.role!,
      });
    }

    handleMenuClose();
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <Settings />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleRoleChange}>
          {t("ChangeRoleTo", { role: t(newRole) })}
        </MenuItem>
      </Menu>

      {loading && <FullscreenLoader transparent />}

      {unsafeToAlter && (
        <NotificationToast
          horizontal="center"
          vertical="bottom"
          message={t("Coacher has active consultations")}
          severity="warning"
        />
      )}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

const roleMap = {
  [UserRole.COACH]: UserRole.CUSTOMER,
  [UserRole.CUSTOMER]: UserRole.COACH,
  [UserRole.MODERATOR]: UserRole.ADMIN,
  [UserRole.ADMIN]: UserRole.MODERATOR,
  [UserRole.GLOBAL_ADMIN]: "",
};
