import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Field, FieldArray, Form, Formik, FormikErrors} from "formik";
import {
  useFetchExpertFromRoute,
  useGetExpertParams,
  useGetUsers,
  useSetHeadbarTitle
} from "../../hooks";
import * as Yup from "yup";
import {languages} from "../../lib";
import {
  AppLanguage,
  ContactsInput,
  Currency,
  ExpertReviewInput,
  FileInput,
  GetUsersInput,
  PriceInput, PublishStatus
} from "../../graphql/globalTypes";
import {Box} from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Fallback,
  FullscreenLoader,
  MyAutocomplete,
  MyDatePicker,
  MyTextField,
  NotificationToast,
  SelectButton
} from "../../components";
import {convertEnumToArray} from "../../utils";
import {SaveOutlined as SaveIcon} from "@material-ui/icons";
import {PictureInBase64Upload} from "../../components/inputs/PictureInBase64Upload";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {theme} from "../../styles/theme";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  GetExpertCompanies_getExpertCompanies
} from "../../graphql/queries/experts/getExpertCompanies/__generated__/GetExpertCompanies";
import {CheckboxWithLabel} from "formik-material-ui";
import {useUpdateExpert} from "../../hooks/mutations/experts/useUpdateExpert";
import {
  UpdateExpertVariables
} from "../../graphql/mutations/experts/updateExpert/__generated__/UpdateExpert";
import htmlToDraft from "html-to-draftjs";
import {omit} from "lodash";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {appLanguages} from "../../lib/constants";
import {FilesUpload} from "../../components/inputs/FilesUpload";
import {Autocomplete} from "@material-ui/lab";
import {
  GetUsers_getUsers_users as IUser
} from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {useDebounce} from "../../hooks/useDebounce";
import EditIcon from "@material-ui/icons/Edit";

export const EditExpert = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [updated, setUpdated] = useState(false)
  const [enableValidation, setEnableValidation] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null)
  useSetHeadbarTitle(t("Edit expert"));
  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    textSearch: undefined,
  });
  const [users, setUsers] = useState<IUser[]>([]);
  const {data, loading: usersLoading} = useGetUsers({
    input: useDebounce(filterParams, 700),
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      }
    }
  });
  const handleTextSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };
  const {expert, loading: initLoading, error: initError, showCreateNotification} = useFetchExpertFromRoute()
  const {
    expertActivities,
    expertRequests,
    expertCompanies,
    countries,
    loading: paramsLoading,
    error: paramsError
  } = useGetExpertParams()
  const {update, loading, error} = useUpdateExpert()

  useEffect(() => {
    if (data) {
      setUsers(data.getUsers.users);
    }
  }, [data]);

  if (initError || (!expert && !initLoading)) {
    return (
      <Fallback
        title={t("Expert not found")}
        message={t("Seems like the Expert you want does not exist")}
        onClick={() => history.push("/experts")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading || !expert) {
    return <FullscreenLoader transparent/>;
  }
  const initialValues: ExpertFormValues = {
    name: expert.name,
    userId: expert.userId?.id,
    description: expert.description,
    slug: expert.slug,
    position: expert.position,
    expertRequests: expert.expertRequests?.map(req => `${req.category} / ${req.title}`) ?? [],
    expertActivities: expert.expertActivities?.map(activ => activ.title) ?? [],
    status: expert.status,
    experience: expert.experience ?? [],
    languages: expert.languages ?? [],
    locations: expert.locations?.map(loc => loc.countryObject?.rusTrans ?? "") ?? [],
    ...(expert.contacts ? {contacts: expert.contacts} : {}),
    picture: {source: expert.picture?.url ?? ""},
    reviews: expert.reviews?.map(rev =>
      ({...rev, picture: {source: rev.picture?.url ?? ""}})) ?? [],
    usefulness: EditorState.createWithContent(ContentState.createFromBlockArray(
        htmlToDraft(expert.usefulness ?? "").contentBlocks,
        htmlToDraft(expert.usefulness ?? "").entityMap
      )
    ),
    services: expert.services?.map(serv => ({
      ...serv,
      isProduct: !!serv.document?.url,
      document: {
        fileName: serv.document?.fileName ?? "",
        source: serv.document?.url ?? "",
      },
      price: serv.price
        ? {value:serv.price.value, currency: serv.price.currency as Currency}
        : null,
      description: EditorState.createWithContent(ContentState.createFromBlockArray(
          htmlToDraft(serv.description ?? "").contentBlocks,
          htmlToDraft(serv.description ?? "").entityMap
        )
      )
    }))
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    position: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    description: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    slug: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    expertRequests: Yup.array().required(t("Required")).min(1, t("Required")),
    expertActivities: Yup.array().required(t("Required")).min(1, t("Required")),
  });
  const handleSubmit = async (
    values: ExpertFormValues,
    setErrors: (errors: FormikErrors<ExpertFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {

    const variables: UpdateExpertVariables = {
        input: {
          ...values,
          id: expert.id,
          contacts: omit(expert.contacts, "__typename"),
          expertActivities: expertActivities?.filter(act => values.expertActivities
            .includes(act.title))
            .map(act => act.id)!,
          expertRequests: expertRequests?.filter(req => values.expertRequests
            .includes(`${req.category} / ${req.title}`))
            .map(req => req.id)!,
          usefulness: values.usefulness
            ? draftToHtml(convertToRaw(values.usefulness.getCurrentContent()))
            : "",
          locations: countries?.filter(c => values.locations?.includes(c.rusTrans!))
            .map(c => ({countryId: c.id})),
          services: values.services?.map(serv =>
            ({
              ...omit(serv, "__typename", "id", "isProduct"),
              ...((serv.price?.value && serv.price.currency) ? {price: omit(serv.price, "__typename")} : {}),
              document: serv.document?.source.slice(0, 4) !== "http"
                ? {source: serv.document?.source ?? ""}
                : {source: serv.document?.source ?? "", fileName: "url"},
              description: serv.description
                ? draftToHtml(convertToRaw(serv.description.getCurrentContent()))
                : draftToHtml(convertToRaw(EditorState.createEmpty().getCurrentContent()))
            })),
          experience: values.experience?.map(exp => ({
            ...omit(exp, "__typename", "id"),
            company: exp.company ? exp.company.id : "",
            isWorkingCurrently: exp.isWorkingCurrently ?? false
          })),
          ...(values.picture?.source.slice(0, 4) !== "http" ? {picture: {source: values.picture?.source ?? ""}} : {}),
          reviews: values.reviews?.map(rev => ({
            ...omit(rev, "__typename", "id"),
            picture: rev.picture?.source.slice(0, 4) !== "http"
              ? {source: rev.picture?.source ?? ""}
              : {source: rev.picture?.source ?? "", fileName: "url"}
          })),
          userId: values.userId !== expert.userId?.id ? values.userId : undefined
        }
      }
    ;
    try {
      const res = await update({variables});
      if (res.data?.updateExpert.error) {
        setUpdateError(res.data.updateExpert.error.message)
      } else if (res.data?.updateExpert.expert) {
        setUpdated(true)
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          handleSubmit(values, setErrors, setSubmitting)
        }>
        {({values, submitForm, setFieldValue, errors}) => {
          return (<Form>
            <Box margin="20px">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="userId"
                    noOptionsText={t("User not found")}
                    loading={usersLoading}
                    loadingText={<CircularProgress
                      thickness={7}
                      style={{marginLeft: 10}}
                      size={20}
                      color="primary"
                    />}
                    component={Autocomplete}
                    options={users}
                    value={expert?.userId}
                    getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} ` || ""}
                    onChange={(event: any, newValue: IUser) => setFieldValue("userId", newValue?.id)}
                    fullWidth
                    style={{marginBottom: 20}}
                    renderInput={(params: any) => (
                      <TextField {...params}
                                 error={!!errors.userId}
                                 placeholder={t("Enter name or email")}
                                 variant="outlined"
                                 InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                     <React.Fragment>
                                       {usersLoading ?
                                         <CircularProgress color="primary"
                                                           size={20}/> : null}
                                       {params.InputProps.endAdornment}
                                     </React.Fragment>
                                   ),
                                 }}
                                 label={t("Enter name or email")}
                                 onChange={handleTextSearchChange}
                                 helperText={errors.userId}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("First name, last name")}
                    placeholder={t("Name example")}
                    name="name"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Job title")}
                    placeholder={t("Job title example")}
                    name="position"
                    style={{marginBottom: 20}}/>
                </Grid>
              </Grid>
              <Divider style={{marginBottom: "20px"}}/>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <SelectButton
                    rootStyle={{width: "100%",
                      ...(values.status===PublishStatus.MODERATION
                        ? {animation: "haloAnimation 1.5s infinite ease-in-out"}
                        : {})}}
                    label={t("status")}
                    options={convertEnumToArray(PublishStatus)}
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}/>
                </Grid>
                <Grid item xs={4}>
                  {paramsLoading ? (
                    <Typography>{t("Activities loading")}</Typography>
                  ) : (
                    <MyAutocomplete
                      multiple
                      error={errors.expertActivities}
                      options={expertActivities?.map(activity => activity.title) ?? []}
                      getOptionLabel={(option) => option}
                      label={t("Expert activities")}
                      value={values.expertActivities ?? []}
                      placeholder={t("Expert activities")}
                      setValue={(e: string[]) => {
                        setFieldValue("expertActivities", e)
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {paramsLoading ? (
                    <Typography>{t("Requests loading")}</Typography>
                  ) : (
                    <MyAutocomplete
                      error={errors.expertRequests}
                      multiple
                      options={expertRequests?.map(request => `${request.category} / ${request.title}`) ?? []}
                      getOptionLabel={(option) => option}
                      label={t("Expert requests")}
                      value={values.expertRequests ?? []}
                      placeholder={t("Expert requests")}
                      setValue={(e: string[]) => {
                        setFieldValue("expertRequests", e)
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Box width="150px" height="150px">
                    <PictureInBase64Upload
                      pictureResolution="500*500"
                      src={values.picture?.source ?? ""}
                      setPicture={(e) => setFieldValue("picture", {source: e})}/>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    multiline
                    rows={6}
                    label={t("description")}
                    placeholder={t("description")}
                    name="description"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Slug")}
                    placeholder={t("Slug")}
                    name="slug"/>
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {paramsLoading ? (
                    <Typography>{t("Countries loading")}</Typography>
                  ) : (
                    <MyAutocomplete
                      multiple
                      options={countries?.map(c => c.rusTrans ? c.rusTrans : c.country) ?? []}
                      getOptionLabel={(option) => option}
                      label={t("Countries")}
                      value={values.locations ?? []}
                      placeholder={t("Countries")}
                      setValue={(e: string[]) => {
                        setFieldValue("locations", e)
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <MyAutocomplete
                    multiple
                    options={languages}
                    getOptionLabel={(option) => option}
                    label={t("Languages")}
                    value={values.languages ?? []}
                    placeholder={t("Languages")}
                    setValue={(e: string[]) => {
                      setFieldValue("languages", e)
                    }}
                  />
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0 0"}}/>

              <Typography variant="h6">
                {t("Usefulness")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="overline">{t("Editor")}</Typography>
                  <Paper style={{padding: 10, minHeight: "250px"}}>
                    <Editor
                      toolbar={{fontFamily: {
                          options: ['Arial','Roboto', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,}
                      }}
                      editorState={values.usefulness}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={(editorState: EditorState) =>
                        setFieldValue("usefulness", editorState)}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="overline">{t("Preview")}</Typography>
                  <Box
                    borderRadius={1}
                    boxShadow={theme.shadows[1]}
                    padding={2}
                    style={{background: "#fff"}}
                    minHeight="300px"
                    maxHeight="450px"
                    overflow="auto"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.usefulness
                          ? draftToHtml(convertToRaw(values.usefulness.getCurrentContent()))
                          : ""
                      }}/>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0 0"}}/>
              <Typography variant="h6" style={{marginBottom: "10px"}}>
                {t("Contacts")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Telegram")}
                    placeholder={t("Telegram example")}
                    name="contacts.telegram"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("LinkedIn")}
                    placeholder={t("LinkedIn example")}
                    name="contacts.linkedIn"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Twitter")}
                    placeholder={t("Twitter example")}
                    name="contacts.twitter"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("YouTube")}
                    placeholder={t("YouTube example")}
                    name="contacts.youTube"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("VK")}
                    placeholder={t("VK example")}
                    name="contacts.vk"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Instagram")}
                    placeholder={t("Instagram example")}
                    name="contacts.instagram"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Website")}
                    placeholder={t("Website example")}
                    name="contacts.website"/>
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>

              <Accordion
                elevation={3}
                style={{background: "#f6f7ff", borderRadius: "4px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                >
                  <Typography variant="h6" style={{marginBottom: "10px"}}>
                    {t("Services")} / {t("Products")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FieldArray
                    name="services"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.services?.map((service, index) => {
                          return (
                            <Box key={index} marginBottom={"20px"}>
                              {service.isProduct
                                ? <>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                      <FilesUpload
                                        // @ts-ignore
                                        files={values?.services[index].document ? [values?.services[index].document] : []}
                                        setFiles={(e) => setFieldValue(`services[${index}].document`, e[0])}
                                        single
                                      />
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Field
                                        component={MyTextField}
                                        label={t("Title")}
                                        placeholder={t("Title")}
                                        fullWidth
                                        name={`services.${index}.title`}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        type="text"
                                        label={t("price")}
                                        placeholder={t("price")}
                                        component={MyTextField}
                                        fullWidth
                                        name={`services.${index}.price.value`}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <SelectButton
                                        label={t("currency")}
                                        rootStyle={{width: "100%"}}
                                        options={["RUB", "USD"]}
                                        value={values.services![index]?.price?.currency ?? ""}
                                        onChange={(e) => setFieldValue(`services[${index}].price.currency`, e.target.value)}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Button
                                    style={{marginTop: "10px"}}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon/> {t("Delete product")}
                                  </Button>
                                </>

                                : <>
                                  <Grid container spacing={3}>
                                    <Grid item xs={4}><Field
                                      component={MyTextField}
                                      label={t("Title")}
                                      placeholder={t("Title")}
                                      fullWidth
                                      name={`services.${index}.title`}/></Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        label={t("MeetingsInfo")}
                                        placeholder={t("MeetingsInfo example")}
                                        component={MyTextField}
                                        fullWidth
                                        name={`services.${index}.meetingsInfo`}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        label={t("PromoText")}
                                        placeholder={t("PromoText")}
                                        component={MyTextField}
                                        fullWidth
                                        name={`services.${index}.promoText`}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        type="text"
                                        label={t("price")}
                                        placeholder={t("price")}
                                        component={MyTextField}
                                        fullWidth
                                        name={`services.${index}.price.value`}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <SelectButton
                                        label={t("currency")}
                                        rootStyle={{width: "100%"}}
                                        options={["RUB", "USD"]}
                                        value={values.services![index]?.price?.currency ?? ""}
                                        onChange={(e) => setFieldValue(`services[${index}].price.currency`, e.target.value)}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        label={t("Affiliate Link")}
                                        placeholder={t("Website example")}
                                        component={MyTextField}
                                        fullWidth
                                        name={`services.${index}.affiliateLink`}/>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                      <Typography
                                        variant="overline">{t("Description")}</Typography>
                                      <Paper style={{padding: 10, minHeight: "250px"}}>
                                        <Editor
                                          toolbar={{
                                            fontFamily: {
                                              options: ['Arial', 'Roboto', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                              className: undefined,
                                              component: undefined,
                                              dropdownClassName: undefined,
                                            }
                                          }}
                                          editorState={values?.services ? values?.services[index]?.description : undefined}
                                          wrapperClassName="demo-wrapper"
                                          editorClassName="demo-editor"
                                          onEditorStateChange={(editorState: EditorState) =>
                                            setFieldValue(`services[${index}].description`, editorState)}
                                        />
                                      </Paper>
                                      <Button
                                        style={{marginTop: "10px"}}
                                        onClick={() => arrayHelpers.remove(index)}>
                                        <DeleteIcon/> {t("Delete service")}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography
                                        variant="overline">{t("Preview")}</Typography>
                                      <Box
                                        borderRadius={1}
                                        boxShadow={theme.shadows[1]}
                                        padding={2}
                                        style={{background: "#fff"}}
                                        minHeight="300px"
                                        maxHeight="450px"
                                        overflow="auto"
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: (values.services && values.services[index].description)
                                              ? draftToHtml(convertToRaw(values.services[index].description.getCurrentContent()))
                                              : draftToHtml(convertToRaw(EditorState.createEmpty().getCurrentContent()))
                                          }}/>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </>
                              }
                              <Divider style={{margin: "10px 0"}}/>
                            </Box>)
                        })
                        }
                        <Button
                          style={{marginRight:"30px"}}
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            arrayHelpers.push("")
                          }}
                        >
                          {t("Add service")}
                        </Button>

                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push({isProduct: true})}
                        >
                          {t("Add product")}
                        </Button>
                      </Box>
                    )}/>
                </AccordionDetails>
              </Accordion>

              <Divider style={{margin: "20px 0"}}/>

              <Accordion
                elevation={3}
                style={{background: "#f6f7ff", borderRadius: "4px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                >
                  <Typography variant="h6" style={{marginBottom: "10px"}}>
                    {t("Experience")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FieldArray
                    name="experience"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.experience?.map((exp, index) => {
                          return (
                            <Box key={index} marginBottom={"20px"}>
                              <Grid container spacing={3}>
                                <Grid item xs={5}>
                                  {paramsLoading ?
                                    <Typography>{t("Countries loading")}</Typography> :
                                    <MyAutocomplete
                                      options={expertCompanies ?? []}
                                      getOptionLabel={(option) => option.name ?? t("Not specified")}
                                      getOptionSelected={(option, value) => option.id === value.id}
                                      label={t("Company")}
                                      value={values.experience ? values.experience[index].company : t("Not specified")}
                                      setValue={(e) => {
                                        setFieldValue(`experience.${index}.company`, e)
                                      }}
                                    />}
                                </Grid>
                                <Grid item xs={7}>
                                  <Field
                                    label={t("Job title")}
                                    placeholder={t("Position example")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`experience.${index}.position`}/>
                                </Grid>
                                <Grid item xs={2}>
                                  <Button
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon/> {t("Delete experience")}
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name={`experience.${index}.isWorkingCurrently`}
                                    Label={{
                                      label: t("isWorkingCurrently"),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MyDatePicker
                                    value={values.experience ? values.experience[index].startedAt : new Date()}
                                    onChange={(e) => setFieldValue(`experience[${index}].startedAt`, e?.toDate())}
                                    label={t("startedAt")}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MyDatePicker
                                    value={values.experience ? values.experience[index].finishedAt : new Date()}
                                    onChange={(e) => setFieldValue(`experience[${index}].finishedAt`, e?.toDate())}
                                    label={t("finishedAt")}
                                  />
                                </Grid>
                              </Grid>
                              <Divider style={{margin: "10px 0"}}/>
                            </Box>)
                        })
                        }
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {t("Add experience")}
                        </Button>
                      </Box>
                    )}/>
                </AccordionDetails>
              </Accordion>

              <Divider style={{margin: "20px 0"}}/>

              <Accordion
                elevation={3}
                style={{background: "#f6f7ff", borderRadius: "4px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                >
                  <Typography variant="h6" style={{marginBottom: "10px"}}>
                    {t("Reviews")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FieldArray
                    name="reviews"
                    render={(arrayHelpers) => (
                      <Box width="100%">
                        {values.reviews?.map((review, index) => {
                          return (<Box
                            key={index}
                            marginBottom={"20px"}>
                            <Grid container spacing={4}>
                              <Grid item xs={4}>
                                <Grid item xs={12}>
                                  <Box width="150px" height="150px" margin="0 auto">
                                    <PictureInBase64Upload
                                      pictureResolution="100x100"
                                      src={values.reviews ? values.reviews[index].picture?.source : ""}
                                      setPicture={(e) => setFieldValue(`reviews[${index}].picture`, {source: e})}/>
                                  </Box>
                                </Grid>
                                <Typography variant="body2" color="textSecondary">
                                  {t("Language")}
                                </Typography>
                                <RadioGroup row
                                            value={values.reviews ? values.reviews[index].language : ""}
                                            onChange={(event, value)=>setFieldValue(`reviews[${index}].language`, value)} >
                                  {convertEnumToArray(AppLanguage).map(lng=>
                                    <FormControlLabel value={lng} control={<Radio />} label={appLanguages[lng]} key={lng} />)}
                                </RadioGroup>
                                <Grid item xs={12}>
                                  <Button
                                    style={{marginTop: "10px"}}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon/> {t("Delete review")}
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item xs={8} container spacing={2}>
                                <Grid item xs={6}><Field
                                  component={MyTextField}
                                  label={t("Title")}
                                  placeholder={t("Review title example")}
                                  fullWidth
                                  name={`reviews.${index}.title`}/></Grid>
                                <Grid item xs={6}>
                                  <Field
                                    label={t("UserName")}
                                    placeholder={t("UserName")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`reviews.${index}.userName`}/>
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    multiline
                                    rows={5}
                                    label={t("Text")}
                                    placeholder={t("Text")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`reviews.${index}.text`}/>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{margin: "10px 0"}}/>
                          </Box>)
                        })
                        }
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push({picture: {source: ""},language:AppLanguage.ru})}
                        >
                          {t("Add review")}
                        </Button>
                      </Box>
                    )}/>
                </AccordionDetails>
              </Accordion>
              <Box height="20px"/>
              <Box textAlign="center">
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  style={{width: "30%"}}
                  id="bottomBtn"
                  onClick={() => {
                    setEnableValidation(true)
                    submitForm()
                  }}
                  disabled={loading}
                >
                  {loading
                    ? <CircularProgress style={{color: "white"}} size={30}/>
                    : <><SaveIcon style={{marginRight: 10}}/>
                      {t("Save")}</>
                  }
                </Fab>
              </Box>
            </Box>
          </Form>)
        }}
      </Formik>
      {loading && <FullscreenLoader transparent/>}
      {(error || paramsError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
      {showCreateNotification && (
        <NotificationToast
          message={t("Expert created")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
      {updated && (
        <NotificationToast
          message={t("Expert updated")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
      {updateError && (
        <NotificationToast
          message={updateError}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface ExpertFormValues {
  name: string;
  userId?: string | null;
  position: string;
  description: string;
  experience?: {
    company: GetExpertCompanies_getExpertCompanies | null;
    position: string;
    startedAt?: any | null;
    finishedAt?: any | null;
    isWorkingCurrently?: boolean | null;
  }[] | null;
  usefulness?: EditorState;
  slug: string;
  picture?: null | FileInput;
  expertRequests: string[];
  expertActivities: string[];
  services?: {
    title: string;
    description: EditorState;
    meetingsInfo?: string | null;
    price?: PriceInput | null;
    affiliateLink?: string | null;
    promoText?: string | null;
    document?: FileInput | null;
    isProduct: boolean;
  }[];
  reviews?: ExpertReviewInput[];
  locations?: string[];
  contacts?: ContactsInput;
  languages?: string[];
  status: PublishStatus;
}

