import {useMutation} from "@apollo/client";
import {
  UPDATE_PARTNER_COMPANY_CATEGORY
} from "../../graphql/mutations/partnerCompanies/updatePartnerCompanyCategory";
import {
  UpdatePartnerCompanyCategory,
  UpdatePartnerCompanyCategoryVariables
} from "../../graphql/mutations/partnerCompanies/updatePartnerCompanyCategory/__generated__/UpdatePartnerCompanyCategory";

export const useUpdatePartnerCompanyCategory = () => {
  const [update, { loading: uLoading }] = useMutation<
    UpdatePartnerCompanyCategory,
    UpdatePartnerCompanyCategoryVariables
  >(UPDATE_PARTNER_COMPANY_CATEGORY, {
    onError: (err) => console.error(err),
  });

  return { update, uLoading };
};
