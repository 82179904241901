import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GET_ARTICLE } from "../graphql";
import {
  GetArticle,
  GetArticleVariables,
} from "../graphql/queries/article/GetArticle/__generated__/GetArticle";

interface EditArticleContentParams {
  id: string;
}

interface EditArticleContentState {
  content?: string;
  title: string;
}

export const useFetchArticleContentFromRoute = () => {
  const { id } = useParams<EditArticleContentParams>();
  const location = useLocation<EditArticleContentState>();
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [processing, setProcessing] = useState(true);
  const [getArticle, { error, data }] = useLazyQuery<
    GetArticle,
    GetArticleVariables
  >(GET_ARTICLE, {
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (location.state?.title) {
      setUrl(location.state.content ?? "");
      setTitle(location.state.title);
      setProcessing(false);
    } else {
      getArticle({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getArticle) {
      setUrl(data.getArticle.content?.length ? data.getArticle.content : "");
      setTitle(data.getArticle.title);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    url,
    title,
    error,
    id,
    article: data?.getArticle,
  };
};
