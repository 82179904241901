import {useMutation} from "@apollo/client";
import {DELETE_EXPERT_REQUEST} from "../../../graphql";
import {
  GetExpertRequests_getExpertRequests
} from "../../../graphql/queries/experts/getExpertRequests/__generated__/GetExpertRequests";
import {
  DeleteExpertRequest,
  DeleteExpertRequestVariables
} from "../../../graphql/mutations/experts/deleteExpertRequest/__generated__/DeleteExpertRequest";

export const useDeleteExpertRequest = (requestToDelete: GetExpertRequests_getExpertRequests) => {
  const [deleteExpertRequest, { loading, error, data }] = useMutation<
    DeleteExpertRequest,
    DeleteExpertRequestVariables
    >(DELETE_EXPERT_REQUEST, {
    onError: (err) => console.error(err),
    update: (cache) => {
      cache.modify({
        fields: {
          getExpertRequests(existing = [], { readField }) {
            return (<any[]>existing).filter(
              (ref) =>
                requestToDelete.id !== readField("id", ref)
            );
          },
        },
      });
    },
  });

  return {
    deleteExpertRequest,
    loading,
    error,
    success: !!data?.deleteExpertRequest,
  };
};