import {gql} from "@apollo/client";
import {CountryFragment, FileFragment} from "../../../fragments";

export const GET_EXPERT_PARAMS = gql`
    query GetExpertParams {
        getExpertParams {
        expertRequests{
            title
            category
            id
        }
        expertActivities{
            title
            id
        }
        expertCompanies{
            name
            id
            logo{
                ...File
            }
        }
        countries{
            ...Country
        }
        }
    }
    ${FileFragment}
    ${CountryFragment}
`;