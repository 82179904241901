import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { GetLearningPaths_getLearningPaths_learningPaths } from "../../../../graphql/queries/learningPath/getLearningPaths/__generated__/GetLearningPaths";
import { BottomArea } from "./BottomArea";
import { TopArea } from "./TopArea";

interface LearningPathCardProps {
  path: GetLearningPaths_getLearningPaths_learningPaths;
}

export const LearningPathCard: React.FC<LearningPathCardProps> = ({ path }) => {
  return (
    <Link
      to={`/learning-paths/${path.slug}`}
      style={{ color: "unset", textDecoration: "unset" }}
    >
      <Box
        style={{
          border: "1px solid #dfdfdf",
          borderRadius: "6px",
          boxShadow: "1px 1px 7px -2px #a9a9a9",
        }}
      >
        <TopArea path={path} />

        <BottomArea path={path} />
      </Box>
    </Link>
  );
};
