import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {Field, Form, Formik, FormikErrors} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {MyTextField, NotificationToast,} from "../../../components";
import {IPicUpload} from "../../../lib";
import {
  GetExpertCompanies_getExpertCompanies
} from "../../../graphql/queries/experts/getExpertCompanies/__generated__/GetExpertCompanies";
import {
  useUpdateExpertCompany
} from "../../../hooks/mutations/experts/useUpdateExpertCompany";
import {
  useCreateExpertCompany
} from "../../../hooks/mutations/experts/useCreateExpertCompany";
import {PictureInBase64Upload} from "../../../components/inputs/PictureInBase64Upload";
import {FileInput, FileType} from "../../../graphql/globalTypes";
import {Box} from "@mui/system";

interface EditExpertCompanyProps {
  expertCompany: GetExpertCompanies_getExpertCompanies | null;
  open: boolean;
  onClose: () => void;
}

export const EditExpertCompany: React.FC<EditExpertCompanyProps> = ({
                                                                      expertCompany,
                                                                      open,
                                                                      onClose,
                                                                    }) => {
  const {t} = useTranslation();
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const [enableValidation, setEnableValidation] = useState(false);
  const [serverError, setServerError] = useState(null);
  const {update, uLoading} = useUpdateExpertCompany();
  const {create, loading} = useCreateExpertCompany();

  useEffect(() => {
    if (!open) {
      setPicUpload(null);
      setServerError(null);
    }
  }, [open]);

  const initialValues: FormValues = {
    name: expertCompany?.name ?? "",
    logo: expertCompany?.logo ? {...expertCompany.logo, source: "", type:FileType.IMAGE} : null
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Required")),
  });

  async function handleSubmit(
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    console.log(values)
    try {
      if (!expertCompany) {
        const res = await create({
          variables: {input: values},
        });
        if (res.data?.createExpertCompany.error) {
          setErrors({name: t("Title already exists")});
        } else {
          onClose();
        }
      } else {
        const {name} = values
        const res = await update({
          variables: {
            input: {
              id: expertCompany.id,
              ...(name !== expertCompany.name ? {name} : {}),
              ...(values.logo?.source ? {logo: {source: values.logo?.source}} : {}),
            },
          },
        });
        if (res.data?.updateExpertCompany.error) {
          setErrors({name: t("Title already exists")});
        } else {
          onClose();
        }
      }
    } catch (err) {
      setServerError(err as any);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {expertCompany ? t("Edit expert company") : t("Add expert company")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          await handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({values, dirty, submitForm, setFieldValue}) => (
          <Form>
            <>
              <DialogContent>
                <Box width="150px" height="150px">
                  <PictureInBase64Upload
                    setPicture={(e) => setFieldValue("logo", {source: e})}
                    pictureResolution={"100*100"}
                    src={values.logo?.source ? values.logo.source : expertCompany?.logo?.url}/>
                </Box>
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Title")}
                  name="name"
                  style={{marginBottom: 20, marginTop: 30}}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={loading || uLoading} onClick={onClose}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={
                    ((!values.name || !dirty) &&
                      (!picUpload || !values.name)) ||
                    loading ||
                    uLoading
                  }
                >
                  {t("Save")}
                  {(loading || uLoading) && (
                    <CircularProgress
                      thickness={7}
                      style={{marginLeft: 10}}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>

      {serverError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Dialog>
  );
};

interface FormValues {
  name: string;
  logo?: FileInput | null
}
