import {useQuery} from "@apollo/client";
import {
  GetOrders,
  GetOrdersVariables
} from "../../graphql/queries/products/getOrders/__generated__/GetOrders";
import {GET_ORDERS} from "../../graphql/queries/products/getOrders";

export const useGetOrders = (variables:GetOrdersVariables) => {
  const { data, loading, error } = useQuery<
    GetOrders,
    GetOrdersVariables
    >(GET_ORDERS, {
    onError: (err) => console.error(err),
    variables,
  });

  return { data, loading, error };
};
