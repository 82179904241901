import { useReactiveVar } from "@apollo/client";
import { Box, FormControlLabel, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userVar } from "../../../apollo";
import { MySwitch, NotificationToast } from "../../../components";
import { useUpdateMySummary } from "../../../hooks";
import { NotificationType } from "../../../lib";
import { convertEnumToArray } from "../../../utils";

export const Notifications: React.FC = () => {
  const user = useReactiveVar(userVar);
  const { t } = useTranslation();
  const [chosenNotifications, setChosenNotifications] = useState(
    user!.notificationTypes
  );
  const { error, loading, update } = useUpdateMySummary();

  useEffect(() => {
    if (!compareArrays(chosenNotifications, user!.notificationTypes)) {
      updateNotifications();
    }
  }, [chosenNotifications]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChosenNotifications((prev) => [...prev, event.target.name]);
    } else {
      setChosenNotifications((prev) =>
        prev.filter((n) => n !== event.target.name)
      );
    }
  };

  const updateNotifications = async () => {
    const res = await update({
      variables: {
        input: {
          notificationTypes: chosenNotifications,
        },
      },
    });
    if (res.data?.updateMySummary) {
      userVar({
        ...user!,
        notificationTypes: chosenNotifications,
      });
    }
  };

  const compareArrays = useCallback((arr1: string[], arr2: string[]) => {
    arr1 = arr1.sort((a, b) => +a - +b);
    arr2 = arr2.sort((a, b) => +a - +b);

    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }, []);

  return (
    <>
      <Box margin={3} />
      <Typography variant="overline">{t("Notifications")}</Typography>

      <Box display="flex" flexDirection="column">
        {convertEnumToArray(NotificationType).map((notific) => (
          <FormControlLabel
            key={notific}
            disabled={loading}
            control={
              <MySwitch
                checked={chosenNotifications.includes(notific)}
                onChange={handleChange}
                name={notific}
              />
            }
            label={t(notific)}
          />
        ))}
      </Box>

      <Box margin={3} />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
