import {useMutation} from "@apollo/client";
import {
  CompletelyRemoveUser, CompletelyRemoveUserVariables
} from "../../graphql/mutations/users/removeUser/__generated__/CompletelyRemoveUser";
import {REMOVE_USER} from "../../graphql/mutations/users/removeUser";
import {GET_USERS} from "../../graphql";


export const useRemoveUser = () => {
  const [removeUser, {loading, error, data}] = useMutation<CompletelyRemoveUser,
    CompletelyRemoveUserVariables>(REMOVE_USER, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_USERS,
        variables:{
          input:{},
          paginationOptions:{
            limit: 10,
            skip: 0,
            sort: {
              field: "created_at",
              sortBy: "desc",
            },
          }
        }
      },
    ],
  });

  return {
    removeUser,
    loading,
    error,
    data
  };
};