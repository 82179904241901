import {Box} from "@mui/system";
import {Checkbox, CheckboxProps} from "@material-ui/core";
import React from "react";


interface SingleCheckboxProps extends CheckboxProps{
  title:string
}

export const SingleCheckbox = ({title,...rest}:SingleCheckboxProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Checkbox {...rest}/> <Box>{title}</Box>
    </Box>
  );
};