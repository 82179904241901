import {gql} from "@apollo/client";

export const CREATE_EXPERT_ACTIVITY = gql`
    mutation CreateExpertActivity($input: CreateExpertActivityInput!) {
        createExpertActivity(input: $input) {
            expertActivity {
                title
                id
            }
            error {
                name
                message
            }
        }
    }
`;
