import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AtomProgress, NotificationToast } from "../../components";
import { GET_SUBSCRIPTIONS } from "../../graphql";
import {
  GetSubscriptionsInput,
  PaginationOptionsArgs,
} from "../../graphql/globalTypes";
import {
  GetSubscriptions,
  GetSubscriptionsVariables,
  GetSubscriptions_getSubscriptions_subscriptions,
} from "../../graphql/queries/subscriptions/GetSubscriptions/__generated__/GetSubscriptions";
import { useSetHeadbarTitle } from "../../hooks";
import { SubscriptionsFilterBar, SubscriptionsTable } from "./components";

interface SubscriptionsProps {}

export const Subscriptions: React.FC<SubscriptionsProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Subscriptions"));
  const [subscriptions, setSubscriptions] = useState<
    GetSubscriptions_getSubscriptions_subscriptions[]
  >([]);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [filterParams, setFilterParams] = useState<GetSubscriptionsInput>({
    planId: undefined,
    status: undefined,
  });
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "startTime",
        sortBy: "desc",
      },
    });
  const { data, loading, error, refetch } = useQuery<
    GetSubscriptions,
    GetSubscriptionsVariables
  >(GET_SUBSCRIPTIONS, {
    onError: (err) => console.error(err),
    variables: {
      input: filterParams,
      paginationOptions,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      skip: 0,
    }));
  }, [filterParams.planId, filterParams.status]);
  useEffect(() => {
    if (data?.getSubscriptions) {
      setSubscriptions(data.getSubscriptions.subscriptions);
      setTotalSubscriptions(data.getSubscriptions.total);
    }
  }, [data]);

  return (
    <>
      <SubscriptionsFilterBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />

      <Box padding={3} marginTop={4}>
        {loading && (
          <Box
            position="absolute"
            marginTop="5px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <SubscriptionsTable
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        subscriptions={subscriptions}
        total={totalSubscriptions}
        loading={loading}
        refetch={refetch}
        filterParams={filterParams}
      />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
