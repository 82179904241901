import {useQuery} from "@apollo/client";
import {
  GetPartnerCompanies,
  GetPartnerCompaniesVariables
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanies/__generated__/GetPartnerCompanies";
import {
  GET_PARTNER_COMPANIES
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanies";

export const useGetPartnerCompanies = (variables: GetPartnerCompaniesVariables) => {
  const { data, loading, error } = useQuery<
    GetPartnerCompanies,
    GetPartnerCompaniesVariables
    >(GET_PARTNER_COMPANIES, {
    onError: (err) => console.error(err),
    variables,
  });

  return { data, loading, error };
};