import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const UPDATE_PROMOTER_SETTINGS = gql`
    mutation UpdatePromoterSettings($input: UpdatePromoterSettingsInput!) {
        updatePromoterSettings(input: $input) {
            error {
                name
                message
            }
            user {
                ...User
            }
        }
    }
    ${UserFragment}
`;
