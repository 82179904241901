import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      ...User
    }
  }
  ${UserFragment}
`;
