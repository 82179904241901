import { useMutation } from "@apollo/client";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import { DeleteButton } from "../../../components";
import { DELETE_REVIEW, GET_REVIEWS } from "../../../graphql";
import {
  DeleteReview,
  DeleteReviewVariables,
} from "../../../graphql/mutations/reviews/DeleteReview/__generated__/DeleteReview";
import { GetReviews_getReviews } from "../../../graphql/queries/reviews/GetReviews/__generated__/GetReviews";

interface ReviewCardProps {
  review: GetReviews_getReviews;
  handleEditOpen: (r: GetReviews_getReviews) => void;
}

export const ReviewCard: React.FC<ReviewCardProps> = ({
  review,
  handleEditOpen,
}) => {
  const [deleteReview, { loading }] = useMutation<
    DeleteReview,
    DeleteReviewVariables
  >(DELETE_REVIEW, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REVIEWS,
      },
    ],
  });

  const handleDelete = async () => {
    await deleteReview({ variables: { id: review.id } });
  };

  return (
    <Box
      style={{
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.07)",
        borderRadius: "6px",
        border: "1px solid #ECEBED",
        background: "white",
        padding: "50px 60px",
        height: "100%",
        position: "relative",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Avatar
            style={{
              width: "100px",
              height: "100px",
              background: "rgb(221 221 221 / 33%)",
            }}
            src={review.avatar?.url}
          />
        </Grid>
        <Grid item xs={8} style={{ marginTop: "auto", marginBottom: "auto" }}>
          <Typography style={{ fontWeight: "bold" }}>
            {review.firstName + " " + review.lastName + ", " + review.position}
          </Typography>

          {review.categories?.map((cat) => (
            <Chip id={cat} label={cat} />
          ))}
        </Grid>
      </Grid>

      <Box height="20px" />

      <Typography style={{ fontSize: "12px" }}>{review.text}</Typography>

      <Box
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          display: "flex",
        }}
      >
        <IconButton onClick={() => handleEditOpen(review)}>
          <Edit />
        </IconButton>
        <Box width={"20px"} />
        <DeleteButton loading={loading} deleteAction={handleDelete} />
      </Box>
    </Box>
  );
};
