import { gql } from "@apollo/client";
import { EnumFragment } from "../../../fragments";

export const ADD_ENUM = gql`
  mutation AddEnum($input: AddEnumInput!) {
    addEnum(input: $input) {
      error {
        name
        message
      }
      enum {
        ...Enum
      }
    }
  }
  ${EnumFragment}
`;
