import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {
  GET_PARTNER_COMPANY_TAGS
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyTags";
import {
  CREATE_PARTNER_COMPANY_TAG
} from "../../../graphql/mutations/partnerCompanies/createPartnerCompanyTag";
import {
  DELETE_PARTNER_COMPANY_TAG
} from "../../../graphql/mutations/partnerCompanies/deletePartnerCompanyTag";
import {
  GetPartnerCompanyTags,
  GetPartnerCompanyTags_getPartnerCompanyTags
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyTags/__generated__/GetPartnerCompanyTags";
import {
  CreatePartnerCompanyTag,
  CreatePartnerCompanyTagVariables
} from "../../../graphql/mutations/partnerCompanies/createPartnerCompanyTag/__generated__/CreatePartnerCompanyTag";
import {
  DeletePartnerCompanyTag,
  DeletePartnerCompanyTagVariables
} from "../../../graphql/mutations/partnerCompanies/deletePartnerCompanyTag/__generated__/DeletePartnerCompanyTag";
import {
  CreatePartnerCompanyInput,
  CreatePartnerCompanyTagInput,
  UpdatePartnerCompanyInput
} from "../../../graphql/globalTypes";
import {useInputStyles} from "../../../components/inputs/styles";
import {
  Button,
  Chip,
  CircularProgress,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/system";

interface UpdatePartnerCompanyTagsProps {
  input: CreatePartnerCompanyInput | UpdatePartnerCompanyInput
  setInput: React.Dispatch<React.SetStateAction<CreatePartnerCompanyInput | UpdatePartnerCompanyInput>>;
}

export const UpdatePartnerCompanyTags = ({
                                           input,
                                           setInput
                                         }: UpdatePartnerCompanyTagsProps) => {

  const {
    loading: tagsLoading,
    data
  } = useQuery<GetPartnerCompanyTags>(GET_PARTNER_COMPANY_TAGS);
  const {t} = useTranslation();
  const [tags, setTags] = useState<GetPartnerCompanyTags_getPartnerCompanyTags[]>([])
  const availableTags = input.tags
    ? tags?.filter((t) => !input.tags?.includes(t.id))
    : tags;
  const existingTagsNames = tags?.map((t) => t.title.toLowerCase().trim() + "/" + t.category.toLowerCase().trim());
  const classes = useInputStyles();
  const [newTag, setNewTag] = useState<CreatePartnerCompanyTagInput>({
    title: "",
    category: ""
  });
  const [exists, setExists] = useState(false);

  const [createTag, {loading}] = useMutation<CreatePartnerCompanyTag, CreatePartnerCompanyTagVariables>(CREATE_PARTNER_COMPANY_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PARTNER_COMPANY_TAGS,
      },
    ],
  });

  const [deleteTag] = useMutation<DeletePartnerCompanyTag, DeletePartnerCompanyTagVariables>(DELETE_PARTNER_COMPANY_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PARTNER_COMPANY_TAGS,
      },
    ],
  });

  const handleGlobalTagAdd = async () => {
    const v = newTag.category.toLowerCase().trim() + "/" + newTag.title.toLowerCase()

    if (existingTagsNames.includes(v)) {
      setExists(true);
    } else {
      await createTag({variables: {input: newTag}});
      setNewTag({title: "", category: ""});
    }
  };

  const handleGlobalTagDelete = async (id: string) => {
    await deleteTag({variables: {id}});
  };

  const handleNewTagChange = (e: any, field: string) => {
    if (exists) setExists(false);
    if (field === "category") {
      setNewTag((prev) => ({...prev, category: e.target.value}))
    }
    if (field === "title") {
      setNewTag((prev) => ({...prev, title: e.target.value}))
    }
  };

  const handleAddTagToPartnerCompany = (id: string) => {
    setInput((prev) => ({
      ...prev,
      tags: prev["tags"] ? [...prev["tags"], id] : [id],
    }));
  };

  const handlePartnerCompanyTagDelete = (id: string) => {
    setInput((prev) => ({
      ...prev,
      tags: (prev["tags"] as string[])!.filter((c) => c !== id),
    }));
  };

  useEffect(() => {
    if (data) {
      setTags(data.getPartnerCompanyTags)
    }
  }, [data])

  return (
    <Box>
      <Typography variant="h6">
        {t("Requests")}
      </Typography>

      <Box margin={2}/>

      <Box display="flex" gap="10px">
        <TextField
          variant="outlined"
          fullWidth
          value={newTag.category}
          label={t("Category")}
          InputLabelProps={{shrink: true}}
          style={{flex: 2}}
          onChange={(e) => handleNewTagChange(e, "category")}
        />
        {/*<Box width="10px"/>*/}
        <TextField
          variant="outlined"
          fullWidth
          value={newTag.title}
          label={t("Title")}
          InputLabelProps={{shrink: true}}
          style={{flex: 2}}
          onChange={(e) => handleNewTagChange(e, "title")}
        />
        <Button
          variant="outlined"
          onClick={handleGlobalTagAdd}
          fullWidth
          style={{flex: 1}}
          disabled={!newTag.category || !newTag.title}
        >{loading ? <CircularProgress/> : t("Add")}

        </Button>
      </Box>

      {exists && (
        <Typography
          color="error"
          variant="caption"
          style={{marginLeft: 10}}
        >
          {t("Request already exists")}
        </Typography>
      )}

      <Box margin={2}/>

      <Box>
        {availableTags.map((tag) => (
          <Tooltip key={tag.id}
                   title={tag.category + "/" + tag.title}>
            <Chip
              key={tag.id}
              label={tag.category + "/" + tag.title}
              onDelete={() => handleGlobalTagDelete(tag.id)}
              onClick={() => handleAddTagToPartnerCompany(tag.id)}
              color="default"
              style={{margin: 3}}
              classes={{
                label: classes.chip__label,
              }}
            />
          </Tooltip>
        ))}
      </Box>

      <Box margin={2}/>

      <Typography variant="body2" color="textSecondary">
        {t("Chosen requests")}
      </Typography>

      {!!tags.length && (
        <Box>
          {(input.tags ?? []).map((tagId) => (
            <Tooltip key={tagId}
                     title={tags.find((t) => t.id === tagId)?.category + "/" + tags.find((t) => t.id === tagId)?.title}>
              <Chip
                key={tagId}
                label={tags.find((t) => t.id === tagId)?.category + "/" + tags.find((t) => t.id === tagId)?.title ?? "NOT FOUND"}
                onClick={() => handlePartnerCompanyTagDelete(tagId)}
                color="default"
                style={{margin: 3}}
                classes={{
                  label: classes.chip__label,
                }}
              />
            </Tooltip>
          ))}
        </Box>
      )}
    </Box>
  );
};
