import { useQuery, useReactiveVar } from "@apollo/client";
import { Box, Grid, Typography } from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import { Empty } from "../../../components";
import { GET_LEARNING_PATHS } from "../../../graphql";
import {
  AppLanguage,
  PaginationOptionsArgs,
  PublishStatus
} from "../../../graphql/globalTypes";
import { useSetHeadbarTitle } from "../../../hooks";
import {
  AddNewLearningPath,
  Filters,
  LearningPathCard,
} from "./components";
import {
  GetLearningPaths,
  GetLearningPaths_getLearningPaths_learningPaths,
  GetLearningPaths_getLearningPaths_meta,
  GetLearningPathsVariables
} from "../../../graphql/queries/learningPath/getLearningPaths/__generated__/GetLearningPaths";

interface LearningPathsViewProps {}

export const LearningPathsView: React.FC<LearningPathsViewProps> = ({}) => {
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  useSetHeadbarTitle(t("Learning Paths"));
  const [status, setStatus] = useState<PublishStatus | "ALL">("ALL");
  const [language, setLanguage] = useState<AppLanguage | "ALL">("ALL");
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
    });
  const [learningPaths, setLearningPaths] = useState<
    GetLearningPaths_getLearningPaths_learningPaths[]
  >([]);
  const [meta, setMeta] =
    useState<null | GetLearningPaths_getLearningPaths_meta>(null);
  const { data, loading, error } = useQuery<
    GetLearningPaths,
    GetLearningPathsVariables
  >(GET_LEARNING_PATHS, {
    variables: {
      paginationOptions,
      status: status === "ALL" ? undefined : status,
      language: language === "ALL" ? undefined : language,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.getLearningPaths) {
      setMeta(data.getLearningPaths.meta);
      setLearningPaths(data.getLearningPaths.learningPaths);
    }
  }, [data]);

  const learningPathsMarkup = (
    <Box>
      <Grid container spacing={4}>
        {learningPaths.map((path) => (
          <Grid item xs={4} key={path.id}>
            <LearningPathCard path={path} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const loadingMarkup = (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            width="100%"
            height="250px"
            animation="wave"
          />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            width="100%"
            height="250px"
            animation="wave"
          />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            width="100%"
            height="250px"
            animation="wave"
          />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            width="100%"
            height="250px"
            animation="wave"
          />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            width="100%"
            height="250px"
            animation="wave"
          />
        </Grid>
      </Grid>
    </Box>
  );

  const noDataMarkup = (
    <Empty emoji="😱" title={t("No Learning Paths Found")} />
  );

  const handleChangePage = (_e: any, page: number) => {
    setPaginationOptions!((prev) => ({
      ...prev,
      skip: paginationOptions!.limit! * page,
    }));
  };

  return (
    <>
      <Box
        zIndex={100}
        display="flex"
        flexWrap="wrap"
        width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
        justifyContent="space-between"
        position="fixed"
      >
        <Filters status={status}
                 setStatus={setStatus}
                 language={language}
                 setLanguage={setLanguage} />

        <AddNewLearningPath
          variables={{
            paginationOptions,
            status: status === "ALL" ? undefined : status,
          }}
        />
      </Box>

      <Box width="100%" marginY={10} padding="0 50px">
        {loading
          ? loadingMarkup
          : learningPaths.length
          ? learningPathsMarkup
          : noDataMarkup}

        {!!(meta && meta.totalPages > 1) && (
          <Pagination
            shape="rounded"
            count={meta.totalPages}
            hidePrevButton={meta.currentPage - 1 < 0}
            hideNextButton={meta.currentPage + 1 === meta.totalPages}
            page={meta.currentPage}
            onChange={(_e, page) =>
              page > meta.totalPages ? undefined : handleChangePage
            }
          />
        )}
      </Box>
    </>
  );
};
