import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  Fallback,
  FullscreenLoader,
  MyTable,
  MyTextField,
  NotificationToast,
  SelectButton,
} from "../../components";
import {
  ACTIVATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION_BY_ID,
  PAUSE_SUBSCRIPTION,
  REFUND_SUBSCRIPTION_SINGLE_TRANSACTION,
  UPDATE_SUBSCRIPTION,
} from "../../graphql";
import {
  ActivateSubscription,
  ActivateSubscriptionVariables,
} from "../../graphql/mutations/subscriptions/ActivateSubscription/__generated__/ActivateSubscription";
import {
  CancelSubscription,
  CancelSubscriptionVariables,
} from "../../graphql/mutations/subscriptions/CancelSubscription/__generated__/CancelSubscription";
import {
  RefundSubscriptionSingleTransaction,
  RefundSubscriptionSingleTransactionVariables,
} from "../../graphql/mutations/subscriptions/FullRefundSubscriptionTransactions/__generated__/RefundSubscriptionSingleTransaction";
import {
  PauseSubscription,
  PauseSubscriptionVariables,
} from "../../graphql/mutations/subscriptions/PauseSubscription/__generated__/PauseSubscription";
import {
  UpdateSubscription,
  UpdateSubscriptionVariables,
} from "../../graphql/mutations/subscriptions/UpdateSubscription/__generated__/UpdateSubscription";
import {
  GetSubscriptionById,
  GetSubscriptionByIdVariables,
  GetSubscriptionById_getSubscriptionById,
  GetSubscriptionById_getSubscriptionById_transactions,
} from "../../graphql/queries/subscriptions/GetSubscriptionById/__generated__/GetSubscriptionById";
import { useSetHeadbarTitle } from "../../hooks";
import { HeadCell, SubscriptionStatus } from "../../lib";
import * as Yup from "yup";

interface SubscriptionProps {}

export const Subscription: React.FC<SubscriptionProps> = ({}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [currentTransactionStatus, setCurrentTransactionStatus] =
    useState("all");
  useSetHeadbarTitle(t("Subscription info"));
  const [subscription, setSubscription] =
    useState<GetSubscriptionById_getSubscriptionById | null>(null);
  const [discountOpen, setDiscountOpen] = useState(false);
  const history = useHistory();
  const { data, loading, error } = useQuery<
    GetSubscriptionById,
    GetSubscriptionByIdVariables
  >(GET_SUBSCRIPTION_BY_ID, {
    variables: { id },
  });
  const [actions, setActions] = useState<MenuOption[]>([]);
  const [
    refund,
    { data: refundData, loading: refundLoading, error: refundError },
  ] = useMutation<
    RefundSubscriptionSingleTransaction,
    RefundSubscriptionSingleTransactionVariables
  >(REFUND_SUBSCRIPTION_SINGLE_TRANSACTION, {
    onCompleted: (res) => {
      setSubscription(res.refundSubscriptionSingleTransaction);
      setCurrentRefundTransaction(null);
    },
  });
  const [discount, { loading: discountLoading, error: discountError }] =
    useMutation<UpdateSubscription, UpdateSubscriptionVariables>(
      UPDATE_SUBSCRIPTION,
      {
        onCompleted: (res) => {
          setSubscription(res.updateSubscription);
          setDiscountOpen(false);
        },
      }
    );
  const [currentRefundTransaction, setCurrentRefundTransaction] =
    useState<GetSubscriptionById_getSubscriptionById_transactions | null>(null);
  const [transactions, setTransactions] = useState<
    GetSubscriptionById_getSubscriptionById_transactions[]
  >([]);
  const [refundValue, setRefundValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cancel, { loading: cancelLoading, error: cancelError }] = useMutation<
    CancelSubscription,
    CancelSubscriptionVariables
  >(CANCEL_SUBSCRIPTION, {
    onError: (err) => console.log(err),
    onCompleted: (res) => {
      setSubscription(res.cancelSubscription);
    },
  });
  const [activate, { loading: activateLoading, error: activateError }] =
    useMutation<ActivateSubscription, ActivateSubscriptionVariables>(
      ACTIVATE_SUBSCRIPTION,
      {
        onError: (err) => console.log(err),
        onCompleted: (res) => {
          setSubscription(res.activateSubscription);
        },
      }
    );
  const [pause, { loading: pauseLoading, error: pauseError }] = useMutation<
    PauseSubscription,
    PauseSubscriptionVariables
  >(PAUSE_SUBSCRIPTION, {
    onError: (err) => console.log(err),
    onCompleted: (res) => {
      setSubscription(res.pauseSubscription);
    },
  });
  const transactionStatuses = ["canceled", "succeeded", "all"];

  useEffect(() => {
    if (subscription) {
      switch (currentTransactionStatus) {
        case "all":
          setTransactions(subscription.transactions);
          break;
        case "canceled":
          setTransactions(
            subscription.transactions.filter(
              (tr) => tr.yooStatus === "canceled"
            )
          );
          break;
        case "succeeded":
          setTransactions(
            subscription.transactions.filter(
              (tr) => tr.yooStatus === "succeeded"
            )
          );
          break;
      }
    }
  }, [currentTransactionStatus, subscription]);

  useEffect(() => {
    if (subscription) {
      const options: MenuOption[] = ["Update Discount"];

      switch (subscription.status) {
        case SubscriptionStatus.ACTIVE:
          options.push("Cancel", "Pause");
          break;
        case SubscriptionStatus.CANCELLED:
          break;
        case SubscriptionStatus.PAUSED:
          options.push("Activate", "Cancel");
          break;
        case SubscriptionStatus.SUSPENDED:
          options.push("Activate", "Cancel");
          break;
      }
      setActions(options);
    } else {
      setAnchorEl(null);
    }
  }, [subscription]);

  useEffect(() => {
    if (data) {
      setSubscription(data.getSubscriptionById);
    }
  }, [data]);

  const handleOpenMenuFor = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (act: MenuOption) => {
    switch (act) {
      case "Activate":
        activate({ variables: { subId: subscription!.id } });
        setAnchorEl(null);
        break;
      case "Cancel":
        cancel({ variables: { subId: subscription!.id } });
        setAnchorEl(null);
        break;
      case "Pause":
        pause({ variables: { subId: subscription!.id } });
        setAnchorEl(null);
        break;
      case "Update Discount":
        setDiscountOpen(true);
        setAnchorEl(null);
        break;
    }
  };

  const handleTransactionsStatusChange = (e: any) => {
    setCurrentTransactionStatus(e.target.value as string);
  };

  const availableAmountForRefund = (
    tr: GetSubscriptionById_getSubscriptionById_transactions
  ): number => {
    if (tr.yooStatus !== "succeeded") return 0;

    if (!tr.refunds || !tr.refunds.length) return parseFloat(tr.price.value);

    const refunded = tr.refunds.reduce((acc, refund) => {
      if (refund.yooStatus !== "succeeded") {
        return acc;
      } else {
        return acc + parseFloat(refund.amount.value);
      }
    }, 0);

    return parseFloat(tr.price.value) - refunded;
  };

  const handleRefund = () => {
    if (currentRefundTransaction && subscription) {
      refund({
        variables: {
          input: {
            paymentId: currentRefundTransaction.yooId,
            subscriptionId: subscription.id,
            value: refundValue.toString(),
          },
        },
      });
    }
  };

  const handleRefundOpen = (
    tr: GetSubscriptionById_getSubscriptionById_transactions
  ) => {
    setCurrentRefundTransaction(tr);
  };

  const mapTransactionsToRows = useMemo(() => {
    const arr = transactions.map((tr) => ({
      yooID: tr.yooId,
      status: t(tr.yooStatus),
      price: (
        <Box>
          <Typography variant="subtitle2">
            {tr.price.value + " " + tr.price.currency}
          </Typography>
          {availableAmountForRefund(tr) !== parseFloat(tr.price.value) && (
            <Typography
              variant="body2"
              style={{
                border: "1px solid #ddd",
                width: "fit-content",
                padding: "0 5px",
                opacity: 0.7,
              }}
            >
              {t("Refunded")}:{" "}
              {parseFloat(tr.price.value) -
                availableAmountForRefund(tr) +
                " " +
                tr.price.currency}
            </Typography>
          )}
        </Box>
      ),
      capturedAt: moment(tr.capturedAt).format("LLL"),
      refund: tr.refunds?.length ? (
        <Box>
          {tr.refunds.map((refund) => (
            <Box key={refund.yooId} borderBottom={"1px solid #ddd"}>
              <Typography variant="caption">{refund.yooId}</Typography>
              <br />
              <Typography variant="caption">{t(refund.yooStatus)}</Typography>
              <br />
              <Typography variant="caption">
                {refund.amount.value + " " + refund.amount.currency}
              </Typography>
              <br />
              <Typography variant="caption">
                {moment(refund.capturedAt).format("LLL")}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        ""
      ),
      edit: availableAmountForRefund(tr) ? (
        <Button onClick={() => handleRefundOpen(tr)}>{t("To refund")}</Button>
      ) : (
        ""
      ),
    }));
    return arr;
  }, [transactions]);

  const validationSchema = Yup.object({
    discountPercentage: Yup.number()
      .min(0, "Скидка не может быть отрицательной")
      .required(t("Required")),
    numberOfCharges: Yup.number()
      .min(0, "Количество не может быть отрицательным")
      .required(t("Required")),
  });

  const initialValues: FormValues = {
    discountPercentage: subscription?.discount?.percentage
      ? subscription.discount.percentage * 100
      : 0,
    numberOfCharges: subscription?.discount?.numberOfCharges ?? 0,
  };

  if (!subscription && loading) {
    return <FullscreenLoader />;
  }

  if (error) {
    return (
      <Fallback
        title={t("Subsctiption not found")}
        onClick={() => history.push("/subscriptions")}
        btnTitle={t("OK")}
      />
    );
  }

  return (
    <Box>
      <Box
        padding="20px 30px"
        style={{
          background: "white",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box display="flex">
            <Typography
              style={{
                paddingRight: "20px",
                borderRight: "1px solid #000",
              }}
            >
              {t("Subscription ID")}:{" "}
              <span style={{ fontWeight: "bold" }}>{subscription?.id}</span>
            </Typography>
            <Typography
              style={{
                paddingLeft: "20px",
              }}
            >
              {t("Status")}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {t(subscription?.status!)}
              </span>
            </Typography>
          </Box>

          {actions.length && (
            <>
              <IconButton onClick={(e) => handleOpenMenuFor(e)}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {actions.map((act) => (
                  <MenuItem key={act} onClick={() => handleAction(act)}>
                    {t(act)}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>

        <Box
          style={{
            background: "#f6f7ff",
            border: "1px solid #ddd",
            padding: "10px",
            width: "fit-content",
            marginTop: "20px",
          }}
        >
          {Boolean(
            subscription?.status === SubscriptionStatus.ACTIVE ||
              subscription?.status === SubscriptionStatus.SUSPENDED
          ) && (
            <Typography>
              {t("Next payment due on")}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(subscription!.nextTimeCharge).format("LLL")}
              </span>
            </Typography>
          )}
          {subscription?.status === SubscriptionStatus.PAUSED && (
            <Typography>
              {t("Active until")}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(subscription.nextTimeCharge).format("LLL")}
              </span>
            </Typography>
          )}
        </Box>
      </Box>

      <Box height="50px" />

      <Box
        marginX="30px"
        display="flex"
        justifyContent={"space-between"}
        alignItems="stretch"
        position="relative"
      >
        <Box
          padding="20px"
          width="calc((100% - 60px) / 2)"
          style={{
            background: "white",
          }}
        >
          <Typography
            style={{
              paddingBottom: "10px",
              borderBottom: "1px solid #ddd",
            }}
            variant="h5"
          >
            {t("SUBSCRIBER")}
          </Typography>

          <Box height="20px" />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography>{t("Email")}:</Typography>
              <Typography>{t("firstName")}:</Typography>
              <Typography>{t("lastName")}:</Typography>
              <Typography>{t("startTime of subscription")}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.user?.email}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                {subscription?.user?.firstName}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                {subscription?.user?.lastName}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                {moment(subscription?.startTime).format("LLL")}
              </Typography>
            </Grid>
          </Grid>

          {subscription?.discount && (
            <>
              <Divider
                style={{
                  margin: "20px 0",
                }}
              />

              <Typography style={{ fontSize: "18px" }}>
                {t("Discount")}: {subscription.discount.percentage * 100}%,{" "}
                {t("left")}: {subscription.discount.numberOfCharges} списания
              </Typography>

              {subscription.discount.coupon && (
                <Box
                  style={{
                    border: "1px solid orange",
                    color: "orange",
                    width: "fit-content",
                    padding: "5px",
                    borderRadius: 6,
                    fontSize: 14,
                  }}
                >
                  {subscription.discount.coupon.couponCode}
                </Box>
              )}
            </>
          )}
        </Box>
        <Box
          style={{
            background: "white",
          }}
          padding="20px"
          width="calc((100% - 60px) / 2)"
        >
          <Typography
            style={{
              paddingBottom: "10px",
              borderBottom: "1px solid #ddd",
            }}
            variant="h5"
          >
            {t("Plan")}
          </Typography>

          <Box height="20px" />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography>ID:</Typography>
              <Typography>{t("Title")}:</Typography>
              <Typography>{t("price")}:</Typography>
              <Typography>{t("billingCycle")}:</Typography>
              <Typography>{t("VAT code")}:</Typography>
              <Typography>{t("Payment Subject")}:</Typography>
              <Typography>{t("Payment Method")}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.plan.id}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.plan.title}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.plan.price.value +
                  " " +
                  subscription?.plan.price.currency}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {t(subscription?.plan.billingCycle!)}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.plan.taxData.vatCode}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.plan.taxData.paymentSubject}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {subscription?.plan.taxData.paymentMethod}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box height="50px" />

      <Box paddingX="30px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography style={{ flex: 1 }} variant="h5">
            {t("Transactions")}
          </Typography>

          <SelectButton
            squared
            onChange={handleTransactionsStatusChange}
            value={currentTransactionStatus}
            options={transactionStatuses}
            rootStyle={{ flex: 0.5 }}
            label={t("Status")}
          />
        </Box>

        <MyTable
          selected={[]}
          loading={loading}
          total={transactions.length}
          setSelected={() => {}}
          headCells={headCells}
          values={mapTransactionsToRows}
          sortFields={[]}
          hideCheck
          bulkActions={
            // <BulkActions
            //   onApply={(option) => applyAction(option)}
            //   options={bulkOptions}
            // />
            <></>
          }
        />
      </Box>

      {Boolean(
        cancelError ||
          refundError ||
          activateError ||
          pauseError ||
          discountError
      ) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}

      <Dialog open={discountOpen} onClose={() => setDiscountOpen(false)}>
        <DialogTitle>{t("Update Discount")}</DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(
            { discountPercentage, numberOfCharges },
            { setSubmitting }
          ) => {
            discount({
              variables: {
                input: {
                  id: subscription!.id,
                  discountInput: {
                    discountPercentage: discountPercentage / 100,
                    numberOfCharges,
                  },
                },
              },
            });
            setSubmitting(false);
          }}
        >
          {({ submitForm }) => (
            <Form>
              <>
                <DialogContent>
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("Discount") + ", %"}
                    name="discountPercentage"
                    type="number"
                  />
                  <Box height="20px" />
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={"Количетсво списаний"}
                    type="number"
                    name="numberOfCharges"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDiscountOpen(false)}>
                    {t("Cancel")}
                  </Button>
                  <Button disabled={discountLoading} onClick={submitForm}>
                    {t("OK")}
                    {discountLoading && <CircularProgress />}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={!!currentRefundTransaction}
        onClose={() => setCurrentRefundTransaction(null)}
      >
        <DialogTitle>{t("To refund")}</DialogTitle>
        {currentRefundTransaction && (
          <>
            <DialogContent>
              <TextField
                variant="outlined"
                value={refundValue}
                type="number"
                onChange={(e) =>
                  setRefundValue(parseFloat(e.target.value as any))
                }
                helperText={t("MAX_AVAL_REFUND", {
                  value: availableAmountForRefund(currentRefundTransaction),
                  currency: "RUB",
                })}
                error={
                  refundValue < 1 ||
                  refundValue >
                    availableAmountForRefund(currentRefundTransaction)
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentRefundTransaction(null)}>
                {t("Cancel")}
              </Button>
              <Button
                disabled={
                  refundValue < 1 ||
                  refundValue >
                    availableAmountForRefund(currentRefundTransaction) ||
                  !refundValue ||
                  refundLoading
                }
                onClick={handleRefund}
              >
                {t("OK")}
                {refundLoading && <CircularProgress />}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

const headCells: HeadCell[] = [
  {
    label: "yooID",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "price",
    align: "left",
  },
  {
    label: "capturedAt",
    align: "center",
  },
  {
    label: "refund",
    align: "left",
  },
  {
    label: "edit",
    align: "left",
  },
];

type MenuOption =
  | "See_details"
  | "Activate"
  | "Pause"
  | "Cancel"
  | "Update Discount";

interface FormValues {
  discountPercentage: number;
  numberOfCharges: number;
}
