import {gql} from "@apollo/client";
import {AffiliateFragment} from "../../../fragments";

export const UPDATE_AFFILIATE = gql`
    mutation UpdateAffiliate($input: UpdateAffiliateInput!) {
        updateAffiliate(input: $input) {
            error {
                name
                message
            }
            affiliate {
                ...Affiliate
            }
        }
    }
    ${AffiliateFragment}
`;