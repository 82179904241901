import { gql } from "@apollo/client";
import {
  SubscriptionPlanFragment,
  SubscriptionUserFragment,
  TransactionFragment,
} from "../../..";

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      id
      planId
      userId
      user {
        ...SubscriptionUser
      }
      status
      lastTimeAmount {
        currency
        value
      }
      startTime
      lastTimeCharge
      nextTimeCharge
      statusUpdateTime
      plan {
        ...SubscriptionPlan
      }
      transactions {
        ...Transaction
      }
      discount {
        percentage
        numberOfCharges
        coupon {
          couponCode
          countUsers
          countTimes
          discountPercentage
        }
      }
    }
  }
  ${SubscriptionUserFragment}
  ${SubscriptionPlanFragment}
  ${TransactionFragment}
`;
