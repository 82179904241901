import { gql } from "@apollo/client";
import { SubscriptionPlanFragment } from "../../../fragments";

export const GET_ADMIN_SUBSCRIPTION_PLANS = gql`
  query GetAdminSubscriptionPlans($status: String) {
    getAdminSubscriptionPlans(status: $status) {
      plan {
        ...SubscriptionPlan
      }
      countSubsctriptions
    }
  }
  ${SubscriptionPlanFragment}
`;
