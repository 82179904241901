import {gql} from "@apollo/client";
import {CountryFragment, FileFragment} from "../../../fragments";

export const GET_EXPERT_FILTERS = gql`
    query GetExpertFilters {
        getExpertFilters{
            countries{
                ...Country
            }
            languages
            expertActivities{
                title
                id
            }
            expertRequests{
                title
                category
                id
            }
            expertCompanies{
                name
                id
                logo{
                    ...File
                }
            }
        }
    }
    ${CountryFragment}
    ${FileFragment}
`;