import { gql } from "@apollo/client";

export const VERIFY_EMAIL_VIA_LINK = gql`
  mutation VerifyEmailViaLink($token: String!) {
    verifyEmailViaLink(token: $token) {
      success
      error {
        name
        message
      }
    }
  }
`;
