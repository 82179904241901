import { gql } from "@apollo/client";

export const UPDATE_COUPON = gql`
  mutation UpdateCoupon($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      coupon {
        id
        couponCode
        countUsers
        countTimes
        discountPercentage
      }
      error {
        name
      }
    }
  }
`;
