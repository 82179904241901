import React, {useMemo, useState} from 'react';
import {MyTable} from "../../../components";
import {Box, Link} from "@material-ui/core";
import {GetTrackerItemsInput, PaginationOptionsArgs} from "../../../graphql/globalTypes";
import {
  GetTrackerItems_getTrackerItems_trackerItems
} from "../../../graphql/queries/jobApplicationTracker/getTrackerItems/__generated__/GetTrackerItems";
import {HeadCell} from "../../../lib";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import {DeleteItemDialog} from "./DeleteItemDialog";

interface TrackerTableProps {
  loading: boolean;
  items: GetTrackerItems_getTrackerItems_trackerItems[];
  totalItems: number;
  filterParams: GetTrackerItemsInput;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
}

export const TrackerTable = ({
                               loading,
                               items,
                               totalItems,
                               filterParams,
                               paginationOptions,
                               setPaginationOptions
                             }: TrackerTableProps) => {
  const {t} = useTranslation();
  const rows = useMemo(() => {
    const arr = items.map((item) => ({
      id: item.id,
      title: <Box width={200}>
        <Box fontWeight="500">{t("Job title")}: {item.title}</Box>
        <Box fontSize="13px">{t("Company")}: {item.company}</Box>
        {item.country && <Box>
          {t("Country")}: {item.country.rusTrans}
        </Box>}
        {item.link && <Link style={{cursor: "pointer"}}>
          {item.link?.slice(0, 30)}...
        </Link>}
      </Box>,
      createdBy: <Box width={200}>
        <Box fontWeight="500">{item.createdBy.email}</Box>
        <Box fontSize="13px">{item.createdBy.firstName} {item.createdBy.lastName}</Box>
        <Box fontSize="12px">{moment(item.createdAt).format("DD.MM.YYYY HH:MM")}</Box>
        <DeleteItemDialog item={item} filterParams={filterParams}
                          paginationOptions={paginationOptions}/>
      </Box>,
      createdAt: <Box width={100}>
        <Box fontSize="13px">
          <Box>{t("Creation date")}:</Box>
          <Box fontWeight="500">
            {moment(item.createdAt).format("DD.MM.YYYY HH:MM")}
          </Box>
        </Box>
        {item.appliedAt && <Box fontSize="13px">
          <Box>{t("Application date")}:</Box>
          <Box fontWeight="500">
            {moment(item.appliedAt).format("DD.MM.YYYY HH:MM")}
          </Box>
        </Box>}
      </Box>,
      status: <Box width={100} fontWeight={500}>
        {t(item.status)}
      </Box>,
      description:
        <>
          {item.description &&
            <Box fontSize="12px"
                 padding="0 10px"
                 width="250px"
                 style={{cursor:"pointer"}}
            >
              <DescriptionCell description={item.description}/>
            </Box>}
        </>
    }));

    return arr;
  }, [items]);

  return (
    <Box width="100%">
      <MyTable
        hideCheck
        loading={loading}
        total={totalItems}
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        headCells={headCells}
        values={rows}
        sortFields={["createdBy", "status", "createdAt", "title"]}
        bulkActions={
          <></>
        }
      />
    </Box>
  );
};

const headCells: HeadCell[] = [
  {
    label: "createdBy",
    align: "left",
  },
  {
    label: "title",
    align: "left",
  },
  {
    label: "createdAt",
    align: "center",
  },
  {
    label: "status",
    align: "center",
  },
  {
    label: "description",
    align: "left",
  },
];

interface DescriptionCellProps {
  description: string
}

const DescriptionCell = ({description}: DescriptionCellProps) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  return (
    show
      ? <div dangerouslySetInnerHTML={{__html: description}}
             onClick={() => setShow(false)}/>
      : <Box onClick={() => setShow(true)}>{t("Show")}</Box>
  )
}