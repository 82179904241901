import React, {useEffect, useState} from 'react';
import {useGetExperts, useSetHeadbarTitle} from "../../hooks";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/system";
import {AddNewButton, NotificationToast} from "../../components";
import {useHistory} from "react-router-dom";
import {
  GetExperts_getExperts_experts
} from "../../graphql/queries/experts/getExperts/__generated__/GetExperts";
import {GetExpertsInput, PaginationOptionsArgs} from "../../graphql/globalTypes";
import {ExpertsTable} from "./components/ExpertsTable";
import {ExpertsFilterBar} from "./components/ExpertsFilterBar";

export const Experts = () => {
  const {t} = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Experts"));
  const handleAddNewProductClick = () => {
    history.push("/experts/create");
  }

  const [totalExperts, setTotalExperts] = useState(0);
  const [experts, setExperts] = useState<GetExperts_getExperts_experts[]>([]);
  const [filterParams, setFilterParams] = useState<GetExpertsInput>({});
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "latest",
        sortBy: "desc",
      },
    });
  const {data, loading, error} = useGetExperts({
    input: filterParams,
    paginationOptions
  })

  useEffect(() => {
    if (data) {
      setTotalExperts(data.getExperts.total);
      setExperts(data.getExperts.experts);
    }
  }, [data]);

  return (
    <Box margin="20px">
      <Box textAlign="right" margin="10px 0 0">
        <ExpertsFilterBar filterParams={filterParams} setFilterParams={setFilterParams}/>
        <Box height="10px"/>
        <AddNewButton title={t("Add new expert")}
                      onClick={handleAddNewProductClick}/>
        <ExpertsTable
          loading={loading}
          experts={experts}
          totalExperts={totalExperts}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}/>
      </Box>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};
