import {gql} from "@apollo/client";
import {UserFragment} from "../../../fragments";

export const TOGGLE_USER_PARTNERSHIP = gql`
    mutation ToggleUserPartnership($id: String!) {
        toggleUserPartnership(id: $id) {
            ...User
        }
    }
    ${UserFragment}
`;