import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLoginRoute } from "../../hooks";
import { ForgotPasswordForm, Header, LoginForm } from "./components";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const { currentSection } = useLoginRoute();

  return (
    <Box padding={2} position="relative" textAlign="center">
      <Header />
      <Box paddingY={3}>
        {currentSection === "login" ? <LoginForm /> : <ForgotPasswordForm />}
      </Box>
    </Box>
  );
};
