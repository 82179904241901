import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CREATE_REFERRAL,
  DELETE_REFERRAL,
  GET_COMPANIES,
  GET_REFERRALS,
  UPDATE_REFERRAL,
} from "../../../../graphql";
import {
  PaginationOptionsArgs,
  ReferralsFilter,
} from "../../../../graphql/globalTypes";
import {
  CreateReferral,
  CreateReferralVariables,
} from "../../../../graphql/mutations/referral/createReferral/__generated__/CreateReferral";
import {
  DeleteReferral,
  DeleteReferralVariables,
} from "../../../../graphql/mutations/referral/deleteReferral/__generated__/DeleteReferral";
import {
  UpdateReferral,
  UpdateReferralVariables,
} from "../../../../graphql/mutations/referral/updateReferral/__generated__/UpdateReferral";
import { GetReferrals_getReferrals_referrals } from "../../../../graphql/queries/referral/getReferrals/__generated__/GetReferrals";
import { ReferralType } from "../../../../lib";
import {
  FormikFieldMultipleAutocomplete,
  MyTextField,
} from "../../../../components";
import { Select } from "formik-material-ui";
import { convertEnumToArray } from "../../../../utils";
import {
  GetCompanies,
  GetCompaniesVariables,
  GetCompanies_getCompanies_companies,
} from "../../../../graphql/queries/company/getCompanies/__generated__/GetCompanies";

interface EditReferralProps {
  open: boolean;
  onClose: () => void;
  referral: GetReferrals_getReferrals_referrals | null;
  paginationOptions: PaginationOptionsArgs;
  filter: ReferralsFilter;
}

function isLinkedInLink(str : string) : boolean {
  return (str != null && str.includes("linkedin"));
}

export const EditReferral: React.FC<EditReferralProps> = ({
  open,
  onClose,
  referral,
  paginationOptions,
  filter,
}) => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<
    GetCompanies_getCompanies_companies[]
  >([]);
  const { loading: companiesLoading } = useQuery<
    GetCompanies,
    GetCompaniesVariables
  >(GET_COMPANIES, {
    variables: {
      filter: {},
    },
    onCompleted: (res) => {
      if (res.getCompanies.companies) {
        setCompanies(res.getCompanies.companies);
      }
    },
  });
  const [create, { loading, error }] = useMutation<
    CreateReferral,
    CreateReferralVariables
  >(CREATE_REFERRAL, {
    onCompleted: (res) => {
      onClose();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REFERRALS,
        variables: {
          paginationOptions,
          query: filter,
        },
      },
    ],
  });
  const [update, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateReferral,
    UpdateReferralVariables
  >(UPDATE_REFERRAL, {
    onCompleted: (res) => {
      onClose();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REFERRALS,
        variables: {
          paginationOptions,
          query: filter,
        },
      },
    ],
  });
  const [deleteReferral, { loading: deleteLoading }] = useMutation<
    DeleteReferral,
    DeleteReferralVariables
  >(DELETE_REFERRAL, {
    onCompleted: () => {
      onClose();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REFERRALS,
        variables: {
          paginationOptions,
          query: filter,
        },
      },
    ],
  });

  const handleDelete = () => {
    deleteReferral({ variables: { id: referral!.id } });
  };

  interface FormValues {
    firstName: string;
    lastName: string;
    type: ReferralType;
    email: string;
    phone: string;
    facebook: string;
    telegram: string;
    linkedIn: string;
    companiesIds: string[];
  }

  const initialValues: FormValues = {
    firstName: referral?.firstName ?? "",
    lastName: referral?.lastName ?? " ",
    type: (referral?.type as ReferralType) ?? ReferralType.Employee,
    email: referral?.email ?? "",
    phone: referral?.phone ?? "",
    facebook: referral?.facebook ?? "",
    telegram: referral?.telegram ?? "",
    linkedIn: referral?.linkedIn ?? "",
    companiesIds: referral?.companiesIds ?? [],
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Required")),
    lastName: Yup.string().required(t("Required")),
    companiesIds: Yup.array().min(1, t("Required")).required("required"),
    email: Yup
    .string()
    .email('Формат почты невалидный')
    .test(
      'oneOfRequired',
      'Нужен хотя бы один способ контакта (кроме телефона)',
      function(item) {
        return (this.parent.email || this.parent.facebook || this.parent.telegram || this.parent.linkedIn)
      }
    ),
    facebook: Yup
    .string()
    .matches(/facebook/i, 'Ссылка должна быть от Фейсбука')
    .matches(/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, "Ссылка не валидная")
    .test(
      'oneOfRequired',
      'Нужен хотя бы один способ контакта (кроме телефона)',
      function(item) {
        return (this.parent.email || this.parent.facebook || this.parent.telegram || this.parent.linkedIn)
      }
    ),
    telegram: Yup
    .string()
    .matches(/^[a-zA-Z0-9_]*$/, 'Должно содержать ник юзера в телеграме')
    .test(
      'oneOfRequired',
      'Нужен хотя бы один способ контакта (кроме телефона)',
      function(item) {
        return (this.parent.email || this.parent.facebook || this.parent.telegram || this.parent.linkedIn)
      }
    ),
    linkedIn: Yup
    .string()
    .matches(/linkedin/i, 'Ссылка должна быть от Линкедина')
    .matches(/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, "Ссылка не валидная")
    .test(
      'oneOfRequired',
      'Нужен хотя бы один способ контакта (кроме телефона)',
      function(item) {
        return (this.parent.email || this.parent.facebook || this.parent.telegram || this.parent.linkedIn)
      }
    ),
  });

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        {referral ? t("Edit Referral") : t("Create Referral")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          if (!referral) {
            await create({
              variables: {
                input: {
                  ...values,
                },
              },
            });
          } else {
            await update({
              variables: {
                input: {
                  id: referral.id,
                  ...values,
                },
              },
            });
          }

          setSubmitting(false);
        }}
      >
        {({ dirty, submitForm, values }) => (
          <Form>
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("firstName")}
                      name="firstName"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("lastName")}
                      name="lastName"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        padding: "18.5px 14px",
                        background: "white",
                        borderRadius: "4px",
                        boxShadow:
                          "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                      }}
                    >
                      <Field component={Select} fullWidth name="type">
                        {convertEnumToArray(ReferralType).map((type) => (
                          <MenuItem key={type} value={type}>
                            {t(type)}
                          </MenuItem>
                        ))}
                      </Field>
                    </Box>
                  </Grid>

                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("email")}
                      name="email"
                      type="email"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("phone")}
                      name="phone"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("facebook")}
                      name="facebook"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("telegram")}
                      name="telegram"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("linkedIn")}
                      name="linkedIn"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {companiesLoading ? (
                      <Typography>Компании загружаются...</Typography>
                    ) : (
                      <div>
                      <FormikFieldMultipleAutocomplete
                        name="companiesIds"
                        options={companies.map((c) => c.id)}
                        placeholder={t("companies")}
                        values={values.companiesIds}
                        getOptionLabel={(id) =>
                          companies.find((c) => c.id === id)?.name ?? ""
                        }
                      />
                      {values.companiesIds.length < 1 && <Typography>Выберите хотя бы одну компанию</Typography>}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={"space-between"}
                >
                  {referral && (
                    <>
                      <Button
                        disabled={loading || deleteLoading || updateLoading}
                        onClick={handleDelete}
                      >
                        {t("Delete referral")}
                      </Button>
                      {Boolean(deleteLoading) && (
                        <CircularProgress style={{ marginLeft: "10px" }} />
                      )}
                    </>
                  )}

                  <Box display="flex" justifyContent={"center"}>
                    <Button onClick={onClose}>{t("Close")}</Button>
                    <Button
                      disabled={loading || deleteLoading || updateLoading}
                      onClick={submitForm}
                    >
                      {t(referral ? "Save" : "Create")}
                      {Boolean(loading || updateLoading) && (
                        <CircularProgress style={{ marginLeft: "10px" }} />
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
