import { gql } from "@apollo/client";
import { ReviewFragment } from "../../../fragments";

export const UPDATE_REVIEW = gql`
  mutation UpdateReview($input: UpdateReviewInput!) {
    updateReview(input: $input) {
      ...Review
    }
  }
  ${ReviewFragment}
`;
