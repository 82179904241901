import { useMutation } from "@apollo/client";
import { CREATE_JOB, GET_JOBS } from "../../graphql";
import {
  CreateJob,
  CreateJobVariables,
} from "../../graphql/mutations/jobs/CreateJob/__generated__/CreateJob";

export const useCreateJob = () => {
  const [create, { loading, error }] = useMutation<
    CreateJob,
    CreateJobVariables
  >(CREATE_JOB, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_JOBS,
        variables: {
          input: {},
          paginationOptions: {},
        },
      },
    ],
  });

  return {
    create,
    loading,
    error,
  };
};
