import {useQuery} from "@apollo/client";
import {
  GetExpertParams
} from "../../../graphql/queries/experts/getExpertParams/__generated__/GetExpertParams";
import {GET_EXPERT_PARAMS} from "../../../graphql/queries/experts/getExpertParams";

export const useGetExpertParams = () => {
  const { data, loading, error } = useQuery<GetExpertParams>(GET_EXPERT_PARAMS, {
    onError: (err) => console.error(err),
  });
  return { loading, error,
    expertActivities:data?.getExpertParams.expertActivities,
    expertRequests:data?.getExpertParams.expertRequests,
    expertCompanies:data?.getExpertParams.expertCompanies,
    countries: data?.getExpertParams.countries,
  };
};
