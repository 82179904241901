import React from 'react';
import {TextSearch} from "../../../components";
import {PromotersListFilter} from "../../../graphql/globalTypes";


interface UsersFilterBarProps {
  filterParams: PromotersListFilter;
  setFilterParams: React.Dispatch<React.SetStateAction<PromotersListFilter>>;
}

export const PromotersFilterBar = ({filterParams,setFilterParams}:UsersFilterBarProps) => {

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        search: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      search: undefined,
    }));
  };

  return (
    <TextSearch
      rootStyle={{ flexBasis: "40%" }}
      onClick={(handleTextSearchChange)}
      onReset={handleTextSearchReset}
      hint={"email, name"}
    />
  );
};

