import React, { useState } from "react";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Snackbar, Typography } from "@material-ui/core";

interface NotificationToastProps {
  message: string | string[];
  horizontal: "center" | "right" | "left";
  severity: "error" | "warning" | "info" | "success";
  vertical: "top" | "bottom";
  notHide?: boolean;
}

export const NotificationToast: React.FC<NotificationToastProps> = ({
  message,
  horizontal,
  severity,
  vertical,
  notHide,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (_event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      {...(!notHide && {
        autoHideDuration: 5000,
      })}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        <Typography style={{ whiteSpace: "pre-line" }}>
          {Array.isArray(message) ? message.join("\r\n") : message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
