import {useMutation} from "@apollo/client";
import {
  GetExpertCompanies_getExpertCompanies
} from "../../../graphql/queries/experts/getExpertCompanies/__generated__/GetExpertCompanies";
import {DELETE_EXPERT_COMPANY} from "../../../graphql";
import {
  DeleteExpertCompany,
  DeleteExpertCompanyVariables
} from "../../../graphql/mutations/experts/deleteExpertCompany/__generated__/DeleteExpertCompany";

export const useDeleteExpertCompany = (companyToDelete: GetExpertCompanies_getExpertCompanies) => {
  const [deleteExpertCompany, { loading, error, data }] = useMutation<
    DeleteExpertCompany,
    DeleteExpertCompanyVariables
    >(DELETE_EXPERT_COMPANY, {
    onError: (err) => console.error(err),
    update: (cache) => {
      cache.modify({
        fields: {
          getExpertCompanies(existing = [], { readField }) {
            return (<any[]>existing).filter(
              (ref) =>
                companyToDelete.id !== readField("id", ref)
            );
          },
        },
      });
    },
  });

  return {
    deleteExpertCompany,
    loading,
    error,
    success: !!data?.deleteExpertCompany,
  };
};