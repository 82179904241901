import { Avatar, Box, Fab } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IErrorResponse } from "../../lib";
import { theme } from "../../styles/theme";
import { CMSUploadButton } from "../buttons";
import { NotificationToast } from "../notifications";
import { useInputStyles } from "./styles";

interface AvatarUploadProps {
  src: string;
  setAvatar: (s: string) => void;
}

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  src,
  setAvatar,
}) => {
  const classes = useInputStyles();
  const [sizeError, setSizeError] = useState<IErrorResponse | null>(null);
  const { t } = useTranslation();

  const handleImageUpload = (e: any) => {
    setSizeError(null);
    const file = e.currentTarget.files[0];

    if (file) {
      const fileIsValidSize = file.size / 1024 / 1024 < 2;
      if (!fileIsValidSize) {
        setSizeError({
          name: "general",
          message:
            "You're only able to upload valid image files of under 2MB in size!",
        });
      } else {
        getBase64Value(file, (imageBase64Value) => {
          setAvatar(imageBase64Value);
        });
      }
    }
  };

  return (
    <Box width={theme.spacing(25)} position="relative">
      <input
        accept="image/*"
        className={classes.avatarUpload__input}
        id="avatar-upload"
        type="file"
        onChange={handleImageUpload}
      />

      <label htmlFor="avatar-upload" className={classes.avatarUpload__label}>
        <Avatar className={classes.avatarUpload__avatar} src={src} />

        <Fab
          id="avatar-upload"
          color="primary"
          disabled
          className={classes.avatarUpload__btn}
        >
          <EditOutlined />
        </Fab>
      </label>

      {sizeError && (
        <NotificationToast
          horizontal="center"
          severity="error"
          vertical="bottom"
          message={t(sizeError.message!)}
        />
      )}
    </Box>
  );
};

const getBase64Value = (
  img: File | Blob,
  callback: (imageBase64Value: string) => void
) => {
  const reader = new FileReader();
  reader.readAsDataURL(img);
  reader.onload = () => {
    callback(reader.result as string);
  };
};
