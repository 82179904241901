import { Box, Typography, Divider } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/logo.png";

export const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box paddingY={2}>
        <img style={{ width: 150 }} src={logo} alt="Networkio" />
        <Typography color="secondary" variant="subtitle2">
          {t("Admin Dashboard")}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};
