import React from "react";
import type { AudioSettings } from "../types/settings";
import { lazyLoad } from "@react-page/editor";

const PlayArrow = lazyLoad(() => import("@material-ui/icons/Audiotrack"));

export const defaultTranslations = {
  pluginName: "Audio-трек",
  pluginDescription: "Загрузить аудио из Cloudinary",
  isInlineable: true,
  titleLabel: "Заголовок",
  subtitleLabel: "Подзаголовок",
};

export const defaultSettings: AudioSettings = {
  Renderer: () => <>Renderer; for this plugin was not provided </>,
  Controls: () => <> Controls for this plugin were not provided</>,
  translations: defaultTranslations,
  icon: <PlayArrow />,
};
