import { useMutation } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { GET_COUNTRIES, UPDATE_COUNTRY } from "../../../../graphql";
import {
  UpdateCountry,
  UpdateCountryVariables,
} from "../../../../graphql/mutations/country/updateCountry/__generated__/UpdateCountry";
import {
  GetCountriesVariables,
  GetCountries_getCountries,
} from "../../../../graphql/queries/country/getCountries/__generated__/GetCountries";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, Input, MenuItem, Select,
  Typography,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import { MyTextField } from "../../../../components";
import { Add, Remove } from "@material-ui/icons";
import {Regions} from "../../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../../utils";
import {MyMultipleSelect} from "../../../../components/inputs/MyMultipleSelect";

interface EditCountryProps {
  open: boolean;
  onClose: () => void;
  country: GetCountries_getCountries | null;
  filter: GetCountriesVariables;
}

export const EditCountry: React.FC<EditCountryProps> = ({
  open,
  onClose,
  country,
  filter,
}) => {
  const { t } = useTranslation();
  const [update, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateCountry,
    UpdateCountryVariables
  >(UPDATE_COUNTRY, {
    onCompleted: (res) => {
      if (res.updateCountry) {
        onClose();
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COUNTRIES,
        variables: filter,
      },
    ],
  });

  interface FormValues {
    abbreviations: string[];
    cities: string[];
    engTrans: string;
    rusTrans: string;
    factor: number;
    regions:Regions[];
  }

  const initialValues: FormValues = {
    abbreviations: country?.abbreviations ?? [],
    cities: country?.cities ?? [],
    engTrans: country?.engTrans ?? "",
    rusTrans: country?.rusTrans ?? "",
    factor: country?.factor ?? 1,
    regions: country?.regions ?? [],
  };

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event:any) => {
    setPersonName(event.target.value);
  };

  const validationSchema = Yup.object({
    engTrans: Yup.string().required(t("Required")),
    rusTrans: Yup.string().required(t("Required")),
    factor: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
  });

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{`Редактировать ${country?.country}`}</DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          if (!country) return;
          await update({
            variables: {
              input: {
                id: country!.id,
                ...(values.rusTrans !== country!.rusTrans && {
                  rusTrans: values.rusTrans,
                }),
                ...(values.engTrans !== country!.engTrans && {
                  engTrans: values.engTrans,
                }),
                ...(values.factor !== country.factor && {
                  factor: values.factor,
                }),
                cities: values.cities,
                abbreviations: values.abbreviations,
                regions:values.regions
              },
            },
          });

          setSubmitting(false);
        }}
      >
        {({ dirty, submitForm, values, setFieldValue }) => (
          <Form>
            <>
              <DialogContent>
                <Field
                  component={MyTextField}
                  fullWidth
                  label={"RUS_trans"}
                  name="rusTrans"
                />
                <Box height="40px" />

                <Field
                  component={MyTextField}
                  fullWidth
                  label={"ENG_trans"}
                  name="engTrans"
                />
                <Box height="40px" />

                <Field
                  onChange={(e:string[])=> {
                    setFieldValue("regions", e)
                  }}
                  value={values.regions}
                  options={convertEnumToArray(Regions)}
                  component={MyMultipleSelect}
                  fullWidth
                  label={"Region"}
                />
                <Box height="40px" />

                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("salaryFactor")}
                  name="factor"
                  type="number"
                />

                <Box height="40px" />

                <Typography style={{ marginBottom: "10px" }}>
                  {t("abbreviations")}
                </Typography>

                <FieldArray
                  name="abbreviations"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.abbreviations &&
                      values.abbreviations.length > 0 ? (
                        values.abbreviations.map((_name, index) => (
                          <Box
                            key={index}
                            display={"flex"}
                            marginBottom={"20px"}
                          >
                            <Field
                              component={MyTextField}
                              fullWidth
                              name={`abbreviations.${index}`}
                            />
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Remove />
                            </IconButton>
                            <IconButton
                              onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        ))
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {t("Add abbreviation")}
                        </Button>
                      )}
                    </Box>
                  )}
                />
                <Box height="40px" />

                <Typography style={{ marginBottom: "10px" }}>
                  {t("Cities")}
                </Typography>

                <FieldArray
                  name="cities"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.cities && values.cities.length > 0 ? (
                        values.cities.map((_name, index) => (
                          <Box
                            key={index}
                            display={"flex"}
                            marginBottom={"20px"}
                          >
                            <Field
                              component={MyTextField}
                              fullWidth
                              name={`cities.${index}`}
                            />
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Remove />
                            </IconButton>
                            <IconButton
                              onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        ))
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {t("Add city")}
                        </Button>
                      )}
                    </Box>
                  )}
                />
                <Box height="40px" />
              </DialogContent>
              <DialogActions>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <Box display="flex" justifyContent={"center"}>
                    <Button onClick={onClose}>{t("Close")}</Button>
                    <Button disabled={updateLoading} onClick={submitForm}>
                      {t("Save")}
                      {Boolean(updateLoading) && (
                        <CircularProgress style={{ marginLeft: "10px" }} />
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
