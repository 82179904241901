import { CellPlugin, lazyLoad } from "@react-page/editor";
import createPlugin from "./createPlugin";

import AudioHtmlRenderer from "./Renderer/AudioHtmlRenderer";
import { AudioSettings } from "./types/settings";
import { AudioState } from "./types/state";

const AudioControls = lazyLoad(() => import("./Controls/AudioControls"));

const audioPlugin: (
  settings?: Partial<AudioSettings>
) => CellPlugin<AudioState> = (settings) =>
  createPlugin({
    Renderer: AudioHtmlRenderer,
    Controls: AudioControls,
    ...settings,
  });

export { audioPlugin };
