import { useMutation } from "@apollo/client";
import { DELETE_ARTICLE_CATEGORY } from "../../graphql";
import {
  DeleteArticleCategory,
  DeleteArticleCategoryVariables,
} from "../../graphql/mutations/categories/DeleteArticleCategory/__generated__/DeleteArticleCategory";
import { GetArticleCategories_getArticleCategories as ICategory } from "../../graphql/queries/article/GetArticleCategories/__generated__/GetArticleCategories";

export const useDeleteArticleCategory = (categoryToDelete: ICategory) => {
  const [deleteArticleCategory, { loading, error, data }] = useMutation<
    DeleteArticleCategory,
    DeleteArticleCategoryVariables
  >(DELETE_ARTICLE_CATEGORY, {
    onError: (err) => console.error(err),
    update: (cache) => {
      cache.modify({
        fields: {
          getArticleCategories(existing = [], { readField }) {
            return (<any[]>existing).filter(
              (ref) =>
                categoryToDelete.category.id !== readField("id", ref.category)
            );
          },
        },
      });
    },
  });

  return {
    deleteArticleCategory,
    loading,
    error,
    success: !!data?.deleteArticleCategory,
  };
};
