import {gql} from "@apollo/client";
import {LearningPathFragment} from "../../../fragments";

export const GET_LEARNING_PATH_BY_SLUG = gql`
    query GetLearningPathBySlug($slug: String!) {
        getLearningPathBySlug(slug: $slug) {
            fullAccess,
            path {
                ...LearningPath
            }
        }
    }
    ${LearningPathFragment}
`;
