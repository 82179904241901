import {
  Box,
  colors,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { MonetizationOn } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { EnumCard } from "../../../components";
import { useUsersStyles } from "../styles";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface ViewPreferencesProps {
  user: Me_me;
}

export const ViewPreferences: React.FC<ViewPreferencesProps> = ({ user }) => {
  const classes = useUsersStyles();
  const { t } = useTranslation();

  return (
    <>
      <Paper className={classes.info__paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textSecondary" variant="h6">
            {t("Preferences")}
          </Typography>

          {user.customerInfo?.isFellow && (
            <MonetizationOn
              fontSize="large"
              style={{ color: colors.green["500"] }}
            />
          )}
        </Box>

        <Divider style={{ marginBottom: 20 }} />

        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <EnumCard
              values={user.customerInfo!.jobPreference?.jobCategories ?? []}
              title={t("Job Categories")}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <EnumCard
              values={user.customerInfo!.jobPreference?.jobLocations ?? []}
              title={t("Job Location")}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
