import {
  TableContainer,
  TableCell,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { PaginationOptionsArgs } from "../../../graphql/globalTypes";
import { HeadCell, SortOrder } from "../../../lib";
import { Empty } from "../../skeletons";
import { MyTableHead } from "./MyTableHead";
import { MyTableToolbar } from "./MyTableToolbar";
import { useTableStyle } from "../styles";
import {Box} from "@mui/system";
import {AtomProgress} from "../../loaders";

interface MyTableProps {
  selected?: string[];
  loading: boolean;
  hideCheck?: boolean;
  total?: number;
  sortFields: string[];
  setSelected?: (s: string[]) => void;
  headCells: HeadCell[];
  values: any[];
  toolbarRightButton?: JSX.Element;
  toolbarLeft?: JSX.Element;
  bulkActions: JSX.Element;
  paginationOptions?: PaginationOptionsArgs;
  setPaginationOptions?: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
  colorCode?: {
    color: string;
    colorIf: (v: any) => boolean;
  };
  noFoundMessage?:{
    emoji:string
    title:string
  }
}

export const MyTable: React.FC<MyTableProps> = ({
  selected,
  setSelected,
  headCells,
  hideCheck,
  sortFields,
  values,
  toolbarRightButton,
  paginationOptions,
  setPaginationOptions,
  bulkActions,
  total,
  loading,
  toolbarLeft,
  colorCode,
  noFoundMessage,
}) => {
  const classes = useTableStyle();
  const { t } = useTranslation();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPaginationOptions!((prev) => ({
      ...prev,
      skip: paginationOptions!.limit! * newPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const limit = parseInt(event.target.value, 10);
    setPaginationOptions!((prev) => ({ ...prev, skip: 0, limit }));
  };

  const isSelected = (name: string) =>
    !selected ? false : selected.indexOf(name) !== -1;

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    field: string
  ) => {
    const isAsc =
      paginationOptions!.sort!.field === field &&
      paginationOptions!.sort!.sortBy === "asc";
    setPaginationOptions!((prev) => ({
      ...prev,
      sort: {
        field,
        sortBy: isAsc ? "desc" : "asc",
      },
    }));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelected) return;

    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, name: string) => {
    if (!selected || !setSelected) return;

    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <>
      <MyTableToolbar
        bulkActions={bulkActions}
        numSelected={selected ? selected.length : 0}
        rightButton={toolbarRightButton}
        left={toolbarLeft}
      />

      <Paper className={classes.paper}>
        <TableContainer style={{ maxHeight: "65vh" }}>
          <Table className={classes.table} stickyHeader>
            <MyTableHead
              numSelected={selected ? selected.length : 0}
              order={
                paginationOptions
                  ? (paginationOptions.sort!.sortBy as SortOrder)
                  : "desc"
              }
              orderBy={
                paginationOptions ? paginationOptions.sort!.field : "updated_at"
              }
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={values.length}
              headCells={headCells}
              sortFields={sortFields}
              hideCheck={hideCheck}
            />
            <TableBody>
              {values.map((value) => {
                const isItemSelected = isSelected(value.id as string);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, value.id as string)}
                    role="checkbox"
                    tabIndex={-1}
                    key={value.id}
                    selected={isItemSelected}
                    {...(colorCode && colorCode.colorIf(value)
                      ? {
                          style: {
                            background: colorCode.color,
                          },
                        }
                      : {})}
                  >
                    {!hideCheck && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                    )}

                    {headCells.map((headCell, index) => (
                      <TableCell width={headCell.width ?? ""} key={index} align={headCell.align}>
                        {value[headCell.label]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && values.length === 0 && (
          <Empty
            emoji={noFoundMessage?.emoji ?? "😱"}
            title={t(noFoundMessage?.title ?? "No Articles Found")}
          />
        )}
        {loading && <Box display="flex" justifyContent="center" padding="30px">
          <AtomProgress relative/>
        </Box>}

        {paginationOptions && total !== undefined && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={paginationOptions.limit!}
            page={paginationOptions.skip! / paginationOptions.limit!}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
          />
        )}
      </Paper>
    </>
  );
};
