import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { enumsVar } from "../../apollo";
import { FETCH_ENUMS } from "../../graphql";
import { FetchEnums } from "../../graphql/queries/jobs/FetchEnums/__generated__/FetchEnums";

export const useFetchEnums = () => {
  const { data, loading, error } = useQuery<FetchEnums>(FETCH_ENUMS, {
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (data) {
      enumsVar(data.fetchEnums);
    }
  }, [data]);

  return { loading, error };
};
