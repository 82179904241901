import {useLazyQuery} from "@apollo/client";
import {
  GetPromoterSubscriptionList,
  GetPromoterSubscriptionListVariables
} from "../../graphql/queries/promoters/getPromoterSubscriptionList/__generated__/GetPromoterSubscriptionList";
import {
  GET_PROMOTER_SUBSCRIPTION_LIST
} from "../../graphql/queries/promoters/getPromoterSubscriptionList";

export const useGetPromoterSubscriptionList = () => {
  const [getPromoterSubscriptionList, {data, loading, error}] =
    useLazyQuery<GetPromoterSubscriptionList, GetPromoterSubscriptionListVariables>(
      GET_PROMOTER_SUBSCRIPTION_LIST,
      {
        onError: (err) => console.error(err),
      }
    );

  return {getPromoterSubscriptionList, data, loading, error};
};