import { gql } from "@apollo/client";
import { ReviewFragment } from "../../../fragments";

export const GET_REVIEWS = gql`
  query GetReviews {
    getReviews {
      ...Review
    }
  }
  ${ReviewFragment}
`;
