import {gql} from "@apollo/client";
import {IncomeFragment, MetaFragment, PromoterUserFragment} from "../../../fragments";

export const GET_PROMOTERS_LIST = gql`
    query GetPromotersList(
        $query: PromotersListFilter!
        $paginationOptions: PaginationOptionsArgs!
    ) {
        getPromotersList(query: $query, paginationOptions: $paginationOptions) {
            promoters{
                accumulatedIncome{
                    ...Income
                }
                projectedIncome{
                    ...Income
                }
                payoutCurrency
                promoter{
                    ...PromoterUser
                }
            }
            meta {
                ...Meta
            }
        }
    }
    ${PromoterUserFragment}
    ${MetaFragment}
    ${IncomeFragment}
`;
