import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const CREATE_PROMOTER = gql`
    mutation CreatePromoter($input: CreatePromoterInput!) {
        createPromoter(input: $input) {
            error {
                name
                message
            }
            user {
                ...User
            }
        }
    }
    ${UserFragment}
`;
