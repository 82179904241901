import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment-timezone";
import React from "react";
import { useInputStyles } from "./styles";

interface MyDatePickerProps {
  value: Date;
  onChange: (e: MaterialUiPickersDate) => void;
  label: string;
  rootStyle?: React.CSSProperties;
  disablePast?: boolean;
  disableFuture?: boolean;
}

export const MyDatePicker: React.FC<MyDatePickerProps> = ({
  value,
  onChange,
  label,
  rootStyle,
  disablePast,
  disableFuture,
}) => {
  const classes = useInputStyles();

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={moment.locale("ru")}
    >
      <KeyboardDatePicker
        label={label}
        disablePast={disablePast}
        disableFuture={disableFuture}
        value={value}
        onChange={onChange}
        clearable
        format="DD/MM/yyyy"
        style={{ height: "100%" }}
        className={classes.datepicker}
      />
    </MuiPickersUtilsProvider>
  );
};
