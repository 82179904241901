import { gql } from "@apollo/client";
import {ProductFragment} from "../../../fragments";

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($input: UpdateProductInput!) {
       updateProduct(input: $input) {
            ...Product
        }
    }
    ${ProductFragment}
`;