import type { CellPluginComponentProps } from "@react-page/editor";
import { lazyLoad } from "@react-page/editor";
import React from "react";
import { CMSUploadButton } from "../../../../buttons";
import type { ImageState } from "../types/state";
import { iconStyle } from "./../common/styles";

const ImageIcon = lazyLoad(() => import("@material-ui/icons/Landscape"));

const ImageHtmlRenderer: React.FC<CellPluginComponentProps<ImageState>> = (
  props
) => {
  const { data } = props;
  const src = data?.src;
  const openInNewWindow = data?.openInNewWindow;
  const image = (
    <img className="react-page-plugins-content-image" alt="" src={src} />
  );

  return (
    <>
      <div
        style={{
          display: props.isEditMode ? "flex" : "none",
          zIndex: 100,
          position: "absolute",
        }}
      >
        <CMSUploadButton
          resource_type="image"
          onInsert={(url) =>
            props.onChange({
              src: url,
            })
          }
        />
      </div>
      {src ? (
        <div>
          {data?.href ? (
            <a
              onClick={props.isEditMode ? (e) => e.preventDefault() : undefined}
              href={data?.href}
              target={openInNewWindow ? "_blank" : undefined}
              rel={openInNewWindow ? "noreferrer noopener" : undefined}
            >
              {image}
            </a>
          ) : (
            image
          )}
        </div>
      ) : (
        <div>
          <div className="react-page-plugins-content-image-placeholder">
            <ImageIcon style={iconStyle} />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageHtmlRenderer;
