import {
  Avatar,
  Box,
  Checkbox,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Edit, SearchOutlined } from "@material-ui/icons";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FetchEnumsWithStats_fetchEnumsWithStats_employmentTypes as IEmpType,
  FetchEnumsWithStats_fetchEnumsWithStats_jobCategories as IJobCategory,
  FetchEnumsWithStats_fetchEnumsWithStats_seniorities as ISeniority,
  FetchEnumsWithStats_fetchEnumsWithStats_jobCategories_enum as IEnum,
} from "../../graphql/queries/jobs/GetEnumsWithStats/__generated__/FetchEnumsWithStats";
import { EnumRepoName } from "../../lib";
import { capitalize } from "../../utils";
import { BulkActions } from "../buttons";
import { EditEnum, BulkEnumAction } from "../dialogs";
import { useInputStyles } from "./styles";

interface EnumListProps {
  values: IJobCategory[] | IEmpType[] | ISeniority[];
  title: string;
  withSearch?: boolean;
  withFactor?: boolean;
  withLogo?: boolean;
  repoName: EnumRepoName;
}

export const EnumList: React.FC<EnumListProps> = ({
  values,
  title,
  withLogo,
  withSearch,
  repoName,
  withFactor,
}) => {
  const classes = useInputStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bulkDialogOpen, setBulkDialogOpen] = useState(false);
  const [currentEnum, setCurrentEnum] = useState<IEnum | null>(null);
  const [searchValues, setSearchValues] = useState<IJobCategory[] | undefined>(
    withSearch ? values : undefined
  );
  const [currentAction, setCurrentAction] = useState<"Replace" | "Delete" | "">(
    ""
  );

  useEffect(() => {
    if (values) setSearchValues(values);
  }, [values]);

  React.useEffect(() => {
    if (!dialogOpen) setCurrentEnum(null);
  }, [dialogOpen]);

  const handleSearchChange = React.useMemo(
    () =>
      debounce((value) => {
        if (value === "") {
          setSearchValues(values);
        } else {
          setSearchValues(values.filter((v) => v.enum.name.includes(value)));
        }
      }, 220),
    [searchValues]
  );

  React.useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleEdit = (value: IEnum) => () => {
    setCurrentEnum(value);
    setDialogOpen(true);
  };

  const applyAction = (option: string) => {
    setCurrentAction(option as any);
    setBulkDialogOpen(true);
  };

  const handleClose = (type: "bulk" | "edit") => {
    if (type === "edit") setDialogOpen(false);
    else setBulkDialogOpen(false);
  };

  return (
    <>
      <Paper className={classes.enumList__root}>
        {withSearch && (
          <Box>
            <TextField
              variant="standard"
              fullWidth
              onChange={(e) => handleSearchChange(e.target.value)}
              style={{
                boxShadow: " 0px 1px 7px -6px #000",
                borderRadius: "11px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>

        <Box maxHeight={"450px"} overflow="scroll">
          <List>
            {(withSearch ? searchValues! : values).map((v) => (
              <ListItem
                key={v.enum.id}
                button
                dense
                onClick={handleToggle(v.enum.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(v.enum.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                {withLogo && (
                  <ListItemAvatar>
                    <Avatar alt={v.enum.name} src={v.enum.logo?.url} />
                  </ListItemAvatar>
                )}

                <ListItemText
                  primary={
                    <Box>
                      <Typography variant="body1">
                        {capitalize(v.enum.name)}
                      </Typography>
                      {!!v.enum.factor && (
                        <Typography variant="body2">
                          {`Factor: ${v.enum.factor}`}
                        </Typography>
                      )}
                    </Box>
                  }
                  secondary={`${t("Jobs")}: ${v.jobCount} | ${t(
                    "Customers"
                  )}: ${v.userCount}`}
                />
                <ListItemSecondaryAction>
                  <IconButton size="small" onClick={handleEdit(v.enum)}>
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          padding={1}
        >
          {checked.length ? (
            <>
              <BulkActions
                onApply={(option) => applyAction(option)}
                options={bulkOptions}
              />
            </>
          ) : (
            <Fab color="primary" onClick={() => setDialogOpen(true)}>
              <Add />
            </Fab>
          )}
        </Box>
      </Paper>

      <EditEnum
        currentEnum={currentEnum}
        onClose={() => handleClose("edit")}
        open={dialogOpen}
        withLogo={withLogo}
        repoName={repoName}
        withFactor={withFactor}
      />

      <BulkEnumAction
        onClose={() => handleClose("bulk")}
        open={bulkDialogOpen}
        repoName={repoName}
        action={currentAction}
        ids={checked}
        setChecked={setChecked}
      />
    </>
  );
};

const bulkOptions = ["Replace", "Delete"];
