import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION_BY_ID = gql`
  query GetSubscriptionById($id: String!) {
    getSubscriptionById(id: $id) {
      id
      planId
      userId
      user {
        id
        email
        firstName
        lastName
      }
      status
      lastTimeAmount {
        currency
        value
      }
      startTime
      lastTimeCharge
      nextTimeCharge
      statusUpdateTime
      plan {
        id
        title
        description
        features
        billingCycle
        status
        price {
          value
          currency
        }
        taxData {
          vatCode
          paymentSubject
          paymentMethod
        }
      }
      discount {
        percentage
        numberOfCharges
        coupon {
          couponCode
          countUsers
          countTimes
          discountPercentage
        }
      }
      transactions {
        yooId
        yooStatus
        price {
          currency
          value
        }
        capturedAt
        refunds {
          yooId
          yooStatus
          amount {
            currency
            value
          }
          capturedAt
        }
      }
    }
  }
`;
