import {Button, Dialog, DialogContent, IconButton, Link,} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {NotificationToast} from "../../../components";
import {useMutation} from "@apollo/client";
import {
  GetTrackerItems_getTrackerItems_trackerItems
} from "../../../graphql/queries/jobApplicationTracker/getTrackerItems/__generated__/GetTrackerItems";
import {
  DeleteTrackerItem,
  DeleteTrackerItemVariables
} from "../../../graphql/mutations/jobApplicationTracker/deleteTrackerItem/__generated__/DeleteTrackerItem";
import {
  DELETE_TRACKER_ITEM
} from "../../../graphql/mutations/jobApplicationTracker/deleteTrackerItem";
import {
  GET_TRACKER_ITEMS
} from "../../../graphql/queries/jobApplicationTracker/getTrackerItems";
import {GetTrackerItemsInput, PaginationOptionsArgs} from "../../../graphql/globalTypes";

interface DeleteItemDialogProps {
  item: GetTrackerItems_getTrackerItems_trackerItems;
  filterParams: GetTrackerItemsInput;
  paginationOptions: PaginationOptionsArgs;
}

export const DeleteItemDialog: React.FC<DeleteItemDialogProps> = ({item, paginationOptions, filterParams}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const [deleteItem, {loading, error, data}] = useMutation<DeleteTrackerItem,
    DeleteTrackerItemVariables>(DELETE_TRACKER_ITEM, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_TRACKER_ITEMS,
        variables:{
          paginationOptions,
          input:filterParams
        }
      },
    ],
  });

  const handleDelete = async () => {
    await deleteItem({
      variables: {
        id:item.id
      }
    })
  }
  return (
    <>
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleOpen()
        }}
      >
        {t("Delete")}
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
            <Box textAlign="center">
              <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
            </Box>
            <Box textAlign="center"
                 fontWeight="600"
                 fontSize="16px">
              {t("Do you really want to delete VAR?", {name: item.title})}
            </Box>
          </DialogContent>
            <Box display="flex" margin="20px" gap="30px" justifyContent="center">
              <Button disabled={loading} onClick={handleClose} variant="contained"
                      color="primary">
                {t("Cancel")}
              </Button>
              <Button disabled={loading} onClick={handleDelete} variant="contained"
                      autoFocus>
                {t("Delete")}
              </Button>
            </Box>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};