import React from "react";
import { useLoaderStyles } from "./styles";

interface AtomProgressProps {
  relative?: boolean;
}

export const AtomProgress: React.FC<AtomProgressProps> = ({ relative }) => {
  const classes = useLoaderStyles();

  return (
    <div
      className={
        relative
          ? classes.progress
          : `${classes.progress} ${classes.progress_absolute}`
      }
    >
      <div className={`${classes.inner} one`} />
      <div className={`${classes.inner} two`} />
      <div className={`${classes.inner} three`} />
    </div>
  );
};
