import { useMutation } from "@apollo/client";
import {
  SEND_PASSWORD_RESET_LINK,
  SEND_VERIFICATION_LINK,
} from "../../graphql";
import {
  SendPasswordResetLink,
  SendPasswordResetLinkVariables,
} from "../../graphql/mutations/users/SendResetPasswordLink/__generated__/SendPasswordResetLink";
import {
  SendVerificationLink,
  SendVerificationLinkVariables,
} from "../../graphql/mutations/users/SendVerificationLink/__generated__/SendVerificationLink";

export const useSendLinkByEmail = (a: string) => {
  const [reset, { error: resetError, loading: resetLoading }] = useMutation<
    SendPasswordResetLink,
    SendPasswordResetLinkVariables
  >(SEND_PASSWORD_RESET_LINK, {
    onError: (err) => console.error(err),
  });

  const [verification, { error, loading }] = useMutation<
    SendVerificationLink,
    SendVerificationLinkVariables
  >(SEND_VERIFICATION_LINK, {
    onError: (err) => console.error(err),
  });

  return {
    send: a === "sendVerificationLink" ? verification : reset,
    error: a === "sendVerificationLink" ? error : resetError,
    loading: a === "sendVerificationLink" ? loading : resetLoading,
  };
};
