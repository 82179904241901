import { useQuery } from "@apollo/client";
import { GET_COUNTRIES } from "../../graphql";
import {
  GetCountries,
  GetCountriesVariables,
} from "../../graphql/queries/country/getCountries/__generated__/GetCountries";

export const useGetAllCountries = () => {
  const { data, loading, error } = useQuery<
    GetCountries,
    GetCountriesVariables
  >(GET_COUNTRIES);

  return {
    countries: data?.getCountries ?? [],
    loading,
    error,
  };
};
