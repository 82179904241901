import {useQuery} from "@apollo/client";
import {
  GetExperts,
  GetExpertsVariables
} from "../../../graphql/queries/experts/getExperts/__generated__/GetExperts";
import {GET_EXPERTS} from "../../../graphql/queries/experts/getExperts";

export const useGetExperts = (variables: GetExpertsVariables) => {
  const { data, loading, error } = useQuery<
    GetExperts,
    GetExpertsVariables
    >(GET_EXPERTS, {
    onError: (err) => console.error(err),
    variables,
  });

  return { data, loading, error };
};