import { gql } from "@apollo/client";
import {BannerFragment} from "../../../fragments";

export const CREATE_BANNER = gql`
    mutation CreateBanner($input: CreateBannerInput!) {
        createBanner(input: $input) {
            banner {
                ...Banner
            }
            error {
                name
                message
            }
        }
    }
    ${BannerFragment}
`;
