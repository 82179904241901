import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxList, NotificationToast } from "../../../components";
import {
  CreateArticleInput,
  UpdateArticleInput,
} from "../../../graphql/globalTypes";
import { useGetArticleCategories } from "../../../hooks";
import { SimpleObject } from "../../../lib";

interface EditArticleCategoriesProps {
  input: CreateArticleInput | UpdateArticleInput;
  setInput: React.Dispatch<
    React.SetStateAction<CreateArticleInput | UpdateArticleInput>
  >;
}

export const EditArticleCategories: React.FC<EditArticleCategoriesProps> = ({
  input,
  setInput,
}) => {
  const { t } = useTranslation();
  const {
    loading: catLoading,
    categories,
    error: catError,
  } = useGetArticleCategories();

  const mapInputCategories = useMemo(() => {
    if (!categories || !input.categoriesIds || !input.categoriesIds.length)
      return [];
    return input.categoriesIds.map((id) => {
      const c = categories.find((cat) => cat.category.id === id);
      return {
        id: c!.category.id,
        name: c!.category.name,
      };
    });
  }, [input.categoriesIds, categories]);

  const mapCategoriesOptions = useMemo(() => {
    if (!categories) return [];

    return categories.map((c) => ({
      id: c.category.id,
      name: c.category.name,
    }));
  }, [categories]);

  const popCategory = useCallback((option: SimpleObject) => {
    setInput((prev) => ({
      ...prev,
      categoriesIds: prev.categoriesIds!.filter((id) => id !== option.id),
    }));
  }, []);

  const pushCategory = useCallback((option: SimpleObject) => {
    setInput((prev) => ({
      ...prev,
      categoriesIds: prev.categoriesIds
        ? [...prev.categoriesIds!, option.id]
        : [option.id],
    }));
  }, []);

  return (
    <>
      <CheckboxList
        value={mapInputCategories}
        loading={catLoading}
        options={mapCategoriesOptions}
        popOption={popCategory}
        pushOption={pushCategory}
        title={t("Categories")}
      />

      {catError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
