import { gql } from "@apollo/client";

export const GET_COMPANIES_WITH_REFERRALS = gql`
  query GetCompaniesWithReferrals {
    getCompaniesWithReferrals {
      id
      name
    }
  }
`;
