import React, {useState} from 'react';
import {
  AppLanguage,
  CreatePartnerCompanyInput,
  FileInput,
  PartnerCompanyReviewInput,
  PartnerCompanyServiceInput,
  PublishStatus
} from "../../graphql/globalTypes";
import {languages} from "../../lib";
import {Field, FieldArray, Form, Formik, FormikErrors} from "formik";
import {
  FullscreenLoader,
  MyAutocomplete,
  MyTextField,
  NotificationToast,
  SelectButton
} from "../../components";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {Add, Remove, SaveOutlined as SaveIcon} from "@material-ui/icons";
import {useGetAllCountries, useSetHeadbarTitle} from "../../hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Fab, FormControlLabel,
  Grid,
  IconButton, Radio, RadioGroup,
  Typography
} from '@material-ui/core';
import {Box} from "@mui/system";
import {PictureInBase64Upload} from "../../components/inputs/PictureInBase64Upload";
import {convertEnumToArray} from "../../utils";
import {
  useGetPartnerCompanyCategories
} from "../../hooks/queries/partnerCompanies/useGetPartnerCompanyCategories";
import {
  CreatePartnerCompanyVariables
} from "../../graphql/mutations/partnerCompanies/createPartnerCompany/__generated__/CreatePartnerCompany";
import {UpdatePartnerCompanyTags} from "./components/UpdatePartnerCompanyTags";
import DeleteIcon from '@material-ui/icons/Delete';
import {useCreatePartnerCompany} from "../../hooks/mutations/useCreatePartnerCompany";
import {useHistory} from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {appLanguages} from "../../lib/constants";

export const CreatePartnerCompany = () => {
  const {t} = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Add Partner Company"));
  const {categories, loading: categoriesLoading} = useGetPartnerCompanyCategories()
  const {countries, loading: countriesLoading} = useGetAllCountries();
  const {create, loading, error} = useCreatePartnerCompany();
  const [input, setInput] = useState<CreatePartnerCompanyInput>({
    title: "",
    description: "",
    slug: "",
    subtitle: "",
    linkedId: "",
    youTube: "",
    website: "",
    telegram: "",
    twitter: "",
    languages: [],
    partnerCompanyCategories: [],
    features: [],
    services: [],
    reviews: [],
    picture: null,
    status: PublishStatus.DRAFT,
    locations: [],
    tags: [],
  });

  const handleSubmit = async (
    values: PartnerCompanyFormValues,
    setErrors: (errors: FormikErrors<PartnerCompanyFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const variables: CreatePartnerCompanyVariables = {
      input: {
        ...values,
        partnerCompanyCategories:
          categories?.filter(cat => values.partnerCompanyCategories.includes(cat?.name)).map(cat => cat?.id)!,
        locations: countries.filter(c => values.locations.includes(c.rusTrans!))
          .map(c => ({countryId: c.id})),
        tags: input.tags
      }
    };
    try {
      const res = await create({variables});
      if (res.data?.createPartnerCompany.error) {
        setErrors({
          [res.data.createPartnerCompany.error.name]: t(res.data.createPartnerCompany.error.message!),
        });
      } else if (res.data?.createPartnerCompany.partnerCompany) {
        history.push({
          pathname: `/partner-companies/edit/${res.data.createPartnerCompany.partnerCompany.id}`,
          state: {
            showCreateNotification: true
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues: PartnerCompanyFormValues = {
    title: input.title,
    subtitle: input.subtitle,
    description: input.description,
    status: PublishStatus.PUBLISHED,
    picture: input.picture,
    partnerCompanyCategories: input.partnerCompanyCategories,
    locations: [],
    languages: input.languages,
    features: [],
    services: [],
    reviews: [],
    slug: ""
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, t("Must have at least VAR characters", {count: 2}))
      .required(t("Required")),
    description: Yup.string()
      .min(2, t("Must have at least VAR characters", {count: 2}))
      .required(t("Required")),
    subtitle: Yup.string()
      .min(2, t("Must have at least VAR characters", {count: 2}))
      .required(t("Required")),
    slug: Yup.string()
      .min(2, t("Must have at least VAR characters", {count: 2}))
      .required(t("Required")),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({values, submitForm, setFieldValue,}) => (
          <Form>
            <Box margin="20px">
              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Title")}
                    placeholder={t("Title")}
                    name="title"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Short description")}
                    placeholder={t("Short description")}
                    name="subtitle"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={2}>
                  <SelectButton
                    rootStyle={{width: "100%"}}
                    label={t("status")}
                    options={convertEnumToArray(PublishStatus)}
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}/>
                </Grid>
              </Grid>
              <Divider style={{marginBottom: "20px"}}/>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {categoriesLoading ? (
                    <Typography>{t("Categories loading")}</Typography>
                  ) : (
                    <MyAutocomplete
                      multiple
                      options={categories?.map(cat => cat.name) ?? []}
                      getOptionLabel={(option) => option}
                      label={t("Partner Company directions")}
                      value={values.partnerCompanyCategories ?? []}
                      placeholder={t("Partner Company directions")}
                      setValue={(e: string[]) => {
                        setFieldValue("partnerCompanyCategories", e)
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Slug")}
                    placeholder={t("Slug")}
                    name="slug"/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Instagram")}
                    placeholder={t("Instagram example")}
                    name="website"/>
                </Grid>
              </Grid>

              <Divider style={{margin: "20px 0"}}/>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    multiline
                    rows={6}
                    label={t("description")}
                    placeholder={t("description")}
                    name="description"/>
                </Grid>
                <Grid item xs={6}>
                  <Box width="150px" height="150px">
                    <PictureInBase64Upload
                      pictureResolution="500*500"
                      src={values.picture?.source ?? ""}
                      setPicture={(e) => setFieldValue("picture", {source: e})}/>
                  </Box>
                </Grid>
              </Grid>

              <Divider style={{margin: "20px 0"}}/>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {countriesLoading ? (
                    <Typography>{t("Countries loading")}</Typography>
                  ) : (
                    <MyAutocomplete
                      multiple
                      options={countries?.map(c => c.rusTrans ? c.rusTrans : c.country) ?? []}
                      getOptionLabel={(option) => option}
                      label={t("Countries")}
                      value={values.locations ?? []}
                      placeholder={t("Countries")}
                      setValue={(e: string[]) => {
                        setFieldValue("locations", e)
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <MyAutocomplete
                    multiple
                    options={languages}
                    getOptionLabel={(option) => option}
                    label={t("Languages")}
                    value={values.languages ?? []}
                    placeholder={t("Languages")}
                    setValue={(e: string[]) => {
                      setFieldValue("languages", e)
                    }}
                  />
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("LinkedIn")}
                    placeholder={t("LinkedIn example")}
                    name="linkedId"
                    style={{marginTop: 20}}/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("VK")}
                    placeholder={t("VK example")}
                    name="twitter"
                    style={{marginTop: 20}}/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Telegram")}
                    placeholder={t("Telegram example")}
                    name="telegram"
                    style={{marginTop: 20}}/>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("YouTube")}
                    placeholder={t("YouTube example")}
                    name="youTube"
                    style={{marginTop: 20}}/>
                </Grid>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>
              <Grid item xs={12}>
                <UpdatePartnerCompanyTags input={input} setInput={setInput as any}/>
              </Grid>
              <Divider style={{margin: "20px 0"}}/>

              <Accordion
                elevation={3}
                style={{background:"#f6f7ff", borderRadius:"4px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h6">
                    {t("Services")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <FieldArray
                    name="services"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.services.map((service, index) => {
                          return (<Box
                            key={index}
                            marginBottom={"20px"}>
                            <Grid container spacing={4}>
                              <Grid item xs={4}>
                                <Grid item xs={12}>
                                  <Box width="300px" height="150px">
                                    <PictureInBase64Upload
                                      pictureResolution="600*300"
                                      src={values.services[index].picture?.source ?? ""}
                                      setPicture={(e) => setFieldValue(`services[${index}].picture`, {source: e})}/>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    style={{marginTop: "10px"}}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon/> {t("Delete service")}
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item xs={8} container spacing={2}>
                                <Grid item xs={6}><Field
                                  component={MyTextField}
                                  label={t("Title")}
                                  placeholder={t("Title")}
                                  fullWidth
                                  name={`services.${index}.title`}/></Grid>
                                <Grid item xs={6}>
                                  <Field
                                    label={t("Description")}
                                    placeholder={t("Description")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`services.${index}.description`}/>
                                </Grid>
                                <Grid item xs={4}>
                                  <Field
                                    label={t("price")}
                                    placeholder={t("price")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`services.${index}.price.value`}/>
                                </Grid>
                                <Grid item xs={4}>
                                  <SelectButton
                                    label={t("currency")}
                                    rootStyle={{width: "100%"}}
                                    options={["RUB", "USD"]}
                                    value={values?.services[index]?.price?.currency ?? ""}
                                    onChange={(e) => setFieldValue(`services[${index}].price.currency`, e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Field
                                    label={t("Discount percentage")}
                                    placeholder={t("Discount percentage")}
                                    type="number"
                                    component={MyTextField}
                                    fullWidth
                                    name={`services.${index}.discountPercentage`}/>
                                </Grid>
                                <Grid item xs={6}>
                                  <Field
                                    label={t("Affiliate Link")}
                                    placeholder={t("https://www.example.com")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`services.${index}.affiliateLink`}/>
                                </Grid>
                                <Grid item xs={6}>
                                  <Field
                                    label={t("PromoText")}
                                    placeholder={t("PromoText")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`services.${index}.promoText`}/>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{margin: "10px 0"}}/>
                          </Box>)
                        })
                        }
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push({picture: {source: ""}})}
                        >
                          {t("Add service")}
                        </Button>
                      </Box>
                    )}/>
                </AccordionDetails>
              </Accordion>

              <Divider style={{margin: "20px 0"}}/>

              <Accordion
                elevation={3}
                style={{background:"#f6f7ff", borderRadius:"4px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h6">
                    {t("Reviews")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <FieldArray
                    name="reviews"
                    render={(arrayHelpers) => (
                      <Box width="100%">
                        {values.reviews.map((review, index) => {
                          return (<Box
                            key={index}
                            marginBottom={"20px"}>
                            <Grid container spacing={4}>
                              <Grid item xs={4}>
                                <Grid item xs={12}>
                                  <Box width="150px" height="150px" margin="0 auto">
                                    <PictureInBase64Upload
                                      pictureResolution="100x100"
                                      src={values.reviews[index].picture?.source ?? ""}
                                      setPicture={(e) => setFieldValue(`reviews[${index}].picture`, {source: e})}/>
                                  </Box>
                                </Grid>
                                <Typography variant="body2" color="textSecondary">
                                  {t("Language")}
                                </Typography>
                                <RadioGroup row
                                            value={values.reviews[index].language}
                                            onChange={(event, value)=>setFieldValue(`reviews[${index}].language`, value)} >
                                  {convertEnumToArray(AppLanguage).map(lng=>
                                    <FormControlLabel value={lng}
                                                      control={<Radio />}
                                                      label={appLanguages[lng]}
                                                      key={lng} />)}
                                </RadioGroup>
                                <Grid item xs={12}>
                                  <Button
                                    style={{marginTop: "10px"}}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon/> {t("Delete review")}
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item xs={8} container spacing={2}>
                                <Grid item xs={6}><Field
                                  component={MyTextField}
                                  label={t("Title")}
                                  placeholder={t("Title")}
                                  fullWidth
                                  name={`reviews.${index}.title`}/></Grid>
                                <Grid item xs={6}>
                                  <Field
                                    label={t("UserName")}
                                    placeholder={t("UserName")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`reviews.${index}.userName`}/>
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    multiline
                                    rows={5}
                                    label={t("Text")}
                                    placeholder={t("Text")}
                                    component={MyTextField}
                                    fullWidth
                                    name={`reviews.${index}.text`}/>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{margin: "10px 0"}}/>
                          </Box>)
                        })
                        }
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push({picture: {source: ""}, language:AppLanguage.ru})}
                        >
                          {t("Add review")}
                        </Button>
                      </Box>
                    )}/>
                </AccordionDetails>
              </Accordion>

              <Box margin="10px 0">


              </Box>

              <Divider style={{margin: "20px 0"}}/>
              <Box margin="10px 0" width="50%">
                <Typography variant="h6">
                  {t("Features")}
                </Typography>
                <FieldArray
                  name="features"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.features && values.features.length > 0 ? (
                        values.features.map((_feature: string, index) => (
                          <Box
                            key={index}
                            display={"flex"}
                            marginBottom={"20px"}>
                            <Field
                              component={MyTextField}
                              fullWidth
                              name={`features.${index}`}/>
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}>
                              <Remove/>
                            </IconButton>
                            <IconButton
                              onClick={() => arrayHelpers.insert(index, "")}>
                              <Add/>
                            </IconButton>
                          </Box>
                        ))
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {t("Add a feature")}
                        </Button>
                      )}
                    </Box>
                  )}/>
              </Box>
              <Divider style={{margin: "20px 0"}}/>
              <Box textAlign="center">
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  style={{width: "30%"}}
                  id="bottomBtn"
                  onClick={() => submitForm()}
                  disabled={loading}
                >
                  {loading
                    ? <CircularProgress style={{color: "white"}} size={30}/>
                    : <><SaveIcon style={{marginRight: 10}}/>
                      {t("Save")}</>
                  }
                </Fab>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {loading && <FullscreenLoader transparent/>}
      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

export interface PartnerCompanyFormValues {
  title: string
  subtitle: string
  description: string
  picture: null | FileInput | undefined
  status: PublishStatus
  partnerCompanyCategories: string[]
  locations: string[]
  languages: string[]
  features: string[]
  services: PartnerCompanyServiceInput[]
  reviews: PartnerCompanyReviewInput[]
  slug: string
}
