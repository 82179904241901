import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    statscard__paper: {
      padding: theme.spacing(2),
      position: "relative",
    },
    enumcard__paper: {
      padding: theme.spacing(1),
      position: "relative",
    },
    statscard__paper__sticky: {
      maxHeight: "500px",
      overflow: "scroll",
      position: "relative",
      paddingTop: 0,
    },
    statscard__title: {
      boxShadow: "0px 2px 7px -7px #00000075",
    },
    statscard__title__sticky: {
      position: "sticky",
      top: 0,
      left: 0,
      zIndex: 50,
      background: "#fff",
      paddingTop: 10,
      paddingBottom: 10,
    },
    enumcard__chip: {
      background: theme.palette.background.default,
      color: theme.palette.text.secondary,
      margin: 5,
      flexDirection: "row",
      flexWrap: "wrap",
      height: "auto",
      "& .MuiChip-label": {
        whiteSpace: "break-spaces",
      },
    },
  })
);
