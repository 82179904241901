import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    sb_label: {
      position: "absolute",
      top: -2,
      zIndex: 1,
      left: "15px",
      fontSize: "10px",
    },
    sb_densed: {
      "& .MuiOutlinedInput-input": {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
  })
);
