import { useReactiveVar } from "@apollo/client";
import {
  Avatar,
  Box,
  colors,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { userVar } from "../../../apollo";
import { FullscreenLoader } from "../../../components";
import { Gender, UserRole } from "../../../lib";
import { useCommonStyle } from "../../../styles/commonStyles";
import { getAgeFromDate } from "../../../utils";
import { useUsersStyles } from "../styles";
import { EditUserSummary } from "./EditUserSummary";
import { GetUserById_getUserById as IUser } from "../../../graphql/queries/users/GetUserById/__generated__/GetUserById";
import { EditUserRole } from "./EditUserRole";
import {DeleteUserDialog} from "./DeleteUserDialog";
import {LockPluginUser} from "./LockPluginUser";

interface UserSummaryProps {
  user?: IUser;
  isMe?: boolean;
  setUser?: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const UserSummary: React.FC<UserSummaryProps> = ({
  user,
  isMe,
  setUser,
}) => {
  const commonClasses = useCommonStyle();
  const classes = useUsersStyles();
  const { t } = useTranslation();
  const authUser = useReactiveVar(userVar);

  if (!user || !authUser) {
    return <FullscreenLoader />;
  }

  const border =
    !user.gender || user.gender === "not specified"
      ? "unset"
      : (user.gender as Gender) === Gender.F
      ? `2px solid ${colors.pink["100"]}`
      : `2px solid ${colors.blue["100"]}`;

  return (
    <Box className={commonClasses.sectionWithSticky__sticky}>
      <Paper className={classes.summary__paper} style={{ border }}>
        <Avatar src={user.avatar?.url} className={classes.summary__avatar} />

        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="overline" className={classes.summary__overline}>
            {t(user.role)}
          </Typography>
          {Boolean(
            !isMe &&
              [UserRole.GLOBAL_ADMIN, UserRole.ADMIN].includes(
                authUser.role as UserRole
              )
          ) && <Box>
            <EditUserRole user={user} setUser={setUser!}/>
            <DeleteUserDialog user={user}/>
          </Box>}
        </Box>

        <Typography variant="h5">
          {`${user.firstName} ${user.lastName}`}
          {user.birthDate && (
            <span>
              , {getAgeFromDate(user.birthDate)} {t("years")}
            </span>
          )}
        </Typography>
        <LockPluginUser user={user}/>
        {user.customerInfo?.orderID && (
          <Box>
            <Typography variant="body2">
              Подписка: {t(user.customerInfo.subscriptionStatus!)}
            </Typography>
            <Typography variant="body2">
              ID: {user.customerInfo.subscriptionID}
            </Typography>
            <Box marginY={4} />
          </Box>
        )}

        <Box marginY={4} />

        <Box style={{ overflow: "scroll" }} maxHeight={220} marginBottom={5}>
          {user.summary && <Typography>{user.summary}</Typography>}
        </Box>

        {isMe && <EditUserSummary user={user} />}
      </Paper>
    </Box>
  );
};
