import {useMutation} from "@apollo/client";
import {DELETE_SUBSCRIPTION_PLAN, GET_ADMIN_SUBSCRIPTION_PLANS} from "../../graphql";
import {
  DeleteSubscriptionPlan,
  DeleteSubscriptionPlanVariables
} from "../../graphql/mutations/subscriptions/DeleteSubscriptionPlan/__generated__/DeleteSubscriptionPlan";

export const useDeleteSubscriptionPlan = () => {
  const [deleteSubscriptionPlan, { data, loading, error }] = useMutation<
    DeleteSubscriptionPlan,
    DeleteSubscriptionPlanVariables
    >(DELETE_SUBSCRIPTION_PLAN, {
    onError: (err) => console.log(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADMIN_SUBSCRIPTION_PLANS,
      },
    ],
  });

  return {
    deleteSubscriptionPlan,
    data,
    loading,
    error,
  };
};