import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Field, Form, Formik, FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  MyTextField,
  NotificationToast,
  PictureUpload,
} from "../../../components";
import { CREATE_ARTICLE_CATEGORY } from "../../../graphql";
import {
  CreateArticleCategory,
  CreateArticleCategoryVariables,
} from "../../../graphql/mutations/categories/CreateArticleCategory/__generated__/CreateArticleCategory";
import { GET_ARTICLE_CATEGORIES } from "../../../graphql/queries/article";
import { GetArticleCategories_getArticleCategories as ICategory } from "../../../graphql/queries/article/GetArticleCategories/__generated__/GetArticleCategories";
import { useUpdateArticleCategory } from "../../../hooks";
import { FileType, IPicUpload } from "../../../lib";
import { getFileNameFromPublicId } from "../../../utils";

interface EditArticleCategoryProps {
  category: ICategory | null;
  open: boolean;
  onClose: () => void;
}

export const EditArticleCategory: React.FC<EditArticleCategoryProps> = ({
  category,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const [enableValidation, setEnableValidation] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { update, uLoading } = useUpdateArticleCategory();

  const [create, { loading }] = useMutation<
    CreateArticleCategory,
    CreateArticleCategoryVariables
  >(CREATE_ARTICLE_CATEGORY, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ARTICLE_CATEGORIES,
      },
    ],
  });

  useEffect(() => {
    if (!open) {
      setPicUpload(null);
      setServerError(null);
    }
  }, [open]);

  const initialValues: FormValues = {
    name: category?.category.name ?? "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Required")),
  });

  async function handleSubmit(
    name: FormValues["name"],
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    let picture: any | null;
    if (picUpload) {
      picture = {
        publicId: picUpload.public_id,
        url: picUpload.url,
        filename: getFileNameFromPublicId(picUpload.public_id),
        type: FileType.IMAGE,
      };
    }

    try {
      if (!category) {
        const res = await create({
          variables: { input: { name, ...(picture ? { picture } : {}) } },
        });
        if (res.data?.createArticleCategory.error) {
          setErrors({ name: t("Title already exists") });
        } else {
          onClose();
        }
      } else {
        const res = await update({
          variables: {
            input: {
              id: category.category.id,
              ...(name !== category.category.name ? { name } : {}),
              ...(picture ? { picture } : {}),
            },
          },
        });
        if (res.data?.updateArticleCategory.error) {
          setErrors({ name: t("Title already exists") });
        } else {
          onClose();
        }
      }
    } catch (err) {
      setServerError(err as any);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {category ? t("Edit Category") : t("Add Category")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async ({ name }, { setErrors, setSubmitting }) =>
          await handleSubmit(name, setErrors, setSubmitting)
        }
      >
        {({ values, dirty, submitForm }) => (
          <Form>
            <>
              <DialogContent>
                <PictureUpload
                  imageUrl={category?.category.picture?.url}
                  picUpload={picUpload}
                  setPicUpload={setPicUpload}
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Title")}
                  name="name"
                  style={{ marginBottom: 20, marginTop: 30 }}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={loading || uLoading} onClick={onClose}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={
                    ((!values.name || !dirty) &&
                      (!picUpload || !values.name)) ||
                    loading ||
                    uLoading
                  }
                >
                  {t("Save")}
                  {(loading || uLoading) && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>

      {serverError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Dialog>
  );
};

interface FormValues {
  name: string;
}
