import { Box, Button, Grid } from "@material-ui/core";
import { KeyboardArrowRight as ToIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AtomProgress, NotificationToast, StatsCard } from "../../components";
import { FetchUserPreferencesInput } from "../../graphql/globalTypes";
import { FetchUserPreferences_fetchUserPreferences as IPreference } from "../../graphql/queries/users/FetchUserPreferences/__generated__/FetchUserPreferences";
import {
  useFetchUserPreferences,
  useGetUserGeography,
  useSetHeadbarTitle,
} from "../../hooks";
import { PreferencesFilterBar } from "./components";

export const UserPreferences: React.FC = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("User Preferences"));
  useGetUserGeography();
  const history = useHistory();
  const [filterParams, setFilterParams] = useState<FetchUserPreferencesInput>({
    countryId: undefined,
    isFellow: undefined,
    nationality: undefined,
    textSearch: undefined,
  });
  const { error, loading, data } = useFetchUserPreferences(filterParams);
  const [preference, setPreference] = useState<IPreference | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (preference) {
      setInitialLoading(false);
    }
  }, [preference]);

  useEffect(() => {
    if (data?.fetchUserPreferences) {
      setPreference(data.fetchUserPreferences);
    }
  }, [data]);

  const skeletonMarkup = (height: number | string = 200) => (
    <Skeleton
      variant="rect"
      animation="wave"
      style={{
        borderRadius: 5,
        display: "inline-block",
        width: "100%",
        height,
      }}
    />
  );

  return (
    <>
      <PreferencesFilterBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />

      <Box padding={3} marginTop={4}>
        {loading && (
          <Box
            position="absolute"
            marginTop="5px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <Box paddingX={2} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => history.push("/users")}
        >
          <ToIcon />
          {t("To users")}
        </Button>
      </Box>

      <Box marginY={2} />

      <Box marginX={"auto"} paddingX={2} maxWidth={"1400px"}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {initialLoading ? (
                  skeletonMarkup()
                ) : (
                  <StatsCard
                    title={t("Job Location")}
                    values={preference!.jobLocations}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            {initialLoading ? (
              skeletonMarkup("100%")
            ) : (
              <StatsCard
                title={t("Job Categories")}
                values={preference!.jobCategories}
                stickyHeader
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
