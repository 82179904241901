import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";

interface MyAutocompleteProps {
  error?:string | string[]
  options: any[];
  getOptionSelected?:(option:any, value:any)=>boolean;
  getOptionLabel: (option: any) => string;
  rootStyles?: React.CSSProperties;
  label: string;
  value: any;
  setValue: (v: any) => void;
  disabled?: boolean;
  multiple?: boolean;
  placeholder?: string;
}

export const MyAutocomplete: React.FC<MyAutocompleteProps> = ({
  options,
  getOptionLabel,
  rootStyles,
  label,
  value,
  setValue,
  disabled,
  multiple,
  placeholder,
  error,
  getOptionSelected
}) => {
  const { t } = useTranslation();

  const onChange = (_event: any, newValue: any | null) => {
    setValue(newValue);
  };

  return (
    <Autocomplete
      getOptionSelected={getOptionSelected}
      options={[t("Not specified"), ...options]}
      multiple={multiple}
      filterSelectedOptions={multiple}
      disabled={disabled}
      value={value}
      defaultValue={placeholder}
      getOptionDisabled={(option) => option === t("Not specified")}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      style={rootStyles}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
