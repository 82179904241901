import { useReactiveVar } from "@apollo/client";
import { Box, colors, Link, Typography } from "@material-ui/core";
import { MonetizationOn } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userVar } from "../../../apollo";
import { SimpleTable } from "../../../components/tables/SimpleTable";
import { PaginationOptionsArgs } from "../../../graphql/globalTypes";
import { GetUsers_getUsers_users as IUser } from "../../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import { HeadCell } from "../../../lib";

interface UsersTableProps {
  loading: boolean;
  users: IUser[];
  totalUsers: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  loading,
  users,
  totalUsers,
  paginationOptions,
  setPaginationOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authUser = useReactiveVar(userVar);

  const goToUser = (id: string) => {
    if (authUser?.id === id) {
      history.push("/profile");
    } else {
      history.push(`/users/${id}`);
    }
  };

  const mapUsersToRows = useMemo(() => {
    const arr = users.map((user) => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: (
        <Link
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={`mailto:${user.email}`}
        >
          {user.email}
        </Link>
      ),
      role: t(user.role),
      country: user.location?.countryObject
        ? user.location?.countryObject.rusTrans
        : "",
      nationality: user.nationality ?? "",
      languages: user.languages?.length
        ? user.languages.map((l) => (
            <Typography variant="body2" key={l}>
              {l}
            </Typography>
          ))
        : "",
      fellowship: user.customerInfo?.orderID ? (
        <>
          <MonetizationOn
            style={{
              color:
                user.customerInfo.subscriptionStatus !== "ACTIVE"
                  ? colors.grey["500"]
                  : colors.green["500"],
            }}
          />
          <Typography variant="body2">
            {t(user.customerInfo.subscriptionStatus!)}
          </Typography>
          <Typography variant="body2">
            {user.customerInfo.subscriptionID}
          </Typography>
        </>
      ) : (
        ""
      ),
    }));
    return arr;
  }, [users]);

  return (
    <>
      <Box width="100%">
        <SimpleTable
          loading={loading}
          total={totalUsers}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          headCells={headCells}
          values={mapUsersToRows}
          sortFields={[]}
          onRowClick={goToUser}
        />
      </Box>
    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "name",
    align: "left",
  },
  {
    label: "email",
    align: "left",
  },
  {
    label: "role",
    align: "left",
  },
  {
    label: "country",
    align: "center",
  },
  {
    label: "nationality",
    align: "center",
  },
  {
    label: "languages",
    align: "center",
  },
  {
    label: "fellowship",
    align: "center",
  },
];
