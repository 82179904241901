import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import {CloseOutlined, EditOutlined} from "@material-ui/icons";
import {Field, Form, Formik, FormikErrors} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {MyTextField, NotificationToast,} from "../../../components";
import {useUsersStyles} from "../../users/styles";
import {
  GetPromoterByAdmin_getPromoterByAdmin
} from "../../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";
import {
  useUpdatePromoterSettings
} from "../../../hooks/mutations/useUpdatePromoterSettings";

interface EditPersonalInfoProps {
  editable?: boolean;
  promoter: GetPromoterByAdmin_getPromoterByAdmin;
}

export const EditPromoterSettings: React.FC<EditPersonalInfoProps> =
  ({
     editable,
     promoter,
   }) => {
    const classes = useUsersStyles();
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState(true);
    const {
      updatePromoterSettings,
      loading,
      error
    } = useUpdatePromoterSettings(promoter.promoter.id)
    const [enableValidation, setEnableValidation] = useState(false);

    const initialValues: FormValues = {
      userId: promoter.promoter.id,
      subscriptionCommission:promoter.promoter.partnerSettings?.subscriptionCommission
         && promoter.promoter.partnerSettings?.subscriptionCommission * 100,
      productCommission:promoter.promoter.partnerSettings?.productCommission
        && promoter.promoter.partnerSettings?.productCommission * 100,
      couponSubscriptionDiscount: promoter.promoter.partnerSettings?.couponSubscriptionDiscount
        && promoter.promoter.partnerSettings?.couponSubscriptionDiscount * 100,
      couponProductDiscount: promoter.promoter.partnerSettings?.couponProductDiscount
        && promoter.promoter.partnerSettings?.couponProductDiscount * 100,
      username: promoter.promoter.partnerSettings?.username,
      couponCode: promoter.promoter.partnerSettings?.couponCode,
    };

    const toggleEdit = () => {
      setDisabled(!disabled);
    };

    const handleSubmit = async (
      values: FormValues,
      setSubmitting: (isSubmitting: boolean) => void,
      setErrors: (errors: FormikErrors<FormValues>) => void,
    ) => {
      try {
        const res = await updatePromoterSettings({
          variables: {
            input: {
              ...values,
              subscriptionCommission:values.subscriptionCommission && values.subscriptionCommission/100,
              productCommission:values.productCommission && values.productCommission/100,
              couponSubscriptionDiscount:values.couponSubscriptionDiscount && values.couponSubscriptionDiscount/100,
              couponProductDiscount:values.couponProductDiscount && values.couponProductDiscount/100,
            }
          }
        });
        if (res.data?.updatePromoterSettings.error?.name === "name") {
          setErrors({username: t("Username already exists")})
        }
        if (res.data?.updatePromoterSettings.error?.name === "coupon") {
          setErrors({couponCode: t("Coupon code already exists")})
        }
        if (!res.data?.updatePromoterSettings.error && res.data?.updatePromoterSettings.user) {
          setDisabled(true);
        }
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <>
        <Paper className={classes.info__paper}>

          <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange={enableValidation}
            validateOnBlur={enableValidation}
            onSubmit={async (values, {setSubmitting, setErrors}) =>
              handleSubmit(values, setSubmitting, setErrors)
            }
          >
            {({values, submitForm, handleReset}) => (
              <Form>
                <>
                  <Box display="flex" alignItems="center">
                    <Typography color="textSecondary" variant="h6">
                      {t("promoter settings")}
                    </Typography>

                    {editable && (
                      <IconButton
                        disabled={promoter.promoter.partnerSettings?.isDeactivated as boolean}
                        color="primary"
                        onClick={() => {
                          toggleEdit()
                          handleReset()
                        }}>
                        {disabled ? <EditOutlined/> : <CloseOutlined/>}
                      </IconButton>
                    )}
                  </Box>

                  <Grid
                    container
                    spacing={2}
                    style={{marginBottom: 20, marginTop: 20}}
                  >
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("Username")}
                        value={values.username}
                        name="username"
                        disabled={disabled}
                        placeholder={t("Username")}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("PromoCode")}
                        value={values.couponCode}
                        name="couponCode"
                        disabled={disabled}
                        placeholder={t("PromoCode")}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        type="number"
                        label={t("couponProductDiscount")}
                        value={values.couponProductDiscount}
                        name="couponProductDiscount"
                        disabled={disabled}
                        placeholder={t("couponProductDiscount")}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        type="number"
                        label={t("couponSubscriptionDiscount")}
                        value={values.couponSubscriptionDiscount}
                        name="couponSubscriptionDiscount"
                        disabled={disabled}
                        placeholder={t("couponSubscriptionDiscount")}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        type="number"
                        fullWidth
                        label={t("Product Commission")}
                        value={values.productCommission}
                        name="productCommission"
                        disabled={disabled}
                        placeholder={t("Product commission")}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        type="number"
                        fullWidth
                        label={t("Subscription commission")}
                        value={values.subscriptionCommission}
                        name="subscriptionCommission"
                        disabled={disabled}
                        placeholder={t("Subscription commission")}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  </Grid>
                  {Boolean(editable && !disabled) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEnableValidation(true);
                        submitForm();
                      }}
                      fullWidth
                      disabled={loading}
                    >
                      {t("Save")}
                      {loading && (
                        <CircularProgress
                          thickness={7}
                          style={{marginLeft: 10}}
                          size={12}
                          color="secondary"
                        />
                      )}
                    </Button>
                  )}
                </>
              </Form>
            )}
          </Formik>
        </Paper>

        {error && (
          <NotificationToast
            message={t("ServerNotResponding")}
            horizontal="center"
            severity="error"
            vertical={"bottom"}
          />
        )}
      </>
    );
  };

interface FormValues {
  userId: string
  subscriptionCommission?: number
  productCommission?: number
  couponSubscriptionDiscount?: number
  couponProductDiscount?: number
  username?: string
  couponCode?: string
}