import { gql } from "@apollo/client";

export const FETCH_USER_PREFERENCES = gql`
  query FetchUserPreferences($input: FetchUserPreferencesInput!) {
    fetchUserPreferences(input: $input) {
      jobLocations {
        value
        total
      }
      jobCategories {
        value
        total
      }
    }
  }
`;
