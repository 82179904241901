import React from 'react';
import {Box, Paper, styled, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {
  useGetPromotersCurrentStats
} from "../../../hooks/queries/useGetPromotersCurrentStats";
import {ExchangeRates} from "./ExchangeRates";


export const PromotersInfo = () => {
  const {t} = useTranslation();
  const {promotersCurrentStats, loading, error} = useGetPromotersCurrentStats()

  return (
    <Box marginBottom="20px">
      <Paper style={{padding: "20px"}}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Promoters info")}
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <Box>
            <InfoRow>{t("Number of promoters")} : {promotersCurrentStats?.countAllPromoters}</InfoRow>
            <InfoRow>{t("Number of active promoters")} : {promotersCurrentStats?.countActivePromoters}</InfoRow>
            <InfoRow>{t("Number of promoters for payout")} : {promotersCurrentStats?.countPromotersEntitledToPayout}</InfoRow>
            <InfoRow>{t("Total payout amount in USD")} : {promotersCurrentStats?.totalPayoutAmountInUsd} USD</InfoRow>
            <InfoRow>{t("Partial payout to USD")} : {promotersCurrentStats?.partialPayoutAmountInUsd} USD</InfoRow>
            <InfoRow>{t("Partial payout to EUR")} : {promotersCurrentStats?.partialPayoutAmountInEur} EUR</InfoRow>
            <InfoRow>{t("Partial payout to RUB")} : {promotersCurrentStats?.partialPayoutAmountInRub} RUB</InfoRow>
          </Box>
          <ExchangeRates/>
        </Box>

      </Paper>
    </Box>
  );
};

const InfoRow = styled(Box)({
  fontSize: "15px",
  margin: "5px 0"
})

