import React, {useEffect, useState} from 'react';
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import {AddNewButton, NotificationToast} from "../../components";
import {useHistory, useLocation} from "react-router-dom";
import {useGetProducts} from "../../hooks/queries/products/useGetProducts";
import {GetProductsFilter, PaginationOptionsArgs} from "../../graphql/globalTypes";
import {useSetHeadbarTitle} from "../../hooks";
import {
  GetProducts_getProducts_products
} from "../../graphql/queries/products/getProducts/__generated__/GetProducts";
import ProductsFilterBar from "./components/ProductsFilterBar";
import {ProductsTable} from "./components/ProductsTable";

export const Products = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Products"));
  const history = useHistory();
  const location = useLocation<any>();
  const [products, setProducts] = useState<GetProducts_getProducts_products[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [filter, setFilter] = useState<GetProductsFilter>({
    canBeSoldSeparately: undefined,
    status: undefined,
    category: undefined,
    isFeatured: undefined,
    variant: undefined,
    isHidden: undefined
  });
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "postedAt",
        sortBy: "desc",
      },
    });
  const {data, loading, error} = useGetProducts({
    filter,
    paginationOptions
  })
  const handleAddNewProductClick = () => {
    history.push("/products/create");
  }

  useEffect(() => {
    if (data) {
      setTotalProducts(data.getProducts.meta.totalItems);
      setProducts(data.getProducts.products);
    }
  }, [data]);

  useEffect(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      skip: 0,
    }));
  }, [filter]);

  return (
    <Box margin="20px">
      <ProductsFilterBar filterParams={filter} setFilterParams={setFilter}/>
      <Box textAlign="right" margin="10px 0 0">
        <AddNewButton title={t("Add new product")} onClick={handleAddNewProductClick}/>
      </Box>
      <ProductsTable loading={loading}
                     products={products}
                     totalProducts={totalProducts}
                     paginationOptions={paginationOptions}
                     setPaginationOptions={setPaginationOptions}/>
      {location?.state?.showCreateNotification && (
        <NotificationToast
          message={t("Product created")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};
