import { useMutation } from "@apollo/client";
import { VERIFY_EMAIL_VIA_LINK } from "../../graphql";
import {
  VerifyEmailViaLink,
  VerifyEmailViaLinkVariables,
} from "../../graphql/queries/me/VerifyEmailByToken/__generated__/VerifyEmailViaLink";

export const useVerifyEmailByLink = () => {
  const [verify, { loading, error: serverError, data }] = useMutation<
    VerifyEmailViaLink,
    VerifyEmailViaLinkVariables
  >(VERIFY_EMAIL_VIA_LINK, {
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    verify,
    loading,
    serverError,
    linkError: data?.verifyEmailViaLink.error?.name,
  };
};
