import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Switch,
  TextField,
} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  CreateCoupon,
  CreateCoupon_createCoupon_coupon,
  CreateCouponVariables,
} from "../../../graphql/mutations/subscriptions/CreateCoupon/__generated__/CreateCoupon";
import * as Yup from "yup";
import {CREATE_COUPON, UPDATE_COUPON} from "../../../graphql";
import {ApolloQueryResult, OperationVariables, useMutation,} from "@apollo/client";
import {
  GetCoupons
} from "../../../graphql/queries/subscriptions/GetCoupons/__generated__/GetCoupons";
import {MyTextField, NotificationToast, SelectButton} from "../../../components";
import {
  UpdateCoupon,
  UpdateCouponVariables,
} from "../../../graphql/mutations/subscriptions/UpdateCoupon/__generated__/UpdateCoupon";
import {
  CouponKind,
  GetUsersInput,
  ProductCategory,
  UserRole
} from "../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../utils";
import {Autocomplete} from "@material-ui/lab";
import {
  GetUsers_getUsers_users as IUser
} from "../../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {useGetUsers} from "../../../hooks";
import {useDebounce} from "../../../hooks/useDebounce";
import {MyMultipleSelect} from "../../../components/inputs/MyMultipleSelect";
import {useGetProducts} from "../../../hooks/queries/products/useGetProducts";

interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  coupon: null | CreateCoupon_createCoupon_coupon;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCoupons>>;
}

export const EditDialog: React.FC<EditDialogProps> = ({
                                                        open,
                                                        onClose,
                                                        coupon,
                                                        refetch,
                                                      }) => {
  const {t} = useTranslation();
  const [behalfPromoter , setBehalfPromoter]=useState(false)
  const [create, {loading, error}] = useMutation<CreateCoupon,
    CreateCouponVariables>(CREATE_COUPON, {
    onCompleted: (res) => {
      if (res.createCoupon.coupon) {
        onClose();
        refetch({});
      }
    },
  });
  const [update, {loading: updateLoading, error: updateError}] = useMutation<UpdateCoupon,
    UpdateCouponVariables>(UPDATE_COUPON, {
    onCompleted: (res) => {
      if (res.updateCoupon.coupon) {
        onClose();
        refetch({});
      }
    },
  });
  const {data: products, loading: productsLoading} = useGetProducts({
    filter: {},
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "postedAt",
        sortBy: "desc",
      },
    }
  })

  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    textSearch: undefined,
    roles: [UserRole.PROMOTER],
  });
  const [users, setUsers] = useState<IUser[]>([]);
  const {data, loading: usersLoading} = useGetUsers({
    input: useDebounce(filterParams, 700),
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      }
    }
  });

  const handleTextSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };

  const initialValues: FormValues = {
    kind: CouponKind.SUBSCRIPTIONS,
    couponCode: coupon?.couponCode ?? "",
    countUsers: coupon?.countUsers ?? 0,
    countTimes: coupon?.countTimes ?? 0,
    discountPercentage: coupon?.discountPercentage
      ? coupon.discountPercentage * 100
      : 0,
  };

  const validationSchema = Yup.object({
    couponCode: Yup.string().required(t("Required")),
    countUsers: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
    countTimes: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
    discountPercentage: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
  });

  useEffect(() => {
    if (data) {
      setUsers(data.getUsers.users);
    }
  }, [data]);

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>
          {coupon ? t("Edit Coupon") : t("Create Coupon")}
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            values, {setErrors, setSubmitting}) => {
            if (!coupon) {
              const res = await create({
                variables: {
                  input: {
                    ...values,
                    couponCode: values.couponCode.toUpperCase(),
                    discountPercentage: values.discountPercentage / 100,
                  },
                },
              });

              if (res.data?.createCoupon.error) {
                setErrors({couponCode: "Такой промокод уже существует"});
              }
            } else {
              const res = await update({
                variables: {
                  input: {
                    id: coupon.id,
                    couponCode: values.couponCode.toUpperCase(),
                    countUsers: values.countUsers,
                    countTimes: values.countTimes,
                  },
                },
              });

              if (res.data?.updateCoupon.error) {
                setErrors({couponCode: "Такой промокод уже существует"});
              }
            }
          }}
        >
          {({dirty, setFieldValue, values, submitForm, errors}) => (
            <Form>
              <>
                <DialogContent>
                  {!coupon && <SelectButton
                    label={t("kind")}
                    rootStyle={{width: "100%"}}
                    options={convertEnumToArray(CouponKind)}
                    value={values.kind}
                    onChange={(e) => setFieldValue("kind", e.target.value)}
                  />}

                  {(values.kind === CouponKind.PRODUCTS__BY_CATEGORIES || values.kind === CouponKind.UNIVERSAL) &&
                    <><Box height="20px"/>
                      <Field
                        onChange={(e: string[]) => {
                          setFieldValue("productCategories", e)
                        }}
                        value={values.productCategories ?? []}
                        options={convertEnumToArray(ProductCategory)}
                        component={MyMultipleSelect}
                        fullWidth
                        label={"PRODUCTS__BY_CATEGORIES"}
                      /></>}

                  {(values.kind === CouponKind.PRODUCTS__BY_IDS || values.kind === CouponKind.UNIVERSAL) &&
                    <><Box height="20px"/>
                      <Field
                        onChange={(e: string[]) => {
                          const Ids = products?.getProducts.products.filter(product => e.includes(product.title)).map(prod => prod.id)
                          setFieldValue("productsIds", Ids)
                        }}
                        value={products?.getProducts.products.filter(prod => values.productsIds?.includes(prod.id)).map(prod => prod.title) ?? []}
                        options={products?.getProducts.products.map(product => product.title)}
                        component={MyMultipleSelect}
                        fullWidth
                        label={"PRODUCTS__BY_IDS"}
                      /></>}

                  <Box height="20px"/>
                  <Field
                    toUpperCase
                    component={MyTextField}
                    fullWidth
                    label={t("couponCode")}
                    name="couponCode"
                    error={errors.couponCode}
                  />
                  <Box height="20px"/>
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={"Количество новых пользователей"}
                    name="countUsers"
                    type="number"
                  />
                  <Box height="20px"/>
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={"Количество используемых раз"}
                    name="countTimes"
                    type="number"
                  />
                  <Box height="20px"/>
                  {!coupon && <Field
                    component={MyTextField}
                    fullWidth
                    label={"Скидка, %"}
                    name="discountPercentage"
                    type="number"
                  />}
                  <Box height="20px"/>

                  {!coupon && <Box display="flex"
                        alignItems="center"
                        marginBottom="20px"
                  >
                    <Switch
                      color="primary"
                      onChange={(event, checked) => {
                        setBehalfPromoter(checked)
                        setFieldValue("promoterId", undefined)
                      }}
                      checked={behalfPromoter}
                    />
                    <Box>{t("behalfPromoter")}</Box>
                  </Box>}


                  {(!coupon && behalfPromoter) &&
                    <Field
                    name="promoterId"
                    noOptionsText={t("Partner not found")}
                    loading={usersLoading}
                    loadingText={<CircularProgress
                      thickness={7}
                      style={{marginLeft: 10}}
                      size={20}
                      color="primary"
                    />}
                    component={Autocomplete}
                    options={users}
                    getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} ` || ""}
                    onChange={(event: any, newValue: IUser) => setFieldValue("promoterId", newValue?.id)}
                    fullWidth
                    style={{marginBottom: 20}}
                    renderInput={(params: any) => (
                      <TextField {...params}
                                 placeholder={t("Enter promoter name or email")}
                                 variant="outlined"
                                 InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                     <React.Fragment>
                                       {usersLoading ?
                                         <CircularProgress color="primary"
                                                           size={20}/> : null}
                                       {params.InputProps.endAdornment}
                                     </React.Fragment>
                                   ),
                                 }}
                                 label={t("Enter promoter name or email")}
                                 onChange={handleTextSearchChange}
                      />
                    )}
                  />}
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>{t("Close")}</Button>
                  <Button disabled={loading} onClick={submitForm}>
                    {t(coupon ? "Save" : "Create")}
                    {Boolean(loading || updateLoading) && (
                      <CircularProgress style={{marginLeft: "10px"}}/>
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {Boolean(error || updateError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  couponCode: string;
  countUsers: number;
  countTimes: number;
  discountPercentage: number;
  kind: CouponKind;
  promoterId?: string;
  productCategories?: ProductCategory[];
  productsIds?: string[];
}
