import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AddEnumVariables } from "../../graphql/mutations/jobs/AddEnum/__generated__/AddEnum";
import { UpdateEnumVariables } from "../../graphql/mutations/jobs/UpdateEnum/__generated__/UpdateEnum";
import { FetchEnumsWithStats_fetchEnumsWithStats_jobCategories_enum as IEnum } from "../../graphql/queries/jobs/GetEnumsWithStats/__generated__/FetchEnumsWithStats";
import { useAddEnum, useUpdateEnum } from "../../hooks";
import { EnumRepoName, IPicUpload } from "../../lib";
import { MyTextField, PictureUpload } from "../inputs";
import { NotificationToast } from "../notifications";

interface EditEnumProps {
  open: boolean;
  onClose: () => void;
  currentEnum: IEnum | null;
  withLogo?: boolean;
  repoName: EnumRepoName;
  withFactor?: boolean;
}

export const EditEnum: React.FC<EditEnumProps> = ({
  open,
  onClose,
  currentEnum,
  withLogo,
  repoName,
  withFactor,
}) => {
  const { t } = useTranslation();
  const {
    error: updateError,
    loading: updateLoading,
    update,
  } = useUpdateEnum();
  const { error: addError, loading: addLoading, add } = useAddEnum();

  const [enableValidation, setEnableValidation] = useState(false);
  const [pic, setPic] = useState<IPicUpload | null>(
    currentEnum?.logo
      ? {
          public_id: currentEnum.logo.public_id,
          url: currentEnum.logo.url,
        }
      : null
  );

  useEffect(() => {
    setPic(
      currentEnum?.logo
        ? {
            public_id: currentEnum.logo.public_id,
            url: currentEnum.logo.url,
          }
        : null
    );
  }, [currentEnum]);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Required")),
    ...(withFactor && {
      factor: Yup.number().required(t("Required")),
    }),
  });

  const initialValues: FormValues = {
    name: currentEnum?.name ?? "",
    translation: currentEnum?.translation ?? "",
    ...(withFactor && {
      factor: currentEnum?.factor ?? 1,
    }),
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {currentEnum ? t("Edit Type") : t("Add New Type")}
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            if (currentEnum) {
              const variables: UpdateEnumVariables = {
                input: {
                  id: currentEnum.id,
                  repoName,
                  ...(pic && pic.url !== currentEnum.logo?.url
                    ? {
                        logo: {
                          filename: currentEnum.name,
                          publicId: pic.public_id,
                          type: "IMAGE",
                          url: pic.url,
                        },
                      }
                    : {}),
                  ...(values.name && values.name !== currentEnum.name
                    ? {
                        name: values.name,
                      }
                    : {}),
                  ...(values.translation &&
                  values.translation !== currentEnum.translation
                    ? {
                        translation: values.translation,
                      }
                    : {}),
                  ...(withFactor && {
                    factor: (values as any).factor!,
                  }),
                },
              };

              try {
                const res = await update({ variables });
                if (res.data?.updateEnum.error) {
                  setErrors({ name: t("Name already exists") });
                } else {
                  onClose();
                }
              } finally {
                setSubmitting(false);
              }
            } else {
              const variables: AddEnumVariables = {
                input: {
                  name: values.name,
                  ...(values.translation
                    ? { translation: values.translation }
                    : {}),
                  ...(withFactor ? { factor: (values as any).factor } : {}),
                  repoName,
                  ...(withLogo && pic
                    ? {
                        logo: {
                          filename: values.name,
                          publicId: pic.public_id,
                          type: "IMAGE",
                          url: pic.url,
                        },
                      }
                    : {}),
                },
              };
              try {
                const res = await add({ variables });
                if (res.data?.addEnum.error) {
                  setErrors({ name: t("Name already exists") });
                } else {
                  onClose();
                }
              } finally {
                setSubmitting(false);
              }
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <>
                <DialogContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {withLogo && (
                      <>
                        <PictureUpload
                          imageUrl={currentEnum?.logo?.url}
                          picUpload={pic}
                          setPicUpload={setPic}
                        />
                        <Box marginX={2} />
                      </>
                    )}

                    <Box>
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("Name")}
                        name="name"
                        style={{ marginBottom: 20 }}
                      />
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("Translation")}
                        name="translation"
                        style={{ marginBottom: 20 }}
                      />
                      {withFactor && (
                        <Field
                          component={MyTextField}
                          type="number"
                          fullWidth
                          label={"Фактор"}
                          name="factor"
                          style={{ marginBottom: 20 }}
                        />
                      )}
                    </Box>
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button onClick={onClose}>{t("Cancel")}</Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    disabled={updateLoading || addLoading}
                  >
                    {t("Save")}
                    {(updateLoading || addLoading) && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {(updateError || addError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  name: string;
  translation: string;
}
