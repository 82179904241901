import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      user {
        id
        role
        isFellow
        token
        avatarSrc
        firstName
        lastName
        email
      }
      error {
        name
        message
      }
    }
  }
`;
