import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useInputStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tf_root: {},
      pu__root: {
        position: "relative",
        width: "100%",
        minWidth: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        aspectRatio: "1.5",
        border: `1px dashed ${theme.palette.text.secondary}`,
        cursor: "default",
        "& img": {
          objectFit: "contain",
          width: "100%",
          maxWidth: "400px",
        },
      },
      pu__uploadBtn: {
        position: "absolute",
        top: 5,
        right: 5,
      },
      checkList__paper: {
        maxHeight: 400,
        overflow: "scroll",
        marginTop: 10,
      },
      tags__paper: {
        marginTop: 10,
        paddingLeft: 10,
        display: "flex",
      },
      avatarUpload__avatar: {
        width: theme.spacing(25),
        height: theme.spacing(25),
        color: "#b1b1b1",
        backgroundColor: "#f6f7fe",
        boxShadow: "0px 1px 5px -3px #000",
      },
      avatarUpload__input: {
        display: "none",
      },

      avatarUpload__label: {
        cursor: "pointer",
      },

      avatarUpload__btn: {
        position: "absolute",
        bottom: 0,
        right: 0,
        color: "#fff !important",
        backgroundColor: " #2196F3 !important",
      },
      datepicker: {
        background: "#fff",
        boxShadow: "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
        borderRadius: 4,
        paddingLeft: 5,
        "& .MuiInputLabel-shrink": {
          top: 2,
          left: 5,
          fontSize: 13,
        },
      },
      chip__label: {
        maxWidth: "200px",
        textOverflow: "ellipsis",
      },
      enumList__root: {
        padding: 10,
      },
      select: {
        "& fieldset": {
          border: "unset",
          "& legend": {
            visibility: "visible",
            display: "flex",
            height: "100%",
            fontSize: "0.9rem",
            textAlign: "left",
            maxWidth: "unset",
            position: "relative",
            alignItems: "center",
          },
        },
      },
      pictureUpload__picture: {
        width: "100%",
        minHeight:"150px",
        color: "#b1b1b1",
        border: "1px dashed #b1b1b1",
        backgroundColor: "#fff",
        boxShadow: "0px 1px 5px -3px #000",
      },
    }),
  { index: 1 }
);
