import { Box, Typography } from "@material-ui/core";
import theme from "@react-page/editor/lib/ui/ThemeProvider/DarkTheme";
import React from "react";
import { useTranslation } from "react-i18next";

export const DesktopOnly: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        background: theme.palette.primary.dark,
        backgroundImage:
          "radial-gradient(circle farthest-corner at center, #303f9f 0%, #1C262B 100%)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          viewBox="0 0 891.8 892"
          style={{
            height: "200px",
            margin: "0 auto",
            display: "block",
          }}
        >
          <g id="apple-core">
            <path
              id="appleItself"
              fill="#e12828"
              d="M681.9 369.5c.7-42.9-19.9-87.6-62.1-101.6-52.2-17.2-94.1-6.1-139 20.9-33.4 20.1-80.1 1.3-112.1-13.8-42.8-20.2-110-7.7-139.4 30.9-30.9 40.6-37.1 100-18.5 147.6 17.8 45.5 45.5 80.1 78.2 114.7 29.1 30.9 61.8 66.5 108 67.7 17 .4 32.1-11 47.9-10.5 8.8.3 19.2 8 27.9 10 16.7 3.9 33.3 1.7 49.7-2.5 45.5-11.5 85.3-61.1 108.2-100 29.7-50.7 50.3-104.5 51.2-163.4z"
            />
            <path
              id="leaf"
              fill="#92b737"
              d="M455.9 225.9c5.2 0-6.9-65.6-8.3-70.5-7.9-28.8-18.9-59.4-32.3-86-6-12-37.2-61.6-52.9-51.3-13.2 8.7-12.5 50.9-13.6 64.2-4.4 50.2 13.5 94.1 40.2 136.7 7.5 11.9 16.2 21.1 26.7 30.4 6.7 5.8 20.6 22.2 28.8 24.3 10.3 2.7 6.4 1.3 8.5-9.9 2.5-13 2.9-24.5 2.9-37.9z"
            />
            <path
              id="stem"
              d="M443 313.5c11.8-13.9 17.1-34.3 26.4-50 11.8-19.8 27.1-36.6 41.5-54.7 15.4-19.3 30.9-38.4 47.1-57 15.1-17.3 27.6-28.9-3.7-38.3-19.3-5.8-22.2 7.5-32.2 23-13.7 21.3-28 42.3-40 64.7-10.5 19.8-19.3 40.5-26.2 61.9-2.9 8.4-19.3 43.3-12.9 50.4z"
            />
            <path
              id="shine"
              fill="#fff"
              d="M263.6 469.4c-28.1-24.9-32-68.3-21.9-102.6 4.1-13.8 10.5-26.5 20.2-37.1 4.1-4.5 12.6-7.4 11.7-14.7-.9-6.6-9.3-10-15-8.6-15.8 4-27.9 41.2-31.5 54.8-4.7 18.2-5.3 37.2-1.7 55.6 4.3 21.5 14.2 41.6 28.6 58.1 2.3 2.7 13.8 18.1 18.2 13.6 4.3-4.1-6.1-16.9-8.6-19.1z"
            />
          </g>
        </svg>

        <Typography variant="h6" style={{ color: "#fff" }}>
          {t("Desktop only")}
        </Typography>
      </div>
    </Box>
  );
};
