import {Button, Dialog, DialogContent, DialogTitle, IconButton,} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {useMutation} from "@apollo/client";
import {
  DeleteAffiliate,
  DeleteAffiliateVariables
} from "../../graphql/mutations/affiliates/deleteAffiliate/__generated__/DeleteAffiliate";
import {GET_AFFILIATES} from "../../graphql/queries/affiliate/GetAffiliates";
import {DELETE_AFFILIATE} from "../../graphql/mutations/affiliates/deleteAffiliate";
import {useOpenDialog} from "../../hooks/useOpenDialog";
import {NotificationToast} from "../../components";

interface Props {
  id:string
}

export const DeleteAffiliateDialog: React.FC<Props> = ({id}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const [deleteAffiliate, { loading, error }] = useMutation<
    DeleteAffiliate,
    DeleteAffiliateVariables
  >(DELETE_AFFILIATE, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_AFFILIATES,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "created_at",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  const handleDelete=async ()=>{
    const res =await deleteAffiliate({
      variables:{
        id
      }
    })
    if(res.data?.deleteAffiliate){
      handleClose()
    }
  }
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon color="primary"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle style={{width: "400px"}}>
          {t("Delete affiliate")}
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
          </Box>
          <Box textAlign="center" fontSize="16px">{t("Do you really want to delete affiliate?")}</Box>
        </DialogContent>
        <Box display="flex" margin="20px" gap="30px" justifyContent="center">
          <Button disabled={loading} onClick={handleClose} variant="contained" color="primary">
            {t("Cancel")}
          </Button>
          <Button disabled={loading} onClick={handleDelete} variant="contained" autoFocus>
            {t("Delete")}
          </Button>
        </Box>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};