import { useReactiveVar } from "@apollo/client";
import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  drawerOpenVar,
  userCountriesVar,
  userNationalitiesVar,
} from "../../../apollo";
import { SelectButton, TextSearch } from "../../../components";
import { FetchUserPreferencesInput } from "../../../graphql/globalTypes";

interface PreferencesFilterBarProps {
  filterParams: FetchUserPreferencesInput;
  setFilterParams: React.Dispatch<
    React.SetStateAction<FetchUserPreferencesInput>
  >;
}

export const PreferencesFilterBar: React.FC<PreferencesFilterBarProps> = ({
  filterParams,
  setFilterParams,
}) => {
  const exNationalities = useReactiveVar(userNationalitiesVar);
  const exCountries = useReactiveVar(userCountriesVar);
  const drawerOpen = useReactiveVar(drawerOpenVar);
  const { t } = useTranslation();

  const handleFellowChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            isFellow: undefined,
          }
        : {
            isFellow: event.target.value === "Have" ? true : false,
          }),
    }));
  };

  const handleNationalityChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            nationality: undefined,
          }
        : {
            nationality: event.target.value as string,
          }),
    }));
  };

  const handleCountryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const countryId =
      event.target.value === "all"
        ? undefined
        : event.target.value === "Not specified"
        ? event.target.value
        : exCountries.find((c) => c.rusTrans === event.target.value)!.id;

    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            countryId: undefined,
          }
        : {
            countryId: countryId,
          }),
    }));
  };

  const castFellow = (isFellow?: boolean | null) => {
    if (isFellow == undefined) {
      return "all";
    }
    return isFellow ? "Have" : "Not have";
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  return (
    <Box
      zIndex={100}
      display="flex"
      width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
      justifyContent="stretch"
      position="fixed"
    >
      <SelectButton
        squared
        onChange={handleFellowChange}
        value={castFellow(filterParams.isFellow)}
        options={["Have", "Not have", "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Fellowship")}
      />

      <SelectButton
        squared
        onChange={handleNationalityChange}
        value={filterParams.nationality ?? "all"}
        options={["Not specified", ...exNationalities, "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Nationality")}
        noOptionTranslation
      />
      <SelectButton
        squared
        onChange={handleCountryChange}
        value={
          filterParams.countryId && filterParams.countryId !== "Not specified"
            ? exCountries.find((c) => c.id === filterParams.countryId)!
                .rusTrans!
            : filterParams.countryId
            ? filterParams.countryId
            : "all"
        }
        options={[
          "Not specified",
          ...exCountries.map((c) => c.rusTrans ?? ""),
          "all",
        ]}
        rootStyle={{ flex: 1 }}
        label={t("Country")}
        noOptionTranslation
      />

      <TextSearch
        rootStyle={{ flex: 2 }}
        onClick={handleTextSearchChange}
        onReset={handleTextSearchReset}
        hint="Email, firstName, lastName, skype, phone, zoom, linkedIn"
      />
    </Box>
  );
};
