import { apolloClient, userVar } from "../apollo";
import * as H from "history";
import { LOGOUT, ME } from "../graphql";
import { Logout } from "../graphql/mutations/me/Logout/__generated__/Logout";
import moment from "moment-timezone";
import _ from "lodash";
import { UserRole } from "../graphql/globalTypes";
import { Me_me } from "../graphql/queries/me/Me/__generated__/Me";
import { googleLogout } from "@react-oauth/google";

export async function loginUser(token: string) {
  localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN!, token);

  window.location.reload();
}

export async function logoutUser(history: H.History<H.LocationState>) {
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN!);

  await apolloClient.mutate<Logout>({
    mutation: LOGOUT,
    variables: {
      input: {
        token,
        token_client: "AdminPanel",
      },
    },
  });
  userVar(undefined);
  googleLogout();
  history.replace("/login");
  localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN!);
}

export function getFileNameFromPublicId(s: string): string {
  const arr = s.split("/");
  let dotIndex = arr[arr.length - 1].lastIndexOf(".");
  return arr[arr.length - 1].substring(
    0,
    dotIndex === -1 ? arr[arr.length - 1].length : dotIndex
  );
}

/**
 * Converts a given enum to an array of the enum values
 * @param input Enum
 */
export function convertEnumToArray<T>(input: T): T[keyof T][] {
  let arrayWithEnumItems: T[keyof T][] = [];
  for (let item in input) {
    if (isNaN(Number(item))) {
      arrayWithEnumItems.push(input[item]);
    }
  }
  return arrayWithEnumItems;
}

/**
 * Returns a random integer between min and max included.
 * @param min lower boundary
 * @param max upper boundary
 */
export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function makeid(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getAgeFromDate(birthdate: Date) {
  return moment().diff(birthdate, "years");
}

export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const capitalize = (str: string) => {
  return _.startCase(_.toLower(str));
};

export const capitalizeFirstLetters = (str: string) => {
  return str
    .split(" ")
    .map((n) => n.charAt(0).toUpperCase() + n.slice(1).toLowerCase())
    .join(" ");
};

export function isAdmin(user?: Me_me): boolean {
  if (!user) return false;
  return [UserRole.ADMIN, UserRole.GLOBAL_ADMIN, UserRole.MODERATOR].includes(
    user.role
  );
}
