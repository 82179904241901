import { gql } from "@apollo/client";
import { ArticleCategoryFragment } from "../../../fragments";

export const GET_ARTICLE_CATEGORIES = gql`
  query GetArticleCategories {
    getArticleCategories {
      category {
        ...ArticleCategory
      }
      articlesCount
    }
  }
  ${ArticleCategoryFragment}
`;
