import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { Form, Formik, FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Fallback,
  FullscreenLoader,
  NotificationToast,
  Stats,
} from "../../components";
import { UpdateJobInput } from "../../graphql/globalTypes";
import { UpdateJobVariables } from "../../graphql/mutations/jobs/UpdateJob/__generated__/UpdateJob";
import {
  useFetchJobFromRoute,
  useSetHeadbarTitle,
  useUpdateJob,
} from "../../hooks";
import { JobFormValues } from "../../lib";
import { useCommonStyle } from "../../styles/commonStyles";
import * as Yup from "yup";
import { EditJobLeft, EditJobRight } from "./components";
import { UpdateJobDescription } from "./components/updateJob";
import moment from "moment";
import { useCardStyle } from "../../components/cards/styles";

interface EditJobProps {}

export const EditJob: React.FC<EditJobProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Edit Job"));
  const history = useHistory();
  const chipStyles = useCardStyle();
  const commonClasses = useCommonStyle();
  const [success, setSuccess] = useState(false);
  const [input, setInput] = useState<UpdateJobInput | null>(null);
  const { update, error, loading } = useUpdateJob();
  const {
    job,
    error: initError,
    loading: initLoading,
    showCreateNotification,
  } = useFetchJobFromRoute();
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    if (job) {
      console.warn("company is : " + job.company);
      setInput({
        id: job.id,
        title: job.title,
        sourceId: job.sourceId,
        applicationLink: job.applicationLink,
        companyId: job.companyId ?? "",
        openUntil: job.openUntil,
        readAccess: job.readAccess,
        status: job.status,
        sourceType: job.sourceType,
        location: {
          countryId: job.location?.countryObject?.id ?? "",
          city: job.location?.city ?? "",
        },
        employmentType: job.employmentTypeId ?? "",
        seniority: job.seniorityId ?? "",
        jobCategories: job.jobCategoriesIds ?? [],
        tags: job.tags ?? [],
        industries: job.industries ?? [],
        description: job.description ?? "",
        isUN: job.isUN ?? false,
        companyName: job.company,
      });
    }
  }, [job]);
  // hi
  if (initError || (!job && !initLoading)) {
    return (
      <Fallback
        title={t("Job not found")}
        message={t("Seems like the job you want does not exist")}
        onClick={() => history.push("/jobs")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading || !input) {
    return <FullscreenLoader transparent />;
  }

  const initialValues: JobFormValues = {
    title: input.title!,
    sourceId: input.sourceId!,
    companyId: input.companyId!,
    applicationLink: input.applicationLink!,
    city: input.location!.city!,
    isUN: input.isUN!,
    companyName: input.companyName!,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(5, t("Must have at least VAR characters", { count: 5 }))
      .required(t("Required")),
    sourceId: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    applicationLink: Yup.string()
      .min(10, t("Must have at least VAR characters", { count: 10 }))
      .required(t("Required")),
    companyName: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
  });

  const handleSubmit = async (
    values: JobFormValues,
    setErrors: (errors: FormikErrors<JobFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSuccess(false);

    const variables: UpdateJobVariables = {
      input: {
        id: input!.id,
        isUN: values.isUN,
        companyName: values.companyName,
        ...(values.title && values.title !== job!.title
          ? { title: values.title }
          : {}),
        ...(values.sourceId && values.sourceId !== job!.sourceId
          ? { sourceId: values.sourceId }
          : {}),
        ...(values.applicationLink &&
        values.applicationLink !== job!.applicationLink
          ? { applicationLink: values.applicationLink }
          : {}),
        ...(input.sourceType && input.sourceType !== job!.sourceType
          ? { sourceType: input.sourceType }
          : {}),

        ...(input.jobCategories !== job?.jobCategoriesIds
          ? {
              jobCategories: input.jobCategories,
            }
          : {}),
        ...(input.industries !== job?.industries
          ? {
              industries: input.industries,
            }
          : {}),
        ...(input.tags !== job?.tags
          ? {
              tags: input.tags,
            }
          : {}),
        ...(input.description !== job?.description
          ? {
              description: input.description,
            }
          : {}),
        ...(input.employmentType &&
        input.employmentType !== job?.employmentTypeId
          ? {
              employmentType: input.employmentType,
            }
          : {}),
        ...(input.seniority && input.seniority !== job?.seniorityId
          ? {
              seniority: input.seniority,
            }
          : {}),
        ...(values.companyId !== job?.companyId
          ? {
              companyId: values.companyId,
            }
          : {}),
        ...(input.openUntil !== job?.openUntil
          ? { openUntil: input.openUntil }
          : {}),
        ...(input.readAccess !== job?.readAccess
          ? { readAccess: input.readAccess }
          : {}),
        ...(input.status !== job?.status ? { status: input.status } : {}),
        location: {
          ...(input.location?.countryId
            ? input.location.countryId === t("Not specified")
              ? {
                  countryId: undefined,
                }
              : {
                  countryId: input.location.countryId,
                }
            : {}),
          ...(values.city !== job?.location?.city
            ? {
                city: values.city,
              }
            : {}),
        },
      },
    };

    try {
      const res = await update({ variables });
      if (res.data?.updateJob.error) {
        setErrors({
          [res.data.updateJob.error.name]: t(res.data.updateJob.error.message!),
        });
      } else if (res.data?.updateJob.job) {
        setSuccess(true);
      }
    } finally {
      setSubmitting(false);
      setEditOpen(false);
    }
  };

  if (!input) return null;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          handleSubmit(values, setErrors, setSubmitting);
        }}
      >
        {({ submitForm }) => (
          <Form>
            <Box className={commonClasses.sectionWithSticky}>
              {editOpen ? (
                <UpdateJobDescription
                  input={input}
                  setInput={setInput as any}
                />
              ) : (
                <>
                  <EditJobLeft
                    update={{
                      input,
                      setInput: setInput as any
                    }}
                    applicationLink={input.applicationLink!}
                    categories={
                      <>
                        <Typography variant="overline">
                          {t("Job Categories")}
                        </Typography>
                        <br />
                        {job?.jobCategories?.map((c) => (
                          <Chip label={c.name} />
                        ))}
                      </>
                    }
                    stats={
                      <>
                        {job?.salary && (
                          <>
                            <Box marginTop={3} />
                            <Typography display="block" variant="overline">
                              Зарплата
                            </Typography>
                            <Chip
                              className={chipStyles.enumcard__chip}
                              style={{ margin: 0 }}
                              size="small"
                              label={
                                <>
                                  <Typography variant="body1" display="inline">
                                    {`${job.salary!.min} - ${job.salary!.max} ${
                                      job.salary!.currency
                                    }`}
                                  </Typography>
                                  {job.salary.withoutCompany && (
                                    <Typography
                                      display="inline"
                                      variant="body1"
                                    >
                                      *
                                    </Typography>
                                  )}
                                </>
                              }
                            />
                          </>
                        )}
                        <Box marginTop={3} />
                        <Typography variant="overline">{t("Stats")}</Typography>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Grid item xs={3}>
                            <Stats
                              title={t("viewCount")}
                              value={job?.viewCount ?? 0}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Stats
                              title={t("savedBy")}
                              value={job?.savedBy ?? 0}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Stats
                              title={t("postedAt")}
                              value={
                                job?.postedAt
                                  ? moment(job.postedAt).format("LL").toString()
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </>
              )}

              <EditJobRight
                updateInput={input}
                editOpen={editOpen}
                setEditOpen={setEditOpen}
                handleSubmit={() => {
                  submitForm();
                }}
                disabled={loading}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {loading && <FullscreenLoader transparent />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}

      {showCreateNotification ||
        (success && (
          <NotificationToast
            message={success ? t("JobUpdated") : t("JobCreated")}
            horizontal="center"
            severity="success"
            vertical={"bottom"}
          />
        ))}
    </>
  );
};
