import { useMutation } from "@apollo/client";
import { UPDATE_USER_INFO } from "../../graphql";
import {
  UpdateUserInfo,
  UpdateUserInfoVariables,
} from "../../graphql/mutations/users/UpdateUserInfo/__generated__/UpdateUserInfo";

export const useUpdateUserInfo = () => {
  const [update, { loading, error }] = useMutation<
    UpdateUserInfo,
    UpdateUserInfoVariables
  >(UPDATE_USER_INFO, {
    onError: (err) => console.error(err),
  });

  return { update, loading, error };
};
