import { gql } from "@apollo/client";
import { ArticleFragment } from "../../../fragments";

export const GET_ARTICLE = gql`
  query GetArticle($id: String!) {
    getArticle(id: $id) {
      ...Article
    }
  }
  ${ArticleFragment}
`;
