import { Box, Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldArray } from "formik";
import React, { Key } from "react";
import { useTranslation } from "react-i18next";

interface FormikFieldMultipleAutocompleteProps {
  name: string;
  placeholder: string;
  values: string[];
  options: string[];
  getOptionLabel: (option: string) => string;
}

export const FormikFieldMultipleAutocomplete: React.FC<
  FormikFieldMultipleAutocompleteProps
> = ({ name, placeholder, values, getOptionLabel, options }) => {
  const { t } = useTranslation();
  let aKey = new Date().toString() as Key;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <Autocomplete
            multiple
            fullWidth
            options={options}
            getOptionLabel={getOptionLabel}
            value={values}
            key={aKey}
            onChange={(_event, value) => {
              value.forEach((v) => {
                if (!values.includes(v)) {
                  arrayHelpers.push(v);
                }
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                variant="outlined"
              />
            )}
            renderTags={() => null}
          />

          <Box marginY={"10px"}>
            {values.map((id) => (
              <Chip
                key={id}
                label={getOptionLabel(id)}
                onDelete={() => {
                  const existIndex = values.findIndex((v) => v === id);
                  arrayHelpers.remove(existIndex);
                  aKey = new Date().toString() as Key;
                }}
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              />
            ))}
          </Box>
        </>
      )}
    />
  );
};
