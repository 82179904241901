import { useMutation } from "@apollo/client";
import { UPDATE_ENUM } from "../../graphql";
import {
  UpdateEnum,
  UpdateEnumVariables,
} from "../../graphql/mutations/jobs/UpdateEnum/__generated__/UpdateEnum";

export const useUpdateEnum = () => {
  const [update, { loading, error }] = useMutation<
    UpdateEnum,
    UpdateEnumVariables
  >(UPDATE_ENUM, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};
