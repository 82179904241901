import {useQuery} from "@apollo/client";
import {
  GetPromotersList,
  GetPromotersListVariables
} from "../../graphql/queries/promoters/getPromotersList/__generated__/GetPromotersList";
import {GET_PROMOTERS_LIST} from "../../graphql/queries/promoters/getPromotersList";

export const useGetPromotersList = (variables: GetPromotersListVariables) => {
  const { data, loading, error } = useQuery<GetPromotersList, GetPromotersListVariables>(
    GET_PROMOTERS_LIST,
    {
      onError: (err) => console.error(err),
      variables,
    }
  );

  return { data, loading, error };
};