import {gql} from "@apollo/client";

export const CREATE_EXPERT_REQUEST = gql`
    mutation CreateExpertRequest($input: CreateExpertRequestInput!) {
        createExpertRequest(input: $input) {
            expertRequest {
                category
                title
                id
            }
            error {
                name
                message
            }
        }
    }
`;
