import {ApolloQueryResult, useMutation} from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {Add, Edit, Remove} from "@material-ui/icons";
import {Field, FieldArray, Form, Formik} from "formik";
import {Select} from "formik-material-ui";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {
  AddNewButton,
  MyTable,
  MyTextField,
  NotificationToast,
} from "../../../components";
import {CREATE_SUBSCRIPTION_PLAN} from "../../../graphql";
import {
  CreateSubscriptionPlan,
  CreateSubscriptionPlanVariables,
} from "../../../graphql/mutations/subscriptions/CreateSubscriptionPlan/__generated__/CreateSubscriptionPlan";
import {
  GetAdminSubscriptionPlans,
  GetAdminSubscriptionPlans_getAdminSubscriptionPlans,
  GetAdminSubscriptionPlansVariables,
} from "../../../graphql/queries/subscriptions/GetAdminSubscriptionPlans/__generated__/GetAdminSubscriptionPlans";
import {BillingCycle, HeadCell} from "../../../lib";
import DeleteIcon from '@material-ui/icons/Delete';
import {
  useDeleteSubscriptionPlan
} from "../../../hooks/mutations/useDeleteSubscriptionPlan";
import {AppLanguage, Currency} from "../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../utils";
import {appLanguages} from "../../../lib/constants";

interface PlansTableProps {
  plans: GetAdminSubscriptionPlans_getAdminSubscriptionPlans[];
  loading: boolean;
  refetch: (
    variables?: Partial<GetAdminSubscriptionPlansVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAdminSubscriptionPlans>>;
  handleOpen: (
    plan: GetAdminSubscriptionPlans_getAdminSubscriptionPlans
  ) => void;
}

export const PlansTable: React.FC<PlansTableProps> = ({
  plans,
  loading,
  refetch,
  handleOpen,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [createPlan, { data, error, loading: createLoading }] = useMutation<
    CreateSubscriptionPlan,
    CreateSubscriptionPlanVariables
  >(CREATE_SUBSCRIPTION_PLAN, {
    onError: (err) => console.log(err),
  });
  const {deleteSubscriptionPlan, error:deletePlanError, loading:deleteLoading}=useDeleteSubscriptionPlan()

  useEffect(() => {
    if (data?.createSubscriptionPlan) {
      refetch({});
      setOpen(false);
    }
  }, [data]);

  const mapArticlesToRows = useMemo(() => {
    const arr = plans.map((plan) => ({
      id: plan.plan.id,
      title: (
        <Box width={400}>
          <Typography variant="subtitle2">{plan.plan.title}</Typography>
          <Typography variant="caption">ID: {plan.plan.id}</Typography>
        </Box>
      ),
      billingCycle: t(plan.plan.billingCycle),
      status: t(plan.plan.status),
      price: (
        <Box>
          <Typography variant="subtitle2">
            {plan.plan.price.value} {plan.plan.price.currency}
          </Typography>
        </Box>
      ),
      subscriptionsCount: (
        <Typography variant="subtitle2">{plan.countSubsctriptions}</Typography>
      ),
      edit: (
        <IconButton onClick={() => handleOpen(plan)}>
          <Edit />
        </IconButton>
      ),
      delete: <IconButton
        disabled={plan.plan.id==="61c1f27e9057db532a9de80e" || plan.plan.id==="6203c8372863dc0003dbe034"}
        onClick={() => handleDeletePlanClick(plan.plan.id)}>
        <DeleteIcon />
      </IconButton>,
    }));
    return arr;
  }, [plans]);

  const handleAddNew = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePlanClick=(id:string)=>{
    deleteSubscriptionPlan({variables:{id}})
  }

  const initialValues: FormValues = {
    title: "",
    description: "",
    features: [],
    billingCycle: BillingCycle.ANNUALLY,
    priceValue: 0,
    language:AppLanguage.ru
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t("Required")),
    description: Yup.string().required(t("Required")),
    priceValue: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
  });

  return (
    <>
      <Box width="100%">
        <MyTable
          hideCheck
          selected={[]}
          loading={loading || deleteLoading}
          setSelected={() => {}}
          headCells={headCells}
          values={mapArticlesToRows}
          sortFields={[]}
          toolbarRightButton={
            <AddNewButton onClick={handleAddNew} title={t("Add Plan")} />
          }
          bulkActions={<></>}
        />
      </Box>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{t("Create Subscription Plan")}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(
            { features, priceValue, ...rest },
            { setErrors, setSubmitting }
          ) => {
            const input = {
              ...(features.length ? { features } : {}),
              price: {
                currency: Currency.RUB,
                value: priceValue.toString(),
              },
              ...rest,
            };
            createPlan({ variables: { input } });
          }}
        >
          {({ values, submitForm, setFieldValue }) => (
            <Form>
              <>
                <DialogContent>
                  <Typography variant="body2" color="textSecondary">
                    {t("Language")}
                  </Typography>
                  <RadioGroup row
                              value={values.language}
                              onChange={(event, value)=>setFieldValue("language", value)} >
                    {convertEnumToArray(AppLanguage).map(lng=>
                      <FormControlLabel
                        value={lng}
                        control={<Radio />}
                        label={appLanguages[lng]}
                        key={lng} />)}
                  </RadioGroup>
                  <Box height="10px"/>
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("Title")}
                    name="title"
                  />
                  <Box height="20px" />
                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("Description")}
                    name="description"
                  />
                  <Box height="20px" />

                  <Field
                    component={Select}
                    fullWidth
                    label={t("billingCycle")}
                    name="billingCycle"
                    variant="outlined"
                  >
                    <MenuItem value={BillingCycle.ANNUALLY}>
                      {t(BillingCycle.ANNUALLY)}
                    </MenuItem>
                    <MenuItem value={BillingCycle.MONTHLY}>
                      {t(BillingCycle.MONTHLY)}
                    </MenuItem>
                    <MenuItem value={BillingCycle.SEMI_ANNUAL}>
                      {t(BillingCycle.SEMI_ANNUAL)}
                    </MenuItem>
                  </Field>
                  <Box height="20px" />

                  <Field
                    component={MyTextField}
                    fullWidth
                    label={t("price") + ", RUB"}
                    name="priceValue"
                    type="number"
                  />

                  <Box height="40px" />

                  <Typography variant="h6" style={{ marginBottom: "10px" }}>
                    {t("Features")}
                  </Typography>
                  <FieldArray
                    name="features"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.features && values.features.length > 0 ? (
                          values.features.map((_feature, index) => (
                            <Box
                              key={index}
                              display={"flex"}
                              marginBottom={"20px"}
                            >
                              <Field
                                component={MyTextField}
                                fullWidth
                                name={`features.${index}`}
                              />
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <Remove />
                              </IconButton>
                              <IconButton
                                onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                              >
                                <Add />
                              </IconButton>
                            </Box>
                          ))
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => arrayHelpers.push("")}
                            disabled={createLoading}
                          >
                            {t("Add a feature")}
                          </Button>
                        )}
                      </Box>
                    )}
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>{t("Close")}</Button>
                  <Button onClick={submitForm}>{t("Create")}</Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {(error || deletePlanError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "title",
    align: "left",
  },
  {
    label: "billingCycle",
    align: "left",
  },
  //
  // {
  //   label: "status",
  //   align: "left",
  // },
  {
    label: "price",
    align: "left",
  },
  {
    label: "subscriptionsCount",
    align: "right",
  },
  {
    label: "edit",
    align: "right",
  },
  {
    label: "delete",
    align: "right",
  },
];

interface FormValues {
  title: string;
  description: string;
  features: string[];
  billingCycle: BillingCycle;
  priceValue: number;
  language?: AppLanguage
}
