import { gql } from "@apollo/client";
import { FileFragment } from "../../..";

export const UPDATE_JOB_CATEGORY = gql`
  mutation UpdateJobCategory($input: UpdateJobCategoryInput!) {
    updateJobCategory(input: $input) {
      jobCategory {
        id
        name
        engTrans
        logo {
          ...File
        }
      }
      error {
        name
        message
      }
    }
  }
  ${FileFragment}
`;
