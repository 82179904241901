import {useQuery} from "@apollo/client";
import {
  GetExpertFilters
} from "../../../graphql/queries/experts/getExpertFilters/__generated__/GetExpertFilters";
import {GET_EXPERT_FILTERS} from "../../../graphql/queries/experts/getExpertFilters";

export const useGetExpertFilters = () => {
  const { data, loading, error } = useQuery<GetExpertFilters>(GET_EXPERT_FILTERS, {
    onError: (err) => console.error(err),
  });
  return { loading, error,filters:data?.getExpertFilters};
};
