import { gql } from "@apollo/client";

export const GET_PARTNER_COMPANY_TAGS = gql`
    query GetPartnerCompanyTags {
        getPartnerCompanyTags {
            category
            title
            id
        }
    }
`;
