import { gql } from "@apollo/client";
import { SeoTagFragment } from "../../../fragments";

export const UPDATE_SEO_TAG = gql`
  mutation UpdateSeoTag($input: UpdateSeoTagInput!) {
    updateSeoTag(input: $input) {
      ...SeoTag
    }
  }
  ${SeoTagFragment}
`;
