import { ApolloLink, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { apiUrl } from "./urls";

const httpLink = createHttpLink({
  uri: apiUrl,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN!);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloLink = ApolloLink.from([
  // new TokenRefreshLink({
  //   accessTokenField: "accessToken",

  //   isTokenValidOrUndefined: () => {
  //     const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN!);
  //     if (!token) return true;

  //     try {
  //       const { exp } = jwtdecode(token) as any;
  //       if (Date.now() >= exp * 1000) {
  //         localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN!);
  //         return false;
  //       }

  //       return true;
  //     } catch (error) {
  //       console.error(error);
  //       return false;
  //     }
  //   },

  //   fetchAccessToken: () => {
  //     return fetch(refreshUrl!, {
  //       method: "POST",
  //       credentials: "include",
  //     });
  //   },

  //   handleFetch: (accessToken) => {
  //     console.log(accessToken);
  //     localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN!, accessToken);
  //   },

  //   handleError: (err) => {
  //     console.warn("Refresh token is invalid");
  //     console.error(err);
  //     localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN!);
  //     userVar(undefined);
  //   },
  // }),
  authLink,
  httpLink,
]);
