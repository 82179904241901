import React, {useState} from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {format} from 'date-fns'
import {ExchangeRates} from "./ExchangeRates";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {NotificationToast} from "../../../components";
import {
  GetPromoterByAdmin_getPromoterByAdmin
} from "../../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";

interface PromoterPaymentsInfoProps {
  promoter: GetPromoterByAdmin_getPromoterByAdmin
  totalSubscribers: number
}

export const PromoterPaymentsInfo =
  ({
     promoter,
     totalSubscribers
   }: PromoterPaymentsInfoProps) => {
    const {t} = useTranslation();
    const [copied, setCopied] = useState(false);
    const {projectedIncome, accumulatedIncome, payoutCurrency} = promoter
    const bankAccount = promoter.promoter?.partnerSettings?.bankAccount
    const partnershipStarted = promoter.promoter?.partnerSettings?.partnershipStarted
    const handleCopyClick = async () => {
      setCopied(false)
      promoter && await navigator.clipboard.writeText(
        `${promoter.promoter.firstName} ${promoter.promoter.lastName}; 
        email: ${promoter.promoter.email}; 
        username: ${promoter.promoter.partnerSettings?.username}; 
        currency: ${bankAccount?.currency ?? t("Not specified")};
        IBAN: ${bankAccount?.iban ?? t("Not specified")};
        SWIFT_BIC: ${bankAccount?.swift_bic ?? t("Not specified")};
        account name: ${bankAccount?.accountName ?? t("Not specified")};
        country: ${bankAccount?.address?.countryObject?.rusTrans ?? t("Not specified")};
        state: ${bankAccount?.address?.state ?? t("Not specified")};
        zip code: ${bankAccount?.address?.zipCode ?? t("Not specified")};
        city, address: ${bankAccount?.address?.city ?? t("Not specified")};`)
      setCopied(true)
    }
    return (
      <Paper>
        <Box padding={"10px"}>
          <Typography color="textSecondary" variant="h6">
            {t("payments info")}
          </Typography>
          <Divider style={{marginBottom: 20}}/>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Box>
              <InfoRow>{t("PromoCode")}: <Value>{promoter.promoter.partnerSettings?.couponCode}</Value></InfoRow>
              <InfoRow>{t("joining date")}: <Value>{format(new Date(partnershipStarted), 'dd.MM.yyyy H:mm')}</Value></InfoRow>
              <InfoRow>{t("payment method")}: <Value>{payoutCurrency ?? "Не указано"}</Value></InfoRow>
              <InfoRow>{t("accumulatedIncome")}: <Value>{accumulatedIncome?.amount}</Value></InfoRow>
              <InfoRow>{t("projectedIncome")}: <Value>{projectedIncome?.amount}</Value></InfoRow>
              <InfoRow>{t("subscribers")}: <Value>{totalSubscribers ?? 0}</Value></InfoRow>
            </Box>
            <ExchangeRates/>
          </Box>
          <Box>
            <Title>
              {t("Bank data")}
              <IconButton
                style={{marginLeft:"5px"}}
                size="small"
                color="primary"
                onClick={handleCopyClick} >
                <FileCopyIcon/>
              </IconButton>
            </Title>
            {bankAccount
              ? <Box>
                {bankAccount.currency === "RUB"
                  ? <Box>
                    <InfoRow>{t("currency")}: <Value>{bankAccount?.currency}</Value></InfoRow>
                    <InfoRow>{t("Promoter full name")}: <Value>{bankAccount?.accountName}</Value></InfoRow>
                    <InfoRow>{t("Account number")}: <Value>{bankAccount?.iban}</Value></InfoRow>
                    <InfoRow>{t("BIC code")}: <Value>{bankAccount?.swift_bic}</Value></InfoRow>
                  </Box>
                  : <Grid container>
                    <Grid item xs={6}><InfoRow>{t("currency")}: <Value>{bankAccount?.currency}</Value></InfoRow>
                      <InfoRow>{t("Promoter full name")}: <Value>{bankAccount?.accountName}</Value></InfoRow>
                      <InfoRow>{t("IBAN")}: <Value>{bankAccount?.iban}</Value></InfoRow>
                      <InfoRow>{t("SWIFT")}: <Value>{bankAccount?.swift_bic}</Value></InfoRow></Grid>
                    <Grid item xs={6}>
                        <InfoRow>{t("Country")}: <Value>{bankAccount?.address?.countryObject?.rusTrans}</Value></InfoRow>
                        <InfoRow>{t("Zip code")}: <Value>{bankAccount?.address?.zipCode}</Value></InfoRow>
                        <InfoRow>{t("State")}: <Value>{bankAccount?.address?.state}</Value></InfoRow>
                        <InfoRow>{t("City, address")}: <Value>{bankAccount?.address?.city}</Value></InfoRow>
                    </Grid>
                  </Grid>
                }
              </Box>
              : <InfoRow>{t("No bank data")}</InfoRow>
            }
          </Box>
        </Box>
        {copied && (
          <NotificationToast
            horizontal="center"
            vertical="bottom"
            message={t("Copied to buffer")}
            severity="success"
          />
        )}
      </Paper>
    );
  };

const InfoRow = styled(Box)({
  fontSize: "15px",
  margin: "5px 0"
})

const Value = styled(Box)({
  fontWeight: 500,
  display: "inline"
})

const Title = styled(Box)({
  marginTop:"10px",
  fontWeight: 500,
  fontSize: "16px"
})

