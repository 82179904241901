import { Box, Paper } from "@material-ui/core";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  CreateJobInput,
  UpdateJobInput,
} from "../../../../graphql/globalTypes";
import { useCommonStyle } from "../../../../styles/commonStyles";

interface UpdateJobDescriptionProps {
  input: CreateJobInput | UpdateJobInput;
  setInput: React.Dispatch<
    React.SetStateAction<CreateJobInput | UpdateJobInput>
  >;
}

export const UpdateJobDescription: React.FC<UpdateJobDescriptionProps> = ({
  input,
  setInput,
}) => {
  const commonClasses = useCommonStyle();
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState<EditorState>(
    input.description
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(input.description).contentBlocks,
            htmlToDraft(input.description).entityMap
          )
        )
      : EditorState.createEmpty()
  );

  useEffect(() => {
    setInput((prev) => ({
      ...prev,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    }));
  }, [editorState]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <Box className={commonClasses.sectionWithSticky__scrollable}>
      <Paper style={{ padding: 20 }}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        />
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </Paper>
    </Box>
  );
};
