import {useMutation} from "@apollo/client";
import {CREATE_EXPERT_ACTIVITY, GET_EXPERT_ACTIVITIES} from "../../../graphql";
import {
  CreateExpertActivity,
  CreateExpertActivityVariables
} from "../../../graphql/mutations/experts/createExpertActivity/__generated__/CreateExpertActivity";

export const useCreateExpertActivity = () => {
  const [create, { loading}] = useMutation<
    CreateExpertActivity,
    CreateExpertActivityVariables
    >(CREATE_EXPERT_ACTIVITY, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_EXPERT_ACTIVITIES,
      },
    ],
  });

  return { create, loading };
};
