import {useQuery} from "@apollo/client";
import {
  GetProducts,
  GetProductsVariables
} from "../../../graphql/queries/products/getProducts/__generated__/GetProducts";
import {GET_PRODUCTS} from "../../../graphql/queries/products/getProducts";

export const useGetProducts = (variables:GetProductsVariables) => {
  const { data, loading, error } = useQuery<
    GetProducts,
    GetProductsVariables
    >(GET_PRODUCTS, {
    onError: (err) => console.error(err),
    variables,
  });

  return { data, loading, error };
};
