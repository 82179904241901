import { gql } from "@apollo/client";
import {CountryFragment} from "../../../fragments";

export const GET_PARTNER_COMPANIES_FILTERS = gql`
    query GetPartnerCompaniesFilters {
        getPartnerCompaniesFilters{
        countries{
            ...Country
        }
        languages
        tags{
            title
            category
            id
        }
        partnerCompanyCategories{
            name
            id
        }
        }
    }
    ${CountryFragment}
`;