import {useMutation} from "@apollo/client";
import {GET_PRODUCTS} from "../../graphql/queries/products/getProducts";
import {
  UpdateProduct,
  UpdateProductVariables
} from "../../graphql/mutations/products/updateProduct/__generated__/UpdateProduct";
import {UPDATE_PRODUCT} from "../../graphql/mutations/products/updateProduct";

export const useUpdateProduct = () => {
  const [update, { loading, error }] = useMutation<
    UpdateProduct,
    UpdateProductVariables
    >(UPDATE_PRODUCT, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          filter: {},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "postedAt",
              sortBy: "desc",
            }
          },
        },
      },
    ],
  });

  return {
    update,
    loading,
    error,
  };
};