import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { GetLearningPaths_getLearningPaths_learningPaths } from "../../../../graphql/queries/learningPath/getLearningPaths/__generated__/GetLearningPaths";
import {PublishStatus} from "../../../../graphql/globalTypes";

interface TopAreaProps {
  path: GetLearningPaths_getLearningPaths_learningPaths;
}

const MAP = {
  [PublishStatus.ARCHIVED]: {
    background: "grey",
  },
  [PublishStatus.DRAFT]: {
    background: "orange",
  },
  [PublishStatus.PUBLISHED]: {
    background: "green",
  },
  [PublishStatus.MODERATION]: {
    background: "red",
  },
};

export const TopArea: React.FC<TopAreaProps> = ({ path }) => {
  const { t } = useTranslation();

  return (
    <Box position={"relative"}>
      <Box
        style={{
          height: "220px",
          borderRadius: "6px",
          overflow: "hidden",
          ...(!path.heroVideoUrl && {
            background: "#2196f317",
          }),
        }}
      >
        {path.heroPic && (
          <img
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
              opacity: 0.8,
            }}
            src={path.heroPic.url}
          />
        )}
      </Box>

      <Box
        style={{
          padding: "7px",
          position: "absolute",
          top: 10,
          right: 10,
          borderRadius: "6px",
          color: "white",
          background: MAP[path.status as PublishStatus].background,
        }}
      >
        {t(path.status)}
      </Box>
    </Box>
  );
};
