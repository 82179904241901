import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectButton } from "./SelectButton";

interface BulkActionsProps {
  options: string[];
  loading?: boolean;
  onApply: (option: string) => void;
}

export const BulkActions: React.FC<BulkActionsProps> = ({
  options,
  loading,
  onApply,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleChange = (e: any) => setValue(e.target.value);

  return (
    <Box display="flex">
      <SelectButton
        displayEmpty
        value={value}
        onChange={handleChange}
        options={options}
        placeholder={t("Choose action")}
        densed
        rootStyle={{ marginRight: 10 }}
      />
      <Button
        variant="contained"
        onClick={() => onApply(value)}
        disabled={loading || !value}
      >
        {t("Apply")}
      </Button>
    </Box>
  );
};
