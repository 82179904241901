import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Paper
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {headerTitleVar} from "../../apollo";
import {Fallback, FullscreenLoader, NotificationToast,} from "../../components";
import {useSetHeadbarTitle} from "../../hooks";
import {LockUnlockSection} from "./components/LockUnlockSection";
import {SubscribersTable} from "./components/SubscribersTable";
import {PaginationOptionsArgs} from "../../graphql/globalTypes";
import {PromoterPaymentsInfo} from "./components/PromoterPaymentsInfo";
import {PromoterSummary} from "./components/PromoterSummary";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {EditPromoterSettings} from "./components/EditPromoterSettings";
import {PromoterPayoutsTable} from "./components/PromoterPayoutsTable";
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import {useUsersStyles} from "../users/styles";
import {
  GetPromoterByAdmin_getPromoterByAdmin
} from "../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";
import {
  GetPromoterSubscriptionList_getPromoterSubscriptionList_archivedSubscriptions
} from "../../graphql/queries/promoters/getPromoterSubscriptionList/__generated__/GetPromoterSubscriptionList";
import {useFetchPromoterFromRoute} from "../../hooks/useFetchPromoterFromRoute";
import {
  useGetPromoterSubscriptionList
} from "../../hooks/queries/useGetPromoterSubscriptionList";


export const EditPromoter: React.FC = () => {
  const {t} = useTranslation();
  const classes = useUsersStyles();
  useSetHeadbarTitle(t("Loading..."));
  const history = useHistory();
  const [promoter, setPromoter] = useState<GetPromoterByAdmin_getPromoterByAdmin | null>(null);
  const [subscribers, setSubscribers] = useState<GetPromoterSubscriptionList_getPromoterSubscriptionList_archivedSubscriptions[]>([]);
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [copied, setCopied] = useState(false);
  const [section, setSection] = useState(0)

  const {
    promoter: initPromoter,
    loading: initLoading,
    error: initError,
    showCreateNotification
  } = useFetchPromoterFromRoute()

  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      },
    });
  const handleCopyClick = async () => {
    setCopied(false)
    promoter && await navigator.clipboard.writeText(
      `${promoter.promoter.firstName} ${promoter.promoter.lastName}; email: ${promoter.promoter.email}; username: ${promoter.promoter.partnerSettings?.username}; coupon code: ${promoter.promoter.partnerSettings?.couponCode};`)
    setCopied(true)
  }

  const {
    getPromoterSubscriptionList,
    data,
    error,
    loading
  } = useGetPromoterSubscriptionList()

  useEffect(() => {
    if (initPromoter) {
      setPromoter(initPromoter);
      headerTitleVar(
        `${t("promoter")} ${initPromoter.promoter.firstName} ${initPromoter.promoter.lastName}`
      );
      getPromoterSubscriptionList({
        variables: {
          id: initPromoter.promoter.id
        }
      })
    }
  }, [initPromoter]);

  useEffect(() => {
    if (data) {
      setTotalSubscribers(data.getPromoterSubscriptionList.subscriptions?.length ?? 0);
      setSubscribers(data.getPromoterSubscriptionList.subscriptions ?? []);
    }
  }, [data]);

  if (initError || (!initPromoter && !initLoading)) {
    return (
      <Fallback
        title={t("User not found")}
        message={t("Seems like the user you want does not exist")}
        onClick={() => history.push("/users")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading || !promoter) {
    return <FullscreenLoader transparent/>;
  }

  return (
    <>
      <Box margin="20px">
        <PromoterSummary promoter={promoter.promoter}/>
        <Box display="flex" gridColumnGap="20px" justifyContent="center"
             alignItems="center">
          <Box>
            <LockUnlockSection promoter={promoter.promoter}/>
          </Box>
          <Button
            onClick={handleCopyClick}
            startIcon={<FileCopyIcon/>}
            color="primary"
            variant="contained"
          >{t("CopyData")}</Button>
        </Box>
        <EditPromoterSettings promoter={promoter} editable/>
        <Box marginY={3}/>
        <PromoterPaymentsInfo
          promoter={promoter}
          totalSubscribers={totalSubscribers}
        />
        <Box marginY={3}/>
        <Paper className={classes.info__paper}>
          <BottomNavigation
            value={section}
            onChange={(event, newValue) => {
              setSection(newValue);
            }}
            showLabels
          >
            <BottomNavigationAction label={t("subscribers")} icon={<PeopleIcon/>}/>
            <BottomNavigationAction label={t("Payouts")} icon={<PaymentIcon/>}/>
          </BottomNavigation>
        </Paper>
      </Box>

      {section === 0 && <SubscribersTable
        loading={loading}
        setPaginationOptions={setPaginationOptions}
        paginationOptions={paginationOptions}
        totalUsers={totalSubscribers}
        subscribers={subscribers}
      />}

      {section === 1 && <PromoterPayoutsTable promoterId={promoter.promoter.id}/>}

      {showCreateNotification && (
        <NotificationToast
          message={t("UserCreated")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}

      {copied && (
        <NotificationToast
          horizontal="center"
          vertical="bottom"
          message={t("Copied to buffer")}
          severity="success"
        />
      )}
    </>
  );
};