import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationToast, SelectButton } from "../../../components";
import { useBulkArticleUpdate } from "../../../hooks";
import { ReadAccess } from "../../../lib";
import { convertEnumToArray } from "../../../utils";
import {PublishStatus} from "../../../graphql/globalTypes";

interface ArticleBulkActionProps {
  currentAction: "Update status" | "Update read access" | "";
  articleIds: string[];
  onClose: () => void;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ArticleBulkAction: React.FC<ArticleBulkActionProps> = ({
  currentAction,
  articleIds,
  onClose,
  setSelected,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { loading, error, update } = useBulkArticleUpdate();

  const handleClose = () => {
    setValue("");
    setSelected([]);
    onClose();
  };

  const handleApply = async () => {
    try {
      await update({
        variables: {
          input: {
            articleIds,
            ...(currentAction === "Update read access"
              ? {
                  readAccess: value,
                }
              : {
                  status: value,
                }),
          },
        },
      });
      handleClose();
    } catch (error) {}
  };

  const getOptions = useMemo(() => {
    if (currentAction === "Update read access") {
      return convertEnumToArray(ReadAccess);
    } else if (currentAction === "Update status") {
      return convertEnumToArray(PublishStatus);
    } else {
      return [];
    }
  }, [currentAction]);

  const getLabel = useMemo(() => {
    if (currentAction === "Update read access") {
      return t("readAccess");
    } else if (currentAction === "Update status") {
      return t("status");
    } else {
      return "";
    }
  }, [currentAction]);

  return (
    <>
      <Dialog open={!!currentAction} onClose={handleClose}>
        <DialogTitle>
          {currentAction === "Update read access"
            ? t("Choose read access")
            : t("Choose status")}
        </DialogTitle>
        <DialogContent>
          <SelectButton
            options={getOptions}
            value={value}
            onChange={(e) => setValue(e?.target.value ?? "")}
            displayEmpty
            placeholder={getLabel}
            rootStyle={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button
            disabled={!value || loading}
            variant="contained"
            color="primary"
            onClick={handleApply}
          >
            {t("Apply")}
            {loading && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={10}
                color="secondary"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
