import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {useDeleteProduct} from "../../../hooks/mutations/useDeleteProduct";
import {NotificationToast} from "../../../components";
import {GET_PRODUCTS} from "../../../graphql/queries/products/getProducts";

interface DeleteProductDialogProps {
  productId:string
}

export const DeleteProductDialog: React.FC<DeleteProductDialogProps> = ({productId}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const {deleteProduct,loading,error}=useDeleteProduct()
  const [isDeleted, setIsDeleted]=useState(false)

  const handleDelete=async ()=>{
    const res =await deleteProduct({
      variables:{
        id:productId
      }
    })
    if(res.data?.deleteProduct){
      handleClose()
      setIsDeleted(true)
    }
  }
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon color="primary"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle style={{width: "400px"}}>
          {t("Delete product")}
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
          </Box>
          <Box textAlign="center" fontSize="16px">Вы дейтсвительно хотите удалить
            продукт?</Box>
        </DialogContent>
          <Box display="flex" margin="20px" gap="30px" justifyContent="center">
            <Button disabled={loading} onClick={handleClose} variant="contained" color="primary">
            {t("Cancel")}
          </Button>
            <Button disabled={loading} onClick={handleDelete} variant="contained" autoFocus>
              {t("Delete")}
            </Button>
          </Box>
      </Dialog>

      {isDeleted && <NotificationToast
        message={t("Product deleted")}
        horizontal="center"
        severity="info"
        vertical={"bottom"}
      />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
