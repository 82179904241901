import {useQuery} from "@apollo/client";
import {
  GET_PARTNER_COMPANY_CATEGORIES
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyCategories";
import {
  GetPartnerCompanyCategories
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyCategories/__generated__/GetPartnerCompanyCategories";

export const useGetPartnerCompanyCategories = () => {
  const { loading, error, data } = useQuery<GetPartnerCompanyCategories>(
    GET_PARTNER_COMPANY_CATEGORIES,
    {
      onError: (err) => console.error(err),
    }
  );

  return { loading, error, categories: data?.getPartnerCompanyCategories };
};
