import { useReactiveVar } from "@apollo/client";
import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { userVar } from "./apollo";
import { DashbordLayout, EditorLayout, FullscreenLayout } from "./layouts";
import {
  Articles,
  ArticlesEditor,
  Categories,
  CompaniesView,
  Consultations,
  CountriesView,
  Coupons,
  CreateArticle,
  CreateJob,
  EditArticle,
  EditArticleContent,
  EditJob,
  EditUser,
  EmailVerification,
  Home,
  JobEnums,
  Jobs,
  LearningPathsView,
  Login,
  PasswordReset,
  Profile,
  ReferralsView,
  Resources,
  Reviews,
  SeoView,
  Subscription,
  SubscriptionPlans,
  Subscriptions,
  UserPreferences,
  Users,
  ViewArticle
} from "./views";
import { Affiliates } from "./views/affiliates/Affiliates";
import { CreateNewAffiliate } from "./views/affiliates/CreateNewAffiliate";
import { EditAffiliate } from "./views/affiliates/EditAffiliate";
import { Banners } from "./views/banners/Banners";
import { CreateExpert } from "./views/experts/CreateExpert";
import { EditExpert } from "./views/experts/EditExpert";
import { ExpertActivities } from "./views/experts/ExpertActivities";
import { ExpertCompanies } from "./views/experts/ExpertCompanies";
import { ExpertRequests } from "./views/experts/ExpertRequests";
import { Experts } from "./views/experts/Experts";
import { JobApplicationTracker } from "./views/jobApplicationTracker/JobApplicationTracker";
import { LearningPathView } from "./views/learningPaths/LearningPathView";
import { CreatePartnerCompany } from "./views/partnerCompanies/CreatePartnerCompany";
import { EditPartnerCompany } from "./views/partnerCompanies/EditPartnerCompany";
import { PartnerCompanies } from "./views/partnerCompanies/PartnerCompanies";
import { PartnerCompaniesCategories } from "./views/partnerCompanies/PartnerCompaniesCategories";
import { CreateProduct } from "./views/products/CreateProduct";
import { EditProduct } from "./views/products/EditProduct";
import { PaymentsForProducts } from "./views/products/payments/PaymentsForProducts";
import { ProductPayment } from "./views/products/payments/ProductPayment";
import { Products } from "./views/products/Products";
import { EditPromoter } from "./views/promoters/EditPromoter";
import Promoters from "./views/promoters/Promoters";
import { PromotersPayouts } from "./views/promoters/PromotersPayouts";

export const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <AuthRoute authorized exact path="/">
          <Home />
        </AuthRoute>

        <AuthRoute authorized exact path="/companies">
          <CompaniesView />
        </AuthRoute>

        <AuthRoute authorized exact path="/countries">
          <CountriesView />
        </AuthRoute>

        <AuthRoute authorized exact path="/referrals">
          <ReferralsView />
        </AuthRoute>

        <AuthRoute authorized exact path="/reviews">
          <Reviews />
        </AuthRoute>

        <AuthRoute authorized exact path="/jobs">
          <Jobs />
        </AuthRoute>

        <AuthRoute authorized exact path="/seo">
          <SeoView />
        </AuthRoute>

        <AuthRoute authorized exact path="/jobs/create">
          <CreateJob />
        </AuthRoute>

        <AuthRoute authorized exact path="/jobs/edit/:id">
          <EditJob />
        </AuthRoute>

        <AuthRoute authorized exact path="/job-enums">
          <JobEnums />
        </AuthRoute>

        <AuthRoute authorized exact path="/profile">
          <Profile />
        </AuthRoute>

        <AuthRoute authorized exact path="/resources">
          <Resources />
        </AuthRoute>

        <AuthRoute authorized exact path="/consultations">
          <Consultations />
        </AuthRoute>

        <AuthRoute authorized exact path="/users">
          <Users />
        </AuthRoute>

        <AuthRoute authorized exact path="/users/:id">
          <EditUser />
        </AuthRoute>

        <AuthRoute authorized exact path="/user-preferences">
          <UserPreferences />
        </AuthRoute>

        <AuthRoute authorized exact path="/subscription-plans">
          <SubscriptionPlans />
        </AuthRoute>

        <AuthRoute authorized exact path="/subscriptions">
          <Subscriptions />
        </AuthRoute>

        <AuthRoute authorized exact path="/coupons">
          <Coupons />
        </AuthRoute>

        <AuthRoute authorized exact path="/subscriptions/:id">
          <Subscription />
        </AuthRoute>

        <AuthRoute authorized exact path="/articles">
          <Articles />
        </AuthRoute>

        <AuthRoute authorized exact path="/learning-paths">
          <LearningPathsView />
        </AuthRoute>

        <AuthRoute authorized exact path="/learning-paths/:slug">
          <LearningPathView />
        </AuthRoute>

        <EditorRoute exact path="/builder/:any">
          <ArticlesEditor />
        </EditorRoute>

        <AuthRoute authorized exact path="/articles/create">
          <CreateArticle />
        </AuthRoute>

        <AuthRoute authorized exact path="/articles/view/:id">
          <ViewArticle />
        </AuthRoute>

        <AuthRoute authorized exact path="/articles/edit/:id/content">
          <EditArticleContent />
        </AuthRoute>

        <AuthRoute authorized exact path="/articles/edit/:id">
          <EditArticle />
        </AuthRoute>

        <AuthRoute authorized exact path="/categories">
          <Categories />
        </AuthRoute>

        <AuthRoute exact path="/login">
          <Login />
        </AuthRoute>

        <AuthRoute exact path="/verification/:token">
          <EmailVerification />
        </AuthRoute>

        <AuthRoute exact path="/reset-password/:token">
          <PasswordReset />
        </AuthRoute>

        <AuthRoute authorized exact path="/promoters">
          <Promoters />
        </AuthRoute>

        <AuthRoute authorized exact path="/promoters-payouts">
          <PromotersPayouts />
        </AuthRoute>

        <AuthRoute authorized exact path="/promoters/:id">
          <EditPromoter />
        </AuthRoute>

        <AuthRoute authorized exact path="/products">
          <Products />
        </AuthRoute>

        <AuthRoute authorized exact path="/products/create">
          <CreateProduct />
        </AuthRoute>

        <AuthRoute authorized exact path="/products/edit/:id">
          <EditProduct />
        </AuthRoute>

        <AuthRoute authorized exact path="/products/payments">
          <PaymentsForProducts />
        </AuthRoute>

        <AuthRoute authorized exact path="/products/payment/:id">
          <ProductPayment />
        </AuthRoute>

        <AuthRoute authorized exact path="/partner-companies">
          <PartnerCompanies />
        </AuthRoute>

        <AuthRoute authorized exact path="/partner-companies/categories">
          <PartnerCompaniesCategories />
        </AuthRoute>

        <AuthRoute authorized exact path="/partner-companies/create">
          <CreatePartnerCompany />
        </AuthRoute>

        <AuthRoute authorized exact path="/partner-companies/edit/:id">
          <EditPartnerCompany />
        </AuthRoute>

        <AuthRoute authorized exact path="/experts">
          <Experts />
        </AuthRoute>

        <AuthRoute authorized exact path="/experts/companies">
          <ExpertCompanies />
        </AuthRoute>

        <AuthRoute authorized exact path="/experts/activities">
          <ExpertActivities />
        </AuthRoute>

        <AuthRoute authorized exact path="/experts/requests">
          <ExpertRequests />
        </AuthRoute>

        <AuthRoute authorized exact path="/experts/create">
          <CreateExpert />
        </AuthRoute>

        <AuthRoute authorized exact path="/experts/edit/:id">
          <EditExpert />
        </AuthRoute>

        <AuthRoute authorized exact path="/job-application-tracker">
          <JobApplicationTracker />
        </AuthRoute>

        <AuthRoute authorized exact path="/banners">
          <Banners />
        </AuthRoute>

        <AuthRoute authorized exact path="/affiliates">
          <Affiliates />
        </AuthRoute>

        <AuthRoute authorized exact path="/affiliates/create">
          <CreateNewAffiliate />
        </AuthRoute>

        <AuthRoute authorized exact path="/affiliates/edit/:id">
          <EditAffiliate />
        </AuthRoute>
      </Switch>
    </Router>
  );
};

function AuthRoute({ authorized, children, ...rest }: any) {
  const userProp = useReactiveVar(userVar);

  if (authorized) {
    return userProp ? (
      <Route
        {...rest}
        render={() => <DashbordLayout>{children}</DashbordLayout>}
      />
    ) : (
      <Redirect to={{ pathname: "/login" }} />
    );
  } else {
    return userProp ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <Route
        {...rest}
        render={() => <FullscreenLayout>{children}</FullscreenLayout>}
      />
    );
  }
}

function EditorRoute({ children, ...rest }: any) {
  const userProp = useReactiveVar(userVar);

  return userProp ? (
    <Route {...rest} render={() => <EditorLayout>{children}</EditorLayout>} />
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
}
