import {Box, IconButton} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {MyTable} from "../../../components";
import {PaginationOptionsArgs} from "../../../graphql/globalTypes";
import {HeadCell} from "../../../lib";
import {useHistory} from "react-router-dom";
import {
  GetPartnerCompanies_getPartnerCompanies_partnerCompanies
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanies/__generated__/GetPartnerCompanies";
import EditIcon from "@material-ui/icons/Edit";
import {DeletePartnerCompanyDialog,} from "./DeletePartnerCompanyDialog";

interface PartnerCompaniesTableProps {
  loading: boolean;
  partnerCompanies: GetPartnerCompanies_getPartnerCompanies_partnerCompanies[];
  totalPartnerCompanies: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const PartnerCompaniesTable: React.FC<PartnerCompaniesTableProps>
  = ({
       loading,
       partnerCompanies,
       totalPartnerCompanies,
       paginationOptions,
       setPaginationOptions,
     }) => {
  const {t} = useTranslation();
  const history = useHistory();
  const handleEditClick = (id: string, partnerCompany:GetPartnerCompanies_getPartnerCompanies_partnerCompanies) => {
    history.push({
      pathname: `/partner-companies/edit/${id}`,
      state: partnerCompany
    })
  }
  const mapProductsToRows = useMemo(() => {
    if (!partnerCompanies?.length) return [];
    const arr = partnerCompanies.map((partnerCompany, index) => ({
      id: partnerCompany.id,
      number: index + 1,
      partnerCompanyTitle: (
        <Box>
          <Box fontWeight={500}>{partnerCompany.title}</Box>
          <Box fontSize="0.8em" fontWeight={500}>{partnerCompany.subtitle}</Box>
          <Box>
            <IconButton onClick={() => handleEditClick(partnerCompany.id, partnerCompany)}>
              <EditIcon color="primary"/>
            </IconButton>
            <DeletePartnerCompanyDialog productId={partnerCompany.id}/>
          </Box>
        </Box>),
      directions: (<Box>
        <Box fontSize="0.9em"
             display="flex">
          {t("Directions")}:<Box marginLeft="5px"
                               fontWeight="500">{partnerCompany.partnerCompanyCategories?.map(cat => cat.name + ", ")}</Box>
        </Box>
        <Box fontSize="0.9em" display="flex">{t("status")}:<Box marginLeft="5px"
                                                                fontWeight="500">{t(partnerCompany.status)}</Box>
        </Box>
      </Box>),
      contacts: (<Box>
        <Box fontSize="0.9em" display="flex">{t("slug")}:<Box marginLeft="5px"
                                                              fontWeight="500">{partnerCompany.slug}</Box></Box>
        <Box fontSize="0.9em" display="flex">{t("LinkedIn")}:<Box marginLeft="5px"
                                                                  fontWeight="500">{partnerCompany.linkedId}</Box></Box>
        <Box fontSize="0.9em" display="flex">{t("VK")}:<Box marginLeft="5px"
                                                            fontWeight="500">{partnerCompany.twitter}</Box></Box>
        <Box fontSize="0.9em" display="flex">{t("Telegram")}:<Box marginLeft="5px"
                                                                  fontWeight="500">{partnerCompany.telegram}</Box></Box>
        <Box fontSize="0.9em" display="flex">{t("YouTube")}:<Box marginLeft="5px"
                                                                 fontWeight="500">{partnerCompany.youTube}</Box></Box>
        <Box fontSize="0.9em" display="flex">{t("Website")}:<Box marginLeft="5px"
                                                                 fontWeight="500">{partnerCompany.website}</Box></Box>
      </Box>),
      services: (<Box>
        {partnerCompany.services.map(serv =>
          <Box key={serv.id}>
            <Box fontSize="0.9em" fontWeight={500}>{serv.title}</Box>
            <Box fontSize="0.8em">{serv.affiliateLink} </Box>
          </Box>)}
      </Box>),
      locations: (<Box>
        <Box fontSize="0.9em"
             display="flex">{t("Countries")}:
          <Box marginLeft="5px"
               fontWeight="500">{partnerCompany.locations?.map(loc => loc.countryObject?.rusTrans + ", ")}</Box></Box>
        <Box fontSize="0.9em"
             display="flex">{t("languages")}:
          <Box marginLeft="5px"
               fontWeight="500">{partnerCompany.languages?.map(cat => cat + ", ")}</Box></Box>
      </Box>),
    }));
    return arr;
  }, [partnerCompanies]);

  return (
    <>
      <Box width="100%">
        <MyTable
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          total={totalPartnerCompanies}
          loading={loading}
          sortFields={[]}
          headCells={headCells}
          values={mapProductsToRows}
          hideCheck
          bulkActions={<></>}/>
      </Box>

    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "number",
    align: "left",
  },
  {
    label: "partnerCompanyTitle",
    align: "left",

  },
  {
    label: "contacts",
    align: "left",
  },
  {
    label: "directions",
    align: "left",
  },
  {
    label: "services",
    align: "left",
  },
  {
    label: "locations",
    align: "left",
  },
];