import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {MyAutocomplete, SelectButton, TextSearch} from "../../../components";
import {
  AdminGetJobsInput,
  GetPartnerCompaniesInput,
  PublishStatus
} from "../../../graphql/globalTypes";
import {convertEnumToArray} from "../../../utils";
import {MyMultipleSelect} from "../../../components/inputs/MyMultipleSelect";
import {Grid} from "@material-ui/core";
import {
  useGetPartnerCompaniesFilters
} from "../../../hooks/queries/partnerCompanies/useGetPartnerCompanyFilters";

interface PartnerCompaniesFilterBarProps {
  filterParams: GetPartnerCompaniesInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetPartnerCompaniesInput>>;
}

export const PartnerCompaniesFilterBar: React.FC<PartnerCompaniesFilterBarProps>
  = ({
       filterParams,
       setFilterParams,
     }) => {

  const {t} = useTranslation();
  const {loading, error, filters} = useGetPartnerCompaniesFilters()

  const handleFilterChange = (
    key: keyof AdminGetJobsInput,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };


  const getTagLabel = useCallback(
    (id: string) => {
      const tagName = filters?.tags?.find((t) => t.id === id);
      return tagName?.category + "/" + tagName?.title;
    },
    [filters?.tags]
  );
  const getCategoryLabel = useCallback(
    (id: string) => {
      const category = filters?.partnerCompanyCategories?.find((t) => t.id === id);
      return category?.name ?? ""
    },
    [filters?.partnerCompanyCategories]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <TextSearch
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"title, subtitle, description"}
        />
      </Grid>
      <Grid item xs={4}>
        <MyAutocomplete
          multiple
          options={filters?.countries ?? []}
          getOptionLabel={(option) => option.rusTrans ?? ""}
          label={t("Countries")}
          value={filters?.countries?.filter(c => filterParams.countriesIds?.includes(c.id)) ?? []}
          placeholder={t("Countries")}
          setValue={(e: any) => {
            setFilterParams((prev) => ({...prev, countriesIds: e.map((c: any) => c.id)}))
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectButton

          onChange={(e) => handleFilterChange("status", e)}
          value={filterParams.status ?? "all"}
          options={[...convertEnumToArray(PublishStatus), "all"]}
          rootStyle={{width: "100%"}}
          label={t("Status")}
        />
      </Grid>
      <Grid item xs={4}>
        <MyMultipleSelect
          fullWidth
          options={filters?.tags?.map((s) => s.id) ?? []}
          label={t("Requests")}
          value={filterParams.tags ?? []}
          onChange={(v) => {
            setFilterParams((prev) => ({
              ...prev, tags: v.length ? v : undefined,
            }))
          }}
          translationMap={(v) => getTagLabel(v)}
        />
      </Grid>
      <Grid item xs={4}>
        <MyMultipleSelect
          fullWidth
          options={filters?.partnerCompanyCategories?.map((s) => s.id) ?? []}
          label={t("Directions")}
          value={filterParams.partnerCompanyCategories ?? []}
          onChange={(v) => {
            setFilterParams((prev) => ({
              ...prev, partnerCompanyCategories: v.length ? v : undefined,
            }))
          }}
          translationMap={(v) => getCategoryLabel(v)}
        />
      </Grid>
      <Grid item xs={4}>
        <MyMultipleSelect
          fullWidth
          options={filters?.languages ?? []}
          label={t("Languages")}
          value={filterParams.languages ?? []}
          onChange={(v) => {
            setFilterParams((prev) => ({
              ...prev, languages: v.length ? v : undefined,
            }))
          }}
        />
      </Grid>
    </Grid>
  );
};