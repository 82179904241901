import {useMutation} from "@apollo/client";
import {CREATE_PROMOTER} from "../../graphql/mutations/promoters/CreatePromoter";
import {
  CreatePromoter, CreatePromoterVariables
} from "../../graphql/mutations/promoters/CreatePromoter/__generated__/CreatePromoter";

export const useCreatePromoter = () => {
  const [createPromoter, { loading, error }] = useMutation<
    CreatePromoter,
    CreatePromoterVariables
    >(CREATE_PROMOTER, {
    onError: (err) => console.error(err),
  });

  return {
    createPromoter,
    loading,
    error,
  };
};
