import {useMutation} from "@apollo/client";
import {
  CreateExpert,
  CreateExpertVariables
} from "../../../graphql/mutations/experts/createExpert/__generated__/CreateExpert";
import {CREATE_EXPERT} from "../../../graphql/mutations/experts/createExpert";
import {GET_EXPERTS} from "../../../graphql/queries/experts/getExperts";

export const useCreateExpert = () => {
  const [create, { loading, error }] = useMutation<
    CreateExpert,
    CreateExpertVariables
    >(CREATE_EXPERT, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_EXPERTS,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "latest",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  return {
    create,
    loading,
    error,
  };
};