import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  CREATE_COMPANY,
  DELETE_COMPANY,
  GET_COMPANIES,
  UPDATE_COMPANY,
} from "../../../../graphql";
import {
  GetCompaniesFilter,
  PaginationOptionsArgs,
} from "../../../../graphql/globalTypes";
import {
  CreateCompany,
  CreateCompanyVariables,
} from "../../../../graphql/mutations/company/createCompany/__generated__/CreateCompany";
import {
  UpdateCompany,
  UpdateCompanyVariables,
} from "../../../../graphql/mutations/company/updateCompany/__generated__/UpdateCompany";
import { GetCompanies_getCompanies_companies } from "../../../../graphql/queries/company/getCompanies/__generated__/GetCompanies";
import * as Yup from "yup";
import { MyTextField } from "../../../../components";
import { Add, Remove } from "@material-ui/icons";
import {
  DeleteCompany,
  DeleteCompanyVariables,
} from "../../../../graphql/mutations/company/deleteCompany/__generated__/DeleteCompany";

interface EditCompanyProps {
  open: boolean;
  onClose: () => void;
  company: GetCompanies_getCompanies_companies | null;
  paginationOptions: PaginationOptionsArgs;
  filter: GetCompaniesFilter;
}

export const EditCompany: React.FC<EditCompanyProps> = ({
  open,
  onClose,
  company,
  paginationOptions,
  filter,
}) => {
  const { t } = useTranslation();
  const [create, { loading, error }] = useMutation<
    CreateCompany,
    CreateCompanyVariables
  >(CREATE_COMPANY, {
    onCompleted: (res) => {
      if (res.createCompany.company) {
        onClose();
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COMPANIES,
        variables: {
          paginationOptions,
          filter,
        },
      },
    ],
  });
  const [update, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateCompany,
    UpdateCompanyVariables
  >(UPDATE_COMPANY, {
    onCompleted: (res) => {
      if (res.updateCompany.company) {
        onClose();
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COMPANIES,
        variables: {
          paginationOptions,
          filter,
        },
      },
    ],
  });
  const [deleteCompany, { loading: deleteLoading }] = useMutation<
    DeleteCompany,
    DeleteCompanyVariables
  >(DELETE_COMPANY, {
    onCompleted: () => {
      onClose();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COMPANIES,
        variables: {
          paginationOptions,
        },
      },
    ],
  });

  interface FormValues {
    name: string;
    nameAbbreviations: string[];
    salaryFactor: number;
  }

  const initialValues: FormValues = {
    name: company?.name ?? "",
    nameAbbreviations: company?.nameAbbreviations ?? [],
    salaryFactor: company?.salaryFactor ?? 1,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Required")),
    salaryFactor: Yup.number()
      .min(0, t("Cannot be less than 0"))
      .required(t("Required")),
  });

  const handleDelete = () => {
    deleteCompany({ variables: { id: company!.id } });
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        {company ? t("Edit Company") : t("Create Company")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          if (!company) {
            const createRes = await create({
              variables: {
                input: {
                  name: values.name,
                  salaryFactor: values.salaryFactor,
                  nameAbbreviations: values.nameAbbreviations,
                },
              },
            });

            if (createRes.data?.createCompany.error) {
              setErrors({ name: t("NAME_ALREADY_EXISTS") });
            }
          } else {
            const updateRes = await update({
              variables: {
                input: {
                  id: company.id,
                  ...(values.name !== company.name && {
                    name: values.name,
                  }),
                  ...(values.salaryFactor !== company.salaryFactor && {
                    salaryFactor: values.salaryFactor,
                  }),

                  nameAbbreviations: values.nameAbbreviations,
                },
              },
            });

            if (updateRes.data?.updateCompany.error) {
              setErrors({ name: t("NAME_ALREADY_EXISTS") });
            }
          }

          setSubmitting(false);
        }}
      >
        {({ dirty, submitForm, values }) => (
          <Form>
            <>
              <DialogContent>
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("name")}
                  name="name"
                />
                <Box height="40px" />

                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("salaryFactor")}
                  name="salaryFactor"
                  type="number"
                />
                <Box height="40px" />

                <Typography style={{ marginBottom: "10px" }}>
                  {t("nameAbbreviations")}
                </Typography>

                <FieldArray
                  name="nameAbbreviations"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.nameAbbreviations &&
                      values.nameAbbreviations.length > 0 ? (
                        values.nameAbbreviations.map((_name, index) => (
                          <Box
                            key={index}
                            display={"flex"}
                            marginBottom={"20px"}
                          >
                            <Field
                              component={MyTextField}
                              fullWidth
                              name={`nameAbbreviations.${index}`}
                            />
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Remove />
                            </IconButton>
                            <IconButton
                              onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        ))
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {t("Add abbreviation")}
                        </Button>
                      )}
                    </Box>
                  )}
                />
                <Box height="40px" />
              </DialogContent>
              <DialogActions>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent={"space-between"}
                >
                  {company && (
                    <>
                      <Button
                        disabled={loading || deleteLoading || updateLoading}
                        onClick={handleDelete}
                      >
                        {t("Delete company")}
                      </Button>
                      {Boolean(deleteLoading) && (
                        <CircularProgress style={{ marginLeft: "10px" }} />
                      )}
                    </>
                  )}

                  <Box display="flex" justifyContent={"center"}>
                    <Button onClick={onClose}>{t("Close")}</Button>
                    <Button
                      disabled={loading || deleteLoading || updateLoading}
                      onClick={submitForm}
                    >
                      {t(company ? "Save" : "Create")}
                      {Boolean(loading || updateLoading) && (
                        <CircularProgress style={{ marginLeft: "10px" }} />
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
