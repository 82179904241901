import { gql } from "@apollo/client";

export const GET_TAGS = gql`
  query GetAdminTags {
    getAdminTags {
      title
      id
      isRemote
    }
  }
`;
