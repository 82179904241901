import {useQuery, useReactiveVar} from "@apollo/client";
import {Typography} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {drawerOpenVar, enumsVar} from "../../../apollo";
import {SelectButton, TextSearch} from "../../../components";
import {FETCH_JOB_FILTERS} from "../../../graphql";
import {AdminGetJobsInput, PublishStatus} from "../../../graphql/globalTypes";
import {
  FetchEnums_fetchEnums_employmentTypes as IEnum
} from "../../../graphql/queries/jobs/FetchEnums/__generated__/FetchEnums";
import {
  FetchJobFilters
} from "../../../graphql/queries/jobs/FetchJobFilters/__generated__/FetchJobFilters";
import {JobSource, ReadAccess} from "../../../lib";
import {convertEnumToArray} from "../../../utils";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Box} from "@mui/system";
import {Button} from "@mui/material";
import moment from "moment-timezone";

interface JobsFilterBarProps {
  filterParams: AdminGetJobsInput;
  setFilterParams: React.Dispatch<React.SetStateAction<AdminGetJobsInput>>;
  jobsCount?:number;
}

export const JobsFilterBar: React.FC<JobsFilterBarProps>
  = ({filterParams, setFilterParams, jobsCount}) => {

  const drawerOpen = useReactiveVar(drawerOpenVar);
  const enums = useReactiveVar(enumsVar);
  const {t} = useTranslation();
  const {data, loading: filtersLoading} =
    useQuery<FetchJobFilters>(FETCH_JOB_FILTERS);
  const [showAllFilters, setShowAllFilters] = useState(false)

  const handleFilterChange = (
    key: keyof AdminGetJobsInput,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  const handleCountryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const countryId =
      event.target.value === "all"
        ? undefined
        : event.target.value === "Not specified"
          ? event.target.value
          : data!.fetchJobFilters.countries.find(
            (c) => c.rusTrans === event.target.value
          )!.id;

    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          countryId: undefined,
        }
        : {
          countryId: countryId,
        }),
    }));
  };

  const handleAuthorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const authorId =
      event.target.value === "all"
        ? undefined
        : event.target.value === "Not specified"
          ? event.target.value
          : data!.fetchJobFilters.authors?.find(
            (c) => c.email === event.target.value
          )!.id;

    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          authorId: undefined,
        }
        : {
          authorId: authorId,
        }),
    }));
  };

  const handleEnumChange = useCallback(
    (
      key: keyof AdminGetJobsInput,
      event: React.ChangeEvent<{ value: unknown }>,
      searchArr: IEnum[]
    ) => {
      const curr = event.target.value as string;

      setFilterParams((prev) => ({
        ...prev,
        ...(curr === "all"
          ? {
            [key]: undefined,
          }
          : {
            [key]:
              curr === "Not specified"
                ? curr
                : searchArr.find(
                  (en) => en.name.toLowerCase() === curr.toLowerCase()
                )!.id,
          }),
      }));
    },
    []
  );

  const getNameById = useCallback((id: string, searchArr: IEnum[]) => {
    return id === "Not specified"
      ? id
      : searchArr.find((e) => e.id === id)!.name;
  }, []);

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  const getCountryValue = (id: undefined | string | "Not specified") => {
    if (id === undefined || id === "all") return "all";
    if (id === "Not specified") return "Not specified";
    return data!.fetchJobFilters.countries.find((c) => c.id === id)!.rusTrans!;
  };

  const getAuthorValue = (id: undefined | string | "Not specified") => {
    if (id === undefined || id === "all") return "all";
    if (id === "Not specified") return "Not specified";
    return data!.fetchJobFilters.authors?.find((c) => c.id === id)?.email;
  };

  const handleFromDateChange = (date: MaterialUiPickersDate) => {
    setFilterParams(prev => ({
      ...prev, periodFrom:  moment(date).startOf("day")
    }))
  };

  const handleToDateChange = (date: MaterialUiPickersDate) => {
    setFilterParams(prev => ({
      ...prev, periodTo: moment(date).endOf("day")
    }))
  };

  return (
    <Box
      zIndex={100}
      padding="20px 10px 0"
      display="flex"
      flexWrap="wrap"
      width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
      justifyContent="flex-start"
    >
      <Box display="flex" width="100%" gap="10px">

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Typography style={{fontWeight:600}}>{t("period")}:</Typography>
          <Box height="10px"/>
          <Box display="flex" gap="10px">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              id="date-picker-inline"
              label={<div style={{marginTop:"-10px"}}>С</div>}
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 40
                }
              }}
              value={filterParams.periodFrom}
              onChange={handleFromDateChange}
              inputVariant="outlined"
            />
            <KeyboardDatePicker
              disableToolbar
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 40
                }
              }}
              variant="inline"
              format="DD/MM/yyyy"
              id="date-picker-inline"
              label={<div style={{marginTop:"-10px"}}>По</div>}
              value={filterParams.periodTo}
              onChange={handleToDateChange}
              inputVariant="outlined"
            />
          </Box>
        </MuiPickersUtilsProvider>
        <SelectButton
          densed
          onChange={(e) => handleAuthorChange(e)}
          value={getAuthorValue(filterParams.authorId ?? "all") ?? "all"}
          options={
            data?.fetchJobFilters.authors
              ? [
                ...data.fetchJobFilters.authors.map((a) => a.email),
                "all",
              ]
              : ["all"]
          }
          rootStyle={{flexBasis: "25%"}}
          label={t("author")}
          noOptionTranslation
          loading={filtersLoading}
        />
        <TextSearch
          isSmall
          rootStyle={{flexBasis: "40%"}}
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"SourceId, title, industries, company"}
        />
        <Button
          style={{width:"200px"}}
          variant="outlined"
          onClick={() => setShowAllFilters(prev => !prev)}>
          {showAllFilters ? t("Hide") :t("More filters")}
        </Button>
      </Box>

      {showAllFilters &&
        <>
          <Box height="20px" width="100%"/>
          <SelectButton
          squared
          densed
          onChange={(e) => handleFilterChange("readAccess", e)}
          value={filterParams.readAccess ?? "all"}
          options={[...convertEnumToArray(ReadAccess), "all"]}
          rootStyle={{flexBasis: "25%"}}
          label={t("Access")}
        />
          <SelectButton
            squared
            densed
            onChange={(e) => handleFilterChange("status", e)}
            value={filterParams.status ?? "all"}
            options={[...convertEnumToArray(PublishStatus), "all"]}
            rootStyle={{flexBasis: "25%"}}
            label={t("Status")}
          />
          <SelectButton
            squared
            densed
            onChange={(e) => handleFilterChange("sourceType", e)}
            value={filterParams.sourceType ?? "all"}
            options={[...convertEnumToArray(JobSource), "all"]}
            rootStyle={{flexBasis: "25%"}}
            label={t("Source Type")}
            noOptionTranslation
          />
          <SelectButton
            squared
            densed
            onChange={(e) => handleCountryChange(e)}
            value={getCountryValue(filterParams.countryId ?? "all")}
            options={
              data?.fetchJobFilters
                ? [
                  "Not specified",
                  ...data.fetchJobFilters.countries.map((c) => c.rusTrans ?? ""),
                  "all",
                ]
                : ["Not specified", "all"]
            }
            rootStyle={{flexBasis: "25%"}}
            label={t("Country")}
            noOptionTranslation
            loading={filtersLoading}
          />

          <Box flexBasis={"100%"}/>

          {enums && (
            <>
              <SelectButton
                squared
                densed
                onChange={(e) =>
                  handleEnumChange("jobCategory", e, enums.jobCategories)
                }
                value={
                  filterParams.jobCategory
                    ? getNameById(filterParams.jobCategory, enums.jobCategories)
                    : "all"
                }
                options={[
                  "Not specified",
                  ...enums.jobCategories.map((f) => f.name),
                  "all",
                ]}
                rootStyle={{flexBasis: "25%"}}
                label={t("Job Categories")}
                noOptionTranslation
              />

              <SelectButton
                squared
                densed
                onChange={(e) =>
                  handleEnumChange("employmentType", e, enums.employmentTypes)
                }
                value={
                  filterParams.employmentType
                    ? getNameById(
                      filterParams.employmentType,
                      enums.employmentTypes
                    )
                    : "all"
                }
                options={[
                  "Not specified",
                  ...enums.employmentTypes.map((f) => f.name),
                  "all",
                ]}
                rootStyle={{flexBasis: "25%"}}
                label={t("Employment Type")}
                noOptionTranslation
              />

              <SelectButton
                squared
                densed
                onChange={(e) =>
                  handleEnumChange("seniority", e, enums.seniorities)
                }
                value={
                  filterParams.seniority
                    ? getNameById(filterParams.seniority, enums.seniorities)
                    : "all"
                }
                options={[
                  "Not specified",
                  ...enums.seniorities.map((f) => f.name),
                  "all",
                ]}
                rootStyle={{flexBasis: "25%"}}
                label={t("Seniorities")}
                noOptionTranslation
              />
            </>
          )}</>}
      {filterParams.authorId &&
        <Box marginTop="10px" display="flex" alignItems="center" width="100%" gap="10px">
          <Box>
            <Box fontWeight="600">{data?.fetchJobFilters.authors?.find(a => a.id === filterParams.authorId)?.firstName}</Box>
            <Box fontSize="0.8em">{data?.fetchJobFilters.authors?.find(a => a.id === filterParams.authorId)?.email}</Box>
          </Box>
          {
            (filterParams.periodFrom || filterParams.periodTo)
            &&
            <Box display="flex" gap="10px">
              <Box>в период</Box>
              {filterParams.periodFrom && <Box fontWeight="600">с {moment(filterParams.periodFrom).format("DD.MM.YYYY")}</Box>}
              {filterParams.periodTo && <Box fontWeight="600">по {moment(filterParams.periodTo).format("DD.MM.YYYY")}</Box>}
            </Box>
          }
          <Box>
            добавил <span style={{color:"red"}}>{jobsCount ?? "...Loading..."}</span> вакансий
          </Box>

        </Box>}
    </Box>
  );
};
