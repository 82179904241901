import { gql } from "@apollo/client";
import { EnumFragment } from "../../..";

export const REPLACE_BULK_ENUM = gql`
  mutation ReplaceBulkEnum($input: ReplaceBulkEnumInput!) {
    replaceBulkEnum(input: $input) {
      error {
        name
        message
      }
      enum {
        ...Enum
      }
    }
  }
  ${EnumFragment}
`;
