import { Box, Fab, Tooltip } from "@material-ui/core";
import {
  ExitToApp as QuitIcon,
  PhoneIphone,
  Save as SaveIcon,
} from "@material-ui/icons";
import type { Value } from "@react-page/editor";
import Editor from "@react-page/editor";
import "@react-page/editor/lib/index.css";
import "@react-page/plugins-slate/lib/index.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCMS } from "../../hooks";
import "./index.css";
import { cellPlugins } from "./plugins";

interface WyziwigProps {
  content: string;
  loading?: boolean;
  handleSaveAndQuit: (e?: Value) => void;
  handleSave?: (e: Value) => void;
  toggleMobile?: () => void;
  isMobile?: boolean;
}

export const Wyziwig: React.FC<WyziwigProps> = ({
  content,
  loading,
  handleSave,
  handleSaveAndQuit,
  toggleMobile,
  isMobile,
}) => {
  const [value, setValue] = useState<Value>(JSON.parse(content));
  const { t } = useTranslation();
  useCMS();

  return (
    <>
      <div>
        <Editor
          readOnly={!Boolean(handleSave)}
          cellPlugins={cellPlugins}
          value={value}
          onChange={setValue}
        />

        <Box
          position="fixed"
          left="10px"
          bottom="20px"
          zIndex="1199"
          display="flex"
          flexDirection="column"
        >
          {toggleMobile && (
            <>
              <Tooltip title={t("Mobile View")!} placement="top">
                <Fab
                  color={isMobile ? "secondary" : "default"}
                  size="large"
                  onClick={() => toggleMobile()}
                >
                  <PhoneIphone />
                </Fab>
              </Tooltip>
              <Box height="20px" />
            </>
          )}
          <Tooltip title={t("Save and quit")!} placement="top">
            <Fab
              color="primary"
              size="large"
              onClick={() => handleSaveAndQuit(value)}
              disabled={loading}
            >
              <QuitIcon />
            </Fab>
          </Tooltip>
          <Box height="20px" />

          {!!handleSave && (
            <Tooltip title={t("Save")!} placement="top">
              <Fab
                color="secondary"
                size="large"
                onClick={() => handleSave(value)}
                disabled={loading}
              >
                <SaveIcon />
              </Fab>
            </Tooltip>
          )}
        </Box>
      </div>
    </>
  );
};
