import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateLearningStepInput } from "../../../../graphql/globalTypes";

interface EditNumberOfFreeArticlesProps {
  steps: UpdateLearningStepInput[];
  free: number;
  handleFreeChange: (free: number) => void;
  disabled: boolean;
}

export const EditNumberOfFreeArticles: React.FC<
  EditNumberOfFreeArticlesProps
> = ({ steps, free, handleFreeChange, disabled }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([
    0,
    ...steps.map((s, index) => index + 1),
  ]);

  useEffect(() => {
    setOptions([0, ...steps.map((s, index) => index + 1)]);
  }, [steps]);

  if (!steps.length) return null;

  return (
    <Box display="flex" alignItems={"center"}>
      <Typography>Количество бесплатных статей: </Typography>
      <Box width="7px" />
      <Select
        value={free}
        disabled={disabled}
        onChange={(e) => handleFreeChange(parseInt(e.target.value as any))}
        variant="outlined"
      >
        {options.map((numb) => (
          <MenuItem value={numb} key={numb}>
            {numb}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
