import { Box, Fab, IconButton, Typography } from "@material-ui/core";
import { Close, Edit, SaveOutlined as SaveIcon } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CreateJobInput, UpdateJobInput } from "../../../graphql/globalTypes";
import { useCommonStyle } from "../../../styles/commonStyles";
import { theme } from "../../../styles/theme";

interface EditJobRightProps {
  handleSubmit: () => void;
  disabled: boolean;
  editOpen: boolean;
  setEditOpen: (o: boolean) => void;
  createInput?: CreateJobInput;
  updateInput?: UpdateJobInput;
}

export const EditJobRight: React.FC<EditJobRightProps> = ({
  updateInput,
  createInput,
  handleSubmit,
  disabled,
  editOpen,
  setEditOpen,
}) => {
  const commonClasses = useCommonStyle();
  const { t } = useTranslation();

  const createMarkup = useMemo(() => {
    const description = createInput?.description ?? updateInput!.description;


    if (description) return { __html: description };

    return {
      __html: `<p style="margin: 0; color: #8c8c8c">${t(
        "No description yet"
      )}</p>`,
    };
  }, [createInput, updateInput]);

  const toggleOpen = () => {
    setEditOpen(!editOpen);
  };

  const no_category_id = "61a6186ddc8716162fe4a395";
  const all_filled_correctly = !updateInput ||
  (updateInput.location != null && updateInput.location.countryId != null && updateInput.location.countryId  !== "" && 
  updateInput.jobCategories != null && updateInput.jobCategories.length > 0 && updateInput.jobCategories[0] !== no_category_id);
  
  return (
    <Box className={commonClasses.sectionWithSticky__sticky}>
      <Box justifyContent="space-between" display="flex">
        <Typography variant="overline">{t("Job Description")}</Typography>
        <IconButton color="primary" onClick={toggleOpen}>
          {editOpen ? <Close fontSize="small" /> : <Edit fontSize="small" />}
        </IconButton>
      </Box>

      <Box
        borderRadius={5}
        boxShadow={theme.shadows[1]}
        padding={2}
        style={{ background: "#fff" }}
        minHeight="200px"
        maxHeight="455px"
        overflow="scroll"
      >
        <div dangerouslySetInnerHTML={createMarkup} />
      </Box>
      {!all_filled_correctly && 
      <><Box alignContent={"right"} justifyContent="right" display="flex" marginBottom={"10px"}
      style={{
            fontWeight: "bold",
            background: "#ee7d7d",
            borderRadius: "3px",
            padding: "2px 5px",
            width: "fit-content",
            fontSize: " 1.5rem",
          }}>
      <Typography variant="caption">Чтобы обновить вакансию надо обязательно выбрать страну и категорию - в том числе проверяйте рабочаю ссылку и нажмите на редакцию описание!<br/>
      Ещё необходимо уточнить что нет прямой ссылки на Impactpool.org - надо зайти и поменять.</Typography>
      </Box>
      <Fab
        color="secondary"
        variant="extended"
        size="large"
        style={{ width: "50%" }}
        id="bottomBtn"
        onClick={handleSubmit}
        disabled={true}
      >
        <SaveIcon style={{ marginRight: 10 }} />
        {t("Save")}
      </Fab>
      </>
      }
      {all_filled_correctly && <Fab
        color="secondary"
        variant="extended"
        size="large"
        style={{ width: "50%" }}
        id="bottomBtn"
        onClick={handleSubmit}
        disabled={disabled && !all_filled_correctly}
      >
        <SaveIcon style={{ marginRight: 10 }} />
        {t("Save")}
      </Fab>}
    </Box>
  );
};


function validURL(str:string) : boolean {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}