import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: "#BBDEFB",
      main: "#2196F3",
      dark: "#1976D2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f4a500",
      contrastText: "#fff",
    },
    background: {
      default: "#f6f7ff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
    divider: "#e8e8e8",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  mixins: {
    toolbar: {
      minHeight: 72,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        boxShadow: "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
        background: "#fff",
        "& $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 1,
        },
        "&:hover $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 1,
        },
        "&$focused $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 1,
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        "& .MuiSvgIcon-root": {
          fill: "#b5b5b5",
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 55,
      },
    },
    MuiAvatar: {
      colorDefault: {
        color: "#2196F3",
        backgroundColor: " #ffffff",
      },
    },
    MuiFab: {
      extended: {
        textTransform: "unset",
        borderRadius: "14px",
      },
    },
    MuiDialog: {
      paper: {
        background: "#f6f7ff",
      },
    },
    MuiButton: {
      root: {
        textTransform: "unset",
      },
      contained: {
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#f6f7fe",
        },
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: "#ffffff",
        borderBottom: "1px solid #dddd",
        cursor: "default",
      },
    },
    MuiInput: {
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1200,
      },
    },
    MuiDrawer: {
      paper: {
        zIndex: 1198,
      },
    },
  },
});
