import React from "react";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {GetAffiliatesInput, PublishStatus} from "../../graphql/globalTypes";
import {SelectButton, TextSearch} from "../../components";
import {convertEnumToArray} from "../../utils";

interface Props {
  filterParams: GetAffiliatesInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetAffiliatesInput>>;
}

export const AffiliatesFilterBar: React.FC<Props>
  = ({
       filterParams,
       setFilterParams,
     }) => {
  const {t} = useTranslation();
  const handleFilterChange = (
    key: keyof GetAffiliatesInput,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <TextSearch
          isSmall
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"name, email"}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectButton
          densed
          onChange={(e:any) => handleFilterChange("status", e)}
          value={filterParams.status ?? "all"}
          options={[...convertEnumToArray(PublishStatus), "all"]}
          rootStyle={{width:"100%"}}
          label={t("Status")}
        />
      </Grid>
    </Grid>
  );
};

