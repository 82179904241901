import { useReactiveVar } from "@apollo/client";
import { Box, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { drawerOpenVar, headerTitleVar } from "../../apollo";
import { useLayoutStyle } from "../styles";
import { HeaderNotification } from "./HeaderNotification";
import { HeaderProfile } from "./HeaderProfile";

export const HeadToolbar: React.FC = ({}) => {
  const classes = useLayoutStyle();
  const drawerOpen = useReactiveVar(drawerOpenVar);

  return (
    <Toolbar>
      <IconButton
        color="inherit"
        onClick={() => drawerOpenVar(true)}
        edge="start"
        className={clsx(classes.dl_menuButton, {
          [classes.hide]: drawerOpen,
        })}
      >
        <MenuIcon />
      </IconButton>

      <Typography variant="h6" noWrap>
        {useReactiveVar(headerTitleVar)}
      </Typography>

      <Box className={classes.grow} />

      <HeaderNotification />

      <HeaderProfile />
    </Toolbar>
  );
};
