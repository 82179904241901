import { useMutation } from "@apollo/client";
import { DUPLICATE_ARTICLE } from "../../graphql";
import {
  DuplicateArticle,
  DuplicateArticleVariables,
} from "../../graphql/mutations/articles/DuplicateArticle/__generated__/DuplicateArticle";

export const useDuplicateArticle = () => {
  const [duplicate, { loading, error }] = useMutation<
    DuplicateArticle,
    DuplicateArticleVariables
  >(DUPLICATE_ARTICLE, {
    onError: (err) => console.error(err),
  });

  return {
    duplicate,
    loading,
    error,
  };
};
