import { Builder } from "@builder.io/react";
import { Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { AtomProgress } from "../../..";
import { useCommonStyle } from "../../../../styles/commonStyles";
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

interface PDFUploaderProps {
  upload: any;
}

export const PDFUploader: React.FC<PDFUploaderProps> = ({ upload }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<number>(0);
  const classes = useCommonStyle();

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handlePaginationChange = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="hidden"
        width="100%"
      >
        <Box
          marginBottom={2}
          overflow="hidden"
          // className={classes.pdfViewerWrapper}
        >
          <Document
            file={upload}
            onLoadError={(e) => console.error(e)}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<AtomProgress />}
            renderMode="canvas"
            externalLinkTarget="_blank"
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
        <Pagination
          count={numPages}
          onChange={(_, page) => handlePaginationChange(page)}
          color="standard"
        />
      </Box>
    </>
  );
};

Builder.registerComponent(PDFUploader, {
  name: "PDFUploader",
  inputs: [
    {
      name: "upload",
      type: "file",
      allowedFileTypes: ["pdf"],
    },
  ],
});
