import { useReactiveVar } from "@apollo/client";
import { Box, Button } from "@material-ui/core";
import { KeyboardArrowRight as ToIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import { userVar } from "../../apollo";
import { AtomProgress, NotificationToast } from "../../components";
import {
  GetUsersInput,
  PaginationOptionsArgs,
} from "../../graphql/globalTypes";
import { GetUsers_getUsers_users as IUser } from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {
  useGetUserGeography,
  useGetUsers,
  useSetHeadbarTitle,
} from "../../hooks";
import { UserRole } from "../../lib";
import { AddNewUser, UsersFilterBar, UsersTable } from "./components";

export const Users: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Users"));
  useGetUserGeography();
  const authUser = useReactiveVar(userVar);

  const [users, setUsers] = useState<IUser[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    roles: undefined,
    isFellow: undefined,
    textSearch:undefined
  });
  const location = useLocation<{showCreateNotification:boolean, message:string}>();
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      },
    });
  const { data, error, loading } = useGetUsers({
    input: filterParams,
    paginationOptions,
  });

  useEffect(() => {
    if (data) {
      setTotalUsers(data.getUsers.total);
      setUsers(data.getUsers.users);
    }
  }, [data]);

  useEffect(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      skip: 0,
    }));
  }, [filterParams]);

  return (
    <>
      <UsersFilterBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />

      <Box padding={3} marginTop={4}>
        {loading && (
          <Box
            position="absolute"
            marginTop="5px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <Box paddingX={2} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => history.push("/user-preferences")}
        >
          <ToIcon />
          {t("To preferences")}
        </Button>

        {Boolean(
          authUser &&
            [UserRole.GLOBAL_ADMIN, UserRole.ADMIN].includes(
              authUser.role as UserRole
            )
        ) && <AddNewUser />}
      </Box>

      <Box marginY={2} />

      <UsersTable
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        users={users}
        totalUsers={totalUsers}
        loading={loading}
      />

      {location?.state?.showCreateNotification && (
        <NotificationToast
          message={t(location.state.message)}
          horizontal="center"
          severity="info"
          vertical={"bottom"}
        />
      )}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
