import { useMutation } from "@apollo/client";
import { DELETE_JOB_CATEGORY, GET_JOB_CATEGORIES } from "../../graphql";
import {
  DeleteJobCategory,
  DeleteJobCategoryVariables,
} from "../../graphql/mutations/jobs/DeleteJobCategory/__generated__/DeleteJobCategory";

export const useDeleteJobCategory = () => {
  const [deleteCategory, { data, loading, error }] = useMutation<
    DeleteJobCategory,
    DeleteJobCategoryVariables
  >(DELETE_JOB_CATEGORY, {
    onError: (err) => console.log(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_JOB_CATEGORIES,
      },
    ],
  });

  return {
    deleteCategory,
    data,
    loading,
    error,
  };
};
