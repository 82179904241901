import { useMutation } from "@apollo/client";
import { ADD_ENUM, FETCH_ENUMS_WITH_STATS } from "../../graphql";
import {
  AddEnum,
  AddEnumVariables,
} from "../../graphql/mutations/jobs/AddEnum/__generated__/AddEnum";

export const useAddEnum = () => {
  const [add, { loading, error }] = useMutation<AddEnum, AddEnumVariables>(
    ADD_ENUM,
    {
      onError: (err) => console.error(err),
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: FETCH_ENUMS_WITH_STATS,
        },
      ],
    }
  );

  return {
    add,
    loading,
    error,
  };
};
