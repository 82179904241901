import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      error {
        name
        message
      }
      user {
        ...User
      }
    }
  }
  ${UserFragment}
`;
