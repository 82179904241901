import {gql} from "@apollo/client";
import {PartnerCompanyFragment} from "../../../fragments";

export const UPDATE_PARTNER_COMPANY = gql`
    mutation UpdatePartnerCompany($input: UpdatePartnerCompanyInput!) {
        updatePartnerCompany(input: $input) {
            error {
                name
                message
            }
            partnerCompany {
                ...PartnerCompany
            }
        }
    }
    ${PartnerCompanyFragment}
`;