import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSetHeadbarTitle } from "../../hooks";
import { useCommonStyle } from "../../styles/commonStyles";
import { LeftSection, RightSection } from "./components";

interface ProfileProps {}

export const Profile: React.FC<ProfileProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("My Profile"));
  const commonClasses = useCommonStyle();

  return (
    <Box className={commonClasses.sectionWithSticky}>
      <LeftSection />

      <RightSection />
    </Box>
  );
};
