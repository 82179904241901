import { gql } from "@apollo/client";
import { CompanyFragment } from "../../../fragments";

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        ...Company
      }
      error {
        name
        message
      }
    }
  }
  ${CompanyFragment}
`;
