import {gql} from "@apollo/client";
import {MetaFragment, PartnerCompanyFragment} from "../../../fragments";

export const GET_PARTNER_COMPANIES = gql`
    query GetPartnerCompanies(
        $input: GetPartnerCompaniesInput!
        $paginationOptions: PaginationOptionsArgs!
    ) {
        getPartnerCompanies(input: $input, paginationOptions: $paginationOptions) {
            meta{
                ...Meta
            }
            total
            partnerCompanies {
                ...PartnerCompany
            }
        }
    }
    ${PartnerCompanyFragment}
    ${MetaFragment}
`;