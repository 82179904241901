import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($email: String!, $userId: String!) {
    updateUserEmail(email: $email, userId: $userId) {
      error {
        name
        message
      }
      user {
        ...User
      }
    }
  }
  ${UserFragment}
`;
