import { Box, Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useUsersStyles } from "../styles";
import { Email } from "./Email";
import { Notifications } from "./Notifications";
import { UpdatePassword } from "./UpdatePassword";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface EditAccountProps {
  user: Me_me;
  isMe?: boolean;
}

export const EditAccount: React.FC<EditAccountProps> = ({ user, isMe }) => {
  const classes = useUsersStyles();
  const { t } = useTranslation();

  return (
    <>
      <Paper className={classes.info__paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textSecondary" variant="h6">
            {t("Account Info")}
          </Typography>
        </Box>

        <Divider style={{ marginBottom: 20 }} />

        <Email user={user} isMe={isMe} />

        {isMe && <Notifications />}

        {isMe && <UpdatePassword user={user} />}
      </Paper>
    </>
  );
};
