import { CellPlugin, lazyLoad } from "@react-page/editor";
import createPlugin from "./createPlugin";
import MuiButtonHtmlRenderer from "./Renderer/MuiButtonHtmlRenderer";
import { MuiButtonSettings } from "./types/settings";
import { MuiButtonState } from "./types/state";

const MuiButtonControls = lazyLoad(
  () => import("./Controls/MuiButtonControls")
);

const muiButtonPlugin: (
  settings?: Partial<MuiButtonSettings>
) => CellPlugin<MuiButtonState> = (settings) =>
  createPlugin({
    Renderer: MuiButtonHtmlRenderer,
    Controls: MuiButtonControls,
    ...settings,
  });

export { muiButtonPlugin };
