import { useReactiveVar } from "@apollo/client";
import { Button } from "@material-ui/core";
import { Publish } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cmsAssetVar } from "../../apollo";
import { makeid } from "../../utils";
import { NotificationToast } from "../notifications";

interface CMSUploadButtonProps {
  resource_type: string;
  onInsert: (url: string, public_id: string) => void;
  style?: React.CSSProperties;
}

export const CMSUploadButton: React.FC<CMSUploadButtonProps> = ({
  resource_type,
  onInsert,
  style,
}) => {
  const id = useMemo(() => makeid(5), []);
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState(false);
  const cmsAsset = useReactiveVar(cmsAssetVar);

  useEffect(() => {
    if (cmsAsset?.callerId === id && cmsAsset.asset) {
      handleInsert(cmsAsset.asset);
    }
  }, [cmsAsset]);

  const handleClick = () => {
    setInvalid(false);
    cmsAssetVar({
      callerId: id,
      asset: null,
    });
    //@ts-ignore
    window.ml.show();
  };

  function handleInsert(asset: any) {
    console.log(asset.resource_type);

    if (asset.resource_type === resource_type) {
      onInsert(asset.secure_url!, asset.public_id!);
    } else {
      setInvalid(true);
    }
  }

  return (
    <>
      <Button
        startIcon={<Publish />}
        variant="contained"
        color="default"
        onClick={handleClick}
        style={style}
      >
        {t("Upload")}
      </Button>

      {invalid && (
        <NotificationToast
          message={t("Invalid format")}
          horizontal="right"
          severity="warning"
          vertical="bottom"
        />
      )}
    </>
  );
};
