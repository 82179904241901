import React from 'react';
import {Box, Paper, styled} from "@material-ui/core";
import {
  useGetCurrencyExchangeRates
} from "../../../hooks/queries/useGetCurrencyExchangeRates";
import {useTranslation} from "react-i18next";


export const ExchangeRates = () => {
  const {data, loading, error} = useGetCurrencyExchangeRates()
  const {t} = useTranslation();
  return (
    <ExchangeWrapper elevation={4}>
      <ExchangeTitle>
        {t("Actual rates")}:
      </ExchangeTitle>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box>RUB/EUR:</Box> <CurrencyTitle>{data?.rubToEur}</CurrencyTitle>
          </Box>
          <Box display="flex">
            <Box>RUB/USD:</Box> <CurrencyTitle>{data?.rubToUsd}</CurrencyTitle>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box>USD/EUR:</Box> <CurrencyTitle>{data?.usdToEur}</CurrencyTitle>
          </Box>
          <Box display="flex">
            <Box>USD/RUB:</Box> <CurrencyTitle>{data?.usdToRub}</CurrencyTitle>
          </Box>
        </Box>
      </Box>
    </ExchangeWrapper>
  );
};

export const ExchangeTitle = styled(Box)(({theme}) => ({
  textTransform: "uppercase",
  fontWeight: 500,
  marginBottom: "5px"
}));

const ExchangeWrapper = styled(Paper)(({theme}) => ({
  width: "300px",
  height: "100px",
  // border: "1px solid grey",
  borderRadius: "5px",
  fontSize: "15px",
  padding: "10px",
}));

const CurrencyTitle = styled(Box)(({theme}) => ({
  fontWeight:500,
  marginLeft:"5px"
}));
