import { useMutation } from "@apollo/client";
import { UPDATE_JOB } from "../../graphql";
import {
  UpdateJob,
  UpdateJobVariables,
} from "../../graphql/mutations/jobs/UpdateJob/__generated__/UpdateJob";

export const useUpdateJob = () => {
  const [update, { loading, error }] = useMutation<
    UpdateJob,
    UpdateJobVariables
  >(UPDATE_JOB, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};
