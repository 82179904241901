import {Box, Card, CardMedia, IconButton,} from "@material-ui/core";
import React from "react";
import noPhoto from "../../../assets/noPhoto.jpg";
import {EditOutlined as EditIcon} from "@material-ui/icons";
import {useCommonStyle} from "../../../styles/commonStyles";
import {useTranslation} from "react-i18next";
import {DeleteButton, NotificationToast} from "../../../components";
import {useIsDesktopView} from "../../../hooks";
import {
  GetExpertCompanies_getExpertCompanies
} from "../../../graphql/queries/experts/getExpertCompanies/__generated__/GetExpertCompanies";
import {
  useDeleteExpertCompany
} from "../../../hooks/mutations/experts/useDeleteExpertCompany";

interface ExpertCompanyCardProps {
  expertCompany: GetExpertCompanies_getExpertCompanies;
  onClick: () => void;
}

export const ExpertCompanyCard: React.FC<ExpertCompanyCardProps> = ({
  expertCompany,
  onClick,
}) => {
  const isDesktopView = useIsDesktopView();
  const commonClasses = useCommonStyle();
  const { t } = useTranslation();
  const { loading, error, deleteExpertCompany } =
    useDeleteExpertCompany(expertCompany);

  async function handleDelete(): Promise<void> {
    await deleteExpertCompany({ variables: { id: expertCompany.id } });
  }

  return (
    <>
      <Card
        style={{ display: "flex", height: "100%", cursor: "default" }}
        className={commonClasses.onHover}
      >
        <Box display="flex" flexDirection="column" flex={2}>

            <Box fontSize="16px" fontWeight="500" padding="10px 10px 10px 20px">
              {expertCompany.name}
            </Box>

          <Box
            display="flex"
            alignItems="center"
            paddingLeft={1}
          >
            <IconButton onClick={onClick}>
              <EditIcon />
            </IconButton>
            <DeleteButton loading={loading} deleteAction={handleDelete} />
          </Box>
        </Box>
        <CardMedia
          component="img"
          height="100px"
          style={{flex: 1 , padding:"5px"}}
          image={
            expertCompany.logo ? expertCompany.logo.url : noPhoto
          }
        />
      </Card>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </>
  );
};
