import { useReactiveVar } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import { Fallback, SelectButton, TextSearch } from "../../../components";
import {
  AppLanguage,
  GetArticlesAdminInput,
  PublishStatus
} from "../../../graphql/globalTypes";
import { useGetArticleCategories } from "../../../hooks";
import { ReadAccess } from "../../../lib";
import { convertEnumToArray } from "../../../utils";

interface ArticlesFilterBarProps {
  filterParams: GetArticlesAdminInput;
  setFilterParams: React.Dispatch<React.SetStateAction<GetArticlesAdminInput>>;
}

export const ArticlesFilterBar: React.FC<ArticlesFilterBarProps> = ({
  filterParams,
  setFilterParams,
}) => {
  const drawerOpen = useReactiveVar(drawerOpenVar);
  const { t } = useTranslation();
  const {
    categories,
    error,
    loading: categoriesloading,
  } = useGetArticleCategories();

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            status: undefined,
          }
        : {
            status: event.target.value as string,
          }),
    }));
  };
  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          language: undefined,
        }
        : {
          language: event.target.value as AppLanguage,
        }),
    }));
  };
  const handleAccessChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            readAccess: undefined,
          }
        : {
            readAccess: event.target.value as string,
          }),
    }));
  };
  const handleCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
            categoriesIds: undefined,
          }
        : {
            categoriesIds: [
              categories!.find(
                (cat) => event.target.value === cat.category.name
              )!.category.id,
            ],
          }),
    }));
  };
  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v,
      }));
    }
  };
  const handleTextSearchReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      textSearch: undefined,
    }));
  };

  const mapCategoryIdToName = useMemo(() => {
    if (
      categories &&
      filterParams.categoriesIds &&
      filterParams.categoriesIds.length
    ) {
      return categories.find(
        (cat) => cat.category.id === filterParams.categoriesIds![0]
      )!.category.name;
    } else {
      return "all";
    }
  }, [filterParams]);

  if (error)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  return (
    <Box
      zIndex={100}
      display="flex"
      width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
      justifyContent="stretch"
      position="fixed"
    >
      <SelectButton
        squared
        onChange={handleStatusChange}
        value={filterParams.status ?? "all"}
        options={[...convertEnumToArray(PublishStatus), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Status")}
      />
      <SelectButton
        squared
        onChange={handleLanguageChange}
        value={filterParams.language ?? "all"}
        options={[...convertEnumToArray(AppLanguage), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Language")}
      />
      <SelectButton
        loading={categoriesloading}
        squared
        noOptionTranslation
        onChange={handleCategoryChange}
        value={mapCategoryIdToName}
        options={[...(categories?.map((c) => c.category.name) ?? []), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Category")}
      />
      <SelectButton
        squared
        onChange={handleAccessChange}
        value={filterParams.readAccess ?? "all"}
        options={[...convertEnumToArray(ReadAccess), "all"]}
        rootStyle={{ flex: 1 }}
        label={t("Access")}
      />
      <TextSearch
        rootStyle={{ flex: 3 }}
        onClick={handleTextSearchChange}
        onReset={handleTextSearchReset}
        hint="Title, tags"
      />
    </Box>
  );
};
