import {useMutation} from "@apollo/client";
import {
  GetPartnerCompanyCategories_getPartnerCompanyCategories
} from "../../graphql/queries/partnerCompanies/getPartnerCompanyCategories/__generated__/GetPartnerCompanyCategories";
import {
  DELETE_PARTNER_COMPANY_CATEGORY
} from "../../graphql/mutations/partnerCompanies/deletePartnerCompanyCategory";
import {
  DeletePartnerCompanyCategory,
  DeletePartnerCompanyCategoryVariables
} from "../../graphql/mutations/partnerCompanies/deletePartnerCompanyCategory/__generated__/DeletePartnerCompanyCategory";

export const useDeletePartnerCompanyCategory = (categoryToDelete: GetPartnerCompanyCategories_getPartnerCompanyCategories) => {
  const [deletePartnerCompanyCategory, { loading, error, data }] = useMutation<
    DeletePartnerCompanyCategory,
    DeletePartnerCompanyCategoryVariables
  >(DELETE_PARTNER_COMPANY_CATEGORY, {
    onError: (err) => console.error(err),
    update: (cache) => {
      cache.modify({
        fields: {
          getPartnerCompanyCategories(existing = [], { readField }) {
            return (<any[]>existing).filter(
              (ref) =>
                categoryToDelete.id !== readField("id", ref)
            );
          },
        },
      });
    },
  });

  return {
    deletePartnerCompanyCategory,
    loading,
    error,
    success: !!data?.deletePartnerCompanyCategory,
  };
};
