import { useMutation } from "@apollo/client";
import { CREATE_ARTICLE, GET_ARTICLES } from "../../graphql";
import {
  CreateArticle,
  CreateArticleVariables,
} from "../../graphql/mutations/articles/CreateArticle/__generated__/CreateArticle";

export const useCreateArticle = () => {
  const [create, { loading, error }] = useMutation<
    CreateArticle,
    CreateArticleVariables
  >(CREATE_ARTICLE, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ARTICLES,
        variables: {
          input: {},
          paginationOptions: {},
        },
      },
    ],
  });

  return {
    create,
    loading,
    error,
  };
};
