import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { userCountriesVar, userNationalitiesVar } from "../../apollo";
import { GET_USERS } from "../../graphql";
import {
  GetUsers,
  GetUsersVariables,
  GetUsers_getUsers_users,
  GetUsers_getUsers_users_location_countryObject,
} from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";

export const useGetUserGeography = () => {
  const exNationalities = useReactiveVar(userNationalitiesVar);
  const exCountries = useReactiveVar(userCountriesVar);
  const [processing, setProcessing] = useState(true);
  const [fetch, { error, data }] = useLazyQuery<GetUsers, GetUsersVariables>(
    GET_USERS,
    {
      onError: (err) => console.error(err),
    }
  );

  useEffect(() => {
    if (!exNationalities.length || !exCountries.length) {
      fetch({
        variables: {
          input: {},
          paginationOptions: {},
        },
      });
    } else {
      setProcessing(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      fillUpExGeography(data.getUsers.users);
      setProcessing(false);
    }
  }, [data]);

  const fillUpExGeography = useCallback((users: GetUsers_getUsers_users[]) => {
    const nats: string[] = [];
    const countries: GetUsers_getUsers_users_location_countryObject[] = [];
    users.forEach((u) => {
      if (u.nationality && !nats.includes(u.nationality)) {
        nats.push(u.nationality);
      }
      if (
        u.location?.countryObject &&
        !countries
          .map((c) => c.country)
          .includes(u.location.countryObject.country)
      ) {
        countries.push(u.location.countryObject);
      }
    });
    userCountriesVar(countries);
    userNationalitiesVar(nats);
  }, []);

  return {
    error,
    loading: processing,
  };
};
