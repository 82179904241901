import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { UpdateLearningStepInput } from "../../../../graphql/globalTypes";

interface EditOrderProps {
  steps: UpdateLearningStepInput[];
  section: "list" | "order";
  setSection: React.Dispatch<React.SetStateAction<"list" | "order">>;
  handleOrderSubmit: () => Promise<void>;
  loading: boolean;
}

export const EditOrder: React.FC<EditOrderProps> = ({
  steps,
  section,
  setSection,
  handleOrderSubmit,
  loading,
}) => {
  const { t } = useTranslation();

  if (steps.length < 2) return null;

  return (
    <>
      {section === "list" ? (
        <Button onClick={() => setSection("order")} variant="contained">
          Изменить порядок
        </Button>
      ) : (
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleOrderSubmit}
        >
          Сохранить
        </Button>
      )}
    </>
  );
};
