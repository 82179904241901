import type { CellPlugin } from "@react-page/editor";
import { defaultSettings } from "./default/settings";
import { MuiIconSettings } from "./types/settings";
import { MuiIconState } from "./types/state";

const createPlugin = (settings?: MuiIconSettings): CellPlugin<MuiIconState> => {
  const mergedSettings = { ...defaultSettings, ...settings };
  const Controls = mergedSettings.Controls;

  return {
    controls: {
      type: "custom",
      Component: (props) => (
        <Controls {...props} translations={mergedSettings.translations!} />
      ),
    },
    Renderer: mergedSettings.Renderer,
    id: "ory/sites/plugin/content/mui-icon",
    version: 1,
    title: mergedSettings.translations!.pluginName,
    description: mergedSettings.translations!.pluginDescription,
    icon: mergedSettings.icon,
    isInlineable: mergedSettings.isInlineable,
    allowClickInside: true,
  };
};

export default createPlugin;
