import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { Fallback, FullscreenLoader } from "../../components";
import { useCheckVerificationLink } from "../../hooks";
import { Header, ResetPasswordForm } from "./components";

export const PasswordReset: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams<ParamsProps>();
  const { linkError, loading, serverError } = useCheckVerificationLink(token);

  if (loading) return <FullscreenLoader />;

  if (serverError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  if (linkError === "invalid") {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  const linkErrorMarkup = (
    <Typography variant="caption">{t("Verification Link Expired")}</Typography>
  );

  return (
    <Box padding={2} position="relative" textAlign="center">
      <Header />
      <Box paddingY={3}>
        {linkError ? linkErrorMarkup : <ResetPasswordForm token={token} />}
      </Box>
    </Box>
  );
};

interface ParamsProps {
  token: string;
}
