import { ApolloClient, InMemoryCache } from "@apollo/client";
import { apolloLink } from "./apolloLink";

export const apolloClient = new ApolloClient({
  credentials: "include",
  link: apolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          project: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
