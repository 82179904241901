import { gql } from "@apollo/client";
import {ProductFragment} from "../../../fragments";

export const GET_PRODUCT_BY_ID = gql`
    query GetProductById($id: String!) {
        getProductById(id: $id) {
            ...Product
        }
    }
    ${ProductFragment}
`;