import { gql } from "@apollo/client";
import { JobFragment } from "../../../fragments";

export const GET_JOB = gql`
  query GetJob($id: String!) {
    getJob(id: $id) {
      ...Job
    }
  }
  ${JobFragment}
`;
