import React from "react";
import { AtomProgress } from "./AtomProgress";
import { useLoaderStyles } from "./styles";

interface FullscreenLoaderProps {
  transparent?: boolean;
}

export const FullscreenLoader: React.FC<FullscreenLoaderProps> = ({
  transparent,
}) => {
  const classes = useLoaderStyles();

  return (
    <div
      className={
        transparent ? `${classes.root} ${classes.transparent}` : classes.root
      }
    >
      <div className={classes.container}>
        <AtomProgress />
      </div>
    </div>
  );
};
