import {useMutation} from "@apollo/client";
import {
  UpdateExpertCompany,
  UpdateExpertCompanyVariables
} from "../../../graphql/mutations/experts/updateExpertCompany/__generated__/UpdateExpertCompany";
import {UPDATE_EXPERT_COMPANY} from "../../../graphql";

export const useUpdateExpertCompany = () => {
  const [update, { loading: uLoading }] = useMutation<
    UpdateExpertCompany,
    UpdateExpertCompanyVariables
    >(UPDATE_EXPERT_COMPANY, {
    onError: (err) => console.error(err),
  });

  return { update, uLoading };
};