import { gql } from "@apollo/client";
import { SubscriptionPlanFragment } from "../../../fragments";

export const UODATE_SUBSCRIPTION_PLAN = gql`
  mutation UpdateSubscriptionPlan($input: UpdateSubscriptionPlanInput!) {
    updateSubscriptionPlan(input: $input) {
      ...SubscriptionPlan
    }
  }
  ${SubscriptionPlanFragment}
`;
