import {useReactiveVar} from "@apollo/client";
import {Box, Link} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {userVar} from "../../../apollo";
import {SimpleTable} from "../../../components/tables/SimpleTable";
import {PaginationOptionsArgs} from "../../../graphql/globalTypes";
import {HeadCell} from "../../../lib";
import moment from "moment-timezone";
import {
  GetPromotersList_getPromotersList_promoters
} from "../../../graphql/queries/promoters/getPromotersList/__generated__/GetPromotersList";

interface PromotersTableProps {
  loading: boolean;
  promoters: GetPromotersList_getPromotersList_promoters[];
  totalPromoters: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const PromotersTable: React.FC<PromotersTableProps> =
  ({
     loading,
     promoters,
     totalPromoters,
     paginationOptions,
     setPaginationOptions,
   }) => {
    const {t} = useTranslation();
    const history = useHistory();
    const authUser = useReactiveVar(userVar);

    const goToUser = (id: string) => {
      if (authUser?.id === id) {
        history.push("/profile");
      } else {
        history.push(`/promoters/${id}`);
      }
    };

    const mapUsersToRows = useMemo(() => {
      const arr = promoters.map(({
                                  promoter,
                                  accumulatedIncome,
                                  projectedIncome,
                                  payoutCurrency
                                }) => ({
        key: promoter.id,
        id: promoter.id,
        login: promoter.partnerSettings?.username,
        name: `${promoter.firstName} ${promoter.lastName}`,
        email: (
          <Link
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={`mailto:${promoter.email}`}
          >
            {promoter.email}
          </Link>
        ),
        role: t(`${promoter.role}`),
        partnerStatus:
          promoter.partnerSettings?.isDeactivated
            ? <Box color="red">{t("blocked")}</Box>
            : <Box color="#3CB371">{t("active")}</Box>,
        accumulatedIncome:
          <Box>
            <Box fontWeight="500">{accumulatedIncome?.amount}</Box>
            <Box fontSize="11px">
              {`${moment(accumulatedIncome?.dateFrom).format("DD.MM.YYYY")} - ${moment(accumulatedIncome?.dateTo).format("DD.MM.YYYY")}`}
            </Box>
          </Box>,
        projectedIncome:
          <Box>
            <Box fontWeight="500">{projectedIncome?.amount}</Box>
            <Box fontSize="11px">
              {`${moment(projectedIncome?.dateFrom).format("DD.MM.YYYY")} - ${moment(projectedIncome?.dateTo).format("DD.MM.YYYY")}`}
            </Box>
          </Box>,
        currency: payoutCurrency ?? "не указано"
      }));
      return arr;
    }, [promoters]);

    return (
      <>
        <Box width="100%">
          <SimpleTable
            loading={loading}
            total={totalPromoters}
            paginationOptions={paginationOptions}
            setPaginationOptions={setPaginationOptions}
            headCells={headCells}
            values={mapUsersToRows}
            sortFields={[]}
            onRowClick={goToUser}
          />
        </Box>
      </>
    );
  };

const headCells: HeadCell[] = [
  {
    label: "login",
    align: "left",
  },
  {
    label: "name",
    align: "left",
  },
  {
    label: "email",
    align: "left",
  },
  {
    label: "role",
    align: "left",
  },
  {
    label: "partnerStatus",
    align: "left",
  },
  {
    label: "accumulatedIncome",
    align: "center",
  },
  {
    label: "projectedIncome",
    align: "center",
  },
  {
    label: "currency",
    align: "center",
  },
];

export default PromotersTable;