import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {Field, Form, Formik, FormikErrors} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {MyTextField, NotificationToast,} from "../../../components";
import {
  useCreateExpertRequest
} from "../../../hooks/mutations/experts/useCreateExpertRequest";
import {
  GetExpertRequests_getExpertRequests
} from "../../../graphql/queries/experts/getExpertRequests/__generated__/GetExpertRequests";
import {
  useUpdateExpertRequest
} from "../../../hooks/mutations/experts/useUpdateExpertRequest";

interface EditExpertRequestProps {
  expertRequest: GetExpertRequests_getExpertRequests | null;
  open: boolean;
  onClose: () => void;
}

export const EditExpertRequest: React.FC<EditExpertRequestProps> = ({
                                                                      expertRequest,
                                                                      open,
                                                                      onClose,
                                                                    }) => {
  const {t} = useTranslation();
  const [enableValidation, setEnableValidation] = useState(false);
  const [serverError, setServerError] = useState(null);
  const {update, uLoading} = useUpdateExpertRequest();
  const {create, loading} = useCreateExpertRequest();

  useEffect(() => {
    if (!open) {
      setServerError(null);
    }
  }, [open]);

  const initialValues: FormValues = {
    title: expertRequest?.title ?? "",
    category: expertRequest?.category ?? "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t("Required")),
    category: Yup.string().required(t("Required")),
  });

  async function handleSubmit(
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    try {
      if (!expertRequest) {
        const res = await create({
          variables: {input: values},
        });
        if (res.data?.createExpertRequest.error) {
          setErrors({title: t("Request already exists")});
        } else {
          onClose();
        }
      } else {
        const {title, category} = values
        const res = await update({
          variables: {
            input: {
              id: expertRequest.id,
              title, category
            },
          },
        });
        if (res.data?.updateExpertRequest.error) {
          setErrors({title: t("Request already exists")});
        } else {
          onClose();
        }
      }
    } catch (err) {
      setServerError(err as any);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {expertRequest ? t("Edit expert request") : t("Add expert request")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          await handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({values, dirty, submitForm}) => (
          <Form>
            <>
              <DialogContent>
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Category")}
                  name="category"
                  style={{marginBottom: 20}}
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Title")}
                  name="title"
                  style={{marginBottom: 20}}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={loading || uLoading} onClick={onClose}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={
                    ((!values.title || !dirty) &&
                      (!values.title)) ||
                    loading ||
                    uLoading
                  }
                >
                  {t("Save")}
                  {(loading || uLoading) && (
                    <CircularProgress
                      thickness={7}
                      style={{marginLeft: 10}}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>

      {serverError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Dialog>
  );
};

interface FormValues {
  title: string;
  category: string;
}
