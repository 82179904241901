import {Box, Grid, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AddNewButton, ArticleCategorySkeleton, Empty, Fallback,} from "../../components";
import {useSetHeadbarTitle,} from "../../hooks";
import {useGetExpertCompanies} from "../../hooks/queries/experts/useGetExpertCompanies";
import {
  GetExpertCompanies_getExpertCompanies
} from "../../graphql/queries/experts/getExpertCompanies/__generated__/GetExpertCompanies";
import {ExpertCompanyCard} from "./components/ExpertCompanyCard";
import {EditExpertCompany} from "./components/EditExpertCompany";


export const ExpertCompanies: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Companies"));

  const {
    loading: expertCompaniesLoading,
    expertCompanies,
    error: expertCompaniesError,
  } = useGetExpertCompanies();

  const [expertCompanyOpen, setExpertCompanyOpen] = useState(false);

  const [currentExpertCompany, setCurrentExpertCompany] =
    useState<GetExpertCompanies_getExpertCompanies | null>(null);

  const handleExpertCompanyOpen = (
    expertCompany: GetExpertCompanies_getExpertCompanies | null
  ) => {
    setCurrentExpertCompany(expertCompany);
    setExpertCompanyOpen(true);
  };

  const handleExpertCompanyClose = () => setExpertCompanyOpen(false);

  if (expertCompaniesError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  const skeletonMarkup = new Array(5).fill(undefined).map((_, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
      <ArticleCategorySkeleton />
    </Grid>
  ));

  const expertCompanyMarkup = expertCompanies?.length ? (
    expertCompanies!.map((company, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <ExpertCompanyCard
          expertCompany={company}
          onClick={() => handleExpertCompanyOpen(company)}
        />
      </Grid>
    ))
  ) : (
    <Empty emoji={"🤔"} title={t("No expert companies yet")} />
  );

  return (
    <>
      <Box paddingX={4} paddingY={2}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Expert companies")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddNewButton
            title={t("Add expert company")}
            onClick={() => handleExpertCompanyOpen(null)}
          />
        </Box>
        <Box paddingTop={3}>
          <Grid container spacing={3}>
            {expertCompaniesLoading ? skeletonMarkup : expertCompanyMarkup}
          </Grid>
        </Box>
      </Box>

      <EditExpertCompany
        expertCompany={currentExpertCompany}
        open={expertCompanyOpen}
        onClose={handleExpertCompanyClose}
      />
    </>
  );
};


