import { Box, colors, Typography } from "@material-ui/core";
import { CheckOutlined, ClearOutlined } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { GetLearningPaths_getLearningPaths_learningPaths } from "../../../../graphql/queries/learningPath/getLearningPaths/__generated__/GetLearningPaths";

interface BottomAreaProps {
  path: GetLearningPaths_getLearningPaths_learningPaths;
}

export const BottomArea: React.FC<BottomAreaProps> = ({ path }) => {
  const { t } = useTranslation();

  return (
    <Box position={"relative"} padding="10px">
      <Typography variant="h6">{path.title}</Typography>

      <Box height="10px" />

      <Typography variant="body1">{path.steps.length} шагов</Typography>

      <Box display="flex" alignItems={"center"}>
        {path.description ? (
          <>
            <CheckOutlined htmlColor={colors.green[500]} />
          </>
        ) : (
          <ClearOutlined />
        )}
        <Typography variant="caption">Вводный текст</Typography>
      </Box>

      <Box display="flex" alignItems={"center"}>
        {path.bulletPoints.length ? (
          <>
            <CheckOutlined htmlColor={colors.green[500]} />
          </>
        ) : (
          <ClearOutlined />
        )}
        <Typography variant="caption">Доп. пункты</Typography>
      </Box>
    </Box>
  );
};
