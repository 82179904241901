import { useReactiveVar } from "@apollo/client";
import { Box } from "@material-ui/core";
import React from "react";
import { userVar } from "../../../apollo";
import { useCommonStyle } from "../../../styles/commonStyles";
import { EditAccount, EditPersonalInfo } from "../../users";

export const LeftSection: React.FC = ({}) => {
  const user = useReactiveVar(userVar);
  const commonClasses = useCommonStyle();

  if (!user) return null;

  return (
    <Box className={commonClasses.sectionWithSticky__scrollable}>
      <EditAccount user={user} isMe />

      <Box marginY={4} />

      <EditPersonalInfo editable user={user} />
    </Box>
  );
};
