import {useMutation} from "@apollo/client";
import {
  UpdatePromoterPayout,
  UpdatePromoterPayoutVariables
} from "../../graphql/mutations/promoters/UpdatePromoterPayout/__generated__/UpdatePromoterPayout";
import {
  UPDATE_PROMOTER_PAYOUT
} from "../../graphql/mutations/promoters/UpdatePromoterPayout";

export const useUpdatePromoterPayout = () => {
  const [update, { loading, error, data }] = useMutation<
    UpdatePromoterPayout,
    UpdatePromoterPayoutVariables
    >(UPDATE_PROMOTER_PAYOUT, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
    data
  };
};