import { ApolloQueryResult, useMutation } from "@apollo/client";
import { Box, Typography, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVertOutlined } from "@material-ui/icons";
import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MyTable, NotificationToast } from "../../../components";
import {
  ACTIVATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION,
} from "../../../graphql";
import {
  GetSubscriptionsInput,
  PaginationOptionsArgs,
} from "../../../graphql/globalTypes";
import {
  ActivateSubscription,
  ActivateSubscriptionVariables,
} from "../../../graphql/mutations/subscriptions/ActivateSubscription/__generated__/ActivateSubscription";
import {
  CancelSubscription,
  CancelSubscriptionVariables,
} from "../../../graphql/mutations/subscriptions/CancelSubscription/__generated__/CancelSubscription";
import {
  PauseSubscription,
  PauseSubscriptionVariables,
} from "../../../graphql/mutations/subscriptions/PauseSubscription/__generated__/PauseSubscription";
import {
  GetSubscriptions,
  GetSubscriptionsVariables,
  GetSubscriptions_getSubscriptions_subscriptions,
} from "../../../graphql/queries/subscriptions/GetSubscriptions/__generated__/GetSubscriptions";
import { HeadCell, SubscriptionStatus } from "../../../lib";

interface SubscriptionsTableProps {
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<
    React.SetStateAction<PaginationOptionsArgs>
  >;
  subscriptions: GetSubscriptions_getSubscriptions_subscriptions[];
  total: number;
  loading: boolean;
  filterParams: GetSubscriptionsInput;
  refetch: (
    variables?: Partial<GetSubscriptionsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetSubscriptions>>;
}

export const SubscriptionsTable: React.FC<SubscriptionsTableProps> = ({
  paginationOptions,
  setPaginationOptions,
  subscriptions,
  total,
  loading,
  refetch,
  filterParams,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [currentSubsctiption, setCurrentSubsctiption] =
    useState<GetSubscriptions_getSubscriptions_subscriptions | null>(null);
  const [actions, setActions] = useState<MenuOption[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cancel, { loading: cancelLoading, error: cancelError }] = useMutation<
    CancelSubscription,
    CancelSubscriptionVariables
  >(CANCEL_SUBSCRIPTION, {
    onError: (err) => console.log(err),
    onCompleted: () => {
      refetch({
        input: filterParams,
        paginationOptions,
      });
    },
  });
  const [activate, { loading: activateLoading, error: activateError }] =
    useMutation<ActivateSubscription, ActivateSubscriptionVariables>(
      ACTIVATE_SUBSCRIPTION,
      {
        onError: (err) => console.log(err),
        onCompleted: () => {
          refetch({
            input: filterParams,
            paginationOptions,
          });
        },
      }
    );
  const [pause, { loading: pauseLoading, error: pauseError }] = useMutation<
    PauseSubscription,
    PauseSubscriptionVariables
  >(PAUSE_SUBSCRIPTION, {
    onError: (err) => console.log(err),
    onCompleted: () => {
      refetch({
        input: filterParams,
        paginationOptions,
      });
    },
  });

  useEffect(() => {
    if (currentSubsctiption) {
      const options: MenuOption[] = ["See_details"];

      switch (currentSubsctiption.status) {
        case SubscriptionStatus.ACTIVE:
          options.push("Cancel", "Pause");
          break;
        case SubscriptionStatus.CANCELLED:
          break;
        case SubscriptionStatus.PAUSED:
          options.push("Activate", "Cancel");
          break;
        case SubscriptionStatus.SUSPENDED:
          options.push("Activate", "Cancel");
          break;
      }
      setActions(options);
    } else {
      setAnchorEl(null);
    }
  }, [currentSubsctiption]);

  const handleOpenMenuFor = (
    event: any,
    subscription: GetSubscriptions_getSubscriptions_subscriptions
  ) => {
    setCurrentSubsctiption(subscription);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setCurrentSubsctiption(null);
  };

  const handleAction = (act: MenuOption) => {
    switch (act) {
      case "Activate":
        activate({ variables: { subId: currentSubsctiption!.id } });
        setAnchorEl(null);
        break;
      case "Cancel":
        cancel({ variables: { subId: currentSubsctiption!.id } });
        setAnchorEl(null);
        break;
      case "Pause":
        pause({ variables: { subId: currentSubsctiption!.id } });
        setAnchorEl(null);
        break;
      case "See_details":
        history.push(`/subscriptions/${currentSubsctiption?.id}`);
        break;
    }
  };

  const mapArticlesToRows = useMemo(() => {
    const arr = subscriptions.map((subscription) => ({
      subscriptionId: (
        <Box>
          <Typography>{subscription.id}</Typography>

          {subscription.discount && (
            <Box>
              <Typography color="primary" style={{ fontSize: "12px" }}>
                {t("Discount")}: {subscription.discount.percentage * 100}% (
                {t("left")}: {subscription.discount.numberOfCharges})
              </Typography>
              {subscription.discount.coupon && (
                <Box
                  style={{
                    border: "1px solid orange",
                    color: "orange",
                    width: "fit-content",
                    padding: "5px",
                    borderRadius: 6,
                    fontSize: 10,
                  }}
                >
                  {subscription.discount.coupon.couponCode}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ),
      user: (
        <Box>
          <Typography variant="subtitle2">
            {subscription.user?.firstName + " " + subscription.user?.lastName}
          </Typography>
          <a href={`mailto:${subscription.user?.email}`}>
            <Typography variant="caption">
              {subscription.user?.email}
            </Typography>
          </a>
        </Box>
      ),
      status: t(subscription.status),
      plan: subscription.plan.title,
      startTime: moment(subscription.startTime).format("LLL"),
      lastTimeCharge: (
        <Box>
          <Typography variant="subtitle2">
            {moment(subscription.lastTimeCharge).format("LLL")}
          </Typography>
          <Typography variant="caption">
            {subscription.lastTimeAmount.value +
              " " +
              subscription.lastTimeAmount.currency}
          </Typography>
        </Box>
      ),
      edit: (
        <IconButton onClick={(e) => handleOpenMenuFor(e, subscription)}>
          <MoreVertOutlined />
        </IconButton>
      ),
    }));
    return arr;
  }, [subscriptions]);

  return (
    <>
      <Box width="100%">
        <MyTable
          selected={[]}
          hideCheck
          loading={loading || cancelLoading || activateLoading || pauseLoading}
          total={total}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          setSelected={() => {}}
          headCells={headCells}
          values={mapArticlesToRows}
          sortFields={["startTime", "lastTimeCharge"]}
          bulkActions={<></>}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {actions.map((act) => (
          <MenuItem key={act} onClick={() => handleAction(act)}>
            {t(act)}
          </MenuItem>
        ))}
      </Menu>

      {Boolean(cancelError || activateError || pauseError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "subscriptionId",
    align: "left",
  },
  {
    label: "user",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "plan",
    align: "center",
  },
  {
    label: "startTime",
    align: "left",
  },
  {
    label: "lastTimeCharge",
    align: "left",
  },
  {
    label: "edit",
    align: "right",
  },
];

type MenuOption = "See_details" | "Activate" | "Pause" | "Cancel";
