import React, {useMemo} from 'react';
import {PaginationOptionsArgs} from "../../../graphql/globalTypes";
import {
  GetOrders_getOrders_orders
} from "../../../graphql/queries/products/getOrders/__generated__/GetOrders";
import {useTranslation} from "react-i18next";
import {SimpleTable} from "../../../components/tables/SimpleTable";
import {useHistory} from "react-router-dom";
import {HeadCell} from "../../../lib";
import {Box} from "@mui/system";
import {format, parseISO} from "date-fns";

interface PaymentsTableProps {
  loading: boolean;
  payments: GetOrders_getOrders_orders[];
  totalPayments: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const PaymentsTable =
  ({
     payments,
     totalPayments,
     paginationOptions,
     setPaginationOptions,
     loading
   }: PaymentsTableProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const goToPayment = (id: string,) => {
      history.push({
        pathname: `/products/payment/${id}`,
        state: {order: null}
      });
    };

    const mapUsersToRows = useMemo(() => {
      const arr = payments.map(({
                                  created_at,
                                  id,
                                  paymentProvider,
                                  customer,
                                  status,
                                  product,
                                  originalPrice,
                                  totalPrice,
                                  priceWithDiscount,
                                  discount

                                }) => ({
        key: id,
        id: id,
        user: <Box>
          <Box fontWeight="500">{customer?.email}</Box>
          <Box fontSize="11px">{`${customer?.firstName} ${customer?.lastName}`}</Box>
        </Box>,
        purchaseDate: <Box fontSize="12px" fontWeight="500">
          {format(parseISO(created_at), "dd.MM.yyyy HH:mm:ss")}
        </Box>,
        paymentProvider: <Box>
          <Box fontWeight="500">{paymentProvider}</Box>
          <Box display="flex" fontSize="11px" gap="5px">
            <Box>{t("Status")}:</Box>
            <Box>{status}</Box>
          </Box>
        </Box>,
        product: product.title,
        originalPrice: `${originalPrice.value} ${originalPrice.currency}`,
        totalPrice: `${totalPrice.value} ${totalPrice.currency}`,
        priceWithDiscount: priceWithDiscount ? `${priceWithDiscount?.value} ${priceWithDiscount?.currency}` : "-",
        coupon: discount?.coupon ? discount?.coupon.couponCode : "-"
      }));
      return arr;
    }, [payments]);

    return (
      <SimpleTable
        loading={loading}
        total={totalPayments}
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        headCells={headCells}
        values={mapUsersToRows}
        sortFields={[]}
        onRowClick={goToPayment}
      />
    );
  };

const headCells: HeadCell[] = [
  {
    label: "user",
    align: "left",
  },
  {
    label: "purchaseDate",
    align: "left",
  },
  {
    label: "paymentProvider",
    align: "left",
  },
  {
    label: "product",
    align: "left",
  },
  {
    label: "originalPrice",
    align: "left",
  },
  {
    label: "totalPrice",
    align: "left",
  },
  {
    label: "priceWithDiscount",
    align: "center",
  },
  {
    label: "coupon",
    align: "center",
  },
];