import { CellPlugin, lazyLoad } from "@react-page/editor";
import createPlugin from "./createPlugin";
import PdfViewerHtmlRenderer from "./Renderer/PdfViewerHtmlRenderer";
import { PdfViewerSettings } from "./types/settings";
import { PdfViewerState } from "./types/state";

const PdfViewerControls = lazyLoad(
  () => import("./Controls/PdfViewerControls")
);

const PdfViewerPlugin: (
  settings?: Partial<PdfViewerSettings>
) => CellPlugin<PdfViewerState> = (settings) =>
  createPlugin({
    Renderer: PdfViewerHtmlRenderer,
    Controls: PdfViewerControls,
    ...settings,
  });

export { PdfViewerPlugin };
