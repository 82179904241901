import {gql} from "@apollo/client";

export const GET_EXPERT_ACTIVITIES = gql`
    query GetExpertActivities {
        getExpertActivities {
            id
            title
        }
    }
`;
