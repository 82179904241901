import {useQuery} from "@apollo/client";
import {GET_EXPERT_REQUESTS} from "../../../graphql";
import {
  GetExpertRequests
} from "../../../graphql/queries/experts/getExpertRequests/__generated__/GetExpertRequests";

export const useGetExpertRequests = () => {
  const { loading, error, data } = useQuery<GetExpertRequests>(
    GET_EXPERT_REQUESTS,
    {
      onError: (err) => console.error(err),
    }
  );

  return { loading, error, expertRequests: data?.getExpertRequests };
};