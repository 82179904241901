import {gql} from "@apollo/client";
import {ExpertFragment} from "../../../fragments";

export const UPDATE_EXPERT = gql`
    mutation UpdateExpert($input: UpdateExpertInput!) {
        updateExpert(input: $input) {
            error {
                name
                message
            }
            expert {
                ...Expert
            }
        }
    }
    ${ExpertFragment}
`;