import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useSkeletonsStyle } from "./styles";

interface EmptyProps {
  emoji: string;
  title: string;
}

export const Empty: React.FC<EmptyProps> = ({ emoji, title }) => {
  const classes = useSkeletonsStyle();

  return (
    <Box margin="auto">
      <Typography className={classes.empty__title} variant="h6">
        <span className={classes.emoji}>{emoji}</span>
        {title}
      </Typography>
    </Box>
  );
};
