import {Box, Grid, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AddNewButton, ArticleCategorySkeleton, Empty, Fallback,} from "../../components";
import {useSetHeadbarTitle,} from "../../hooks";
import {
  useGetPartnerCompanyCategories
} from "../../hooks/queries/partnerCompanies/useGetPartnerCompanyCategories";
import {
  GetPartnerCompanyCategories_getPartnerCompanyCategories
} from "../../graphql/queries/partnerCompanies/getPartnerCompanyCategories/__generated__/GetPartnerCompanyCategories";
import {PartnerCompanyCategoryCard} from "./components/PartnerCompanyCategoryCard";
import {EditPartnerCompanyCategory} from "./components/EditPartnerCompanyCategory";

export const PartnerCompaniesCategories: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Partner Company Categories"));

  const {
    loading: partnerCompanyLoading,
    categories: partnerCompanyCategories,
    error: partnerCompanyError,
  } = useGetPartnerCompanyCategories();

  const [partnerCompanyOpen, setPartnerCompanyOpen] = useState(false);

  const [currentPartnerCompanyCategory, setCurrentPartnerCompanyCategory] =
    useState<GetPartnerCompanyCategories_getPartnerCompanyCategories | null>(null);

  const handlePartnerCompanyOpen = (
    category: GetPartnerCompanyCategories_getPartnerCompanyCategories | null
  ) => {
    setCurrentPartnerCompanyCategory(category);
    setPartnerCompanyOpen(true);
  };

  const handlePartnerCompanyClose = () => setPartnerCompanyOpen(false);

  if (partnerCompanyError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  const skeletonMarkup = new Array(5).fill(undefined).map((_, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
      <ArticleCategorySkeleton />
    </Grid>
  ));

  const partnerCompanyCategoriesMarkup = partnerCompanyCategories?.length ? (
    partnerCompanyCategories!.map((cat, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <PartnerCompanyCategoryCard
          category={cat}
          onClick={() => handlePartnerCompanyOpen(cat)}
        />
      </Grid>
    ))
  ) : (
    <Empty emoji={"🤔"} title={t("No partner company categories yet")} />
  );

  return (
    <>
      <Box paddingX={4} paddingY={2}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Partner Company Categories")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddNewButton
            title={t("Add Partner Company category")}
            onClick={() => handlePartnerCompanyOpen(null)}
          />
        </Box>
        <Box paddingTop={3}>
          <Grid container spacing={3}>
            {partnerCompanyLoading ? skeletonMarkup : partnerCompanyCategoriesMarkup}
          </Grid>
        </Box>
      </Box>

      <EditPartnerCompanyCategory
        category={currentPartnerCompanyCategory}
        open={partnerCompanyOpen}
        onClose={handlePartnerCompanyClose}
      />
    </>
  );
};


