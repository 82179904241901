import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { CloseOutlined, EditOutlined } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { userVar } from "../../../apollo";
import {
  MyAutocomplete,
  MyTextField,
  NotificationToast,
} from "../../../components";
import { UpdateUserInfoInput } from "../../../graphql/globalTypes";
import { UpdateUserInfoVariables } from "../../../graphql/mutations/users/UpdateUserInfo/__generated__/UpdateUserInfo";
import { useGetAllCountries, useUpdateUserInfo } from "../../../hooks";
import { languages as arrLanguages, nationalities } from "../../../lib";
import { phoneRegExp } from "../../../utils";
import { useUsersStyles } from "../styles";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface EditPersonalInfoProps {
  editable?: boolean;
  user: Me_me;
}

export const EditPersonalInfo: React.FC<EditPersonalInfoProps> = ({
  editable,
  user,
}) => {
  const classes = useUsersStyles();
  const { countries } = useGetAllCountries();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const { update, loading, error } = useUpdateUserInfo();
  const [enableValidation, setEnableValidation] = useState(false);
  const [input, setInput] = useState<UpdateUserInfoInput>({
    nationality: user.nationality ?? t("Not specified"),
    countryId: user.location?.countryObject?.id
      ? user.location.countryObject.id
      : t("Not specified"),
    languages: user.languages ?? [],
  });

  const initialValues: FormValues = {
    city: user.location?.city ?? "",
    skype: user.skype ?? "",
    linkedId: user.linkedId ?? "",
    zoom: user.zoom ?? "",
    phone: user.phone ?? "",
  };

  useEffect(() => {
    if (disabled) {
      setInput({
        nationality: user.nationality ?? t("Not specified"),
        countryId: user.location?.countryObject?.id
          ? user.location.countryObject.id
          : t("Not specified"),
        languages: user.languages ?? [],
      });
    }
  }, [disabled]);

  const handleSetInput = (n: string | null, key: keyof UpdateUserInfoInput) => {
    setInput((prev) => ({
      ...prev,
      [key]: n ?? t("Not specified"),
    }));
  };

  const handleSetCountry = (n: string | null) => {
    if (n && n !== t("Not specified")) {
      const countryId = countries.find((c) => c.rusTrans === n)!.id;
      setInput((prev) => ({
        ...prev,
        countryId: countryId,
      }));
    } else if (n === t("Not specified")) {
      setInput((prev) => ({
        ...prev,
        countryId: undefined,
      }));
    }
  };

  const validationSchema = Yup.object({
    phone: Yup.string().matches(phoneRegExp, t("Phone number is not valid")),
  });

  const toggleEdit = () => {
    setDisabled(!disabled);
  };

  const handleSubmit = async (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const variables: UpdateUserInfoVariables = {
      input: {
        ...values,
        ...(input.nationality !== t("Not specified") &&
        input.nationality !== user.nationality
          ? {
              nationality: input.nationality,
            }
          : {}),
        ...(input.countryId !== t("Not specified") &&
        input.countryId !== user.location?.countryObject?.id
          ? {
              countryId: input.countryId,
            }
          : {}),
        ...(input.languages!.length && input.languages !== user.languages
          ? {
              languages: input.languages,
            }
          : {}),
      },
    };

    try {
      const res = await update({ variables });
      if (res.data?.updateUserInfo) {
        userVar(res.data.updateUserInfo);
        setDisabled(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Paper className={classes.info__paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textSecondary" variant="h6">
            {t("Personal Info")}
          </Typography>

          {editable && (
            <IconButton color="primary" onClick={toggleEdit}>
              {disabled ? <EditOutlined /> : <CloseOutlined />}
            </IconButton>
          )}
        </Box>

        <Divider style={{ marginBottom: 20 }} />

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
        >
          {({ values, submitForm }) => (
            <Form>
              <>
                <Typography variant="overline">{t("Geography")}</Typography>

                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <Grid item xs={6}>
                    <MyAutocomplete
                      options={nationalities}
                      getOptionLabel={(option) => option}
                      label={t("nationality")}
                      placeholder={disabled ? t("Not specified") : undefined}
                      value={input.nationality!}
                      setValue={(v) => handleSetInput(v, "nationality")}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyAutocomplete
                      multiple
                      options={arrLanguages}
                      getOptionLabel={(option) => option}
                      label={t("languages")}
                      placeholder={disabled ? t("Not specified") : undefined}
                      value={input.languages!}
                      setValue={(v) => handleSetInput(v, "languages")}
                      disabled={disabled}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MyAutocomplete
                      options={countries.map((c) => c.rusTrans)}
                      getOptionLabel={(option) => option}
                      label={t("Country")}
                      value={
                        countries.find((c) => c.id === input.countryId)
                          ?.rusTrans ?? ""
                      }
                      placeholder={undefined}
                      setValue={handleSetCountry}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("City")}
                      value={disabled ? user.location?.city ?? "" : values.city}
                      name="city"
                      disabled={disabled}
                      placeholder={disabled ? t("Not specified") : undefined}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Divider style={{ marginBottom: 20 }} />

                <Typography variant="overline">{t("Contacts")}</Typography>

                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label={t("Phone")}
                      name="phone"
                      placeholder={disabled ? t("Not specified") : undefined}
                      disabled={disabled}
                      InputLabelProps={{ shrink: true }}
                      value={disabled ? user.phone ?? "" : values.phone}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label="LinkedIn"
                      name="linkedId"
                      disabled={disabled}
                      placeholder={disabled ? t("Not specified") : undefined}
                      InputLabelProps={{ shrink: true }}
                      value={disabled ? user.linkedId ?? "" : values.linkedId}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label="Skype"
                      name="skype"
                      disabled={disabled}
                      placeholder={disabled ? t("Not specified") : undefined}
                      InputLabelProps={{ shrink: true }}
                      value={disabled ? user.skype ?? "" : values.skype}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MyTextField}
                      fullWidth
                      label="Zoom"
                      name="zoom"
                      placeholder={disabled ? t("Not specified") : undefined}
                      disabled={disabled}
                      InputLabelProps={{ shrink: true }}
                      value={disabled ? user.zoom ?? "" : values.zoom}
                    />
                  </Grid>
                </Grid>

                {Boolean(editable && !disabled) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    fullWidth
                    disabled={loading}
                  >
                    {t("Save")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                )}
              </>
            </Form>
          )}
        </Formik>
      </Paper>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  city: string;
  skype: string;
  linkedId: string;
  zoom: string;
  phone: string;
}
