import { useQuery } from "@apollo/client";
import { useState } from "react";
import { userVar } from "../../apollo";
import { ME } from "../../graphql";
import { Me } from "../../graphql/queries/me/Me/__generated__/Me";

export const useAuthentication = () => {
  const [processing, setProcessing] = useState(true);

  const { error } = useQuery<Me>(ME, {
    onError: (err) => {
      console.error(err);
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN!);
      setProcessing(false);
    },
    onCompleted: (res) => {
      setProcessing(false);
      if (res.me) {
        userVar(res.me);
      }
    },
  });

  return { processing, error };
};
