import {Box, Card, IconButton,} from "@material-ui/core";
import React from "react";
import {EditOutlined as EditIcon} from "@material-ui/icons";
import {useCommonStyle} from "../../../styles/commonStyles";
import {useTranslation} from "react-i18next";
import {DeleteButton, NotificationToast} from "../../../components";
import {useIsDesktopView} from "../../../hooks";
import {
  GetExpertActivities_getExpertActivities
} from "../../../graphql/queries/experts/getExpertActivities/__generated__/GetExpertActivities";
import {
  useDeleteExpertActivity
} from "../../../hooks/mutations/experts/useDeleteExpertActivity";

interface ExpertActivityCardProps {
  expertActivity: GetExpertActivities_getExpertActivities;
  onClick: () => void;
}

export const ExpertActivityCard: React.FC<ExpertActivityCardProps> = ({
  expertActivity,
  onClick,
}) => {
  const isDesktopView = useIsDesktopView();
  const commonClasses = useCommonStyle();
  const { t } = useTranslation();
  const { loading, error, deleteExpertActivity } =
    useDeleteExpertActivity(expertActivity);

  async function handleDelete(): Promise<void> {
    await deleteExpertActivity({ variables: { id: expertActivity.id } });
  }

  return (
    <>
      <Card
        style={{ display: "flex", height: "100%", cursor: "default" }}
        className={commonClasses.onHover}
      >
        <Box display="flex" flexDirection="column" flex={2}>
            <Box fontSize="16px" fontWeight="500" padding="10px 10px 0 10px">
              {expertActivity.title}
            </Box>
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={1}
          >
            <IconButton onClick={onClick}>
              <EditIcon />
            </IconButton>
            <DeleteButton loading={loading} deleteAction={handleDelete} />
          </Box>
        </Box>
      </Card>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </>
  );
};
