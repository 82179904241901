import {gql} from "@apollo/client";
import {AffiliateFragment, ExpertFragment} from "../../../fragments";

export const GET_AFFILIATE_BY_ID = gql`
    query GetAffiliateById($id: String!) {
        getAffiliateById(id: $id) {
            ...Affiliate
        }
    }
    ${AffiliateFragment}
`;
