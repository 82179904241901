import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Empty } from "../../components";
import { useSetHeadbarTitle } from "../../hooks";

interface ResourcesProps {}

export const Resources: React.FC<ResourcesProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Resources"));
  return (
    <Box height="50vh" display="flex" alignItems="center">
      <Empty emoji={"👰🏻‍♂️"} title="Скоро будет..." />
    </Box>
  );
};
