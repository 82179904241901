import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { EmailOutlined as EmailIcon } from "@material-ui/icons";
import { Field, Form, Formik, FormikErrors } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  AddNewButton,
  FullscreenLoader,
  MyTextField,
  NotificationToast,
  SelectButton,
} from "../../../components";
import { useRegisterUser } from "../../../hooks";
import { UserRole } from "../../../lib";

export const AddNewUser: React.FC = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { register, loading, error } = useRegisterUser();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<UserRole>(UserRole.ADMIN);
  const [enableValidation, setEnableValidation] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues: FormValues = {
    email: "",
    firstName: "",
    lastName: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t("Invalid email")).required(t("Required")),
    firstName: Yup.string().required(t("Required")),
    lastName: Yup.string().required(t("Required")),
  });

  const handleSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const res = await register({
        variables: {
          input: {
            ...values,
            role,
          },
        },
      });
      if (res.data?.registerUser.error) {
        setErrors({ email: t("Email already exists") });
      } else {
        history.push({
          pathname: `/users/${res.data?.registerUser.user!.id}`,
          state: {
            user: res.data?.registerUser.user!,
            showCreateNotification: true,
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleRoleChange = (e: any) => {
    setRole(e.target.value);
  };

  return (
    <>
      <AddNewButton onClick={handleOpen} title={t("Add User")} />

      <Dialog maxWidth={"xs"} open={open} onClose={handleClose}>
        <DialogTitle>{t("New User")}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, { setErrors, setSubmitting }) =>
            handleSubmit(values, setErrors, setSubmitting)
          }
        >
          {({ values, submitForm }) => (
            <Form>
              <>
                <DialogContent>
                  <Field
                    component={MyTextField}
                    type="email"
                    fullWidth
                    placeholder={t("Email")}
                    name="email"
                    style={{ marginBottom: 20 }}
                    icon={<EmailIcon />}
                  />

                  <Field
                    component={MyTextField}
                    fullWidth
                    placeholder={t("firstName")}
                    name="firstName"
                    style={{ marginBottom: 20 }}
                  />

                  <Field
                    component={MyTextField}
                    fullWidth
                    placeholder={t("lastName")}
                    name="lastName"
                    style={{ marginBottom: 20 }}
                  />

                  <SelectButton
                    options={[
                      UserRole.ADMIN,
                      UserRole.COACH,
                      UserRole.MODERATOR,
                    ]}
                    rootStyle={{ width: "100%" }}
                    value={role}
                    onChange={handleRoleChange}
                    label={t("Role")}
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>{t("Cancel")}</Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    disabled={
                      !values.email ||
                      !values.firstName ||
                      !values.lastName ||
                      loading
                    }
                  >
                    {t("Add")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {loading && <FullscreenLoader transparent />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
}
