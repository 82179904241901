import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { theme } from "../../styles/theme";

interface StatsProps {
  value: string | number;
  title: string;
}

export const Stats: React.FC<StatsProps> = ({ value, title }) => {
  return (
    <Box boxShadow={theme.shadows["2"]} padding={1} borderRadius="5px">
      <Box width="100%" textAlign="right">
        <Typography variant="caption">{title}</Typography>
      </Box>
      <Box width="100%" textAlign="right">
        <Typography color="textSecondary" variant="h6">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
