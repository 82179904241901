import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@material-ui/core";
import {
  EmailOutlined as EmailIcon,
  VpnKeyOutlined as PassIcon,
} from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { MyTextField, NotificationToast } from "../../../components";
import { useIsDesktopView, useLogin, useLoginRoute } from "../../../hooks";
import { IErrorResponse } from "../../../lib";
import { loginUser } from "../../../utils";
import { SignInVariables } from "../../../graphql/mutations/me/Login/__generated__/SignIn";
import { GoogleIcon } from "../../../assets/GoogleIcon";
import { useGoogleLogin } from "@react-oauth/google";
import { SignInWithGoogle } from "./SignInWithGoogle";

export const LoginForm: React.FC = () => {
  const isDesktopView = useIsDesktopView();
  const { t } = useTranslation();
  const { updateSection } = useLoginRoute();
  const { login, loading, error } = useLogin();
  const [invalidLoginError, setInvalidLoginError] =
    useState<IErrorResponse | null>(null);
  const history = useHistory();
  const [enableValidation, setEnableValidation] = useState(false);

  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t("Invalid email")).required(t("Required")),
    password: Yup.string().required(t("Required")),
  });

  const handleLogin = async (
    { email, password }: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setInvalidLoginError(null);
    const variables: SignInVariables = {
      input: {
        creds: {
          email,
          password,
          tokenClient: "AdminPanel",
        },
      },
    };

    try {
      const res = await login({ variables });
      if (res?.data?.signIn.error) {
        setInvalidLoginError(res.data.signIn.error);
      } else {
        await loginUser(res!.data!.signIn.user!.token);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleGoogleSignIn = () => {};

  return (
    <Box>
      <Typography color="textSecondary" variant="caption">
        {t("Login to continue")}
      </Typography>

      <Box position={"relative"}>
        <Box marginTop={4} marginBottom={2}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={enableValidation}
            validateOnBlur={enableValidation}
            onSubmit={async ({ email, password }, { setSubmitting }) =>
              handleLogin({ email, password }, setSubmitting)
            }
          >
            {({ values, submitForm }) => (
              <Form>
                <>
                  <Field
                    component={MyTextField}
                    type="email"
                    fullWidth
                    placeholder={t("Email")}
                    name="email"
                    style={{ marginBottom: 20 }}
                    icon={<EmailIcon />}
                  />
                  <Field
                    component={MyTextField}
                    fullWidth
                    placeholder={t("Password")}
                    name="password"
                    style={{ marginBottom: 20 }}
                    icon={<PassIcon />}
                    isPassword
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    disabled={
                      !values.email.length || !values.password.length || loading
                    }
                  >
                    {t("Login")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </>
              </Form>
            )}
          </Formik>
        </Box>

        <Box textAlign={"right"}>
          <Link
            color="secondary"
            style={{ cursor: "pointer" }}
            onClick={() => updateSection("forgot_pass")}
          >
            <Typography variant="caption">{t("Forgot Password")}</Typography>
          </Link>
        </Box>
      </Box>

      <Box mt={4}>
        <SignInWithGoogle />
      </Box>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}

      {invalidLoginError && (
        <NotificationToast
          message={t(invalidLoginError.message!)}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </Box>
  );
};

interface FormValues {
  email: string;
  password: string;
}
