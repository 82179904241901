import { gql } from "@apollo/client";
import { TableJobFragment } from "../../../fragments";

export const BULK_JOBS_UPDATE = gql`
  mutation BulkJobsUpdate($input: BulkJobsUpdateInput!) {
    bulkJobsUpdate(input: $input) {
      ...TableJob
    }
  }
  ${TableJobFragment}
`;
