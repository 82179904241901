import {useMutation} from "@apollo/client";
import {DELETE_EXPERT_ACTIVITY} from "../../../graphql";
import {
  GetExpertActivities_getExpertActivities
} from "../../../graphql/queries/experts/getExpertActivities/__generated__/GetExpertActivities";
import {
  DeleteExpertActivity,
  DeleteExpertActivityVariables
} from "../../../graphql/mutations/experts/deleteExpertActivity/__generated__/DeleteExpertActivity";

export const useDeleteExpertActivity = (activityToDelete: GetExpertActivities_getExpertActivities) => {
  const [deleteExpertActivity, { loading, error, data }] = useMutation<
    DeleteExpertActivity,
    DeleteExpertActivityVariables
    >(DELETE_EXPERT_ACTIVITY, {
    onError: (err) => console.error(err),
    update: (cache) => {
      cache.modify({
        fields: {
          getExpertActivities(existing = [], { readField }) {
            return (<any[]>existing).filter(
              (ref) =>
                activityToDelete.id !== readField("id", ref)
            );
          },
        },
      });
    },
  });

  return {
    deleteExpertActivity,
    loading,
    error,
    success: !!data?.deleteExpertActivity,
  };
};