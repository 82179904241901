import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Field, Form, Formik, FormikErrors} from "formik";
import {
  AddNewButton,
  FullscreenLoader,
  MyTextField,
  NotificationToast
} from "../../../components";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  Grid, Radio, RadioGroup,
  Switch,
  TextField, Typography
} from "@material-ui/core";
import {useCreatePromoter} from "../../../hooks/mutations/useCreatePromoter";

import {useGetUsers} from "../../../hooks";
import {AppLanguage, GetUsersInput} from "../../../graphql/globalTypes";
import {
  GetUsers_getUsers_users as IUser
} from "../../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {useDebounce} from "../../../hooks/useDebounce";
import {Autocomplete} from "@material-ui/lab";
import {convertEnumToArray} from "../../../utils";
import {appLanguages} from "../../../lib/constants";


export const AddNewPromoter = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [enableValidation, setEnableValidation] = useState(false);
  const {createPromoter, loading, error} = useCreatePromoter()
  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    textSearch: undefined,
    roles: undefined,
    isFellow: undefined,
  });
  const {data, loading: usersLoading} = useGetUsers({
    input: useDebounce(filterParams, 700),
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      }
    }
  });

  const handleTextSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues: FormValues = {
    email: "",
    firstName: "",
    lastName: "",
    userId: "",
    username: "",
    password: "",
    couponCode: "",
    couponProductDiscount:10,
    couponSubscriptionDiscount:20,
    productCommission:10,
    subscriptionCommission:30,
    isUserExist: true,
    language:appLanguages.ru
  };

  const validationSchema = Yup.object().shape({
    isUserExist: Yup.boolean(),
    username: Yup.string().required(t("Required")),
    couponProductDiscount: Yup.number().positive(t("must be a positive number")).required(t("Required")),
    couponSubscriptionDiscount: Yup.number().positive(t("must be a positive number")).required(t("Required")),
    userId: Yup.string().when('isUserExist', {
      is: true,
      then: Yup.string().required(t("Required")),
    }),
    password: Yup.string().required(t("Required"))
      .min(8, t("MinLength", {length: 8}))
      .max(64, t("MaxLength", {length: 64})),
    couponCode: Yup.string().required(t("Required"))
      .min(3, t("MinLength", {length: 3})),
    productCommission: Yup.number().positive(t("must be a positive number")).required(t("Required")),
    subscriptionCommission: Yup.number().positive(t("must be a positive number")).required(t("Required")),
    email: Yup.string().when('isUserExist', {
      is: false,
      then: Yup.string().email(t("Invalid email")).required(t("Required")),
    }),
    firstName: Yup.string().when('isUserExist', {
      is: false,
      then: Yup.string().required(t("Required")),
    }),
    lastName: Yup.string().when('isUserExist', {
      is: false,
      then: Yup.string().required(t("Required")),
    }),
  });

  const handleSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const res = await createPromoter({
        variables: {
          input: {
            userId: values.userId,
            password: values.password,
            username: values.username,
            couponCode: values.couponCode,
            promoterData: {
              email: values.email,
              lastName: values.lastName,
              firstName: values.firstName
            },
            subscriptionCommission: values.subscriptionCommission / 100,
            productCommission: values.productCommission / 100,
            couponSubscriptionDiscount: values.couponSubscriptionDiscount / 100,
            couponProductDiscount: values.couponProductDiscount / 100,
            language: values.language
          },
        },
      });
      if (res.data?.createPromoter.error?.name === "email") {
        setErrors({email: t("Email already exists")});
      }
      if (res.data?.createPromoter.error?.name === "user") {
        setErrors({userId: t("User already has partnership")});
      }
      if (res.data?.createPromoter.error?.name === "coupon") {
        setErrors({couponCode: t("Coupon code already exists")});
      }
      if (res.data?.createPromoter.error?.name === "name") {
        setErrors({username: t("Username already exists")});
      } else {
        history.push({
          pathname: `/promoters/${res.data?.createPromoter.user!.id}`,
          state: {
            showCreateNotification: true,
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (data) {
      setUsers(data.getUsers.users);
    }
  }, [data]);

  return (
    <>
      <AddNewButton onClick={handleOpen} title={t("Add promoter")}/>
      <Dialog maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle>{t("New promoter")}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, {setErrors, setSubmitting}) =>
            handleSubmit(values, setErrors, setSubmitting)
          }
        >
          {({values, submitForm, setFieldValue, errors}) => (
            <Form>
              <>
                <DialogContent>
                  <Box display="flex"
                       alignItems="center"
                       marginBottom="5px"
                  >
                    <Switch
                      color="primary"
                      onChange={(event, checked) => {
                        setFieldValue("isUserExist", checked)
                      }}
                      checked={values.isUserExist}
                    />
                    <Box>{t("isUserExist")}</Box>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                    {t("Mailing language")}
                  </Typography>
                    <RadioGroup row
                                value={values.language}
                                onChange={(e) => setFieldValue("language", e.target.value)}
                    >
                      {Object.values(appLanguages).map(lng=>
                        <FormControlLabel value={lng}
                                          control={<Radio />}
                                          label={lng}
                                          key={lng} />)}
                    </RadioGroup>
                  </Box>
                  <Box height="10px"/>
                  {values.isUserExist
                    ? <>
                      <Field
                        name="userId"
                        noOptionsText={t("User not found")}
                        loading={usersLoading}
                        loadingText={<CircularProgress
                          thickness={7}
                          style={{marginLeft: 10}}
                          size={20}
                          color="primary"
                        />}
                        component={Autocomplete}
                        options={users}
                        getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} ` || ""}
                        onChange={(event: any, newValue: IUser) => setFieldValue("userId", newValue?.id)}
                        fullWidth
                        style={{marginBottom: 20}}
                        renderInput={(params: any) => (
                          <TextField {...params}
                                     error={!!errors.userId}
                                     placeholder={t("Enter name or email")}
                                     variant="outlined"
                                     InputProps={{
                                       ...params.InputProps,
                                       endAdornment: (
                                         <React.Fragment>
                                           {usersLoading ?
                                             <CircularProgress color="primary"
                                                               size={20}/> : null}
                                           {params.InputProps.endAdornment}
                                         </React.Fragment>
                                       ),
                                     }}
                                     label={t("Enter name or email")}
                                     onChange={handleTextSearchChange}
                                     helperText={errors.userId}
                          />
                        )}
                      />
                    </>
                    : <><Field
                      component={MyTextField}
                      label={t("Email")}
                      type="email"
                      fullWidth
                      placeholder={t("Email")}
                      name="email"
                      style={{marginBottom: 20}}
                    />

                      <Field
                        component={MyTextField}
                        label={t("firstName")}
                        fullWidth
                        placeholder={t("firstName")}
                        name="firstName"
                        style={{marginBottom: 20}}
                      />

                      <Field
                        component={MyTextField}
                        label={t("lastName")}
                        fullWidth
                        placeholder={t("lastName")}
                        name="lastName"
                        style={{marginBottom: 20}}
                      /></>}

                  <Field
                    component={MyTextField}
                    label={t("uniqueUserName")}
                    fullWidth
                    placeholder={t("uniqueUserName")}
                    name="username"
                    style={{marginBottom: 20}}
                  />

                  <Grid container spacing={2}
                        style={{marginBottom: 20, marginTop: 20}}>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        label={t("Password")}
                        fullWidth
                        placeholder={t("Password")}
                        name="password"
                        style={{marginBottom: 20}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        label={t("PromoCode")}
                        fullWidth
                        placeholder={t("PromoCode")}
                        name="couponCode"
                        style={{marginBottom: 20}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        icon="%"
                        type="number"
                        label={t("couponProductDiscount")}
                        fullWidth
                        placeholder={t("couponProductDiscount")}
                        name="couponProductDiscount"
                        style={{marginBottom: 20}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        icon="%"
                        type="number"
                        label={t("couponSubscriptionDiscount")}
                        fullWidth
                        placeholder={t("couponSubscriptionDiscount")}
                        name="couponSubscriptionDiscount"
                        style={{marginBottom: 20}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        type="number"
                        icon="%"
                        label={t("Product commission")}
                        fullWidth
                        placeholder={t("product commission")}
                        name="productCommission"
                        style={{marginBottom: 20}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        type="number"
                        icon="%"
                        label={t("Subscription commission")}
                        fullWidth
                        placeholder={t("Subscription commission")}
                        name="subscriptionCommission"
                        style={{marginBottom: 20}}
                      />
                    </Grid>
                  </Grid>

                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>{t("Cancel")}</Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    disabled={loading}
                  >
                    {t("Add")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{marginLeft: 10}}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {loading && <FullscreenLoader transparent/>}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  isUserExist: boolean
  userId: string;
  email: string,
  firstName: string,
  lastName: string,
  password: string;
  username: string;
  couponSubscriptionDiscount: number
  couponProductDiscount: number
  productCommission: number
  subscriptionCommission: number
  couponCode: string;
  language:string
}
