import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useGetPayouts} from "../../hooks/queries/useGetPayouts";
import {
  GetPayoutsVariables
} from "../../graphql/queries/promoters/getPayouts/__generated__/GetPayouts";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import {useGetUsers, useSetHeadbarTitle} from "../../hooks";
import {MyTable, SelectButton} from "../../components";
import {HeadCell} from "../../lib";
import {CircularProgress, Grid, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {
  GetUsers_getUsers_users as IUser
} from "../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {GetUsersInput, UserRole} from "../../graphql/globalTypes";
import {useDebounce} from "../../hooks/useDebounce";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Box} from "@mui/system"
import {UpdatePromoterPayoutModal} from "./components/UpdatePromoterPayoutModal";

export const PromotersPayouts = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Promoters payouts"));
  const {getPayouts, payouts, loading, error} = useGetPayouts()

  const [promoters, setPromoters] = useState<IUser[]>([]);
  const [filterParams, setFilterParams] = useState<GetUsersInput>({
    roles: [UserRole.PROMOTER]
  });
  const {data, loading: promotersLoading} = useGetUsers({
    input: useDebounce(filterParams, 700),
    paginationOptions: {
      limit: 100,
    }
  });

  const handleTextSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };

  const handleFromDateChange = (date: MaterialUiPickersDate) => {
    setVariables(prev => ({
      query: {
        ...prev.query, periodFrom: date
      }
    }))
  };

  const handleToDateChange = (date: MaterialUiPickersDate) => {
    setVariables(prev => ({
      query: {
        ...prev.query, periodTo: date
      }
    }))
  };

  const handleStatusChange = (e: any) => {
    setVariables(prev => ({
      query: {
        ...prev.query, status: e.target.value === "all" ? null : e.target.value
      }
    }))
  }

  const mapPayoutsToRows = useMemo(() => {
    if (!payouts?.length) return [];
    const arr = payouts.map((payout, index) => ({
      id: payout.id,
      number: index + 1,
      status: t(payout.status),
      period: `${moment(payout.periodFrom).format("DD.MM.YYYY")} - ${moment(payout.periodTo).format("DD.MM.YYYY")}`,
      totalAmount: payout.totalAmount,
      currency: payout.currency,
      promoterCommission: (<Box>
        <Box>{`${t("Subscription commission")}: ${payout.subscriptionCommission * 100}%`}</Box>
        <Box>{`${t("Product commission")}: ${payout.productCommission * 100}%`}</Box>
      </Box>),
      payoutDate: payout.paidOutAt
        ? moment(payout.paidOutAt).format("DD.MM.YYYY HH:mm")
        : t("Not paid"),
      edit:<UpdatePromoterPayoutModal payout={payout}/>
    }));
    return arr;
  }, [payouts]);

  const [variables, setVariables] = useState<GetPayoutsVariables>({
      query: {
        periodFrom: "01.01.2023",
        periodTo: new Date(),
        promoterId: null,
        status: null
      }
    }
  )

  useEffect(() => {
    if (data) {
      setPromoters(data.getUsers.users);
    }
  }, [data]);

  useEffect(() => {
    getPayouts({
      variables
    })
  }, [variables])

  return (

    <Box padding="20px">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            noOptionsText={t("User not found")}
            loading={promotersLoading}
            loadingText={<CircularProgress
              thickness={7}
              style={{marginLeft: 10}}
              size={20}
              color="primary"
            />}
            options={promoters}
            getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} ` || ""}
            onChange={(event: any, newValue: IUser | null) => setVariables(prev => ({
              query: {
                ...prev.query,
                promoterId: newValue?.id
              }
            }))}
            fullWidth
            style={{marginBottom: 20}}
            renderInput={(params: any) => (
              <TextField {...params}
                         placeholder={t("Promoter name or email")}
                         variant="outlined"
                         InputProps={{
                           ...params.InputProps,
                           endAdornment: (
                             <React.Fragment>
                               {promotersLoading ?
                                 <CircularProgress color="primary"
                                                   size={20}/> : null}
                               {params.InputProps.endAdornment}
                             </React.Fragment>
                           ),
                         }}
                         label={t("Promoter name or email")}
                         onChange={handleTextSearchChange}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectButton
            onChange={handleStatusChange}
            value={variables.query.status ? variables.query.status : "all"}
            options={["PAID", "PENDING", "FAILED", "all"]}
            rootStyle={{width:"100%"}}
            label={t("Status")}
          />
        </Grid>
      </Grid>

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography>{t("period")}</Typography>
        <Box height="10px"/>
        <Box display="flex" gap="20px" flexWrap="wrap">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            label="С"
            value={variables.query.periodFrom}
            onChange={handleFromDateChange}
            inputVariant="outlined"
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            label="По"
            value={variables.query.periodTo}
            onChange={handleToDateChange}
            inputVariant="outlined"
          />
        </Box>
      </MuiPickersUtilsProvider>
      <MyTable
        loading={loading}
        headCells={headCells}
        values={mapPayoutsToRows}
        bulkActions={<></>}
        sortFields={[]}
        hideCheck
        noFoundMessage={{title: "No partner payouts found", emoji: "💸"}}
      />
    </Box>
  );
};

const headCells: HeadCell[] = [
  {
    label: "number",
    align: "left",
  },
  {
    label: "status",
    align: "left",
  },
  {
    label: "period",
    align: "left",
  },
  {
    label: "totalAmount",
    align: "right",
  },
  {
    label: "currency",
    align: "right",
  },
  {
    label: "promoterCommission",
    align: "right",
  },
  {
    label: "payoutDate",
    align: "right",
  },
  {
    label: "edit",
    align: "center"
  }
];
