import { useMutation } from "@apollo/client";
import {DELETE_JOB_CATEGORY, GET_JOB_CATEGORIES, GET_JOBS} from "../../graphql";
import {
  DeleteJobCategory,
  DeleteJobCategoryVariables,
} from "../../graphql/mutations/jobs/DeleteJobCategory/__generated__/DeleteJobCategory";
import {DELETE_PRODUCT} from "../../graphql/mutations/products/deleteProduct";
import {GET_PRODUCTS} from "../../graphql/queries/products/getProducts";
import {
  DeleteProduct, DeleteProductVariables
} from "../../graphql/mutations/products/deleteProduct/__generated__/DeleteProduct";

export const useDeleteProduct = () => {
  const [deleteProduct, { loading, error }] = useMutation<
    DeleteProduct,
    DeleteProductVariables
    >(DELETE_PRODUCT, {
    onError: (err) => console.log(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          filter: {},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "postedAt",
              sortBy: "desc",
            }
            },
        },
      },
    ],
  });

  return {
    deleteProduct,
    loading,
    error,
  };
};
