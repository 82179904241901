import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useButtonStyles } from "./styles";

interface SelectButtonProps {
  options: string[];
  value: string;
  ns?: string;
  onChange: (e: any) => void;
  rootStyle?: React.CSSProperties;
  squared?: boolean;
  loading?: boolean;
  displayEmpty?: boolean;
  label?: string;
  placeholder?: string;
  densed?: boolean;
  disabled?:boolean;
  noOptionTranslation?: boolean;
}

export const SelectButton: React.FC<SelectButtonProps> = ({
  value,
  options,
  onChange,
  rootStyle,
  squared,
  label,
  loading,
  displayEmpty,
  placeholder,
  densed,
  disabled,
  ns,
  noOptionTranslation,
}) => {
  const { t } = useTranslation();
  const classes = useButtonStyles();

  return (
    <FormControl style={rootStyle}>
      {label && (
        <FormHelperText className={classes.sb_label}>{label}</FormHelperText>
      )}
      {loading ? (
        <CircularProgress style={{ margin: "auto" }} size={20} />
      ) : (
        <Select
          disabled={disabled}
          fullWidth
          value={value}
          displayEmpty={displayEmpty}
          onChange={onChange}
          variant="outlined"
          style={{
            ...(squared ? { borderRadius: 0 } : {}),
          }}
          className={densed ? classes.sb_densed : undefined}
        >
          {displayEmpty && placeholder && (
            <MenuItem value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((option, index) => (
            <MenuItem value={option} key={index}>
              {noOptionTranslation
                ? option === "all" || option === "Not specified"
                  ? t(option)
                  : option
                : ns
                ? option === "all"
                  ? t(option)
                  : t(option, { ns })
                : t(option)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
