import { gql } from "@apollo/client";
import { TableUserFragment } from "../../../fragments";

export const GET_USERS = gql`
  query GetUsers(
    $input: GetUsersInput!
    $paginationOptions: PaginationOptionsArgs!
  ) {
    getUsers(input: $input, paginationOptions: $paginationOptions) {
      total
      users {
        ...TableUser
      }
    }
  }
  ${TableUserFragment}
`;
