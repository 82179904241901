import { useQuery, useReactiveVar } from "@apollo/client";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { drawerOpenVar } from "../../../apollo";
import { Fallback, FullscreenLoader } from "../../../components";
import { GET_LEARNING_PATH_BY_SLUG } from "../../../graphql";
import {
  GetLearningPathBySlug,
  GetLearningPathBySlugVariables,
  GetLearningPathBySlug_getLearningPathBySlug,
} from "../../../graphql/queries/learningPath/getLearningBySlug/__generated__/GetLearningPathBySlug";
import { useSetHeadbarTitle } from "../../../hooks";
import { useCommonStyle } from "../../../styles/commonStyles";
import { UpdateLearningPathTexts } from "./components/UpdateLearningPathTexts";
import { UpdateMedia } from "./components/UpdateMedia";
import { UpdateStatus } from "./components/UpdateStatus";
import { UpdateSteps } from "./components/UpdateSteps";

interface LearningPathViewProps {}

export const LearningPathView: React.FC<LearningPathViewProps> = ({}) => {
  const { t } = useTranslation();
  const params = useParams<{ slug: string }>();
  useSetHeadbarTitle("Редакция марафона");
  const commonClasses = useCommonStyle();
  const [learningPath, setLearningPath] =
    useState<GetLearningPathBySlug_getLearningPathBySlug | null>(null);
  const { data, loading, error } = useQuery<
    GetLearningPathBySlug,
    GetLearningPathBySlugVariables
  >(GET_LEARNING_PATH_BY_SLUG, {
    variables: {
      slug: params.slug,
    },
  });
  const history = useHistory();

  useEffect(() => {
    if (data?.getLearningPathBySlug) {
      setLearningPath(data.getLearningPathBySlug);
    }
  }, [data]);

  if (error || (!loading && !data?.getLearningPathBySlug)) {
    return (
      <Fallback
        title={"Марафон не найден"}
        message={"Кажется, марафона с таким slug не существует"}
        onClick={() => history.push("/learning-paths")}
        btnTitle={t("Ok")}
      />
    );
  }

  if (loading || !learningPath) {
    return <FullscreenLoader transparent />;
  }

  return (
    <Box padding={4}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <UpdateLearningPathTexts learningPath={learningPath} />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <UpdateStatus learningPath={learningPath} />

          <Box height="30px" />

          <UpdateMedia learningPath={learningPath} />
        </Grid>

        <Grid item xs={12}>
          <Box height="50px" />
          <UpdateSteps learningPath={learningPath} />
        </Grid>
      </Grid>
    </Box>
  );
};
