import React from "react";
import { Fallback } from "./components";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error Boundary error: ", error);
    console.error("Error Boundary Info: ", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fallback
          title={"Something went wrong"}
          message={
            "Reload the page to restart the client and check the console logs."
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
