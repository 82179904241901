import {useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  GetPartnerCompanies_getPartnerCompanies_partnerCompanies
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanies/__generated__/GetPartnerCompanies";
import {
  GetPartnerCompanyById,
  GetPartnerCompanyByIdVariables
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyById/__generated__/GetPartnerCompanyById";
import {
  GET_PARTNER_COMPANY_BY_ID
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyById";

interface EditPartnerCompanyParams {
  id: string;
}

interface EditPartnerCompanyState {
  partnerCompany?: GetPartnerCompanies_getPartnerCompanies_partnerCompanies;
  showCreateNotification?: boolean;
}

export const useFetchPartnerCompanyFromRoute = () => {
  const { id } = useParams<EditPartnerCompanyParams>();
  const location = useLocation<EditPartnerCompanyState>();
  const [processing, setProcessing] = useState(true);
  const [partnerCompany, setPartnerCompany] = useState<GetPartnerCompanies_getPartnerCompanies_partnerCompanies | null>(null);
  const [getPartnerCompany, { error, data }] = useLazyQuery<GetPartnerCompanyById, GetPartnerCompanyByIdVariables>(
    GET_PARTNER_COMPANY_BY_ID,
    {
      onError: (err) => console.error(err),
    }
  );

  useEffect(() => {
    if (location.state.partnerCompany) {
      setPartnerCompany(location.state.partnerCompany);
      setProcessing(false);
    } else {
      getPartnerCompany({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getPartnerCompanyById) {
      setPartnerCompany(data.getPartnerCompanyById);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    partnerCompany,
    showCreateNotification: location.state.showCreateNotification
  };
};
