import { useMutation } from "@apollo/client";
import { UPDATE_PASSWORD } from "../../graphql";
import {
  UpdatePassword,
  UpdatePasswordVariables,
} from "../../graphql/mutations/me/UpdatePassword/__generated__/UpdatePassword";

export const useUpdatePassword = () => {
  const [update, { loading, error }] = useMutation<
    UpdatePassword,
    UpdatePasswordVariables
  >(UPDATE_PASSWORD, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};
