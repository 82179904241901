import {
  TableHead,
  TableCell,
  TableSortLabel,
  Checkbox,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { HeadCell, SortOrder } from "../../../lib";
import { useTableStyle } from "../styles";

interface MyTableHeadProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: SortOrder;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
  sortFields: string[];
  hideCheck?: boolean;
}

export const MyTableHead: React.FC<MyTableHeadProps> = ({
  onRequestSort,
  numSelected,
  rowCount,
  onSelectAllClick,
  orderBy,
  order,
  headCells,
  sortFields,
  hideCheck,
}) => {
  const classes = useTableStyle();
  const { t } = useTranslation();

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {!hideCheck && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.label}
            align={headCell.align}
            sortDirection={orderBy === headCell.label ? order : false}
            style={{
              color: sortFields.includes(headCell.label)
                ? "#212121"
                : "#6f6f6f",
            }}
          >
            {sortFields.includes(headCell.label) ? (
              <TableSortLabel
                active={orderBy === headCell.label}
                direction={orderBy === headCell.label ? order : "asc"}
                onClick={createSortHandler(headCell.label)}
              >
                {t(headCell.label)}
                {orderBy === headCell.label ? (
                  <span className={classes.tableHead_visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              t(headCell.label)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
