import { gql } from "@apollo/client";
import { ReferralFragment } from "../../../fragments";

export const CREATE_REFERRAL = gql`
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      ...Referral
    }
  }
  ${ReferralFragment}
`;
