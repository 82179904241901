import React from "react";
import type { PdfViewerSettings } from "../types/settings";
import { lazyLoad } from "@react-page/editor";

const BtnIcon = lazyLoad(() => import("@material-ui/icons/Slideshow"));

export const defaultTranslations = {
  pluginName: "Слайды",
  pluginDescription: "Добавить PDF-файл",
  isInlineable: true,
  widthLabel: "Ширина страницы",
  heightLabel: "Высота страницы",
  alignLabel: "Выравнивание",
  valueLabel: "Значение (в пикселях)",
};

export const defaultSettings: PdfViewerSettings = {
  Renderer: () => <>Renderer for this plugin was not provided </>,
  Controls: () => <> Controls for this plugin were not provided</>,
  translations: defaultTranslations,
  icon: <BtnIcon />,
};
