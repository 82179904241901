import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetLearningPathBySlug_getLearningPathBySlug,
  GetLearningPathBySlug_getLearningPathBySlug_path_bulletPoints,
} from "../../../../graphql/queries/learningPath/getLearningBySlug/__generated__/GetLearningPathBySlug";
import { UpdateLearningPathFormValues } from "../../../../lib";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import {
  UpdateLearningPath,
  UpdateLearningPathVariables,
} from "../../../../graphql/mutations/learningPath/updateLearningPath/__generated__/UpdateLearningPath";
import {
  GET_LEARNING_PATH_BY_SLUG,
  UPDATE_LEARNING_PATH,
} from "../../../../graphql";
import { MyTextField } from "../../../../components";
import { UpdateDescription } from "../../LearningPathsView/components/UpdateDescription";
import { BulletPoints } from "./BulletPoints";
import { UpdateLearningBulletPointInput } from "../../../../graphql/globalTypes";
import { useHistory, useParams } from "react-router-dom";

interface UpdateLearningPathTextsProps {
  learningPath: GetLearningPathBySlug_getLearningPathBySlug;
}

export const UpdateLearningPathTexts: React.FC<
  UpdateLearningPathTextsProps
> = ({ learningPath }) => {
  const { t } = useTranslation();
  const params = useParams<{ slug: string }>();
  const history = useHistory();
  const [description, setDescription] = useState<string>(
    learningPath.path.description ?? ""
  );
  const [update, { loading, error }] = useMutation<
    UpdateLearningPath,
    UpdateLearningPathVariables
  >(UPDATE_LEARNING_PATH, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LEARNING_PATH_BY_SLUG,
        variables: {
          slug: learningPath.path.slug,
        },
      },
    ],
  });

  useEffect(() => {
    if (learningPath.path.slug !== params.slug) {
      history.replace(`/learning-paths/${learningPath.path.slug}`);
    }
  }, [learningPath.path.slug]);

  function convertBulletsToInput(
    bulletPoints: GetLearningPathBySlug_getLearningPathBySlug_path_bulletPoints[]
  ): UpdateLearningBulletPointInput[] {
    return bulletPoints.map((bullet) => ({
      text: bullet.text ?? "",
      big_text: bullet.big_text ?? "",
      picture: bullet.picture
        ? {
            filename: bullet.picture.fileName,
            publicId: bullet.picture.public_id,
            type: bullet.picture.type,
            url: bullet.picture.url,
          }
        : undefined,
    }));
  }

  const initialValues: UpdateLearningPathFormValues = {
    title: learningPath.path.title,
    slug: learningPath.path.slug,
    bulletPoints: convertBulletsToInput(learningPath.path.bulletPoints),
    announcementOnly: learningPath.path.announcementOnly,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    slug: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t("Invalid slug"))
      .required(t("Required")),
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const res = await update({
            variables: {
              input: {
                id: learningPath.path.id,
                description: description,
                slug: values.slug,
                title: values.title,
                announcementOnly: values.announcementOnly,
                bulletPoints: values.bulletPoints.filter((bp) => bp.big_text),
              },
            },
          });

          setSubmitting(false);
        }}
      >
        {({ dirty, submitForm, isSubmitting, values }) => (
          <Form>
            <>
              <Field type="checkbox" name="announcementOnly" Label={{ label: "Announcement Only (Dont publish fully)" }} component={CheckboxWithLabel} />
              <Field
                component={MyTextField}
                fullWidth
                label={t("Title")}
                name="title"
                style={{ marginBottom: 20 }}
              />
              <Field
                component={MyTextField}
                fullWidth
                label={t("slug")}
                name="slug"
                style={{ marginBottom: 20 }}
              />

              <Typography variant="caption">Описание</Typography>
              <UpdateDescription
                description={description}
                setDescription={setDescription}
              />

              <Box height="50px" />

              <BulletPoints
                bulletPoints={values.bulletPoints}
                // setBulletPointsInput={setBulletPointsInput}
              />

              <Box height="50px" />

              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={submitForm}
              >
                {t("Save")}
                {isSubmitting && (
                  <CircularProgress
                    thickness={7}
                    style={{ marginLeft: 10 }}
                    size={10}
                    color="secondary"
                  />
                )}
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
