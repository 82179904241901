import { gql } from "@apollo/client";
import { ArticleFragment } from "../../../fragments";

export const CREATE_ARTICLE = gql`
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      article {
        ...Article
      }
      error {
        name
        message
      }
    }
  }
  ${ArticleFragment}
`;
