import { useQuery } from "@apollo/client";
import { FETCH_ENUMS_WITH_STATS } from "../../graphql";
import { FetchEnumsWithStats } from "../../graphql/queries/jobs/GetEnumsWithStats/__generated__/FetchEnumsWithStats";

export const useFetchEnumsWithStats = () => {
  const { data, loading, error } = useQuery<FetchEnumsWithStats>(
    FETCH_ENUMS_WITH_STATS,
    {
      onError: (err) => console.error(err),
    }
  );

  return { enums: data?.fetchEnumsWithStats, loading, error };
};
