import { gql } from "@apollo/client";
import {OrderFragment} from "../../../fragments";

export const REFUND_ORDER = gql`
    mutation RefundOrder($input: RefundOrderInput!) {
        refundOrder(input: $input) {
            ...Order
        }
    }
    ${OrderFragment}
`;