import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationToast, SelectButton } from "../../../components";
import { useBulkJobUpdate, useDeleteJobs } from "../../../hooks";
import { ReadAccess } from "../../../lib";
import { convertEnumToArray } from "../../../utils";
import {PublishStatus} from "../../../graphql/globalTypes";

interface JobBulkActionProps {
  currentAction: "Update status" | "Update read access" | "Delete" | "";
  jobsIds: string[];
  onClose: () => void;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const JobBulkAction: React.FC<JobBulkActionProps> = ({
  currentAction,
  jobsIds,
  onClose,
  setSelected,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { loading, error, update } = useBulkJobUpdate();
  const {
    loading: deleteLoading,
    error: deleteError,
    update: deleteUpdate,
  } = useDeleteJobs(jobsIds);

  const handleClose = () => {
    setValue("");
    setSelected([]);
    onClose();
  };

  const handleApply = async () => {
    try {
      if (currentAction !== "Delete") {
        await update({
          variables: {
            input: {
              jobsIds,
              ...(currentAction === "Update read access"
                ? {
                    readAccess: value,
                  }
                : {
                    status: value,
                  }),
            },
          },
        });
      } else {
        await deleteUpdate({ variables: { ids: jobsIds } });
      }
      handleClose();
    } catch (error) {}
  };

  const getOptions = useMemo(() => {
    if (currentAction === "Update read access") {
      return convertEnumToArray(ReadAccess);
    } else if (currentAction === "Update status") {
      return convertEnumToArray(PublishStatus);
    } else if (currentAction === "Delete") {
      return null;
    } else {
      return [];
    }
  }, [currentAction]);

  const getLabel = useMemo(() => {
    if (currentAction === "Update read access") {
      return t("readAccess");
    } else if (currentAction === "Update status") {
      return t("status");
    } else if (currentAction === "Delete") {
      return t("Delete");
    } else {
      return "";
    }
  }, [currentAction]);

  const getTitle = useMemo(() => {
    if (currentAction === "Update read access") {
      return t("Choose read access");
    } else if (currentAction === "Update status") {
      return t("Choose status");
    } else if (currentAction === "Delete") {
      return t("Delete");
    } else {
      return "";
    }
  }, [currentAction]);

  return (
    <>
      <Dialog open={!!currentAction} onClose={handleClose}>
        <DialogTitle>{getTitle}</DialogTitle>
        <DialogContent>
          {getOptions ? (
            <SelectButton
              options={getOptions}
              value={value}
              onChange={(e) => setValue(e?.target.value ?? "")}
              displayEmpty
              placeholder={getLabel}
              rootStyle={{ width: "100%" }}
            />
          ) : (
            <Typography>{t("Confirm delete action")}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button
            disabled={(!value && currentAction !== "Delete") || loading}
            variant="contained"
            color="primary"
            onClick={handleApply}
          >
            {t("Apply")}
            {(loading || deleteLoading) && (
              <CircularProgress
                thickness={7}
                style={{ marginLeft: 10 }}
                size={10}
                color="secondary"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {(error || deleteError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
