import { useMutation } from "@apollo/client";
import { CREATE_JOB_CATEGORY, GET_JOB_CATEGORIES } from "../../graphql";
import { CreateJobCategoryInput } from "../../graphql/globalTypes";
import {
  CreateJobCategory,
  CreateJobCategoryVariables,
} from "../../graphql/mutations/jobs/CreateJobCategory/__generated__/CreateJobCategory";

export const useCreateJobCategory = () => {
  const [create, { data, loading, error }] = useMutation<
    CreateJobCategory,
    CreateJobCategoryVariables
  >(CREATE_JOB_CATEGORY, {
    onError: (err) => console.log(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_JOB_CATEGORIES,
      },
    ],
  });

  return {
    create,
    data,
    loading,
    error,
  };
};
