import {
  DialogContent,
  Dialog,
  Button,
  CircularProgress,
  InputAdornment,
  DialogActions,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetSeoTags_getSeoTags } from "../../../graphql/queries/seotags/getSeoTags/__generated__/GetSeoTags";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

interface EditTagProps {
  open: boolean;
  handleClose: () => void;
  tag?: GetSeoTags_getSeoTags;
  handleSubmit: (values: SeoTagFormValues) => Promise<void>;
}

export interface SeoTagFormValues {
  title: string;
  url: string;
  description: string;
}

export const EditTag: React.FC<EditTagProps> = ({
  open,
  handleClose,
  tag,
  handleSubmit,
}) => {
  const [validate, setValidate] = useState(false);
  const { t } = useTranslation();

  const initialValues: SeoTagFormValues = {
    title: tag?.title ?? "",
    url: tag?.url ?? "",
    description: tag?.description ?? "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t("Required")),
    url: Yup.string().required(t("Required")),
    description: Yup.string().required(t("Required")),
  });

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Formik
          enableReinitialize
          validateOnBlur={validate}
          validateOnChange={validate}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            setSubmitting(true);

            try {
              await handleSubmit(values);
              handleClose();
            } catch (error) {
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ dirty, submitForm, isSubmitting, values }) => (
            <Form>
              <>
                <DialogContent>
                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    label={t("URL")}
                    name="url"
                    style={{ marginBottom: 20 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          https://networkio.io
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    label={"Title"}
                    name="title"
                    style={{ marginBottom: 20 }}
                  />

                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    label={"Description"}
                    name="description"
                    multiline
                    minRows={3}
                    style={{ marginBottom: 20 }}
                  />
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => {
                      setValidate(true);
                      console.log(values);
                      submitForm();
                    }}
                    disabled={isSubmitting || !dirty}
                  >
                    {t("Save")}
                    {isSubmitting && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
