import { gql } from "@apollo/client";
import { LearningPathFragment, MetaFragment } from "../../../fragments";

export const GET_LEARNING_PATHS = gql`
  query GetLearningPaths(
    $status: String
    $paginationOptions: PaginationOptionsArgs!
    $language: AppLanguage
  ) {
    getLearningPaths(paginationOptions: $paginationOptions, status: $status, language:$language) {
      meta {
        ...Meta
      }
      learningPaths {
        ...LearningPath
      }
    }
  }
  ${MetaFragment}
  ${LearningPathFragment}
`;
