import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useLoaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      height: "100vh",
      background: theme.palette.primary.dark,
      backgroundImage:
        "radial-gradient(circle farthest-corner at center, #303f9f 0%, #1C262B 100%)",
      position: "fixed",
      filter: "brightness(0.5)",
      top: 0,
      left: 0,
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    transparent: {
      background: "#1976d224",
      backgroundImage: "unset",
    },
    container: {},
    progress_absolute: {
      position: "absolute",
      top: "calc(50% - 32px)",
      left: "calc(50% - 32px)",
    },
    progress: {
      width: "64px",
      height: "64px",
      borderRadius: "50%",
      perspective: "800px",
    },
    inner: {
      position: "absolute",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      "&.one": {
        left: "0%",
        top: "0%",
        animation: "rotate-one 1s linear infinite",
        borderBottom: `3px solid ${theme.palette.secondary.light}`,
      },
      "&.two": {
        right: "0%",
        top: "0%",
        animation: "rotate-two 1s linear infinite",
        borderRight: `3px solid ${theme.palette.secondary.main}`,
      },
      "&.three": {
        right: "0%",
        bottom: "0%",
        animation: "rotate-three 1s linear infinite",
        borderTop: `3px solid ${theme.palette.secondary.dark}`,
      },
    },
  })
);
