import { gql } from "@apollo/client";
import { JobFragment } from "../../../fragments";

export const UPDATE_JOB = gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      error {
        name
        message
      }
      job {
        ...Job
      }
    }
  }
  ${JobFragment}
`;
