import React, {useEffect, useState} from 'react';
import {useSetHeadbarTitle} from "../../hooks";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/system";
import {AddNewButton, NotificationToast} from "../../components";
import {useHistory} from "react-router-dom";
import {GetExpertsInput, PaginationOptionsArgs} from "../../graphql/globalTypes";
import {useQuery} from "@apollo/client";
import {
  GetAffiliates,
  GetAffiliates_getAffiliates_affiliates,
  GetAffiliatesVariables
} from "../../graphql/queries/affiliate/GetAffiliates/__generated__/GetAffiliates";
import {GET_AFFILIATES} from "../../graphql/queries/affiliate/GetAffiliates";
import {AffiliateTable} from "./AffiliateTable";
import {AffiliatesFilterBar} from "./AffiliatesFilterBar";

export const Affiliates = () => {
  const {t} = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Affiliates"));
  const handleAddNewAffiliateClick = () => {
    history.push("/affiliates/create");
  }

  const [totalAffiliates, setTotalAffiliates] = useState(0);
  const [affiliates, setAffiliates] = useState<GetAffiliates_getAffiliates_affiliates[]>([]);
  const [filterParams, setFilterParams] = useState<GetExpertsInput>({});
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      },
    });

  const { data, loading, error } = useQuery<
    GetAffiliates,
    GetAffiliatesVariables
  >(GET_AFFILIATES, {
    onError: (err) => console.error(err),
    variables: {
      input: filterParams,
      paginationOptions
    }
  });

  useEffect(() => {
    if (data) {
      setTotalAffiliates(data.getAffiliates.total);
      setAffiliates(data.getAffiliates.affiliates);
    }
  }, [data]);

  return (
    <Box margin="20px">
      <Box textAlign="right" margin="10px 0 0">
        <AffiliatesFilterBar filterParams={filterParams} setFilterParams={setFilterParams}/>
        <Box height="10px"/>
        <AddNewButton title={t("Add new affiliate")}
                      onClick={handleAddNewAffiliateClick}/>
        <AffiliateTable
          loading={loading}
          affiliates={affiliates}
          totalAffiliates={totalAffiliates}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}/>
      </Box>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};
