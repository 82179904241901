import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { headerTitleVar } from "../../apollo";
import {
  Fallback,
  FullscreenLoader,
  NotificationToast,
} from "../../components";
import { GetUserById_getUserById as IUser } from "../../graphql/queries/users/GetUserById/__generated__/GetUserById";
import { useFetchUserFromRoute, useSetHeadbarTitle } from "../../hooks";
import { useCommonStyle } from "../../styles/commonStyles";
import {
  EditAccount,
  EditPersonalInfo,
  UserSummary,
  ViewPreferences,
} from "./components";

export const EditUser: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Loading..."));
  const history = useHistory();
  const commonClasses = useCommonStyle();
  const [user, setUser] = useState<IUser | null>(null);
  const {
    user: initUser,
    error: initError,
    redirect,
    loading: initLoading,
    showCreateNotification,
  } = useFetchUserFromRoute();

  useEffect(() => {
    if (initUser) {
      setUser(initUser);
      headerTitleVar(
        `${t(initUser.role)} ${initUser.firstName} ${initUser.lastName}`
      );
    }
  }, [initUser]);

  if (initError || (!initUser && !initLoading)) {
    return (
      <Fallback
        title={t("User not found")}
        message={t("Seems like the user you want does not exist")}
        onClick={() => history.push("/users")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (redirect) {
    return <Redirect to={{ pathname: "/profile" }} />;
  }

  if (initLoading || !user) {
    return <FullscreenLoader transparent />;
  }

  return (
    <Box className={commonClasses.sectionWithSticky}>
      <Box className={commonClasses.sectionWithSticky__scrollable}>
        <EditAccount user={user} />

        <Box marginY={4} />

        {user.customerInfo && <ViewPreferences user={user} />}

        <Box marginY={4} />

        <EditPersonalInfo user={user} />
      </Box>

      <UserSummary user={user} setUser={setUser} />

      {showCreateNotification && (
        <NotificationToast
          message={t("UserCreated")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};
