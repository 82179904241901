import { useReactiveVar } from "@apollo/client";
import React from "react";
import { userVar } from "../../../apollo";
import { UserSummary } from "../../users";

export const RightSection: React.FC = ({}) => {
  const user = useReactiveVar(userVar);

  return <UserSummary user={user as any} isMe />;
};
