import { builder, BuilderComponent } from "@builder.io/react";
import { Avatar, Box, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { headerTitleVar } from "../../apollo";
import { Fallback, FullscreenLoader } from "../../components";
import {
  useFetchArticleContentFromRoute,
  useSetHeadbarTitle,
} from "../../hooks";
builder.init(process.env.REACT_APP_BUILDER_PUBLIC_KEY!);

export const ViewArticle: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Article Content Viewer"));
  const {
    error: initError,
    loading: initLoading,
    url,
    title,
    article,
  } = useFetchArticleContentFromRoute();
  const [content, setContent] = useState<any>(undefined);
  const [processing, setProcessing] = useState(true);
  const isMobile = useMediaQuery("(max-width:889px)");

  useEffect(() => {
    console.log(article);
  }, [article]);

  useEffect(() => {
    if (title) headerTitleVar(title);
  }, [title]);

  useEffect(() => {
    if (!initLoading && !url) setProcessing(false);
  }, [initLoading, url]);

  useEffect(() => {
    if (url) {
      (async () => {
        const c = await builder.get("page", { url }).promise();
        setContent(c);
        setProcessing(false);
      })();
    }
  }, [url]);

  if (initError) {
    return (
      <Fallback
        title={t("Article not found")}
        message={t("Seems like the article you want does not exist")}
        onClick={() => history.push("/articles")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading || processing) {
    return <FullscreenLoader transparent />;
  }

  return (
    <Box margin="auto" maxWidth={900}>
      <Typography
        variant="h5"
        align={isMobile ? "center" : undefined}
        style={{ textTransform: "uppercase", fontWeight: "bold" }}
      >
        {title}
      </Typography>

      <Box
        marginY={2}
        display="flex"
        alignItems="center"
        justifyContent={isMobile ? "center" : undefined}
      >
        <Avatar
          alt="Networkio"
          src={
            article?.author
              ? article.author?.avatar?.url
              : "/assets/logo-fav.svg"
          }
        />
        <Box marginX={1} />
        <Typography variant="subtitle2">
          {article?.author
            ? `${article?.author.firstName} ${article?.author.lastName}`
            : "Networkio"}
        </Typography>
      </Box>

      <Box marginY={2}>
        <Typography color="textSecondary" variant="subtitle1">
          {article?.description}
        </Typography>
      </Box>

      {article?.picture?.url && (
        <Box
          position="relative"
          width="100%"
          maxHeight="300px"
          minHeight="250px"
        >
          <img
            src={article?.picture?.url ?? "/assets/random.jpg"}
            alt={article?.title}
            style={{
              objectFit: "cover",
              width: "100%",
              minHeight: `inherit`,
              maxHeight: `inherit`,
            }}
          />
        </Box>
      )}

      {content && <BuilderComponent model="page" content={content} />}
    </Box>
  );
};
