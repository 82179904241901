import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {Field, Form, Formik, FormikErrors} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {MyTextField, NotificationToast,} from "../../../components";
import {
  GetExpertActivities_getExpertActivities
} from "../../../graphql/queries/experts/getExpertActivities/__generated__/GetExpertActivities";
import {
  useUpdateExpertActivity
} from "../../../hooks/mutations/experts/useUpdateExpertActivity";
import {
  useCreateExpertActivity
} from "../../../hooks/mutations/experts/useCreateExpertActivity";

interface EditExpertActivityProps {
  expertActivity: GetExpertActivities_getExpertActivities | null;
  open: boolean;
  onClose: () => void;
}

export const EditExpertActivity: React.FC<EditExpertActivityProps> = ({
  expertActivity,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [enableValidation, setEnableValidation] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { update, uLoading } = useUpdateExpertActivity();
  const { create, loading } = useCreateExpertActivity();

  useEffect(() => {
    if (!open) {
      setServerError(null);
    }
  }, [open]);

  const initialValues: FormValues = {
    title: expertActivity?.title ?? "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t("Required")),
  });

  async function handleSubmit(
    values :FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    try {
      if (!expertActivity) {
        const res = await create({
          variables: { input:values },
        });
        if (res.data?.createExpertActivity.error) {
          setErrors({ title: t("Title already exists") });
        } else {
          onClose();
        }
      } else {
        const {title}=values
        const res = await update({
          variables: {
            input: {
              id: expertActivity.id,
              title
            },
          },
        });
        if (res.data?.updateExpertActivity.error) {
          setErrors({ title: t("Title already exists") });
        } else {
          onClose();
        }
      }
    } catch (err) {
      setServerError(err as any);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {expertActivity ? t("Edit expert activity") : t("Add expert activity")}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (values, { setErrors, setSubmitting }) =>
          await handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({ values, dirty, submitForm }) => (
          <Form>
            <>
              <DialogContent>
                <Field
                  component={MyTextField}
                  fullWidth
                  label={t("Title")}
                  name="title"
                  style={{ marginBottom: 20, marginTop: 30 }}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={loading || uLoading} onClick={onClose}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={
                    ((!values.title || !dirty) &&
                      (!values.title)) ||
                    loading ||
                    uLoading
                  }
                >
                  {t("Save")}
                  {(loading || uLoading) && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>

      {serverError && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Dialog>
  );
};

interface FormValues {
  title: string;
}
