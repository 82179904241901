export const iconStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  padding: "0",
  color: "#aaa",
  textAlign: "center",
  minWidth: 64,
  minHeight: 64,
  maxHeight: 256,
};
