import {useMutation} from "@apollo/client";
import {
  DeleteExpert,
  DeleteExpertVariables
} from "../../../graphql/mutations/experts/deleteExpert/__generated__/DeleteExpert";
import {DELETE_EXPERT} from "../../../graphql/mutations/experts/deleteExpert";
import {GET_EXPERTS} from "../../../graphql";

export const useDeleteExpert = () => {
  const [deleteExpert, { loading, error }] = useMutation<
    DeleteExpert,
    DeleteExpertVariables
    >(DELETE_EXPERT, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_EXPERTS,
        variables: {
          input:{},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "latest",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  return {
    deleteExpert,
    loading,
    error,
  };
};