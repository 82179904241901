import { useMutation } from "@apollo/client";
import { BULK_JOBS_UPDATE } from "../../graphql";
import {
  BulkJobsUpdate,
  BulkJobsUpdateVariables,
} from "../../graphql/mutations/jobs/BulkArticlesUpdate/__generated__/BulkJobsUpdate";

export const useBulkJobUpdate = () => {
  const [update, { loading, error }] = useMutation<
    BulkJobsUpdate,
    BulkJobsUpdateVariables
  >(BULK_JOBS_UPDATE, {
    onError: (err) => console.error(err),
  });

  return { loading, error, update };
};
