import { useReactiveVar } from "@apollo/client";
import { AppBar, Box, CssBaseline } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { drawerOpenVar } from "../apollo";
import { useIsDesktopView } from "../hooks";
import { HeadToolbar, SideDrawer } from "./components";
import { DesktopOnly } from "./DesktopOnly";
import { useLayoutStyle } from "./styles";

export const DashbordLayout: React.FC = ({ children }) => {
  const classes = useLayoutStyle();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  const isDesktop = useIsDesktopView();

  if (!isDesktop) {
    return <DesktopOnly />;
  }

  return (
    <Box display="flex">
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <HeadToolbar />
      </AppBar>

      <SideDrawer />

      <main className={classes.dl_content}>
        <Box className={classes.drawerHeader} />

        {children}
      </main>
    </Box>
  );
};
