import {gql} from "@apollo/client";
import {MetaFragment, OrderFragment} from "../../../fragments";

export const GET_ORDERS = gql`
    query GetOrders(
        $filter: GetOrdersFilter!
        $paginationOptions: PaginationOptionsArgs!
    ) {
        getOrders(filter: $filter, paginationOptions: $paginationOptions) {
            orders{
                ...Order
            }
            meta {
                ...Meta
            }
        }
    }
    ${OrderFragment}
    ${MetaFragment}
`;
