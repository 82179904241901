/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppLanguage {
  en = "en",
  ru = "ru",
}

export enum BannerKind {
  MAIN_HOME_BANNER = "MAIN_HOME_BANNER",
  SECONDARY_HOME_BANNER = "SECONDARY_HOME_BANNER",
}

export enum BillingCycle {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
}

export enum CouponKind {
  PRODUCTS = "PRODUCTS",
  PRODUCTS__BY_CATEGORIES = "PRODUCTS__BY_CATEGORIES",
  PRODUCTS__BY_IDS = "PRODUCTS__BY_IDS",
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
  UNIVERSAL = "UNIVERSAL",
}

export enum Currency {
  AED = "AED",
  ARS = "ARS",
  AUD = "AUD",
  BGN = "BGN",
  BHD = "BHD",
  BRL = "BRL",
  CAD = "CAD",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  FJD = "FJD",
  GBP = "GBP",
  GEL = "GEL",
  GNF = "GNF",
  HKD = "HKD",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  JPY = "JPY",
  KRW = "KRW",
  KWD = "KWD",
  KZT = "KZT",
  MDL = "MDL",
  MXN = "MXN",
  MYR = "MYR",
  NAD = "NAD",
  NOK = "NOK",
  NZD = "NZD",
  OMR = "OMR",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RUB = "RUB",
  SAR = "SAR",
  SEK = "SEK",
  SGD = "SGD",
  THB = "THB",
  TRY = "TRY",
  TWD = "TWD",
  UAH = "UAH",
  USD = "USD",
}

export enum FileType {
  AUDIO = "AUDIO",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum MailerLiteGroups {
  ai_tools_user = "ai_tools_user",
  club_curious_about_bundle = "club_curious_about_bundle",
  club_curious_about_consultation = "club_curious_about_consultation",
  club_curious_about_cv = "club_curious_about_cv",
  club_curious_about_cv_cover_edit = "club_curious_about_cv_cover_edit",
  club_curious_about_interview = "club_curious_about_interview",
  club_curious_about_linkedin = "club_curious_about_linkedin",
  cover_letter_user = "cover_letter_user",
  forum_commentator = "forum_commentator",
  forum_theme_writer = "forum_theme_writer",
  free_curious_about_bundle = "free_curious_about_bundle",
  free_curious_about_consultation = "free_curious_about_consultation",
  free_curious_about_cv = "free_curious_about_cv",
  free_curious_about_cv_cover_edit = "free_curious_about_cv_cover_edit",
  free_curious_about_interview = "free_curious_about_interview",
  free_curious_about_linkedin = "free_curious_about_linkedin",
  lead_magnet_1 = "lead_magnet_1",
  payment_source_ai_tools = "payment_source_ai_tools",
  payment_source_articles = "payment_source_articles",
  payment_source_jobs = "payment_source_jobs",
  payment_source_other = "payment_source_other",
  promoter_candidates = "promoter_candidates",
  promoters = "promoters",
  registration_source_ai_tools = "registration_source_ai_tools",
  registration_source_articles = "registration_source_articles",
  registration_source_courses = "registration_source_courses",
  registration_source_jobs = "registration_source_jobs",
  registration_source_other = "registration_source_other",
  stripe_sub = "stripe_sub",
  unihow_all_sub = "unihow_all_sub",
  unihow_cancel_risk = "unihow_cancel_risk",
  unihow_canceled_club = "unihow_canceled_club",
  unihow_failed_payment = "unihow_failed_payment",
  unihow_failed_payment_twice = "unihow_failed_payment_twice",
  unihow_free_sub = "unihow_free_sub",
  unihow_paid_sub = "unihow_paid_sub",
  unihow_returning_customer = "unihow_returning_customer",
  yandex_sub = "yandex_sub",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export enum PaymentProvider {
  STRIPE = "STRIPE",
  YOO = "YOO",
}

export enum ProductCategory {
  ACCESS_TO_MARATHON = "ACCESS_TO_MARATHON",
  BUNDLE = "BUNDLE",
  CONSULTATION_WITH_A_COACH = "CONSULTATION_WITH_A_COACH",
  DOWNLOADABLE_MEDIA = "DOWNLOADABLE_MEDIA",
  EXTERNAL_ACCESS_LINK = "EXTERNAL_ACCESS_LINK",
  LEARNING_PATH_ACCESS = "LEARNING_PATH_ACCESS",
}

export enum ProductKind {
  CAREER = "CAREER",
  EDUCATION = "EDUCATION",
  SELF_DEVELOPMENT = "SELF_DEVELOPMENT",
}

export enum ProductVariant {
  BUNDLE = "BUNDLE",
  SEPARATE_ITEM = "SEPARATE_ITEM",
}

export enum PromoterPayoutStatus {
  FAILED = "FAILED",
  PAID = "PAID",
  PENDING = "PENDING",
}

export enum PublishStatus {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  MODERATION = "MODERATION",
  PUBLISHED = "PUBLISHED",
}

export enum Regions {
  AFRICA = "AFRICA",
  AFRICA_CENTRAL = "AFRICA_CENTRAL",
  AFRICA_EAST = "AFRICA_EAST",
  AFRICA_NORTH = "AFRICA_NORTH",
  AFRICA_SOUTH = "AFRICA_SOUTH",
  AFRICA_WEST = "AFRICA_WEST",
  ASIA = "ASIA",
  AUSTRALIA = "AUSTRALIA",
  CARIBBEAN = "CARIBBEAN",
  CENTRAL_AMERICA = "CENTRAL_AMERICA",
  CENTRAL_ASIA = "CENTRAL_ASIA",
  EASTERN_ASIA = "EASTERN_ASIA",
  EASTERN_EUROPE = "EASTERN_EUROPE",
  EASTERN_EUROPE_BALTIC = "EASTERN_EUROPE_BALTIC",
  EASTERN_EUROPE_CENTRAL = "EASTERN_EUROPE_CENTRAL",
  EASTERN_EUROPE_EAST = "EASTERN_EUROPE_EAST",
  EASTERN_EUROPE_SOUTH = "EASTERN_EUROPE_SOUTH",
  EUROPE = "EUROPE",
  EUROPE_CENTRAL = "EUROPE_CENTRAL",
  MIDDLE_EAST = "MIDDLE_EAST",
  NEAR_EAST = "NEAR_EAST",
  NORTHERN_EUROPE = "NORTHERN_EUROPE",
  NORTH_AMERICA = "NORTH_AMERICA",
  OCEANIA = "OCEANIA",
  SOUTHEAST_ASIA = "SOUTHEAST_ASIA",
  SOUTHERN_EUROPE = "SOUTHERN_EUROPE",
  SOUTH_AMERICA = "SOUTH_AMERICA",
  SOUTH_AMERICA_NORTH = "SOUTH_AMERICA_NORTH",
  SOUTH_AMERICA_SOUTH = "SOUTH_AMERICA_SOUTH",
  SOUTH_ASIA = "SOUTH_ASIA",
  WESTERN_ASIA = "WESTERN_ASIA",
  WESTERN_EUROPE = "WESTERN_EUROPE",
}

export enum ReviewCategory {
  Club = "Club",
  CoverTool = "CoverTool",
  General = "General",
  LinkedIn = "LinkedIn",
  Resume = "Resume",
  SelfDevelopment = "SelfDevelopment",
  Services = "Services",
  Studies = "Studies",
  UN = "UN",
}

export enum TrackerItemStatus {
  ACCEPTED = "ACCEPTED",
  APPLIED = "APPLIED",
  APPLYING = "APPLYING",
  ARCHIVED = "ARCHIVED",
  BOOKMARKED = "BOOKMARKED",
  DECLINED = "DECLINED",
  INTERVIEWING = "INTERVIEWING",
  NEGOTIATING = "NEGOTIATING",
  REJECTED = "REJECTED",
}

export enum UserRole {
  ADMIN = "ADMIN",
  COACH = "COACH",
  CUSTOMER = "CUSTOMER",
  GLOBAL_ADMIN = "GLOBAL_ADMIN",
  MODERATOR = "MODERATOR",
  PROMOTER = "PROMOTER",
}

export interface AccessToLearningPathPropsInput {
  learningPathId: string;
}

export interface AccessToMarathonPropsInput {
  marathonTitle: string;
  linkForBooking: string;
}

export interface AddEnumInput {
  name: string;
  translation?: string | null;
  factor?: number | null;
  repoName: string;
  logo?: CLFileInput | null;
}

export interface AdminGetJobsInput {
  textSearch?: string | null;
  companyId?: string | null;
  authorId?: string | null;
  periodFrom?: any | null;
  periodTo?: any | null;
  readAccess?: string | null;
  sourceType?: string | null;
  jobCategory?: string | null;
  employmentType?: string | null;
  seniority?: string | null;
  countryId?: string | null;
  status?: string | null;
}

export interface BulkArticlesUpdateInput {
  articleIds: string[];
  readAccess?: string | null;
  status?: string | null;
}

export interface BulkJobsUpdateInput {
  jobsIds: string[];
  readAccess?: string | null;
  status?: string | null;
}

export interface CLFileInput {
  publicId: string;
  url: string;
  filename: string;
  type: string;
}

export interface ConsultationWithCoachPropsInput {
  coachId: string;
  linkForBooking: string;
}

export interface ContactsInput {
  linkedIn?: string | null;
  twitter?: string | null;
  telegram?: string | null;
  youTube?: string | null;
  website?: string | null;
  vk?: string | null;
  instagram?: string | null;
}

export interface CreateAffiliateInput {
  name: string;
  userId: string;
  email: string;
  affiliateApplicationText: string;
  status: string;
}

export interface CreateArticleCategoryInput {
  name: string;
  picture?: CLFileInput | null;
}

export interface CreateArticleInput {
  title: string;
  authorId?: string | null;
  readAccess: string;
  status: string;
  categoriesIds?: string[] | null;
  description?: string | null;
  slug: string;
  picture?: CLFileInput | null;
  content?: string | null;
  tags?: string[] | null;
  hideHalf?: boolean | null;
  isFeatured?: boolean | null;
  hiddenFromArticles?: boolean | null;
  featureOrder?: number | null;
  recentlyUpdated?: boolean | null;
  isPinned?: boolean | null;
  language?: AppLanguage | null;
  withVideo?: boolean | null;
  articleText?: string | null;
  video?: string | null;
  buttonLink?: string | null;
  buttonTitle?: string | null;
}

export interface CreateBannerInput {
  kind: BannerKind;
  title: string;
  text?: string | null;
  extraText?: string | null;
  buttonTitle?: string | null;
  nextLink?: string | null;
  href?: string | null;
  image?: FileInput | null;
  imageMobile?: FileInput | null;
  isFree: boolean;
  isRegistered: boolean;
  isPaid: boolean;
  sortOrder: number;
  status: PublishStatus;
  language?: AppLanguage | null;
}

export interface CreateCompanyInput {
  name: string;
  nameAbbreviations?: string[] | null;
  salaryFactor: number;
  companyLogo?: FileInput | null;
}

export interface CreateCouponInput {
  couponCode: string;
  countUsers: number;
  countTimes: number;
  promoterId?: string | null;
  discountPercentage: number;
  kind: CouponKind;
  productsIds?: string[] | null;
  productCategories?: ProductCategory[] | null;
}

export interface CreateExpertActivityInput {
  title: string;
}

export interface CreateExpertCompanyInput {
  name: string;
  logo?: FileInput | null;
}

export interface CreateExpertInput {
  name: string;
  userId?: string | null;
  position: string;
  description: string;
  experience?: ExperienceItemInput[] | null;
  usefulness?: string | null;
  slug: string;
  picture?: FileInput | null;
  expertRequests?: string[] | null;
  expertActivities?: string[] | null;
  services?: ExpertServiceInput[] | null;
  reviews?: ExpertReviewInput[] | null;
  locations?: InputAddress_Gql[] | null;
  contacts?: ContactsInput | null;
  languages?: string[] | null;
  status: string;
}

export interface CreateExpertRequestInput {
  category: string;
  title: string;
}

export interface CreateJobCategoryInput {
  name: string;
  engTrans?: string | null;
  minSalary: number;
  maxSalary: number;
  avgSalary: number;
  logo?: CLFileInput | null;
}

export interface CreateJobInput {
  sourceId: string;
  sourceType: string;
  applicationLink: string;
  title: string;
  isUN: boolean;
  description: string;
  companyId?: string | null;
  openUntil: any;
  tags?: string[] | null;
  readAccess: string;
  status: string;
  companyLogo?: FileInput | null;
}

export interface CreateLearningPathInput {
  title: string;
  slug: string;
  heroVideoUrl?: string | null;
  description?: string | null;
  heroPic?: CLFileInput | null;
  language?: AppLanguage | null;
}

export interface CreatePartnerCompanyCategoryInput {
  name: string;
  logo?: CLFileInput | null;
}

export interface CreatePartnerCompanyInput {
  title: string;
  subtitle: string;
  description: string;
  slug: string;
  linkedId?: string | null;
  twitter?: string | null;
  telegram?: string | null;
  youTube?: string | null;
  website?: string | null;
  status: string;
  partnerCompanyCategories: string[];
  tags: string[];
  features?: string[] | null;
  services: PartnerCompanyServiceInput[];
  reviews: PartnerCompanyReviewInput[];
  locations: InputAddress_Gql[];
  languages: string[];
  picture?: FileInput | null;
}

export interface CreatePartnerCompanyTagInput {
  category: string;
  title: string;
}

export interface CreateProductInput {
  canBeSoldSeparately: boolean;
  discountPercentage?: string | null;
  baseNumber?: number | null;
  purchasesPerMonth?: number | null;
  sortOrder: number;
  isHidden: boolean;
  linkToProductInfo?: string | null;
  status: PublishStatus;
  price: PriceInput;
  marketingPrice: string;
  slug: string;
  variant: ProductVariant;
  title: string;
  isFeatured: boolean;
  description: string;
  shortDescription?: string | null;
  features?: string[] | null;
  category: ProductCategory;
  kind: ProductKind;
  language: AppLanguage;
  picture?: FileInput | null;
  productsIds?: string[] | null;
  consultationWithCoachPropsInput?: ConsultationWithCoachPropsInput | null;
  downloadableMediaPropsInput?: DownloadableMediaPropsInput | null;
  externalAccessLinkPropsInput?: ExternalAccessLinkPropsInput | null;
  accessToLearningPathPropsInput?: AccessToLearningPathPropsInput | null;
  accessToMarathonPropsInput?: AccessToMarathonPropsInput | null;
}

export interface CreatePromoterInput {
  userId?: string | null;
  promoterData?: PromoterDataInput | null;
  password: string;
  username: string;
  couponSubscriptionDiscount: number;
  couponProductDiscount: number;
  productCommission: number;
  subscriptionCommission: number;
  couponCode: string;
  language?: string | null;
}

export interface CreateReferralInput {
  firstName: string;
  lastName: string;
  type: string;
  email?: string | null;
  phone?: string | null;
  facebook?: string | null;
  telegram?: string | null;
  linkedIn?: string | null;
  companiesIds?: string[] | null;
}

export interface CreateReviewInput {
  firstName: string;
  lastName: string;
  position: string;
  text: string;
  categories: ReviewCategory[];
  avatar?: CLFileInput | null;
  language?: AppLanguage | null;
}

export interface CreateSeoTagInput {
  title: string;
  description: string;
  url: string;
}

export interface CreateSubscriptionPlanInput {
  title: string;
  description: string;
  features?: string[] | null;
  billingCycle: BillingCycle;
  price: PriceInput;
  language?: AppLanguage | null;
}

export interface CreateTagInput {
  title: string;
}

export interface CreateTrackerItemInput {
  title: string;
  company: string;
  link?: string | null;
  notes?: string | null;
  description?: string | null;
  salary?: string | null;
  companyLogo?: FileInput | null;
  countryId?: string | null;
  jobId?: string | null;
}

export interface DeleteBulkEnumInput {
  ids: string[];
  repoName: string;
}

export interface DiscountInput {
  discountPercentage: number;
  numberOfCharges: number;
}

export interface DownloadableMediaPropsInput {
  files: FileInput[];
}

export interface ExperienceItemInput {
  company: string;
  position: string;
  startedAt?: any | null;
  finishedAt?: any | null;
  isWorkingCurrently?: boolean | null;
}

export interface ExpertReviewInput {
  title?: string | null;
  text?: string | null;
  userName?: string | null;
  picture?: FileInput | null;
  language?: AppLanguage | null;
}

export interface ExpertServiceInput {
  title: string;
  description: string;
  meetingsInfo?: string | null;
  price?: PriceInput | null;
  affiliateLink?: string | null;
  promoText?: string | null;
  stripeProductId?: string | null;
  stripePriceId?: string | null;
  document?: FileInput | null;
}

export interface ExternalAccessLinkPropsInput {
  url: string;
}

export interface FetchUserPreferencesInput {
  textSearch?: string | null;
  isFellow?: boolean | null;
  nationality?: string | null;
  countryId?: string | null;
}

export interface FileInput {
  fileName?: string | null;
  source: string;
  type?: FileType | null;
}

export interface GetAffiliatesInput {
  textSearch?: string | null;
  status?: string | null;
}

export interface GetArticlesAdminInput {
  textSearch?: string | null;
  readAccess?: string | null;
  status?: string | null;
  categoriesIds?: string[] | null;
  language?: AppLanguage | null;
}

export interface GetBannersInput {
  textSearch?: string | null;
  kind?: string | null;
  status?: PublishStatus | null;
  language?: AppLanguage | null;
}

export interface GetCompaniesFilter {
  search?: string | null;
}

export interface GetExpertsInput {
  textSearch?: string | null;
  expertRequests?: string[] | null;
  countriesIds?: string[] | null;
  expertActivities?: string[] | null;
  expertCompanies?: string[] | null;
  languages?: string[] | null;
  status?: string | null;
}

export interface GetOrdersFilter {
  paymentProvider?: PaymentProvider | null;
  stripeId?: string | null;
  customerId?: string | null;
  status?: OrderStatus | null;
  productId?: string | null;
  promoterId?: string | null;
}

export interface GetPartnerCompaniesInput {
  textSearch?: string | null;
  tags?: string[] | null;
  countriesIds?: string[] | null;
  partnerCompanyCategories?: string[] | null;
  languages?: string[] | null;
  status?: string | null;
}

export interface GetProductsFilter {
  status?: PublishStatus | null;
  isHidden?: boolean | null;
  canBeSoldSeparately?: boolean | null;
  variant?: ProductVariant | null;
  category?: ProductCategory | null;
  kind?: ProductKind | null;
  language?: AppLanguage | null;
  isFeatured?: boolean | null;
}

export interface GetSubscriptionsInput {
  textSearch?: string | null;
  planId?: string | null;
  status?: string | null;
  couponCode?: string | null;
  language?: AppLanguage | null;
}

export interface GetTrackerItemsInput {
  textSearch?: string | null;
  status?: string | null;
  countryId?: string | null;
  createdById?: string | null;
  createdFrom?: any | null;
  createdTo?: any | null;
}

export interface GetUsersInput {
  textSearch?: string | null;
  emailVerified?: boolean | null;
  isFellow?: boolean | null;
  roles?: string[] | null;
  nationality?: string | null;
  countryId?: string | null;
  iWantNetwork?: boolean | null;
}

export interface GlobalVarInput {
  usdToRubExchangeRateForProducts: number;
}

export interface InputAddress_Gql {
  countryId?: string | null;
  zipCode?: string | null;
  state?: string | null;
  city?: string | null;
}

export interface PaginationOptionsArgs {
  sort?: PaginationSortArgs | null;
  limit?: number | null;
  skip?: number | null;
}

export interface PaginationSortArgs {
  field: string;
  sortBy: string;
}

export interface PartnerCompanyReviewInput {
  title?: string | null;
  text?: string | null;
  userName?: string | null;
  picture?: FileInput | null;
  language?: AppLanguage | null;
}

export interface PartnerCompanyServiceInput {
  title: string;
  description?: string | null;
  price?: PriceInput | null;
  discountPercentage: number;
  affiliateLink?: string | null;
  promoText?: string | null;
  picture?: FileInput | null;
}

export interface PayoutListFilter {
  promoterId?: string | null;
  periodFrom?: any | null;
  periodTo?: any | null;
  status?: string | null;
}

export interface PriceInput {
  value: string;
  currency: Currency;
  stripePriceId?: string | null;
}

export interface PromoterDataInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface PromotersListFilter {
  isActive?: boolean | null;
  search?: string | null;
}

export interface ReferralsFilter {
  companyId?: string | null;
  search?: string | null;
}

export interface RefundOrderInput {
  id: string;
  amountToRefundInOrderCurrency: number;
}

export interface RefundSubscriptionSingleTransactionInput {
  subscriptionId: string;
  paymentId: string;
  value: string;
}

export interface RefundSubscriptionTransactionsInput {
  subscriptionId: string;
  paymentIds: string[];
}

export interface RegisterUserInput {
  role: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ReplaceBulkEnumInput {
  ids: string[];
  name: string;
  repoName: string;
}

export interface ResetPasswordByTokenInput {
  password: string;
  token: string;
  tokenClient: string;
}

export interface ReviewOrderInput {
  id: string;
  order: number;
}

export interface SignInInput {
  creds: SignInViaEmailOrUsernameInput;
  userInfo?: UserInfoInput | null;
}

export interface SignInViaEmailOrUsernameInput {
  email?: string | null;
  username?: string | null;
  password: string;
  tokenClient: string;
}

export interface SignOutInput {
  token: string;
  token_client: string;
}

export interface UpdateAffiliateInput {
  id: string;
  userId?: string | null;
  name?: string | null;
  email?: string | null;
  affiliateApplicationText?: string | null;
  status?: string | null;
}

export interface UpdateArticleCategoryInput {
  id: string;
  name?: string | null;
  picture?: CLFileInput | null;
}

export interface UpdateArticleInput {
  id: string;
  recentlyUpdated?: boolean | null;
  isPinned?: boolean | null;
  title?: string | null;
  authorId?: string | null;
  readAccess?: string | null;
  categoriesIds?: string[] | null;
  description?: string | null;
  slug?: string | null;
  hiddenFromArticles?: boolean | null;
  isFeatured?: boolean | null;
  featureOrder?: number | null;
  picture?: CLFileInput | null;
  content?: string | null;
  tags?: string[] | null;
  hideHalf?: boolean | null;
  status?: string | null;
  language?: AppLanguage | null;
  withVideo?: boolean | null;
  articleText?: string | null;
  video?: string | null;
  buttonLink?: string | null;
  buttonTitle?: string | null;
}

export interface UpdateBannerInput {
  id: string;
  kind?: BannerKind | null;
  title?: string | null;
  text?: string | null;
  extraText?: string | null;
  buttonTitle?: string | null;
  nextLink?: string | null;
  href?: string | null;
  image?: FileInput | null;
  imageMobile?: FileInput | null;
  isFree?: boolean | null;
  isRegistered?: boolean | null;
  isPaid?: boolean | null;
  sortOrder?: number | null;
  status?: PublishStatus | null;
  language?: AppLanguage | null;
}

export interface UpdateCompanyInput {
  id: string;
  name?: string | null;
  nameAbbreviations?: string[] | null;
  salaryFactor?: number | null;
  companyLogo?: FileInput | null;
}

export interface UpdateCountryInput {
  id: string;
  abbreviations?: string[] | null;
  cities?: string[] | null;
  factor?: number | null;
  engTrans?: string | null;
  rusTrans?: string | null;
  regions?: Regions[] | null;
}

export interface UpdateCouponInput {
  id: string;
  couponCode?: string | null;
  countUsers?: number | null;
  countTimes?: number | null;
  productsIds?: string[] | null;
  productCategories?: ProductCategory[] | null;
}

export interface UpdateEnumInput {
  id: string;
  name?: string | null;
  translation?: string | null;
  factor?: number | null;
  repoName: string;
  logo?: CLFileInput | null;
}

export interface UpdateExpertActivityInput {
  title?: string | null;
  id: string;
}

export interface UpdateExpertCompanyInput {
  id: string;
  name?: string | null;
  logo?: FileInput | null;
}

export interface UpdateExpertInput {
  id: string;
  userId?: string | null;
  name?: string | null;
  position?: string | null;
  description?: string | null;
  experience?: ExperienceItemInput[] | null;
  usefulness?: string | null;
  slug?: string | null;
  picture?: FileInput | null;
  expertRequests?: string[] | null;
  expertActivities?: string[] | null;
  services?: ExpertServiceInput[] | null;
  reviews?: ExpertReviewInput[] | null;
  locations?: InputAddress_Gql[] | null;
  contacts?: ContactsInput | null;
  status?: string | null;
  languages?: string[] | null;
}

export interface UpdateExpertRequestInput {
  category?: string | null;
  title?: string | null;
  id: string;
}

export interface UpdateJobCategoryInput {
  id: string;
  name?: string | null;
  engTrans?: string | null;
  logo?: CLFileInput | null;
  minSalary?: number | null;
  maxSalary?: number | null;
  avgSalary?: number | null;
}

export interface UpdateJobInput {
  id: string;
  sourceId?: string | null;
  sourceType?: string | null;
  applicationLink?: string | null;
  title?: string | null;
  isUN?: boolean | null;
  jobCategories?: string[] | null;
  industries?: string[] | null;
  description?: string | null;
  employmentType?: string | null;
  seniority?: string | null;
  location?: InputAddress_Gql | null;
  openUntil?: any | null;
  tags?: string[] | null;
  readAccess?: string | null;
  status?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  isRelocationPackage?: boolean | null;
  isVisaNeeded?: boolean | null;
}

export interface UpdateLearningBulletPointInput {
  text: string;
  big_text: string;
  picture?: CLFileInput | null;
}

export interface UpdateLearningPathInput {
  id: string;
  status?: string | null;
  announcementOnly?: boolean | null;
  title?: string | null;
  slug?: string | null;
  heroVideoUrl?: string | null;
  description?: string | null;
  countAvailableArticles?: number | null;
  bulletPoints?: UpdateLearningBulletPointInput[] | null;
  steps?: UpdateLearningStepInput[] | null;
  heroPic?: CLFileInput | null;
  language?: AppLanguage | null;
}

export interface UpdateLearningStepInput {
  articleId?: string | null;
  order: number;
  hasVideo: boolean;
  text: string;
  picture?: CLFileInput | null;
}

export interface UpdateMySummaryInput {
  picture?: FileInput | null;
  gender?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  summary?: string | null;
  nationality?: string | null;
  languages?: string[] | null;
  birthDate?: any | null;
  countryId?: string | null;
  notificationTypes?: string[] | null;
  skype?: string | null;
  linkedId?: string | null;
  zoom?: string | null;
  phone?: string | null;
  isSubscribedOnJobsMailing?: boolean | null;
  iWantNetwork?: boolean | null;
  jobTitle?: string | null;
}

export interface UpdateOrderInput {
  status?: OrderStatus | null;
  id: string;
}

export interface UpdatePartnerCompanyCategoryInput {
  id: string;
  name?: string | null;
  logo?: CLFileInput | null;
}

export interface UpdatePartnerCompanyInput {
  id: string;
  title?: string | null;
  subtitle?: string | null;
  description?: string | null;
  slug?: string | null;
  linkedId?: string | null;
  twitter?: string | null;
  telegram?: string | null;
  youTube?: string | null;
  website?: string | null;
  status?: string | null;
  partnerCompanyCategories?: string[] | null;
  tags?: string[] | null;
  features?: string[] | null;
  services?: PartnerCompanyServiceInput[] | null;
  reviews?: PartnerCompanyReviewInput[] | null;
  locations?: InputAddress_Gql[] | null;
  languages?: string[] | null;
  picture?: FileInput | null;
}

export interface UpdatePartnerCompanyTagInput {
  category?: string | null;
  title?: string | null;
  id: string;
}

export interface UpdatePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface UpdateProductInput {
  id: string;
  sortOrder?: number | null;
  discountPercentage?: string | null;
  baseNumber?: number | null;
  purchasesPerMonth?: number | null;
  slug?: string | null;
  isFeatured?: boolean | null;
  canBeSoldSeparately?: boolean | null;
  linkToProductInfo?: string | null;
  isHidden?: boolean | null;
  status?: PublishStatus | null;
  price?: PriceInput | null;
  marketingPrice?: string | null;
  variant?: ProductVariant | null;
  title?: string | null;
  description?: string | null;
  shortDescription?: string | null;
  features?: string[] | null;
  category?: ProductCategory | null;
  kind?: ProductKind | null;
  language?: AppLanguage | null;
  picture?: FileInput | null;
  productsIds?: string[] | null;
  consultationWithCoachPropsInput?: ConsultationWithCoachPropsInput | null;
  downloadableMediaPropsInput?: DownloadableMediaPropsInput | null;
  externalAccessLinkPropsInput?: ExternalAccessLinkPropsInput | null;
  accessToLearningPathPropsInput?: AccessToLearningPathPropsInput | null;
  accessToMarathonPropsInput?: AccessToMarathonPropsInput | null;
}

export interface UpdatePromoterBankAccountInput {
  currency: Currency;
  accountName: string;
  iban: string;
  swift_bic?: string | null;
  address?: InputAddress_Gql | null;
  reference?: string | null;
}

export interface UpdatePromoterPayoutInput {
  payoutId: string;
  productCommission?: number | null;
  subscriptionCommission?: number | null;
  currency?: string | null;
  failReason?: string | null;
  totalAmount?: number | null;
  bankAccount?: UpdatePromoterBankAccountInput | null;
  status?: PromoterPayoutStatus | null;
}

export interface UpdatePromoterSettingsInput {
  userId: string;
  subscriptionCommission?: number | null;
  productCommission?: number | null;
  couponSubscriptionDiscount?: number | null;
  couponProductDiscount?: number | null;
  username?: string | null;
  couponCode?: string | null;
}

export interface UpdateReferralInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  type?: string | null;
  email?: string | null;
  phone?: string | null;
  facebook?: string | null;
  telegram?: string | null;
  linkedIn?: string | null;
  companiesIds?: string[] | null;
}

export interface UpdateReviewInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  position?: string | null;
  text?: string | null;
  avatar?: CLFileInput | null;
  categories?: ReviewCategory[] | null;
  language?: AppLanguage | null;
}

export interface UpdateReviewsOrderInput {
  reviewsOrder: ReviewOrderInput[];
}

export interface UpdateSeoTagInput {
  id: string;
  title?: string | null;
  description?: string | null;
  url?: string | null;
}

export interface UpdateSubscriptionInput {
  id: string;
  discountInput?: DiscountInput | null;
}

export interface UpdateSubscriptionPlanInput {
  id: string;
  title?: string | null;
  description?: string | null;
  features?: string[] | null;
  billingCycle?: BillingCycle | null;
  status?: string | null;
  price?: PriceInput | null;
  language?: AppLanguage | null;
}

export interface UpdateTrackerItemInput {
  id: string;
  title?: string | null;
  company?: string | null;
  link?: string | null;
  notes?: string | null;
  description?: string | null;
  companyLogo?: FileInput | null;
  countryId?: string | null;
  appliedAt?: any | null;
  status?: string | null;
}

export interface UpdateUserInfoInput {
  userId?: string | null;
  nationality?: string | null;
  languages?: string[] | null;
  skype?: string | null;
  linkedId?: string | null;
  zoom?: string | null;
  phone?: string | null;
  applyForPartnership?: boolean | null;
  countryId?: string | null;
  city?: string | null;
}

export interface UpdateUserRoleInput {
  newRole: string;
  userId: string;
}

export interface UserInfoInput {
  firstName: string;
  redirectTo: string;
  lastName: string;
  language: string;
  applyForPartnership?: boolean | null;
  regSource?: MailerLiteGroups | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
