import React, {useMemo} from 'react';
import {SimpleTable} from "../../../components/tables/SimpleTable";
import {HeadCell} from "../../../lib";
import {Box, Link, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {PaginationOptionsArgs} from "../../../graphql/globalTypes";
import moment from "moment-timezone";
import {
  GetPromoterSubscriptionList_getPromoterSubscriptionList_archivedSubscriptions
} from "../../../graphql/queries/promoters/getPromoterSubscriptionList/__generated__/GetPromoterSubscriptionList";

interface SubscribersTableProps {
  loading: boolean;
  subscribers: GetPromoterSubscriptionList_getPromoterSubscriptionList_archivedSubscriptions[];
  totalUsers: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const SubscribersTable =
  ({
     loading,
     subscribers,
     totalUsers,
     paginationOptions,
     setPaginationOptions
   }: SubscribersTableProps) => {
    const history = useHistory();
    const {t} = useTranslation();

    const mapUsersToRows = useMemo(() => {
      const arr = subscribers?.map((subscriber) => ({
        id: subscriber.id,
        email: (
          <Link
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={`mailto:${subscriber.customerEmail}`}
          >
            {subscriber.customerEmail}
          </Link>
        ),
        subscriptionAmountWithoutDiscount: subscriber.priceWithoutDiscount,
        subscriptionAmountAfterDiscount: subscriber.priceAfterDiscount,
        currency: subscriber.currency,
        planTitle: subscriber.planTitle,
        nextPayment: moment(subscriber.nextTimeCharge).format("DD.MM.YYYY HH:MM"),
        activeCoupon: subscriber.coupon?.couponCode?? "-",
        couponDiscount: `${subscriber.couponDiscountPercentage*100}%`,
        commission: subscriber.commission.toFixed(2),
      }));
      return arr;
    }, [subscribers]);

    return (
      <Box padding="0 20px" width="100%">
        <Typography
          style={{
            marginBottom: "10px",
          }}
          variant="h6"
        >
          {t("subscribers")}
        </Typography>
        <SimpleTable
          loading={loading}
          total={totalUsers}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          headCells={headCells}
          values={mapUsersToRows}
          sortFields={[]}
          onRowClick={()=>{}}
        />
      </Box>
    );
  };


const headCells: HeadCell[] = [
  {
    label: "email",
    align: "left",
    isBreakWord:true
  },
  {
    label: "subscriptionAmountWithoutDiscount",
    align: "left",
  },
  {
    label: "subscriptionAmountAfterDiscount",
    align: "left",
  },
  {
    label: "couponDiscount",
    align: "center",
  },
  {
    label: "planTitle",
    align: "left",
  },
  {
    label: "nextPayment",
    align: "left",
  },
  {
    label: "activeCoupon",
    align: "center",
  },
  {
    label: "currency",
    align: "left",
  },
  {
    label: "commission",
    align: "center",
  },
];