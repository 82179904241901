import {Button, Dialog, DialogContent, DialogTitle, IconButton,} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {NotificationToast} from "../../../components";
import {
  GetUserById_getUserById as IUser
} from "../../../graphql/queries/users/GetUserById/__generated__/GetUserById";
import {useHistory} from "react-router-dom";
import {useRemoveUser} from "../../../hooks/mutations/useRemoveUser";

interface DeleteUserProps {
  user: IUser
}

export const DeleteUserDialog: React.FC<DeleteUserProps> = ({user}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const {removeUser, loading, error, data} = useRemoveUser()
  const [isDeleted, setIsDeleted] = useState(false)

  const history = useHistory();
  const handleDelete = async () => {
    const res = await removeUser({
      variables: {
        id:user.id
      }
    })
    if (res.data?.completelyRemoveUser) {
      handleClose()
      setIsDeleted(true)
      history.push({
        pathname: "/users",
        state: {showCreateNotification: true,
          message: "User deleted"
        },
      });
    }
  }
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon color="primary"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle style={{width: "400px"}}>
          {t("Delete user")}
        </DialogTitle>
        {user.customerInfo?.isFellow
          ? <><DialogContent>
            <Box height="60px"/>
            <Box textAlign="center"
                 fontSize="18px"
            >
              {t("You must unsubscribe before deleting a user VAR", {name: user.firstName + " " + user.lastName})}
            </Box>
            <Box height="60px"/>
          </DialogContent>
            <Box display="flex" margin="20px" gap="30px" justifyContent="center">
              <Button disabled={loading}
                      onClick={handleClose}
                      variant="contained"
                      style={{padding:"8px 50px"}}
                      color="primary">
                {t("Close")}
              </Button>
            </Box></>
          : <><DialogContent>
            <Box textAlign="center">
              <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
            </Box>
            <Box textAlign="center"
                 fontSize="16px">
              {t("Do you really want to delete VAR?", {name: user.firstName + " " + user.lastName})}
            </Box>
          </DialogContent>
            <Box display="flex" margin="20px" gap="30px" justifyContent="center">
              <Button disabled={loading} onClick={handleClose} variant="contained"
                      color="primary">
                {t("Cancel")}
              </Button>
              <Button disabled={loading} onClick={handleDelete} variant="contained"
                      autoFocus>
                {t("Delete")}
              </Button>
            </Box></>
        }
      </Dialog>

      {isDeleted && <NotificationToast
        message={t("User deleted")}
        horizontal="center"
        severity="info"
        vertical={"bottom"}
      />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};