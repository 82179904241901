import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Field, Form, Formik, FormikErrors} from "formik";
import {FullscreenLoader, MyTextField, NotificationToast} from "../../../components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import {useRefundOrder} from "../../../hooks/mutations/useRefundOrder";

interface RefundOrderDialogProps {
  amountToRefundInOrderCurrency: number
  orderId: string
  currency:string
}

export const RefundOrderDialog = ({amountToRefundInOrderCurrency,orderId, currency}: RefundOrderDialogProps) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const [enableValidation, setEnableValidation] = useState(false);
  const {refund, loading, error} = useRefundOrder()

  const initialValues: FormValues = {
    amountToRefundInOrderCurrency: amountToRefundInOrderCurrency,
  };

  const validationSchema = Yup.object().shape({
    amountToRefundInOrderCurrency: Yup.number().required(t("Required")),
  });

  const handleSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const res = await refund({
        variables: {
          input:{
            amountToRefundInOrderCurrency:Number(values.amountToRefundInOrderCurrency),
            id:orderId
          }
        },
      });
      if (res.errors) {
        setErrors({amountToRefundInOrderCurrency: res.errors[0].message});
      }
      else {
        // history.push({
        //   pathname: `/promoters/${res.data?.refundOrder.user!.id}`,
        //   state: {
        //     showRefundNotification: true,
        //   },
        // });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <><Button variant="contained"
              color="secondary" onClick={handleOpen}>{t("To refund")}</Button>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{t("To refund")}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, {setErrors, setSubmitting}) =>
            handleSubmit(values, setErrors, setSubmitting)
          }
        >
          {({submitForm, values}) => (
            <Form>
              <>
                <Typography style={{margin:"0 0 10px 30px"}}>
                  {t("MAX_AVAL_REFUND",{value:amountToRefundInOrderCurrency, currency})}
                </Typography>
                <DialogContent>

                  <Field
                    component={MyTextField}
                    label={t("Refund value")}
                    type="number"
                    fullWidth
                    placeholder={t("Refund value")}
                    name="amountToRefundInOrderCurrency"
                    style={{marginBottom: 20}}
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>{t("Cancel")}</Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    disabled={loading || !values.amountToRefundInOrderCurrency || values.amountToRefundInOrderCurrency<0}
                  >
                    {t("refund")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{marginLeft: 10}}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {loading && <FullscreenLoader transparent/>}

      {error && (
        <NotificationToast
          message={error.message}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  amountToRefundInOrderCurrency: number
}
