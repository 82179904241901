import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  DescriptionOutlined as ArticlesIcon,
  AttachMoney,
  Ballot,
  BusinessCenter,
  CalendarViewDay,
  CardGiftcard,
  CategoryOutlined as CategoriesIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  GroupWork,
  WorkOutline as JobsIcon,
  Language,
  ListOutlined,
  PictureAsPdf,
  RateReview,
  Pageview as SeoIcon,
  Subscriptions,
  SupervisedUserCircle,
  TableChart,
  PeopleOutline as UsersIcon
} from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DirectionsIcon from "@material-ui/icons/Directions";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import InfoIcon from "@material-ui/icons/Info";
import LocalGroceryStoreOutlinedIcon from "@material-ui/icons/LocalGroceryStoreOutlined";
import NextWeekIcon from "@material-ui/icons/NextWeek";
import PaymentIcon from "@material-ui/icons/Payment";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { drawerOpenVar } from "../../apollo";
import logo from "../../assets/logo.png";
import { useLayoutStyle } from "../styles";

const sections = [
  {
    title: "Dashbord",
    link: "/",
    icon: <DashboardIcon />,
  },
  {
    title: "Partners",
    key: "partners",
    icon: <GroupWork />,
    children: [
      {
        link: "/companies",
        icon: <BusinessCenter />,
        title: "Companies",
      },
      {
        link: "/referrals",
        icon: <SupervisedUserCircle />,
        title: "Referrals",
      },
    ],
  },
  {
    title: "Affiliates",
    link: "/affiliates",
    icon: <RecordVoiceOverIcon />,
  },
  {
    title: "Promoters",
    link: "/promoters",
    key: "promoters",
    icon: <EmojiPeopleIcon />,
    children: [
      {
        link: "/promoters",
        icon: <InfoIcon />,
        title: "Common info",
      },
      {
        link: "/promoters-payouts",
        icon: <PaymentIcon />,
        title: "Payouts",
      },
    ],
  },
  {
    title: "PartnerCompanies",
    link: "/partner-companies",
    key: "partner-companies",
    icon: <NextWeekIcon />,
    children: [
      {
        link: "/partner-companies",
        icon: <BusinessCenter />,
        title: "Companies",
      },
      {
        link: "/partner-companies/categories",
        icon: <Ballot />,
        title: "Directions",
      },
    ],
  },
  {
    title: "Experts",
    key: "experts",
    icon: <AssignmentIndIcon />,
    children: [
      {
        link: "/experts",
        icon: <AssignmentIndIcon />,
        title: "Experts",
      },
      {
        link: "/experts/companies",
        icon: <BusinessCenter />,
        title: "Companies",
      },
      {
        link: "/experts/requests",
        icon: <ShopTwoIcon />,
        title: "Requests",
      },
      {
        link: "/experts/activities",
        icon: <DirectionsIcon />,
        title: "Activities",
      },
    ],
  },
  {
    title: "Jobs",
    key: "jobs",
    icon: <JobsIcon />,
    children: [
      {
        link: "/jobs",
        icon: <TableChart />,
        title: "Jobs table",
      },
      {
        link: "/job-enums",
        icon: <ListOutlined />,
        title: "Properties",
      },
    ],
  },
  {
    title: "ContentSection",
    link: "/articles",
    key: "articles",
    icon: <PictureAsPdf />,
    children: [
      {
        link: "/articles",
        icon: <ArticlesIcon />,
        title: "Articles",
      },
      {
        link: "/learning-paths",
        icon: <Ballot />,
        title: "Learning Paths",
      },
    ],
  },
  {
    title: "Categories",
    link: "/categories",
    icon: <CategoriesIcon />,
  },
  {
    title: "Products",
    icon: <LocalGroceryStoreOutlinedIcon />,
    key: "products",
    link: "/products",
    children: [
      {
        link: "/products",
        icon: <LocalGroceryStoreOutlinedIcon />,
        title: "Products",
      },
      {
        link: "/products/payments",
        icon: <CreditCardIcon />,
        title: "Payments",
      },
    ],
  },
  // {
  //   title: "Resources",
  //   link: "/resources",
  //   icon: <ResourcesIcon />,
  // },
  // {
  //   title: "Consultations",
  //   link: "/consultations",
  //   icon: <ConsultationsIcon />,
  // },
  {
    title: "Reviews",
    link: "/reviews",
    icon: <RateReview />,
  },
  {
    title: "Job Application Tracker",
    link: "/job-application-tracker",
    icon: <EventAvailableIcon />,
  },
  {
    title: "Banners",
    link: "/banners",
    icon: <ViewCarouselOutlinedIcon />,
  },
  {
    title: "Countries",
    link: "/countries",
    icon: <Language />,
  },
  {
    title: "Users",
    link: "/users",
    icon: <UsersIcon />,
  },
  {
    link: "/seo",
    icon: <SeoIcon />,
    title: "SEO",
  },
  {
    title: "Finance",
    key: "finance",
    icon: <AttachMoney />,
    children: [
      {
        link: "/subscription-plans",
        icon: <CalendarViewDay />,
        title: "SubscriptionPlans",
      },
      {
        link: "/subscriptions",
        icon: <Subscriptions />,
        title: "Subscriptions",
      },
      {
        link: "/coupons",
        icon: <CardGiftcard />,
        title: "Coupons",
      },
    ],
  },
];

export const SideDrawer: React.FC = () => {
  const classes = useLayoutStyle();
  const history = useHistory();
  const location = useLocation()
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  const [open, setOpen] = useState({
    finance: false,
    jobs: false,
    partners: false,
  });

  const handleClick = (key: keyof typeof open) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
    >
      <Box className={classes.logoWrapper}>
        <Box className={classes.logoContainer}>
          <img src={logo} alt="Networkio" />
        </Box>
        <IconButton onClick={() => drawerOpenVar(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>

      <Divider />

      <List
        classes={{
          root: classes.listRoot,
        }}
      >
        {sections.map((section, index) =>
          section.children ? (
            <Fragment key={index}>
              <ListItem
                selected={section.children.some(
                  (ch) => ch.link === location.pathname
                )}
                button
                onClick={() => handleClick(section.key as keyof typeof open)}
              >
                <ListItemIcon>{section.icon}</ListItemIcon>
                <ListItemText primary={t(section.title)} />
                {open[section.key as keyof typeof open] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>

              <Collapse
                in={open[section.key as keyof typeof open]}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ paddingLeft: "10px" }}
                >
                  {section.children.map((ch) => (
                    <ListItem
                      button
                      key={ch.title}
                      selected={ch.link === location.pathname}
                      onClick={() => history.push(ch.link)}
                    >
                      <ListItemIcon>{ch.icon}</ListItemIcon>
                      <ListItemText primary={t(ch.title)} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ) : (
            <ListItem
              key={index}
              button
              selected={section.link === location.pathname}
              onClick={() => history.push(section.link!)}
            >
              <ListItemIcon>{section.icon}</ListItemIcon>
              <ListItemText primary={t(section.title)} />
            </ListItem>
          )
        )}
      </List>
    </Drawer>
  );
};
