import { useMutation } from "@apollo/client";
import { DELETE_JOBS } from "../../graphql";
import {
  DeleteJobs,
  DeleteJobsVariables,
} from "../../graphql/mutations/jobs/DeleteJobs/__generated__/DeleteJobs";

export const useDeleteJobs = (ids: string[]) => {
  const [update, { loading, error }] = useMutation<
    DeleteJobs,
    DeleteJobsVariables
  >(DELETE_JOBS, {
    onError: (err) => console.error(err),
    update: (cache) => {
      ids.forEach((id) => {
        const normalizedId = cache.identify({ id, __ref: `Job_Gql:${id}` });
        cache.evict({ id: normalizedId });
        cache.gc();
      });
    },
  });

  return {
    update,
    loading,
    error,
  };
};
