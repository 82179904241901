import {useLazyQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  GetOrderById,
  GetOrderById_getOrderById,
  GetOrderByIdVariables
} from "../../../graphql/queries/products/getOrderById/__generated__/GetOrderById";
import {GET_ORDER_BY_ID} from "../../../graphql/queries/products/getOrderById";

interface EditOrderParams {
  id: string;
}

interface EditOrderState {
  order?: GetOrderById_getOrderById;
  showCreateNotification?: boolean;
}

export const useFetchOrderFromRoute = () => {
  const { id } = useParams<EditOrderParams>();
  const location = useLocation<EditOrderState>();
  const [processing, setProcessing] = useState(true);
  const [order, setOrder] = useState<GetOrderById_getOrderById | null>(null);
  const [getOrder, { error, data }] = useLazyQuery<GetOrderById, GetOrderByIdVariables>(
    GET_ORDER_BY_ID,
    {
      onError: (err) => console.error(err),
    }
  );
  console.log(location)
  useEffect(() => {
    if (location.state.order) {
      setOrder(location.state.order);
      setProcessing(false);
    } else {
      getOrder({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getOrderById) {
      setOrder(data.getOrderById);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    order,
    showCreateNotification: location.state?.showCreateNotification,
  };
};
