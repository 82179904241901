import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSetHeadbarTitle} from "../../hooks";
import {useQuery} from "@apollo/client";
import {
  GetTrackerItems,
  GetTrackerItemsVariables
} from "../../graphql/queries/jobApplicationTracker/getTrackerItems/__generated__/GetTrackerItems";
import {
  GET_TRACKER_ITEMS
} from "../../graphql/queries/jobApplicationTracker/getTrackerItems";
import {TrackerTable} from "./components/TrackerTable";
import {GetTrackerItemsInput, PaginationOptionsArgs} from "../../graphql/globalTypes";
import {TrackerFilterBar} from "./components/TrackerFilterBar";
import {Box} from "@mui/system";

export const JobApplicationTracker = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Job Application Tracker"));
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "createdAt",
        sortBy: "desc",
      },
    });

  const [filterParams, setFilterParams] = useState<GetTrackerItemsInput>({
    createdFrom:null,
    createdTo:null
  });
  const { data, loading, error }= useQuery<
    GetTrackerItems,
    GetTrackerItemsVariables
    >(GET_TRACKER_ITEMS, {
    onError: (err) => console.error(err),
    variables: {
      paginationOptions,
      input: filterParams
    }
  });

  return (
    <Box margin="20px 10px">
      <TrackerFilterBar
        itemsCount={data?.getTrackerItems.meta.totalItems}
        filterParams={filterParams}
        setFilterParams={setFilterParams}/>

      <TrackerTable loading={loading}
                    filterParams={filterParams}
                    paginationOptions={paginationOptions}
                    setPaginationOptions={setPaginationOptions}
                    items={data?.getTrackerItems.trackerItems ?? []}
                    totalItems={data?.getTrackerItems.meta.totalItems ?? 0}
      />
    </Box>
  );
};

