import {gql} from "@apollo/client";
import {PartnerCompanyFragment} from "../../../fragments";

export const CREATE_PARTNER_COMPANY = gql`
    mutation CreatePartnerCompany($input: CreatePartnerCompanyInput!) {
        createPartnerCompany(input: $input) {
            error {
                name
                message
            }
            partnerCompany {
                ...PartnerCompany
            }
        }
    }
    ${PartnerCompanyFragment}
`;