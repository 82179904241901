import {useMutation} from "@apollo/client";
import {UPDATE_EXPERT_REQUEST} from "../../../graphql";
import {
  UpdateExpertRequest,
  UpdateExpertRequestVariables
} from "../../../graphql/mutations/experts/updateExpertRequest/__generated__/UpdateExpertRequest";

export const useUpdateExpertRequest = () => {
  const [update, { loading: uLoading }] = useMutation<
    UpdateExpertRequest,
    UpdateExpertRequestVariables
    >(UPDATE_EXPERT_REQUEST, {
    onError: (err) => console.error(err),
  });

  return { update, uLoading };
};