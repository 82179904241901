import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { NotificationToast } from "../../../components";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  TogglePluginUserBlock,
  TogglePluginUserBlockVariables,
} from "../../../graphql/mutations/jobApplicationTracker/togglePluginUserBlock/__generated__/TogglePluginUserBlock";
import { TOGGLE_PLUGIN_USER_BLOCK } from "../../../graphql/mutations/jobApplicationTracker/togglePluginUserBlock";
import { GetUserById_getUserById as IUser } from "../../../graphql/queries/users/GetUserById/__generated__/GetUserById";
import { GET_USER_BY_ID } from "../../../graphql";

interface Props {
  user: IUser;
}

export const LockPluginUser = ({ user }: Props) => {
  const [togglePluginUserBlock, { loading, error }] = useMutation<
    TogglePluginUserBlock,
    TogglePluginUserBlockVariables
  >(TOGGLE_PLUGIN_USER_BLOCK, {
    onError: (err) => console.error(err),
  });
  const [isChangedStatus, setIsChangedStatus] = useState(false);
  const { t } = useTranslation();
  const handleUnlockClick = async () => {
    await togglePluginUserBlock({
      variables: {
        id: user.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_BY_ID,
          variables: {
            id: user.id,
          },
        },
      ],
    });
    setIsChangedStatus(false);
    setIsChangedStatus(true);
  };
  return (
    <>
      <Box display="flex" justifyContent="center" marginY="20px">
        <Button
          onClick={handleUnlockClick}
          disabled={loading}
          variant="contained"
          startIcon={user.isLocked ? <LockOpenIcon /> : <LockIcon />}
          color="primary"
        >
          {user.isLocked
            ? "Разблокировать в плагине Networkio"
            : "Заблокировать в плагине Networkio"}
          {loading && (
            <CircularProgress
              thickness={7}
              style={{ marginLeft: 10 }}
              size={12}
            />
          )}
        </Button>
      </Box>
      {isChangedStatus && (
        <NotificationToast
          message={user.isLocked ? t("user blocked") : t("user unlocked")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
