import { useMutation } from "@apollo/client";
import { UPDATE_USER_ROLE } from "../../graphql";
import {
  UpdateUserRole,
  UpdateUserRoleVariables,
} from "../../graphql/mutations/users/UpdateUserRole/__generated__/UpdateUserRole";

export const useUpdateUserRole = () => {
  const [update, { loading, error }] = useMutation<
    UpdateUserRole,
    UpdateUserRoleVariables
  >(UPDATE_USER_ROLE, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};
