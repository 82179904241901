import React, {ChangeEvent, useState} from 'react';
import {Button} from '@mui/material';
import {Box} from "@mui/system";
import {FileInput, FileType} from "../../graphql/globalTypes";
import {IErrorResponse} from "../../lib";
import {NotificationToast} from "../notifications";
import {useTranslation} from "react-i18next";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {IconButton} from "@material-ui/core";

interface FilesUploadProps {
  files?: FileInput[];
  setFiles: (s: FileInput[]) => void;
  single?:boolean
}

export const FilesUpload = ({setFiles, files=[], single}: FilesUploadProps) => {
  const [sizeError, setSizeError] = useState<IErrorResponse | null>(null);
  const {t} = useTranslation();
  const handleFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      const convertedFiles: FileInput [] = [];
      newFiles.forEach((file) => {
        if (file.size / 1024 / 1024 > 30) {
          setSizeError({
            name: "general",
            message:
              "You're only able to upload valid files of under 30MB in size!",
          });
        } else {
          getBase64Value(file, (base64Value) => {
            convertedFiles.push({source: base64Value, fileName: file.name, type: getFileType(file.name)})
            if (convertedFiles.length === newFiles.length) {
              setFiles([...files,...convertedFiles]);
            }
          });
        }
      })
    }
  };

  return (
    <Box minHeight="30px">
      <label>
        <input style={{display: "none"}}
               type="file"
               multiple={!single}
               onChange={handleFilesChange}/>
        <Button component="span" variant="contained">
          {single ? "Выберите файл" : "Выберите файлы"}
        </Button>
      </label>
      {files.map((file) => (
        <Box border="1px solid rgb(245, 160, 0)"
             margin="10px"
             padding="5px"
             borderRadius="10px"
             width="max-content"
             display="flex"
             alignItems="center"
             gap="5px"
             key={file.fileName}>
          {file.fileName}
          <IconButton  size="small"
                       onClick={()=>setFiles(files.filter(el=>`${el.fileName}${el.type}`!==`${file.fileName}${file.type}`))}>
            <HighlightOffRoundedIcon/>
          </IconButton>
        </Box>
      ))}
      {sizeError && (
        <NotificationToast
          horizontal="center"
          severity="error"
          vertical="bottom"
          message={t(sizeError.message!)}
        />
      )}
    </Box>
  );
};

const getBase64Value = (
  file: File | Blob,
  callback: (imageBase64Value: string) => void
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result as string);
  };
};

const getFileType = (fileName: string) => {
  const format = fileName.split(".")[1]
  if (["jpg", "png", ".if", "webp", "bmp", "tiff", "svg", "ico", "heif", "avif", "raw", "eps"].includes(format)) {
    return FileType.IMAGE
  }
  if (["txt", "doc", "docx", "pdf", "rtf", "odt", "html", "xml", "csv", "xls", "xlsx", "ppt", "pptx", "odp", "epub", "json"].includes(format)) {
    console.log("txt")
    return FileType.DOCUMENT
  }
  if (["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm", "m4v", "mpeg", "mpg", "3gp", "ogg", "ogv", "swf", "vob", "ts"].includes(format)) {
    return FileType.VIDEO
  }
  if (["mp3", "wav", "ogg", "m4a", "flac", "aac", "wma", "ape", "amr", "mid", "midi", "ac3", "aiff", "au", "ra", "ram", "pcm", "dts"].includes(format)) {
    return FileType.AUDIO
  }

}