import {useQuery} from "@apollo/client";
import {
  GetPartnerCompaniesFilters
} from "../../../graphql/queries/partnerCompanies/getPartnerCompaniesFilters/__generated__/GetPartnerCompaniesFilters";
import {
  GET_PARTNER_COMPANIES_FILTERS
} from "../../../graphql/queries/partnerCompanies/getPartnerCompaniesFilters";

export const useGetPartnerCompaniesFilters = () => {
  const { data, loading, error } = useQuery<GetPartnerCompaniesFilters>(GET_PARTNER_COMPANIES_FILTERS, {
    onError: (err) => console.error(err),
  });
  return { loading, error,filters:data?.getPartnerCompaniesFilters};
};
