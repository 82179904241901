import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import noPhoto from "../../../assets/noPhoto.jpg";
import {EditOutlined as EditIcon} from "@material-ui/icons";
import {useCommonStyle} from "../../../styles/commonStyles";
import {useTranslation} from "react-i18next";
import {DeleteButton, NotificationToast} from "../../../components";
import {useIsDesktopView} from "../../../hooks";
import {
  GetPartnerCompanyCategories_getPartnerCompanyCategories
} from "../../../graphql/queries/partnerCompanies/getPartnerCompanyCategories/__generated__/GetPartnerCompanyCategories";
import {
  useDeletePartnerCompanyCategory
} from "../../../hooks/mutations/useDeletePartnerCompanyCategory";

interface PartnerCompanyCategoryCardProps {
  category: GetPartnerCompanyCategories_getPartnerCompanyCategories;
  onClick: () => void;
}

export const PartnerCompanyCategoryCard: React.FC<PartnerCompanyCategoryCardProps> = ({
  category,
  onClick,
}) => {
  const isDesktopView = useIsDesktopView();
  const commonClasses = useCommonStyle();
  const { t } = useTranslation();
  const { loading, error, deletePartnerCompanyCategory } =
    useDeletePartnerCompanyCategory(category);

  async function handleDelete(): Promise<void> {
    await deletePartnerCompanyCategory({ variables: { id: category.id } });
  }

  return (
    <>
      <Card
        style={{ display: "flex", height: "100%", cursor: "default" }}
        className={commonClasses.onHover}
      >
        <Box display="flex" flexDirection="column" flex={2}>
          <CardContent style={{ flex: "1 0 auto" }}>
            <Typography component="h6" variant="h6">
              {category.name}
            </Typography>
          </CardContent>
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={1}
            paddingBottom={1}
          >
            <IconButton onClick={onClick}>
              <EditIcon />
            </IconButton>
            <DeleteButton loading={loading} deleteAction={handleDelete} />
          </Box>
        </Box>
        <CardMedia
          style={{ width: "100%", flex: 1 }}
          image={
            category.logo ? category.logo.url : noPhoto
          }
        />
      </Card>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </>
  );
};
