import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useLoginRoute = () => {
  const location = useLocation();
  const history = useHistory();

  const [currentSection, setCurrentSection] = useState<"login" | "forgot_pass">(
    getCurrentSection()
  );

  const updateSection = (section: "login" | "forgot_pass") => {
    if (section === "login" && getCurrentSection() !== "login") {
      history.push("/login");
    } else if (
      section === "forgot_pass" &&
      getCurrentSection() !== "forgot_pass"
    ) {
      history.push("/login#forgot_pass");
    }
    setCurrentSection(section);
  };

  useEffect(() => {
    if (location.hash && location.hash !== "#forgot_pass") {
      history.replace("/login");
    }

    setCurrentSection(getCurrentSection());
  }, [location.hash]);

  function getCurrentSection(): "login" | "forgot_pass" {
    return location.hash === "#forgot_pass" ? "forgot_pass" : "login";
  }

  return { currentSection, updateSection };
};
