import {Box, IconButton, Menu, MenuItem, Tooltip} from "@material-ui/core";
import {CheckCircle, EmailOutlined as EmailIcon, MoreVert,} from "@material-ui/icons";
import theme from "@react-page/editor/lib/ui/ThemeProvider/DarkTheme";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {MyMuiTextField, NotificationToast} from "../../../components";
import {EmailMessage} from "../../users/components/EmailMessage";
import {
  GetPromoterByAdmin_getPromoterByAdmin_promoter
} from "../../../graphql/queries/promoters/getPromoterByAdmin/__generated__/GetPromoterByAdmin";

interface PromoterEmailProps {
  promoter: GetPromoterByAdmin_getPromoterByAdmin_promoter;
}

export const PromoterEmail: React.FC<PromoterEmailProps> = ({promoter}) => {
  const {t} = useTranslation();
  const actions = ["copyEmail", "sendPasswordReset"]
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleAction = useCallback(async (a: string) => {
    setCopied(false);
    if (a === "copyEmail") {
      await navigator.clipboard.writeText(promoter.email);
      setCopied(true);
    } else {
      setCurrentAction(a);
      handleOpen();
    }
    handleMenuClose();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentAction("");
    setOpen(false);
  };

  const dialogMarkup = useMemo(() => {
    if (!!currentAction) {
      return (
        <EmailMessage
          isPartner
          onClose={handleClose}
          open={open}
          action={currentAction}
          user={promoter}
          setEmailSent={setEmailSent}
        />
      );
    }
  }, [currentAction]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <MyMuiTextField
          type="email"
          value={promoter.email}
          fullWidth
          placeholder={t("Email")}
          icon={<EmailIcon/>}
          disabled
          rightIcon={
            <Tooltip title={t("Verified")!}>
              <CheckCircle style={{color: theme.palette.success.main}}/>
            </Tooltip>}
        />

        <IconButton onClick={handleMenuClick}>
          <MoreVert/>
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {actions.map((a) => (
            <MenuItem key={a} onClick={() => handleAction(a)}>
              {t(a)}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {copied && (
        <NotificationToast
          horizontal="center"
          vertical="bottom"
          message={t("Copied to buffer")}
          severity="success"
        />
      )}

      {dialogMarkup}

      {emailSent && (
        <NotificationToast
          message={t("Email has been sent")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
