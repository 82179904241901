import {Box} from "@material-ui/core";
import {Form, Formik, FormikErrors} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AppLanguage, CreateArticleInput, PublishStatus} from "../../graphql/globalTypes";
import {
  CreateArticleVariables
} from "../../graphql/mutations/articles/CreateArticle/__generated__/CreateArticle";
import {useCreateArticle, useSetHeadbarTitle} from "../../hooks";
import {ArticlesFormValues, FileType, IPicUpload, ReadAccess,} from "../../lib";
import {useCommonStyle} from "../../styles/commonStyles";
import {EditArticlesLeft} from "./components";
import * as Yup from "yup";
import {getFileNameFromPublicId} from "../../utils";
import {FullscreenLoader, NotificationToast} from "../../components";
import {useHistory} from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

export const CreateArticle: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Article Editor"));
  const commonClasses = useCommonStyle();
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const { create, error, loading } = useCreateArticle();
  const history = useHistory();

  const [input, setInput] = useState<CreateArticleInput>({
    title: "",
    readAccess: ReadAccess.UNANYMOUS,
    status: PublishStatus.DRAFT,
    slug: "",
    featureOrder: 0,
    language:AppLanguage.ru
  });

  const handleSubmit = async (
    values: ArticlesFormValues,
    setErrors: (errors: FormikErrors<ArticlesFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const variables: CreateArticleVariables = {
      input: {
        title: values.title,
        slug: values.slug,
        readAccess: input.readAccess,
        status: input.status,
        language: input.language,
        ...(input.authorId ? { authorId: input.authorId } : {}),
        ...(input.categoriesIds?.length
          ? { categoriesIds: input.categoriesIds }
          : {}),
        ...(input.tags?.length ? { tags: input.tags } : {}),
        ...(values.description ? { description: values.description } : {}),
        isPinned: values.isPinned,
        withVideo: values.withVideo,
        // articleText: values.articleText
        //   ? draftToHtml(convertToRaw(values.articleText.getCurrentContent()))
        //   : draftToHtml(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        ...(values.articleText ? {articleText: draftToHtml(convertToRaw(values.articleText?.getCurrentContent()))} : {}),
        ...(values.video ? {video: values.video} : {}),
        ...(values.buttonLink ? {buttonLink: values.buttonLink} : {}),
        ...(values.buttonTitle ? {buttonTitle: values.buttonTitle} : {}),
        hiddenFromArticles: values.hiddenFromArticles,
        isFeatured: values.isFeatured,
        ...(values.isFeatured
          ? {
              featureOrder: input.featureOrder,
            }
          : {}),
        // recentlyUpdated: values.recentlyUpdated,
        hideHalf:
          input.readAccess !== ReadAccess.UNANYMOUS ? values.hideHalf : false,
        ...(picUpload
          ? {
              picture: {
                publicId: picUpload.public_id,
                url: picUpload.url,
                filename: getFileNameFromPublicId(picUpload.public_id),
                type: FileType.IMAGE,
              },
            }
          : {}),
      },
    };
    try {
      const res = await create({ variables });
      if (res.data?.createArticle.error) {
        setErrors({ slug: t("Slug already exists") });
      } else {
        history.push({
          pathname: `/articles/edit/${res.data!.createArticle.article!.id}`,
          state: {
            article: res.data!.createArticle.article,
            showCreateNotification: true,
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues: ArticlesFormValues = {
    title: input.title,
    description: input.description ?? "",
    content: input.content ?? "",
    slug: input.slug,
    isPinned: input.isPinned ?? false,
    hideHalf: input.hideHalf ?? false,
    isFeatured: input.isFeatured ?? false,
    hiddenFromArticles: input.hiddenFromArticles ?? false,
    withVideo: input.withVideo ?? false
    // recentlyUpdated: input.recentlyUpdated ?? false,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .required(t("Required")),
    slug: Yup.string()
      .min(2, t("Must have at least VAR characters", { count: 2 }))
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t("Invalid slug"))
      .required(t("Required")),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors, setSubmitting }) =>
          handleSubmit(values, setErrors, setSubmitting)
        }
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form>
            <Box className={commonClasses.sectionWithSticky}>
              <EditArticlesLeft
                showEditContent={false}
                setPicUpload={setPicUpload}
                picUpload={picUpload}
                handleSubmit={() => {
                  submitForm();
                }}
                setFieldValue={setFieldValue}
                disabled={loading}
                values={values}
                input={input}
                setInput={setInput as any}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {loading && <FullscreenLoader transparent />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
