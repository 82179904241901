import { gql } from "@apollo/client";

export const GET_CURRENCY_EXCHANGE_RATES = gql`
    query GetCurrencyExchangeRates {
        getCurrencyExchangeRates {
            date
            rubToEur
            rubToUsd
            usdToEur
            usdToRub
        }
    }
`;