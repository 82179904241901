import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MyTextField, NotificationToast } from "../../../components";
import * as Yup from "yup";
import { VpnKeyOutlined as PassIcon } from "@material-ui/icons";
import { useIsDesktopView, useResetPassword } from "../../../hooks";
import { ResetPasswordByTokenVariables } from "../../../graphql/mutations/me/ResetPasswordByToken/__generated__/ResetPasswordByToken";
import { loginUser } from "../../../utils";
import { useHistory } from "react-router-dom";

interface ResetPasswordFormProps {
  token: string;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  token,
}) => {
  const { t } = useTranslation();
  const [enableValidation, setEnableValidation] = useState(false);
  const { resetPass, loading, error } = useResetPassword();
  const isDesktopView = useIsDesktopView();
  const [expirationError, setExpirationError] = useState(false);
  const history = useHistory();

  const initialValues: FormValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, t("Must have at least VAR characters", { count: 8 }))
      .max(64, t("Cannot have more than VAR characters", { count: 64 }))
      .matches(/(.*[A-Z].*)/, t("Must have at least 1 capital letter"))
      .matches(/(.*\d.*)/, t("Must have at least 1 digit"))
      .matches(/^\S*$/, t("Cannot have whitespaces"))
      .required(t("Required")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("Passwords do not match")
    ),
  });

  async function handleResetPass(
    { password }: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const variables: ResetPasswordByTokenVariables = {
      input: {
        password,
        token,
        tokenClient: "AdminPanel",
      },
    };

    try {
      const res = await resetPass({ variables });
      if (res.data?.resetPasswordByToken.error) {
        setExpirationError(true);
      } else {
        await loginUser(res!.data!.resetPasswordByToken.user!.token);
      }
    } finally {
      setSubmitting(false);
    }
  }

  return expirationError ? (
    <Typography variant="caption">{t("Verification Link Expired")}</Typography>
  ) : (
    <Box>
      <Typography color="textSecondary" variant="caption">
        {t("Enter new password")}
      </Typography>

      <Box marginTop={4} marginBottom={6}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, { setSubmitting }) =>
            handleResetPass(values, setSubmitting)
          }
        >
          {({ values, submitForm }) => (
            <Form>
              <>
                <Field
                  component={MyTextField}
                  fullWidth
                  placeholder={t("Password")}
                  name="password"
                  style={{ marginBottom: 20 }}
                  icon={<PassIcon />}
                  isPassword
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  placeholder={t("Confirm Password")}
                  name="confirmPassword"
                  style={{ marginBottom: 20 }}
                  icon={<PassIcon />}
                  isPassword
                />

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={
                    !values.password || !values.confirmPassword || loading
                  }
                >
                  {t("Save")}
                  {loading && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </>
            </Form>
          )}
        </Formik>
      </Box>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={!isDesktopView ? "top" : "bottom"}
        />
      )}
    </Box>
  );
};

interface FormValues {
  password: string;
  confirmPassword: string;
}
