import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useInputStyles } from "./styles";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";

interface MyTextFieldProps extends TextFieldProps {
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  isPassword?: boolean;
  toUpperCase?:boolean;
}

interface MyMuiTextFieldProps {
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
}

export const MyTextField: React.FC<MyTextFieldProps> = ({
  icon,
  isPassword,
  rightIcon, toUpperCase,
  ...rest
}) => {
  const classes = useInputStyles();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const extraProps = {
    ...(isPassword
      ? {
          type: passwordVisible ? "text" : "password",
        }
      : {}),
    InputProps: {
      ...(isPassword
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  style={{ margin: 0 }}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }
        : rightIcon
        ? {
            endAdornment: (
              <InputAdornment position="end">{rightIcon}</InputAdornment>
            ),
          }
        : {}),
      ...(icon
        ? {
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }
        : {}),
    },
  };

  return (
    <TextField
      inputProps={{ style: { textTransform: `${toUpperCase ? "uppercase" : "none"}` } }}
      classes={{
        root: classes.tf_root,
      }}
      {...fieldToTextField(rest as any)}
      {...extraProps}
      variant="outlined"
    />
  );
};

export const MyMuiTextField: React.FC<MyMuiTextFieldProps & MuiTextFieldProps> =
  ({ icon, rightIcon, ...rest }) => {
    const classes = useInputStyles();

    const extraProps = {
      InputProps: {
        ...(rightIcon
          ? {
              endAdornment: (
                <InputAdornment position="end">{rightIcon}</InputAdornment>
              ),
            }
          : {}),
        ...(icon
          ? {
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }
          : {}),
      },
    };

    return (
      <TextField
        classes={{
          root: classes.tf_root,
        }}
        {...rest}
        {...extraProps}
        variant="outlined"
      />
    );
  };
