import { Box, Chip, Paper, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { useCardStyle } from "./styles";
import { capitalize } from "../../utils";

interface EnumCardProps {
  values?: string[];
  title: string;
  stickyHeader?: boolean;
  rootStyle?: React.CSSProperties;
}

export const EnumCard: React.FC<EnumCardProps> = ({
  values,
  title,
  rootStyle,
  stickyHeader,
}) => {
  const classes = useCardStyle();

  return (
    <Paper elevation={2} className={classes.enumcard__paper}>
      <Box>
        <Typography color="textSecondary" variant="overline">
          {title}
        </Typography>
      </Box>

      <Box>
        {values?.map((v) => (
          <Chip
            className={classes.enumcard__chip}
            key={v}
            size="small"
            label={capitalize(v)}
          />
        ))}
      </Box>
    </Paper>
  );
};
