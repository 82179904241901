import { Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { CellPluginComponentProps, useUiTranslator } from "@react-page/editor";
import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { CMSUploadButton } from "../../../../buttons";
import { AtomProgress } from "../../../../loaders";
import { defaultPdfViewerState } from "../default/state";
import { PdfViewerState } from "../types/state";
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

const PdfViewerHtmlRenderer: React.FC<
  CellPluginComponentProps<PdfViewerState>
> = (props) => {
  const { data } = props;
  const { t } = useUiTranslator();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    props.onChange(defaultPdfViewerState);
  }, []);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handlePaginationChange = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  return (
    <>
      <div
        style={{
          display: props.isEditMode ? "flex" : "none",
          zIndex: 100,
          position: "absolute",
        }}
      >
        <CMSUploadButton
          resource_type="image"
          onInsert={(url) =>
            props.onChange({
              url,
            })
          }
        />
      </div>

      {data.url ? (
        <Box display="flex" flexDirection="column" alignItems={data.align}>
          <Box {...{ [data.type]: data.value }} marginBottom={2}>
            <Document
              file={data.url}
              onLoadError={(e) => console.error(e)}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<AtomProgress />}
              renderMode="canvas"
            >
              <Page {...{ [data.type]: data.value }} pageNumber={pageNumber} />
            </Document>
          </Box>
          <Pagination
            count={numPages}
            onChange={(_, page) => handlePaginationChange(page)}
            color="standard"
          />
        </Box>
      ) : (
        <Box height={"100px"} />
      )}
    </>
  );
};

export default PdfViewerHtmlRenderer;
