import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AtomProgress, NotificationToast } from "../../components";
import { GET_COUPONS } from "../../graphql";
import { CreateCoupon_createCoupon_coupon } from "../../graphql/mutations/subscriptions/CreateCoupon/__generated__/CreateCoupon";
import {
  GetCoupons,
  GetCoupons_getCoupons,
} from "../../graphql/queries/subscriptions/GetCoupons/__generated__/GetCoupons";
import { useSetHeadbarTitle } from "../../hooks";
import { EditDialog } from "../subscriptionPlans/components/EditDialog";
import { CouponsTable, FilterBar } from "./components";

interface CouponsProps {}

export const Coupons: React.FC<CouponsProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Coupons"));
  const [coupons, setCoupons] = useState<GetCoupons_getCoupons[]>([]);
  const [couponCode, setCouponCode] = useState("");
  const { data, loading, refetch, error } = useQuery<GetCoupons>(GET_COUPONS);
  const [coupon, setCoupon] = useState<null | CreateCoupon_createCoupon_coupon>(
    null
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data?.getCoupons) {
      setCoupons(data.getCoupons);
    }
  }, [data]);

  useEffect(() => {
    if (coupon) setOpen(true);
  }, [coupon]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FilterBar
        refetch={refetch}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        setOpen={setOpen}
      />

      <Box padding={3} marginTop={4}>
        {loading && (
          <Box
            position="absolute"
            marginTop="5px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
      </Box>

      <CouponsTable
        coupons={
          !couponCode
            ? coupons
            : coupons.filter((coupon) =>
                coupon.coupon.couponCode
                  .toLowerCase()
                  .includes(couponCode.toLowerCase())
              )
        }
        loading={loading}
        setCoupon={setCoupon}
        refetch={refetch}
      />

      <EditDialog
        coupon={coupon}
        onClose={handleClose}
        open={open}
        refetch={refetch}
      />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
