import { gql } from "@apollo/client";

export const CREATE_COUPON = gql`
  mutation CreateCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      coupon {
        id
        couponCode
        countUsers
        countTimes
        discountPercentage
      }
      error {
        name
        message
      }
    }
  }
`;
