import { gql } from "@apollo/client";
import { SubscriptionPlanFragment } from "../../../fragments";

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscriptionPlan($input: CreateSubscriptionPlanInput!) {
    createSubscriptionPlan(input: $input) {
      ...SubscriptionPlan
    }
  }
  ${SubscriptionPlanFragment}
`;
