import React, {useMemo} from 'react';
import {MyTable} from "../../../components";
import {Box, CardMedia} from "@material-ui/core";
import {HeadCell} from "../../../lib";
import {useTranslation} from "react-i18next";
import {
  GetBanners_getBanners
} from "../../../graphql/queries/banners/getBanners/__generated__/GetBanners";
import {DeleteBannerDialog} from "./DeleteBannerDialog";
import {BannerDialog} from "./BannerDialog";
import {GetBannersInput} from "../../../graphql/globalTypes";

interface BannersTableProps {
  loading: boolean;
  banners?: GetBanners_getBanners[];
  input:GetBannersInput
}

export const BannersTable = ({loading, banners, input}: BannersTableProps) => {
  const {t} = useTranslation();
  const sortedBanners = banners ? [...banners].sort((a,b)=> a.sortOrder - b.sortOrder) : []
  const rows = useMemo(() => {
    const arr = sortedBanners?.map((banner) => ({
      id: banner.id,
      Title: <Box>
        <Box fontWeight="500">{banner.title}</Box>
        <BannerDialog edit banner={banner}/>
        <DeleteBannerDialog banner={banner} input={input}/>
      </Box>,
      Properties: <Box width="220px">
        {banner.buttonTitle && <Box fontSize="0.9em" display="flex">
          {t("Button title")}:
          <Box marginLeft="5px" fontWeight="500">
            {banner.buttonTitle}
          </Box>
        </Box>}
        <Box fontSize="0.9em" display="flex">
          {t("kind")}:
          <Box marginLeft="5px" fontWeight="500">
            {banner.kind}
          </Box>
        </Box>
        {banner.href && <Box fontSize="0.9em" display="flex">
          {t("EXTERNAL_ACCESS_LINK")}:
          <Box marginLeft="5px" fontWeight="500" style={{wordBreak:"break-all"}}>
            {banner.href}
          </Box>
        </Box>}
        {banner.nextLink && <Box fontSize="0.9em" display="flex">
          {t("Internal link")}:
        <Box marginLeft="5px" fontWeight="500" style={{wordBreak:"break-all"}}>
          {banner.nextLink}
        </Box>
      </Box>}
        <Box fontSize="0.9em" display="flex">
          {t("SortOrder")}:
          <Box marginLeft="5px" fontWeight="500" style={{wordBreak:"break-all"}}>
            {banner.sortOrder}
          </Box>
        </Box>
        <Box marginLeft="5px" fontWeight="500" style={{wordBreak:"break-all"}}>
          {t(banner.status)}
        </Box>
      </Box>,
      text: <Box textAlign="start">
        {banner.text && <Box fontSize="0.9em" fontWeight="500">
          {t("Text")}:
          <Box marginLeft="5px" fontWeight="400">
            {banner.text}
          </Box>
        </Box>}
        {banner.extraText && <Box fontSize="0.9em" fontWeight="500">
          {t("Extra text")}:
          <Box marginLeft="5px" fontWeight="400">
            {banner.extraText}
          </Box>
        </Box>}
      </Box>,
      mainImage: <Box width={267}>
        {banner.image ? <CardMedia
          component="img"
          alt={banner.title}
          height="100"
          width="267"
          image={banner.image?.url}
          title={banner.title}
        /> : t("Not have")}
      </Box>,
      mobileImage: <Box width={100}>
        {banner.imageMobile ? <CardMedia
          component="img"
          alt={banner.title}
          height="100"
          width="100"
          image={banner.imageMobile.url}
          title={banner.title}
        /> : t("Not have")}
      </Box>


    }));

    return arr;
  }, [banners]);

  return (
    <Box width="100%">
      <MyTable
        hideCheck
        loading={loading}
        headCells={headCells}
        values={rows ?? []}
        sortFields={[]}
        bulkActions={
          <></>
        }
      />
    </Box>
  );
};

const headCells: HeadCell[] = [
  {
    label: "Title",
    align: "left",
  },
  {
    label: "Properties",
    align: "left",
  },
  {
    label: "text",
    align: "center",
  },
  {
    label: "mainImage",
    align: "center",
  },
  {
    label: "mobileImage",
    align: "center",
  },
];