import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { AtomProgress, NotificationToast } from "../../components";
import { useGetSeoTags, useSetHeadbarTitle } from "../../hooks";
import { SeoTagCard } from "./components";
import { AddNew } from "./components/AddNew";

interface SeoViewProps {}

export const SeoView: React.FC<SeoViewProps> = ({}) => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Seotags"));
  const { loading, tags, error } = useGetSeoTags();

  return (
    <>
      <Box
        padding={3}
        marginY={2}
        display="flex"
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {loading && (
          <Box
            position="absolute"
            marginTop="15px"
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}
        <AddNew />
      </Box>

      <Box padding={3} marginY={2}>
        <Grid container spacing={2}>
          {tags.map((tag) => (
            <Grid item md={4} sm={6} xs={12} key={tag.id}>
              <SeoTagCard tag={tag} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
