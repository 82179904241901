import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AtomProgress, NotificationToast } from "../../components";
import {
  AdminGetJobsInput,
  PaginationOptionsArgs,
} from "../../graphql/globalTypes";
import { AdminGetJobs_adminGetJobs_jobs } from "../../graphql/queries/jobs/AdminGetJobs/__generated__/AdminGetJobs";
import { useGetJobs, useSetHeadbarTitle } from "../../hooks";
import { JobsFilterBar, JobsTable } from "./components";

export const Jobs: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Jobs"));
  const [jobs, setJobs] = useState<AdminGetJobs_adminGetJobs_jobs[]>([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [filterParams, setFilterParams] = useState<AdminGetJobsInput>({
    countryId: undefined,
    employmentType: undefined,
    jobCategory: undefined,
    seniority: undefined,
    readAccess: undefined,
    sourceType: undefined,
    status: undefined,
    textSearch: undefined,
    periodTo:null,
    periodFrom:null
  });
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationOptionsArgs>({
      limit: 10,
      skip: 0,
      sort: {
        field: "postedAt",
        sortBy: "desc",
      },
    });
  const { data, error, loading } = useGetJobs({
    input: filterParams,
    paginationOptions,
  });

  useEffect(() => {
    if (data) {
      setTotalJobs(data.adminGetJobs.total);
      setJobs(data.adminGetJobs.jobs);
    }
  }, [data]);

  useEffect(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      skip: 0,
    }));
  }, [filterParams]);

  return (
    <>
      <JobsFilterBar
        jobsCount={data?.adminGetJobs.total}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />


        {loading && (
          <Box
            position="absolute"
            marginTop="15px"
            zIndex={100}
            width="calc(100% - 200px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AtomProgress relative />
          </Box>
        )}




      <JobsTable
        paginationOptions={paginationOptions}
        setPaginationOptions={setPaginationOptions}
        jobs={jobs}
        totalJobs={totalJobs}
        loading={loading}
      />

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
