import { useMutation } from "@apollo/client";
import { UPDATE_USER_EMAIL } from "../../graphql";
import {
  UpdateUserEmail,
  UpdateUserEmailVariables,
} from "../../graphql/mutations/users/UpdateUserEmail/__generated__/UpdateUserEmail";

export const useUpdateEmail = () => {
  const [update, { loading, error }] = useMutation<
    UpdateUserEmail,
    UpdateUserEmailVariables
  >(UPDATE_USER_EMAIL, {
    onError: (err) => console.error(err),
  });

  return { update, loading, error };
};
