import {useMutation} from "@apollo/client";
import {
  UpdatePromoterSettings,
  UpdatePromoterSettingsVariables
} from "../../graphql/mutations/promoters/UpdatePromoterSettings/__generated__/UpdatePromoterSettings";
import {GET_PROMOTER_BY_ADMIN} from "../../graphql/queries/promoters/getPromoterByAdmin";
import {
  UPDATE_PROMOTER_SETTINGS
} from "../../graphql/mutations/promoters/UpdatePromoterSettings";

export const useUpdatePromoterSettings = (id:string) => {
  const [updatePromoterSettings, { loading, error }] = useMutation<
    UpdatePromoterSettings,
    UpdatePromoterSettingsVariables
    >(UPDATE_PROMOTER_SETTINGS, {
    onError: (err) => console.error(err),
    refetchQueries: [
      {
        query: GET_PROMOTER_BY_ADMIN,
        variables: {
          id:id
        },
      },
    ],
  });

  return {
    updatePromoterSettings,
    loading,
    error,
  };
};