import { useMutation } from "@apollo/client";
import { Avatar, Box, Fab, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { NotificationToast } from "../../../components";
import { GET_REVIEWS, UPDATE_REVIEWS_ORDER } from "../../../graphql";
import { ReviewOrderInput } from "../../../graphql/globalTypes";
import {
  UpdateReviewsOrder,
  UpdateReviewsOrderVariables,
} from "../../../graphql/mutations/reviews/UpdateReviewsOrder/__generated__/UpdateReviewsOrder";
import { GetReviews_getReviews } from "../../../graphql/queries/reviews/GetReviews/__generated__/GetReviews";

interface EditOrderProps {
  reviews: GetReviews_getReviews[];
  onClose: () => void;
}

const grid = 8;

export const EditOrder: React.FC<EditOrderProps> = ({ reviews, onClose }) => {
  const { t } = useTranslation();
  const [orderedReviews, setOrderedReviews] =
    useState<GetReviews_getReviews[]>(reviews);
  const [updateOrder, { error, loading }] = useMutation<
    UpdateReviewsOrder,
    UpdateReviewsOrderVariables
  >(UPDATE_REVIEWS_ORDER, {
    onCompleted: () => {
      onClose();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REVIEWS,
      },
    ],
  });

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reviews = reorder(
      orderedReviews,
      result.source.index,
      result.destination.index
    );

    setOrderedReviews(reviews);
  };

  const handleReorder = () => {
    let reviewsOrder: ReviewOrderInput[] = [];
    orderedReviews.forEach((review, index) => {
      const reviewOrderUpdate: ReviewOrderInput = {
        id: review.id,
        order: index,
      };
      reviewsOrder.push(reviewOrderUpdate);
    });

    updateOrder({ variables: { input: { reviewsOrder } } });
  };

  const getListStyle = () => ({
    display: "flex",
    overflow: "auto",
    padding: grid,
  });

  if (!reviews) return null;

  return (
    <Box position={"relative"}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle()}
              {...provided.droppableProps}
            >
              {orderedReviews.map((review, index) => (
                <Draggable
                  key={review.id}
                  draggableId={review.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                        userSelect: "none",
                        padding: grid * 2,
                        margin: `0 ${grid}px 0 0`,
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Avatar
                        style={{ width: "120px", height: "120px" }}
                        src={review.avatar?.url}
                      />
                      <Typography>
                        {review.firstName + " " + review.lastName}
                      </Typography>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Fab
        variant="extended"
        color="primary"
        onClick={handleReorder}
        style={{
          left: 0,
          position: "absolute",
          top: "-74px",
        }}
        disabled={loading}
      >
        <Save />
        {t("Save")}
      </Fab>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};

function reorder(
  reviews: GetReviews_getReviews[],
  startIndex: number,
  endIndex: number
) {
  const result = Array.from(reviews);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
