import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCMS } from "../../hooks";
import { IPicUpload } from "../../lib";
import { CMSUploadButton } from "../buttons";
import { useInputStyles } from "./styles";

interface PictureUploadProps {
  imageUrl?: string;
  picUpload: IPicUpload | null;
  setPicUpload: (p: IPicUpload | null) => void;
  rootStyles?: React.CSSProperties;
}

export const PictureUpload: React.FC<PictureUploadProps> = ({
  imageUrl,
  picUpload,
  setPicUpload,
  rootStyles,
}) => {
  const classes = useInputStyles();
  const { t } = useTranslation();
  useCMS();

  return (
    <Box className={classes.pu__root} style={rootStyles}>
      {picUpload ? (
        <img src={picUpload.url} alt="pic" />
      ) : imageUrl ? (
        <img src={imageUrl} alt="pic" />
      ) : (
        <Typography variant="body2" color="textSecondary">
          {t("No picture chosen")}
        </Typography>
      )}

      <CMSUploadButton
        resource_type="image"
        onInsert={(url, public_id) =>
          setPicUpload({
            public_id,
            url,
          })
        }
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      />
    </Box>
  );
};
