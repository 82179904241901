import {gql} from "@apollo/client";
import {FileFragment} from "../../../fragments";

export const CREATE_PARTNER_COMPANY_CATEGORY = gql`
    mutation CreatePartnerCompanyCategory($input: CreatePartnerCompanyCategoryInput!) {
        createPartnerCompanyCategory(input: $input) {
            partnerCompanyCategory {
                name
                id
                logo{
                    ...File
                }
            }
            error {
                name
                message
            }
        }
    }
    ${FileFragment}
`;
