import { useQuery, useReactiveVar } from "@apollo/client";
import { Box, IconButton, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { drawerOpenVar } from "../../../apollo";
import { MyTable, TextSearch } from "../../../components";
import { GET_COUNTRIES } from "../../../graphql";
import {
  GetCountries,
  GetCountriesVariables,
  GetCountries_getCountries,
} from "../../../graphql/queries/country/getCountries/__generated__/GetCountries";
import { useSetHeadbarTitle } from "../../../hooks";
import { HeadCell } from "../../../lib";
import { EditCountry } from "./components";

interface CountriesViewProps {}

export const CountriesView: React.FC<CountriesViewProps> = ({}) => {
  const { t } = useTranslation();
  const drawerOpen = useReactiveVar(drawerOpenVar);
  useSetHeadbarTitle(t("Countries"));
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<GetCountriesVariables>({ search: "" });
  const [currentCountry, setCurrentCountry] =
    useState<GetCountries_getCountries | null>(null);
  const { data, loading, error } = useQuery<
    GetCountries,
    GetCountriesVariables
  >(GET_COUNTRIES, {
    variables: filter,
    fetchPolicy: "network-only",
  });

  const mapCompaniesToRows = useMemo(() => {
    if (!data?.getCountries.length) return [];

    const arr = data.getCountries.map((country) => ({
      id: country.id,
      ALPHA3: <Typography variant="subtitle2">{country.country}</Typography>,
      Rus_trans: (
        <Typography variant="subtitle2">{country.rusTrans}</Typography>
      ),
      Eng_trans: (
        <Typography variant="subtitle2">{country.engTrans}</Typography>
      ),
      salaryFactor: (
        <Typography variant="subtitle2">{country.factor}</Typography>
      ),
      edit: (
        <IconButton onClick={() => handleOpen(country)}>
          <Edit />
        </IconButton>
      ),
    }));
    return arr;
  }, [data?.getCountries]);

  const handleTextSearchChange = (v: string) => {
    if (v.length > 2) {
      setFilter({ search: v });
    }
  };

  const handleTextSearchReset = () => {
    setFilter({ search: "" });
  };

  const handleOpen = (country: GetCountries_getCountries) => {
    setOpen(true);
    setCurrentCountry(country);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentCountry(null);
  };

  return (
    <>
      <Box
        zIndex={100}
        display="flex"
        flexWrap="wrap"
        width={drawerOpen ? "calc(100% - 240px)" : "calc(100% - 73px)"}
        justifyContent="flex-start"
        position="fixed"
      >
        <TextSearch
          rootStyle={{ flexBasis: "40%" }}
          onClick={handleTextSearchChange}
          onReset={handleTextSearchReset}
          hint={"Name, name abbreviations..."}
        />
      </Box>

      <Box width="100%" marginY={2}>
        <MyTable
          loading={loading}
          total={data?.getCountries.length ?? 0}
          headCells={headCells}
          values={mapCompaniesToRows}
          bulkActions={<></>}
          sortFields={[]}
          hideCheck
        />
      </Box>

      <EditCountry
        open={open}
        onClose={handleClose}
        country={currentCountry}
        filter={filter}
      />
    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "ALPHA3",
    align: "left",
  },
  {
    label: "Rus_trans",
    align: "left",
  },
  {
    label: "Eng_trans",
    align: "left",
  },
  {
    label: "salaryFactor",
    align: "right",
  },
  {
    label: "edit",
    align: "right",
  },
];
