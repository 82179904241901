import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFetchEnumsWithStats, useSetHeadbarTitle } from "../../hooks";
import {
  EnumList,
  FullscreenLoader,
  NotificationToast,
} from "../../components";
import {
  FetchEnumsWithStats_fetchEnumsWithStats_employmentTypes as IEmpType,
  FetchEnumsWithStats_fetchEnumsWithStats_seniorities as ISeniority,
} from "../../graphql/queries/jobs/GetEnumsWithStats/__generated__/FetchEnumsWithStats";

export const JobEnums: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Job Enums"));
  const history = useHistory();
  const { enums, error, loading } = useFetchEnumsWithStats();
  const [seniorities, setSeniorities] = useState<ISeniority[]>([]);
  const [empTypes, setEmpTypes] = useState<IEmpType[]>([]);

  useEffect(() => {
    if (enums) {
      setSeniorities(enums.seniorities);
      setEmpTypes(enums.employmentTypes);
    }
  }, [enums]);

  return (
    <Box padding={2}>
      <Box marginTop={2} />

      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          <EnumList
            values={seniorities}
            title={t("Seniorities")}
            repoName="seniority"
            withFactor
          />
        </Grid>
        <Grid item xs={6}>
          <EnumList
            values={empTypes}
            title={t("Employment Type")}
            repoName="employmentType"
          />
        </Grid>
      </Grid>

      {loading && <FullscreenLoader transparent />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </Box>
  );
};
