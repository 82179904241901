import {gql} from "@apollo/client";
import {
    CountryFragment,
    UserFragment
} from "../../../fragments";

export const GET_TRACKER_FILTERS = gql`
    query GetTrackerFilters {
        getTrackerFilters {
            countries{
                ...Country
            }
            authors{
                ...User
            }
        }
    }
    ${CountryFragment}
    ${UserFragment}
`;