import { gql } from "@apollo/client";
import {BannerFragment} from "../../../fragments";

export const GET_BANNERS = gql`
    query GetBanners($input: GetBannersInput!) {
        getBanners(input: $input) {
                ...Banner
        }
    }
    ${BannerFragment}
`;
