import { useQuery } from "@apollo/client";
import { GET_ARTICLE_CATEGORIES } from "../../graphql/queries/article";
import { GetArticleCategories } from "../../graphql/queries/article/GetArticleCategories/__generated__/GetArticleCategories";

export const useGetArticleCategories = () => {
  const { loading, error, data } = useQuery<GetArticleCategories>(
    GET_ARTICLE_CATEGORIES,
    {
      onError: (err) => console.error(err),
    }
  );

  return { loading, error, categories: data?.getArticleCategories };
};
