import {gql} from "@apollo/client";
import {BankAccountFragment, TransactionFragment} from "../../../fragments";

export const UPDATE_PROMOTER_PAYOUT = gql`
    mutation UpdatePromoterPayout($input: UpdatePromoterPayoutInput!) {
        updatePromoterPayout(input: $input) {
            id
            promoterId
            failReason
            createdAt
            paidOutAt
            periodFrom
            periodTo
            productCommission
            subscriptionCommission
            totalAmount
            currency
            status
            stripeInvoices{
                stripeSubscriptionId
                invoiceId
                currency
                amount
            }
            yooInvoices{
                subscriptionId
                transaction{
                    ...Transaction
                }
            }
            bankAccount{
                ...BankAccount
            }
        }
    }
    ${BankAccountFragment}
    ${TransactionFragment}
`;