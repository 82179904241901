import { gql } from "@apollo/client";

export const CountryFragment = gql`
  fragment Country on Country_Gql {
    id
    country
    abbreviations
    cities
    factor
    engTrans
    rusTrans
    regions
  }
`;

export const LocationFragment = gql`
  fragment Location on Address_Gql {
    state
    zipCode
    city
    countryObject {
      ...Country
    }
  }
  ${CountryFragment}
`;

export const ReferralFragment = gql`
  fragment Referral on Referral_Gql {
    id
    firstName
    lastName
    type
    email
    phone
    facebook
    telegram
    linkedIn
    companiesIds
    companies {
      id
      name
    }
  }
`;

export const CompanyFragment = gql`
  fragment Company on Company_Gql {
    id
    name
    nameAbbreviations
    salaryFactor
    referrals {
      id
      firstName
      lastName
    }
  }
`;

export const FileFragment = gql`
  fragment File on File_Gql {
    fileName
    url
    public_id
    type
  }
`;

export const SeoTagFragment = gql`
  fragment SeoTag on SeoTag_Gql {
    url
    description
    title
    id
  }
`;

export const MetaFragment = gql`
  fragment Meta on MetaObjectType {
    hasNextPage
    hasPrevPage
    totalItems
    totalPages
    currentPage
    nextPage
    prevPage
  }
`;

export const JobPreferenceFragment = gql`
  fragment JobPreference on Preference_GQL {
    jobLocations
    jobCategories
  }
`;

export const EnumFragment = gql`
  fragment Enum on Enum_Gql {
    id
    name
    translation
    logo {
      ...File
    }
    factor
  }
  ${FileFragment}
`;

export const ArticleCategoryFragment = gql`
  fragment ArticleCategory on ArticleCategory_Gql {
    id
    name
    picture {
      ...File
    }
  }
  ${FileFragment}
`;

export const TableArticleFragment = gql`
  fragment TableArticle on Article_Gql {
    id
    title
    readAccess
    categoriesIds
    description
    slug
    hideHalf
    recentlyUpdated
    isPinned
    viewCount
    status
    updated_at
    isFeatured
    hiddenFromArticles
    language
    withVideo
  }
`;

export const ArticleFragment = gql`
  fragment Article on Article_Gql {
    ...TableArticle
    author {
      id
      firstName
      lastName
      avatar {
        url
      }
    }
    featureOrder
    content
    withVideo
    tags
    picture {
      ...File
    }
    articleText
    buttonLink
    buttonTitle
    video
  }
  ${TableArticleFragment}
  ${FileFragment}
`;

export const BankAccountFragment = gql`
  fragment BankAccount on BankAccount_Gql {
    accountName
    address {
      ...Location
    }
    currency
    iban
    reference
    swift_bic
  }
  ${LocationFragment}
`;

export const UserFragment = gql`
  fragment User on User_GQL {
    id
    email
    firstName
    lastName
    emailVerified
    role
    notificationTypes
    isLocked
    isDeleted
    gender
    summary
    birthDate
    nationality
    skype
    linkedId
    zoom
    phone
    languages
    created_at
    partnerSettings {
      partnershipStarted
      partnershipCancelled
      username
      isDeactivated
      chatGptTokensLeft
      chatGptTokensPeriodStart
      subscriptionCommission
      productCommission
      couponCode
      couponSubscriptionDiscount
      couponProductDiscount
      bankAccount {
        ...BankAccount
      }
    }
    applyForPartnership {
      isApprovedPartner
      requestForPartnershipSent
    }
    customerInfo {
      jobPreference {
        ...JobPreference
      }
      isFellow
      orderID
      subscriptionID
      subscriptionStatus
      chatGptTokensPeriodStart
      chatGptTokensLeft
    }
    location {
      ...Location
    }
    avatar {
      ...File
    }
  }
  ${FileFragment}
  ${LocationFragment}
  ${JobPreferenceFragment}
  ${BankAccountFragment}
`;

export const SubscriptionUserFragment = gql`
  fragment SubscriptionUser on SubscriptionUser {
    id
    email
    firstName
    lastName
    emailVerified
    role
    gender
    avatar {
      ...File
    }
    isLocked
    isDeleted
    customerInfo {
      jobPreference {
        ...JobPreference
      }
      isFellow
      orderID
      subscriptionID
      subscriptionStatus
      chatGptTokensPeriodStart
      chatGptTokensLeft
    }
  }
  ${FileFragment}
  ${LocationFragment}
  ${JobPreferenceFragment}
`;

export const TableUserFragment = gql`
  fragment TableUser on User_GQL {
    id
    email
    firstName
    lastName
    emailVerified
    role
    partnerSettings {
      username
      isDeactivated
      chatGptTokensLeft
      chatGptTokensPeriodStart
      subscriptionCommission
      productCommission
      couponSubscriptionDiscount
      couponProductDiscount
      couponCode
      bankAccount {
        ...BankAccount
      }
    }
    applyForPartnership {
      isApprovedPartner
      requestForPartnershipSent
    }
    customerInfo {
      isFellow
      orderID
      subscriptionID
      subscriptionStatus
      chatGptTokensLeft
      chatGptTokensPeriodStart
    }
    location {
      ...Location
    }
    nationality
    languages
  }
  ${LocationFragment}
  ${BankAccountFragment}
`;

export const TableJobFragment = gql`
  fragment TableJob on Job_Gql {
    id
    title
    jobCategoriesIds
    jobCategories {
      id
      minSalary
      maxSalary
      avgSalary
      name
    }
    industries
    postedAt
    openUntil
    seniorityId
    employmentTypeId
    savedBy
    readAccess
    viewCount
    status
    companyId
    companyObject {
      ...Company
    }
    company
    location {
      countryObject {
        ...Country
      }
      city
      state
    }
    salary {
      min
      max
      avg
      withoutCompany
      currency
    }
    createdBy {
      ...User
    }
  }
  ${CompanyFragment}
  ${CountryFragment}
  ${UserFragment}
`;

export const JobFragment = gql`
  fragment Job on Job_Gql {
    ...TableJob
    sourceId
    isUN
    sourceType
    applicationLink
    description
    tags
    location {
      countryObject {
        ...Country
      }
      city
      state
    }
  }
  ${TableJobFragment}
  ${CountryFragment}
`;


export const EnumWithStatFragment = gql`
  fragment EnumWithStat on EnumWithCount {
    enum {
      ...Enum
    }
    userCount
    jobCount
  }
  ${EnumFragment}
`;

export const SubscriptionPlanFragment = gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    id
    title
    description
    features
    billingCycle
    status
    price {
      value
      currency
    }
    taxData {
      vatCode
      paymentSubject
      paymentMethod
    }
    language
  }
`;

export const TransactionFragment = gql`
  fragment Transaction on Transaction_Gql {
    yooId
    yooStatus
    price {
      currency
      value
    }
    capturedAt
    refunds {
      yooId
      yooStatus
      amount {
        currency
        value
      }
      capturedAt
    }
  }
`;

export const ReviewFragment = gql`
  fragment Review on Review_Gql {
    id
    firstName
    lastName
    position
    text
    order
    categories
    avatar {
      ...File
    }
    language
  }
  ${FileFragment}
`;

export const LearningStepFragment = gql`
  fragment LearningStep on LearningStep_Gql {
    text
    order
    hasVideo
    articleId
    icon {
      ...File
    }
    articleId
    article {
      id
      title
      readAccess
      slug
      status
      picture {
        ...File
      }
    }
  }
  ${FileFragment}
`;

export const LearningBulletPointFragment = gql`
  fragment LearningBulletPoint on LearningBulletPoint_Gql {
    text
    big_text
    picture {
      ...File
    }
  }
  ${FileFragment}
`;

export const LearningPathFragment = gql`
  fragment LearningPath on LearningPath_Gql {
    id
    language
    title
    slug
    heroVideoUrl
    description
    countAvailableArticles
    status
    announcementOnly
    bulletPoints {
      ...LearningBulletPoint
    }
    steps {
      ...LearningStep
    }
    heroPic {
      ...File
    }
  }
  ${LearningBulletPointFragment}
  ${LearningStepFragment}
`;

export const CouponFragment = gql`
  fragment Coupon on Coupon_Gql {
    countTimes
    countUsers
    couponCode
    discountPercentage
    id
  }
`;

export const IncomeFragment = gql`
  fragment Income on Income_Gql {
    dateFrom
    dateTo
    amount
    currency
  }
`;
export const PromoterSubscriptionFragment = gql`
  fragment PromoterSubscription on PromoterSubscription_GQL {
    billingCycle
    commission
    coupon {
      ...Coupon
    }
    couponDiscountPercentage
    currency
    customerEmail
    id
    nextTimeCharge
    numberOfCharges
    paymentMethod
    planTitle
    priceAfterDiscount
    priceWithoutDiscount
    startTime
    status
  }
  ${CouponFragment}
`;

export const PluginUserFragment = gql`
  fragment PluginUser on UserProfile_Gql {
    id
    email
    firstName
    lastName
    role
    gender
    summary
    skype
    linkedId
    zoom
    phone
    nationality
    languages
    birthDate
    avatar {
      ...File
    }
    location {
      ...Location
    }
    created_at
  }
  ${LocationFragment}
  ${FileFragment}
`;

export const SubProductFragment = gql`
  fragment SubProduct on Product_GQL {
    id
    stripeId
    slug
    sortOrder
    discountPercentage
    baseNumber
    purchasesPerMonth
    linkToProductInfo
    marketingPrice
    canBeSoldSeparately
    isFeatured
    isHidden
    status
    variant
    priceInRub
    priceInUsd
    title
    description
    features
    category
    kind
    language
    shortDescription
    picture {
      ...File
    }
    consultationWithCoachProps {
      coach {
        ...PluginUser
      }
      linkForBooking
    }
    downloadableMediaProps {
      files {
        ...File
      }
    }
    externalAccessLinkProps {
      type
      url
    }
    accessToLearningPathProps {
      learningPathId
      learningPath {
        ...LearningPath
      }
    }
    accessToMarathonProps {
      marathonTitle
      linkForBooking
    }
  }
  ${FileFragment}
  ${PluginUserFragment}
  ${LearningPathFragment}
`;

export const ProductFragment = gql`
  fragment Product on Product_GQL {
    ...SubProduct
    products {
      ...SubProduct
    }
  }
  ${SubProductFragment}
`;

export const SubShortProductFragment = gql`
  fragment SubShortProduct on ShortProduct_GQL {
    id
    slug
    sortOrder
    linkToProductInfo
    marketingPrice
    canBeSoldSeparately
    discountPercentage
    baseNumber
    purchasesPerMonth
    isFeatured
    isHidden
    variant
    priceInRub
    priceInUsd
    title
    status
    description
    features
    category
    kind
    language
    shortDescription
    picture {
      ...File
    }
  }
  ${FileFragment}
`;

export const ShortProductFragment = gql`
  fragment ShortProduct on ShortProduct_GQL {
    ...SubShortProduct
    products {
      ...SubShortProduct
    }
  }
  ${SubShortProductFragment}
`;

export const OrderFragment = gql`
  fragment Order on Order_GQL {
    id
    partner {
      ...User
    }
    created_at
    stripeId
    status
    paymentProvider
    product {
      ...Product
    }
    customerId
    customer {
      ...User
    }
    quantity
    discount {
      customerId
      ordersIds
      customer {
        ...User
      }
      numberOfChargesLeft
      coupon {
        ...Coupon
      }
    }
    originalPrice {
      value
      currency
    }
    priceWithDiscount {
      value
      currency
    }
    totalPrice {
      value
      currency
    }
  }
  ${ProductFragment}
  ${CouponFragment}
  ${UserFragment}
`;
export const PartnerCompanyServiceFragment = gql`
  fragment PartnerCompanyService on PartnerCompanyService_GQL {
    id
    title
    description
    promoText
    price {
      currency
      value
    }
    discountPercentage
    affiliateLink
    picture {
      ...File
    }
  }
  ${FileFragment}
`;

export const PartnerCompanyFragment = gql`
  fragment PartnerCompany on PartnerCompany_Gql {
    id
    title
    subtitle
    description
    slug
    linkedId
    twitter
    telegram
    youTube
    website
    status
    partnerCompanyCategoriesIds
    partnerCompanyCategories {
      name
      id
      logo {
        ...File
      }
    }
    tags {
      title
      category
      id
    }
    objectTags {
      title
      category
      id
    }
    features
    locations {
      ...Location
    }
    services {
      ...PartnerCompanyService
    }
    reviews {
      title
      text
      userName
      language
      picture {
        ...File
      }
    }
    languages
    picture {
      ...File
    }
  }
  ${FileFragment}
  ${LocationFragment}
  ${PartnerCompanyServiceFragment}
`;
export const ExperienceItemFragment = gql`
  fragment ExperienceItem on ExperienceItem_GQL {
    id
    company {
      name
      id
      logo {
        ...File
      }
    }
    position
    startedAt
    finishedAt
    isWorkingCurrently
  }
  ${FileFragment}
`;

export const ExpertFragment = gql`
  fragment Expert on Expert_Gql {
    id
    userId {
      ...User
    }
    name
    position
    description
    experience {
      ...ExperienceItem
    }
    usefulness
    slug
    picture {
      ...File
    }
    expertRequests {
      title
      category
      id
    }
    expertActivities {
      title
      id
    }
    services {
      id
      title
      description
      price {
        value
        currency
      }
      meetingsInfo
      affiliateLink
      promoText
      document {
        ...File
      }
    }
    reviews {
      id
      title
      text
      language
      userName
      picture {
        ...File
      }
    }
    locations {
      ...Location
    }
    contacts {
      linkedIn
      twitter
      telegram
      youTube
      website
      vk
      instagram
    }
    languages
    postedAt
    status
  }
  ${FileFragment}
  ${LocationFragment}
  ${ExperienceItemFragment}
  ${UserFragment}
`;

export const TrackerItemFragment = gql`
  fragment TrackerItem on TrackerItem_Gql {
    appliedAt
    company
    country {
      ...Country
    }
    createdAt
    createdBy {
      ...User
    }
    id
    job {
      ...Job
    }
    link
    notes
    status
    title
    description
    salary
    companyLogo {
      ...File
    }
  }
  ${CountryFragment}
  ${UserFragment}
  ${JobFragment}
  ${FileFragment}
`;

export const BannerFragment = gql`
  fragment Banner on Banner_Gql {
    id
    kind
    title
    text
    extraText
    buttonTitle
    nextLink
    href
    image {
      ...File
    }
    imageMobile {
      ...File
    }
    isFree
    isRegistered
    isPaid
    sortOrder
    status
    language
  }
  ${FileFragment}
`;

export const AffiliateFragment = gql`
  fragment Affiliate on Affiliate_Gql {
    id
    userId {
      ...User
    }
    name
    email
    affiliateApplicationText
    created_at
    updated_at
    status
  }
  ${UserFragment}
`;

export const PromoterUserFragment = gql`
  fragment PromoterUser on PromoterUser_Gql {
    id
    email
    firstName
    lastName
    role
    gender
    summary
    skype
    linkedId
    zoom
    phone
    nationality
    languages
    birthDate
    avatar {
      ...File
    }
    location {
      ...Location
    }
    notificationTypes
    resume {
      experienceYears
      experienceDescription
      withRelocation
      email
    }
    isLocked
    isDeleted
    created_at
    partnerSettings {
      username
      isDeactivated
      chatGptTokensLeft
      chatGptTokensPeriodStart
      partnershipStarted
      partnershipCancelled
      subscriptionCommission
      productCommission
      couponCode
      couponSubscriptionDiscount
      couponProductDiscount
      isSubscribedOnPaymentsInfo
      bankAccount {
        ...BankAccount
      }
    }
  }
  ${FileFragment}
  ${LocationFragment}
  ${BankAccountFragment}
`;
