import { gql } from "@apollo/client";
import { TableArticleFragment } from "../../../fragments";

export const GET_ARTICLES = gql`
  query GetArticlesByAdmin(
    $input: GetArticlesAdminInput!
    $paginationOptions: PaginationOptionsArgs!
  ) {
    getArticlesByAdmin(input: $input, paginationOptions: $paginationOptions) {
      articles {
        ...TableArticle
      }
      total
    }
  }
  ${TableArticleFragment}
`;
