import { Badge, Box, IconButton, Menu, Typography } from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const HeaderNotification: React.FC = ({}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [notifications, setNotifications] = useState<any[]>([]);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge="end"
        color="inherit"
        style={{ marginRight: 10 }}
        onClick={handleClick}
      >
        <Badge color="secondary" badgeContent={notifications.length}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "250px",
            minWidth: 250,
            maxWidth: "400px",
          },
        }}
        elevation={10}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {notifications.length === 0 ? (
          <Box textAlign="center" padding={1}>
            <Typography variant="body2">{t("No Notifications Yet")}</Typography>
          </Box>
        ) : null}
      </Menu>
    </>
  );
};
