import { useQuery } from "@apollo/client";
import { GET_REVIEWS } from "../../graphql";
import { GetReviews } from "../../graphql/queries/reviews/GetReviews/__generated__/GetReviews";

export const useGetReviews = () => {
  const { data, loading, error } = useQuery<GetReviews>(GET_REVIEWS, {
    onError: (err) => console.log(err),
  });

  return {
    reviews: data?.getReviews,
    loading,
    error,
  };
};
