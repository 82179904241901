export enum FileType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
}

export enum Gender {
  F = "Female",
  M = "Male",
}
export enum BillingCycle {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
  SEMI_ANNUAL="SEMI_ANNUAL"
}

export enum PendingJobStatus {
  pending = "pending",
  done = "done",
  cancelled = "cancelled",
}

export type EnumRepoName = "jobCategory" | "seniority" | "employmentType";

export enum JobSource {
  LINKEDIN = "LinkedIn",
  IMPACTPOOL = "ImpactPool",
  OTHER = "Other",
}

export enum ReadAccess {
  UNANYMOUS = "UNANYMOUS",
  AUTHORIZED = "AUTHORIZED",
  FELLOW = "FELLOW",
}

export enum UserRole {
  GLOBAL_ADMIN = "GLOBAL_ADMIN",
  ADMIN = "ADMIN",
  MODERATOR = "MODERATOR",
  CUSTOMER = "CUSTOMER",
  COACH = "COACH",
}

export const nationalities = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Ni-Vanuatu",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];

export enum ReferralType {
  Recruiter = "Recruiter",
  Employee = "Employee",
}

export enum Currency {
  RUB = "RUB",
  USD = "USD",
  GEL = "GEL",
  EUR = "EUR",
  AUD = "AUD",
  ARS = "ARS",
  BHD = "BHD",
  BGN = "BGN",
  BRL = "BRL",
  HKD = "HKD",
  DKK = "DKK",
  AED = "AED",
  ILS = "ILS",
  INR = "INR",
  KZT = "KZT",
  CAD = "CAD",
  CNY = "CNY",
  COP = "COP",
  KWD = "KWD",
  MYR = "MYR",
  MXN = "MXN",
  MDL = "MDL",
  NAD = "NAD",
  NZD = "NZD",
  RON = "RON",
  NOK = "NOK",
  OMR = "OMR",
  PLN = "PLN",
  SAR = "SAR",
  SGD = "SGD",
  TWD = "TWD",
  TRY = "TRY",
  UAH = "UAH",
  SEK = "SEK",
  JPY = "JPY",
  KRW = "KRW",
  CLP = "CLP",
  GBP = "GBP",
  GNF = "GNF",
  QAR = "QAR",
  THB = "THB",
  CZK = "CZK",
  FJD = "FJD",
  IDR = "IDR",
}

export const languages = [
  "Afrikaans",
  "العربية",
  "Български Език",
  "中文 (Zhōngwén), 汉语, 漢語",
  "Hrvatski",
  "česky, čeština",
  "Dansk",
  "Nederlands",
  "English",
  "Eesti",
  "Suomi",
  "Français",
  "Deutsch",
  "Ελληνικά",
  "עברית",
  "हिन्दी, हिंदी",
  "Magyar",
  "Íslenska",
  "Italiano",
  "日本語 (にほんご／にっぽんご)",
  "Қазақ тілі",
  "한국어 (韓國語), 조선말 (朝鮮語)",
  "Lietuvių kalba",
  "Norsk",
  "Latviešu valoda",
  "Polski",
  "Português",
  "Русский язык",
  "Српски језик",
  "Slovenčina",
  "Slovenščina",
  "Español",
  "Svenska",
  "Türkçe",
  "Українська",
];

export enum NotificationType {
  EMAIL = "EMAIL",
}
