//@ts-nocheck
import { CellPlugin } from "@react-page/editor";
import divider from "@react-page/plugins-divider";
import html5video from "@react-page/plugins-html5-video";
import "@react-page/plugins-html5-video/lib/index.css";
import spacer from "@react-page/plugins-spacer";
import "@react-page/plugins-spacer/lib/index.css";
import video from "@react-page/plugins-video";
import "@react-page/plugins-video/lib/index.css";
import background, { ModeEnum } from "./background";
import { imagePlugin } from "./image";
import { defaultSlate } from "./slate";
import { audioPlugin } from "./audio";
import { muiButtonPlugin } from "./muiButton";
import { muiIconPlugin } from "./muiIcon";
import { PdfViewerPlugin } from "./pdfViewer";

export const cellPlugins: CellPlugin[] = [
  defaultSlate,
  spacer,
  divider,
  video,
  html5video,
  muiButtonPlugin(),
  audioPlugin(),
  imagePlugin(),
  muiIconPlugin(),
  PdfViewerPlugin(),
  background({
    enabledModes:
      ModeEnum.COLOR_MODE_FLAG |
      ModeEnum.IMAGE_MODE_FLAG |
      ModeEnum.GRADIENT_MODE_FLAG,
  }),
];
