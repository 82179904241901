import { gql } from "@apollo/client";
import { UserFragment } from "../../../fragments";

export const UPDATE_MY_SUMMARY = gql`
  mutation UpdateMySummary($input: UpdateMySummaryInput!) {
    updateMySummary(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`;
