import { Fab } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React from "react";

interface AddNewButtonProps {
  title: string;
  onClick: () => void;
}

export const AddNewButton: React.FC<AddNewButtonProps> = ({
  title,
  onClick,
}) => {
  return (
    <Fab variant="extended" color="primary" onClick={onClick}>
      <AddIcon />
      {title}
    </Fab>
  );
};
