import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Fallback, FullscreenLoader } from "../../components";
import { useVerifyEmailByLink } from "../../hooks";
import { Header } from "./components";

export const EmailVerification: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams<ParamsProps>();
  const { loading, serverError, verify, linkError } = useVerifyEmailByLink();
  const history = useHistory();

  useEffect(() => {
    verify({ variables: { token } });
  }, []);

  if (loading) return <FullscreenLoader />;

  if (serverError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  if (linkError === "invalid") {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  return (
    <Box padding={2} position="relative" textAlign="center">
      <Header />
      <Box paddingY={3}>
        {linkError ? (
          <Typography variant="caption">
            {t("Verification Link Expired")}
          </Typography>
        ) : (
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">{t("Email is verified")}</Typography>
            <Box marginY={2} />
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.replace("/login")}
            >
              {t("Go to login")}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface ParamsProps {
  token: string;
}
