import { gql } from "@apollo/client";
import { SeoTagFragment } from "../../../fragments";

export const GET_SEO_TAG_BY_URL = gql`
  query GetSeoTagByUrl($url: String!) {
    getSeoTagByUrl(url: $url) {
      ...SeoTag
    }
  }
  ${SeoTagFragment}
`;
