import {Box, IconButton} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {MyTable} from "../../../components";
import {PaginationOptionsArgs} from "../../../graphql/globalTypes";
import {HeadCell} from "../../../lib";
import {DeleteProductDialog} from "./DeleteProductDialog";
import {
  GetProducts_getProducts_products
} from "../../../graphql/queries/products/getProducts/__generated__/GetProducts";
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from "react-router-dom";

interface ProductsTableProps {
  loading: boolean;
  products: GetProducts_getProducts_products[];
  totalProducts: number;
  paginationOptions: PaginationOptionsArgs;
  setPaginationOptions: React.Dispatch<React.SetStateAction<PaginationOptionsArgs>>;
}

export const ProductsTable: React.FC<ProductsTableProps>
  = ({
       loading,
       products,
       totalProducts,
       paginationOptions,
       setPaginationOptions,
     }) => {
  const {t} = useTranslation();
  const history = useHistory();
  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/products/edit/${id}`,
      // state: {
      //   job: res.data!.createJob.job,
      //   showCreateNotification: true,
      // },
    })
  }
  const mapProductsToRows = useMemo(() => {
    if (!products?.length) return [];
    const arr = products.map((product, index) => ({
      id: product.id,
      number: index + 1,
      productTitle: (<Box>
        <Box fontWeight={500}>{product.title}</Box>
        <Box>
          <IconButton onClick={() => handleEditClick(product.id)}>
            <EditIcon color="primary"/>
          </IconButton>
          <DeleteProductDialog productId={product.id}/>
        </Box>
      </Box>),
      types: (<Box>
        <Box fontSize="0.9em"
             display="flex">{t("category")}:<Box marginLeft="5px" fontWeight="500">{t(product.category)}</Box></Box>
        <Box fontSize="0.9em"
             display="flex">{t("variant")}:<Box marginLeft="5px" fontWeight="500">{t(product.variant)}</Box></Box>
        <Box fontSize="0.9em" display="flex">{t("status")}:<Box marginLeft="5px" fontWeight="500">{t(product.status)}</Box></Box>
      </Box>),
      price: (
        <Box>
          <Box style={{textDecoration: "line-through", color: "grey", fontSize: "0.8em"}}>
            {product.marketingPrice}</Box>
          <Box fontWeight={500} fontSize="0.9em">
            USD {product.priceInUsd} / РУБ {Math.round(product.priceInRub)}
          </Box>
        </Box>),
      states: (<Box>
        <Box
          display="flex">{t("isHidden")}: <Box marginLeft="5px" fontWeight="500">{product.isHidden ? t("yes") : t("no")}</Box></Box>
        <Box
          display="flex">{t("isFeatured")}: <Box marginLeft="5px" fontWeight="500">{product.isFeatured ? t("yes") : t("no")}</Box></Box>
        <Box
          display="flex">{t("canBeSoldSeparately")}: <Box marginLeft="5px" fontWeight="500">{product.canBeSoldSeparately ? t("yes") : t("no")}</Box></Box>
      </Box>),
      action: (<Box>
        <IconButton onClick={() => handleEditClick(product.id)}>
          <EditIcon color="primary"/>
        </IconButton>
        <DeleteProductDialog productId={product.id}/>
      </Box>),
    }));
    return arr;
  }, [products]);


  return (
    <>
      <Box width="100%">
        <MyTable
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
          total={totalProducts}
          loading={loading}
          sortFields={[]}
          headCells={headCells}
          values={mapProductsToRows}
          hideCheck
          bulkActions={<></>}/>
      </Box>

    </>
  );
};

const headCells: HeadCell[] = [
  {
    label: "number",
    align: "left",
  },
  {
    label: "productTitle",
    align: "left",
    width: "250px"
  },
  {
    label: "price",
    align: "left",
    width: "150px"
  },
  {
    label: "types",
    align: "left",
  },
  {
    label: "states",
    align: "left",
  },
];