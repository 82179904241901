import { useMutation } from "@apollo/client";
import { RESET_PASS_BY_TOKEN } from "../../graphql";
import {
  ResetPasswordByToken,
  ResetPasswordByTokenVariables,
} from "../../graphql/mutations/me/ResetPasswordByToken/__generated__/ResetPasswordByToken";

export const useResetPassword = () => {
  const [resetPass, { loading, error }] = useMutation<
    ResetPasswordByToken,
    ResetPasswordByTokenVariables
  >(RESET_PASS_BY_TOKEN, {
    onError: (err) => console.error(err),
  });

  return {
    resetPass,
    loading,
    error,
  };
};
