import {Form, Formik, FormikErrors} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {
  Fallback,
  FullscreenLoader,
  NotificationToast,
} from "../../components";
import {UpdateArticleInput} from "../../graphql/globalTypes";
import {
  useFetchArticleFromRoute,
  useSetHeadbarTitle,
  useUpdateArticle,
} from "../../hooks";
import {
  ArticlesFormValues,
  FileType,
  IPicUpload,
  ReadAccess,
} from "../../lib";
import {useCommonStyle} from "../../styles/commonStyles";
import * as Yup from "yup";
import {Box} from "@material-ui/core";
import {EditArticlesLeft, EditArticleRight} from "./components";
import {
  UpdateArticleVariables
} from "../../graphql/mutations/articles/UpdateArticle/__generated__/UpdateArticle";
import {getFileNameFromPublicId} from "../../utils";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

export const EditArticle: React.FC = () => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Article Editor"));
  const history = useHistory();
  const commonClasses = useCommonStyle();
  const [success, setSuccess] = useState(false);
  const [picUpload, setPicUpload] = useState<IPicUpload | null>(null);
  const {
    article,
    error: initError,
    loading: initLoading,
    showCreateNotification,
  } = useFetchArticleFromRoute();
  const [input, setInput] = useState<UpdateArticleInput | null>(null);
  const {update, error, loading} = useUpdateArticle();

  useEffect(() => {
    if (article) {
      setInput({
        id: article.id,
        readAccess: article.readAccess,
        status: article.status,
        authorId: article.author?.id,
        categoriesIds: article.categoriesIds,
        tags: article.tags,
        content: article.content,
        featureOrder: article.featureOrder ?? 0,
        language: article.language
      });
    }
  }, [article]);

  if (initError || (!article && !initLoading)) {
    return (
      <Fallback
        title={t("Article not found")}
        message={t("Seems like the article you want does not exist")}
        onClick={() => history.push("/articles")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading || !input) {
    return <FullscreenLoader transparent/>;
  }

  const initialValues: ArticlesFormValues = {
    title: article!.title,
    description: article!.description ?? "",
    content: article!.content ?? "",
    slug: article!.slug,
    isPinned: article!.isPinned ?? false,
    withVideo: article!.withVideo ?? false,
    hideHalf: article!.hideHalf ?? false,
    // recentlyUpdated: article!.recentlyUpdated ?? false,
    isFeatured: article!.isFeatured ?? false,
    hiddenFromArticles: article!.hiddenFromArticles ?? false,
    articleText: article?.articleText ? EditorState.createWithContent(ContentState.createFromBlockArray(
      htmlToDraft(article?.articleText).contentBlocks,
      htmlToDraft(article?.articleText).entityMap
    )) : undefined,
    video: article?.video ?? undefined,
    buttonTitle: article?.buttonTitle ?? undefined,
    buttonLink: article?.buttonLink ?? undefined,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, t("Must have at least VAR characters", {count: 2}))
      .required(t("Required")),
    slug: Yup.string()
      .min(2, t("Must have at least VAR characters", {count: 2}))
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t("Invalid slug"))
      .required(t("Required")),
  });

  const handleSubmit = async (
    values: ArticlesFormValues,
    setErrors: (errors: FormikErrors<ArticlesFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSuccess(false);
    const variables: UpdateArticleVariables = {
      input: {
        id: article!.id,
        ...(values.title !== article!.title ? {title: values.title} : {}),
        ...(values.slug !== article!.slug ? {slug: values.slug} : {}),
        ...(values.description !== article!.description
          ? {description: values.description}
          : {}),
        ...(input.readAccess !== article!.readAccess
          ? {readAccess: input.readAccess}
          : {}),
        ...(values.content !== article!.content
          ? {content: values.content}
          : {}),
        ...(input.status !== article!.status ? {status: input.status} : {}),
        ...(input.language !== article!.language ? {language: input.language} : {}),
        ...(input.authorId !== article!.author?.id
          ? {authorId: input.authorId}
          : {}),
        ...(input.authorId !== article!.author?.id
          ? {authorId: input.authorId}
          : {}),
        ...(input.categoriesIds !== article?.categoriesIds
          ? {categoriesIds: input.categoriesIds}
          : {}),
        ...(input.tags !== article!.tags ? {tags: input.tags} : {}),
        ...(values.isPinned !== article!.isPinned
          ? {isPinned: values.isPinned}
          : {}),
        ...(values.withVideo !== article!.withVideo
          ? {withVideo: values.withVideo}
          : {}),
        hideHalf:
          input.readAccess !== ReadAccess.UNANYMOUS ? values.hideHalf : false,
        // ...(values.recentlyUpdated !== article!.recentlyUpdated
        //   ? { recentlyUpdated: values.recentlyUpdated }
        //   : {}),
        hiddenFromArticles: values.hiddenFromArticles,
        isFeatured: values.isFeatured,
        featureOrder: values.isFeatured ? input.featureOrder : undefined,
        ...(picUpload
          ? {
            picture: {
              publicId: picUpload.public_id,
              url: picUpload.url,
              filename: getFileNameFromPublicId(picUpload.public_id),
              type: FileType.IMAGE,
            },
          }
          : {}),
        ...(values.video !== article!.video ? {video: values.video} : {}),
        ...(values.buttonLink !== article!.buttonLink ? {buttonLink: values.buttonLink} : {}),
        ...(values.buttonTitle !== article!.buttonTitle ? {buttonTitle: values.buttonTitle} : {}),
        articleText: values.articleText ? draftToHtml(convertToRaw(values.articleText?.getCurrentContent())) : undefined,
      },
    };

    try {
      const res = await update({variables});
      if (res.data?.updateArticle.error) {
        setErrors({slug: t("Slug already exists")});
      } else {
        setSuccess(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, {setErrors, setSubmitting, }) => {
          handleSubmit(values, setErrors, setSubmitting);
        }}
      >
        {({submitForm, values, setFieldValue}) => (
          <Form>
            <Box className={commonClasses.sectionWithSticky}>
              <EditArticlesLeft
                values={values}
                input={input!}
                setInput={setInput as any}
                showEditContent={true}
                setPicUpload={setPicUpload}
                picUpload={picUpload}
                handleSubmit={() => {
                  submitForm();
                }}
                disabled={loading}
                imageUrl={article!.picture?.url}
                id={article!.id}
                setFieldValue={setFieldValue}
              />

              {/*<EditArticleRight*/}
              {/*  showEditContent={true}*/}
              {/*  setPicUpload={setPicUpload}*/}
              {/*  picUpload={picUpload}*/}
              {/*  handleSubmit={() => {*/}
              {/*    submitForm();*/}
              {/*  }}*/}
              {/*  disabled={loading}*/}
              {/*  imageUrl={article!.picture?.url}*/}
              {/*  id={article!.id}*/}
              {/*/>*/}
            </Box>
          </Form>
        )}
      </Formik>

      {loading && <FullscreenLoader transparent/>}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}

      {showCreateNotification ||
        (success && (
          <NotificationToast
            message={success ? t("ArticleUpdated") : t("ArticleCreated")}
            horizontal="center"
            severity="success"
            vertical={"bottom"}
          />
        ))}
    </>
  );
};
