import { makeVar } from "@apollo/client";
import { AdminGetJobs_adminGetJobs_jobs_location_countryObject } from "../graphql/queries/jobs/AdminGetJobs/__generated__/AdminGetJobs";
import { FetchEnums_fetchEnums as IEnums } from "../graphql/queries/jobs/FetchEnums/__generated__/FetchEnums";
import { GetTags_getTags } from "../graphql/queries/jobs/GetTags/__generated__/GetTags";
import { Me_me } from "../graphql/queries/me/Me/__generated__/Me";
import { GetUsers_getUsers_users_location_countryObject } from "../graphql/queries/users/GetUsers/__generated__/GetUsers";

export const userVar = makeVar<Me_me | undefined>(undefined);
export const headerTitleVar = makeVar<string>("No Title Set");
export const drawerOpenVar = makeVar<boolean>(true);
export const userNationalitiesVar = makeVar<string[]>([]);
export const userCountriesVar = makeVar<
  GetUsers_getUsers_users_location_countryObject[]
>([]);
export const jobCountriesVar = makeVar<
  AdminGetJobs_adminGetJobs_jobs_location_countryObject[]
>([]);

export const cmsAssetVar = makeVar<{ callerId: string; asset: any } | null>(
  null
);

export const enumsVar = makeVar<IEnums | null>(null);

export const tagsVar = makeVar<GetTags_getTags[]>([]);
