import { gql } from "@apollo/client";
import {
  SubscriptionPlanFragment,
  SubscriptionUserFragment,
  TransactionFragment,
} from "../../..";

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subId: String!) {
    cancelSubscription(subId: $subId) {
      id
      planId
      userId
      user {
        ...SubscriptionUser
      }
      status
      lastTimeAmount {
        currency
        value
      }
      startTime
      lastTimeCharge
      nextTimeCharge
      statusUpdateTime
      plan {
        ...SubscriptionPlan
      }
      transactions {
        ...Transaction
      }
      discount {
        percentage
        numberOfCharges
        coupon {
          couponCode
          countUsers
          countTimes
          discountPercentage
        }
      }
    }
  }
  ${TransactionFragment}
  ${SubscriptionPlanFragment}
  ${SubscriptionUserFragment}
`;
