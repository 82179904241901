import { Button } from "@material-ui/core";
import React from "react";
import { GoogleIcon } from "../../../assets/GoogleIcon";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { apiDomain, apiUrl } from "../../../apollo/urls";
import { loginUser } from "../../../utils";

interface SignInWithGoogleProps {}

export const SignInWithGoogle: React.FC<SignInWithGoogleProps> = ({}) => {
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const res = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }
      );

      const { data } = await axios.post(`${apiDomain}/google/login`, {
        access_token: codeResponse.access_token, //access token from google
        token_client: "AdminPanel",
        email: res.data.email,
        given_name: res.data.given_name ?? res.data.name,
        family_name: res.data.family_name,
        picture: res.data.picture,
      });

      await loginUser(data.token);
      window.location.reload();
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <Button
      onClick={() => loginWithGoogle()}
      startIcon={<GoogleIcon />}
      fullWidth
      variant="contained"
    >
      Авторизоваться с помощью Google
    </Button>
  );
};
