import React from "react";
import {
  BuilderComponent,
  Builder,
  withBuilder,
} from "@builder.io/react";
import { Box } from "@material-ui/core";
import "../../components/wyziwig/plugins/pdfUploader";

Builder.register("editor.settings", {
  hideStyleTab: false, // Hide the style tab
  hideMainTabs: false, // Hide all main tabs
  hideDataTab: false, // Hide the data tab
  hideOptionsTab: false, // Hide the options tab
  hideToolbar: false, // Hide the main toolbar
  hideHeatMap: false, // Hide the heatmap button
  hidePageUrlEditor: false, // Hide the page URL editor
  hideAnimateTab: false, // Hide the animate tab
  hideInsertTab: false, // Hide the insert tab
  hideTargeting: false, // Hide the targeting UI
});

export const ArticlesEditor: React.FC = () => {
  return (
    <Box width="100%">
      <BuilderComponent model="page" />
    </Box>
  );
};

const CloudinaryImageComponent: React.FC<{ image: any }> = ({ image }) => {
  return image ? <img src={image.secure_url} /> : null;
};

export const Image = withBuilder(CloudinaryImageComponent, {
  name: "Image",
  inputs: [{ name: "image", type: "cloudinaryImageEditor" }],
});
