import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  GetOrdersFilter,
  GetUsersInput,
  OrderStatus,
  PaymentProvider
} from "../../../graphql/globalTypes";
import {SelectButton, TextSearch} from "../../../components";
import {Grid} from "@mui/material";
import {convertEnumToArray} from "../../../utils";
import {useTranslation} from "react-i18next";
import {
  GetUsers_getUsers_users as IUser
} from "../../../graphql/queries/users/GetUsers/__generated__/GetUsers";
import {useGetUsers} from "../../../hooks";
import {useDebounce} from "../../../hooks/useDebounce";
import {CircularProgress, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useQuery} from "@apollo/client";
import {
  GetProducts,
  GetProductsVariables
} from "../../../graphql/queries/products/getProducts/__generated__/GetProducts";
import {GET_PRODUCTS} from "../../../graphql/queries/products/getProducts";

interface PaymentsFilterBarProps {
  filterParams: GetOrdersFilter;
  setFilterParams: React.Dispatch<React.SetStateAction<GetOrdersFilter>>;
}

export const PaymentsFilterBar = ({
                                    filterParams,
                                    setFilterParams
                                  }: PaymentsFilterBarProps) => {
  const {t} = useTranslation();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userFilterParams, setUserFilterParams] = useState<GetUsersInput>({});
  const {data: productData, loading: productsLoading, error} = useQuery<GetProducts,
    GetProductsVariables>(GET_PRODUCTS, {
    onError: (err) => console.error(err),
    variables: {
      filter: {},
      paginationOptions: {
        limit: 100
      }
    }
  });
  const {data, loading: usersLoading} = useGetUsers({
    input: useDebounce(userFilterParams, 700),
    paginationOptions: {
      limit: 100,
      skip: 0,
      sort: {
        field: "created_at",
        sortBy: "desc",
      }
    }
  });
  // const handleTextSearchChange = (v: string) => {
  //   if (v.length > 2) {
  //     setFilterParams((prev) => ({
  //       ...prev,
  //       textSearch: v,
  //     }));
  //   }
  // };
  // const handleTextSearchReset = () => {
  //   setFilterParams((prev) => ({
  //     ...prev,
  //     textSearch: undefined,
  //   }));
  // };

  const handleFilterChange = (
    key: keyof GetOrdersFilter,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterParams((prev) => ({
      ...prev,
      ...(event.target.value === "all"
        ? {
          [key]: undefined,
        }
        : {
          [key]: event.target.value as string,
        }),
    }));
  };

  const handleUserSearchChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (v.target.value.length > 2) {
      setUserFilterParams((prev) => ({
        ...prev,
        textSearch: v.target.value
      }));
    }
  };
  useEffect(() => {
    if (data) {
      setUsers(data.getUsers.users);
    }
  }, [data]);


  return (
    <Grid container spacing={1}>
      {/*<Grid item xs={3}>*/}
      {/*  <TextSearch*/}
      {/*    onClick={handleTextSearchChange}*/}
      {/*    onReset={handleTextSearchReset}*/}
      {/*    hint={"stripeId"}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item xs={3}>
        <Autocomplete
          noOptionsText={t("User not found")}
          loading={usersLoading}
          loadingText={<CircularProgress
            thickness={7}
            style={{marginLeft: 10}}
            size={20}
            color="primary"
          />}
          options={users}
          getOptionLabel={(option: IUser) => `${option.firstName} ${option.lastName} ${option.email} `}
          onChange={(event: any, value) => setFilterParams(prev => ({
            ...prev,
            customerId: value?.id
          }))}
          fullWidth
          style={{marginBottom: 20}}
          renderInput={(params: any) => (
            <TextField {...params}
                       placeholder={t("Enter name or email")}
                       variant="outlined"
                       InputProps={{
                         ...params.InputProps,
                         endAdornment: (
                           <React.Fragment>
                             {usersLoading ?
                               <CircularProgress color="primary"
                                                 size={20}/> : null}
                             {params.InputProps.endAdornment}
                           </React.Fragment>
                         ),
                       }}
                       label={t("Enter name or email")}
                       onChange={handleUserSearchChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          noOptionsText={t("Product not found")}
          loading={productsLoading}
          loadingText={<CircularProgress
            thickness={7}
            style={{marginLeft: 10}}
            size={20}
            color="primary"
          />}
          options={productData?.getProducts.products ?? []}
          getOptionLabel={(option) => option.title}
          onChange={(event: any, value) => setFilterParams(prev => ({
            ...prev,
            productId: value?.id
          }))}
          fullWidth
          style={{marginBottom: 20}}
          renderInput={(params: any) => (
            <TextField {...params}
                       placeholder={t("Enter product title")}
                       variant="outlined"
                       InputProps={{
                         ...params.InputProps,
                         endAdornment: (
                           <React.Fragment>
                             {usersLoading ?
                               <CircularProgress color="primary"
                                                 size={20}/> : null}
                             {params.InputProps.endAdornment}
                           </React.Fragment>
                         ),
                       }}
                       label={t("Enter product title")}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectButton
          onChange={(e) => handleFilterChange("paymentProvider", e)}
          value={filterParams.status ?? "all"}
          options={[...convertEnumToArray(PaymentProvider), "all"]}
          rootStyle={{width: "100%"}}
          label={t("paymentProvider")}
          noOptionTranslation
        />
      </Grid>
      <Grid item xs={3}>
        <SelectButton
          onChange={(e) => handleFilterChange("status", e)}
          value={filterParams.status ?? "all"}
          options={[...convertEnumToArray(OrderStatus), "all"]}
          rootStyle={{width: "100%"}}
          label={t("status")}
        />
      </Grid>
    </Grid>
  );
};
