import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Field, Form, Formik, FormikErrors} from "formik";
import {
  AddNewButton,
  FullscreenLoader,
  MyTextField,
  NotificationToast,
  SelectButton
} from "../../../components";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  Grid,
  Link, Radio, RadioGroup,
  Typography
} from "@material-ui/core";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import {
  AppLanguage,
  BannerKind,
  FileInput,
  GetBannersInput,
  PublishStatus
} from "../../../graphql/globalTypes";
import {PictureInBase64Upload} from "../../../components/inputs/PictureInBase64Upload";
import {useMutation} from "@apollo/client";
import {CREATE_BANNER} from "../../../graphql/mutations/banners/createBanner";
import {
  CreateBanner,
  CreateBannerVariables
} from "../../../graphql/mutations/banners/createBanner/__generated__/createBanner";
import {convertEnumToArray} from "../../../utils";
import {GET_BANNERS} from "../../../graphql/queries/banners/getBanners";
import {
  GetBanners_getBanners
} from "../../../graphql/queries/banners/getBanners/__generated__/GetBanners";
import {
  UpdateBanner,
  UpdateBannerVariables
} from "../../../graphql/mutations/banners/updateBanner/__generated__/UpdateBanner";
import {UPDATE_BANNER} from "../../../graphql/mutations/banners/updateBanner";
import {CheckboxWithLabel} from "formik-material-ui";
import {appLanguages} from "../../../lib/constants";

interface BannerDialogProps {
  edit?: boolean
  input?: GetBannersInput
  banner?: GetBanners_getBanners
}

export const BannerDialog = ({edit, input, banner}: BannerDialogProps) => {
  const {t} = useTranslation();
  const [enableValidation, setEnableValidation] = useState(false);
  const {open, handleOpen, handleClose} = useOpenDialog()
  const [create, {loading, error}] = useMutation<
    CreateBanner,
    CreateBannerVariables
  >(CREATE_BANNER)

  const [update, {loading: updateLoadig, error: updateError}] = useMutation<
    UpdateBanner,
    UpdateBannerVariables
  >(UPDATE_BANNER)

  const initialValues: FormValues = {
    title: banner ? banner.title : "",
    kind: banner ? banner.kind : BannerKind.MAIN_HOME_BANNER,
    href: banner && banner.href,
    text: banner && banner.text,
    extraText: banner && banner.extraText,
    nextLink: banner && banner.nextLink,
    buttonTitle: banner && banner.buttonTitle,
    image: banner?.image && {source: banner.image?.url, fileName: "edit"},
    imageMobile: banner?.imageMobile && {source: banner.imageMobile?.url,  fileName: "edit"},
    isFree:banner ? banner.isFree : true,
    isRegistered:banner ? banner.isRegistered : true,
    isPaid:banner ? banner.isPaid : true,
    sortOrder: banner ? banner.sortOrder : 0,
    status: banner ? banner.status : PublishStatus.DRAFT,
    language: banner ? banner.language : AppLanguage.ru
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    kind: Yup.string()
      .required(t("Required")),
  })

  const handleSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      {
        (edit && banner)
          ? await update({
            variables: {
              input: {
                id: banner.id,
                ...(values.kind!==initialValues.kind ? {kind:values.kind} : {}),
                ...(values.href!==initialValues.href ? {href:values.href} : {}),
                ...(values.title!==initialValues.title ? {title:values.title} : {}),
                ...(values.nextLink!==initialValues.nextLink ? {nextLink:values.nextLink} : {}),
                ...(values.extraText!==initialValues.extraText ? {extraText:values.extraText} : {}),
                ...(values.text!==initialValues.text ? {text:values.text} : {}),
                ...(values.buttonTitle!==initialValues.buttonTitle ? {buttonTitle:values.buttonTitle} : {}),
                ...(values.isFree!==initialValues.isFree ? {isFree:values.isFree} : {}),
                ...(values.isPaid!==initialValues.isPaid ? {isPaid:values.isPaid} : {}),
                ...(values.isRegistered!==initialValues.isRegistered ? {isRegistered:values.isRegistered} : {}),
                ...(values.sortOrder!==initialValues.sortOrder ? {sortOrder:values.sortOrder} : {}),
                ...(values.status!==initialValues.status ? {status:values.status} : {}),
                ...(values.image?.fileName!=="edit" ? {image:values.image} : {}),
                ...(values.imageMobile?.fileName!=="edit" ? {imageMobile:values.imageMobile} : {}),
                ...(values.language!==initialValues.language ? {language:values.language} : {}),
              }
            },
          })
          : await create({
            variables: {
              input: values
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_BANNERS,
                variables: {
                  input
                }
              },
            ],
          })
      }
      handleClose()
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {!edit && <AddNewButton onClick={handleOpen} title={t("Add banner")}/>}
      {edit &&
        <Link style={{cursor: "pointer", marginRight: "10px"}} onClick={handleOpen}>
          {t("edit")}
        </Link>}
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>{t("Banner")}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (values, {setErrors, setSubmitting}) =>
            handleSubmit(values, setErrors, setSubmitting)
          }
        >
          {({values, submitForm, setFieldValue}) => (
            <Form>
              <>
                <DialogContent>
                  <Typography variant="body2" color="textSecondary">
                    {t("Language")}
                  </Typography>
                  <RadioGroup row
                              value={values.language}
                              onChange={(event, value)=>setFieldValue("language", value)} >
                    {convertEnumToArray(AppLanguage).map(lng=>
                      <FormControlLabel value={lng} control={<Radio />} label={appLanguages[lng]} key={lng} />)}
                  </RadioGroup>
                  <Box height="10px"/>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("Title")}
                        name="title"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        label={t("Button title")}
                        name="buttonTitle"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectButton
                        noOptionTranslation
                        rootStyle={{width: "100%"}}
                        label={t("kind")}
                        options={convertEnumToArray(BannerKind)}
                        value={values.kind}
                        onChange={(e) => setFieldValue("kind", e.target.value)}/>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        disabled={!!values.href}
                        component={MyTextField}
                        fullWidth
                        placeholder="/coaching/education"
                        label={t("Internal link")}
                        name="nextLink"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        disabled={!!values.nextLink}
                        component={MyTextField}
                        fullWidth
                        placeholder="https://calendly.com/unihow/careerconsultation"
                        label={t("EXTERNAL_ACCESS_LINK")}
                        name="href"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectButton
                        rootStyle={{width: "100%"}}
                        label={t("Status")}
                        options={convertEnumToArray(PublishStatus)}
                        value={values.status}
                        onChange={(e) => setFieldValue("status", e.target.value)}/>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        type="number"
                        label={t("SortOrder")}
                        name="sortOrder"
                      />
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom:"0px", fontWeight:600}}>
                      {t("Who to show to")}:
                    </Grid>
                    <Grid item xs={4} style={{paddingTop:"0px"}}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isFree"
                        Label={{
                          label: t("UNANYMOUS"),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} style={{paddingTop:"0px"}}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isRegistered"
                        Label={{
                          label: t("isUserExist"),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} style={{paddingTop:"0px"}}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isPaid"
                        Label={{
                          label: t("SUBSCRIBER"),
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        component={MyTextField}
                        fullWidth
                        multiline
                        rows={4}
                        label={t("Text")}
                        name="text"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        multiline
                        rows={4}
                        component={MyTextField}
                        fullWidth
                        label={t("Extra text")}
                        name="extraText"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{fontSize:"16px", fontWeight:600}}>
                        {t("mainImage")}
                      </Typography>
                      <Box width="400px">
                        <PictureInBase64Upload
                          pictureResolution="1600*600 webp"
                          src={values.image?.source ?? ""}
                          setPicture={(e) => setFieldValue("image", {source: e})}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{fontSize:"16px", fontWeight:600}}>
                        {t("mobileImage")}
                      </Typography>
                      <Box width="150px">
                        <PictureInBase64Upload
                          pictureResolution="800*800 webp"
                          src={values.imageMobile?.source ?? ""}
                          setPicture={(e) => setFieldValue("imageMobile", {source: e})}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>{t("Cancel")}</Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    disabled={loading || updateLoadig}
                  >
                    {edit ? t("Save") : t("Add")}
                    {(loading || updateLoadig) && (
                      <CircularProgress
                        thickness={7}
                        style={{marginLeft: 10}}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {loading && <FullscreenLoader transparent/>}

      {(error || updateError) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  kind: BannerKind
  title: string
  text?: string | null
  extraText?: string | null
  buttonTitle?: string | null
  nextLink?: string | null
  href?: string | null
  image?: FileInput | null
  imageMobile?: FileInput | null
  isPaid:boolean
  isFree:boolean
  isRegistered:boolean
  sortOrder:number
  status:PublishStatus
  language?:AppLanguage | null
}