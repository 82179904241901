export const UNCATEGORIZED_JOB_CATEGORY_ID = "61a6186ddc8716162fe4a395";

export const spells = [
  "Accio",
  "Alohomora",
  "Avada Kedavra",
  "Bombardo",
  "Expecto Patronum",
  "Confundo",
  "Obliviate",
  "Expelliarmus",
  "Lumos",
  "Sectumsempra",
  "Wingardium Leviosa",
  "Petrificus Totalus",
  "Crucio",
  "Riddikulus",
  "Reparo",
];

export const emojis = [
  "👀",
  "🤯",
  "🙈",
  "🤪",
  "🤨",
  "🤓",
  "🥸",
  "🤬",
  "😳",
  "🤥",
  "🙄",
  "🤑",
  "😈",
  "💩",
  "☠️",
  "💀",
  "🤟",
  "🖕",
  "🫀",
  "🫁",
  "🧠",
  "🥷",
  "🧙‍♀️",
  "🧟‍♀️",
  "🪲",
  "🍄",
];

export const COUNTIRES = {
  afghanistan: "Афганистан",
  albania: "Албания",
  algeria: "Алжир",
  "american samoa": "Американское Самоа",
  andorra: "Андорра",
  angola: "Ангола",
  anguilla: "Ангилья",
  antarctica: "Антарктида",
  "antigua and barbuda": "Антигуа И Барбуда",
  argentina: "Аргентина",
  armenia: "Армения",
  aruba: "Аруба",
  australia: "Австралия",
  austria: "Австрия",
  azerbaijan: "Азербайджан",
  bahamas: "Багамы",
  bahrain: "Бахрейн",
  bangladesh: "Бангладеш",
  barbados: "Барбадос",
  belarus: "Беларусь",
  belgium: "Бельгия",
  belize: "Белиз",
  benin: "Бенин",
  bermuda: "Бермуды",
  bhutan: "Бутан",
  bolivia: "Боливия",
  "bonaire, sint eustatius and saba": "Бонайре, Синт-Эстатиус и Саба",
  "bosnia and herzegovina": "Босния И Герцеговина",
  botswana: "Ботсвана",
  "bouvet island": "Остров Буве",
  brazil: "Бразилия",
  "virgin islands": "Виргинские Острова",
  brunei: "Бруней",
  bulgaria: "Болгария",
  "burkina faso": "Буркина-Фасо",
  burundi: "Бурунди",
  "cabo verde": "Кабо-Верде",
  cambodia: "Камбоджа",
  cameroon: "Камерун",
  canada: "Канада",
  "cayman islands": "Каймановы Острова",
  "central african republic": "Центрально-Африканская Республика",
  chad: "Чад",
  chile: "Чили",
  china: "Китай",
  "christmas island": "Остров Рождества",
  "cocos islands": "Кокосовые Острова",
  colombia: "Колумбия",
  comoros: "Коморские Острова",
  "congo (the drc)": "Демократическая Республика Конго",
  congo: "Конго",
  "cook islands": "Острова Кука",
  "costa rica": "Коста-Рика",
  croatia: "Хорватия",
  cuba: "Куба",
  curaçao: "Кюрасао",
  cyprus: "Кипр",
  "czech republic": "Чехия",
  "côte divoire": "Берег Слоновой Кости",
  denmark: "Дания",
  djibouti: "Джибути",
  dominica: "Доминика",
  "dominican republic": "Доминиканская Республика",
  ecuador: "Эквадор",
  egypt: "Египет",
  "el salvador": "Сальвадор",
  "equatorial guinea": "Экваториальная Гвинея",
  eritrea: "Эритрея",
  estonia: "Эстония",
  eswatini: "Эсватини",
  ethiopia: "Эфиопия",
  "falkland islands": "Фолклендские Острова",
  "faroe islands": "Фарерские Острова",
  fiji: "Фиджи",
  finland: "Финляндия",
  france: "Франция",
  "french guiana": "Французская Гвиана",
  "french polynesia": "Французская Полинезия",
  "the french southern territories": "Французские Южные Территории",
  gabon: "Габон",
  gambia: "Гамбия",
  georgia: "Грузия",
  germany: "Германия",
  ghana: "Гана",
  gibraltar: "Гибралтар",
  greece: "Греция",
  greenland: "Гренландия",
  grenada: "Гренада",
  guadeloupe: "Гваделупа",
  guam: "Гуам",
  guatemala: "Гватемала",
  guernsey: "Гернси",
  guinea: "Гвинея",
  "guinea-bissau": "Гвинея-Бисау",
  guyana: "Гайана",
  haiti: "Гаити",
  "heard island and mcdonald islands": "Остров Херд и острова Макдональд",
  "holy see": "Святой Престол",
  honduras: "Гондурас",
  "hong kong": "Гонконг",
  hungary: "Венгрия",
  iceland: "Исландия",
  india: "Индия",
  indonesia: "Индонезия",
  iran: "Иран",
  iraq: "Ирак",
  ireland: "Ирландия",
  "isle of man": "Остров Мэн",
  israel: "Израиль",
  italy: "Италия",
  jamaica: "Ямайка",
  japan: "Япония",
  jersey: "Джерси",
  jordan: "Иордания",
  kazakhstan: "Казахстан",
  kenya: "Кения",
  kiribati: "Кирибати",
  "north korea": "Северная Корея",
  "south korea": "Южная Корея",
  kuwait: "Кувейт",
  kyrgyzstan: "Кыргызстан",
  "lao people's democratic republic (the)":
    "Народная Демократическая Республика Лао",
  latvia: "Латвия",
  lebanon: "Ливан",
  lesotho: "Лесото",
  liberia: "Либерия",
  libya: "Ливия",
  liechtenstein: "Лихтенштейн",
  lithuania: "Литва",
  luxembourg: "Люксембург",
  macao: "Макао",
  madagascar: "Мадагаскар",
  malawi: "Малави",
  malaysia: "Малайзия",
  maldives: "Мальдивы",
  mali: "Мали",
  malta: "Мальта",
  "marshall islands": "Маршалловы Острова",
  martinique: "Мартиника",
  mauritania: "Мавритания",
  mauritius: "Маврикий",
  mayotte: "Майотта",
  mexico: "Мексика",
  micronesia: "Микронезия",
  moldova: "Молдова",
  monaco: "Монако",
  mongolia: "Монголия",
  montenegro: "Черногория",
  montserrat: "Монцеррат",
  morocco: "Марокко",
  mozambique: "Мозамбик",
  myanmar: "Мьянма",
  namibia: "Намибия",
  nauru: "Науру",
  nepal: "Непал",
  netherlands: "Нидерланды",
  "new caledonia": "Новая Каледония",
  "new zealand": "Новая Зеландия",
  nicaragua: "Никарагуа",
  niger: "Нигер",
  nigeria: "Нигерия",
  niue: "Ниуэ",
  "norfolk island": "Остров Норфолк",
  "northern mariana islands": "Северные Марианские Острова",
  norway: "Норвегия",
  oman: "Оман",
  pakistan: "Пакистан",
  palau: "Палау",
  palestine: "Палестина",
  panama: "Панама",
  "papua new guinea": "Папуа - Новая Гвинея",
  paraguay: "Парагвай",
  peru: "Перу",
  philippines: "Филиппины",
  pitcairn: "Питкэрн",
  poland: "Польша",
  portugal: "Португалия",
  "puerto rico": "Пуэрто-Рико",
  qatar: "Катар",
  macedonia: "Македония",
  romania: "Румыния",
  "russian federation": "Российская Федерация",
  russia: "Россия",
  rwanda: "Руанда",
  réunion: "Реюньон",
  "saint barthélemy": "Сен-Бартелеми",
  "saint helena, ascension and tristan da cunha":
    "Святая Елена, Вознесение И Тристан-Да-Кунья",
  "saint kitts and nevis": "Сент-Китс И Невис",
  "saint lucia": "Санкт-Люсия",
  "saint martin": "Святой Мартин",
  "saint pierre and miquelon": "Сен-Пьер И Микелон",
  "saint vincent and the grenadines": "Святой Винсент И Гренадины",
  samoa: "Самоа",
  "san marino": "Сан-Марино",
  "sao tome and principe": "Сан-Томе И Принсипи",
  "saudi arabia": "Саудовская Аравия",
  senegal: "Сенегал",
  serbia: "Сербия",
  seychelles: "Сейшелы",
  "sierra leone": "Сьерра-Леоне",
  singapore: "Сингапур",
  "sint maarten": "Синт-Мартен",
  slovakia: "Словакия",
  slovenia: "Словения",
  "solomon islands": "Соломоновы Острова",
  somalia: "Сомали",
  "south africa": "Южная Африка",
  "south georgia and the south sandwich islands":
    "Южная Георгия и Южные Сандвичевы Острова",
  "south sudan": "Южная Судан",
  spain: "Испания",
  "sri lanka": "Шри-Ланка",
  sudan: "Судан",
  suriname: "Суринам",
  svalbard: "Шпицберген",
  sweden: "Швеция",
  switzerland: "Швейцария",
  syria: "Сирия",
  taiwan: "Тайвань",
  tajikistan: "Таджикистан",
  tanzania: "Танзания",
  thailand: "Таиланд",
  "timor-leste": "Тимор-Лешти",
  togo: "Идти",
  tokelau: "Токелау",
  tonga: "Тонга",
  "trinidad and tobago": "Тринидад И Тобаго",
  tunisia: "Тунис",
  turkey: "Турция",
  turkmenistan: "Туркменистан",
  "turks and caicos islands": "Острова Туркс И Кайкос",
  tuvalu: "Тувалу",
  uganda: "Уганда",
  ukraine: "Украина",
  "united arab emirates": "Объединенные Арабские Эмираты",
  "united kingdom": "Объединенное Королевство",
  "united states": "Соединенные Штаты",
  uruguay: "Уругвай",
  uzbekistan: "Узбекистан",
  vanuatu: "Вануату",
  venezuela: "Венесуэла",
  "viet nam": "Вьетнам",
  "virgin islands (british)": "Виргинские Острова (Великобритания)",
  "virgin islands (u.s.)": "Виргинские Острова (США)",
  "wallis and futuna": "Уоллис И Футуна",
  "western sahara": "Западная Сахара",
  yemen: "Йемен",
  zambia: "Замбия",
  zimbabwe: "Зимбабве",
  "åland islands": "Аландские острова",
};

export const appLanguages = {
  en: "English",
  ru: "Русский",
}
