import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { MyTextField, NotificationToast } from "../../../components";
import { VpnKeyOutlined as PassIcon } from "@material-ui/icons";
import { useUpdatePassword } from "../../../hooks";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface UpdatePasswordProps {
  user: Me_me;
}

export const UpdatePassword: React.FC<UpdatePasswordProps> = ({ user }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [enableValidation, setEnableValidation] = useState(false);
  const { update, loading, error } = useUpdatePassword();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .min(8, t("Must have at least VAR characters", { count: 8 }))
      .max(64, t("Cannot have more than VAR characters", { count: 64 }))
      .required(t("Required")),
    newPassword: Yup.string()
      .min(8, t("Must have at least VAR characters", { count: 8 }))
      .max(64, t("Cannot have more than VAR characters", { count: 64 }))
      .matches(/(.*[A-Z].*)/, t("Must have at least 1 capital letter"))
      .matches(/(.*\d.*)/, t("Must have at least 1 digit"))
      .matches(/^\S*$/, t("Cannot have whitespaces"))
      .required(t("Required")),
  });

  return (
    <>
      <Box marginY={3} />
      <Button
        fullWidth
        onClick={handleOpen}
        variant="contained"
        color="primary"
      >
        {t("Update password")}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Update password")}</DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async (
            { newPassword, oldPassword },
            { setErrors, setSubmitting }
          ) => {
            setSuccess(false);
            try {
              const res = await update({
                variables: { input: { newPassword, oldPassword } },
              });
              if (res.data?.updatePassword.error) {
                setErrors({ oldPassword: t("old password is invalid") });
              } else {
                setSuccess(true);
                handleClose();
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, submitForm }) => (
            <Form>
              <DialogContent>
                <Field
                  component={MyTextField}
                  fullWidth
                  placeholder={t("Old password")}
                  name="oldPassword"
                  style={{ marginBottom: 20 }}
                  icon={<PassIcon />}
                  isPassword
                />
                <Field
                  component={MyTextField}
                  fullWidth
                  placeholder={t("New password")}
                  name="newPassword"
                  style={{ marginBottom: 20 }}
                  icon={<PassIcon />}
                  isPassword
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}>{t("Cancel")}</Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm();
                  }}
                  disabled={
                    (!values.newPassword && !values.oldPassword) || loading
                  }
                >
                  {t("Save")}
                  {loading && (
                    <CircularProgress
                      thickness={7}
                      style={{ marginLeft: 10 }}
                      size={12}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}

      {success && (
        <NotificationToast
          message={t("Password updated")}
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface FormValues {
  oldPassword: string;
  newPassword: string;
}
