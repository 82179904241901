import { gql } from "@apollo/client";
import {OrderFragment} from "../../../fragments";

export const UPDATE_ORDER = gql`
    mutation UpdateOrder($input: UpdateOrderInput!) {
        updateOrder(input: $input) {
            ...Order
        }
    }
    ${OrderFragment}
`;