import {gql} from "@apollo/client";
import {MetaFragment, TrackerItemFragment} from "../../../fragments";

export const GET_TRACKER_ITEMS = gql`
    query GetTrackerItems( $paginationOptions: PaginationOptionsArgs!
        $input: GetTrackerItemsInput!) {
        getTrackerItems(paginationOptions: $paginationOptions, input: $input ) {
            meta{
                ...Meta
            }
            trackerItems{
                ...TrackerItem
            }
        }
    }
    ${MetaFragment}
    ${TrackerItemFragment}
`;