import {Button, Dialog, DialogContent, DialogTitle, IconButton,} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useOpenDialog} from "../../../hooks/useOpenDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import {Box} from "@mui/system";
import {NotificationToast} from "../../../components";
import {useDeletePartnerCompany} from "../../../hooks/mutations/useDeletePartnerCompany";

interface DeleteProductDialogProps {
  productId:string
}

export const DeletePartnerCompanyDialog: React.FC<DeleteProductDialogProps> = ({productId}) => {
  const {t} = useTranslation();
  const {open, handleOpen, handleClose} = useOpenDialog()
  const {deletePartnerCompany,loading,error}=useDeletePartnerCompany()
  const [isDeleted, setIsDeleted]=useState(false)

  const handleDelete=async ()=>{
    const res =await deletePartnerCompany({
      variables:{
        id:productId
      }
    })
    if(res.data?.deletePartnerCompany){
      handleClose()
      setIsDeleted(true)
    }
  }
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon color="primary"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <IconButton style={{position: "absolute", top: "5px", right: "5px"}}
                    onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle style={{width: "400px"}}>
          {t("Delete Partner-Company")}
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <ReportIcon style={{width: "150px", height: "150px", color: "red"}}/>
          </Box>
          <Box textAlign="center" fontSize="16px">{t("Do you really want to delete partner company?")}</Box>
        </DialogContent>
        <Box display="flex" margin="20px" gap="30px" justifyContent="center">
          <Button disabled={loading} onClick={handleClose} variant="contained" color="primary">
            {t("Cancel")}
          </Button>
          <Button disabled={loading} onClick={handleDelete} variant="contained" autoFocus>
            {t("Delete")}
          </Button>
        </Box>
      </Dialog>

      {isDeleted && <NotificationToast
        message={t("Partner-company deleted")}
        horizontal="center"
        severity="info"
        vertical={"bottom"}
      />}

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};