import {Box} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSetHeadbarTitle} from "../../hooks";
import {DollarExchangeRate} from "./components/DollarExchangeRate";

interface HomeProps {
}

export const Home: React.FC<HomeProps> = ({}) => {
  const {t} = useTranslation();
  useSetHeadbarTitle(t("Dashbord"));

  return (
    <Box margin="10px">
      <DollarExchangeRate/>
    </Box>
  );
};
