import { gql } from "@apollo/client";
import { SeoTagFragment } from "../../../fragments";

export const GET_SEO_TAGS = gql`
  query GetSeoTags {
    getSeoTags {
      ...SeoTag
    }
  }
  ${SeoTagFragment}
`;
