import { Box, Fab, Grid } from "@material-ui/core";
import { FlipToBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddNewButton, Fallback, FullscreenLoader } from "../../components";
import { GetReviews_getReviews } from "../../graphql/queries/reviews/GetReviews/__generated__/GetReviews";
import { useGetReviews, useSetHeadbarTitle } from "../../hooks";
import { EditOrder, EditReview, ReviewCard } from "./components";

export const Reviews: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Reviews"));
  const { loading, reviews, error } = useGetReviews();
  const [reviewOpen, setReviewOpen] = useState(false);
  const [editOrderOpen, setEditOrderOpen] = useState(false);
  const [currentReview, setCurrentReview] =
    useState<GetReviews_getReviews | null>(null);

  useEffect(() => {
    if (!reviewOpen) {
      setCurrentReview(null);
    }
  }, [reviewOpen]);

  const handleReviewOpen = (review: GetReviews_getReviews | null) => {
    setCurrentReview(review);
    setReviewOpen(true);
  };
  const handleReviewClose = () => setReviewOpen(false);
  const handleOrderOpen = () => {
    setEditOrderOpen(true);
  };
  const handleOrderClose = () => {
    setEditOrderOpen(false);
  };
  const handleOrderSave = () => {
    handleOrderClose();
  };

  if (error)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  if (loading) {
    return <FullscreenLoader />;
  }

  return (
    <>
      <Box paddingX={4} paddingY={2}>
        <Box
          display="flex"
          justifyContent={editOrderOpen ? "flex-end" : "space-between"}
        >
          {!editOrderOpen && (
            <Fab variant="extended" color="primary" onClick={handleOrderOpen}>
              <FlipToBack />
              {t("Rearrange order")}
            </Fab>
          )}

          <AddNewButton
            title={t("Add review")}
            onClick={() => handleReviewOpen(null)}
          />
        </Box>

        <Box height="20px" />

        <EditReview
          review={currentReview}
          open={reviewOpen}
          onClose={handleReviewClose}
        />

        <Box height="20px" />

        <Grid container spacing={3} alignItems="stretch">
          {editOrderOpen && reviews ? (
            <EditOrder reviews={reviews} onClose={handleOrderClose} />
          ) : (
            reviews?.map((review) => (
              <Grid item xs={4} key={review.id}>
                <ReviewCard
                  review={review}
                  handleEditOpen={() => handleReviewOpen(review)}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
};
