import { gql } from "@apollo/client";
import {
  CountryFragment,
  FileFragment,
  UserFragment
} from "../../../fragments";

export const FETCH_JOB_FILTERS = gql`
  query FetchJobFilters {
    fetchJobFilters {
      countries {
        ...Country
      }
      jobCategories  {
          id
          name
          engTrans
          logo {
              ...File
          }
      }
      authors {
          ...User
      }
    }
  }
  ${CountryFragment}
  ${UserFragment}
  ${FileFragment}
`;
