import {gql} from "@apollo/client";
import {FileFragment} from "../../../fragments";

export const UPDATE_PARTNER_COMPANY_CATEGORY = gql`
    mutation UpdatePartnerCompanyCategory($input: UpdatePartnerCompanyCategoryInput!) {
        updatePartnerCompanyCategory(input: $input) {
            partnerCompanyCategory {
                name
                id
                logo{
                    ...File
                }
            }
            error {
                name
                message
            }
        }
    }
    ${FileFragment}
`;
