import React from "react";
import type { BackgroundSettings } from "../types/settings";
import {
  IMAGE_MODE_FLAG,
  COLOR_MODE_FLAG,
  GRADIENT_MODE_FLAG,
} from "./../const/mode";
// import { defaultTranslations as defaultImageUploadTranslations } from '@react-page/editor/lib/ui/ImageUpload/defaultTranslations';

export const defaultTranslations = {
  // Strings used in ImageUpload module
  buttonContent: "Выбрать картинку",
  noFileError: "Файл не выбран",
  badExtensionError: "Некорректный формат",
  tooBigError: "Размер картинки > 5MB",
  uploadingError: "Ошибка при закачке",
  unknownError: "Неизвестная ошибка",

  imageMode: "Картинка",
  colorMode: "Сплошной цвет",
  gradientMode: "Градиент",
  lighten: "Светлее",
  darken: "Темнее",
  usePadding: "Использовать внутренний отступ",
  onOff: "Вкл. настройки на этом табе",
  gradientRotation: "Угол градиента",
  degrees: "гр.",
  gradientOpacity: "Прозрачность градиента",
  addColor: "Добавить цвет",
  addGradient: "Добавить градиент",
  pluginName: "Фон",
  pluginDescription: "Добавить фон из цвета, градиента или картинки",
  or: "ИЛИ",
  haveUrl: "Существующий УРЛ",
  srcPlaceholder: "http://example.com/image.png",
  imageUrl: "Image URL",
  isParallax: "Использовать parallax",
};

export const defaultSettings: Partial<BackgroundSettings> = {
  defaultBackgroundColor: { r: 245, g: 0, b: 87, a: 1 },
  defaultGradientColor: { r: 245, g: 0, b: 87, a: 1 },
  defaultGradientSecondaryColor: { r: 71, g: 245, b: 87, a: 1 },
  defaultMode: 1,
  defaultModeFlag: 1,
  defaultDarken: 0.1,
  defaultLighten: 0,
  defaultHasPadding: true,
  defaultIsParallax: true,
  translations: defaultTranslations,
  enabledModes: IMAGE_MODE_FLAG | COLOR_MODE_FLAG | GRADIENT_MODE_FLAG,
  Controls: () => <> Controls for this plugin were not provided</>,
  Renderer: () => <>Renderer; for this plugin was not provided </>,
  cellStyle: {
    padding: 0,
  },
};
