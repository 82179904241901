import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { userVar } from "../apollo";
import { GET_USER_BY_ID } from "../graphql";
import {
  GetUserById,
  GetUserByIdVariables,
  GetUserById_getUserById as IUser,
} from "../graphql/queries/users/GetUserById/__generated__/GetUserById";

interface EditUserParams {
  id: string;
}

interface EditUserState {
  user?: IUser;
  showCreateNotification?: boolean;
}

export const useFetchUserFromRoute = () => {
  const { id } = useParams<EditUserParams>();
  const location = useLocation<EditUserState>();
  const me = useReactiveVar(userVar);
  const [processing, setProcessing] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [getUser, { error, data }] = useLazyQuery<
    GetUserById,
    GetUserByIdVariables
  >(GET_USER_BY_ID, {
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (me?.id === id) {
      setRedirect(true);
    } else if (location.state?.user) {
      setUser(location.state.user);
      setProcessing(false);
    } else {
      getUser({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.getUserById) {
      setUser(data.getUserById);
      setProcessing(false);
    }
  }, [data]);

  return {
    loading: processing,
    error,
    user,
    redirect,
    showCreateNotification: location.state?.showCreateNotification,
  };
};
