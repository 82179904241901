import { gql } from "@apollo/client";
import {BannerFragment} from "../../../fragments";

export const UPDATE_BANNER = gql`
    mutation UpdateBanner($input: UpdateBannerInput!) {
        updateBanner(input: $input) {
            banner {
                ...Banner
            }
            error {
                name
                message
            }
        }
    }
    ${BannerFragment}
`;
