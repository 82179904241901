import React, {useEffect, useState} from 'react';
import {Box, IconButton, TextField, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import {NotificationToast} from "../../../components";
import {useGetGlobalVars} from "../../../hooks/queries/useGetGlobalVars";
import {useUpdateGlobalVars} from "../../../hooks/mutations/useUpdateGlobalVars";
import {useTranslation} from "react-i18next";

export const DollarExchangeRate = () => {
  const {t} = useTranslation();
  const {loading,data,error}=useGetGlobalVars()
  const {update, loading: updateLoading, error: updateError} = useUpdateGlobalVars()
  const [rate, setRate] = useState(data?.getGlobalVars.usdToRubExchangeRateForProducts)
  const [editMode, setEditMode] = useState(false)
  const handleOnEditClick = () => {
    setEditMode(true)
  }
  const handleOnSaveClick =async () => {
    const res = await update({
      variables: {
        input: {
          usdToRubExchangeRateForProducts: rate!
        }
      },
    })
    if (res.errors){
      setRate(data?.getGlobalVars.usdToRubExchangeRateForProducts)
    }
    setEditMode(false)
  }

  useEffect(() => {
    setRate(data?.getGlobalVars.usdToRubExchangeRateForProducts)
  }, [data])

  return (
    <div>
      <Typography
        style={{
          borderBottom: "1px solid #ddd",
          marginBottom: "10px",
        }}
        variant="h6"
      >
        {t("Dollar exchange rate")}
      </Typography>

      <Box display="flex" alignItems="center" fontSize="18px">
        <Box margin="0 10px">
          usd/rub:
        </Box>
        <>
          {editMode
            ? <TextField
              style={{width: "120px"}}
              size="small"
              type="number"
              value={rate}
              variant="outlined"
              onChange={(e) => setRate(Number(e.target.value))}/>
            : <Box fontWeight={500} margin="0 5px">
              {rate}
            </Box>
          }
        </>
        {!editMode &&
          <IconButton disabled={loading || updateLoading} color="primary"
                      onClick={handleOnEditClick}>
            <EditIcon fontSize="medium"/>
          </IconButton>}

        {editMode &&
          <IconButton
            color="primary"
            disabled={!rate || rate < 0 || updateLoading}
            onClick={handleOnSaveClick}>
            <SaveIcon fontSize="medium"/>
          </IconButton>}
      </Box>
      {(error || updateError) && (
        <NotificationToast
          message={error?.message || updateError?.message || t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </div>
  );
};

