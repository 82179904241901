import {useMutation} from "@apollo/client";
import {
  UpdateOrder,
  UpdateOrderVariables
} from "../../graphql/mutations/products/updateOrder/__generated__/UpdateOrder";
import {UPDATE_ORDER} from "../../graphql/mutations/products/updateOrder";

export const useUpdateOrder = () => {
  const [update, { loading, error }] = useMutation<
    UpdateOrder,
    UpdateOrderVariables
    >(UPDATE_ORDER, {
    onError: (err) => console.error(err),
  });

  return {
    update,
    loading,
    error,
  };
};