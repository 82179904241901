import {gql} from "@apollo/client";

export const UPDATE_EXPERT_REQUEST = gql`
    mutation UpdateExpertRequest($input: UpdateExpertRequestInput!) {
        updateExpertRequest(input: $input) {
            expertRequest {
                category
                title
                id
            }
            error {
                name
                message
            }
        }
    }
`;
