import { gql } from "@apollo/client";
import { ArticleFragment } from "../../../fragments";

export const DUPLICATE_ARTICLE = gql`
  mutation DuplicateArticle($id: String!) {
    duplicateArticle(id: $id) {
      ...Article
    }
  }
  ${ArticleFragment}
`;
