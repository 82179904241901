import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

export const supportedLanguages = ["ru"];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "ru",
    lng: "ru",
    debug: true,
    ns: "translation",
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

const t = i18n.t.bind(i18n);
export { t };
