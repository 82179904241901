import {Box, Grid, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AddNewButton, ArticleCategorySkeleton, Empty, Fallback,} from "../../components";
import {useSetHeadbarTitle,} from "../../hooks";
import {useGetExpertRequests} from "../../hooks/queries/experts/useGetExpertRequests";
import {
  GetExpertRequests_getExpertRequests
} from "../../graphql/queries/experts/getExpertRequests/__generated__/GetExpertRequests";
import {ExpertRequestCard} from "./components/ExpertRequestCard";
import {EditExpertRequest} from "./components/EditExpertRequest";


export const ExpertRequests: React.FC = () => {
  const { t } = useTranslation();
  useSetHeadbarTitle(t("Requests"));

  const {
    loading: expertRequestsLoading,
    expertRequests,
    error: expertRequestsError,
  } = useGetExpertRequests();

  const [expertRequestOpen, setExpertRequestOpen] = useState(false);

  const [currentExpertRequest, setCurrentExpertRequest] =
    useState<GetExpertRequests_getExpertRequests | null>(null);

  const handleExpertRequestOpen = (
    expertRequest: GetExpertRequests_getExpertRequests | null
  ) => {
    setCurrentExpertRequest(expertRequest);
    setExpertRequestOpen(true);
  };

  const handleExpertRequestClose = () => setExpertRequestOpen(false);

  if (expertRequestsError)
    return (
      <Fallback
        title={t("Something went wrong")}
        message={t("ServerNotResponding")}
      />
    );

  const skeletonMarkup = new Array(5).fill(undefined).map((_, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
      <ArticleCategorySkeleton />
    </Grid>
  ));

  const expertRequestMarkup = expertRequests?.length ? (
    [...expertRequests].sort((a,b)=>a.category.localeCompare(b.category)).map((request, index) => (
      <Grid item key={index} xs={12} sm={6} md={4} >
        <ExpertRequestCard
          expertRequest={request}
          onClick={() => handleExpertRequestOpen(request)}
        />
      </Grid>
    ))
  ) : (
    <Empty emoji={"🤔"} title={t("No expert requests yet")} />
  );

  return (
    <>
      <Box paddingX={4} paddingY={2}>
        <Typography
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "20px",
          }}
          variant="h6"
        >
          {t("Expert requests")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddNewButton
            title={t("Add expert request")}
            onClick={() => handleExpertRequestOpen(null)}
          />
        </Box>
        <Box paddingTop={3}>
          <Grid container spacing={3}>
            {expertRequestsLoading ? skeletonMarkup : expertRequestMarkup}
          </Grid>
        </Box>
      </Box>

      <EditExpertRequest
        expertRequest={currentExpertRequest}
        open={expertRequestOpen}
        onClose={handleExpertRequestClose}
      />
    </>
  );
};




