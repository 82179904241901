import { gql } from "@apollo/client";
import { EnumFragment } from "../../../fragments";

export const FETCH_ENUMS = gql`
  query FetchEnums {
    fetchEnums {
      jobCategories {
        ...Enum
      }
      employmentTypes {
        ...Enum
      }
      seniorities {
        ...Enum
      }
    }
  }
  ${EnumFragment}
`;
