import { PropTypes, Typography } from "@material-ui/core";
import type { CellPluginComponentProps } from "@react-page/editor";
import React from "react";
import { CMSUploadButton } from "../../../../buttons";
import type { AudioState } from "../types/state";

export const AudioHtmlRenderer: React.FC<CellPluginComponentProps<AudioState>> =
  (props) => {
    const { data } = props;

    return (
      <>
        <div
          style={{
            display: props.isEditMode ? "flex" : "none",
            zIndex: 100,
            position: "absolute",
          }}
        >
          <CMSUploadButton
            resource_type="video"
            onInsert={(url) =>
              props.onChange({
                url,
              })
            }
          />
        </div>
        <div
          className="react-page-content-plugin-audio"
          style={{ margin: "auto" }}
        >
          {data.title && (
            <Typography
              variant="h6"
              align={data.alignTitle as PropTypes.Alignment}
            >
              {data.title}
            </Typography>
          )}
          <audio controls src={data?.url} autoPlay={false} loop={false}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          {data.subtitle && (
            <Typography
              variant="body2"
              style={{ fontStyle: "italic" }}
              align={data.alignSubtitle as PropTypes.Alignment}
            >
              {data.subtitle}
            </Typography>
          )}
        </div>
      </>
    );
  };

export default AudioHtmlRenderer;
