import {useQuery} from "@apollo/client";
import {
  GET_PROMOTERS_CURRENT_STATS
} from "../../graphql/queries/promoters/getPromotersCurrentStats";
import {
  GetPromotersCurrentStats
} from "../../graphql/queries/promoters/getPromotersCurrentStats/__generated__/GetPromotersCurrentStats";

export const useGetPromotersCurrentStats = () => {
  const { data, loading, error } = useQuery<GetPromotersCurrentStats>(GET_PROMOTERS_CURRENT_STATS, {
    onError: (err) => console.log(err),
  });

  return {
    promotersCurrentStats: data?.getPromotersCurrentStats,
    loading,
    error,
  };
};
