import {useQuery} from "@apollo/client";
import {
  GetGlobalVars
} from "../../graphql/queries/globalVars/__generated__/GetGlobalVars";
import {GET_GLOBAL_VARS} from "../../graphql/queries/globalVars";

export const useGetGlobalVars = () => {
  const { data, loading, error, refetch } = useQuery<GetGlobalVars>(GET_GLOBAL_VARS, {
    onError: (err) => console.error(err),
  });

  return { data, loading, error, refetch };
};