import { gql } from "@apollo/client";

export const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions(
    $input: GetSubscriptionsInput!
    $paginationOptions: PaginationOptionsArgs!
  ) {
    getSubscriptions(input: $input, paginationOptions: $paginationOptions) {
      total
      subscriptions {
        id
        planId
        userId
        user {
          email
          firstName
          lastName
        }
        status
        lastTimeAmount {
          currency
          value
        }
        startTime
        lastTimeCharge
        plan {
          id
          title
          billingCycle
        }
        discount {
          percentage
          numberOfCharges
          coupon {
            couponCode
            countUsers
            countTimes
            discountPercentage
          }
        }
      }
    }
  }
`;
