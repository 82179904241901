import {gql} from "@apollo/client";
import {ExpertFragment, MetaFragment} from "../../../fragments";

export const GET_EXPERTS = gql`
    query GetExperts(
        $input: GetExpertsInput!
        $paginationOptions: PaginationOptionsArgs!
    ) {
        getExperts(input: $input, paginationOptions: $paginationOptions) {
            meta{
                ...Meta
            }
            total
            experts {
                ...Expert
            }
        }
    }
    ${ExpertFragment}
    ${MetaFragment}
`;