import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { EmailOutlined as EmailIcon } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { userVar } from "../../../apollo";
import { MyTextField, NotificationToast } from "../../../components";
import { useUpdateEmail } from "../../../hooks";
import { Me_me } from "../../../graphql/queries/me/Me/__generated__/Me";

interface UpdateEmailProps {
  user: Me_me;
  open: boolean;
  onClose: () => void;
}

export const UpdateEmail: React.FC<UpdateEmailProps> = ({
  user,
  open,
  onClose,
}) => {
  const [enableValidation, setEnableValidation] = useState(false);
  const { t } = useTranslation();
  const { update, loading, error } = useUpdateEmail();

  const validationSchema = Yup.object({
    email: Yup.string().email(t("Invalid email")).required(t("Required")),
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("updateEmail")}</DialogTitle>

        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          onSubmit={async ({ email }, { setErrors, setSubmitting }) => {
            try {
              const res = await update({
                variables: {
                  email,
                  userId: user.id,
                },
              });

              if (res.data?.updateUserEmail.error) {
                setErrors({ email: t("Email already exists") });
              } else {
                userVar(res.data?.updateUserEmail.user!);
                onClose();
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ dirty, submitForm }) => (
            <Form>
              <>
                <DialogContent>
                  <Field
                    component={MyTextField}
                    type="email"
                    name="email"
                    fullWidth
                    placeholder={t("Email")}
                    icon={<EmailIcon />}
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={onClose}>{t("Cancel")}</Button>
                  <Button
                    disabled={!dirty || loading}
                    onClick={() => {
                      setEnableValidation(true);
                      submitForm();
                    }}
                    color="primary"
                    variant="contained"
                  >
                    {t("Save")}
                    {loading && (
                      <CircularProgress
                        thickness={7}
                        style={{ marginLeft: 10 }}
                        size={12}
                        color="secondary"
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>

      {error && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
