import { gql } from "@apollo/client";
import { CountryFragment } from "../../../fragments";

export const UPDATE_COUNTRY = gql`
  mutation UpdateCountry($input: UpdateCountryInput!) {
    updateCountry(input: $input) {
      ...Country
    }
  }
  ${CountryFragment}
`;
