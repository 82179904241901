import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleStat } from "../../lib";
import { emojis } from "../../lib/constants";
import { capitalize, getRandomInt } from "../../utils";
import { Empty } from "../skeletons";
import { useCardStyle } from "./styles";
import clsx from "clsx";

interface StatsCardProps {
  title: string;
  values: SimpleStat[];
  stickyHeader?: boolean;
  rootStyle?: React.CSSProperties;
}

export const StatsCard: React.FC<StatsCardProps> = ({
  title,
  values,
  rootStyle,
  stickyHeader,
}) => {
  const classes = useCardStyle();
  const { t } = useTranslation();

  const sorted = useMemo(
    () => values.slice().sort((a, b) => b.total - a.total),
    [values]
  );

  return (
    <Paper
      className={clsx(classes.statscard__paper, {
        [classes.statscard__paper__sticky]: stickyHeader,
      })}
      style={rootStyle}
    >
      <Box
        className={clsx(classes.statscard__title, {
          [classes.statscard__title__sticky]: stickyHeader,
        })}
      >
        <Typography color="textSecondary" variant="h6">
          {title}
        </Typography>
      </Box>

      <List>
        {!values.length && (
          <Empty
            emoji={emojis[getRandomInt(0, emojis.length - 1)]}
            title={t("No preferences found")}
          />
        )}
        {sorted.map((v) => (
          <ListItem
            key={v.value}
            disableRipple
            button
            style={{ cursor: "default" }}
          >
            <ListItemText secondary={capitalize(v.value)} />
            <Typography variant="body2" color="textSecondary">
              {v.total}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
