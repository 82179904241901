import { useMutation, useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { tagsVar } from "../../../../apollo";
import { useInputStyles } from "../../../../components/inputs/styles";
import { ADD_TAG, DELETE_TAG, GET_TAGS } from "../../../../graphql";
import {
  CreateJobInput,
  UpdateJobInput,
} from "../../../../graphql/globalTypes";
import {
  AddTag,
  AddTagVariables,
} from "../../../../graphql/mutations/jobs/AddTag/__generated__/AddTag";
import {
  DeleteTag,
  DeleteTagVariables,
} from "../../../../graphql/mutations/jobs/DeleteTag/__generated__/DeleteTag";

interface UpdateTagsProps {
  input: CreateJobInput | UpdateJobInput;
  setInput: React.Dispatch<
    React.SetStateAction<CreateJobInput | UpdateJobInput>
  >;
}

export const UpdateTags: React.FC<UpdateTagsProps> = ({ input, setInput }) => {
  const { t } = useTranslation();
  const tags = useReactiveVar(tagsVar);
  console.log(tags);

  const availableTags = input.tags
    ? tags.filter((t) => !input.tags?.includes(t.id))
    : tags;
  const existingTagsNames = tags.map((t) => t.title.toLowerCase().trim());
  const classes = useInputStyles();
  const [newTag, setNewTag] = useState("");
  const [exists, setExists] = useState(false);
  const [addTag] = useMutation<AddTag, AddTagVariables>(ADD_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_TAGS,
      },
    ],
  });
  const [deleteTag] = useMutation<DeleteTag, DeleteTagVariables>(DELETE_TAG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_TAGS,
      },
    ],
  });

  // const popTag = useCallback(
  //   (t: string, key: keyof CreateJobInput) => {

  //   },
  //   [input, setInput]
  // );

  const handleGlobalTagAdd = async () => {
    const v = newTag.toLowerCase().trim();
    if (existingTagsNames.includes(v)) {
      setExists(true);
    } else {
      await addTag({ variables: { input: { title: v } } });
      setNewTag("");
    }
  };

  const handleNewTagChange = (e: any) => {
    if (exists) setExists(false);
    setNewTag(e.target.value);
  };

  const handleGlobalTagDelete = async (id: string) => {
    await deleteTag({ variables: { id } });
  };

  const handleAddTagToJob = (id: string) => {
    setInput((prev) => ({
      ...prev,
      tags: prev["tags"] ? [...prev["tags"], id] : [id],
    }));
  };

  const handleJobTagDelete = (id: string) => {
    setInput((prev) => ({
      ...prev,
      tags: (prev["tags"] as string[])!.filter((c) => c !== id),
    }));
  };

  return (
    <>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {t("Tags")}
        </Typography>

        <Box margin={2} />

        <Paper className={classes.tags__paper}>
          <TextField
            variant="standard"
            fullWidth
            value={newTag}
            label={t("New tag")}
            InputLabelProps={{ shrink: true }}
            style={{ flex: 2 }}
            onChange={handleNewTagChange}
            onKeyPress={(e) => {
              if (e.code === "Enter" && newTag) {
                e.preventDefault();
                handleGlobalTagAdd();
              }
            }}
          />
          <Button
            onClick={handleGlobalTagAdd}
            fullWidth
            style={{ flex: 1 }}
            disabled={!newTag}
          >
            {t("Add")}
          </Button>
        </Paper>

        {exists && (
          <Typography
            color="error"
            variant="caption"
            style={{ marginLeft: 10 }}
          >
            {t("Tag already exists")}
          </Typography>
        )}

        <Box margin={2} />

        <Box>
          {availableTags.map((tag) => (
            <Chip
              key={tag.id}
              label={tag.title}
              onDelete={
                tag.title === "remote"
                  ? undefined
                  : () => handleGlobalTagDelete(tag.id)
              }
              onClick={() => handleAddTagToJob(tag.id)}
              color="default"
              style={{ margin: 3 }}
              classes={{
                label: classes.chip__label,
              }}
            />
          ))}
        </Box>

        <Box margin={2} />

        <Typography variant="body2" color="textSecondary">
          {t("Chosen Tags")}
        </Typography>

        {console.log(input.tags)}
        {console.log(tags)}

        {!!tags.length && (
          <Box>
            {(input.tags ?? []).map((tagId) => (
              <Chip
                key={tagId}
                label={tags.find((t) => t.id === tagId)?.title ?? "NOT FOUND"}
                onClick={() => handleJobTagDelete(tagId)}
                color="default"
                style={{ margin: 3 }}
                classes={{
                  label: classes.chip__label,
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};
