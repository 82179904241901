import { gql } from "@apollo/client";

export const TOGGLE_PLUGIN_USER_BLOCK = gql`
  mutation TogglePluginUserBlock($id: String!) {
    togglePluginUserBlock(id: $id) {
      id
      email
      firstName
      lastName
      role
      gender
      summary
      skype
      linkedId
      zoom
      phone
      nationality
      languages
      birthDate
      avatar {
        ...File
      }
      location {
        ...Location
      }
      created_at
    }
  }
`;
