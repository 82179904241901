import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Field, Form, Formik, FormikErrors} from "formik";
import {useSetHeadbarTitle} from "../../hooks";
import * as Yup from "yup";
import {PublishStatus} from "../../graphql/globalTypes";
import {Box} from "@mui/system";
import {CircularProgress, Divider, Fab, Grid} from "@material-ui/core";
import {
  Fallback,
  FullscreenLoader,
  MyTextField,
  NotificationToast,
  SelectButton
} from "../../components";
import {convertEnumToArray} from "../../utils";
import {SaveOutlined as SaveIcon} from "@material-ui/icons";
import {useMutation} from "@apollo/client";
import {GET_AFFILIATES} from "../../graphql/queries/affiliate/GetAffiliates";
import {
  UpdateAffiliate,
  UpdateAffiliateVariables
} from "../../graphql/mutations/affiliates/updateAffiliate/__generated__/UpdateAffiliate";
import {UPDATE_AFFILIATE} from "../../graphql/mutations/affiliates/updateAffiliate";
import {useFetchAffiliateFromRoute} from "../../hooks/useFetchAffiliateFromRoute";


export const EditAffiliate = () => {
  const {t} = useTranslation();
  const history = useHistory();
  useSetHeadbarTitle(t("Edit affiliate"));
  const [updated, setUpdated] = useState(false)
  const [enableValidation, setEnableValidation] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null)

  const {
    affiliate,
    loading: initLoading,
    error: initError,
    showCreateNotification
  } = useFetchAffiliateFromRoute()
  const [update, {loading, error}] = useMutation<
    UpdateAffiliate,
    UpdateAffiliateVariables
  >(UPDATE_AFFILIATE, {
    onError: (err) => console.error(err),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_AFFILIATES,
        variables: {
          input: {},
          paginationOptions: {
            limit: 10,
            skip: 0,
            sort: {
              field: "created_at",
              sortBy: "desc",
            },
          },
        },
      },
    ],
  });

  if (initError || (!affiliate && !initLoading)) {
    return (
      <Fallback
        title="Affiliate not found"
        message="Seems like the Affiliate you want does not exist"
        onClick={() => history.push("/affiliates")}
        btnTitle={t("Understand")}
      />
    );
  }

  if (initLoading || !affiliate) {
    return <FullscreenLoader transparent/>;
  }

  const initialValues: AffiliateFormValues = {
    name: affiliate.name,
    affiliateApplicationText: affiliate.affiliateApplicationText,
    email: affiliate.email,
    userId: affiliate.userId.id,
    status: affiliate.status,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
    userId: Yup.string()
      .required(t("Required")),
    email: Yup.string().email(t("Invalid email"))
      .required(t("Required")),
    affiliateApplicationText: Yup.string()
      .min(3, t("Must have at least VAR characters", {count: 3}))
      .required(t("Required")),
  });

  const handleSubmit = async (
    values: AffiliateFormValues,
    setErrors: (errors: FormikErrors<AffiliateFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {

    const variables: UpdateAffiliateVariables = {
        input: {
          id: affiliate.id,
          ...values
        }
      }
    ;

    try {
      const res = await update({variables})
      if (res.data?.updateAffiliate.error) {
        setUpdateError(res.data.updateAffiliate.error.message)
      } else if (res.data?.updateAffiliate.affiliate) {
        setUpdated(true)
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={enableValidation}
        validateOnBlur={enableValidation}
        onSubmit={async (values, {setErrors, setSubmitting}) =>
          handleSubmit(values, setErrors, setSubmitting)
        }>
        {({values, submitForm, setFieldValue, errors}) => {
          return (<Form>
            <Box margin="20px">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("First name, last name")}
                    placeholder={t("Name example")}
                    name="name"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    label={t("Email")}
                    placeholder={t("Email")}
                    name="email"
                    style={{marginBottom: 20}}/>
                </Grid>
                <Grid item xs={6}>
                  <SelectButton
                    rootStyle={{
                      width: "100%",
                      ...(values.status === PublishStatus.MODERATION
                        ? {animation: "haloAnimation 1.5s infinite ease-in-out"}
                        : {})
                    }}
                    label={t("status")}
                    options={convertEnumToArray(PublishStatus)}
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={MyTextField}
                    multiline
                    rows={6}
                    label={t("Motivational text")}
                    placeholder={t("Motivational text")}
                    name="affiliateApplicationText"/>
                  <Divider style={{marginBottom: "20px"}}/>
                </Grid>
              </Grid>

              <Box textAlign="center">
                <Fab
                  color="secondary"
                  variant="extended"
                  size="large"
                  style={{width: "30%"}}
                  id="bottomBtn"
                  onClick={() => {
                    setEnableValidation(true);
                    submitForm()
                  }}
                  disabled={loading}
                >
                  {loading
                    ? <CircularProgress style={{color: "white"}} size={30}/>
                    : <><SaveIcon style={{marginRight: 10}}/>
                      {t("Save")}</>
                  }
                </Fab>
              </Box>
            </Box>
          </Form>)
        }}
      </Formik>
      {loading && <FullscreenLoader transparent/>}
      {(error) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
      {updateError && (
        <NotificationToast
          message={updateError}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
      {showCreateNotification && (
        <NotificationToast
          message="Affiliate created"
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
      {updated && (
        <NotificationToast
          message="Affiliate updated"
          horizontal="center"
          severity="success"
          vertical={"bottom"}
        />
      )}
    </>
  );
};

interface AffiliateFormValues {
  name: string;
  userId: string;
  email: string;
  affiliateApplicationText: string;
  status: PublishStatus;
}


