import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationToast } from "../../../components";
import { DELETE_COUPON } from "../../../graphql";
import { CreateCoupon_createCoupon_coupon } from "../../../graphql/mutations/subscriptions/CreateCoupon/__generated__/CreateCoupon";
import {
  DeleteCoupon,
  DeleteCouponVariables,
} from "../../../graphql/mutations/subscriptions/DeleteCoupon/__generated__/DeleteCoupon";
import {
  GetCoupons,
  GetCoupons_getCoupons,
} from "../../../graphql/queries/subscriptions/GetCoupons/__generated__/GetCoupons";

interface CouponsTableProps {
  coupons: GetCoupons_getCoupons[];
  loading: boolean;
  setCoupon: React.Dispatch<
    React.SetStateAction<CreateCoupon_createCoupon_coupon | null>
  >;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCoupons>>;
}

export const CouponsTable: React.FC<CouponsTableProps> = ({
  coupons,
  loading,
  setCoupon,
  refetch,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [deleteCouponId, setDeleteCouponId] = useState("");
  const [deleteCoupon, { loading: deleteLoading, error }] = useMutation<
    DeleteCoupon,
    DeleteCouponVariables
  >(DELETE_COUPON, {
    onCompleted: () => {
      setOpen(false);
      refetch({});
    },
  });

  const handleDelete = () => {
    deleteCoupon({ variables: { id: deleteCouponId } });
  };

  return (
    <>
      <Box width="100%" padding="50px">
        <Grid container spacing={4}>
          {coupons.map((coupon) => (
            <Grid item sm={4} lg={3} key={coupon.coupon.id}>
              <Card
                style={{
                  height: "100%",
                  position: "relative",
                  marginBottom: "50px",
                }}
              >
                <CardContent>
                  <Typography
                    style={{
                      padding: "10px 7px",
                      border: "2px solid #f4a500",
                      borderRadius: "6px",
                      color: "#f4a500",
                      textAlign: "center",
                    }}
                  >
                    {coupon.coupon.couponCode}
                  </Typography>

                  <Box height="20px" />

                  <Typography
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Количество новых пользователей:
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: 5,
                      }}
                    >
                      {coupon.coupon.countUsers}
                    </span>
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Количество используемых раз:
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: 5,
                      }}
                    >
                      {coupon.coupon.countTimes}
                    </span>
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Скидка:
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: 5,
                      }}
                    >
                      {coupon.coupon.discountPercentage * 100}%
                    </span>
                  </Typography>

                  <Box height="20px" />

                  <Typography
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {coupon.countSubsctriptions} пользователей использовали
                  </Typography>
                </CardContent>
                <CardActions
                  style={{
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <IconButton onClick={() => setCoupon(coupon.coupon)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteCouponId(coupon.coupon.id);
                      setOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogContent>Точно удалить?</DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>
          <Button disabled={deleteLoading} onClick={() => handleDelete()}>
            {t("Delete")}
            {Boolean(deleteLoading) && (
              <CircularProgress style={{ marginLeft: "10px" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {Boolean(error) && (
        <NotificationToast
          message={t("ServerNotResponding")}
          horizontal="center"
          severity="error"
          vertical={"bottom"}
        />
      )}
    </>
  );
};
