import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Box, Paper } from "@material-ui/core";

interface UpdateDescriptionProps {
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  description: string;
}

export const UpdateDescription: React.FC<UpdateDescriptionProps> = ({
  setDescription,
  description,
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    description
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(description).contentBlocks,
            htmlToDraft(description).entityMap
          )
        )
      : EditorState.createEmpty()
  );

  useEffect(() => {
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  });

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <Box>
      <Paper style={{ padding: 20 }}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        />
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </Paper>
    </Box>
  );
};
